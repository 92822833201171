import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  API,
  DocumentStatus,
  DocumentSummaryVm,
  FormTypeDTO,
  ReportingSubmissionStatsVm,
  SortDirection,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { DateRange } from "shared/src/components/DateRange/DateRange";
import Loader from "shared/src/components/Loader/Loader";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import { FilterSelect } from "shared/src/components/Select/FilterSelect";
import { SelectOption } from "shared/src/components/Select/Select";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { TabGroup } from "shared/src/components/Tabs/TabGroup/TabGroup";
import {
  DateRangeValue,
  getMinMaxSubmissionDatesFromTimeFilter,
  TimeFilter,
} from "shared/src/data/timeFilters";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { timeFilterOptions } from "shared/src/data/timeFilters";
import { userWriteRoles } from "../../../routes/constants/permissionSets";
import {
  DocumentDrawer,
  DocumentDrawerSection,
} from "../documents/DocumentDrawer";
import * as UserStyles from "../users/styles";
import Modal from "shared/src/components/common/Modal";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import Popper from "shared/src/components/common/Popper";
import { StatsCard } from "../../common/StatsCard";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "shared/src/components/common/styled/Table";
import DefaultCell from "../../common/TableUI/DefaultCell";
import { default as CommonText } from "shared/src/components/common/Text";
import { prettifyDocumentStatus } from "../../Documents/helpers";
import { generateGroupOptions } from "shared/src/components/clientAdmin/helpers";
import {
  PageContent,
  StatsWrapper,
  SubmitButton,
  Text,
} from "shared/src/components/clientAdmin/styles";
import { DeleteDocumentConfirmation } from "../users/modalContent/DeleteDocumentConfirmation";
import { PopperChevron, PopperRow, PopperWrapper } from "../users/styles";
import StickyProfileHeader from "./components/StickyProfileHeader";
import SubmissionsCard from "./components/SubmissionsCard";
import * as S from "./styles";

interface ViewUserProfileProps {
  authToken: string;
  dispatch?: any;
  formTypes?: FormTypeDTO[];
}

interface Params extends Record<string, string> {
  id: string;
}

interface Sorting {
  [key: string]: {
    direction: SortDirection;
    type?: string;
  };

  primarySort: {
    direction: SortDirection;
    type: string;
  };
}

// constants

const DeleteDocumentMenuItem = ({
  loading,
  error,
  closeMenu,
  openConfirmationModal,
}: {
  loading: boolean;
  error: boolean;
  closeMenu: () => void;
  openConfirmationModal: () => void;
}) => {
  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();
  return (
    <Loader loading={loading}>
      <S.DeleteDocumentButton
        onClick={() => {
          openConfirmationModal();
          closeMenu();
        }}
      >
        Delete {documentTermLower} (permission required)
        {error && (
          <CommonText className="ml-2" variant="error">
            An error occurred
          </CommonText>
        )}
      </S.DeleteDocumentButton>
    </Loader>
  );
};

const statusOptions: { value: DocumentStatus; label: string }[] = [
  { value: DocumentStatus.NEW, label: "New" },
  { value: DocumentStatus.SUBMITTED, label: "Submitted" },
  { value: DocumentStatus.IN_PROGRESS, label: "In Progress" },
];

const formTypeOptions = (
  formTypes: FormTypeDTO[] = []
): SelectOption<number>[] => {
  return formTypes.map((e) => ({
    value: e.id,
    label: e.name,
  }));
};

/** helper function to get label for totalDocuments box (left most) */
function getDocumentsStatsNameIfSubmissionsFilterApplied(
  dateRange: DateRangeValue | undefined,
  timeFilter: TimeFilter,
  documentsTerm: string
): string {
  const { minSubmissionDate } = getMinMaxSubmissionDatesFromTimeFilter(
    timeFilter,
    dateRange
  );

  if (minSubmissionDate) {
    return `${documentsTerm} submitted since ${moment(minSubmissionDate).format(
      "MM/DD/YY"
    )}`;
  }

  return documentsTerm;
}

/** helper function to extend ReportingSubmissionStatsVm */
function getStat(
  key: string,
  data?: ReportingSubmissionStatsVm | null
): string | number | JSX.Element | undefined {
  if (!data) {
    return;
  }
  let stat: number = 0;
  if (key === "totalDocuments") {
    stat = data.totalSubmissions + data.totalOutstanding;
  } else if (key === "completeRateOfSubmissions") {
    stat =
      (100 * data.totalSubmissions) /
      (data.totalSubmissions + data.totalOutstanding);
    return stat.toFixed(0);
  } else if (key in data) {
    stat = data[key];
  } else {
    console.error("Stat not found in ReportingSubmissionStatsVm: " + key);
  }
  return stat;
}

const listGroups = (allGroups, primaryGroup) => {
  const _allGroups = allGroups;
  _allGroups.sort((x, y) =>
    x.id === primaryGroup ? -1 : y.id === primaryGroup ? 1 : 0
  ); // Move primary group to front
  const result: any[] = [""];
  const primaryGroupStar = <S.StarIcon />;
  if (_allGroups) {
    _allGroups.forEach((group, index) => {
      if (primaryGroup === group.id) result.push(primaryGroupStar);
      result.push(
        `${group.name}${(index !== allGroups.length - 1 && ", ") || ""}`
      );
    });
  }
  // If no groups
  if (!result || result.length === 0) {
    return "-";
  }
  // If groups
  return result;
};

export const ViewUserProfile = (props: ViewUserProfileProps) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const formTypesCall = useAPI(API.getAllFormTypes);
  const allGroupCall = useAPI(API.getClientGroups, {
    size: 200,
    sort: "name,asc",
  });

  // Group config terms
  const documentsTerm = useGroupTerm("document", "noun", "plural", "Documents");
  const documentsTermLower = documentsTerm.toLowerCase();
  const documentTerm = useGroupTerm("document", "noun", undefined, "Documents");
  const documentTermLower = documentTerm.toLowerCase();
  const supervisorTerm = useGroupTerm(
    "supervisor",
    "noun",
    undefined,
    "Supervisor"
  );

  // Get user id from url
  const participantId = Number(params.id);

  // Sort
  const [sorting, setSorting] = useState<Sorting>({
    // Order is by appearance in table
    title: {
      direction: "asc",
    },
    ["clientGroup.name"]: {
      direction: "asc",
    },
    ["formTypeIds"]: {
      direction: "asc",
    },
    startDate: {
      direction: "asc",
    },
    submissionDate: {
      direction: "asc",
    },
    status: {
      direction: "asc",
    },
    primarySort: {
      // Not a column, table's sorted by 1 column at all times
      direction: "desc",
      type: "submissionDate",
    },
  });

  const sortBy = (type, direction) => {
    setSorting({
      ...sorting,
      [type]: {
        direction,
      },
      primarySort: {
        direction,
        type,
      },
    });
  };

  // pagination
  const [documentsPage, setDocumentsPage] = useState(0);
  const [documentsSize, setDocumentsSize] = useState(10);

  // Filters
  const [group, setGroup] = useState<number>();
  const [status, setStatus] = useState<DocumentStatus>();
  const [formType, setFormType] = useState<number>();
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>(
    undefined
  );
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(TimeFilter.ALL_TIME);

  // Values for stat cards above the documents table
  const statsMap = useMemo(() => {
    const box2and3notAvailable = status && !["SUBMITTED"].includes(status); // If submission type is set and not "Submitted"
    return [
      {
        key: "totalDocuments",
        name: getDocumentsStatsNameIfSubmissionsFilterApplied(
          dateRange,
          timeFilter,
          documentsTerm
        ),
      },
      {
        key: "averageTimeToComplete",
        name: "Average Time to Complete",
        notAvailable: box2and3notAvailable,
        type: "time",
        valueName: "minutes",
      },
      {
        key: "completeRateOfSubmissions",
        name: "Completion Rate of Submissions",
        notAvailable: box2and3notAvailable,
        valueName: "percent",
      },
    ];
  }, [status, dateRange, timeFilter]);

  const { minSubmissionDate, maxSubmissionDate } =
    getMinMaxSubmissionDatesFromTimeFilter(timeFilter, dateRange);

  const userStatsCall = useAPI(API.getParticipantSubmissionStats, {
    clientGroupId: group,
    formTypeId: formType,
    maxSubmissionDate,
    minSubmissionDate,
    participantId,
    query: searchQuery,
    status: status,
  });

  const userCall = useAPI(API.getUserByParticipantId, { participantId });
  const formTypeStatsCall = useAPI(API.getParticipantFormSubmissionStats, {
    participantId,
  });

  // On filter change, get documents/aggregate stats and reset `currentPage`
  useEffect(() => {
    setDocumentsPage(0);
  }, [
    group,
    formType,
    maxSubmissionDate,
    minSubmissionDate,
    status,
    participantId,
    searchQuery,
    documentsSize,
    sorting,
  ]);

  const documentsCall = useAPI(API.getDocuments, {
    clientGroupId: group,
    formTypeId: formType,
    maxSubmissionDate,
    minSubmissionDate,
    status,
    ownerId: participantId,
    query: searchQuery,
    page: documentsPage,
    size: documentsSize,
    sort: [sorting.primarySort.type, sorting.primarySort.direction],
  });

  const participant = useMemo(
    () => userCall.data?.participant,
    [userCall.data]
  );
  const supervisor = useMemo(() => userCall.data?.supervisor, [userCall.data]);
  const clientGroups = useMemo(
    () => userCall.data?.clientGroups,
    [userCall.data]
  );

  // Is sticky header showing
  const mainContent = document.getElementById("contentWrapper");
  const [stickyHeaderIsShowing, setStickyHeaderIsShowing] = useState(false);
  mainContent &&
    mainContent.addEventListener(
      "scroll",
      () => {
        if (mainContent.scrollTop > 200) {
          if (!stickyHeaderIsShowing) setStickyHeaderIsShowing(true);
        } else {
          if (stickyHeaderIsShowing) setStickyHeaderIsShowing(false);
        }
      },
      true
    );

  // Action menu
  // const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const [showDocumentDrawer, setShowDocumentDrawer] = useState<
    DocumentDrawerSection | undefined
  >();
  const [popperIsOpen, setPopperIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Delete document
  const [deleteDocumentError, setDeleteDocumentError] = useState(false);
  const [deleteDocumentIsLoading, setDeleteDocumentIsLoading] = useState(false);
  const [
    deleteDocumentConfirmationModalIsVisible,
    setDeleteDocumentConfirmationModalIsVisible,
  ] = useState(false);
  const [
    deleteDocumentOutcomeModalIsVisible,
    setDeleteDocumentOutcomeModalIsVisible,
  ] = useState(false);
  const [anchorDocument, setAnchorDocument] = useState<DocumentSummaryVm>();

  // View
  const viewDocument = useCallback(() => {
    // In this table, we'll always view the document in sidebar first -- GK
    if (anchorDocument && anchorDocument.id) {
      setPopperIsOpen(false);
      setShowDocumentDrawer(DocumentDrawerSection.Document); // View doc
    }
  }, [anchorEl]);

  // Unmount
  useEffect(() => {
    return () => {
      mainContent &&
        mainContent.removeEventListener("scroll", () => true, true);
    };
  }, []);

  return (
    <>
      {/* Drawer has Document History, Delete Document and View Document  */}
      {showDocumentDrawer && anchorDocument?.id && (
        <DocumentDrawer
          closeDrawer={() => {
            setShowDocumentDrawer(undefined);
          }}
          documentId={anchorDocument.id}
          show={showDocumentDrawer}
          onClose={() => {
            setShowDocumentDrawer(undefined);
          }}
        />
      )}
      {/* Modal for deleting a user */}
      <Modal
        qa={`${Components.ViewUser}-${ElementType.Modal}-deleteDocument-${
          anchorDocument && anchorDocument.id
        }`}
        border="none"
        open={deleteDocumentConfirmationModalIsVisible}
        handleClose={() => {
          setDeleteDocumentConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <DeleteDocumentConfirmation
            // user data
            created={anchorDocument && anchorDocument.dateCreated}
            group={
              anchorDocument &&
              anchorDocument.clientGroup &&
              anchorDocument.clientGroup.name
            }
            title={anchorDocument && anchorDocument.title}
            // rest
            loading={deleteDocumentIsLoading}
            onCancel={() => setDeleteDocumentConfirmationModalIsVisible(false)}
            onSubmit={async () => {
              setDeleteDocumentIsLoading(true);
              try {
                if (anchorDocument && anchorDocument.id) {
                  await API.deleteDocumentById({ id: anchorDocument.id });
                  setDeleteDocumentError(false);
                  setShowDocumentDrawer(undefined); // Close drawer if document is deleted
                }
              } catch (e) {
                setDeleteDocumentError(true);
              } finally {
                setDeleteDocumentIsLoading(false);
                setDeleteDocumentConfirmationModalIsVisible(false);
                setDeleteDocumentOutcomeModalIsVisible(true);
              }
            }}
            qa={`${Components.ViewUser}-${anchorDocument && anchorDocument.id}`}
          />
        }
      />
      {/* Modal for the outcome when deleting a document */}
      <Modal
        border="none"
        open={deleteDocumentOutcomeModalIsVisible}
        handleClose={() => {
          !deleteDocumentError && documentsCall.refresh();
          setDeleteDocumentError(false);
          setDeleteDocumentOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <UserStyles.ModalPromptWrapper>
            <UserStyles.ModalPromptTitle>
              {deleteDocumentError
                ? "Error: Document could not be deleted"
                : "Document has been deleted"}
            </UserStyles.ModalPromptTitle>
            <UserStyles.ModalPromptSubtitle>
              {deleteDocumentError
                ? `A problem has occurred while attempting to delete the selected ${documentTermLower}. Try again later or contact your system administrator for support.`
                : `The selected ${documentTermLower} has been successfully deleted. ${documentTerm} will no longer impact relevant reporting.`}
            </UserStyles.ModalPromptSubtitle>
            <UserStyles.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  !deleteDocumentError && documentsCall.refresh();
                  setDeleteDocumentError(false);
                  setDeleteDocumentOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </UserStyles.DisableUserButtonsWrapper>
            <UserStyles.ModalPromptCloseIcon
              onClick={() => {
                !deleteDocumentError && documentsCall.refresh();
                setDeleteDocumentError(false);
                setDeleteDocumentOutcomeModalIsVisible(false);
              }}
            />
          </UserStyles.ModalPromptWrapper>
        }
      />
      <Popper
        qa={`${Components.ViewUser}-${ElementType.MenuModal}-${
          anchorDocument && anchorDocument.id
        }`}
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <PopperWrapper>
          <PopperRow
            data-testid={`${Components.ViewUser}-${
              ElementType.MenuItem
            }-viewDocuments-${anchorDocument && anchorDocument.id}`}
            onClick={() => viewDocument()}
          >
            <Text fontSize="0.9rem">View {documentTermLower}</Text>
            <PopperChevron />
          </PopperRow>
          <PopperRow
            data-testid={`${Components.ViewUser}-${
              ElementType.MenuItem
            }-viewDocumentsHistory-${anchorDocument && anchorDocument.id}`}
            onClick={() => {
              setPopperIsOpen(false);
              // setSelectedDocumentId(anchorEl && anchorEl.id);
              setShowDocumentDrawer(DocumentDrawerSection.History);
            }}
          >
            <Text fontSize="0.9rem">View {documentTermLower} history</Text>
            <PopperChevron />
          </PopperRow>
          <WriteContent roles={userWriteRoles}>
            <PopperRow
              data-testid={`${Components.ViewUser}-${
                ElementType.MenuItem
              }-deleteDocument-${anchorDocument && anchorDocument.id}`}
            >
              <DeleteDocumentMenuItem
                loading={deleteDocumentIsLoading}
                error={deleteDocumentError}
                closeMenu={() => setPopperIsOpen(false)}
                openConfirmationModal={() =>
                  setDeleteDocumentConfirmationModalIsVisible(true)
                }
              />
              <PopperChevron />
            </PopperRow>
          </WriteContent>
        </PopperWrapper>
      </Popper>
      {/* 
        @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
        @TODO: Update with a better solution - Trevor
      */}
      <PageContent id="mainContent">
        <div className="col-12">
          <Breadcrumbs
            paths={[
              {
                pathName: "People",
              },
              {
                pathName: "Users",
                href: "/people/users",
              },
              {
                pathName: `User Profile: ${userCall.data?.name || ""}`,
                href: `/people/users/view/${participantId}`,
              },
            ]}
          />
          {/* // Add fixed header here  */}
          {stickyHeaderIsShowing && (
            <StickyProfileHeader
              email={userCall.data?.email}
              firstName={userCall.data?.firstName}
              icon={
                <i
                  className="icon-icons8-pencil ml-2"
                  style={{ fontSize: "0.66rem", cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/people/users/edit-user/${participantId}`)
                  }
                />
              }
              lastName={userCall.data?.lastName}
              primaryGroup={userCall.data?.primaryGroup?.name}
              supervisor={supervisor?.name}
            />
          )}
        </div>
        <S.ProfileWrapper>
          {/* Profile information  */}
          <S.ProfileInformationWrapper>
            <S.ProfileName
              data-testid={`${Page.Desktop}-${Components.ViewUser}`}
            >
              {userCall.data && (
                <>
                  {userCall.data.name}
                  <WriteContent roles={userWriteRoles}>
                    <i
                      data-testid={`${Components.ViewUser}-${ElementType.Icon}-editPencil`}
                      className="icon-icons8-pencil ml-2"
                      style={{ fontSize: "1rem", cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/people/users/edit-user/${participantId}`)
                      }
                    />
                  </WriteContent>
                </>
              )}
            </S.ProfileName>
            <S.ProfileContentWrapper>
              <S.ProfileContent>
                <S.ProfileContentHeader>Group</S.ProfileContentHeader>
                <S.ProfileContentValue>
                  {(participant &&
                    participant.id &&
                    listGroups(
                      clientGroups,
                      userCall.data?.primaryGroup?.id
                    )) ||
                    "-"}
                </S.ProfileContentValue>
              </S.ProfileContent>
              <S.ProfileContent>
                <S.ProfileContentHeader>
                  {supervisorTerm}
                </S.ProfileContentHeader>
                <S.ProfileContentValue>
                  {(supervisor && supervisor.name) || "-"}
                </S.ProfileContentValue>
              </S.ProfileContent>
              <S.ProfileContent>
                <S.ProfileContentHeader>Email</S.ProfileContentHeader>
                <S.ProfileContentValue active>
                  <a href={`mailto: ${userCall.data?.email || ""}`}>
                    {userCall.data?.email || ""}
                  </a>
                </S.ProfileContentValue>
              </S.ProfileContent>
            </S.ProfileContentWrapper>
          </S.ProfileInformationWrapper>

          {/* Submissions by form */}
          <SubmissionsCard
            submissions={formTypeStatsCall.data || []}
            title={`Total Created ${documentsTerm} by Form Type`}
          />
        </S.ProfileWrapper>

        <div className="col-12 mt-5">
          <TabGroup
            variant="white"
            tabs={[
              {
                label: documentsTerm,
                tabId: documentsTerm,
                tabPanelId: documentsTerm,
                tabPanelContent: (
                  <div className="row">
                    {/* Filters */}
                    <div className="col-12 d-flex flex-row justify-content-between align-items-center mt-3">
                      <div className="mr-2">
                        <FilterSelect
                          qa={`${Components.ViewUser}-${ElementType.SelectInput}-groupsFilter`}
                          name="groups"
                          value={group}
                          label=""
                          placeholder="All Groups"
                          options={generateGroupOptions(
                            allGroupCall.data?.content
                          )}
                          onChange={(option) => setGroup(option?.value)}
                        />
                      </div>
                      <div className="ml-2 mr-2">
                        <FilterSelect
                          qa={`${Components.ViewUser}-${ElementType.SelectInput}-formTypeFilter`}
                          name="formType"
                          value={formType}
                          label=""
                          placeholder="All Form Types"
                          options={formTypeOptions(formTypesCall.data || [])}
                          onChange={(option) => setFormType(option?.value)}
                        />
                      </div>
                      <div className="ml-2 mr-2">
                        <FilterSelect
                          qa={`${Components.ViewUser}-${ElementType.SelectInput}-statusesFilter`}
                          name="status"
                          placeholder="All Statuses"
                          value={status}
                          label=""
                          options={statusOptions}
                          onChange={(option) => setStatus(option?.value)}
                        />
                      </div>
                      <div className="ml-2 mr-2">
                        <FilterSelect
                          qa={`${Components.ViewUser}-${ElementType.SelectInput}-timeFilter`}
                          name="timeFilter"
                          value={timeFilter}
                          label=""
                          options={timeFilterOptions}
                          onChange={(option) =>
                            setTimeFilter(option?.value || TimeFilter.ALL_TIME)
                          }
                        />
                      </div>
                      <SearchBar
                        qa={`${Components.ViewUser}-${ElementType.TextInput}-searchBar`}
                        onSearch={setSearchQuery}
                      />
                    </div>
                    {timeFilter === "CUSTOM_RANGE" && (
                      <S.DateRangeWrapper>
                        <span
                          className="visually-hidden"
                          id="custom-range-label"
                        >
                          Custom Range
                        </span>
                        <DateRange
                          handleChange={setDateRange}
                          labelId="custom-range-label"
                          name="customRange"
                          onClear={() => {
                            setDateRange(undefined);
                            setTimeFilter(TimeFilter.ALL_TIME);
                          }}
                        />
                      </S.DateRangeWrapper>
                    )}
                    {/* Stats */}
                    <Loader loading={userStatsCall.isLoading}>
                      <StatsWrapper>
                        {statsMap.map((e, i) => (
                          <StatsCard
                            header={
                              e.name === "Documents"
                                ? getDocumentsStatsNameIfSubmissionsFilterApplied(
                                    dateRange,
                                    timeFilter,
                                    documentsTerm
                                  )
                                : e.name
                            }
                            stretch
                            key={i}
                            notAvailable={!!e.notAvailable}
                            type={e.type}
                            value={getStat(e.key, userStatsCall.data)}
                            valueName={e.valueName}
                          />
                        ))}
                      </StatsWrapper>
                    </Loader>
                    {/* Documents  */}
                    <div className="col-12 mt-3 mb-3">
                      <Loader loading={documentsCall.isLoading}>
                        <TableSummary
                          pageSize={documentsCall.data?.size || documentsSize}
                          currentPage={
                            documentsCall.data?.number || documentsPage
                          }
                          totalElements={documentsCall.data?.totalElements}
                          ofWhat={documentsTermLower}
                          exports={["print", "xls", "csv"]}
                          onExport={API.downloadDocuments}
                          exportParams={{
                            clientGroupId: group,
                            formTypeId: formType,
                            maxSubmissionDate,
                            minSubmissionDate,
                            status,
                            ownerId: participantId,
                            query: searchQuery,
                          }}
                        />
                        <Table>
                          <TableHead>
                            <TableRow noBottomBorder>
                              <TableCell
                                qa={`${Components.ViewUser}-${ElementType.TableCell}-sort-documents`}
                                active={sorting.primarySort.type === "title"}
                                onClick={(dir) => sortBy("title", dir)}
                                sortDirection={sorting.title.direction}
                                minWidth="125px"
                              >
                                <b>{documentTerm}</b>
                              </TableCell>
                              <TableCell
                                qa={`${Components.ViewUser}-${ElementType.TableCell}-sort-group`}
                                active={
                                  sorting.primarySort.type ===
                                  "clientGroup.name"
                                }
                                minWidth="150px"
                                onClick={(dir) =>
                                  sortBy("clientGroup.name", dir)
                                }
                                sortDirection={
                                  sorting["clientGroup.name"].direction
                                }
                                width="175px"
                              >
                                <b>Group</b>
                              </TableCell>
                              <TableCell
                                qa={`${Components.ViewUser}-${ElementType.TableCell}-sort-formType`}
                                active={
                                  sorting.primarySort.type === "formTypeIds"
                                }
                                minWidth="155px"
                                onClick={(dir) => sortBy("formTypeIds", dir)}
                                sortDirection={sorting["formTypeIds"].direction}
                                width="155px"
                              >
                                <b>Type</b>
                              </TableCell>
                              <TableCell
                                qa={`${Components.ViewUser}-${ElementType.TableCell}-sort-created`}
                                active={
                                  sorting.primarySort.type === "startDate"
                                }
                                minWidth="110px"
                                onClick={(dir) => sortBy("startDate", dir)}
                                sortDirection={sorting.startDate.direction}
                                width="110px"
                              >
                                <b>Created</b>
                              </TableCell>
                              <TableCell
                                qa={`${Components.ViewUser}-${ElementType.TableCell}-sort-submitted`}
                                active={
                                  sorting.primarySort.type === "submissionDate"
                                }
                                minWidth="130px"
                                onClick={(dir) => sortBy("submissionDate", dir)}
                                sortDirection={sorting.submissionDate.direction}
                                width="130px"
                              >
                                <b>Submitted</b>
                              </TableCell>
                              <TableCell
                                qa={`${Components.ViewUser}-${ElementType.TableCell}-sort-status`}
                                active={sorting.primarySort.type === "status"}
                                minWidth="100px"
                                onClick={(dir) => sortBy("status", dir)}
                                sortDirection={sorting.status.direction}
                                width="100px" // `In Progress` is on 2 lines unfortunately
                              >
                                <b>Status</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {documentsCall.data &&
                              documentsCall.data.content.map((document) => (
                                <TableRow
                                  key={document.id}
                                  qa={`${Components.ViewUser}-${ElementType.TableRow}-${document.id}`}
                                >
                                  <TableCell
                                    qa={`${Components.ViewUser}-${ElementType.TableCell}-name-${document.id}`}
                                  >
                                    <Text
                                      data-testid={`${Components.ViewUser}-${ElementType.Link}-viewDocument-${document.id}`}
                                      fontSize={"0.875rem"}
                                      fontWeight={400}
                                      lineClamp={2}
                                      textDecoration="underline"
                                      onClick={() => {
                                        setAnchorDocument(document);
                                        setShowDocumentDrawer(
                                          DocumentDrawerSection.Document
                                        ); // View document
                                      }}
                                    >
                                      {`${document.title}`}
                                    </Text>
                                    <S.IdLabel>
                                      {`ID: ${document.id || "-"}`}
                                    </S.IdLabel>
                                  </TableCell>
                                  <TableCell
                                    qa={`${Components.ViewUser}-${ElementType.TableCell}-group-${document.id}`}
                                  >
                                    <S.TableData>
                                      {(document.clientGroup &&
                                        document.clientGroup.name) ||
                                        "-"}
                                    </S.TableData>
                                  </TableCell>
                                  <TableCell
                                    qa={`${Components.ViewUser}-${ElementType.TableCell}-type-${document.id}`}
                                  >
                                    <S.TableData>
                                      {document.formSummary &&
                                        document.formSummary.type &&
                                        document.formSummary.type.name}
                                    </S.TableData>
                                  </TableCell>
                                  <TableCell
                                    qa={`${Components.ViewUser}-${ElementType.TableCell}-createdDate-${document.id}`}
                                  >
                                    <S.TableData>
                                      {`${moment(document.startDate).format(
                                        "MM/DD/YY"
                                      )}`}
                                    </S.TableData>
                                    <S.Time>
                                      {`${moment(document.startDate).format(
                                        "hh:mma"
                                      )}`}
                                    </S.Time>
                                  </TableCell>
                                  <TableCell
                                    qa={`${Components.ViewUser}-${ElementType.TableCell}-submittedDate-${document.id}`}
                                  >
                                    <S.TableData>
                                      {`${moment(
                                        document.submissionDate
                                      ).format("MM/DD/YY")}`}
                                    </S.TableData>
                                    <S.Time>
                                      {`${moment(
                                        document.submissionDate
                                      ).format("hh:mma")}`}
                                    </S.Time>
                                  </TableCell>
                                  <TableCell
                                    qa={`${Components.ViewUser}-${ElementType.TableCell}-status-${document.id}`}
                                  >
                                    <div className="d-flex flex-row justify-content-between">
                                      <DefaultCell
                                        value={prettifyDocumentStatus(
                                          document.status
                                        )}
                                      />
                                      {/* Context menu and sidebar */}
                                      <ClickAwayListener
                                        onClickAway={() => {
                                          if (popperIsOpen) {
                                            setPopperIsOpen(false);
                                          }
                                        }}
                                      >
                                        <div className="d-flex flex-row justify-content-end">
                                          <ActionButton
                                            qa={`${Components.ViewUser}-${ElementType.Menu}-${document.id}`}
                                            label="menu"
                                            onClick={(e) => {
                                              setAnchorEl(e.currentTarget);
                                              setAnchorDocument(document);
                                              setTimeout(() => {
                                                setPopperIsOpen(true);
                                              }, 100);
                                            }}
                                          />
                                        </div>
                                      </ClickAwayListener>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        {/* // Pagination */}
                        {documentsCall.data && (
                          <PaginationWrapper>
                            <RowsPerPage
                              qaBase={`${Components.ViewUser}-view`}
                              pageSize={
                                documentsCall.data?.size || documentsSize
                              }
                              onClick={(size) => setDocumentsSize(size)}
                            />
                            <Pagination
                              qaBase={`${Components.ViewUser}-pagination`}
                              currentPage={
                                documentsCall.data?.number || documentsPage
                              }
                              onClick={(page) => setDocumentsPage(page)}
                              totalPages={documentsCall.data?.totalPages}
                            />
                            <BackToTop
                              qa={`${Components.ViewUser}-${ElementType.Link}-backToTop`}
                            />
                          </PaginationWrapper>
                        )}
                      </Loader>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </PageContent>
    </>
  );
};
