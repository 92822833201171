import { CircularProgress } from "@mui/material";
import React from "react";

import { ProgressStyle } from "./types";

interface ProgressProps {
  createStyles: ProgressStyle;
}

export const Progress = ({ createStyles }: ProgressProps) => {
  return (
    <CircularProgress
      className={createStyles.progress}
      classes={{ root: "root" }}
      size={20}
    />
  );
};
