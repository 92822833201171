import { PieTooltipProps } from "@nivo/pie";
import React, { FC } from "react";
import { QAProps } from "../../../qa-slugs";
import { PieChartDatum } from "./PieChartTypes";
import styles from "./PieChartTooltip.module.scss";

export const PieChartTooltip: FC<PieTooltipProps<PieChartDatum> & QAProps> = ({
  datum: { color, data, id, value },
  qa,
}) => {
  return (
    <div className={styles.tooltip} data-testid={`${qa}-${data.qa}`}>
      <span
        className={styles.tooltipColorSwatch}
        style={{
          background: color,
        }}
      ></span>
      <span>
        {id}: <strong>{value}</strong>
      </span>
    </div>
  );
};
