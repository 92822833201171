import React, { Dispatch, FC, SetStateAction } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { useNavigate } from "react-router-dom";

import styles from "./MyDocuments.module.scss";
import documentsStyles from "shared/src/components/Documents/Documents.module.scss";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import Loader from "shared/src/components/Loader/Loader";
import DocumentsList from "./DocumentsList";
import { DocumentScreenTerms } from "../types";
import {
  API,
  DocumentRequestParameters,
  DocumentSummaryVm,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";
import DesktopSearchFilters from "./DesktopSearchFilters";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { AppliedFiltersProps } from "./types";

interface MyDocumentsProps {
  openContextMenu: any;
  documents: DocumentSummaryVm[];
  onLoadMore: () => void;
  isLoadingSearchInfinite: boolean;
  isSearchLoading: boolean;
  lastPageSearch: boolean;
  searchDocumentsError: boolean;
  terms: DocumentScreenTerms;
  onOpenNewDocumentDrawer: () => void;
  filters: API.GetDocumentsArgs;
  formTypes: FormTypeDTO[];
  onFilterChange: (params: Partial<DocumentRequestParameters>) => void;
  onSearch: (query?: string) => void;
  query?: string;
  onOpenFilterDrawer?: () => void;
  appliedFilters: AppliedFiltersProps;
  setAppliedFilters: Dispatch<SetStateAction<AppliedFiltersProps>>;
}

const MyDocuments: FC<MyDocumentsProps> = ({
  documents,
  onLoadMore,
  openContextMenu,
  isLoadingSearchInfinite,
  isSearchLoading,
  lastPageSearch,
  searchDocumentsError,
  terms,
  onOpenNewDocumentDrawer,
  filters,
  formTypes,
  onFilterChange,
  onSearch,
  query,
  onOpenFilterDrawer,
  appliedFilters,
  setAppliedFilters,
}) => {
  const navigate = useNavigate();

  const loadMore = (isVisible: boolean) => {
    if (isVisible) {
      onLoadMore();
    }
  };

  return (
    <div className={styles.myDocuments}>
      <div
        className={joinClassNames(
          documentsStyles.documentsListHeader,
          documentsStyles.documents
        )}
      >
        All {terms.document.plural}
      </div>

      {searchDocumentsError && <ErrorText>Error fetching Documents</ErrorText>}

      <div className={styles.documentsListContainer}>
        <DesktopSearchFilters
          filters={filters}
          formTypes={formTypes || []}
          onFilterChange={onFilterChange}
          onSearch={onSearch}
          query={query}
          onOpenFilterDrawer={onOpenFilterDrawer}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
        {/** @TODO future ticket of CORE-1514 of redesign to make pagination instead of lazy loading */}
        {/* <TableSummary
          pageSize={0}
          currentPage={0}
          totalElements={0}
          ofWhat={terms.document.plural}
        /> */}
        <Loader loading={isSearchLoading}>
          <DocumentsList
            onOpenNewDocumentDrawer={onOpenNewDocumentDrawer}
            handleOnClick={(documentId) => navigate(`/document/${documentId}`)}
            openContextMenu={(document) => openContextMenu(document)}
            documents={documents}
            terms={terms}
          />

          {!lastPageSearch && (
            <Loader loading={isLoadingSearchInfinite}>
              <VisibilitySensor onChange={loadMore}>
                <div>Loading More</div>
              </VisibilitySensor>
            </Loader>
          )}
        </Loader>
      </div>
    </div>
  );
};

export default MyDocuments;
