import React from "react";

import bs from "../../styles.module.scss";
import s from "../../create/validation/styles.module.scss";
import { TextButton } from "../../../../common/Button";
import { joinClassNames } from "../../../../../helpers/theme.helpers";
import useValidation from "../../create/validation/useValidation";

interface Props {
  onClick: () => void;
  title: string;
  error: string;
}

const ValidationLink = ({ onClick, title, error }: Props) => {
  let link: string | React.ReactNode = error;
  const { alertLevel } = useValidation();

  if (title) {
    link = (
      <>
        <b>{title}:</b> {error}
      </>
    );
  }

  return (
    <TextButton
      className={joinClassNames(
        bs.alertButton,
        bs.lowercase,
        alertLevel === "error" ? s.validationLinkError : s.validationLink
      )}
      variant="link"
      onClick={onClick}
    >
      {link}
    </TextButton>
  );
};

export default ValidationLink;
