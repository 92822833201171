import React from "react";
import { Link } from "../Link/Link";
import { ContentWrapper } from "../Wrappers/Wrappers";
import styles from "./routes.module.scss";
import { Components, Page } from "../../qa-slugs";

export const RouteNotFound = () => {
  return (
    <ContentWrapper>
      <div
        className={styles.warningWrapper}
        data-testid={`${Page.Desktop}-${Components.NotFound}`}
      >
        You are trying to view a page that does not exist. Click
        <Link to="/">&nbsp;here&nbsp;</Link>to return to Dashboard.
      </div>
    </ContentWrapper>
  );
};
