import styled from "styled-components";

import { devices } from "shared/src/themes/mediaQueries";
import { remCalc } from "shared/src/themes/helpers";

export const FeedbackTypeWrapper = styled.div`
  width: 50%;
  @media (max-width: ${devices.maxPhone}px) {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1.05rem;
  margin-top: 1.05rem;

  @media (max-width: ${devices.maxPhone}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Icon = styled.i`
  font-size: ${remCalc(24)};
  left: 16px;
  position: absolute;
`;
