import React, { FC } from "react";
import { Button } from "../../../components/Button/Button";
import styles from "./ActionButtons.module.scss";
import { BackArrow } from "../../../assets/svg/BackArrow";
import { Save } from "../../../assets/svg/Save";
import { NextArrow } from "../../../assets/svg/NextArrow";
import { useDocumentSection } from "./hooks/useDocumentSection";
import { useSectionalContext } from "./hooks/useSectionalContext";
import { QAProps } from "../../../qa-slugs";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { Submit } from "../../../assets/svg/Submit";
import { useFormikContext } from "formik";
import {
  DocumentSubmissionType,
  DocumentVm,
  SubmissionType,
} from "@rtslabs/field1st-fe-common";
import { validate } from "../DocumentForm/validation";
import { isEmpty } from "lodash";
import { getIsSectionValidated } from "../document.helpers";

interface ActionButtonsProps extends QAProps {
  documentTerm: string;
}

export const ActionButtons: FC<ActionButtonsProps> = ({
  documentTerm,
  qa = "DocSec-actionButtons",
}) => {
  const { currentSection, visibleSections } = useSectionalContext();
  const { values, handleSubmit, setFieldValue, isSubmitting, setErrors } =
    useFormikContext<DocumentVm>();
  const { getCurrentSectionIndex, handleBackSection, handleNextSection } =
    useDocumentSection();

  const curSecIndex = getCurrentSectionIndex();
  const hasBackSection = !!visibleSections[curSecIndex - 1];
  const hasNextSection = !!visibleSections[curSecIndex + 1];
  const isSectionValidated = getIsSectionValidated(currentSection, values);

  const onSubmit = (submissionType: SubmissionType): void => {
    if (submissionType === "SUBMIT") {
      const fullDocValidation = validate(values, undefined, true);

      if (!isEmpty(fullDocValidation)) {
        return setErrors(fullDocValidation);
      }
    }

    setFieldValue("submissionType", submissionType);
    setTimeout(handleSubmit, 0);
  };

  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.left} data-testid={`${qa}-back`}>
        <Button
          disabled={!hasBackSection}
          onClick={handleBackSection}
          className={styles.backButton}
        >
          <BackArrow className={styles.backIcon} />
          <span className={styles.backText}>Back</span>
        </Button>
      </div>
      <div className={styles.right} data-testid={`${qa}-save-next`}>
        <Button
          disabled={isSubmitting}
          onClick={() => onSubmit(DocumentSubmissionType.SAVE_DRAFT)}
          className={styles.saveButton}
        >
          <Save className={styles.saveIcon} />
          <span className={styles.saveText}>Save {documentTerm}</span>
        </Button>
        {hasNextSection && (
          <Button
            className={joinClassNames(
              styles.nextButton,
              !isSectionValidated && styles.notValidated
            )}
            onClick={handleNextSection}
          >
            <span className={styles.nextText}>Next</span>{" "}
            <NextArrow className={styles.nextIcon} />
          </Button>
        )}
        {!hasNextSection && (
          <Button
            disabled={isSubmitting}
            className={joinClassNames(
              styles.nextButton,
              !isSectionValidated && styles.notValidated
            )}
            onClick={() => onSubmit(DocumentSubmissionType.SUBMIT)}
          >
            <span className={styles.nextText}>Submit</span>
            <Submit className={styles.submitIcon} />
          </Button>
        )}
      </div>
    </div>
  );
};
