import React from "react";

import {
  DisableUserButtonsWrapper,
  ModalPromptCloseIcon,
  ModalPromptSubtitle,
  ModalPromptTitle,
  ModalPromptWrapper,
} from "../../../clientAdmin/users/styles";
import { SubmitButton } from "shared/src/components/clientAdmin/styles";
import { TagDTO } from "@rtslabs/field1st-fe-common";
import { TagStatusModalProps } from "./TagStatusModal";
import { actionPerformed } from "../helpers";

interface Props {
  archived: TagDTO["archived"];
  onClose: () => void;
  status: TagStatusModalProps["status"];
}

export const TagStatusOutcomeModal = ({ archived, status, onClose }: Props) => {
  const error = status === "failure";

  return (
    <>
      <ModalPromptWrapper>
        <ModalPromptTitle>
          {error
            ? `Error - Unable to ${actionPerformed(archived, "present")} tag`
            : `Tag has been ${actionPerformed(archived, "past")}`}
        </ModalPromptTitle>
        <ModalPromptSubtitle>
          {error
            ? `The system encountered an error while trying to ${actionPerformed(
                archived,
                "present"
              )} the selected tag`
            : `The selected tag has successfully been ${actionPerformed(
                archived,
                "past"
              )}`}
        </ModalPromptSubtitle>
        <DisableUserButtonsWrapper>
          <SubmitButton onClick={onClose}>
            <span>close </span>
          </SubmitButton>
        </DisableUserButtonsWrapper>
        <ModalPromptCloseIcon onClick={onClose} />
      </ModalPromptWrapper>
    </>
  );
};
