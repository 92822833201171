import React, { FC } from "react";
import Label from "../../Label/Label";
import styles from "./ReadOnly.module.scss";

interface Props {
  label: string;
  value: string | JSX.Element;
  required?: boolean;
}

export const ReadOnlyResponse: FC<Props> = ({ label, value, required }) => {
  return (
    <div className={styles.response}>
      <Label
        containerClassName={styles.labelWrapper}
        className={styles.label}
        required={required}
      >
        {label}
      </Label>
      <div className={styles.value}>{value}</div>
    </div>
  );
};
