import React, { ReactElement, useState } from "react";
import {
  DataSourceItem,
  SubmitButtonLabel,
} from "../../ItemSelectorDrawer/ItemSelectorForm";
import { ItemSelectorDrawer } from "../../ItemSelectorDrawer/ItemSelectorDrawer";
import {
  QuestionAnswerSourceDTO,
  useDebouncedValue,
  useDataSourceValues,
  ParticipantDTO,
  WorkLocationDTO,
  API,
  DataSourceType,
} from "@rtslabs/field1st-fe-common";
import { GetDataSourceValuesArgs } from "@rtslabs/field1st-fe-common/dist/main/api";
import { get } from "lodash";
import { QAProps } from "../../../qa-slugs";

interface DataSourceDrawerProps extends QAProps {
  allowMultipleAnswers?: boolean;
  answerSource: QuestionAnswerSourceDTO;
  isOpen: boolean;
  onChangeOpen: (isOpen: boolean) => void;
  onSubmit: (values: DataSourceItem[]) => void;
  selected?: DataSourceItem[];
  submitButtonLabel: SubmitButtonLabel;
}

function DataSourceDrawer<T = WorkLocationDTO | ParticipantDTO>({
  allowMultipleAnswers,
  answerSource,
  isOpen,
  onChangeOpen,
  onSubmit,
  qa,
  selected,
  submitButtonLabel,
}: DataSourceDrawerProps): ReactElement {
  const [query, setQuery] = useState<string>();
  const debouncedQuery = useDebouncedValue(query, 600);

  const dataSourceKey = answerSource.dataSourceKey;
  let dataSourceUIListItems;
  let isLastPage = true;
  let isLoading = false;
  let isPageLoading = false;
  let loadMore: (() => void) | undefined;
  if (dataSourceKey) {
    const results = useDataSourceValues<T, GetDataSourceValuesArgs["sort"]>({
      dataSourceKey,
      getDataSourceValues: API.getDataSourceValues,
      query: debouncedQuery,
    });
    ({ dataSourceUIListItems, isLastPage, isLoading, isPageLoading, loadMore } =
      results);
  }

  const detailedSearch = answerSource.properties?.detailedSearch;
  const title = detailedSearch?.title || "...";
  const subTitle = detailedSearch?.subtitle || "...";
  const searchPlaceholder = detailedSearch?.searchBy || "...";
  const listTitle = detailedSearch?.infiniteListTitle || "";

  function handleChangeOpen(open: boolean) {
    if (!open) {
      setQuery(undefined);
    }
    onChangeOpen(open);
  }

  function handleChangeSearch(input: string) {
    setQuery(input);
  }

  function handleSubmit(values: DataSourceItem[]) {
    setQuery(undefined);
    onSubmit(values);
  }

  const options: DataSourceItem[] =
    dataSourceUIListItems?.map((i) => ({
      ...i,
      title: get(i.content, answerSource.properties?.answerField || "name"),
      value: i.id,
      associatedLocation:
        dataSourceKey === DataSourceType.WORK_LOCATION
          ? get(
              i.content,
              answerSource.properties?.associatedLocationField || ""
            )
          : undefined,
    })) ?? [];

  return (
    <ItemSelectorDrawer
      handleChangeOpen={handleChangeOpen}
      handleChangeSearch={handleChangeSearch}
      handleSubmit={handleSubmit}
      hasMultiSelect={allowMultipleAnswers}
      isLastPage={isLastPage}
      isLoadingMore={isLoading}
      isLoadingSearchResults={isPageLoading}
      isOpen={isOpen}
      listTitle={listTitle}
      name={`${title}_${answerSource.id}`}
      noResultsMessage="No Results Found"
      onLoadMore={loadMore}
      searchLabel={searchPlaceholder || "Search"}
      searchPlaceholder={
        searchPlaceholder ||
        answerSource?.properties?.detailedSearch?.searchBy ||
        "Search..."
      }
      options={options}
      dataSourceKey={dataSourceKey || ""}
      answerSourceFields={{ answerField: "fullName" }}
      qa={qa}
      selected={selected || []}
      submitButtonLabel={submitButtonLabel}
      subtitle={subTitle}
      title={title}
      searched={!!query && query.length > 2}
    />
  );
}

export default DataSourceDrawer;
