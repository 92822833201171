// Local
import {
  API,
  FilterParams,
  Pagination as PaginationType,
  PaginationParams,
  TagDTO,
} from "@rtslabs/field1st-fe-common";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Common
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import { Button } from "shared/src/components/Button/Button";
import Loader from "shared/src/components/Loader/Loader";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import usePrevious from "shared/src/util/hooks/usePrevious";
import { tagWriteRoles } from "../../../../routes/constants/permissionSets";
// Styles from resources
import {
  PageHeader,
  PageTitle,
} from "shared/src/components/clientAdmin/styles";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import TagFilters from "../components/TagFilters";
import { TagLibraryTable } from "../components/TagLibraryTable";
import TagLibraryTablePagination from "../components/TagLibraryTablePagination";

const initialPaginationParams = {
  page: 0,
  size: 10,
  sort: "name,asc",
};

export const TagLibrary = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState<FilterParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationValues, setPaginationValues] = useState<
    Omit<PaginationType, "content"> | undefined
  >(undefined);
  const [params, setParams] = useState<PaginationParams>(
    initialPaginationParams
  );
  const [tags, setTags] = useState<TagDTO[]>([]);

  const handleUpdateFilters = (filters) => {
    setFilters(filters);
    setParams({ ...params, page: 0 });
  };

  const handleSort = (sort: string) => setParams({ ...params, sort });

  const fetchTags = async () => {
    setIsLoading(true);
    try {
      const res = await API.getOETags({ ...params, ...filters });
      setTags(res.content);
      setPaginationValues(omit(res, ["content"]));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const prevParamsSize = usePrevious(params.size);

  const handleUpdatePaginationParams = (param: PaginationParams) =>
    setParams({ ...params, ...param });

  // get the tags on mount and when search/pagination params change
  useEffect(() => {
    // When we change the page size, reset page to 0
    if (prevParamsSize !== params.size) {
      setParams({ ...params, page: 0 });
    } else {
      fetchTags();
    }
  }, [params, filters]);

  return (
    <ContentWrapper id="mainContent">
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Tag Library",
            href: "/content/tag-library",
          },
        ]}
      />
      <PageHeader>
        <PageTitle data-testid={`${Page.Desktop}-${Components.TagLibrary}`}>
          Tag Library
        </PageTitle>
        <WriteContent roles={tagWriteRoles}>
          <Button
            onClick={() => navigate("/content/tag-library/add-tag")}
            qa={`${Components.TagLibrary}-${ElementType.Button}-addTag`}
          >
            Add Tag
          </Button>
        </WriteContent>
      </PageHeader>

      <TagFilters onUpdateFilters={handleUpdateFilters} />

      <Loader loading={isLoading}>
        <TagLibraryTable
          onFetch={fetchTags}
          onSort={handleSort}
          params={params}
          tags={tags}
          totalElements={paginationValues?.totalElements || 0}
        />
      </Loader>
      <TagLibraryTablePagination
        params={params}
        paginationValues={paginationValues}
        onUpdateParams={handleUpdatePaginationParams}
      />
    </ContentWrapper>
  );
};
