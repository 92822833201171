import React, { ChangeEventHandler } from "react";
import { Icon } from "../../Icon/Icon";
import * as QA from "../../../qa-slugs";
import { TextInput } from "../../TextInput/TextInput";
import styles from "./PillBox.module.scss";

interface PillBoxWithSuggestionsProps<T> extends QA.QAProps {
  error?: string;
  label: string;
  labelClass?: string;
  labelField: keyof T;
  onAdd: (value: T) => void;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onRemove: (value: T) => void;
  onViewMore?: () => void;
  placeholder?: string;
  required?: boolean;
  search: string;
  suggestions: Array<T>;
  suggestionsLabel: string;
  totalSuggestions: number;
  values: Array<T>;
  viewMoreModalIsVisible?: boolean;
  disabled?: boolean;
}

export function PillBoxWithSuggestions<T>({
  error,
  label,
  labelClass,
  labelField,
  onAdd,
  onChange,
  onRemove,
  onViewMore,
  placeholder,
  qa,
  required,
  search,
  suggestions,
  suggestionsLabel,
  totalSuggestions,
  values,
  viewMoreModalIsVisible,
  disabled,
}: PillBoxWithSuggestionsProps<T>) {
  return (
    <>
      {/* Selected Values & Input */}
      <TextInput
        className={styles.hideInput}
        wrapperClassName={styles.textInputWrapper}
        label={label}
        labelClass={labelClass}
        required={required}
        error={error}
        startAdornment={{
          label: (
            <div className={styles.contentWrapper}>
              <div
                className={styles.pillWrapper}
                data-testid={
                  qa ? `${qa}-${QA.Components.SelectedItemsWrapper}` : undefined
                }
              >
                {values.map((v, idx) => (
                  <div
                    onClick={() => {
                      if (disabled) return;
                      onRemove(v);
                    }}
                    key={idx}
                    className={styles.pill}
                    data-testid={
                      qa
                        ? `${qa}-${QA.Components.SelectedItem}-${idx}`
                        : undefined
                    }
                  >
                    <span className={styles.pillText}>
                      {v[labelField] as unknown as string}
                    </span>
                    {!disabled && (
                      <Icon type="delete_sign" className={styles.pillIcon} />
                    )}
                  </div>
                ))}
                {/* added here for positioning */}
                <input
                  data-testid={
                    qa ? `${qa}-${QA.Components.SearchInput}` : undefined
                  }
                  className={styles.input}
                  placeholder={placeholder}
                  onChange={onChange}
                  value={search}
                />
              </div>
            </div>
          ),
          visible: true,
        }}
        disabled={disabled}
      />

      {/* Suggested Values */}
      {!disabled && (
        <div className={styles.suggestionsWrapper}>
          <p className={styles.suggestionsLabel}>{suggestionsLabel}:</p>
          <div
            className={styles.pillWrapper}
            data-testid={
              qa ? `${qa}-${QA.Components.SuggestionsWrapper}` : undefined
            }
          >
            {suggestions.map((o, idx) => (
              <div
                onClick={() => onAdd(o)}
                key={idx}
                className={styles.pill}
                data-testid={
                  qa
                    ? `${qa}-${QA.Components.SuggestionItem}-${idx}`
                    : undefined
                }
              >
                <span className={styles.pillText}>
                  {o[labelField] as unknown as string}
                </span>
                <Icon type="plus_math" className={styles.pillIcon} />
              </div>
            ))}

            {/* View More Results Pill Box */}
            {!viewMoreModalIsVisible && totalSuggestions > 5 && (
              <div
                className={styles.pillWrapper}
                data-testid={
                  qa ? `${qa}-${QA.Components.ViewMoreResults}` : undefined
                }
              >
                <div
                  onClick={onViewMore}
                  className={styles.pill}
                  data-testid={
                    qa
                      ? `${qa}-${QA.Components.SuggestionItem}-viewMore`
                      : undefined
                  }
                >
                  <span className={styles.pillText}>
                    View {`${totalSuggestions} `}
                    {totalSuggestions === 1 ? "Result" : "Results"}
                  </span>
                  <Icon type="expand_arrow" className={styles.pillIcon} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
