import React, { FC, useState } from "react";
import Label from "../../../Label/Label";
import { AssistiveLink } from "../../../TextInput/types";
import SignaturePad from "./SignaturePad";

interface Props {
  participantId: string;
  handleClearSignature: () => void;
  onAddSignature: (signatureDataUrl: string) => Promise<void>;
  assistiveLink?: AssistiveLink;
  label: string;
}

export const DrawSignatureField: FC<Props> = ({
  participantId,
  handleClearSignature,
  assistiveLink,
  label,
  onAddSignature,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  async function handleAddSignature(signatureDataUrl: string): Promise<void> {
    setLoading(true);
    try {
      await onAddSignature(signatureDataUrl);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Label htmlFor={participantId} assistiveLink={assistiveLink}>
        {label}
      </Label>
      <SignaturePad
        name={participantId}
        clearSignature={handleClearSignature}
        onAddSignature={handleAddSignature}
        loading={loading}
      />
    </>
  );
};
