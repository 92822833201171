import React, { Dispatch, SetStateAction } from "react";
import RehuddleSidebarContent from "../components/RehuddleSidebar/RehuddleSidebarContent";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import { DocumentSummaryVm, DocumentVm } from "@rtslabs/field1st-fe-common";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isDesktop?: boolean;
  onSuccess: () => void;
  selectedDocument: DocumentSummaryVm | DocumentVm | null;
  terms: {
    document: {
      singular: string;
    };
    rehuddle: string;
  };
}

const RehuddleSidebar = ({
  isOpen,
  setIsOpen,
  isDesktop,
  onSuccess,
  selectedDocument,
  terms,
}: Props) => {
  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      id="RehuddleSidebar"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      disableBackdrop
    >
      <RehuddleSidebarContent
        onSuccess={onSuccess}
        toggleRehuddleSidebarVisibility={() => setIsOpen(false)}
        selectedDocument={selectedDocument}
        terms={terms}
      />
    </Drawer>
  );
};

export default RehuddleSidebar;
