import React, { PropsWithChildren, MouseEventHandler, forwardRef } from "react";

import styles from "./styles.module.scss";

import { useMouseFocus } from "../../util/hooks/useMouseFocus";
import { joinClassNames } from "../../helpers/theme.helpers";
import { Icon } from "../Icon/Icon";

interface SelectionChipProps {
  className?: string;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  isFocusDisabled?: boolean;
}

export const SelectionChip = forwardRef<
  HTMLLIElement,
  PropsWithChildren<SelectionChipProps>
>(({ children, className, handleClick, isFocusDisabled }, ref) => {
  const { eventHandlers, isMouseFocused } = useMouseFocus();

  return (
    <li className={className} ref={ref}>
      <button
        className={joinClassNames(
          styles.selectionChip,
          !isMouseFocused && styles.selectionChipKeyboardFocused
        )}
        onClick={handleClick}
        tabIndex={isFocusDisabled ? -1 : undefined}
        type="button"
        {...eventHandlers}
      >
        <span className={styles.label}>{children}</span>
        <Icon type="cross" className={styles.icon} />
      </button>
    </li>
  );
});
