import { FormSectionVm, SectionItem } from "@rtslabs/field1st-fe-common";
import React, { FC, ReactNode } from "react";
import { FormSection } from "../DocumentForm/FormSection";

interface ReadOnlySectionsProps {
  sections: FormSectionVm[];
  children: (questions?: SectionItem[]) => ReactNode;
}

export const ReadOnlySections: FC<ReadOnlySectionsProps> = ({
  sections,
  children,
}) => {
  return (
    <>
      {sections.map((section) => {
        return (
          <FormSection key={section.id} title={section.title || ""}>
            {children(section.items)}
          </FormSection>
        );
      })}
    </>
  );
};
