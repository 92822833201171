import React, {
  FC,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { ElementType } from "../../../qa-slugs";
import { useMappedRefs } from "../../../util/hooks/useMappedRefs";
import Portal from "../../Portal/Portal";
import { Select } from "../../Select/Select";
import { primaryTabClassNames, Tab, whiteTabClassNames } from "../Tab/Tab";
import { TabPanel } from "../TabPanel/TabPanel";
import styles from "./TabGroup.module.scss";

export interface TabContent {
  label: string;
  showPanelInPortal?: boolean;
  subLabel?: string;
  tabId: string;
  tabPanelContent: ReactNode;
  tabPanelId: string;
}

interface TabGroupClassNames {
  tab?: string;
  tabList?: string;
}

export type TabGroupVariant = "primary" | "white";

export interface TabGroupProps {
  tabs: TabContent[];
  variant?: TabGroupVariant;
  initiallySelectedTabIndex?: number;
  selectedTab?: number;
  classes?: {
    tabList?: string;
    tab?: string;
    tabActive?: string;
    tabsContainer?: string;
    content?: string;
    tabSubLabel?: string;
    tabLabel?: string;
    tabLabelActive?: string;
    tabPanel?: string;
    select?: string;
  };
  maxWidth?: number;
  maxTabs?: number;
  dropDownText?: string;
  onTabChange?: (newTab: TabContent) => void;
  portalId?: string;
  setSelectedTab?: (tab: number) => void;
}

const primaryTabGroupClassNames: TabGroupClassNames = {
  tabList: styles.tabList,
};

const whiteTabGroupClassNames: TabGroupClassNames = {
  tab: styles.tab,
  tabList: styles.whiteTabList,
};

export const TabGroup: FC<TabGroupProps> = ({
  tabs,
  variant = "primary",
  selectedTab,
  initiallySelectedTabIndex = 0,
  classes,
  maxWidth,
  maxTabs,
  dropDownText,
  onTabChange,
  portalId,
  setSelectedTab,
}) => {
  const [visibleTabs, setVisibleTabs] = useState<TabContent[]>(tabs);
  const [hiddenTabs, setHiddenTabs] = useState<TabContent[]>([]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    initiallySelectedTabIndex
  );
  const { handleRef: handleTabRef, keyToRef: tabsById } = useMappedRefs<
    HTMLButtonElement,
    string,
    TabContent
  >(tabs);
  const tabClassNames =
    variant === "primary" ? primaryTabClassNames : whiteTabClassNames;
  const tabGroupClassNames =
    variant === "primary" ? primaryTabGroupClassNames : whiteTabGroupClassNames;

  const selectTab = (index: number) => {
    setSelectedTab && setSelectedTab(index); // updates selected tab in formBuilder
    setSelectedTabIndex(index);
    const newTab = tabs[index];
    const tab = tabsById.current.get(newTab.tabId);
    tab?.focus();
    onTabChange && onTabChange(newTab);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    switch (event.key) {
      case "ArrowLeft":
        if (selectedTabIndex > 0) {
          event.preventDefault();
          selectTab(selectedTabIndex - 1);
        }
        break;

      case "ArrowRight":
        if (selectedTabIndex < tabs.length - 1) {
          event.preventDefault();
          selectTab(selectedTabIndex + 1);
        }
        break;

      case "End":
        event.preventDefault();
        selectTab(tabs.length - 1);
        break;

      case "Home":
        event.preventDefault();
        selectTab(0);
        break;
    }
  };
  const [tabWidth, setTabWidth] = useState<number>();
  useEffect(() => {
    if (maxWidth && maxTabs) {
      if (maxTabs < tabs.length) {
        setVisibleTabs(tabs.slice(0, maxTabs - 1));
        setHiddenTabs(tabs.slice(maxTabs - 1, tabs.length));
        setShowDropdown(true);
      } else {
        setVisibleTabs(tabs);
      }
      setTabWidth(maxWidth / maxTabs);
    } else {
      setVisibleTabs(tabs);
    }
  }, [maxWidth, maxTabs, tabs]);

  useEffect(() => {
    if (selectedTabIndex >= tabs.length) {
      setSelectedTabIndex(0);
    }
    if (typeof selectedTab === "number") {
      setSelectedTabIndex(selectedTab);
    }
  }, [tabs, selectedTab]);

  return (
    <div className={classes && classes.tabsContainer}>
      <div
        className={joinClassNames(
          tabGroupClassNames.tabList,
          classes && classes.tabList
        )}
        role="tablist"
      >
        {visibleTabs.map(({ label, tabId, tabPanelId, subLabel }, index) => {
          const handleClick = () => {
            selectTab(index);
          };

          const handleRef = (instance: HTMLButtonElement | null) => {
            if (instance) {
              handleTabRef(tabId, instance);
            }
          };

          return (
            <Tab
              className={joinClassNames(
                tabGroupClassNames.tab,
                classes && classes.tab,
                index === selectedTabIndex && classes && classes.tabActive
              )}
              handleClick={handleClick}
              handleKeyDown={handleKeyDown}
              id={tabId}
              isSelected={index === selectedTabIndex}
              key={tabId}
              ref={handleRef}
              tabPanelId={tabPanelId}
              variantClassNames={tabClassNames}
              style={{ width: tabWidth }}
              qa={`${ElementType.Button}-${label}`}
            >
              <span
                className={joinClassNames(
                  classes && classes.tabLabel,
                  index === selectedTabIndex &&
                    classes &&
                    classes.tabLabelActive
                )}
              >
                {label}
              </span>
              {subLabel && (
                <span className={classes && classes.tabSubLabel}>
                  {subLabel}
                </span>
              )}
            </Tab>
          );
        })}
        {showDropdown && (
          <Select
            placeholder={dropDownText || "More tabs"}
            value="" // always show placeholder
            options={hiddenTabs.map((h) => ({
              value: h.tabId,
              label: h.label,
            }))}
            wrapperClassName={joinClassNames(classes?.tab, classes?.select)}
            overrideStyles={{
              container: () => ({
                position: "relative",
                width: tabWidth,
                fontWeight: 500,
                flex: 1,
                zIndex: 1000,
              }),
            }}
            onChange={(selected) => {
              const index = tabs.findIndex(
                (tab) => tab.tabId === selected?.value
              );
              selectTab(index);
            }}
          />
        )}
      </div>
      <div className={classes && classes.content}>
        {tabs.map(
          (
            { showPanelInPortal, tabId, tabPanelContent, tabPanelId },
            index
          ) => {
            const panel = (
              <TabPanel
                id={tabPanelId}
                isHidden={index !== selectedTabIndex}
                key={tabPanelId}
                tabId={tabId}
                className={classes && classes.tabPanel}
              >
                {tabPanelContent}
              </TabPanel>
            );
            return showPanelInPortal ? (
              <Portal key={tabPanelId} portalId={portalId} variant="default">
                {panel}
              </Portal>
            ) : (
              panel
            );
          }
        )}
      </div>
    </div>
  );
};
