import * as React from "react";
import styled from "styled-components";
import { GroupStats } from "shared/src/components/clientAdmin/helpers";
import { toRgba } from "shared/src/themes/helpers";

const PercentLineGraphWrapper = styled.div.attrs({ className: "d-flex 1-100" })`
  border-radius: 5px;
  overflow: hidden;
  height: 1rem;
`;

type PointProps = {
  percent: number;
  color?: string;
};

const PercentLineGraphPoint = styled.div<PointProps>`
  background: ${({ color, theme }) =>
    `${color || toRgba(theme.colors.black, 0.2)} linear-gradient(0deg, ${toRgba(
      theme.colors.black,
      0.25
    )}, transparent)`};
  width: ${(props) => props.percent}%;
`;

type Props = {
  total: number;
  data: GroupStats;
};

/**
 * Constructs a line graph from given group data
 * @param data  - individual group counts
 * @param total - total number of submissions
 */
const PercentLineGraph = ({ data, total }: Props) => (
  <PercentLineGraphWrapper>
    {total > 0 ? (
      <>
        {Object.keys(data).map((key, i) => (
          <PercentLineGraphPoint
            key={`group_${i}`}
            percent={data[key].percent}
            color={data[key].color}
          />
        ))}
      </>
    ) : (
      <PercentLineGraphPoint percent={100} />
    )}
  </PercentLineGraphWrapper>
);

export default PercentLineGraph;
