import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import PopperBase, { PopperProps } from "@mui/material/Popper";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC, RefCallback, useContext, useState } from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { toRgba } from "../../../themes/helpers";
import { ThemeContext } from "styled-components";
import styles from "./Popper.module.scss";

const useStyles = () => {
  const theme = useContext(ThemeContext);
  return makeStyles({
    root: {
      border: `1px solid ${toRgba(theme.colors.black, 0.1)}`,
      boxShadow: `0px 16px 24px ${toRgba(theme.colors.lightGrey, 0.2)}`,
      margin: "12px",
    },
  })();
};

type Placement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

export interface Props {
  arrow?: boolean;
  anchorEl: VirtualElement | null;
  children: any;
  className?: string;
  id?: string;
  onClose?: () => void;
  open: boolean;
  placement?: Placement;
  placementOffset?: number;
  qa?: string;
}

export type VirtualElement = PopperProps["anchorEl"];

const Popper: FC<Props> = ({
  arrow,
  className,
  anchorEl,
  children,
  id,
  onClose,
  open,
  placement,
  placementOffset,
  qa,
}) => {
  const classes = useStyles();
  const [arrowRef, setArrowRef] = useState<any>(null);

  return (
    <ClickAwayListener
      // Disable the click away listener when onClose isn't defined.
      mouseEvent={onClose === undefined ? false : undefined}
      onClickAway={() => onClose && onClose()}
    >
      <PopperBase
        className={joinClassNames(className, styles.popper)}
        anchorEl={anchorEl}
        id={id}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: ({ placement }: { placement: Placement }) => {
                const addedOffset = placementOffset ?? 0;
                const arrowOffset = 16;
                const skidding = placement.endsWith("end")
                  ? arrowOffset
                  : placement.endsWith("start")
                  ? -arrowOffset
                  : 0;
                return [skidding + addedOffset, 0];
              },
            },
          },
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            enabled: !!arrow,
            name: "arrow",
            options: {
              element: arrowRef,
              padding: 32,
            },
          },
        ]}
        open={open}
        placement={placement}
        transition
        data-testid={qa}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper classes={classes} elevation={0}>
              {arrow && (
                <div
                  className={styles.arrow}
                  ref={setArrowRef as RefCallback<HTMLDivElement>}
                ></div>
              )}
              {children}
            </Paper>
          </Fade>
        )}
      </PopperBase>
    </ClickAwayListener>
  );
};

export default Popper;
