import React from "react";
import styles from "./Checkbox.module.scss";
import ErrorText from "../ErrorText/ErrorText";
import { CheckBoxTwoTone } from "./svg/CheckBoxTwoTone";
import { CheckBoxTwoToneBlank } from "./svg/CheckBoxTwoToneBlank";
import { CheckBoxFilled } from "./svg/CheckBoxFilled";
import { CheckBoxFilledBlank } from "./svg/CheckBoxFilledBlank";
import { ElementType, QAProps } from "../../qa-slugs";

export interface CheckboxProps extends QAProps {
  checked?: boolean;
  disabled?: boolean;
  error?: string;
  onChange: (checked: boolean) => void;
  label?: React.ReactNode;
  name?: string;
  value?: number | string;
  containerClassName?: string;
  className?: string;
}

const Checkbox = ({
  checked = false,
  disabled,
  error,
  onChange,
  label,
  name,
  containerClassName,
  className,
  qa,
  value,
}: CheckboxProps) => {
  let checkedIcon: React.ReactNode;
  let uncheckedIcon: React.ReactNode;
  let labelClassName: string;
  if (disabled) {
    checkedIcon = <CheckBoxTwoTone />;
    uncheckedIcon = <CheckBoxTwoToneBlank />;
    labelClassName = styles.disabledLabel;
  } else if (error) {
    checkedIcon = <CheckBoxTwoTone error={error} />;
    uncheckedIcon = <CheckBoxTwoToneBlank error={error} />;
    labelClassName = styles.errorLabel;
  } else {
    checkedIcon = <CheckBoxFilled />;
    uncheckedIcon = <CheckBoxFilledBlank />;
    labelClassName = styles.label;
  }
  return (
    <div className={containerClassName} onClick={(e) => e.stopPropagation()}>
      <label className={styles.formField}>
        <input
          type="checkbox"
          className={className}
          data-testid={qa ? `${qa}-${ElementType.Checkbox}` : undefined}
          disabled={disabled}
          checked={checked}
          value={value === null ? "" : value}
          name={name}
          onChange={(e) => onChange(e.target.checked)}
          onKeyPress={(e) => e.key === "Enter" && onChange(!checked)}
        />

        {checked ? checkedIcon : uncheckedIcon}
        <span className={labelClassName}>{label}</span>
      </label>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

export default Checkbox;
