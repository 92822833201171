import {
  API,
  DefenseDTO,
  QuestionSelectionsSaveVm,
} from "@rtslabs/field1st-fe-common";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../../../../../../Button/Button";
import Checkbox from "../../../../../../Checkbox/Checkbox";
import { Drawer } from "../../../../../../Drawer/Drawer";
import DrawerHeader from "../../../../../../Drawer/DrawerHeader";
import Label from "../../../../../../Label/Label";
import MultiInput from "../../../../../../MultiInput/MultiInput";
import { TextInput } from "../../../../../../TextInput/TextInput";
import { joinClassNames } from "../../../../../../../helpers/theme.helpers";
import { addCustomDefense } from "../../../../../../../api/formBuilder/defenseResource";
import {
  IconSelector,
  IconValue,
} from "../../../../../../common/Icon/IconSelector";
import { IconSelectorButton } from "../../../../../../common/Icon/IconSelectorButton";
import SidebarLabel from "../../../../baseUi/sidebar/SidebarLabel";
import ps from "../../styles.module.scss";
import AddDefenseSection from "./AddDefenseSection";
import styles from "./CustomAnswerOptionsDrawer.module.scss";
import {
  CustomAnswerOptionsDrawerState,
  DefenseType,
  OETagDTOWithId,
  validationSchema,
} from "./customAnswerOptionsFormUtils";
import { useFBConfigs } from "../../../../../../../util/hooks/useFBConfigs";

interface Props {
  closeDrawer: () => void;
  initialValues: QuestionSelectionsSaveVm;
  itemSubType: string;
  onSaveOption: (
    option: QuestionSelectionsSaveVm,
    selectedDefenseType: DefenseType,
    previousDefense?: DefenseDTO,
    newDefense?: DefenseDTO
  ) => Promise<void>;
  show: boolean;
}

const CustomAnswerOptionsDrawer: React.FC<Props> = ({
  closeDrawer,
  initialValues,
  itemSubType,
  onSaveOption,
  show,
}) => {
  const { formBuilderConfigs } = useFBConfigs();
  const { enableChoiceIcons, enableDefense, enableOE } = formBuilderConfigs;
  const { initialDefenseId, initialIcon } = useMemo(
    () => getInitialValues(initialValues),
    [initialValues]
  );
  const [isIconSelectorOpen, setIsIconSelectorOpen] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<IconValue | undefined>(
    initialIcon
  );
  const [addedIcon, setAddedIcon] = useState<IconValue | undefined>(
    initialIcon
  );
  const allowsIcons =
    !!enableChoiceIcons &&
    (itemSubType === "MULTI_SELECT" || itemSubType === "RADIO_BUTTONS");

  useEffect(() => {
    /**
     * `initialIcon` is correct on the first render, but for some reason
     * `selectedIcon` is undefined. I can't figure it out, so I added this
     * effect. It seems useless... but it's not.
     * if no 'intitialIcon', reset icon to default state
     */
    if (initialIcon) {
      setSelectedIcon(initialIcon);
      setAddedIcon(initialIcon);
    } else {
      resetSelectedIcon();
      resetAddedIcon();
    }
    // closes icon selecter when drawer is closed
    closeIconSelector();
  }, [initialIcon, show]);

  function toggleIconSelector() {
    setIsIconSelectorOpen((prevIsOpen) => !prevIsOpen);
    // cancels any unsaved icon changes on collaping IconSelector
    isIconSelectorOpen && cancelIconChanges();
  }

  function closeIconSelector() {
    setIsIconSelectorOpen(false);
  }

  function resetSelectedIcon() {
    setSelectedIcon({ icon: "", color: "#000000" });
  }

  function resetAddedIcon() {
    setAddedIcon({ icon: "", color: "#000000" });
  }

  function cancelIconChanges() {
    setSelectedIcon(addedIcon);
  }

  const [oeTagSuggestions, setOeTagSuggestions] = useState<OETagDTOWithId[]>(
    []
  );
  async function searchOeTags(query: string): Promise<void> {
    try {
      const res = await API.getOETags({ query, archived: false });
      setOeTagSuggestions(res.content);
    } catch (e) {
      console.error(e);
    }
  }

  const debouncedSearchTags = debounce(searchOeTags, 1000, {
    leading: true,
    trailing: false,
  });

  async function saveOption(
    values: CustomAnswerOptionsDrawerState
  ): Promise<void> {
    let newDefense: DefenseDTO | undefined;
    if (values.selectedDefenseType === DefenseType.Custom) {
      // yup validation will ensure description and title are defined here
      newDefense = await addCustomDefense({
        description: values.newCustomDefense!.description!,
        files: values.newCustomDefense!.files,
        title: values.newCustomDefense!.title!,
      });
    } else if (values.selectedDefenseType === DefenseType.Global) {
      newDefense = values.newGlobalDefense;
    }

    const saveOption: QuestionSelectionsSaveVm = {
      ...initialValues,
      properties: {
        commentRequired: values.commentRequired,
        hideChoiceLabel: values.hideChoiceLabel,
        icon: values.icon && {
          name: values.icon.icon,
          color: values.icon.color,
        },
      },
      tags: values.tags,
      title: values.title,
    };
    await onSaveOption(
      saveOption,
      values.selectedDefenseType,
      values.previousDefense,
      newDefense
    );

    closeDrawer();
  }

  /**
   * Custom defenses automatically get added to the global defense library, so if
   * there's an id, the defense type is global
   */
  const initialDefenseType = initialDefenseId
    ? DefenseType.Global
    : DefenseType.None;

  return (
    <Drawer
      anchor="right"
      disableBackdrop
      id="multiSelectOptionsDrawer"
      isOpen={show}
      onClose={closeDrawer}
    >
      <DrawerHeader onClose={closeDrawer} title="Custom Answer Options" />
      <div className={ps.drawerContent}>
        <Formik
          initialValues={{
            commentRequired: initialValues.properties?.commentRequired ?? false,
            hideChoiceLabel: initialValues.properties?.hideChoiceLabel ?? false,
            selectedDefenseType: initialDefenseType,
            tags: initialValues.tags as OETagDTOWithId[],
            title: initialValues.title,
            icon: initialIcon,
          }}
          onSubmit={saveOption}
          validationSchema={validationSchema}
        >
          {({ errors, isSubmitting, setFieldValue, touched, values }) => {
            function updateHideChoiceLabel(value?: boolean) {
              setFieldValue("hideChoiceLabel", !!value);
            }

            return (
              <Form translate="yes">
                <div className={ps.drawerSection}>
                  <Label className={ps.label} htmlFor="choiceLabelField">
                    Custom Answer Option
                  </Label>
                  <p className={ps.help}>
                    Custom choices are displayed as a list under a multi-select
                    question field. Each choice has the option of an attached
                    Defense and Observational Experience.
                  </p>
                  <TextInput
                    name="title"
                    className={styles.labelInput}
                    error={touched.title && errors.title}
                    id="choiceLabelField"
                    label="Choice Label"
                    labelClass={ps.label}
                    wrapperClassName={styles.labelWrapper}
                    placeholder="Choice Label"
                    required
                    value={values.title}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("title", value);
                      if (!value) updateHideChoiceLabel();
                    }}
                    startAdornment={
                      allowsIcons
                        ? {
                            visible: true,
                            customIcon: (
                              <IconSelectorButton
                                expanded={isIconSelectorOpen}
                                selectedIcon={addedIcon}
                              />
                            ),
                            handleClick: toggleIconSelector,
                            className: joinClassNames(
                              styles.iconPicker,
                              isIconSelectorOpen
                                ? styles.expanded
                                : styles.closed
                            ),
                          }
                        : undefined
                    }
                  />
                  {isIconSelectorOpen && allowsIcons && (
                    <div className={styles.iconSelector}>
                      <IconSelector
                        onChange={setSelectedIcon}
                        value={selectedIcon}
                        propStyles={{
                          searchInputWrapper: styles.iconSearchWrapper,
                          selectionArea: styles.iconSelectionArea,
                        }}
                        onAddClick={() => {
                          setAddedIcon(selectedIcon);
                          setFieldValue("icon", selectedIcon);
                          closeIconSelector();
                        }}
                        onCancelClick={() => {
                          closeIconSelector();
                          cancelIconChanges();
                          updateHideChoiceLabel(
                            initialValues.properties?.hideChoiceLabel
                          );
                        }}
                        onClearClick={() => {
                          resetSelectedIcon();
                          resetAddedIcon();
                          updateHideChoiceLabel(false);
                          closeIconSelector();
                        }}
                      />
                    </div>
                  )}
                  <SidebarLabel>Rules</SidebarLabel>
                  <div className={ps.commentRequired}>
                    <Checkbox
                      className={ps.checkbox}
                      name="commentRequired"
                      label="Comment required (*) when answer is selected"
                      checked={values.commentRequired}
                      onChange={(checked: boolean) =>
                        setFieldValue("commentRequired", checked)
                      }
                    />
                    {allowsIcons && (
                      <Checkbox
                        className={ps.checkbox}
                        name="hideLabel"
                        label="Hide Choice Label"
                        checked={values.hideChoiceLabel}
                        onChange={(checked: boolean) =>
                          updateHideChoiceLabel(checked)
                        }
                        disabled={
                          !selectedIcon?.icon ||
                          !addedIcon?.icon ||
                          !values.title
                        }
                      />
                    )}
                  </div>
                </div>

                {!!enableDefense && (
                  <AddDefenseSection initialDefenseId={initialDefenseId} />
                )}

                {!!enableOE && (
                  <div className={ps.drawerSection}>
                    <Label className={ps.label} htmlFor="oeTags">
                      Add OE Tags (optional)
                    </Label>
                    <p className={ps.help}>
                      OE tags will determine what OEs have the possibility of{" "}
                      displaying and being included in the team discussion.
                    </p>
                    <MultiInput
                      name="tags"
                      className={ps.multiInput}
                      canUseCustomValues={false}
                      labelField="name"
                      idField="name"
                      placeholder="Begin typing OE tag..."
                      autoCompleteSuggestions={oeTagSuggestions}
                      selectedValues={values.tags?.map((val) => {
                        return {
                          label: val.name,
                          ...val,
                        };
                      })}
                      onAddItem={(tag: OETagDTOWithId) => {
                        setFieldValue("tags", (values.tags || []).concat(tag));
                      }}
                      onRemoveItem={(tag: OETagDTOWithId) => {
                        setFieldValue(
                          "tags",
                          values.tags?.filter((t) => t.id !== tag.id) || []
                        );
                      }}
                      onChangeInput={debouncedSearchTags}
                    />
                  </div>
                )}

                <div className={ps.customAnswerButtonContainer}>
                  <Button
                    className={ps.addOptionButton}
                    type="submit"
                    loading={isSubmitting}
                  >
                    {initialValues.id ? "Update" : "Add"} Option
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Drawer>
  );
};

export default CustomAnswerOptionsDrawer;

function getInitialValues(initialValues: QuestionSelectionsSaveVm): {
  initialDefenseId?: number;
  initialIcon?: IconValue;
} {
  return {
    initialDefenseId:
      initialValues.defenseIds?.length && initialValues.defenseIds[0],
    initialIcon: initialValues.properties?.icon
      ? {
          icon: initialValues.properties?.icon.name,
          color: initialValues.properties?.icon.color,
        }
      : undefined,
  };
}
