import { DocSecActionTypes, DocSecActions } from "./actions";
import { SectionalContextProps } from "../types";

const reducer = (
  state: SectionalContextProps,
  action: DocSecActions
): SectionalContextProps => {
  switch (action.type) {
    case DocSecActionTypes.SET_CURRENT_SECTION:
      return { ...state, currentSection: action.value };
    case DocSecActionTypes.SET_DOCUMENT_TITLE: {
      return { ...state, documentTitle: action.value };
    }
    // case DocSecActionTypes.SET_IS_SECTION_VALIDATED: {
    //   return { ...state, isSectionValidated: action.value };
    // }
    case DocSecActionTypes.SET_VISIBLE_SECTIONS: {
      return { ...state, visibleSections: action.value };
    }
    default:
      return state;
  }
};

export default reducer;
