import {
  DocumentPhotoVm,
  DocumentVm,
  FormSectionVm,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import { getFlattenedItems } from "../components/Document/DocumentForm/formEntity.helpers";

export enum DocumentHistoryStatus {
  created = "Created",
  original = "Original",
  rehuddled = "Rehuddled",
  related = "Related",
}

export const prettifyDocumentHistoryStatus = (
  statuses: string,
  documentId: number,
  historyDocumentId?: number | null
) => {
  /**
   *
   * @param statuses          - historyType in history
   * @param documentId        - id of the original document
   * @param historyDocumentId - documentId in history
   */

  const isOriginal = documentId !== historyDocumentId;
  const isCreated = documentId === historyDocumentId;

  if (statuses) {
    switch (statuses) {
      case "CREATED":
        if (isOriginal) return DocumentHistoryStatus.original;
        return DocumentHistoryStatus.created;
      case "SUBMITTED":
        if (isOriginal) return DocumentHistoryStatus.original;
        return "Submitted";
      case "REHUDDLE":
        if (isCreated) return DocumentHistoryStatus.created;
        return DocumentHistoryStatus.rehuddled;
      case "DELETE":
        if (isOriginal) return DocumentHistoryStatus.original;
        return "Deleted";
      case "SAVED_AS_DRAFT":
        if (isOriginal) return DocumentHistoryStatus.original;
        return "Saved as Draft";
      default:
        return "-";
    }
  } else {
    return "-";
  }
};

/**
 * Get a document section item (question/widget/content) from the document by its root id
 */
export const getFormItemByRootId = (
  document: DocumentVm,
  rootId: number
): SectionItem | undefined => {
  const items: SectionItem[] | undefined = document.form.sections?.flatMap(
    (section: FormSectionVm) => section.items
  );
  return items?.find((item: SectionItem) => item.rootId === rootId);
};

/**
 * Gets a sorted collection of document photos by question order and time added (descending)
 */
export const sortPhotos = (
  photos: DocumentPhotoVm[],
  sections: FormSectionVm[]
) => {
  const questionRootIdsInOrder = getFlattenedItems(sections, "QUESTION").map(
    (item) => item.rootId
  );
  const galleryPhotos = photos
    .filter((p) => !p.questionRootId)
    .sort(sortPhotosByTimeAdded);

  return questionRootIdsInOrder.reduce(
    (sortedPhotos: DocumentPhotoVm[] = [], rootId: number, idx) => {
      const questionPhotos = photos
        .filter((p) => p.questionRootId === rootId)
        .sort(sortPhotosByTimeAdded);
      sortedPhotos.push(...questionPhotos);

      // appends sorted gallery photos to end of gallery
      if (idx === questionRootIdsInOrder.length - 1)
        sortedPhotos.push(...galleryPhotos);

      return sortedPhotos;
    },
    []
  );
};

export function sortPhotosByTimeAdded(a: DocumentPhotoVm, b: DocumentPhotoVm) {
  if (a.timeAdded < b.timeAdded) {
    return 1;
  } else if (a.timeAdded > b.timeAdded) {
    return -1;
  } else {
    return 0;
  }
}
