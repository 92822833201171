import { API, DocumentVm, MapWidgetDTO } from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { useState } from "react";
import { LocationMarker } from "shared/src/api/geolocationAPI";
import { ClassicLocationMarker } from "shared/src/components/Document/DocumentForm/LocationField/geo";
import { FormTypeIcon } from "shared/src/components/Documents/FormTypeIcon";
import { Icon } from "shared/src/components/Icon/Icon";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import { Components, ElementType } from "shared/src/qa-slugs";
import { EsriViewport } from "../../common/ArcGisMap/esriMapUtilities";
import CollapsibleDiv from "shared/src/components/common/CollapsibleDiv";
import { prettifyWorkorderID } from "../../Documents/helpers";
import { ExpandedSection } from "./ExpandedSection";
import styles from "./RecentsListItem.module.scss";

interface RecentsListItemProps {
  document: DocumentVm;
  selectedDocumentId: number | undefined;
  onClickDocument: (id: number) => void;
}

export const RecentsListItem = ({
  document,
  selectedDocumentId,
  onClickDocument,
}: RecentsListItemProps) => {
  const [map, setMap] = useState<MapWidgetDTO>();
  const [viewport, setViewport] = useState<EsriViewport>();
  const [location, setLocation] = useState<
    LocationMarker | ClassicLocationMarker
  >();
  const [error, setError] = useState<string>();
  const displayMap = !!location && !!map && !!viewport;

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTableRowElement>,
    document: DocumentVm
  ) => {
    if (event.type === "keydown") {
      switch (event.key) {
        case " ":
        case "Enter": {
          handleClickDocument(document);
          event.preventDefault();
          break;
        }
      }
    }
  };

  const handleClickDocument = (document: DocumentVm) => {
    try {
      onClickDocument(document.id);
      const documentItems = document.form.sections.flatMap((s) => s.items);

      const mapWidget = documentItems.find(
        (i) => i.subType === "MAP" && i.type === "WIDGET"
      ) as MapWidgetDTO;
      mapWidget && setMap(mapWidget);

      if (mapWidget && mapWidget?.includeMap) {
        // full question on document
        const workLocationQuestion = documentItems.find(
          (i) =>
            i.type === "QUESTION" &&
            i.answerSource?.dataSourceKey === "WORK_LOCATION"
        );

        // map question on Map Widget
        const mapWorkQuestion = mapWidget.questions.find(
          (q) => q.questionRootId === workLocationQuestion?.rootId
        );

        const workLocationResponse = document.responses.find(
          (r) => r.questionRootId === workLocationQuestion?.rootId
        );

        if (workLocationResponse?.associatedLocation) {
          const mapViewport: EsriViewport = {
            center: {
              latitude: workLocationResponse.associatedLocation.latitude,
              longitude: workLocationResponse.associatedLocation.longitude,
            },
          };

          const mapLocation: LocationMarker | ClassicLocationMarker = {
            geolocation: workLocationResponse?.associatedLocation || undefined,
            locationName: workLocationResponse?.answer,
            color: mapWorkQuestion?.iconColor,
          };

          setViewport(mapViewport);
          setLocation(mapLocation);
        }
      }
    } catch (e) {
      console.error(e);
      const errorMessage = API.getErrorMessage(e);
      setError(errorMessage);
    }
  };

  return (
    <>
      <TableRow
        qa={`${Components.RecentDocuments}-${ElementType.TableRow}-${
          document.status === "SUBMITTED" ? "SUBMITTED" : "DRAFT"
        }-${document.id}`}
        className={styles.documentListItem}
        key={document.id}
        onClick={() => handleClickDocument(document)}
        onKeyDown={(e) => handleKeyDown(e, document)}
      >
        <TableCell
          qa={`${Components.RecentDocuments}-${ElementType.TableCell}-type-${document.formSummary.type.iconName}-${document.formSummary.type.iconColor}-${document.id}`}
        >
          <FormTypeIcon formType={document.formSummary.type} />
        </TableCell>
        <TableCell
          className={styles.titleAndId}
          qa={`${Components.RecentDocuments}-${ElementType.TableCell}-title-${document.id}`}
        >
          <span
            className={styles.title}
            data-testid={`${Components.RecentDocuments}-${ElementType.Text}-title-${document.id}`}
          >
            {document.title}
          </span>
          <span
            data-testid={`${Components.RecentDocuments}-${ElementType.Text}-workId-${document.id}`}
          >
            ID: {prettifyWorkorderID(document.workOrder?.workOrderId || "--")}
          </span>
        </TableCell>
        {document.submissionDate && (
          <TableCell
            className={styles.dateCell}
            qa={`${Components.RecentDocuments}-${ElementType.TableCell}-submission-${document.id}`}
          >
            <span
              className={styles.date}
              data-testid={`${Components.RecentDocuments}-${ElementType.Text}-date-${document.id}`}
            >
              {getDate(document.submissionDate)}
            </span>
            <Icon
              size={25}
              type={
                document.id === selectedDocumentId
                  ? "cheveron-down"
                  : "cheveron-up"
              }
              color={document.id === selectedDocumentId ? "#0072CE" : "#666666"}
            />
          </TableCell>
        )}
      </TableRow>
      {document.id === selectedDocumentId && (
        <CollapsibleDiv
          qa={`${Components.RecentDocuments}-listItemDetails-${document.id}`}
          collapsed={document.id !== selectedDocumentId}
          children={
            <ExpandedSection
              displayMap={displayMap}
              document={document}
              error={error}
              location={location}
              viewport={viewport}
            />
          }
        />
      )}
    </>
  );
};

function isToday(date: Date) {
  const today = new Date();
  today.setDate(today.getDate());
  return today.toDateString() === date.toDateString();
}

function isYesterday(date: Date) {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toDateString() === date.toDateString();
}

const getDate = (date: string) => {
  const submissionDate = new Date(date);
  if (isToday(submissionDate)) return "Today";
  if (isYesterday(submissionDate)) return "Yesterday";
  return moment(submissionDate).format("MM/DD/YYYY");
};
