import React from "react";

import { Icon } from "../../../../../../Icon/Icon";

import ps from "../../styles.module.scss";

interface DeleteRuleButton {
  onDelete: () => void;
}

const DeleteRuleButton = ({ onDelete }: DeleteRuleButton) => {
  return (
    <div onClick={onDelete}>
      <Icon className={ps.addIcon} type="minus_sign" color="black" />
    </div>
  );
};

export default DeleteRuleButton;
