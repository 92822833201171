import React from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps } from "../../qa-slugs";
import styles from "./Modal.module.scss";

interface ModalPromptCloseButtonProps extends QAProps {
  onClick: (event?: React.MouseEvent) => void;
  className?: string;
}

export const ModalPromptCloseButton = ({
  qa,
  onClick,
  className,
}: ModalPromptCloseButtonProps) => {
  return (
    <button
      data-testid={qa}
      type="button"
      className={joinClassNames(
        className,
        "icon-icons8-delete_sign",
        styles.modalPromptCloseButton
      )}
      onClick={onClick}
      aria-label="close"
    ></button>
  );
};
