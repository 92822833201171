const iconMap: any = {
  PARTICIPANT: "add_user_male",
  MULTI_SELECT: "todo_list",
  RADIO_BUTTONS: "radio_button",
  YES_OR_NO: "true_false",
  DROP_DOWN: "dropdown_field",
  TEXT_LINE: "edit_row",
  TEXT_AREA: "paragraph",
  DATE: "calendar",
  TIME: "clock",
  LOCATION: "map",
  SAFETY_RATING: "under_construction",
  SECTION: "overview_pages_4",
  BLOCK: "content",
  LINE_BREAK: "horizontal_line",
  BANNER_MESSAGE: "clapperboard",
  MAP: "map",
  DEFENSES: "defense",
  OPERATIONAL_EXPERIENCES: "development_skill",
  SIGNATURE: "autograph",
  DOCUMENT_CREATOR: "shared_document",
  SUPERVISOR: "person_male",
  PHOTO_GALLERY: "images",
  ESRI_CONTENT: "marker1",
};

export const getItemIcon = (itemType: string): string => {
  return iconMap[itemType] || "delete_sign";
};
