import React, { FC, HTMLAttributes, useContext } from "react";
import { ThemeContext } from "styled-components";

import {
  DateRange as BaseDateRange,
  DateRangeProps,
} from "shared/src/components/DateRange/DateRange";
import { Text, TextProps } from "shared/src/components/clientAdmin/styles";
import s from "./styles.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

const DataItemEmail: FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.875rem"
      fontWeight={400}
      letterSpacing="0.24px"
      {...props}
    />
  );
};

const DataItemLabel: FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.75rem"
      fontWeight={500}
      letterSpacing="1.88px"
      margin="0 0 8px 0"
      {...props}
    />
  );
};

const DataItemText: FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.colors.black}
      fontSize="0.875rem"
      fontWeight={400}
      letterSpacing="0.24px"
      {...props}
    />
  );
};

const DateRange: FC<DateRangeProps> = (props) => (
  <BaseDateRange
    className={joinClassNames(props.className, s.dateRange)}
    {...props}
  />
);

const DateRangeRow: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className={s.dateRangeRow} {...rest}>
    {children}
  </div>
);

const EmailText: FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.875rem"
      fontWeight={400}
      letterSpacing="0.24px"
      {...props}
    />
  );
};

const PageHeader: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className={joinClassNames("col-12", s.pageHeader)} {...rest}>
    {children}
  </div>
);

const PageTitle: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className={s.pageTitle} {...rest}>
    {children}
  </div>
);

const PopperWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className={s.popperWrapper} {...rest}>
    {children}
  </div>
);

type PopperRowType = {
  disabled?: boolean;
  error?: boolean;
};

function popperRowClassName(disabled?: boolean, error?: boolean): string {
  if (disabled) {
    return s.popperRowDisabled;
  } else if (error) {
    return s.popperRowError;
  } else {
    return s.popperRow;
  }
}

const PopperRow: FC<HTMLAttributes<HTMLDivElement> & PopperRowType> = ({
  disabled,
  error,
  children,
  ...rest
}) => (
  <div className={popperRowClassName(disabled, error)} {...rest}>
    {children}
  </div>
);

const PopperChevron: FC<HTMLAttributes<HTMLElement>> = ({
  children,
  ...rest
}) => (
  <div
    className={joinClassNames(
      "icon",
      "icon-icons8-chevron-left",
      s.popperChevron
    )}
    {...rest}
  >
    {children}
  </div>
);

// Generic modal content
const ModalPromptWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => <div {...rest}>{children}</div>;
const ModalPromptTitle: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className={s.modalPromptTitle} {...rest}>
    {children}
  </div>
);
const ModalPromptSubtitle: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className={s.modalPromptSubtitle} {...rest}>
    {children}
  </div>
);
const ModalPromptCloseIcon: FC<HTMLAttributes<HTMLElement>> = ({
  children,
  ...rest
}) => (
  <button
    className={joinClassNames(
      "icon-icons8-delete_sign",
      s.modalPromptCloseIcon
    )}
    {...rest}
    aria-label="close"
  >
    {children}
  </button>
);

// Disable user modal content
const DisableUserDataItem: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className={s.disableUserDataItem} {...rest}>
    {children}
  </div>
);
const DisableUserWarningLabel: FC<
  HTMLAttributes<HTMLDivElement> & { margin?: string }
> = ({ children, margin, ...rest }) => (
  <div style={{ margin }} className={s.disableUserWarningLabel} {...rest}>
    {children}
  </div>
);
const DisableUserButtonsWrapper: FC<
  HTMLAttributes<HTMLDivElement> & { margin?: string }
> = ({ children, margin, ...rest }) => (
  <div style={{ margin }} className={s.disableUserButtonsWrapper} {...rest}>
    {children}
  </div>
);

// Delete user modal content
const DeleteUserWarningLabel: FC<
  HTMLAttributes<HTMLDivElement> & { margin?: string }
> = ({ children, margin, ...rest }) => (
  <div style={{ margin }} className={s.deleteUserWarningLabel} {...rest}>
    {children}
  </div>
);

export {
  DataItemEmail,
  DataItemLabel,
  DataItemText,
  DateRange,
  DateRangeRow,
  DeleteUserWarningLabel,
  DisableUserButtonsWrapper,
  DisableUserDataItem,
  DisableUserWarningLabel,
  EmailText,
  ModalPromptCloseIcon,
  ModalPromptSubtitle,
  ModalPromptTitle,
  ModalPromptWrapper,
  PageHeader,
  PageTitle,
  PopperChevron,
  PopperRow,
  PopperWrapper,
};
