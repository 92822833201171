import { UserStatus } from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { Button, TertiaryButton } from "shared/src/components/Button/Button";
import { Icon } from "shared/src/components/Icon/Icon";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { ElementType } from "../../../../../shared/src/qa-slugs";
import Popper from "shared/src/components/common/Popper/Popper";
import { getActivationStatusForTable } from "./helpers";
import styles from "./UserStatusDropDown.module.scss";

interface UserStatusDropDownProps {
  currentStatus: UserStatus;
  onChangeStatus: (newStatus: UserStatus) => void;
  qaBase?: string;
}

export const UserStatusDropDown: FC<UserStatusDropDownProps> = ({
  currentStatus,
  onChangeStatus,
  qaBase,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedItem, setSelectedItem] = useState<UserStatus>(currentStatus);
  const [statusDropdownIsOpen, setStatusDropdownIsOpen] = useState(false);

  function getClassForStatus(): string {
    if (currentStatus === UserStatus.ACTIVE) return styles.active;
    if (currentStatus === UserStatus.DISABLED) return styles.deactivated;
    return styles.nonActivated;
  }

  const showDropdown: boolean =
    currentStatus === UserStatus.ACTIVE ||
    currentStatus === UserStatus.DISABLED;

  return (
    <>
      <a
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setStatusDropdownIsOpen(true);
        }}
        role="button"
        className={getClassForStatus()}
      >
        {getActivationStatusForTable(currentStatus)}{" "}
        {showDropdown && <Icon type="sort_down" />}
      </a>
      {statusDropdownIsOpen && showDropdown && (
        <Popper
          qa={`${qaBase}-${ElementType.MenuModal}`}
          anchorEl={anchorEl}
          arrow
          onClose={() => {
            setStatusDropdownIsOpen(false);
          }}
          open={statusDropdownIsOpen}
          placement="bottom-end"
        >
          <div className={styles.popperWrapperNoArrow} role="menu">
            <div
              data-testid={`${qaBase}-${ElementType.Button}-activeStatus`}
              className={joinClassNames(
                styles.menuRow,
                selectedItem === UserStatus.ACTIVE
                  ? styles.selectedRow
                  : styles.deselectedRow
              )}
              onClick={() => {
                setSelectedItem(UserStatus.ACTIVE);
              }}
              role="menuitem"
            >
              {selectedItem === UserStatus.ACTIVE && (
                <Icon type="checkmark" className={styles.icon} size={20} />
              )}
              <p className={styles.itemText}>Active</p>
            </div>
            <div
              data-testid={`${qaBase}-${ElementType.Button}-disabledStatus`}
              className={joinClassNames(
                styles.menuRow,
                selectedItem === UserStatus.DISABLED
                  ? styles.selectedRow
                  : styles.deselectedRow
              )}
              onClick={() => {
                setSelectedItem(UserStatus.DISABLED);
              }}
              role="menuitem"
            >
              {selectedItem === UserStatus.DISABLED && (
                <Icon type="checkmark" className={styles.icon} size={20} />
              )}
              <p className={styles.itemText}>Disabled</p>
            </div>
            <div className={styles.actionRow}>
              <Button
                qa={`${qaBase}-${ElementType.Button}-applyStatus`}
                className={styles.actionButton}
                onClick={() => {
                  onChangeStatus(selectedItem);
                  setStatusDropdownIsOpen(false);
                }}
              >
                APPLY
              </Button>
              <TertiaryButton
                qa={`${qaBase}-${ElementType.Button}-resetStatus`}
                className={joinClassNames(
                  styles.actionButton,
                  styles.resetButton
                )}
                onClick={() => {
                  setSelectedItem(currentStatus);
                }}
              >
                Reset
              </TertiaryButton>
            </div>
          </div>
        </Popper>
      )}
    </>
  );
};
