import { QuestionSelectionsDTO } from "@rtslabs/field1st-fe-common";
import { css } from "aphrodite/no-important";
import { get } from "lodash";
import React, { JSXElementConstructor } from "react";
import { AssistiveText } from "../../../../AssistiveText/AssistiveText";
import Checkbox from "../../../../Checkbox/Checkbox";
import CheckboxGroup, {
  CheckboxGroupOption,
} from "../../../../Document/DocumentForm/CheckboxField/CheckboxGroup";
import { LocationField } from "../../../../Document/DocumentForm/LocationField/LocationField";
import { YesNoField } from "../../../../Document/DocumentForm/YesNoField/YesNoField";
import Label from "../../../../Label/Label";
import MultiInput from "../../../../MultiInput/MultiInput";
import {
  RadioButtonOption,
  RadioButtonsGroup,
} from "../../../../RadioButtons/RadioButtonsGroup";
import { Select, SelectOption } from "../../../../Select/Select";
import DisabledTextField from "../../../../TextInput/DisabledTextField";
import { TextInput } from "../../../../TextInput/TextInput";
import { remCalc } from "../../../../../themes/helpers";
import { ThemeContext } from "styled-components";
import { Components, ElementType, Page } from "../../../../../qa-slugs";
import { numberWithCommas } from "../../../../../util/numberWithCommas";
import Banner from "../../../../common/Banner/Banner";
import DateTime from "../../../../common/form/DateTime";
import { AppWidgetProps, FBItem, FBOEWidget } from "../../../types";
import contentStyles from "./styles";

interface MoreValuesNotificationProps {
  additionalCount: number;
  count: number;
  dsKey?: string | null;
}

const MoreValuesNotification = ({
  additionalCount,
  count,
  dsKey,
}: MoreValuesNotificationProps) => (
  <div>
    <span className={css(contentStyles().moreValuesAvailable)}>
      +{numberWithCommas(additionalCount)} more options from{" "}
      {dsKey ? `${dsKey} ` : ""}
      data set
    </span>
    {count > 100 && (
      <Banner type="warning">
        <p>
          You’ve added a very large data set to this question. Answer choices
          may take some time to load when this form is being filled out.
        </p>
      </Banner>
    )}
  </div>
);

/**
 * Convert ItemSelections to Options for select (drop-down) items
 * @param selections
 */
function fmtSelectOptions(selections: ItemSelection[]): SelectOption[] {
  return selections.map((selection) => ({
    label: selection.title,
    value: selection.id || selection.title,
  }));
}

/**
 * Convert ItemSelections to Options for checkbox items
 * @param selections
 */
function fmtCheckboxOptions(
  selections: ItemSelection[]
): CheckboxGroupOption[] {
  return selections.map((selection, index) => ({
    value: selection.id || index,
    label: {
      icon: selection.properties?.icon,
      text: selection.title,
    },
    hideLabel: selection.properties?.hideChoiceLabel,
  }));
}

/**
 * Convert ItemSelections to Options for radio buttons items
 * @param selections
 */
function fmtRadioOptions(
  selections: ItemSelection[]
): RadioButtonOption<number | undefined | null>[] {
  return selections.map((selection) => ({
    data: selection.id,
    label: {
      icon: selection.properties?.icon,
      text: selection.title,
    },
    hideLabel: selection.properties?.hideChoiceLabel,
  }));
}

/**
 * Checks that a string does not match the empty state of a rich text input
 * @param str
 */
function emptyContent(str: string) {
  const trimmedStr = str.trim();
  return trimmedStr.length === 0 || /^<p><\/p>$/.test(trimmedStr);
}

export interface ItemSelection {
  id?: number | null;
  rootId?: number | null;
  title: string;
  optionPlaceholder?: boolean;
  properties?: QuestionSelectionsDTO["properties"];
}

interface Props {
  item: FBItem;
  selections: Array<ItemSelection>;
  totalSelections: number;
  selected?: boolean;
  siblings: Array<FBItem>;
  appWidgets: JSXElementConstructor<AppWidgetProps>;
}

const qaBase = `${Page.Desktop}-${Components.FormBuilder}`;

export function ItemField({
  item,
  selections,
  totalSelections,
  selected,
  siblings,
  appWidgets,
}: Props) {
  const theme = React.useContext(ThemeContext);

  const itemName = `item_${item.id}`;

  const cs = contentStyles();

  if (item.type === "QUESTION") {
    const itemTitle = item.title || "Untitled";
    const itemPlaceholder = item.properties?.placeHolderText;
    const assistiveText = item.properties?.assistiveText;
    const isRequired = get(item, ["formProperties", "isRequired"]);
    const dataSourceKey = item.answerSource?.dataSourceKey;
    const truncatedSelections = !!dataSourceKey
      ? selections.slice(0, 10)
      : selections;
    const additionalDSValuesCount = dataSourceKey
      ? Math.max(totalSelections - 10, 0)
      : 0;

    if (item.properties?.fakeWidgetType) {
      switch (item.properties.fakeWidgetType) {
        case "DOCUMENT_CREATOR":
          return (
            <DisabledTextField
              name={itemName}
              label={itemTitle}
              value="[Document creator's name]"
            />
          );
        case "SUPERVISOR":
          return (
            <MultiInput
              assistiveText={assistiveText}
              canUseCustomValues={true}
              idField="id"
              label={itemTitle}
              labelField="title"
              name={itemName}
              onAddItem={() => null}
              onRemoveItem={() => null}
              placeholder={itemPlaceholder}
              required={isRequired}
              assistiveLink={{
                label: "Add Participant",
                onClick: () => null,
              }}
            />
          );
        default:
          console.error(
            "Unhandled fake widget type - presenting as question - " +
              item.properties.fakeWidgetType
          );
      }
    }

    switch (item.subType) {
      case "CHECKBOX":
        return <Checkbox onChange={() => undefined} label="Checkbox label" />;
      case "MULTI_SELECT":
        return (
          <>
            <Label htmlFor={item.id?.toString()} required={isRequired}>
              {itemTitle}
            </Label>
            <p className={css(cs.note)}>
              Add multi-select choices in the properties panel
            </p>
            {selections.length > 0 && (
              <CheckboxGroup
                options={fmtCheckboxOptions(truncatedSelections)}
                selected={[]}
                onChange={() => undefined}
                qa={`${ElementType.CheckboxGroup}-MultiSelect`}
              />
            )}
            {!!additionalDSValuesCount && (
              <MoreValuesNotification
                additionalCount={additionalDSValuesCount}
                count={totalSelections}
                dsKey={dataSourceKey}
              />
            )}
            {item.properties?.assistiveText && (
              <AssistiveText>{item.properties.assistiveText}</AssistiveText>
            )}
          </>
        );
      case "DATE":
        return (
          <DateTime
            assistiveText={assistiveText}
            label={itemTitle}
            name={itemName}
            required={isRequired}
            type="date"
          />
        );
      case "DATE_TIME":
        return (
          <DateTime
            assistiveText={assistiveText}
            label={itemTitle}
            name={itemName}
            required={isRequired}
            type="datetime-local"
          />
        );
      case "DROP_DOWN":
        return (
          <>
            <Label htmlFor={item.id?.toString()} required={isRequired}>
              {itemTitle}
            </Label>
            <p className={css(cs.note)}>
              Add dropdown choices in the properties panel
            </p>
            <Select
              placeholder={itemPlaceholder}
              assistiveText={assistiveText}
              name={itemName}
              onChange={() => undefined}
              options={fmtSelectOptions(truncatedSelections)}
              value={selections[0]?.id || selections[0]?.title}
            />
            {!!additionalDSValuesCount && (
              <div className={css(cs.moreValuesOption)}>
                <MoreValuesNotification
                  additionalCount={additionalDSValuesCount}
                  count={totalSelections}
                  dsKey={dataSourceKey}
                />
              </div>
            )}
          </>
        );
      case "LOCATION":
        return (
          <LocationField
            question={{
              id: item.id!,
              rootId: item.rootId!,
              formProperties: {
                isRequired: false,
                isSearchable: false,
              },
              type: item.type,
              subType: item.subType,
              title: itemTitle,
              workflowType: "DRAFT",
            }}
          />
        );
      case "PARTICIPANT":
        return (
          <MultiInput
            assistiveText={assistiveText}
            idField="id"
            label={itemTitle}
            labelField="title"
            canUseCustomValues={true}
            name={itemName}
            onAddItem={() => null}
            onRemoveItem={() => null}
            placeholder={itemPlaceholder}
            required={isRequired}
            assistiveLink={{
              label: "Add Participant",
              onClick: () => null,
            }}
          />
        );
      case "RADIO_BUTTONS":
        return (
          <>
            <Label htmlFor={item.id!.toString()} required={isRequired}>
              {itemTitle}
            </Label>
            <p className={css(cs.note)}>
              Add radio button choices in the properties panel
            </p>
            <RadioButtonsGroup
              name={itemName}
              value="value"
              label=""
              onChange={() => null}
              options={fmtRadioOptions(truncatedSelections)}
            />
            <AssistiveText>{assistiveText}</AssistiveText>
            {!!additionalDSValuesCount && (
              <MoreValuesNotification
                additionalCount={additionalDSValuesCount}
                count={totalSelections}
                dsKey={dataSourceKey}
              />
            )}
          </>
        );
      case "TEXT_AREA":
        return (
          <TextInput
            assistiveText={assistiveText}
            id={item.id!.toString()}
            label={itemTitle}
            name={itemName}
            placeholder={itemPlaceholder}
            required={isRequired}
            multiline={true}
            rows={5}
          />
        );
      case "TEXT_LINE":
        return (
          <TextInput
            assistiveText={assistiveText}
            label={itemTitle}
            name={itemName}
            placeholder={itemPlaceholder}
            required={isRequired}
          />
        );
      case "TIME":
        return (
          <DateTime
            assistiveText={assistiveText}
            label={itemTitle}
            name={itemName}
            required={isRequired}
            type="time"
          />
        );
      case "YES_OR_NO":
        return (
          <YesNoField
            question={{
              id: item.id!,
              rootId: item.rootId!,
              formProperties: {
                isRequired: false,
                isSearchable: false,
              },
              type: item.type,
              subType: item.subType,
              title: itemTitle,
              workflowType: "DRAFT",
            }}
          />
        );
      default:
        return null;
    }
  }

  if (item.type === "WIDGET") {
    const AppWidgets = appWidgets;
    return (
      <AppWidgets
        item={item}
        qaBase={qaBase}
        selected={selected}
        siblings={siblings}
        itemName={itemName}
      />
    );
  }

  if (item.type === "CONTENT") {
    switch (item.subType) {
      case "BLOCK":
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: !emptyContent(item.content)
                ? item.content
                : "<p style='font-style: italic; margin-bottom: 0'>A read-only content block will go here</p>",
            }}
          />
        );
      case "LINE_BREAK":
        return <hr style={{ width: "100%" }} />;
      case "BANNER_MESSAGE":
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: !emptyContent(item.content)
                ? item.content
                : "<p style='font-style: italic; margin-bottom: 0'>A banner message will go here</p>",
            }}
            style={{
              backgroundColor:
                "backgroundColor" in item ? item.backgroundColor : "none",
              color:
                "textColor" in item ? item.textColor : theme.masterColors.black,
              borderRadius: remCalc(4),
              border: `1px solid ${theme.colors.lightGrey}`,
              padding: !emptyContent(item.content)
                ? `${remCalc(16)} ${remCalc(16)} 0`
                : remCalc(16),
            }}
          />
        );
      default:
        return null;
    }
  }
  return null;
}
