import { ArcGisAction, ArcGisActionType } from "./arcGisActions";

export interface ArcGisState {
  isLoadingConfig: boolean;
}

const initialState: ArcGisState = {
  isLoadingConfig: false,
};

export const arcGisReducer = (
  state = initialState,
  action: ArcGisAction
): ArcGisState => {
  switch (action.type) {
    case ArcGisActionType.SetIsLoadingConfig:
      return { ...state, isLoadingConfig: action.isLoadingConfig };
    default:
      return state;
  }
};
