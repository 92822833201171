import styled from "styled-components";

import { Chevron as BaseChevron } from "../../styles";
import { H2 as BaseH2 } from "../../../../clientAdmin/styles";
import { toRgba } from "../../../../../themes/helpers";

// FIXME - remove negative margins
export const H2 = styled(BaseH2)`
  box-shadow: 0px 5px 2px -3px ${({ theme }) => toRgba(theme.colors.black, 0.2)};
  margin-left: -15px;
  margin-right: -15px;
`;

export const Chevron = styled(BaseChevron)`
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  // Disabling this temporarily, it's stretched -- GK
  // :hover {
  //   background: ${({ theme }) => toRgba(theme.colors.black, 0.08)};
  // }
`;

export const TextWrapper = styled.span`
  display: flex;
`;
