import { API, CoreRedux } from "@rtslabs/field1st-fe-common";
import { useMemo } from "react";
import { useAppSelector } from "../../store/hooks";

export const useUserInfo = () => {
  const user = useAppSelector(CoreRedux.selectUser);

  const userInfo = useMemo(() => {
    const firstName =
      user?.firstName || API.Environment.getParsedAuthToken?.firstName || "";
    const lastName =
      user?.lastName || API.Environment.getParsedAuthToken?.lastName || "";

    return {
      firstName,
      initials: getUserInitials(firstName, lastName),
      lastName,
    };
  }, [user]);

  return userInfo;
};

const getUserInitials = (firstName: string, lastName: string) => {
  let initials = "";
  if (firstName) {
    initials += firstName[0].toUpperCase();
  }
  if (lastName) {
    initials += lastName[0].toUpperCase();
  }
  return initials;
};
