import React from "react";
import { CSSProperties } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";
import { remCalc } from "../../../../../../../themes/helpers";

import styled from "styled-components";
import RadioBase, { RadioProps as RadioPropsBase } from "@mui/material/Radio";
import RadioGroupBase, {
  RadioGroupProps as RadioGroupPropsBase,
} from "@mui/material/RadioGroup";
import SvgIconBase, { SvgIconProps } from "@mui/material/SvgIcon";

import { FieldErrorType } from "../../../../../../../api/form.types";
import mediaQueries from "../../../../../../../themes/mediaQueries";

export const SvgIcon = styled<React.FC<SvgIconProps>>(({ ...otherProps }) => (
  <SvgIconBase {...otherProps} classes={{ root: "root" }} />
))`
  &&.root {
    font-size: ${remCalc(24)};

    @media (${mediaQueries.minTablet}) {
      font-size: ${remCalc(24)};
    }
  }
`;

interface RadioProps extends RadioPropsBase {
  error?: FieldErrorType;
}

export const Radio = styled<React.FC<RadioProps>>((props) => (
  <RadioBase {...props} classes={{ root: "root" }} />
))`
  &&.root {
    color: ${({ theme, error }) =>
      error ? theme.masterColors.error : theme.colors.radioCircleBorder};
    // padding-left: ${remCalc(4)};
    width: auto;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const CheckIcon = styled.span`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.iconFill};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  padding: ${remCalc(1)};

  @media (${mediaQueries.minTablet}) {
    font-size: ${remCalc(24)};
  }
`;

interface RadioGroupProps extends RadioGroupPropsBase {
  powerfieldsVariant: "DEFAULT" | "RATING";
  stacked?: boolean;
}

export const RadioGroup = styled<React.FC<RadioGroupProps>>(
  ({ powerfieldsVariant, stacked, ...otherProps }) => (
    <RadioGroupBase
      {...otherProps}
      classes={{
        root: "root",
      }}
    />
  )
)`
  &&.root {
    display: flex;
    flex-direction: ${({ stacked }) => (stacked ? "column" : "row")};

    &:hover {
      background-color: transparent;
    }

    ${({ powerfieldsVariant }) => {
      if (powerfieldsVariant === "RATING") {
        return `
          width: 100%;
          flex-wrap: nowrap;

          // MOBILE RESPONSIVE STYLES FOR RATING VARIANT
          @media (${mediaQueries.maxPhone}) {
            flex-direction: row;
          }
        `;
      }
    }}
  }
`;

export default function (): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    scaleContainer: {
      fontSize: remCalc(14),
      background: theme.masterColors.lightWhite,
      padding: "1rem",
      display: "flex",
    },
    scaleBody: {
      ":nth-child(1n) p": {
        marginBottom: "0.1rem",
      },
    },
    radioSection: {
      display: "flex",
    },
    radioGroup: {
      flexGrow: 1,
      marginLeft: "1rem",
    },
    radioOption: {
      // @TODO checked styles
      backgroundColor: theme.colors.formFieldBackground,
      borderColor: theme.masterColors.mediumGrey,
      borderStyle: "solid",
      borderWidth: "1px 1px 1px 0",
      display: "flex",
      flexDirection: "column",
      minHeight: remCalc(85),
      marginLeft: 0,
      marginTop: 0,
      padding: "0.25rem",
      textAlign: "center",
      width: "25%",
      ":first-of-type": {
        borderLeftWidth: "1px",
        borderRadius: "1rem 0px 0px 1rem",
      },
      ":last-of-type": {
        borderRadius: "0 1rem 1rem 0",
      },
    },
    radioOptionLabel: {
      color: theme.masterColors.darkGrey,
      whiteSpace: "pre-wrap",
    },
    small: {
      fontSize: remCalc(12),
    },
    bold: {
      fontWeight: 700,
    },
  });
}
