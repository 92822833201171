import React from "react";
import { css } from "aphrodite/no-important";
import { RadioButtonUnchecked } from "@mui/icons-material";
import { useFormikContext } from "formik";

import { RadioGroup, Radio, SvgIcon, CheckIcon } from "./styles";
import Label from "../../../../../../Label/Label";

import { FBForm, FBQuestion, FBItem } from "../../../../../types";
import { getItemInSection } from "../../../properties/getItemInSection";
import styles from "./styles";
import CheckboxGroup, {
  CheckboxGroupOption,
} from "../../../../../../Document/DocumentForm/CheckboxField/CheckboxGroup";
import { SafetyRatingWidgetSaveVm } from "@rtslabs/field1st-fe-common";

export type RatingSelection = {
  title: string;
  id: number | null;
};

export const ratingSelections: RatingSelection[] = [
  { title: "Risk", id: null },
  { title: "Coaching Moment", id: null },
  { title: "Satisfactory", id: null },
  { title: "Best Practice", id: null },
];

const RatingsWidget = ({
  item,
  qa,
}: {
  item: FBItem<SafetyRatingWidgetSaveVm>;
  qa?: string;
}) => {
  const s = styles();
  const { values } = useFormikContext<FBForm>();

  // get the parent MULTI_SELECT item
  const parentItem = getItemInSection(
    values,
    item.parentSectionRootId,
    item.parentQuestionRootId
  ) as FBQuestion;

  const initialOptions: CheckboxGroupOption[] =
    parentItem?.selections && parentItem.selections.length > 0
      ? parentItem.selections.map((selection, index) => ({
          label: { icon: selection.properties?.icon, text: selection.title },
          value: selection.id || index,
          hideLabel: selection.properties?.hideChoiceLabel,
        }))
      : [
          {
            label: { text: "Area to review" },
            value: 0,
          },
        ];

  return (
    <div data-testid={qa}>
      <Label htmlFor={item.id!.toString()}>
        Which areas do you want to review?
      </Label>
      <CheckboxGroup
        options={initialOptions}
        onChange={() => undefined}
        selected={[]}
      />
      {item.displayDescription && (
        <div className={css(s.scaleContainer)}>
          <div>
            <strong>How to use the safety scale</strong>
          </div>
          <div className={css([s.scaleBody, s.small])}>
            <p>
              <strong>Risk:</strong> A situation involving exposure to danger.
            </p>
            <p>
              <strong>Coaching Moment:</strong> When additional effort is
              required to prevent a risk.
            </p>
            <p>
              <strong>Satisfactory:</strong> Meeting expectations is acceptable
            </p>
            <p>
              <strong>Best Practice:</strong> Most effective or above
              expectations
            </p>
          </div>
        </div>
      )}

      {/* @TODO make a common Radio Input component */}
      <Label htmlFor={`item_${item.id}`}>Area to Review</Label>
      <div className={css(s.radioSection)}>
        <p className={css([s.small, s.bold])}>Subcategory</p>
        <RadioGroup
          className={css(s.radioGroup)}
          stacked={false}
          aria-label={""}
          powerfieldsVariant="RATING"
          name={`item_${item.id}`}
          value={""}
          onChange={() => null}
        >
          {ratingSelections.map((option, i) => (
            <div className={css(s.radioOption)} key={`${option.title}_${i}`}>
              <Radio
                icon={
                  <SvgIcon>
                    <RadioButtonUnchecked />
                  </SvgIcon>
                }
                checkedIcon={
                  <CheckIcon className="icon icon-icons8-checkmark" />
                }
              />
              <span className={css([s.radioOptionLabel, s.small])}>
                {option.title}
              </span>
            </div>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

export default RatingsWidget;
