import React from "react";
import { ErrorToast } from "../../../../../shared/src/components/Toast/ErrorToast";
import { SuccessToast } from "../../../../../shared/src/components/Toast/SuccessToast";
import {
  Components,
  ElementType,
  QAProps,
} from "../../../../../shared/src/qa-slugs";

interface FormTypeToastsProps extends QAProps {
  status: "CONFIRM" | "SUCCESS" | "FAIL";
  onDelay: () => void;
  failMessage: string;
  successMessage: string;
}

export const FormTypeToasts = ({
  status,
  onDelay,
  failMessage,
  successMessage,
  qa,
}: FormTypeToastsProps) => {
  return (
    <>
      <SuccessToast
        qa={qa ? `${qa}-success` : undefined}
        visible={status === "SUCCESS"}
        onDelay={onDelay}
        component={() => <>{successMessage}</>}
      />
      <ErrorToast
        qa={qa ? `${qa}-failure` : undefined}
        visible={status === "FAIL"}
        onDelay={onDelay}
        component={() => <>{failMessage}</>}
      />
    </>
  );
};
