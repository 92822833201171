import React from "react";

import Label from "../Label/Label";
import styles from "./DisabledTextField.module.scss";

interface Props {
  label?: string;
  value?: string;
  name: string;
}

const DisabledTextField = ({ name, label, value }: Props) => {
  return (
    <>
      <Label htmlFor={name}>{label}</Label>
      <span className={styles.disabledTextField} id={name}>
        {value}
      </span>
    </>
  );
};

export default DisabledTextField;
