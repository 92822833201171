import {
  API,
  BasicDataSourceValue,
  QuestionDTO,
  ResponseContent,
  useDataSourceValues,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import {
  LazyLoadSelect,
  LazyLoadSelectProps,
} from "../../../Select/LazyLoadSelect";
import { SelectOption } from "../../../Select/Select";
import CommentField from "../CommentField/CommentField";
import { usePhotos } from "../usePhotos";
import { useQuestion } from "../useQuestion";
import styles from "./DropdownField.module.scss";

interface DropdownFieldOption extends SelectOption {
  rootId?: number | null;
  content?: ResponseContent | null;
}

interface DropdownFieldProps extends Omit<LazyLoadSelectProps, "onChange"> {
  question: QuestionDTO;
  appPath?: string;
}

export const DropdownField: FC<DropdownFieldProps> = ({
  question,
  appPath,
}) => {
  const selectProps = useQuestion(question);
  const {
    commentError,
    commentRequired,
    commentValue,
    enableComments,
    onBlur,
    name,
    handleChangeBasicResponse,
    onChangeComment,
    response,
  } = selectProps;
  const [oldDSValue, setOldDSValue] = useState<DropdownFieldOption>();

  const value = response?.associatedId;
  const answerValue = response?.answer;

  const { photos, handleUpdatePhoto } = usePhotos(
    question.rootId,
    undefined,
    appPath
  );

  /**
   * Data Sources
   */
  const dataSourceKey = question.answerSource?.dataSourceKey;
  let loadMore: (() => void) | undefined;
  let options: DropdownFieldOption[];
  let isLoading: boolean = false;
  let isLastPage: boolean = true;
  if (dataSourceKey) {
    /**
     * Hooks aren't allowed to be conditionally rendered, but
     * answerSource?.dataSourceKey will either always exist or it won't,
     * so this hook will either always run or it never will... I think.
     */
    const sort =
      question.answerSource?.properties?.detailedSearch?.infiniteListSortBy;
    const answerField = question.answerSource?.properties?.answerField;
    const results = useDataSourceValues<
      BasicDataSourceValue,
      Array<string> | undefined
    >({
      answerField,
      dataSourceKey,
      getDataSourceValues: API.getDataSourceValues,
      sort: sort ? [sort, "asc"] : undefined,
    });

    options = results.dataSourceUIListItems.map((i) => ({
      label: i.title,
      value: i.id,
      content: i.content,
    }));

    if (!!options.length) {
      if (
        response &&
        !oldDSValue &&
        !options.find((item) => item.value === response.associatedId)
      ) {
        setOldDSValue({
          value: response.associatedId!,
          label: response.answer,
        });
      }

      if (!!oldDSValue) {
        options.unshift(oldDSValue);
      }
    }

    ({ isLoading, loadMore, isLastPage } = results);
  } else if (question.selections) {
    options = question.selections.map((i) => ({
      label: i.title,
      value: i.id,
      rootId: i.rootId,
      associatedId: i.id,
    }));
  } else {
    options = [];
  }

  const handleChangeOption = async (option: DropdownFieldOption | null) => {
    if (option) {
      const o = options.find((o) => o.value === option.value);
      if (o) {
        handleChangeBasicResponse({
          id: response?.id ?? undefined,
          answer: o.label,
          associatedId: option.value as number,
          associatedRootId: option.rootId,
          content: o.content,
        });
        photos.forEach((photo) => {
          handleUpdatePhoto({
            ...photo,
            associatedId: option.value as number,
          });
        });
      }
    }
  };

  const handleChangeComment = (comments: string) => {
    onChangeComment(comments);
  };

  const handleClearComment = () => {
    onChangeComment("");
  };

  return (
    <div className={styles.selectInputWrapper} onBlur={onBlur}>
      <LazyLoadSelect
        {...selectProps}
        // className={styles.selectStyle}
        answerValue={answerValue}
        onChange={(option) => handleChangeOption(option)}
        options={options}
        onLoadMore={loadMore}
        isLoadingMore={isLoading}
        isFinalPage={isLastPage}
        value={value}
      />
      {enableComments && value && (
        <CommentField
          commentValue={commentValue}
          error={commentError}
          name={`${name}_comment`}
          handleUpdateComment={handleChangeComment}
          handleClearComment={handleClearComment}
          required={commentRequired}
          response={response}
        />
      )}
    </div>
  );
};
