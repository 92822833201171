import React from "react";
import { css } from "aphrodite/no-important";

import styles from "./styles";

interface Props {
  collapsed: boolean;
  toggleCollapse: () => void;
}

const CollapseCaret = ({ collapsed, toggleCollapse }: Props) => {
  const s = styles();
  return (
    <div
      className={css(s.caretContainer)}
      onClick={(event) => {
        event.stopPropagation();
        toggleCollapse();
      }}
    >
      <i
        className={`icon icon-icons8-sort_down ${css([
          s.caret,
          collapsed && s.collapsed,
        ])}`}
      />
    </div>
  );
};

export default CollapseCaret;
