import { FBItem, FBSection } from "../../../../../types";
import { getItemName } from "../../../content/Item";
import { SelectOption } from "../../../../../../Select/Select";
import { ItemParams } from "../../../Create";

export function getTargetItems(sections: FBSection[]): DCTargetOption[] {
  return sections.flatMap((section: FBSection) => [
    {
      ...section,
      id: section.rootId,
      sectionIndex: section.sectionIndex,
    },
    ...section.items.map((item) => ({
      ...item,
      id: item.rootId,
      sectionIndex: section.sectionIndex,
      itemIndex: item.itemIndex,
    })),
  ]);
}

type DCTargetOption =
  | (FBItem & { sectionIndex: number; itemIndex: number })
  | (FBSection & { sectionIndex: number });

function buildTargetOptionTitle(
  item: DCTargetOption,
  appWidgetsList: ItemParams[]
) {
  let title = `Section ${item.sectionIndex + 1}`;
  switch (item.type) {
    case "SECTION":
      break;
    case "WIDGET":
    case "CONTENT":
      title = `${title}, Item ${item.itemIndex + 1}: ${getItemName(
        item,
        appWidgetsList
      )}`;
      break;
    default:
      title = `${title}, Item ${item.itemIndex + 1}: ${
        item.title || `Untitled ${getItemName(item, appWidgetsList)}`
      }`;
  }
  return title;
}

export function buildTargetOptions(
  targetItems: DCTargetOption[],
  item: FBItem | FBSection,
  appWidgetsList: ItemParams[]
): SelectOption<number>[] {
  // filter out the source (selected) item
  // and any items which belong to widgets
  const availableItems = targetItems.filter(
    (tItem) =>
      tItem.id !== item.id &&
      (item.type === "SECTION" || tItem.id !== item.parentSectionId) &&
      (tItem.type !== "SECTION" || !tItem.parentWidgetRootId)
  );

  return availableItems.map((item) => ({
    value: item.rootId!,
    label: buildTargetOptionTitle(item, appWidgetsList),
  }));
}
