import React, { memo } from "react";

import { getItemIcon } from "./iconMap";
import FormItem from "./FormItem";
import styles from "./styles";
// import SearchGlobalFields from "./SearchGlobalFields";
import { GeneratorFn, ItemParams } from "../Create";
import { H5 } from "../../../styles";
import { css } from "aphrodite/no-important";
import { initialParticipantAnswerSource } from "../../helpers";

export const fields: ItemParams[] = [
  {
    name: "Add Participants",
    title: "",
    type: "QUESTION",
    subType: "PARTICIPANT",
    workflowType: "DRAFT",
    answerSource: initialParticipantAnswerSource,
  },
  {
    name: "Multi-Select",
    title: "",
    type: "QUESTION",
    subType: "MULTI_SELECT",
    workflowType: "DRAFT",
    selections: [],
  },
  {
    name: "Radio Button",
    title: "",
    type: "QUESTION",
    subType: "RADIO_BUTTONS",
    workflowType: "DRAFT",
    selections: [],
  },
  {
    name: "Yes/No Answer",
    title: "",
    type: "QUESTION",
    subType: "YES_OR_NO",
    workflowType: "DRAFT",
  },
  {
    name: "Dropdown",
    title: "",
    type: "QUESTION",
    subType: "DROP_DOWN",
    workflowType: "DRAFT",
    selections: [],
  },
  {
    name: "Single Line",
    title: "",
    type: "QUESTION",
    subType: "TEXT_LINE",
    workflowType: "DRAFT",
  },
  {
    name: "Paragraph",
    title: "",
    type: "QUESTION",
    subType: "TEXT_AREA",
    workflowType: "DRAFT",
  },
  {
    name: "Date",
    title: "",
    type: "QUESTION",
    subType: "DATE",
    workflowType: "DRAFT",
  },
  {
    name: "Time",
    title: "",
    type: "QUESTION",
    subType: "TIME",
    workflowType: "DRAFT",
  },
];

interface Props {
  add: GeneratorFn;
  itemSelected: boolean;
}

const Fields = ({ add, itemSelected }: Props) => {
  const S = styles({});
  return (
    <>
      {/* Hide Global Fields until further requirements approved -- ED */}
      {/* <div className={css(S.ItemsContainer)}>
        <span className={css(S.Label)}>Global Fields</span>
        <SearchGlobalFields addGlobal={add} />
      </div> */}
      <div className={css(S.ItemsContainer)}>
        <H5 className={css(S.Label)}>Fields</H5>
        {fields.map((field) => (
          <FormItem
            disabled={!itemSelected}
            key={field.name}
            name={field.name}
            icon={getItemIcon(field.subType)}
            onClick={() => add(field)}
          />
        ))}
      </div>
    </>
  );
};

export default memo(Fields);
