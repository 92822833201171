import {
  DocumentVm,
  FormSectionVm,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import { useSectionalContext } from "./useSectionalContext";
import { useFormikContext } from "formik";
import { validate } from "../../DocumentForm/validation";
import {
  getIsSectionValidated,
  getParticipantsNeedToSign,
  getRequiredItemsNotValidated,
} from "../../document.helpers";

interface UseDocumentSectionReturnValues {
  getCurrentSectionIndex: () => number;
  getErrorCount: () => number;
  getNextSection: () => FormSectionVm<SectionItem>;
  getVisibleSectionsTotal: () => number;
  handleBackSection: () => void;
  handleNextSection: () => void;
  handleSelectSection: (id: number) => void;
  handleUpdateDocumentTitle: () => void;
}

export const useDocumentSection = (): UseDocumentSectionReturnValues => {
  const {
    currentSection,
    setCurrentSection,
    setDocumentTitle,
    visibleSections,
  } = useSectionalContext();
  const { values, setErrors } = useFormikContext<DocumentVm>();

  const scrollToTop = () => {
    const wrapperDiv = document.getElementById("contentWrapper");

    if (wrapperDiv) {
      return wrapperDiv.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const getCurrentSectionIndex = (): number => {
    return visibleSections.findIndex(
      (section) => section.id === currentSection.id
    );
  };

  const getErrorCount = (): number => {
    const requiredItems = getRequiredItemsNotValidated(currentSection, values);
    return requiredItems.length;
  };

  const getNextSection = (): FormSectionVm<SectionItem> => {
    return visibleSections[getCurrentSectionIndex() + 1];
  };

  const getVisibleSectionsTotal = (): number => {
    return visibleSections.length;
  };

  const handleSelectSection = (id: number) => {
    const newSection = visibleSections.find((section) => section.id === id);
    setCurrentSection(newSection!);
  };

  const handleBackSection = () => {
    setErrors({});
    const currentIndex = getCurrentSectionIndex();
    const backSection = visibleSections[currentIndex - 1];
    setCurrentSection(backSection);
    scrollToTop();
  };

  const handleNextSection = () => {
    if (getIsSectionValidated(currentSection, values)) {
      setErrors({});
      setCurrentSection(getNextSection());
      scrollToTop();
    } else {
      const filteredParticipantsNeedToSign = getParticipantsNeedToSign(
        currentSection.items,
        values
      );
      const testErrors = validate(values, filteredParticipantsNeedToSign, true);
      setErrors(testErrors);
      scrollToTop(); // TODO: should be scrolling to first error. will figure this out later
    }
  };

  const handleUpdateDocumentTitle = () => {
    const titleConfig = values.form.documentSummaryEntries?.find(
      (dse) => dse.type === "TITLE"
    );

    const titleResponse = values.responses.find(
      (r) => r.questionRootId === titleConfig?.sourceQuestionRootId
    );

    if (titleResponse) {
      setDocumentTitle(titleResponse.answer);
    }
  };

  return {
    getCurrentSectionIndex,
    getErrorCount,
    getNextSection,
    getVisibleSectionsTotal,
    handleBackSection,
    handleNextSection,
    handleSelectSection,
    handleUpdateDocumentTitle,
  };
};
