import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import { Button, LinkButton } from "../Button/Button";
import { ListItem } from "../List/ListItem";
import { ListItemContent, ListItemContentProps } from "../List/ListItemContent";
import { joinClassNames } from "../../helpers/theme.helpers";
import Array from "../../util/array";
import Loader from "../Loader/Loader";
import DrawerHeader from "../Drawer/DrawerHeader";
import ErrorText from "../ErrorText/ErrorText";
import SearchBar from "../SearchBar/SearchBar";
import styles from "./ItemSelectorForm.module.scss";
import { GeoPoint, ResponseContent } from "@rtslabs/field1st-fe-common";
import { ElementType } from "../../qa-slugs";

export interface DataSourceItem extends ListItemContentProps {
  id: number;
  associatedLocation?: GeoPoint | null;
  content?: ResponseContent | null;
}

export type SubmitButtonLabel = {
  prefix: string;
  label: string;
};

interface ItemSelectorFormProps {
  className?: string;
  error?: string;
  handleChangeSearch: (query: string) => void;
  handleClose: () => void;
  handleSubmit: (selectedOptions: DataSourceItem[]) => void;
  hasMultiSelect?: boolean;
  isLastPage?: boolean;
  isLoadingMore?: boolean;
  isLoadingSearchResults?: boolean;
  listTitle: string;
  name: string;
  noResultsMessage: string;
  onLoadMore?: () => void;
  options: DataSourceItem[];
  searched?: boolean;
  searchLabel: string;
  searchPlaceholder?: string;
  selected: DataSourceItem[];
  submitButtonLabel?: SubmitButtonLabel;
  subtitle?: string;
  title: string;
  qa?: string;
}

export const ItemSelectorForm = ({
  className,
  error,
  handleChangeSearch,
  handleClose,
  handleSubmit,
  hasMultiSelect,
  isLastPage,
  isLoadingMore,
  isLoadingSearchResults,
  listTitle,
  name,
  noResultsMessage,
  onLoadMore,
  options,
  searched,
  searchLabel,
  searchPlaceholder,
  selected,
  submitButtonLabel,
  subtitle,
  title,
  qa,
}: ItemSelectorFormProps) => {
  const [selectedResults, setSelectedOptions] = useState<DataSourceItem[]>([]);
  const listTitleId = `${name}-listTitle`;

  useEffect(() => {
    setSelectedOptions(selected);
  }, []);

  const toggleOption = (option: DataSourceItem) => {
    let selectedOptions: DataSourceItem[];
    if (hasMultiSelect) {
      const foundIndex = Array.findIndexById(selectedResults, option.id);
      selectedOptions =
        foundIndex !== -1
          ? Array.remove(selectedResults, foundIndex)
          : Array.push(selectedResults, option);
    } else {
      selectedOptions = [option];
    }
    setSelectedOptions(selectedOptions);
  };

  const localHandleSubmit = () => {
    handleSubmit(selectedResults);
  };

  const getSubmitButtonLabel = () => {
    if (submitButtonLabel) {
      if (selectedResults.length > 1) {
        return (
          <span className={styles.buttonContent}>
            {submitButtonLabel.prefix}
            <span className={styles.selectedCount}>
              {selectedResults.length}
            </span>
            {submitButtonLabel.label}s
          </span>
        );
      }

      return `${submitButtonLabel.prefix} ${submitButtonLabel.label}`;
    }

    return "Submit";
  };

  return (
    <div className={joinClassNames(styles.form, className)}>
      <DrawerHeader onClose={handleClose} subtitle={subtitle} title={title} />
      <div className={styles.searchContainer}>
        <SearchBar
          className={styles.searchBar}
          onSearch={handleChangeSearch}
          label={searchLabel}
          name={`${name}-search`}
          placeholder={searchPlaceholder}
          qa={qa ? `${qa}-${ElementType.TextInput}` : undefined}
        />
        <span className={styles.listTitle} id={listTitleId}>
          {listTitle}
        </span>
      </div>
      <Loader className={styles.loader} loading={isLoadingSearchResults}>
        <ul className={styles.list}>
          {options.length > 0 ? (
            options.map((option) => {
              return (
                <ListItem
                  className={styles.listItem}
                  onClick={() => toggleOption(option)}
                  active={!!Array.findById(selectedResults, option.id)}
                  key={option.id}
                >
                  <ListItemContent {...option} />
                </ListItem>
              );
            })
          ) : (
            <ListItem disabled={true}>
              <ListItemContent title={noResultsMessage} />
            </ListItem>
          )}
          {!isLastPage && !error && onLoadMore && !searched && (
            <VisibilitySensor
              active={options.length > 0 && !isLoadingMore}
              onChange={(isVisible: boolean) => {
                if (isVisible) {
                  onLoadMore();
                }
              }}
              delayedCall={true}
              intervalDelay={500}
            >
              <div className={styles.visibilitySensor}>
                <Loader loading={isLoadingMore} />
              </div>
            </VisibilitySensor>
          )}
        </ul>
        {error && (
          <div className={styles.error}>
            <ErrorText>{error}</ErrorText>
            <LinkButton className={styles.tryAgain} onClick={onLoadMore}>
              Try again
            </LinkButton>
          </div>
        )}
      </Loader>
      <div className={styles.footer}>
        <Button
          disabled={selectedResults.length === 0}
          onClick={localHandleSubmit}
          qa={qa ? `${qa}-${ElementType.Button}-submit` : undefined}
        >
          {getSubmitButtonLabel()}
        </Button>
      </div>
    </div>
  );
};
