import { ParticipantType } from "@rtslabs/field1st-fe-common";
import { SelectOption } from "../../../../../shared/src/components/Select/Select";

export const statusOptions = [
  { value: 0, label: "All Statuses" },
  { value: 1, label: "Active" },
  { value: 2, label: "Disabled" },
  { value: 3, label: "Not registered" },
  { value: 4, label: "N/A" },
];

interface roleSelection extends SelectOption<number> {
  role: ParticipantType | null;
}

export const mockRoleOptions: roleSelection[] = [
  { value: 0, role: null, label: "All Roles" },
  { value: 1, role: "EMPLOYEE", label: "Team Member" },
  { value: 2, role: "EMPLOYEE_SUPERVISOR", label: "Supervisor" },
  { value: 3, role: "CONTRACTOR", label: "Contractor" },
];

export const getParticipantType = (roleIndex: number): ParticipantType => {
  return mockRoleOptions.find((rs) => rs.value === roleIndex)?.role!;
};

export const getRoleLabel = (
  role?: ParticipantType | null
): roleSelection["label"] => {
  return mockRoleOptions.find((rs) => rs.role === role)?.label!;
};
