import React, { FC, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../../Icon/Icon";
import Tooltip from "../../../../Tooltip/Tooltip";
import styles from "./EditButton.module.scss";

interface EditButtonProps {
  iconColor?: string;
  label?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const EditButton: FC<EditButtonProps> = ({
  iconColor,
  label,
  onClick,
}) => {
  const { t } = useTranslation("formBuilder");
  return (
    <Tooltip
      arrow
      placement="top"
      title={label || t("generic.editButtonLabel")}
    >
      <button className={styles.editButton} onClick={onClick} type="button">
        <Icon color={iconColor} type="pencil1" />
      </button>
    </Tooltip>
  );
};
