import React, { FC } from "react";

import { joinClassNames } from "../../../../helpers/theme.helpers";
import Checkbox, { CheckboxProps } from "../../../Checkbox/Checkbox";
import styles from "./CheckboxField.module.scss";

import { DocumentQuestionResponseVm } from "@rtslabs/field1st-fe-common";
import CommentField from "../CommentField/CommentField";

export interface CheckboxFieldProps extends CheckboxProps {
  checked: boolean;
  className?: string;
  commentEnabled?: boolean;
  commentError?: string;
  commentRequired?: boolean;
  commentValue?: string;
  error?: string;
  handleBlur?: () => void;
  onUpdateComment?: (
    value: string,
    response?: DocumentQuestionResponseVm
  ) => void;
  onClearComment?: (response?: DocumentQuestionResponseVm) => void;
  id: string | number;
  label: React.ReactNode;
  response?: DocumentQuestionResponseVm;
}

export const CheckboxField: FC<CheckboxFieldProps> = ({
  checked,
  className,
  commentEnabled,
  commentError,
  commentRequired,
  commentValue,
  error,
  onUpdateComment,
  onClearComment,
  id,
  name,
  response,
  ...checkboxProps
}) => {
  return (
    <div
      className={joinClassNames(
        styles.input,
        checked && styles.checked,
        error && styles.error,
        className
      )}
    >
      <Checkbox
        name={name}
        checked={checked}
        error={error}
        {...checkboxProps}
      />

      {/* When `checked`, we render the option to add a comment */}
      {commentEnabled && onUpdateComment && onClearComment && (
        <CommentField
          error={commentError}
          name={`${id}_comment`}
          commentValue={commentValue}
          disabled={!checked}
          required={commentRequired}
          handleClearComment={onClearComment}
          handleUpdateComment={onUpdateComment}
          qa={`${id}_comment`}
          className={styles.commentField}
          response={response}
          useDynamicTextArea
        />
      )}
    </div>
  );
};
