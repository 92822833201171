import React, { FC } from "react";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import styles from "../OperationalExperiences.module.scss";

interface Props {
  includedCount: number;
  requiredCount: number;
}

export const RequiredOEsText: FC<Props> = ({
  includedCount,
  requiredCount,
}) => {
  return (
    <p
      className={joinClassNames(
        styles.requiredText,
        includedCount >= requiredCount && styles.met
      )}
    >
      {`${includedCount}/${requiredCount} Required`}
    </p>
  );
};
