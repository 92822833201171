import { API, CoreRedux } from "@rtslabs/field1st-fe-common";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PasswordHelp from "../../../shared/src/components/auth/PasswordHelp";
import { AuthRequestStatus } from "../../../shared/src/components/auth/types";

const PasswordHelpScreen = () => {
  const [status, setStatus] = useState<AuthRequestStatus>();

  const navigate = useNavigate();
  const logo = useSelector(CoreRedux.selectPrimaryLogoImageProps);

  const onRequestReset = async (
    args: API.RequestPasswordResetArgs
  ): Promise<void> => {
    try {
      await API.requestPasswordReset(args);
      setStatus("success");
    } catch {
      setStatus("failure");
    }
  };

  return (
    <PasswordHelp
      onRequestReset={onRequestReset}
      status={status}
      logo={logo}
      onReturnToLogin={() => navigate("/login")}
    />
  );
};

export default PasswordHelpScreen;
