import React, { CSSProperties } from "react";
import { ThemeContext } from "styled-components";
import { StyleSheet } from "aphrodite";

import { remCalc } from "../../../../../themes/helpers";

export default function (): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    section: {
      scrollMargin: "20px",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.masterColors.white,
      border: `1px solid ${theme.masterColors.mediumGrey}`,
      borderRadius: remCalc(4),
      padding: `0 ${remCalc(16)} ${remCalc(16)}`,
      marginBottom: remCalc(20),
    },

    emptyStateImage: {
      width: remCalc(229),
      height: remCalc(167),
      marginBottom: remCalc(24),
    },

    sectionTitleContainer: {
      paddingTop: 15,
      paddingBottom: 10,
      justifyContent: "space-between",
      alignItems: "center",
    },

    sectionTitle: {
      display: "block",
      fontSize: remCalc(20),
      letterSpacing: remCalc(0.19),
      lineHeight: remCalc(26),
      fontWeight: 500,
      marginLeft: 15,
    },

    selected: {
      borderWidth: 2,
      backgroundColor: theme.colors.primaryFill,
      borderColor: theme.colors.primary,
    },

    alertItem: {
      borderColor: theme.colors.warning,
    },

    alertItemSelected: {
      backgroundColor: theme.colors.warningFill,
    },

    errorItem: {
      borderColor: theme.colors.error,
    },

    errorItemSelected: {
      backgroundColor: theme.colors.errorFill,
    },

    padding: {
      padding: "10px 0",
    },

    item: {
      scrollMargin: "20px",
      padding: `0 ${remCalc(14)} ${remCalc(14)}`,
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.masterColors.mediumGrey}`,
      borderRadius: remCalc(4),
      marginBottom: remCalc(16),
    },

    itemContent: {
      flexGrow: 1,
    },

    itemNameWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: 40,
    },

    itemName: {
      fontSize: remCalc(14),
      color: theme.masterColors.darkGrey,
      lineHeight: remCalc(19),
      letterSpacing: remCalc(0.13),
      marginLeft: 15,
    },

    grabberContainer: {
      display: "flex",
      marginLeft: remCalc(16),
      alignItems: "center",
      justifyContent: "center",
    },

    emptySectionMessage: {
      display: "block",
      color: theme.masterColors.darkGrey,
      letterSpacing: remCalc(0.15),
      lineHeight: remCalc(21),
      fontStyle: "italic",
    },

    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      margin: `0 ${remCalc(16)} ${remCalc(16)} 0`,
    },

    deleteButton: {
      width: remCalc(56),
      height: remCalc(19),
      padding: 0,
      margin: 0,
      color: theme.colors.error,
    },

    deleteButtonContainer: {
      marginBottom: remCalc(4),
    },

    placeholder: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: theme.colors.background,
      alignItems: "center",
      border: `2px dashed ${theme.colors.activeFill}`,
      padding: `${remCalc(82)} ${remCalc(77)}`,
    },

    placeholderTitle: {
      display: "block",
      fontSize: remCalc(24),
      letterSpacing: remCalc(0.42),
      lineHeight: remCalc(32),
      marginBottom: remCalc(12),
    },

    placeholderText: {
      display: "block",
      color: theme.masterColors.darkGrey,
      letterSpacing: remCalc(0.15),
      lineHeight: remCalc(21),
      textAlign: "center",
    },

    note: {
      color: theme.masterColors.darkGrey,
      fontStyle: "italic",
    },

    shiftButton: {
      height: "2rem",
      width: "2rem",
    },

    moreValuesAvailable: {
      fontStyle: "italic",
      color: theme.masterColors.darkGrey,
    },

    moreValuesOption: {
      padding: "6px 16px",
    },
  });
}
