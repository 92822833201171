import React from "react";

import {
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
} from "../../common/styled/Table";
import { getSortDirection } from "./resourceHelpers";
import { SortParams } from "@rtslabs/field1st-fe-common";
import { Components, ElementType } from "../../../qa-slugs";

interface TableHeaderCellProps extends TableCellProps {
  id: string;
  label?: string;
  currentSort?: string | SortParams | string[];
  onSort: (sort: string) => void;
  qa?: string;
}

const TableHeaderCell = ({
  id,
  label,
  currentSort,
  onSort,
  qa,
  ...rest
}: TableHeaderCellProps) => {
  const sortDirection = getSortDirection(id, currentSort);
  let activeSort = "";
  if (typeof currentSort === "string") {
    activeSort = currentSort?.split(",")[0];
  } else if (Array.isArray(currentSort)) {
    activeSort = currentSort[0];
  }

  return (
    <TableCell
      active={activeSort === id}
      onClick={(dir) => onSort(`${id},${dir}`)}
      sortDirection={sortDirection}
      qa={qa}
      {...rest}
    >
      {label || id}
    </TableCell>
  );
};

const TableHeader = ({
  columns,
  onSort,
  currentSort,
}: {
  columns: { id: string; label?: string }[];
  onSort: (sort: string) => void;
  currentSort?: string | SortParams | string[];
}) => (
  <TableHead>
    <TableRow noBottomBorder>
      {columns.map((col) => (
        <TableHeaderCell
          key={col.id}
          id={col.id}
          label={col.label}
          onSort={onSort}
          currentSort={currentSort}
          qa={`${Components.TableHeader}-${ElementType.Button}-sortBy_${col.id}`}
        />
      ))}
    </TableRow>
  </TableHead>
);

export default TableHeader;
