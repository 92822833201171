import React, { FC } from "react";
import Label from "../../../Label/Label";
import styles from "./Signatures.module.scss";

interface Props {
  label: string;
  onClearSignature: () => void;
  signatureUrl: string;
}

export const DrawnSignature: FC<Props> = ({
  label,
  onClearSignature,
  signatureUrl,
}) => {
  return (
    <div className={styles.signature}>
      <div className={styles.labelRow}>
        <div className={styles.maxWidth}>
          <Label>{label}</Label>
        </div>
        <button
          className={styles.clearButton}
          type="button"
          onClick={onClearSignature}
        >
          Clear Signature
        </button>
      </div>
      <div className={styles.submittedImageWrap}>
        <img className={styles.image} src={signatureUrl} />
      </div>
    </div>
  );
};
