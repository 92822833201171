import React, { FC } from "react";
import { Field } from "formik";

import { ConditionActionType, DataSource } from "@rtslabs/field1st-fe-common";
import { FBItem, FBSection } from "../../../../../types";
import { SelectOption } from "../../../../../../Select/Select";

import ps from "../../styles.module.scss";
import bs from "../../../../styles.module.scss";
import ShowHideField from "./ShowHideField";
import ShowHideCondition from "./ShowHideCondition";
import PrefillCondition from "./PrefillCondition";
import DeleteRuleButton from "./DeleteRuleButton";
import { joinClassNames } from "../../../../../../../helpers/theme.helpers";
import { useFBConfigs } from "../../../../../../../util/hooks/useFBConfigs";

type RuleType = ConditionActionType | undefined;

function getRuleTypeValue(ruleType?: RuleType) {
  switch (ruleType) {
    case "PREFILL":
      return "prefill";
    case "SHOW":
    case "HIDE":
      return "showhide";
    default:
      return undefined;
  }
}

interface ConditionProps {
  ruleType?: RuleType;
  itemIndex: number;
  conditionIndex: number;
  onRemove: (index: number) => void;
  onUpdateType: (index: number, type: RuleType) => void;
  responseOptions: SelectOption[];
  targetOptions: SelectOption<number>[];
  targetItems: (FBItem | FBSection)[];
  dataSource?: DataSource;
  sourceColumns: SelectOption<string>[];
  itemSubType: string;
}

const Condition: FC<ConditionProps> = ({
  ruleType,
  itemIndex,
  conditionIndex,
  onRemove,
  onUpdateType,
  responseOptions,
  targetOptions,
  targetItems,
  dataSource,
  sourceColumns,
  itemSubType,
}) => {
  const { formBuilderConfigs } = useFBConfigs();
  const { enableDataSets } = formBuilderConfigs;

  function renderRule() {
    switch (ruleType) {
      case "SHOW":
      case "HIDE":
        return (
          <ShowHideCondition
            conditionIndex={conditionIndex}
            responseOptions={responseOptions}
            targetOptions={targetOptions}
            targetItems={targetItems}
            itemSubType={itemSubType}
          />
        );
      case "PREFILL":
        return (
          !!enableDataSets && (
            <PrefillCondition
              conditionIndex={conditionIndex}
              dataSet={dataSource}
              sourceColumns={sourceColumns}
              targetOptions={targetOptions}
              targetItems={targetItems}
            />
          )
        );
      default:
        return;
    }
  }

  const value = getRuleTypeValue(ruleType);

  return (
    <div className={ps.dcRule}>
      <div className={joinClassNames(bs.row, ps.dcRow)}>
        <b style={{ color: "#000", fontSize: "0.875rem" }}>
          Rule {itemIndex + 1}
        </b>
        <Field
          as={ShowHideField}
          value={value}
          onChange={(e: any) =>
            onUpdateType(
              conditionIndex,
              e.value === "showhide" ? "SHOW" : "PREFILL"
            )
          }
        />
        <DeleteRuleButton onDelete={() => onRemove(conditionIndex)} />
      </div>
      {renderRule()}
    </div>
  );
};

export default Condition;
