import React, { FC } from "react";
import { DateRange } from "shared/src/components/DateRange/DateRange";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import { FilterSelect } from "shared/src/components/Select/FilterSelect";
import { SelectOption } from "shared/src/components/Select/Select";
import { DateRangeValue, TimeFilter } from "shared/src/data/timeFilters";
import { Components, ElementType } from "shared/src/qa-slugs";
import styled from "styled-components";
import { timeFilterOptions } from "shared/src/data/timeFilters";
import { FilterSelectMulti } from "../../../common/MultiFilterSelect";

const DateRangeWrapper = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  dateRange?: DateRangeValue;
  groupFilter: {
    groupId?: number;
    setGroupId: (id?: number) => void;
    groupFilterOptions: SelectOption<number>[];
  };
  handleChangeDateRange: (dateRange?: DateRangeValue) => void;
  ratingsFilter: {
    ratingsFilter: string[];
    setRatingsFilter: (filter: string[]) => void;
    ratingOptions: string[];
  };
  safetyFlag: boolean;
  setQuery: (query: string) => void;
  timeFilter?: TimeFilter;
  setTimeFilter: (type: TimeFilter) => void;
}

const Filters: FC<Props> = ({
  dateRange,
  groupFilter: { groupId, setGroupId, groupFilterOptions },
  handleChangeDateRange,
  ratingsFilter: { ratingsFilter, setRatingsFilter, ratingOptions },
  safetyFlag,
  setQuery,
  timeFilter,
  setTimeFilter,
}) => {
  const onClear = () => {
    setTimeFilter(TimeFilter.ALL_TIME);
    handleChangeDateRange(undefined);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-2 mt-2">
        <div className="mr-4">
          <FilterSelect
            name="timeFilter"
            value={timeFilter}
            label=""
            options={timeFilterOptions}
            onChange={(option) => {
              setTimeFilter(option?.value || TimeFilter.ALL_TIME);
            }}
            placeholder="All Times"
            qa={`${Components.FormReport}-${ElementType.SelectInput}-timeFilter`}
          />
        </div>
        <div className="mr-3">
          {/* TODO real group filter */}
          <FilterSelect
            name="groups"
            value={groupId}
            label=""
            options={groupFilterOptions}
            onChange={(option) => setGroupId(option?.value)}
            placeholder="All Groups"
            qa={`${Components.FormReport}-${ElementType.SelectInput}-groupFilter`}
          />
        </div>
        {safetyFlag && (
          <div
            className="mr-3"
            data-testid={`${Components.FormReport}-${ElementType.SelectInput}-ratingFilter`} // Tagging parent
          >
            <FilterSelectMulti
              name="rating"
              value={ratingsFilter}
              label=""
              options={ratingOptions}
              handleChange={(e) => {
                setRatingsFilter(e.target.value);
              }}
            />
          </div>
        )}
        <SearchBar
          qa={`${Components.FormReport}-${ElementType.TextInput}-search`}
          onSearch={(newQuery) => setQuery(newQuery)}
        />
      </div>
      {timeFilter === TimeFilter.CUSTOM_RANGE && (
        <DateRangeWrapper>
          <DateRange
            handleChange={handleChangeDateRange}
            labelId="customRangeId"
            name="customRange"
            initialValues={{
              from: dateRange?.startDate,
              to: dateRange?.endDate,
            }}
            onClear={onClear}
            qa={`${Components.FormReport}-${ElementType.SelectInput}-dateRangeFilter`}
          />
        </DateRangeWrapper>
      )}
    </>
  );
};

export default Filters;
