import React from "react";
import { css } from "aphrodite/no-important";
import styles from "./styles";

const Banner = ({
  children,
  type,
}: {
  children: React.ReactNode;
  type: "warning" | "error";
}) => {
  const s = styles(type);

  return <div className={css(s.notice)}>{children}</div>;
};

export default Banner;
