import React from "react";
import { TextInput } from "../../../TextInput/TextInput";
import { TextInputProps } from "../../../TextInput/types";
import { Components, ElementType } from "../../../../qa-slugs";

const ResourceTitleField = (fieldProps: TextInputProps) => {
  return (
    <TextInput
      qa={`${Components.ResourceTitleField}-${ElementType.TextInput}-resourceTitle`}
      label="Title"
      placeholder="Resource title"
      {...fieldProps}
    />
  );
};

export default ResourceTitleField;
