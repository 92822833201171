import React from "react";

import styles from "./Alert.module.scss";
import { joinClassNames } from "../../helpers/theme.helpers";

interface AlertProps {
  variant: "warning" | "error";
  title: string;
  message?: string;
  isVisible?: boolean;
  className?: string;
}

export const Alert = ({
  variant,
  title,
  message,
  isVisible = true,
  className,
}: AlertProps) => {
  if (!isVisible) {
    return null;
  }

  const getAlertVariantClassName = () => {
    switch (variant) {
      case "error":
        return styles.error;
      default:
        return styles.warning;
    }
  };

  const shouldHaveAlertRole = variant === "error";

  return (
    <div
      className={joinClassNames(
        styles.alert,
        getAlertVariantClassName(),
        className
      )}
      role={shouldHaveAlertRole ? "alert" : undefined}
    >
      <h3 className={styles.title}>{title}</h3>
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
};
