import React, { useState } from "react";

import * as S from "./styles";

type LinkBuilderProps = {
  initialValue?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onConfirm: (link: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const LinkBuilder = ({
  onBlur,
  onFocus,
  onConfirm,
  initialValue = "",
}: LinkBuilderProps) => {
  const [linkUrl, setLinkUrl] = useState<string>(initialValue);

  const handleAddLink = (e: any) => {
    e.preventDefault();
    onConfirm(linkUrl);
    setLinkUrl("");
  };

  return (
    <S.LinkBuilderContainer>
      <S.LinkField
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus
        name="url"
        value={linkUrl}
        onChange={(e) => setLinkUrl(e.target.value)}
      />
      <S.ApplyButton disabled={linkUrl === ""} onClick={handleAddLink}>
        Apply
      </S.ApplyButton>
    </S.LinkBuilderContainer>
  );
};

export default LinkBuilder;
