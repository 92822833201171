import React from "react";
import { TabGroup } from "../../../../Tabs/TabGroup/TabGroup";
import { GeneratorFn, ItemParams } from "../Create";
import Elements from "./Elements";
import Fields from "./Fields";
import Widgets from "./Widgets";

interface Props {
  add: GeneratorFn;
  itemSelected: boolean;
  disableAddGallery?: boolean;
  disableAddMap?: boolean;
  appWidgetsList: ItemParams[];
}

const ItemsPanel = ({
  add,
  itemSelected,
  disableAddGallery,
  disableAddMap,
  appWidgetsList,
}: Props) => {
  const tabs = [
    {
      label: "Elements",
      tabId: "elements",
      tabPanelId: "elements",
      tabPanelContent: <Elements add={add} itemSelected={itemSelected} />,
    },
    {
      label: "Fields",
      tabId: "fields",
      tabPanelId: "fields",
      tabPanelContent: <Fields add={add} itemSelected={itemSelected} />,
    },
  ];

  if (appWidgetsList.length) {
    tabs.push({
      label: "Widgets",
      tabId: "widgets",
      tabPanelId: "widgets",
      tabPanelContent: (
        <Widgets
          add={add}
          itemSelected={itemSelected}
          disableAddGallery={disableAddGallery}
          disableAddMap={disableAddMap}
          appWidgetsList={appWidgetsList}
        />
      ),
    });
  }

  return (
    <div>
      <TabGroup variant="white" tabs={tabs} />
    </div>
  );
};

export default ItemsPanel;
