import { ResourceDTO, TagLibraryVM } from "@rtslabs/field1st-fe-common";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import ResourceForm from "./ResourceForm";
import { resourceSchema, ResourceValues } from "./resourceHelpers";

interface FormikResourceProps {
  initialValues: ResourceValues;
  handleSubmitForm: (
    values: ResourceValues,
    setFieldValue: FormikHelpers<ResourceValues>
  ) => Promise<void>;
  onSearch: (search: string) => Promise<TagLibraryVM[] | undefined>;
  resourceSummary?: ResourceDTO;
}

export const FormikResource = ({
  initialValues,
  handleSubmitForm,
  onSearch,
  resourceSummary,
}: FormikResourceProps) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      enableReinitialize
      validationSchema={resourceSchema}
      // validateOnChange={submitCount > 0}
      // validateOnBlur={submitCount > 0}
    >
      <Form
        id="resourcesForm"
        translate="yes"
        onKeyDown={(e) => {
          if ((e.charCode || e.keyCode) === 13) {
            e.preventDefault();
          }
        }}
      >
        <ResourceForm onSearch={onSearch} resourceSummary={resourceSummary} />
      </Form>
    </Formik>
  );
};
