import React, { FC, ComponentType } from "react";
import { Navigate } from "react-router-dom";

export interface AuthenticatedRouteProps {
  Component: ComponentType<any>;
  accessRoles: string[];
  userRoles: string[];
  isAuthenticated: boolean;
  redirectPath?: string;
}

export const AuthenticatedRoute: FC<AuthenticatedRouteProps> = ({
  Component,
  accessRoles,
  userRoles,
  isAuthenticated,
  redirectPath,
}) => {
  // Compare user roles to required roles
  const hasRequiredRole = userRoles.some((r) => accessRoles.includes(r));

  if (isAuthenticated) {
    if (hasRequiredRole) {
      return <Component accessRoles={accessRoles} />;
    }
    return (
      <Navigate
        replace
        to={{
          pathname: "/no-permissions",
        }}
      />
    );
  }
  // else redirect to login
  return (
    <Navigate
      replace
      state={{
        redirect: redirectPath,
      }}
      to={{
        pathname: "/login",
      }}
    />
  );
};
