import React, { FC, useMemo, useState } from "react";
import { Formik } from "formik";
import { delay } from "lodash";
import * as yup from "yup";
import { ParticipantDTO } from "@rtslabs/field1st-fe-common";
import { DataSourceItem } from "../ItemSelectorDrawer/ItemSelectorForm";
import { ShareFormButtons } from "./components/ShareFormButtons";
import ErrorText from "../../components/ErrorText/ErrorText";
import { ItemSelectorDrawer } from "../ItemSelectorDrawer/ItemSelectorDrawer";
import { List } from "../List/List";
import { Icon } from "../Icon/Icon";
import { ListItem } from "../List/ListItem";
import styles from "./ShareForm.module.scss";
import { ListItemContent } from "../List/ListItemContent";
import { OptionContent, SearchSelect } from "../SearchableSelect/SearchSelect";
import Label from "../Label/Label";
import { useDataSourceValues } from "../../util/hooks/useDataSourceValues";

interface Values {
  members: DataSourceItem[];
}

const initialValues: Values = {
  members: [],
};

const validationSchema = yup.object().shape({
  members: yup.array().required("Please select a member").min(1),
});

interface Props {
  closeDrawer: () => void;
  onShareDocument: (participantIds: number[]) => Promise<void>;
}

const ShareForm: FC<Props> = ({ closeDrawer, onShareDocument }) => {
  const [inputVal, setInputVal] = useState<OptionContent | null>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  // TODO copied from old code - could be simplified -JA
  const [submitStatus, setSubmitStatus] = useState<
    "success" | "failure" | "request" | null
  >(null);

  const {
    dataSourceValues,
    loading: dsvLoading,
    onLoadMore,
    loadingMore: dsvLoadingMore,
    setQuery,
    error: dsvError,
    lastPage,
  } = useDataSourceValues<ParticipantDTO>("PARTICIPANT", "fullName");

  const suggestions = useMemo(
    () =>
      dataSourceValues.map((v) => ({
        id: v.id,
        label: v.content.fullName || "-",
      })),
    [dataSourceValues]
  );

  if (dsvError) {
    // TODO copied from old code - may be outdated -JA
    return (
      <ErrorText>
        DATA SOURCE API call has detected an error and is intentionally being
        prevented. Please sign out and try again or contact your system admin.
      </ErrorText>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values: Values) => {
        setSubmitStatus(null);

        const participantIds = values.members.map((member) => member.id);

        try {
          setSubmitStatus("request");
          await onShareDocument(participantIds);
          delay(() => closeDrawer(), 1000);
          setSubmitStatus("success");
        } catch (err) {
          setSubmitStatus("failure");
        }
      }}
    >
      {({ values, setValues, handleSubmit, getFieldProps }) => {
        function selectMember(id: number): void {
          const member = dataSourceValues.find(
            (dsv) => dsv.content.id?.toString() === id.toString()
          );
          if (member) {
            const value = `${member.content.lastName}, ${member.content.firstName}`;
            setValues({
              members: [
                ...values.members,
                {
                  id: member.id,
                  title: value,
                },
              ],
            });
          }
        }

        const disabled = submitStatus
          ? ["request", "success"].includes(submitStatus)
          : !values.members.length;

        return (
          <>
            <ItemSelectorDrawer
              error={dsvError}
              handleChangeOpen={(isOpen: boolean) => {
                if (isOpen) {
                  setQuery("");
                }
                setDrawerOpen(isOpen);
              }}
              handleChangeSearch={setQuery}
              handleSubmit={(selections: DataSourceItem[]) => {
                setValues({ members: selections });
                setDrawerOpen(false);
              }}
              hasMultiSelect={true}
              isLoadingMore={dsvLoadingMore}
              isLoadingSearchResults={dsvLoading}
              isOpen={drawerOpen}
              listTitle="Add Another Member"
              name="ParticipantSelectorDrawer"
              noResultsMessage="No Results Found"
              onLoadMore={onLoadMore}
              searchLabel="Search by name"
              searchPlaceholder="Search by name"
              options={dataSourceValues.map((dsv) => ({
                id: dsv.id,
                title: dsv.content.fullName,
              }))}
              dataSourceKey="PARTICIPANT"
              answerSourceFields={{
                answerField: "fullName",
              }}
              selected={values.members}
              submitButtonLabel={{ prefix: "Add", label: "Member" }}
              subtitle="Who would you like to add?"
              title="Add Another Member"
              isLastPage={lastPage}
              qa="share-form"
            />
            <form onSubmit={handleSubmit}>
              <Label
                htmlFor="members"
                assistiveLink={{
                  label: "Add Another Member",
                  onClick: () => {
                    setQuery("");
                    setDrawerOpen(true);
                  },
                }}
              >
                Add Member
              </Label>
              {values.members.length > 0 && (
                <List>
                  {values.members.map((participant) => (
                    <ListItem
                      key={participant.id}
                      className={styles.listOption}
                    >
                      <ListItemContent {...participant} />
                      <div
                        onClick={() => {
                          setValues({
                            members: values.members.filter(
                              (m) => m.id !== participant.id
                            ),
                          });
                        }}
                      >
                        <Icon
                          type="icon-icons8-delete_sign"
                          className="icon icon-icons8-delete_sign"
                        />
                      </div>
                    </ListItem>
                  ))}
                </List>
              )}
              <SearchSelect
                {...getFieldProps("members")}
                placeholder="Add member"
                options={suggestions}
                canSelectMultiple={false}
                handleChange={(value) => {
                  if (!Array.isArray(value) && value?.id) {
                    selectMember(value.id);
                  }
                  setInputVal(value);
                }}
                value={inputVal}
                handleUpdateSearch={(s) => setQuery(s ?? "")}
                isFinalPage={lastPage}
                isLoadingMore={dsvLoadingMore}
                onLoadMore={onLoadMore}
                qa="share-form"
              />

              <ShareFormButtons
                disabled={disabled}
                submitStatus={submitStatus}
                onCancel={closeDrawer}
              />
            </form>
          </>
        );
      }}
    </Formik>
  );
};

ShareForm.displayName = "ShareForm";
export default ShareForm;
