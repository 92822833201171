import React, { FC } from "react";
import { SubmitButton } from "shared/src/components/clientAdmin/styles";
import {
  DisableUserButtonsWrapper,
  ModalPromptCloseIcon,
  ModalPromptSubtitle,
  ModalPromptTitle,
  ModalPromptWrapper,
} from "../styles";

interface Props {
  onClose: () => void;
}
export const DeleteUserSuccess: FC<Props> = ({ onClose }) => (
  <ModalPromptWrapper>
    <ModalPromptTitle>User has been deleted</ModalPromptTitle>
    <ModalPromptSubtitle>
      The selected user's account has been successfully deleted. User will no
      longer be able to log in.
    </ModalPromptSubtitle>
    <DisableUserButtonsWrapper>
      <SubmitButton onClick={onClose}>
        <span>close </span>
      </SubmitButton>
    </DisableUserButtonsWrapper>
    <ModalPromptCloseIcon onClick={onClose} />
  </ModalPromptWrapper>
);
