import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";

import { TextInput } from "../../../../TextInput/TextInput";

import { getSecondsFromMidnight, getTimeFromSeconds } from "../../helpers";
import s from "../styles.module.scss";
import { FBForm } from "../../../types";
import { Components, ElementType } from "../../../../../qa-slugs";

const TimeToOption = ({ setFormSubmissionType }: any) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();

  // reshape form value to time input string
  const value = getTimeFromSeconds(
    values.formSubmissionConstraint?.timeLimit || 28800 // 8:00
  );

  function handleChange(v: string) {
    const timeLimit = getSecondsFromMidnight(v);
    setFieldValue("formSubmissionConstraint.timeLimit", timeLimit);
  }

  // if the parent radio button is selected/unselected we want to handle the change
  useEffect(() => {
    if (values.formSubmissionConstraint?.type === "TIME") {
      handleChange(value);
    }
  }, [values.formSubmissionConstraint?.type]);

  const timeLimitField = useField("formSubmissionConstraint.timeLimit");

  return (
    <div className={s.editTimeframeOption}>
      At
      <div className={s.timeToInputContainer}>
        <TextInput
          qa={`${Components.FormSettings}-${ElementType.TextInput}-timeLimit`}
          {...timeLimitField}
          className={s.timeToInput}
          type="time"
          onChange={(e) => {
            setFormSubmissionType("TIME");
            handleChange(e.target.value);
          }}
          value={value}
        />
      </div>
    </div>
  );
};

export default TimeToOption;
