import React, { FC, useRef } from "react";
import { createPortal } from "react-dom";
import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./Drawer.module.scss";

export interface DrawerProps {
  children?: React.ReactNode;
  anchor?: "bottom" | "left" | "right" | "top";
  className?: string;
  labelId?: string;
  id?: string;
  onClose: () => void;
  isOpen: boolean;
  disableBackdrop?: boolean;
  qa?: string;
}

export const Drawer: FC<DrawerProps> = ({
  anchor = "bottom",
  children,
  className,
  isOpen,
  labelId,
  onClose,
  disableBackdrop,
  qa,
}) => {
  const modalRef = useRef(null);

  return isOpen
    ? createPortal(
        <>
          <div className={styles.backdrop} onClick={onClose} />
          <aside
            aria-label={labelId}
            ref={modalRef}
            data-testid={qa}
            className={
              !disableBackdrop
                ? joinClassNames(styles.paper, styles[anchor], className)
                : joinClassNames(
                    styles.paper,
                    styles[anchor],
                    styles.disableBackdrop,
                    className
                  )
            }
            tabIndex={0}
          >
            <div className={styles.content}>{children}</div>
          </aside>
        </>,
        document.body
      )
    : null;
};
