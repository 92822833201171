import React from "react";
import moment from "moment";

import * as S from "../styles";
import Loader from "shared/src/components/Loader/Loader";
import {
  CancelButton,
  SubmitButton,
} from "shared/src/components/clientAdmin/styles";
import { LoaderWrapper } from "../../addUser/styles";
import { UserStatus } from "@rtslabs/field1st-fe-common";

interface UserActivationStatusConfirmation {
  // user data
  email?: string | null;
  firstName?: string | null;
  groupNames?: string[] | null;
  lastLogin?: string | null;
  lastName?: string | null;
  status?: UserStatus | null;

  enabledOrDisabled: string;
  loading?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

// Content only
export const UserActivationStatusConfirmation = ({
  // User data
  email = "",
  firstName = "",
  groupNames = [],
  status,
  lastLogin = "",
  lastName = "",

  enabledOrDisabled,
  loading,
  onCancel,
  onSubmit,
}: UserActivationStatusConfirmation) => {
  return (
    <S.ModalPromptWrapper>
      <S.ModalPromptTitle>
        Would you like to {enabledOrDisabled} this user?
      </S.ModalPromptTitle>
      <S.ModalPromptSubtitle>
        You are about to {enabledOrDisabled} this user:
      </S.ModalPromptSubtitle>
      <div className="d-flex flex-row">
        <S.DisableUserDataItem>
          <S.DataItemLabel>USER NAME</S.DataItemLabel>
          <S.DataItemText>
            {firstName} {lastName}
          </S.DataItemText>
          <S.DataItemEmail>{email}</S.DataItemEmail>
        </S.DisableUserDataItem>
        <S.DisableUserDataItem>
          <S.DataItemLabel>GROUP</S.DataItemLabel>
          {groupNames?.map((e) => (
            <S.DataItemText key={e}>{e}</S.DataItemText>
          ))}
        </S.DisableUserDataItem>
        <S.DisableUserDataItem>
          <S.DataItemLabel>LAST LOGIN</S.DataItemLabel>
          <S.DataItemText>
            {lastLogin && moment(lastLogin).format("MM/DD/YY")}
          </S.DataItemText>
        </S.DisableUserDataItem>
      </div>
      {status === "ACTIVE" && (
        <S.DisableUserWarningLabel margin="32.5px 0 0 0">
          Disabling a user will suspend their account, preventing them from
          being able to sign in. Data related to the user after being disabled
          will remain in the system and will not affect any reports related to
          this user.
        </S.DisableUserWarningLabel>
      )}
      <S.DisableUserButtonsWrapper margin="24px 0 0 0">
        <CancelButton disabled={false} onClick={onCancel}>
          cancel
        </CancelButton>
        <SubmitButton disabled={false} onClick={onSubmit}>
          <LoaderWrapper>
            <Loader
              loading={loading}
              className="p-0 mr-3"
              spinnerProps={{ size: 14 }}
            />
          </LoaderWrapper>
          <span>{enabledOrDisabled} user </span>
        </SubmitButton>
      </S.DisableUserButtonsWrapper>
      <S.ModalPromptCloseIcon onClick={onCancel} />
    </S.ModalPromptWrapper>
  );
};
