import {
  API,
  FormItemType,
  FormSectionSaveVm,
  FormVm,
  SectionItemSaveVm,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import { omit } from "lodash";
import React, { JSXElementConstructor } from "react";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import ss from "../baseUi/sidebar/styles.module.scss";
import {
  getAddedItemIndex,
  getAllFormItems,
  getFormItemById,
  getFormItemByRootId,
  getSelectedSectionIndex,
  marshallItem,
  marshallSection,
  scrollToElement,
  unmarshallForm,
} from "../helpers";
import bs from "../styles.module.scss";
import { AppWidgetProps, FBForm, FBItem, FBSection } from "../../types";
import Content from "./Content";
import ItemsPanel from "./items/ItemsPanel";
import Properties from "./properties/Properties";

export type ItemParams = SectionItemSaveVm & {
  name: string;
  parentId?: number;
};
export type SectionParams = FormSectionSaveVm & {
  name: string;
  type: "SECTION";
  subType: "SECTION";
};

export type AnyItemParams = ItemParams | SectionParams;

export type GeneratorFn = (item: AnyItemParams) => Promise<void>;

export type ItemsMutator = (
  item: FBItem,
  removeWidgetChildren?: boolean
) => Promise<boolean>;
export type SectionsMutator = (
  section: FBSection,
  removeChildren?: boolean
) => Promise<boolean>;

/**
 * Creates any dependencies of item then returns updated item
 */
async function createItemDependencies(
  item: ItemParams,
  formId: number,
  sectionId: number,
  sectionIndex: number
): Promise<ItemParams> {
  if (item.subType === "SAFETY_RATING") {
    /* The safety rating widget requires creation of a multi-select parent field */
    // const currentSectionIndex = values.sections.findIndex((section) => section.id === currentItem.id);
    const res = await API.addOrUpdateFormItem({
      formId,
      sectionId,
      item: {
        title: "Safety Rating Parent Question",
        type: "QUESTION",
        subType: "MULTI_SELECT",
        workflowType: "DRAFT",
        selections: [],
      },
    });
    const parentQuestionRootId =
      res.sections[sectionIndex].items.slice(-1)[0].id!;
    item = {
      ...item,
      parentQuestionRootId,
    };
  }
  return item;
}

interface Props {
  currentItem: FBItem | FBSection | null;
  setCurrentItem: (item: FBItem | FBSection | null) => void;
  onSaveError?: (error: string) => void;
  appWidgets: JSXElementConstructor<AppWidgetProps>;
  appWidgetsList: ItemParams[];
}

/** Tab contents for the "Create" Form Builder tab */
const Create: React.FC<Props> = ({
  currentItem,
  setCurrentItem,
  onSaveError,
  appWidgets,
  appWidgetsList,
}) => {
  const { values, setValues } = useFormikContext<FBForm>();

  function selectAddedItem(
    itemIndex: number,
    itemType: FormItemType | "SECTION",
    currentSectionIndex: number,
    sections: FBSection[]
  ): void {
    if (currentSectionIndex > -1 && itemIndex > -1) {
      const selectedItem =
        itemType == "SECTION"
          ? sections[itemIndex]
          : sections[currentSectionIndex].items[itemIndex];
      setCurrentItem(selectedItem);
      // scroll to the newly added item
      scrollToElement(selectedItem.id!.toString());
    }
  }

  /**
   * Remove a non-section item from the form via API and update the local form state
   */
  async function removeItem(
    item: FBItem,
    removeWidgetChildren?: boolean
  ): Promise<boolean> {
    if (item.itemIndex > -1) {
      const parentSection = values.sections.find(
        (section) => section.id === item.parentSectionId
      );
      setCurrentItem(parentSection || null);
      const res = await API.deleteFormItem({
        formId: values.id,
        sectionId: item.parentSectionId,
        index: item.itemIndex,
        removeWidgetChildren: removeWidgetChildren ?? true,
      });
      setValues(unmarshallForm(res));
      return true;
    }
    return false;
  }

  /**
   * Remove a section item from the form via API and update the local form state
   * @param section
   * @param removeChildren
   */
  async function removeSection(
    section: FBSection,
    removeChildren?: boolean
  ): Promise<boolean> {
    try {
      const res = await API.deleteFormSection({
        formId: values.id,
        sectionId: section.id!,
        removeWidgetChildren: removeChildren ?? true,
      });
      setValues(unmarshallForm(res));
      setCurrentItem(null);
      return true;
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  /**
   * Update a form item and then unmarshall the response and update the current item
   * @param item
   * @param index - target index to insert item in items array
   */
  async function updateItem(
    item: FBItem | FBSection,
    index?: number
  ): Promise<FBForm | undefined> {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    // ensure the item is in DRAFT mode if has workflow
    if ("workflowType" in item) {
      item = { ...item, workflowType: "DRAFT" };
    }

    let res: FormVm;
    if (item.type === "SECTION") {
      res = await API.addOrUpdateFormSection({
        formId: values.id,
        section: marshallSection(item),
        index,
      });
    } else {
      res = await API.addOrUpdateFormItem({
        formId: values.id,
        sectionId: item.parentSectionId,
        item: marshallItem(item),
        index,
      });
    }

    // update form builder state
    const updatedValues = unmarshallForm(res);
    setValues(updatedValues);

    // find updated item to select it
    let updatedCurrentItem: FBSection | FBItem | undefined;
    if (item.type === "SECTION") {
      updatedCurrentItem = updatedValues.sections.find(
        (s) => s.rootId === item.rootId
      );
    } else if (item.type === "QUESTION") {
      updatedCurrentItem = getFormItemByRootId(updatedValues, item.rootId!);
    } else {
      updatedCurrentItem = getFormItemById(updatedValues, item.id!);
    }

    setCurrentItem(updatedCurrentItem ?? null);

    return updatedValues;
  }

  /**
   * Add an item to the form via API and update local form state with response
   * @param item
   */
  async function add(item: AnyItemParams): Promise<void> {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    let updatedForm: FormVm | undefined;

    const currentSectionIndex = getSelectedSectionIndex(
      currentItem,
      values.sections
    );
    const itemIndex = getAddedItemIndex(
      item.type,
      item.subType,
      currentSectionIndex,
      currentItem,
      values.sections
    );

    /* user is adding a section */
    if (item.type === "SECTION") {
      updatedForm = await API.addOrUpdateFormSection({
        formId: values.id,
        section: omit(item, ["type", "subType"]),
        index: itemIndex,
      });
    } else if (item.subType === "PHOTO_GALLERY") {
      // the Photo Gallery Widget is added to the bottom of the form in its own section
      if (!galleryWidget) {
        updatedForm = await API.addOrUpdateFormSection({
          formId: values.id,
          section: {
            items: [
              {
                type: "WIDGET",
                subType: "PHOTO_GALLERY",
              },
            ],
            workflowType: "DRAFT",
          },
          index: itemIndex,
        });
      }
    } else if (currentItem) {
      /* user is adding a child item to a section */
      const sectionId = values.sections[currentSectionIndex].id!;
      /* user is adding a new item */
      item = await createItemDependencies(
        item,
        values.id,
        sectionId,
        currentSectionIndex
      );
      updatedForm = await API.addOrUpdateFormItem({
        formId: values.id,
        sectionId,
        item,
        index: itemIndex,
      });
    }

    /* form was successfully updated */
    if (updatedForm) {
      // update the form values
      const form = unmarshallForm(updatedForm);
      setValues(form);
      // select the newly added item
      typeof itemIndex === "number" &&
        selectAddedItem(
          itemIndex,
          item.type,
          currentSectionIndex,
          form.sections
        );
    }
  }

  const mapWidget = getAllFormItems(values).find(
    (item) => item.subType === "MAP"
  );

  const galleryWidget = getAllFormItems(values).find(
    (item) => item.subType === "PHOTO_GALLERY"
  );

  return (
    <div className={bs.contentWrapper}>
      {/* Left sidebar */}
      <div className={ss.sidebar}>
        <ItemsPanel
          add={add}
          itemSelected={!!currentItem}
          disableAddMap={!!mapWidget}
          disableAddGallery={!!galleryWidget}
          appWidgetsList={appWidgetsList}
        />
      </div>
      {/* Layout */}
      <Content
        currentItem={currentItem}
        removeItem={removeItem}
        removeSection={removeSection}
        setCurrentItem={setCurrentItem}
        onUpdateItem={updateItem}
        appWidgets={appWidgets}
        appWidgetsList={appWidgetsList}
      />
      {/* Right sidebar */}
      <div className={ss.sidebarContainer}>
        {currentItem && (
          <div className={joinClassNames(ss.sidebar, bs.propertiesSidebar)}>
            <Properties
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              onAddItem={add}
              onUpdateItem={updateItem}
              onSaveError={onSaveError}
              appWidgetsList={appWidgetsList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Create;
