import React from "react";

import {
  DocumentQuestionResponseVm,
  DefenseDTO,
  QuestionDTO,
} from "@rtslabs/field1st-fe-common";

import { DefenseDetails } from "./DefenseDetails";
import { FormSection } from "shared/src/components/Document/DocumentForm/FormSection";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";

type DefensesProps = {
  defenses: DefenseDTO[];
  flattenedQuestions: Array<QuestionDTO>;
  responses?: DocumentQuestionResponseVm[];
  setDocumentResponses: (
    question: QuestionDTO,
    questionResponses: DocumentQuestionResponseVm[]
  ) => void;
};

export const Defenses = ({
  defenses,
  flattenedQuestions,
  responses,
  setDocumentResponses,
}: DefensesProps) => {
  const defensesTerm: string = useGroupTerm(
    "defense",
    "noun",
    "plural",
    "Defenses"
  );

  const visibleDefenses = defenses.filter((defense) =>
    responses?.find((res) =>
      defense.questionSelections?.find((sel) =>
        res.associatedId
          ? sel.id === res.associatedId
          : sel.id === res.questionId
      )
    )
  );

  const defensesTermLowercase = defensesTerm.toLowerCase();

  const subTitle = visibleDefenses.length
    ? `The following ${defensesTermLowercase} are recommended based on the selections made above. Review with your team and make comments to each section as necessary.`
    : `Please make your task selections first and then we’ll provide ${defensesTermLowercase} to talk 
    through with your team.`;

  return (
    <div style={{ paddingBottom: 20 }}>
      <FormSection title={defensesTerm}>
        <DefenseDetails
          defenses={visibleDefenses}
          responses={responses}
          questions={flattenedQuestions}
          setQuestionResponses={setDocumentResponses}
          subTitle={subTitle}
        />
      </FormSection>
    </div>
  );
};
