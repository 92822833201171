import moment from "moment";

export interface DateInterval {
  endTime: string;
  startTime: string;
}

export const formatDayOfWeek = (date: Date) => {
  return moment(date).format("dddd");
};

export const getTimeOfDay = (date: Date) => {
  const hour = moment(date).hour();
  if (hour > 16) {
    return "evening";
  }
  if (hour > 11) {
    return "afternoon";
  }
  return "morning";
};

/**
 * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
 */
export const parseInterval = (iso8601Interval: string) => {
  const parts = iso8601Interval.split("/");

  // The interval could be a duration and an end time.
  const durationBefore = moment.duration(parts[0]);
  if (durationBefore.asSeconds() > 0) {
    const endTime = parts[1];
    const startTime = moment(endTime)
      .subtract(durationBefore.asSeconds(), "s")
      .toISOString();
    const interval: DateInterval = { startTime, endTime };
    return interval;
  }

  // The interval could be a start time and a duration.
  const durationAfter = moment.duration(parts[1]);
  if (durationAfter.asSeconds() > 0) {
    const startTime = parts[0];
    const endTime = moment(startTime)
      .add(durationAfter.asSeconds(), "s")
      .toISOString();
    const interval: DateInterval = { startTime, endTime };
    return interval;
  }

  // The interval must be a start and end time.
  const interval: DateInterval = {
    startTime: parts[0],
    endTime: parts[1],
  };

  return interval;
};
