import React, { FC } from "react";
import { QAProps } from "../../../../qa-slugs";
import { Icon } from "../../../Icon/Icon";

export interface CheckboxLabelProps extends QAProps {
  icon?: {
    name: string;
    color: string;
  };
  text?: string;
}

export const CheckboxLabel: FC<CheckboxLabelProps> = (props) => {
  return (
    <>
      {props.icon && (
        <Icon
          type={props.icon.name}
          color={props.icon.color}
          qa={`${props.qa}-icon`}
        />
      )}{" "}
      <span data-testid={`${props.qa}-text`}>{props.text}</span>
    </>
  );
};
