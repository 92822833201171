import React, { useState } from "react";
import { QAProps } from "../../../qa-slugs";
import {
  SortOrder,
  SortableTableHeader,
} from "../TableHeader/SortableTableHeader";
import { TableHeader } from "../TableHeader/TableHeader";

interface ColumnBase extends QAProps {
  id: string;
  label: string | JSX.Element;
  className?: string;
}

interface UnsortableColumn extends ColumnBase {
  isUnsortable: true;
}

export interface SortableColumn extends ColumnBase {
  isUnsortable?: false;
}

export type Column = SortableColumn | UnsortableColumn;

export type InternalSortOrder = SortOrder;

interface TableHeadProps {
  className?: string;
  columns: Column[];
  handleSortOrderChange?: (
    column: SortableColumn,
    sortOrder: SortOrder
  ) => void;
  initialSort?: {
    columnId: string;
    order: SortOrder;
  };
}

export const TableHead = ({
  className,
  columns,
  handleSortOrderChange,
  initialSort,
}: TableHeadProps) => {
  const [activeColumnId, setActiveColumnId] = useState<string | null>(
    initialSort?.columnId || null
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    initialSort?.order || "none"
  );

  const localHandleSortOrderChange = (newSortOrder: SortOrder, id: string) => {
    setSortOrder(newSortOrder);
    setActiveColumnId(newSortOrder !== "none" ? id : null);
    const foundColumn = columns.find((column) => column.id === id);
    if (foundColumn && !foundColumn.isUnsortable && handleSortOrderChange) {
      handleSortOrderChange(foundColumn, newSortOrder);
    }
  };

  return (
    <thead className={className}>
      <tr>
        {columns.map((column) =>
          column.isUnsortable ? (
            <TableHeader
              key={column.id}
              qa={column.qa}
              className={column.className}
            >
              {column.label}
            </TableHeader>
          ) : (
            <SortableTableHeader
              handleSortOrderChange={localHandleSortOrderChange}
              id={column.id}
              key={column.id}
              qa={column.qa}
              sortOrder={column.id === activeColumnId ? sortOrder : "none"}
              className={column.className}
            >
              {column.label}
            </SortableTableHeader>
          )
        )}
      </tr>
    </thead>
  );
};
