import React, { FC, useRef } from "react";
import moment from "moment";

import { TextInput, TextInputRef } from "../../TextInput/TextInput";
import { TextInputProps } from "../../TextInput/types";
import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import { useQuestion } from "./useQuestion";

interface Props extends Omit<TextInputProps, "onChange"> {
  type: "date" | "datetime-local" | "time";
  question: QuestionDTO;
}

export const DateField: FC<Props> = ({ question, type }) => {
  const { error, name, handleChangeBasicResponse, value, ...inputProps } =
    useQuestion(question);
  const inputRef = useRef<TextInputRef>(null);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    handleChangeBasicResponse({
      id: inputProps.response?.id ?? undefined,
      answer: fmtResponse(e.target.value, type),
    });
  }

  const handleClick = () => {
    inputRef.current?.showPicker();
  };

  return (
    <TextInput
      onClick={handleClick}
      onChange={handleChange}
      type={type}
      value={fmtLocalValue(type, value)}
      error={error}
      ref={inputRef}
      {...inputProps}
    />
  );
};

function fmtLocalValue(type: string, value?: string): string {
  if (!value) {
    return "";
  }
  if (type === "time") {
    return moment(value).format("HH:mm");
  } else {
    return moment(value).format("YYYY-MM-DD");
  }
}

function fmtResponse(value: string | undefined, type: string): string {
  if (!value) {
    return "";
  }
  if (type === "time") {
    return moment(value, "HH:mm").toISOString();
  } else {
    return moment(value).toISOString();
  }
}
