import React, { FC } from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import styles from "./ProgressBar.module.scss";

interface Props {
  value?: number;
  minValue?: number;
  maxValue?: number;
  className?: string;
}

export const ProgressBar: FC<Props> = ({
  value = 0,
  minValue = 0,
  maxValue = 100,
  className,
}) => (
  <div
    className={styles.progressBar}
    role="progressbar"
    aria-valuenow={value}
    aria-valuemin={minValue}
    aria-valuemax={maxValue}
  >
    <div
      className={joinClassNames(styles.progressIndicator, className)}
      style={{ transform: `translateX(${value - maxValue}%)` }}
    />
  </div>
);
