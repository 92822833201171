import { DocumentAction, DocumentActionType } from "./documentActions";

interface DocumentState {
  isPreview: boolean;
}

const initialState: DocumentState = {
  isPreview: false,
};

export const documentReducer = (
  state = initialState,
  action: DocumentAction
): DocumentState => {
  switch (action.type) {
    case DocumentActionType.SetPreview:
      return { ...state, isPreview: action.isPreview };
    default:
      return state;
  }
};
