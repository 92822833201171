import {
  CompositeDecorator,
  ContentState,
  EditorState,
  getVisibleSelectionRect,
  Modifier,
  RawDraftEntity,
  RichUtils,
  SelectionState,
} from "draft-js";

import { stateFromHTML } from "draft-js-import-html";
import { VirtualElement } from "../../common/Popper/Popper";

import { IndentDirection } from "./StyleControls";

/**
 * Convert an HTML string into an EditorState, and decorate with an optional CompositeDecorator
 * @param htmlContent String HTML value to convert
 * @param decorator CompositeDecorator used in the editor
 */
export const getStateFromHTML = (
  htmlContent: string,
  decorator?: CompositeDecorator
) => {
  // convert html data from API to ContentState
  const content = stateFromHTML(htmlContent);

  const initialState = ContentState.createFromBlockArray(
    content.getBlocksAsArray(),
    content.getEntityMap()
  );
  const initialEditorState = EditorState.createWithContent(
    initialState,
    decorator
  );
  return initialEditorState;
};

/**
 * Custom content block html rendering - currently only customizes a link
 * see use here:
 * https://github.com/jpuri/draftjs-to-html/blob/251fb4ee36f959d76e74cb5717916250c4824785/lib/draftjs-to-html.js#L400
 * @param entity draftjs entity to convert to html
 * @param text inner text of the html
 * @return html or false if default rendering should talk over
 */
export const customEntityTransform = (
  entity: RawDraftEntity,
  text: string
): string | boolean => {
  if (entity.type === "LINK" && entity.data.url) {
    let { url } = entity.data;
    if (!url.startsWith("http")) {
      url = "https://" + url;
    }
    const targetOption = entity.data.targetOption || "_blank";
    return `<a href="${url}" target="${targetOption}">${text}</a>`;
  }
  return false;
};

/**
 * Returns a material-ui popper referenceObject to the highlighted text DOMRect
 * @param e Event
 */
export const getSelectionAnchor = (): VirtualElement => {
  const editorSelector = getVisibleSelectionRect(window);

  if (!editorSelector) return null;

  const getBoundingClientRect = () => ({
    ...editorSelector,
    x: editorSelector.left,
    y: editorSelector.top,
    toJSON: () => {
      console.error("Not implemented");
    },
  });

  return {
    getBoundingClientRect,
  };
};

/**
 * Moves the cursor to the end of the supplied selection
 * @param editorState current editor state
 * @param selection selection to collapse
 */
const getCollapsedSelectionState = (
  editorState: EditorState,
  selection: SelectionState
) => {
  const collapsed = selection.merge({
    anchorOffset: selection.getEndOffset(),
    focusOffset: selection.getEndOffset(),
  }) as SelectionState;
  return EditorState.forceSelection(editorState, collapsed);
};

/**
 * Returns the start and end positions of the currently selected entity
 * @param contentBlock Key of the currently selected content block
 * @param entity Entity to search for
 */
const findEntityRange = (contentBlock: any, entity: any): any => {
  let range;
  contentBlock.findEntityRanges(
    (character: any) => character.getEntity() === entity,
    (start: any, end: any) => {
      range = { start, end };
    }
  );
  return range;
};

/**
 * Returns the currently selected content block
 * @param editorState The current editorState
 */
const getContentBlock = (editorState: any) => {
  const contentState = editorState.getCurrentContent();
  const startKey = editorState.getSelection().getStartKey();
  return contentState.getBlockForKey(startKey);
};

/**
 * Returns the entity key from the current selection
 * @param editorState the current editorState
 */
export const getEntityKeyFromSelection = (editorState: any) => {
  const startOffset = editorState.getSelection().getStartOffset();
  const block = getContentBlock(editorState);
  const entityKey = block.getEntityAt(startOffset);

  return entityKey;
};

/**
 * Returns the URL from a selected link entity
 * @param editorState the current editorState
 */
export const getLinkFromEditorState = (editorState: any) => {
  const contentState = editorState.getCurrentContent();

  const entityKey = getEntityKeyFromSelection(editorState);
  let url = "";

  if (entityKey) {
    const linkInstance = contentState.getEntity(entityKey);
    url = linkInstance.getData().url;
  }

  return url;
};

/**
 * Finds all the link entities, and run a callback on them
 * @param contentBlock currently selected content block
 * @param callback callback to handle all link entities
 * @param contentState current contentState
 */
export const findLinkEntities = (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const getCursorFocus = (selectionState: SelectionState, offset: number) => {
  const nextOffset = selectionState.getFocusOffset() + offset;
  return selectionState.merge({
    focusOffset: nextOffset,
    anchorOffset: nextOffset,
  }) as SelectionState;
};

/**
 * Returns a new editor state with applied indent
 * @param editorState current editorState
 * @param direction direction of the indent. 'increase' or 'decrease'
 */
export const getIndentedState = (
  editorState: EditorState,
  direction: IndentDirection
) => {
  const selectionState = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  // find the block that is currently selected
  const currentBlockKey = selectionState.getAnchorKey();
  const currentBlock = currentContent.getBlockForKey(currentBlockKey);

  let newContent: ContentState;
  let newState: EditorState = editorState;

  // set the cursor to the beginning of the block
  const tabPlacement = new SelectionState({
    anchorKey: currentBlockKey,
    anchorOffset: 0,
    focusKey: currentBlockKey,
    focusOffset: 0,
    hasFocus: false,
  });

  // handle increasing the indent
  if (direction === "increase") {
    // insert tabs into the beginning of the block
    newContent = Modifier.insertText(currentContent, tabPlacement, "    ");
    newState = EditorState.push(editorState, newContent, "insert-characters");
    // move the cursor back to where it belongs
    const cursorFocus = getCursorFocus(selectionState, 4);
    newState = EditorState.acceptSelection(newState, cursorFocus);
  }
  // handle decreasing the indent
  else {
    // if the block begins with a tab character, replace it with an empty string
    const hasTab = /\s\s\s\s/.test(currentBlock.getText());
    if (hasTab) {
      // select the first tab
      const selection = tabPlacement.merge({
        focusOffset: 4,
      }) as SelectionState;
      // replace the tab with ""
      newContent = Modifier.replaceText(currentContent, selection, "");
      newState = EditorState.push(newState, newContent, "backspace-character");
      // move the cursor back to where it belongs
      const cursorFocus = getCursorFocus(selectionState, -4);
      newState = EditorState.acceptSelection(newState, cursorFocus);
    }
  }

  return newState;
};

/**
 * Build a className for the editor based on the current state of the editor
 * @param editorState current editorState
 */
export const getEditorClassName = (
  editorState: EditorState,
  error?: boolean
) => {
  let className = "RichEditor-editor";

  // If the user changes block type before entering any text, hide the placeholder
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }

  if (error) className += " error";

  return className;
};

/**
 * attach a link entity to a selected string
 * @param linkUrl the url to add to the string
 */
export const addLinkEntity = (editorState: EditorState, linkUrl: string) => {
  const contentState = editorState.getCurrentContent();
  const entity = getEntityKeyFromSelection(editorState);

  // create the entity
  const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", {
    url: linkUrl,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  // add it to the editorState
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  let selection = newEditorState.getSelection();

  // if there's already an entity at the selection, expand the selection to include
  // the entire entity so it can be replaced
  if (entity) {
    const contentBlock = getContentBlock(editorState);
    // Get begining and end of the entity
    const entityRange = findEntityRange(contentBlock, entity);
    selection = selection.merge({
      anchorOffset: entityRange.start,
      focusOffset: entityRange.end,
    }) as SelectionState;
  }

  const linked = RichUtils.toggleLink(newEditorState, selection, entityKey);
  // Move the cursor to the end of the selection
  const collapsed = getCollapsedSelectionState(linked, selection);

  return collapsed;
};

/**
 * remove link entity from the selected string
 * @param editorState the current editorState
 */
export const removeLinkEntity = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const contentBlock = getContentBlock(editorState);
  const entity = getEntityKeyFromSelection(editorState);
  let newState = editorState;

  // if an entity is found, remove it
  if (entity) {
    // Get begining and end of the entity
    const entityRange = findEntityRange(contentBlock, entity);
    const entitySelection = selectionState.merge({
      anchorOffset: entityRange.start,
      focusOffset: entityRange.end,
    }) as SelectionState;

    // Remove the entity
    const newContentState = Modifier.applyEntity(
      contentState,
      entitySelection,
      null
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );
    // Move the cursor to the end of the selection + apply it to the state
    const collapsed = getCollapsedSelectionState(
      newEditorState,
      entitySelection
    );
    newState = collapsed;
  }
  // return the new editorState
  return newState;
};
