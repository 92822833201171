import {
  EsriContentWidgetSaveVm,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import React, { useState } from "react";
import { QAProps } from "shared/src/qa-slugs";
import { findLayerById } from "shared/src/api/esri/getFeatureLayers";
import { ArcGisMap } from "../../../../../../common/ArcGisMap/ArcGisMap";
import { FBItem } from "shared/src/components/clientAdmin/types";

interface EsriContentWidget extends QAProps {
  item: FBItem<EsriContentWidgetSaveVm>;
}

export function EsriContentWidget({ item, qa }: EsriContentWidget) {
  const [layerName, setLayerName] = useState<string>("");

  useAsyncEffect(async () => {
    const layer = await findLayerById({
      featureLayerPortalItemId: item.portalItemId,
      layerId: item.layerId,
    });
    setLayerName(layer?.title || "");
  }, [item.layerId, item.portalItemId]);

  return (
    <div data-testid={qa}>
      {item.includeMap && (
        <ArcGisMap
          disableNavigation={true}
          userPositionViewport={{
            center: {
              latitude: 37.54129,
              longitude: -77.43476,
            },
          }}
        />
      )}
      <p>{layerName}</p>
    </div>
  );
}
