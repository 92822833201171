import React, { Component } from "react";
import styled from "styled-components";

import { ThemeTypes } from "../../../themes/colors";
import mediaQueries from "../../../themes/mediaQueries";
import { remCalc } from "../../../themes/helpers";

import { TextProps, TextVariant } from "./Text";

export const Text = styled<React.FC<TextProps>>(({ variant, ...props }) => {
  return <Component {...props} />;
})`
  // BASE STYLES CAN GO HERE
  font-family: "Roboto", sans-serif;
  color: ${({ theme }: { theme: ThemeTypes }) => theme.colors.black};

  // HANDLE STYLES FOR VARIANTS HERE
  ${({ variant, theme }: { variant: TextVariant; theme: ThemeTypes }) => {
    switch (variant) {
      case "error":
        return `
          color: ${theme.colors.error};
          font-size: ${remCalc(16)};
          margin-left: ${remCalc(16)};
        `;
      case "field": {
        // styles for when this is used as a value in form fields
        return `
          color: ${theme.colors.black};
          font-size: ${remCalc(24)};

          @media(${mediaQueries.maxPhone}) {
            font-size: ${remCalc(16)};
          }

        `;
      }
      case "helper": {
        return `
          color: ${theme.masterColors.darkGreyBlue};
          font-size: ${remCalc(16)};
        `;
      }
      default:
        return null;
    }
  }}
`;
