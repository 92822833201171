import React, { useEffect, useState } from "react";

import {
  DocumentSummaryVm,
  DocumentHistoryVm,
} from "@rtslabs/field1st-fe-common";
import { DocumentDetails } from "../DocumentDetails/DocumentDetails";
import DocumentHistoryTable from "./DocumentHistoryTable";
import { TertiaryButton } from "shared/src/components/Button/Button";
import styles from "./documentHistory.module.scss";
import { prettifyDocumentStatus } from "../../../helpers";

interface DocumentHistoryProps {
  document: DocumentSummaryVm;
  fetchDocumentHistory: (id: number) => Promise<DocumentHistoryVm[]>;
  handleDeleteDocument?: (documentId: number) => void;
  term: string;
}

const DocumentHistory = ({
  document,
  fetchDocumentHistory,
  handleDeleteDocument,
  term,
}: DocumentHistoryProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [history, setHistory] = useState<DocumentHistoryVm[]>([]);

  const workOrderId = document.workOrder?.workOrderId;
  const workOrderIdSubtitle = workOrderId ? `ID: ${workOrderId}` : "ID: -";

  useEffect(() => {
    async function getDocHistory(docId: number) {
      setError(false);
      setLoading(true);
      try {
        const history = await fetchDocumentHistory(docId);
        setHistory(history);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    getDocHistory(document.id);
  }, [document]);

  return (
    <>
      <div className={styles.documentDetailsWrapper}>
        <h2 className={styles.title}>Document History</h2>
        <p className={styles.subtitle}>
          See the different actions your team members completed.
        </p>
        <DocumentDetails
          title={document.formSummary.type.name || "Unknown Document Type"}
          subtitle={document.title}
          status={prettifyDocumentStatus(document.status)}
          row1={workOrderIdSubtitle}
        />
      </div>
      <div className={styles.documentHistoryTable}>
        <DocumentHistoryTable
          document={document}
          documentHistoryData={history}
          isDocumentHistoryDataLoading={loading}
          isDocumentHistoryError={error}
          term={term}
        />
      </div>
      {handleDeleteDocument && (
        <div className={styles.deleteDocumentButtonWrapper}>
          <TertiaryButton onClick={() => handleDeleteDocument(document.id)}>
            Delete Document
          </TertiaryButton>
        </div>
      )}
    </>
  );
};

export default DocumentHistory;
