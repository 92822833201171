import React, { FC } from "react";
import { TableRow } from "../../../common/styled/Table";
import NoResultsContent, { NoResultsContentProps } from "./NoResultsContent";
import styles from "./NoResults.module.scss";

const NoResults: FC<NoResultsContentProps> = (props) => {
  return (
    <TableRow>
      <td className={styles.emptyRow} colSpan={100}>
        <NoResultsContent {...props} />
      </td>
    </TableRow>
  );
};

export default NoResults;
