import React from "react";

interface ClassName {
  className?: string;
}
export const HazardIcon = ({ className }: ClassName) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1200 872.916"
      enableBackground="new 0 0 1200 872.916"
      width="24px"
      className={className}
    >
      <path
        d="M1087.337,714.024L932.011,558.697c87.115-112.077,79.217-274.584-23.707-377.509
	c-111.487-111.484-292.888-111.489-404.374,0c-111.487,111.486-111.487,292.885,0,404.372
	c54.006,54.007,125.812,83.751,202.187,83.751c64.325,0,125.404-21.101,175.341-60.022l155.307,155.307
	c6.982,6.982,16.134,10.474,25.286,10.474c9.152,0,18.304-3.492,25.286-10.474C1101.304,750.632,1101.304,727.988,1087.337,714.024z
	 M706.117,597.79c-57.271,0-111.116-22.304-151.615-62.802c-83.6-83.6-83.6-219.628,0-303.227
	c41.807-41.805,96.703-62.7,151.615-62.7c54.9,0,109.82,20.905,151.615,62.7c83.6,83.6,83.6,219.627,0,303.227
	C817.233,575.486,763.39,597.79,706.117,597.79z"
      />
      <path
        d="M287.145,469.078c23.49,0,42.332-18.842,42.332-42.332c0-23.288-18.842-42.332-42.332-42.332
	c-23.289,0-42.349,19.044-42.349,42.332C244.796,450.236,263.856,469.078,287.145,469.078"
      />
      <g>
        <path
          d="M765.294,663.215L765.294,663.215c0,20.577-16.836,37.414-37.414,37.414H211.689c-20.586,0-37.429-16.843-37.429-37.429
		V340.604c0-20.671,16.757-37.429,37.429-37.429h220.03c7.76-26.716,19.479-52.513,35.167-76.456H194.532
		c-51,0-92.344,41.344-92.344,92.344v378.253c0,43.093,34.933,78.026,78.026,78.026h574.057c43.093,0,78.027-34.934,78.027-78.027
		v-57.21C811.067,650.566,788.593,658.336,765.294,663.215z"
        />
      </g>
      <g>
        <path
          d="M706.117,669.311c-76.375,0-148.181-29.745-202.187-83.751c-11.263-11.263-21.368-23.25-30.355-35.796l-12.657,21.404
		c-1.865,2.954-4.835,4.819-8.24,4.975c-3.42,0.311-6.685-1.244-8.877-3.886l-56.883-72.756c-5.131-6.529-12.904-10.26-21.143-9.95
		c-8.255,0.311-15.873,4.664-20.211,11.038L212.023,670.354l508.825-0.777l-0.35-0.657
		C715.722,669.154,710.932,669.311,706.117,669.311z"
        />
      </g>
      <g>
        <g>
          <g>
            <circle cx="706.117" cy="499.615" r="26.168" />
          </g>
          <g>
            <path
              d="M706.117,226.719L706.117,226.719c20.036,0,35.712,17.266,33.782,37.209L722.51,443.61
				c-0.817,8.442-7.912,14.884-16.393,14.884l0,0c-8.482,0-15.577-6.441-16.393-14.884l-17.389-179.681
				C670.405,243.985,686.08,226.719,706.117,226.719z"
            />
          </g>
        </g>
      </g>
      <path
        d="M287.232,469.729c23.486,0,42.325-18.839,42.325-42.325c0-23.284-18.839-42.325-42.325-42.325
	c-23.285,0-42.342,19.041-42.342,42.325C244.89,450.89,263.947,469.729,287.232,469.729"
      />
      <g opacity="0.25">
        <path
          d="M681.589,596.396l-90.356-169.77c-4.664-8.083-12.902-12.901-22.227-12.901c-9.342,0-17.581,4.819-22.088,12.746
		l-31.955,54.038c9.966,19.648,23.136,38.076,39.539,54.479C589.161,569.646,633.598,590.964,681.589,596.396z"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M832.298,556.771V319.739c0-50.992-41.337-92.329-92.329-92.329H558.992c-1.511,1.427-3.011,2.872-4.49,4.351
		c-21.223,21.223-37.046,45.828-47.494,72.092h220.885c20.577,0,37.412,16.835,37.412,37.412v248.28
		C789.33,582.686,811.957,571.639,832.298,556.771z"
        />
      </g>
    </svg>
  );
};
