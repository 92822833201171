import {
  DocumentVm,
  EsriContentWidgetDTO,
  LatLng,
  QuestionDTO,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import React, { useMemo, useRef, useState } from "react";
import { Button } from "shared/src/components/Button/Button";
import { getItemByRootId } from "shared/src/components/Document/DocumentForm/formEntity.helpers";
import { getCurrentPosition } from "shared/src/util/geolocation";
import { findLayerById } from "shared/src/api/esri/getFeatureLayers";
import { queryFeatureLayer } from "shared/src/api/esri/queryFeatureLayer";
import { addSymbolToFeature } from "../../../data/esri/addSymbolToFeature";
import { findNearestFeature } from "../../../data/esri/findNearestFeature";
import { ArcGisMap } from "../../common/ArcGisMap/ArcGisMap";
import { EsriViewport } from "../../common/ArcGisMap/esriMapUtilities";
import styles from "./EsriContent.module.scss";
import { EsriField } from "./EsriField";

interface EsriContentProps {
  item: EsriContentWidgetDTO;
}

interface QuestionToAttribute {
  question: QuestionDTO;
  label: string;
  attribute: [name: string, value: string];
}

export const EsriContent = ({ item }: EsriContentProps) => {
  const { values } = useFormikContext<DocumentVm>();
  const { maxDistance, unitOfMeasure, questions } = item;
  const outFields = useMemo(
    () => questions.map((q) => q.fieldName),
    [questions]
  );
  const mapViewRef = useRef<__esri.MapView | null>(null);
  const [layer, setLayer] = useState<__esri.FeatureLayer>();
  const [features, setFeatures] = useState<__esri.Graphic[]>([]);
  const [nearestFeature, setNearestFeature] = useState<
    __esri.Graphic | undefined
  >();
  const [fieldInfos, setFieldInfos] = useState<__esri.FieldInfo[]>([]);
  const [questionsToAttributes, setQuestionsToAttributes] = useState<
    QuestionToAttribute[]
  >([]);
  const [userPositionEsriViewport, setUserPositionEsriViewport] = useState<
    EsriViewport | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMapViewReady, setisMapViewReady] = useState<boolean>(false);

  const getLocation = async () => {
    const position = await getCurrentPosition();
    // if coordinates were found for the user's location
    if ("coords" in position && position.coords) {
      const geolocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
      const _userPositionViewport = {
        center: geolocation,
      };
      setUserPositionEsriViewport(_userPositionViewport);
    }
    setIsLoading(false);
  };

  // Step 1, get the user's location
  useAsyncEffect(getLocation, []);

  // Step 2, fetch the esri layer and features
  useAsyncEffect(async () => {
    if (!isLoading) {
      const featureLayer = await findLayerById({
        featureLayerPortalItemId: item.portalItemId,
        layerId: item.layerId,
      });
      setLayer(featureLayer);
      if (featureLayer) {
        const result = await queryFeatureLayer({
          coordinates: userPositionEsriViewport!.center,
          featureLayer,
          outFields,
          distance: maxDistance,
          units: unitOfMeasure,
        });
        setFeatures(result.features);
        setFieldInfos(featureLayer.popupTemplate.fieldInfos);
      }
    }
  }, [isLoading]);

  /**
   * Step 3:
   *   - wait for steps 1 and 2 to finish
   *   - wait for the map to load
   *   - fetch asset data of the nearest feature
   */
  useAsyncEffect(() => {
    /**
     * If a response already exists for an esri question when a user opens the document,
     * then don't change it unless the user clicks the "use my current location" button.
     *
     * This makes it so that original responses are preserved unless the current user
     * intentionally wants to update the data. i.e. do not automatically overwrite existing
     * data.
     */
    if (
      features.length &&
      isMapViewReady &&
      fieldInfos.length &&
      !documentHasResponsesForWidget(values, item)
    ) {
      handleSearchNearestFeature(
        userPositionEsriViewport!.center,
        features,
        fieldInfos
      );
    }
  }, [features, isMapViewReady, fieldInfos]);

  const handleMapViewReady = async (mapView: __esri.MapView) => {
    mapViewRef.current = mapView;
    setisMapViewReady(true);
  };

  const handleSearchAtCurrentLocationButtonPress = async () => {
    setIsLoading(true);
    await getLocation();
  };

  const handleSearchInAreaButtonPress = async () => {
    updateMap(mapViewRef.current!.center);
  };

  const updateMap = async (coordinates: LatLng) => {
    const result = await queryFeatureLayer({
      coordinates,
      featureLayer: layer!,
      outFields,
      distance: maxDistance,
      units: unitOfMeasure,
    });
    await handleSearchNearestFeature(coordinates, result.features, fieldInfos);
  };

  const handleSearchNearestFeature = async (
    coordinates: LatLng,
    newFeatures: __esri.Graphic[],
    fieldInfos: __esri.FieldInfo[]
  ) => {
    const previouslyNearestFeature = nearestFeature;
    const _nearestFeature = await findNearestFeature(coordinates, newFeatures);
    setNearestFeature(_nearestFeature);
    if (_nearestFeature) {
      addSymbolToFeature(_nearestFeature);
      mapViewRef.current!.graphics.removeAll();
      mapViewRef.current!.graphics.add(_nearestFeature);
    } else if (previouslyNearestFeature) {
      mapViewRef.current!.graphics.removeAll();
      setNearestFeature(undefined);
    }

    const questionDTOsToAttributes = questions.map((q, idx) => {
      const questionDTO = getItemByRootId(
        q.questionRootId,
        values.form.sections
      );
      const attribute = _nearestFeature
        ? Object.entries(_nearestFeature.attributes)[idx]
        : undefined;
      const label =
        fieldInfos.find((info) => info.fieldName === attribute?.[0])?.label ||
        attribute?.[0];
      return {
        attribute: (attribute || ["", ""]) as [name: string, value: string],
        label: label || "",
        question: questionDTO as QuestionDTO,
      };
    });
    setQuestionsToAttributes(questionDTOsToAttributes);
  };

  return (
    <div>
      <h1>{layer?.title}</h1>
      {item.includeMap && userPositionEsriViewport && (
        <ArcGisMap
          userPositionViewport={userPositionEsriViewport}
          nearestFeature={nearestFeature}
          onMapViewReady={handleMapViewReady}
        />
      )}
      <Button
        className={styles.searchButton}
        onClick={handleSearchAtCurrentLocationButtonPress}
        loading={isLoading}
      >
        Search at Current Location
      </Button>
      <Button
        className={styles.searchButton}
        onClick={handleSearchInAreaButtonPress}
        loading={isLoading}
      >
        Search in Area
      </Button>
      {questionsToAttributes.map((questionToAttribute) => {
        const { attribute, label, question } = questionToAttribute;
        const value = attribute[1];
        return (
          <EsriField
            attributeLabel={label}
            attributeValue={value}
            key={question.id}
            question={question}
          />
        );
      })}
    </div>
  );
};

function documentHasResponsesForWidget(
  document: DocumentVm,
  widget: EsriContentWidgetDTO
) {
  const widgetQuestionRootIds = widget.questions.map((q) => q.questionRootId);
  widgetQuestionRootIds.forEach((rootId) => {
    const response = document.responses.find(
      (r) => r.questionRootId === rootId
    );
    if (response) {
      return true;
    }
  });

  return false;
}
