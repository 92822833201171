import React, { useState, ElementType, useMemo } from "react";

import { Text } from "shared/src/components/clientAdmin/styles";

interface Props {
  Component: ElementType;
  items: string[];
  qa?: string;
}

/**
 *
 * @param items      - Array of strings, dress them with Component
 * @param Component  - Wraps items
 *
 * - GK
 */
const ExpandableItemList = ({ items, Component, qa }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const content = useMemo(() => {
    if (items && items.length > 0) {
      // When collapsed, we only show the first
      const itemsToShow = isExpanded ? items : items.slice(0, 1);
      const remainingItemsCount = items.length - 1;
      const buttonText = isExpanded
        ? "Collapse ^"
        : `+ ${remainingItemsCount} others`;

      return (
        <>
          {itemsToShow.map((item) => (
            <Component data-testid={qa} key={item}>
              {item}
            </Component>
          ))}
          {remainingItemsCount > 0 && (
            <Text
              fontSize={"0.875rem"}
              fontWeight={400}
              textDecoration="underline"
              onClick={() => {
                handleExpand();
              }}
            >
              {buttonText}
            </Text>
          )}
        </>
      );
    } else {
      return "-";
    }
  }, [Component, items, isExpanded]);

  return <>{content}</>;
};

export default ExpandableItemList;
