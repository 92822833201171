import { FC } from "react";
import React from "react";
import styles from "./Wrappers.module.scss";

interface ContentWrapperProps {
  id?: string;
  children?: React.ReactNode;
}
export const ContentWrapper: FC<ContentWrapperProps> = ({ children, id }) => (
  <div id={id} className={styles.contentWrapper}>
    {children}
  </div>
);

// Pick a better name if you can, but for "mobile" responsive routes (so mostly non-CA specific)
// we need to not have min-width -- GK
export const ResponsiveContentWrapper: FC<ContentWrapperProps> = ({
  children,
}) => <div className={styles.responsiveContentWrapper}>{children}</div>;
