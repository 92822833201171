import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import * as S from "./styles";
import * as US from "../addUser/styles";
import * as VUPS from "../viewUserProfile/styles";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import Loader from "shared/src/components/Loader/Loader";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { ErrorToast } from "../../../../../shared/src/components/Toast/ErrorToast";
import { AppState } from "../../../store";
import {
  API,
  ClientGroupConfigDTO,
  ClientGroupDTO,
  DefaultTermDefinitionDTO,
  GroupTerm,
} from "@rtslabs/field1st-fe-common";
import {
  EditIcon,
  PageContent,
} from "shared/src/components/clientAdmin/styles";

import { TabGroup } from "shared/src/components/Tabs/TabGroup/TabGroup";
import {
  getAllDefaultTermDefinitions,
  getClientGroupConfig,
  updateClientGroupConfig,
} from "@rtslabs/field1st-fe-common/dist/main/redux";
import { Components, Page } from "shared/src/qa-slugs";

interface ViewGroupProps {
  dispatch?: any;
}

// For the url params
interface Params extends Record<string, string> {
  id: string;
}

// Constants
const mapDefaultTerms = (
  terms: DefaultTermDefinitionDTO[]
): GroupTerm[] | undefined => {
  if (terms?.length > 0) {
    return terms.map((term) => ({
      id: term.visibleId,
      label: term.label,
      value: term.val,
    }));
  }
};

const getInitialValues = (terms) => {
  // Accepts terms array and returns a cleaned up object
  const result = {};
  if (terms) {
    terms.forEach((term) => {
      result[term.id] = term.value;
    });
  }
  return result;
};

const ViewGroup = (props: ViewGroupProps) => {
  const { dispatch } = props;
  const navigate = useNavigate();
  const params = useParams<Params>();

  // Get group id from url
  const groupId = params.id ? Number(params.id) : undefined;

  // Current tab
  const [tab, setTab] = useState(0);

  // Current group
  const [currentGroup, setCurrentGroup] = useState<ClientGroupDTO>();
  const [currentGroupLoading, setCurrentGroupLoading] = useState<boolean>(true);

  // Current group configs
  const [defaultTerms, setDefaultTerms] = useState<GroupTerm[]>([]);
  const [defaultTermsLoading, setDefaultTermsLoading] = useState(false);
  const [currentGroupTerms, setCurrentGroupTerms] = useState<GroupTerm[]>([]);
  const [currentGroupTermsLoading, setCurrentGroupTermsLoading] =
    useState(false);

  // Error toast visible
  const [errorToastVisible, setErrorToastVisible] = useState<boolean>(false);

  // Async form submit loading (API)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  async function getGroupTerms(clientGroupId: number) {
    setCurrentGroupTermsLoading(true);
    setDefaultTermsLoading(true);
    const res: ClientGroupConfigDTO | undefined = await dispatch(
      getClientGroupConfig({
        clientGroupId,
        keyName: "terms",
      })
    );
    setCurrentGroupTerms(res?.properties.terms || []);
    // Get default terms to distinguish
    const defaultTermDefinitions = await dispatch(
      getAllDefaultTermDefinitions()
    );
    setDefaultTerms(mapDefaultTerms(defaultTermDefinitions) || []);

    setDefaultTermsLoading(false);
    setCurrentGroupTermsLoading(false);
  }

  async function getCurrentGroup(groupId: number) {
    setCurrentGroupLoading(true);
    try {
      const res = await API.getClientGroupById({ id: groupId });
      setCurrentGroup(res);
      // Get current group terms
      getGroupTerms(res.id);
    } catch (error) {
      console.error(error); // todo
    }

    setCurrentGroupLoading(false);
  }

  // On mount
  useEffect(() => {
    if (groupId) getCurrentGroup(groupId);
  }, [groupId]);

  const _handleSubmit = (values) => {
    const properties = defaultTerms.map((e) => {
      return {
        ...e,
        // value: values[e.id] || e.value,
        value: values[e.id],
      };
    });

    const payload: ClientGroupConfigDTO = {
      active: true,
      clientGroupId: currentGroup!.id,
      keyName: "terms",
      properties: {
        terms: properties,
      },
    };

    // Do API call
    setSubmitLoading(true);
    dispatch(updateClientGroupConfig(payload))
      .catch(() => setErrorToastVisible(true))
      .finally(() => {
        setSubmitLoading(false);
        navigate("/people/groups", { replace: true });
      });
  };

  return (
    <PageContent>
      <div className="col-12">
        <Breadcrumbs
          paths={[
            {
              pathName: "People",
              href: "/people/groups",
            },
            {
              pathName: "Groups",
              href: "/people/groups",
            },
            {
              pathName: `${currentGroup?.name} Profile`,
              href: `/people/groups/view/${currentGroup?.id}`,
            },
          ]}
        />
      </div>
      <Loader loading={currentGroupLoading}>
        {/* Group information  */}
        <div className="col-lg-12">
          <VUPS.ProfileInformationWrapper>
            <VUPS.ProfileName
              data-testid={`${Page.Desktop}-${Components.ViewGroup}`}
            >
              {currentGroup?.name}
              <span className="ml-2">
                <EditIcon
                  onClick={() =>
                    navigate(`/people/groups/edit-group/${groupId}`)
                  }
                />
              </span>
            </VUPS.ProfileName>
            <VUPS.ProfileContentWrapper>
              <VUPS.ProfileContent>
                <VUPS.ProfileContentHeader>ID</VUPS.ProfileContentHeader>
                <VUPS.ProfileContentValue>
                  {currentGroup?.externalGroupId}
                </VUPS.ProfileContentValue>
              </VUPS.ProfileContent>
            </VUPS.ProfileContentWrapper>
          </VUPS.ProfileInformationWrapper>
        </div>

        <div className="col-12 mt-5 pb-5">
          <TabGroup
            variant="white"
            tabs={[
              {
                label: "Group terms",
                tabId: "group-terms",
                tabPanelId: "group-terms",
                tabPanelContent: (
                  <S.TabContent>
                    <VUPS.ProfilePermissionsCopy>
                      Specific terms and phrases within the Fields1st app can be
                      customized for each group. For example, a term referred to
                      as “Refocus” by one group may be called a “Rehuddle” by
                      another. Using the terms and language your team members
                      use will improve adoption and reduce confusion.{" "}
                      <b>Note:</b> System defaults exist for all terms, so only
                      specify those you want to vary.
                    </VUPS.ProfilePermissionsCopy>
                    <Loader
                      loading={currentGroupTermsLoading || defaultTermsLoading}
                    >
                      {/* Terms  */}
                      <div className="pl-0 pr-0">
                        <S.TermColumnsWrapper>
                          <S.TermColumnHeader width="199px">
                            <S.TermColumnHeaderContent>
                              company term
                            </S.TermColumnHeaderContent>
                          </S.TermColumnHeader>
                          <S.TermColumnHeader>
                            <S.TermColumnHeaderContent>
                              group term
                            </S.TermColumnHeaderContent>
                          </S.TermColumnHeader>
                        </S.TermColumnsWrapper>
                        <Formik
                          enableReinitialize
                          initialValues={getInitialValues(currentGroupTerms)}
                          onSubmit={(values) => {
                            _handleSubmit(values);
                          }}
                        >
                          {(props) => {
                            const { handleChange, handleSubmit, values } =
                              props;
                            return (
                              <form onSubmit={handleSubmit}>
                                {defaultTerms.map((config, i) => {
                                  return (
                                    <S.TermColumnsWrapper key={i}>
                                      <S.LabelWrapper width="199px">
                                        <S.Label>{config.label}</S.Label>
                                      </S.LabelWrapper>

                                      <S.FieldWrapper>
                                        <TextInput
                                          name={config.id}
                                          onChange={handleChange}
                                          placeholder={config.label}
                                          value={values[config.id]}
                                        />
                                      </S.FieldWrapper>
                                    </S.TermColumnsWrapper>
                                  );
                                })}

                                <ErrorToast
                                  onClick={() => setErrorToastVisible(false)}
                                  visible={errorToastVisible}
                                >
                                  Failed to save
                                </ErrorToast>
                                <br />
                                <US.ButtonsWrapper>
                                  <US.AddUserButton disabled={submitLoading}>
                                    <US.LoaderWrapper>
                                      <Loader
                                        loading={submitLoading}
                                        className="p-0 mr-3"
                                        spinnerProps={{ size: 14 }}
                                      />
                                    </US.LoaderWrapper>
                                    <span>save </span>
                                  </US.AddUserButton>
                                </US.ButtonsWrapper>
                              </form>
                            );
                          }}
                        </Formik>
                      </div>
                    </Loader>
                  </S.TabContent>
                ),
              },
            ]}
          />
        </div>
      </Loader>
    </PageContent>
  );
};

const mapStateToProps = (state: AppState) => {
  return {};
};

export default connect(mapStateToProps)(ViewGroup);
