import { useForkRef } from "@mui/material";
import React, {
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  useEffect,
  useRef,
} from "react";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { QAProps } from "shared/src/qa-slugs";
import { MenuItem } from "./MenuItem";
import styles from "./MenuList.module.scss";
import { MenuOption } from "./TableRowMenu";

interface Props extends QAProps {
  autoFocus?: boolean;
  className?: string;
  onBlur?: FocusEventHandler<HTMLUListElement>;
  onChangeSelection: (selectedOption: MenuOption, wasClicked: boolean) => void;
  onKeyDown?: KeyboardEventHandler<HTMLUListElement>;
  labelId: string;
  options: MenuOption[];
  selectedOption: MenuOption;
}

export const MenuList = forwardRef<HTMLUListElement, Props>(
  (
    {
      autoFocus,
      className,
      onBlur,
      onChangeSelection,
      onKeyDown,
      labelId,
      options,
      selectedOption,
      qa,
    },
    ref
  ) => {
    const list = useRef<HTMLUListElement | null>(null);
    const handleRef = useForkRef(list, ref);

    const selectOption = (option: MenuOption, wasClicked?: boolean) => {
      onChangeSelection(option, !!wasClicked);
    };

    const moveDown = () => {
      const foundIndex = options.findIndex(
        (option) => option.id === selectedOption.id
      );
      if (foundIndex <= options.length - 2) {
        const nextOption = options[foundIndex + 1];
        selectOption(nextOption);
      }
    };

    const moveEnd = () => {
      const nextOption = options[options.length - 1];
      selectOption(nextOption);
    };

    const moveStart = () => {
      const nextOption = options[0];
      selectOption(nextOption);
    };

    const moveUp = () => {
      const foundIndex = options.findIndex(
        (option) => option.id === selectedOption.id
      );
      if (foundIndex >= 1) {
        const nextOption = options[foundIndex - 1];
        selectOption(nextOption);
      }
    };

    const handleFocus: FocusEventHandler<HTMLUListElement> = () => {
      selectOption(selectedOption);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLUListElement> = (event) => {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          moveDown();
          break;

        case "ArrowUp":
          event.preventDefault();
          moveUp();
          break;

        case "End":
          event.preventDefault();
          moveEnd();
          break;

        case "Home":
          event.preventDefault();
          moveStart();
          break;
      }
      if (onKeyDown) {
        onKeyDown(event);
      }
    };

    useEffect(() => {
      if (autoFocus) {
        list.current?.focus();
      }
    }, [autoFocus]);

    return (
      <ul
        aria-activedescendant={selectedOption.id}
        aria-labelledby={labelId}
        className={joinClassNames(styles.menuList, className)}
        data-testid={qa}
        onBlur={onBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        ref={handleRef}
        role="menu"
        tabIndex={-1}
      >
        {options.map((option) => (
          <MenuItem
            isSelected={option.id === selectedOption.id}
            key={option.id}
            onSelect={selectOption}
            option={option}
          />
        ))}
      </ul>
    );
  }
);
