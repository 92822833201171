import React, { FC } from "react";

interface ClassName {
  className?: string;
}
export const Required: FC<ClassName> = ({ className }) => {
  return (
    <svg
      version="1.1"
      id="glyph"
      width="12"
      height="12"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 185.8 161"
      className={className}
      fill="red"
    >
      <g>
        <polygon points="78.5,87.1 65.5,160.6 86.7,160.6 99.7,87.1 	" />
        <path
          d="M100.4,76.3c2.3-2.3,3.4-5.2,3.3-8.7c-0.1-3.4-1.2-6.1-3.4-8.2c-2.2-2.1-5-3.1-8.3-3.1s-6,1.2-8.3,3.5s-3.4,5.2-3.3,8.7
		c0.1,3.4,1.3,6.1,3.5,8.2s5,3.1,8.2,3.1C95.4,79.8,98.2,78.6,100.4,76.3z"
        />
        <path
          d="M182.3,124.6L113,11.3C108.7,4.2,101.2,0,92.9,0c-8.2,0-15.8,4.2-20.1,11.3L3.5,124.6c-4.5,7.4-4.7,16.3-0.4,23.8
		c4.2,7.5,11.9,12,20.6,12h30.8l3.4-19.6H23.6c-1.9,0-2.9-1.1-3.5-2.1c-0.4-0.7-1-2.2,0.1-4L89.5,21.5c1-1.7,2.6-1.9,3.4-1.9
		c1,0,2.4,0.3,3.4,1.9l69.3,113.3c1,1.7,0.6,3.1,0.1,4c-0.8,1.4-2,2.1-3.5,2.1H101l-3.4,19.6h64.6c8.7,0,16.4-4.5,20.6-12.1
		S186.8,132,182.3,124.6z"
        />
      </g>
    </svg>
  );
};
