import React from "react";

import {
  DocumentVm,
  DocumentSummaryVm,
  RelatedDocumentVm,
} from "@rtslabs/field1st-fe-common";
import { DocumentDetails } from "../DocumentDetails/DocumentDetails";
import RelatedDocumentsTable from "./RelatedDocumentsTable";
import Loader from "shared/src/components/Loader/Loader";
import styles from "./relatedDocuments.module.scss";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import { prettifyDocumentStatus } from "../../../helpers";

interface RelatedDocumentsProps {
  document: DocumentVm | DocumentSummaryVm;
  relatedDocuments: RelatedDocumentVm[];
  loading?: boolean;
  error?: boolean;
  terms: {
    documents: string;
    rehuddle: string;
  };
}

const RelatedDocuments = ({
  document,
  relatedDocuments,
  loading,
  error,
  terms: { documents: documentsTerm, rehuddle: rehuddleTerm },
}: RelatedDocumentsProps) => {
  const workOrderId = document.workOrder?.workOrderId;
  const workOrderIdSubtitle = workOrderId ? `ID: ${workOrderId}` : "ID: -";
  const subtitle = `${
    document.isRehuddle ? `[${rehuddleTerm.toUpperCase()}]` : ""
  } ${document.title}`;

  return (
    <div className={styles.relatedDocuments}>
      <div className={styles.documentDetailsWrapper}>
        <h2 className={styles.title}>Related {documentsTerm}</h2>
        <p className={styles.subtitle}>
          The following {documentsTerm.toLowerCase()} are related to:
        </p>
        <DocumentDetails
          title={document.formSummary.type.name}
          subtitle={subtitle}
          status={prettifyDocumentStatus(document.status)}
          row1={workOrderIdSubtitle}
        />
      </div>
      <div className={styles.documentHistoryTable}>
        <Loader loading={loading}>
          <RelatedDocumentsTable relatedDocuments={relatedDocuments} />
        </Loader>
        {error && <ErrorText>{error}</ErrorText>}
      </div>
    </div>
  );
};

export default RelatedDocuments;
