import React from "react";
import { QAProps } from "../../../qa-slugs";

interface CollapsibleDivProps extends QAProps {
  children: React.ReactNode;
  collapsed: boolean;
}
const CollapsibleDiv = ({ children, collapsed, qa }: CollapsibleDivProps) => (
  <div data-testid={qa} style={collapsed ? { display: "none" } : {}}>
    {children}
  </div>
);

export default CollapsibleDiv;
