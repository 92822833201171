import React from "react";
import styled from "styled-components";

import {
  QuestionDTO,
  DocumentQuestionResponseVm,
  DocumentVm,
} from "@rtslabs/field1st-fe-common";
import { createMarkup } from "shared/src/util/createMarkup";

import { SmallText, H3 } from "shared/src/components/clientAdmin/styles";

import * as S from "./styles";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";

const QuestionAndAnswer = styled.div.attrs({
  className: "text-left px-3x py-4",
})`
  border-bottom: solid 1px ${({ theme }) => theme.colors.lightGrey};
  align-self: center;
  width: 100%;
`;
const ResponseText = styled.div`
  color: ${(props) => props.theme.masterColors.darkGrey};
`;

const Response = (response: DocumentQuestionResponseVm) => {
  return (
    <>
      <div className="d-flex pt-3">
        <SmallText className="mr-4">Response</SmallText>
        <ResponseText>{response.answer}</ResponseText>
      </div>
      {response.comments && (
        <div className="d-flex mt-2">
          <SmallText className="mr-4">Comment</SmallText>
          <ResponseText>{response.comments}</ResponseText>
        </div>
      )}
    </>
  );
};

/**
 * Finds the responses for the passed question ID.
 * @param document
 * @param questionId
 */
const responsesFor = (
  document: DocumentVm,
  questionId?: number
): DocumentQuestionResponseVm[] => {
  if (!document.responses || !questionId) {
    return [];
  }

  return document.responses
    .filter((r) => r.questionId === questionId)
    .map((r) => {
      if ((r.answer === "true" || r.answer === "false") && !r.associatedId) {
        return {
          ...r,
          answer: r.answer === "true" ? "Yes" : "No",
        };
      }
      return r;
    });
};

const getQuestionAndAnswer = (question: QuestionDTO, document: DocumentVm) => {
  const responses = responsesFor(document, question.id);
  if (responses.length === 0) {
    responses.push({ answer: "N/A" } as DocumentQuestionResponseVm);
  }

  return (
    <QuestionAndAnswer key={question.id}>
      <H3>{question.title}</H3>
      {responses.map(Response)}
    </QuestionAndAnswer>
  );
};

const ResponseList = ({ document }: { document: DocumentVm }) => {
  const operationalExperiences = document.operationalExperiences || []; // OEs
  const items: QuestionDTO[] | undefined = document.form?.sections!.reduce(
    (result: QuestionDTO[], section) => {
      section.items!.forEach((item) => {
        if (item.type === "QUESTION") {
          result.push(item as QuestionDTO);
        }
      });
      return result;
    },
    []
  );

  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );

  return (
    <S.ResponseList>
      {items?.map((question) => getQuestionAndAnswer(question, document))}
      {/* OEs */}
      {operationalExperiences && operationalExperiences.length > 0 && (
        <QuestionAndAnswer id="OEs">
          <H3 className="mb-3">{operationExperiencesTerm}</H3>
          {operationalExperiences.map((OE) => (
            <div key={`op_ex_${OE.id}`} className="d-flex">
              <SmallText className="mr-4">Included</SmallText>
              <S.OEDescription
                dangerouslySetInnerHTML={createMarkup(OE.description!)}
              />
              {/* <ResponseText>{OE.description}</ResponseText> */}
            </div>
          ))}
        </QuestionAndAnswer>
      )}
    </S.ResponseList>
  );
};

export default ResponseList;
