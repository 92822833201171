import React, { FC, ReactNode } from "react";
import { CoreRedux } from "@rtslabs/field1st-fe-common";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import styles from "./AiChatDrawer.module.scss";
import { Components, ElementType } from "shared/src/qa-slugs";
import { Icon } from "shared/src/components/Icon/Icon";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import { useAppSelector } from "../../../store/hooks";

interface AiChatDrawerProps {
  anchor?: "right" | "bottom" | "left" | "top";
  onClose: () => void;
  open: boolean;
  title?: string | ReactNode;
}

const AiChatDrawer: FC<AiChatDrawerProps> = ({
  anchor = "right",
  onClose,
  open,
  title,
}) => {
  const chatbaseConfig = useAppSelector(CoreRedux.selectChatbaseConfigProps);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${scssVariables.minDesktop})`,
  });

  if (!chatbaseConfig) return null;

  return (
    <Drawer
      isOpen={open}
      onClose={onClose}
      anchor={isDesktop ? anchor : "bottom"}
    >
      <div>
        <div className={styles.titleRow}>
          <h1 className={styles.title}>
            {!!title ? title : "AI Safety Co-Pilot Chat"}
          </h1>
          <ActionButton
            data-testid={`${Components.AIChatDrawer}-close-${ElementType.Button}`}
            className={styles.iconContainer}
            onClick={onClose}
            aria-label="Close"
            label="Close"
            IconComponent={<Icon type="delete_sign" className={styles.icon} />}
          />
        </div>
        <DrawerContentWrapper>
          <iframe
            className={styles.iframe}
            src={`https://www.chatbase.co/chatbot-iframe/${chatbaseConfig.chatbotId}`}
            width="100%"
          ></iframe>
        </DrawerContentWrapper>
      </div>
    </Drawer>
  );
};

export default AiChatDrawer;
