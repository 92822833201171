import { get } from "lodash";
import React from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./TextInput.module.scss";
import { TextInputSuggestion } from "./TextInputWithSuggestions";

interface SuggestionsProps<T> {
  suggestions: T[];
  onSelectSuggestion: (suggestion: T) => void;
  labelField: string;
}

export const SUGGESTION_CLASS = "suggestion";

export const Suggestions = <T extends TextInputSuggestion>({
  suggestions,
  onSelectSuggestion,
  labelField,
}: SuggestionsProps<T>) => (
  <div role="tooltip" className={styles.suggestionPopper}>
    <ul className={styles.suggestionOptions} role="menu">
      {suggestions.map((suggestion, index) => (
        <li
          key={suggestion.id}
          className={joinClassNames(styles.suggestionOption, SUGGESTION_CLASS)}
          tabIndex={index} // Valid tabIndex required for LocationField.tsx onBlur handler to work
          role="menuitem"
          aria-disabled="false"
          onClick={() => onSelectSuggestion(suggestion)}
        >
          {get(suggestion, labelField)}
        </li>
      ))}
    </ul>
  </div>
);
