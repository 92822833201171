import React, { FC, useState } from "react";
import { API, FormTypeSaveVm } from "@rtslabs/field1st-fe-common";
import {
  ModalPromptCloseIcon,
  ModalPromptTitle,
  ModalPromptWrapper,
} from "../../users/styles";
import { FormTypeForm } from "./FormTypeForm";

import Modal from "shared/src/components/common/Modal";
import * as QA from "shared/src/qa-slugs";
import { FormTypeToasts } from "../FormTypeToasts";

interface AddFormTypeProps {
  visible: boolean;
  onClose: () => void;
}

const initialValues: FormTypeSaveVm = {
  active: true,
  name: "",
  isGlobal: true,
  iconColor: "#000000",
};

export const AddFormType: FC<AddFormTypeProps> = ({ onClose, visible }) => {
  const [loading, setLoading] = useState(false);
  const [toastStatus, setToastStatus] = useState<
    "CONFIRM" | "SUCCESS" | "FAIL"
  >("CONFIRM");
  const [formTypeName, setFormTypeName] = useState("");
  async function handleSubmit(values: FormTypeSaveVm) {
    try {
      setToastStatus("CONFIRM");
      setLoading(true);

      const newVals: FormTypeSaveVm = values.isGlobal
        ? { ...values, clientGroups: undefined }
        : values;

      await API.createFormType({ formType: newVals });
      setToastStatus("SUCCESS");
      setFormTypeName(values.name);
      setLoading(false);
      onClose();
    } catch (e) {
      console.error(e);
      setToastStatus("FAIL");
      setLoading(false);
    }
  }

  return (
    <>
      {/* Toasts */}
      <FormTypeToasts
        status={toastStatus}
        onDelay={() => setToastStatus("CONFIRM")}
        successMessage={`Form Type "${formTypeName}" Created`}
        failMessage={`The system encountered an error while trying to save the
                      form type. Please try again.`}
        qa={`${QA.Components.FormTypes}-${QA.ElementType.Toast}-create`}
      />
      <Modal
        border="none"
        open={visible}
        handleClose={onClose}
        height="auto"
        width="652px"
        padding="24px 23px"
        qa={`${QA.Components.FormTypes}-${QA.Components.AddModal}`}
        content={
          <ModalPromptWrapper>
            <ModalPromptTitle>New Form Type</ModalPromptTitle>
            <ModalPromptCloseIcon
              onClick={onClose}
              data-testid={`${QA.Components.FormTypes}-${QA.Components.AddModal}-${QA.Components.Button}-close`}
            />
            <FormTypeForm
              initialValues={initialValues}
              loading={loading}
              onSubmit={handleSubmit}
              onClose={onClose}
              saveButtonLabel="add"
            />
          </ModalPromptWrapper>
        }
      />
    </>
  );
};
