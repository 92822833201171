import React, { Dispatch, SetStateAction, useState } from "react";

import { Drawer } from "shared/src/components/Drawer/Drawer";
import InterstitialDrawerContent from "./components/InterstitialDrawerContent";
import { createRelatedDoc } from "./createRelatedDoc";
import {
  CoreRedux,
  DocumentSummaryVm,
  DocumentVm,
  ParticipantUser,
} from "@rtslabs/field1st-fe-common";
import { useSelector } from "react-redux";

interface Props {
  submissionTimeError?: string | null;
  formId?: number;
  formType?: string;
  formTypeId?: number;
  isDesktop: boolean;
  isOpen: boolean;
  onSuccess: (newDocument: DocumentVm) => void;
  selectedDocument: DocumentSummaryVm | DocumentVm | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
}

export const InterstitialDrawer = ({
  formId,
  formType,
  submissionTimeError,
  isDesktop,
  isOpen,
  onSuccess,
  selectedDocument,
  setIsOpen,
  title = "",
}: Props) => {
  const currentUser: ParticipantUser | null = useSelector(
    ({ user }: CoreRedux.Store.RootState) => user.data
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleSubmit = async () => {
    if (!selectedDocument || !formId || !currentUser) {
      return;
    }
    try {
      setIsLoading(true);
      const newDocument = await createRelatedDoc({
        parentDocumentId: selectedDocument.id,
        targetFormId: formId,
        targetOwner: currentUser,
      });
      onSuccess(newDocument);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      id="FormTemplatesDrawer"
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      disableBackdrop
    >
      <InterstitialDrawerContent
        formType={formType}
        title={title}
        onClose={() => setIsOpen(false)}
        onSubmit={handleSubmit}
        submissionTimeError={submissionTimeError}
        isLoading={isLoading}
      />
    </Drawer>
  );
};
