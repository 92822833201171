import { Back } from "../../assets/svg/back";
import React, { MouseEventHandler } from "react";
import { useMouseFocus } from "../../util/hooks/useMouseFocus";
import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./NavButton.module.scss";
interface NavButtonProps {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
  iconStyle?: string;
}

export const NavButton = ({
  handleClick,
  label,
  iconStyle,
}: NavButtonProps) => {
  const { eventHandlers, isMouseFocused } = useMouseFocus();

  return (
    <button
      aria-label={label}
      className={joinClassNames(
        styles.navButton,
        !isMouseFocused && styles.navButtonKeyboardFocused
      )}
      onClick={handleClick}
      type="button"
      {...eventHandlers}
    >
      <Back
        className={iconStyle === "back" ? styles.backIcon : styles.forwardIcon}
      />
    </button>
  );
};
