import { Field, useFormikContext } from "formik";
import React, { useState } from "react";

import { FormVersionDTO, WorkflowType } from "@rtslabs/field1st-fe-common";
import { css } from "aphrodite/no-important";
import { Select, SelectOption } from "../../../../Select/Select";
import Button, { TextButton } from "../../../../common/Button";
import { getFormStatusString } from "../../../../clientAdmin/formBuilder/helpers";
import { FBForm } from "../../../../clientAdmin/types";
import style from "./styles";
import { useFBConfigs } from "../../../../../util/hooks/useFBConfigs";

const workflowTypeOptions: SelectOption<"DRAFT" | "FINAL">[] = [
  { value: "DRAFT", label: "Draft" },
  { value: "FINAL", label: "Published" },
];

type StatusUpdateButtonsProps = {
  onCancel: () => void;
  onSubmit: () => void;
};

const StatusUpdateButtons = ({
  onCancel,
  onSubmit,
}: StatusUpdateButtonsProps) => {
  const bs = style();
  return (
    <div className={css(bs.centerFlex)}>
      <TextButton variant="cancel" onClick={onCancel}>
        Cancel
      </TextButton>
      <Button onClick={onSubmit}>Update</Button>
    </div>
  );
};

type StatusProps = {
  status?: WorkflowType;
  onSubmit: (workflowType?: WorkflowType) => void;
  isPublishing?: boolean;
  onPublish: () => void;
  formVersion: FormVersionDTO["version" | "formId"];
};

const Status = ({
  status,
  onSubmit,
  isPublishing,
  onPublish,
  formVersion,
}: StatusProps) => {
  const { formBuilderConfigs } = useFBConfigs();
  const { enableHistory } = formBuilderConfigs;

  // Formik
  const { setFieldValue } = useFormikContext<FBForm>();
  const [displayStatusField, setDisplayStatusField] = useState<boolean>(false);
  const s = style();

  return (
    <div>
      <div className={css(s.statusContainer)}>
        <span>Status:&nbsp;</span>
        {/* Status + Edit Link or Status Dropdown */}
        {displayStatusField ? (
          <Field
            name="workflowType"
            as={Select}
            options={workflowTypeOptions}
            onChange={(option: SelectOption) =>
              setFieldValue("workflowType", option?.value)
            }
          />
        ) : (
          <>
            {!!enableHistory && status === "FINAL" && formVersion ? (
              <span className={css(s.status)}>
                {getFormStatusString(status)} &#8226; Version: {formVersion}
              </span>
            ) : (
              <span className={css(s.status)}>
                {getFormStatusString(status)}
              </span>
            )}
            {status === "FINAL" && (
              <TextButton
                variant="link"
                onClick={() => setDisplayStatusField(true)}
              >
                Edit
              </TextButton>
            )}
          </>
        )}
      </div>

      {/* Publish or Update/Cancel buttons */}
      {displayStatusField ? (
        <StatusUpdateButtons
          onCancel={() => {
            setFieldValue("workflowType", "FINAL"); // Set type back to "FINAL"
            setDisplayStatusField(false);
          }}
          onSubmit={() => {
            onSubmit();
            setDisplayStatusField(false);
          }}
        />
      ) : (
        status !== "FINAL" &&
        status !== "DEACTIVATED" && (
          <Button
            loading={isPublishing}
            className={css(s.publishButton)}
            onClick={onPublish}
          >
            Publish
          </Button>
        )
      )}
    </div>
  );
};

export default Status;
