import React from "react";

import * as S from "./styles";

export interface ButtonProps {
  children: string | React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: "submit" | "reset";
  className?: string;
  qa?: string;
}

/** Filled button */
const Button = ({
  children,
  onClick,
  disabled,
  loading,
  type,
  qa,
  ...rest
}: ButtonProps) => {
  // If type is "submit" it will still submit form when disabled,
  // changing it to a "disabled" "button" -- gk
  disabled = disabled || loading;
  const _type = disabled ? "button" : type || "button";
  return (
    <S.Button
      type={_type}
      onClick={disabled ? undefined : onClick}
      loading={loading}
      disabled={disabled}
      data-testid={qa}
      {...rest}
    >
      {loading && (
        <S.Loader loading spinnerProps={{ color: "secondary", size: 14 }} />
      )}
      <S.ButtonText>{children}</S.ButtonText>
    </S.Button>
  );
};

export interface TextButtonProps extends ButtonProps {
  variant?: "cancel" | "link";
}

/** Text-only button */
export const TextButton = ({
  variant,
  className,
  children,
  onClick,
  disabled,
  loading,
  qa,
  ...rest
}: TextButtonProps) => {
  return (
    <S.TextButton
      className={className}
      type="button"
      variant={variant}
      onClick={disabled ? undefined : onClick}
      disabled={loading || disabled}
      data-testid={qa}
      {...rest}
    >
      {children}
    </S.TextButton>
  );
};

export default Button;
