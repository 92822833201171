import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import React, { useEffect } from "react";
import { useQuestion } from "shared/src/components/Document/DocumentForm/useQuestion";
import { ReadOnlyResponse } from "shared/src/components/Document/ReadOnly/ReadOnlyResponse";

interface EsriFieldProps {
  question: QuestionDTO;
  attributeLabel: string;
  attributeValue: string;
}

export const EsriField = ({
  question,
  attributeLabel,
  attributeValue,
}: EsriFieldProps) => {
  const { handleChangeBasicResponse, response, required } =
    useQuestion(question);

  useEffect(() => {
    handleChangeBasicResponse({
      id: response?.id ?? undefined,
      answer: attributeValue,
    });
  }, [attributeValue]);

  return (
    <ReadOnlyResponse
      label={attributeLabel}
      value={attributeValue}
      required={required}
    />
  );
};
