import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { joinClassNames } from "../../../helpers/theme.helpers";
import { Icon } from "../../Icon/Icon";
import scssVariables from "shared/src/sass/jsExports.module.scss";

import styles from "./ProfileCard.module.scss";
import { Components, Page } from "../../../qa-slugs";

export interface UserForm {
  count: number;
  icon?: string | null;
  iconColor?: string | null;
  id: number;
  name: string;
}

interface GroupLabel {
  mobile?: string;
  desktop?: string;
}

interface Props {
  email?: string;
  groupLabel: GroupLabel;
  name?: string;
  userForms?: Array<UserForm>;
  headerBackground: string;
  supervisor?: string;
  adfsEnabled?: boolean;
  supervisorTerm?: string;
}

interface FormCountProps {
  userForm: UserForm;
  desktop?: boolean;
  className?: string;
}

export const FormCount = ({
  userForm: { iconColor, icon, count, id },
  desktop,
  className,
}: FormCountProps) => {
  return (
    <a data-testid={`my-submissions-icon-${id}`} href={`/documents/${id}`}>
      <div
        className={joinClassNames(
          styles.formCount,
          desktop && styles.formCount_desktop
        )}
      >
        {iconColor && icon && (
          <Icon color={iconColor} type={icon} className={styles.icon} />
        )}
        <div
          className={joinClassNames(
            styles.count,
            desktop && styles.count_desktop,
            className
          )}
        >
          {count}
        </div>
      </div>
    </a>
  );
};

/**
 * Used in Mobile View, renders a clickable area
 * around the Group Label. When clicked, switches
 * between rendering a truncated label
 * and all of the groups.
 */
const GroupLabel = ({ groupLabel }: { groupLabel: GroupLabel }) => {
  const [renderFull, setRenderFull] = useState(false);

  const onClick = () => {
    setRenderFull(!renderFull);
  };

  return (
    <button className={styles.clickable} onClick={onClick}>
      {renderFull ? (
        <div className={styles.groupRow}>
          <Icon
            color={scssVariables.warning}
            type="star-filled"
            className={styles.star}
          />{" "}
          <p className={styles.group}>{groupLabel.desktop}</p>
        </div>
      ) : (
        <div className={styles.groupRow}>
          <Icon
            color={scssVariables.warning}
            type="star-filled"
            className={styles.star}
          />{" "}
          <p className={styles.group}>{groupLabel.mobile}</p>
        </div>
      )}
    </button>
  );
};

const MobileView = ({ name, email, groupLabel, userForms }: Props) => {
  return (
    <div className={styles.profileCard}>
      <p className={styles.userName}>{name}</p>
      <p className={styles.email}>{email}</p>

      <GroupLabel groupLabel={groupLabel} />

      {userForms && (
        <div className={styles.formCounts}>
          {userForms.map((userForm) => (
            <FormCount key={userForm.id} userForm={userForm} />
          ))}
        </div>
      )}
    </div>
  );
};

const DesktopView = ({
  name,
  email,
  groupLabel,
  userForms,
  headerBackground,
  supervisor,
  adfsEnabled,
  supervisorTerm,
}: Props) => {
  const supervisorTermUpper = supervisorTerm?.toUpperCase();
  return (
    <div
      className={joinClassNames(styles.profileCard, adfsEnabled && styles.adfs)}
      style={{ backgroundImage: `url(${headerBackground})` }}
    >
      <div className={styles.card}>
        <p
          className={joinClassNames(styles.userName, styles.userName_desktop)}
          data-testid={`${Page.Desktop}-${Components.Profile}`}
        >
          {name}
        </p>

        <div className={styles.row}>
          <div>
            <div className={styles.leftTop}>
              <div className={styles.groupCol}>
                <p className={styles.sectionLabel}>GROUP</p>
                <div className={styles.groupRow}>
                  <Icon
                    color={scssVariables.warning}
                    type="star-filled"
                    className={styles.star}
                  />{" "}
                  <p className={styles.group}>{groupLabel.desktop}</p>
                </div>
              </div>

              <div className={styles.col}>
                <p className={styles.sectionLabel}>{supervisorTermUpper}</p>
                <p className={styles.supervisor}>
                  {supervisor ? supervisor : "-"}
                </p>
              </div>
            </div>

            <div className={styles.col}>
              <p className={styles.sectionLabel}>EMAIL</p>
              <p className={styles.email}>{email}</p>
            </div>
          </div>
          <div>
            <p className={styles.sectionLabel}>SUBMISSIONS</p>
            {userForms && (
              <div
                className={joinClassNames(
                  styles.formCounts,
                  styles.formCounts_desktop
                )}
              >
                {userForms.map((userForm) => (
                  <FormCount desktop key={userForm.id} userForm={userForm} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Uses a hook to determine which component to render
 * based on the view port.
 */
const ProfileCard = (props: Props) => {
  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  if (isDesktop) {
    return <DesktopView {...props} />;
  }

  return <MobileView {...props} />;
};

export default ProfileCard;
