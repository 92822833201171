import React, { FC, ReactNode } from "react";
import styles from "./FieldMessagingWrapper.module.scss";
import { AssistiveText } from "../../AssistiveText/AssistiveText";
import ErrorText from "../../ErrorText/ErrorText";
import { QAProps } from "../../../qa-slugs";
import { Icon } from "../../Icon/Icon";
import { Required } from "../../../assets/svg/Required";

interface FieldMessagingWrapperProps extends QAProps {
  assistiveText?: string | null;
  error?: ReactNode | ReactNode[] | string;
  children: ReactNode;
  comments?: ReactNode;
}

export const FieldMessagingWrapper: FC<FieldMessagingWrapperProps> = ({
  assistiveText,
  error,
  children,
  comments,
  qa,
}) => {
  return (
    <div className={styles.fieldMessagingWrapper}>
      <div>{children}</div>
      <div className={styles.bottomRow}>
        <div>
          <AssistiveText qa={`${qa}-assistive`}>{assistiveText}</AssistiveText>
        </div>
        <div>
          {error && (
            <ErrorText qa={`${qa}-error`}>
              <Required /> {error}
            </ErrorText>
          )}
        </div>
      </div>
      <>{comments}</>
    </div>
  );
};
