import React from "react";
import { sortBy } from "lodash";

import Loader from "../../../Loader/Loader";

import styles from "./NewForm.module.scss";
import { Components, ElementType } from "../../../../qa-slugs";
import { ListItem } from "../../../List/ListItem";
import { List } from "../../../List/List";
import { FormTypeIcon } from "../../FormTypeIcon";
import { ChangeTypeButton } from "./ChangeTypeButton";
import { FormSummaryVm, FormTypeDTO } from "@rtslabs/field1st-fe-common";

export interface SelectFormTypeProps {
  selectedFormTypeId?: FormTypeDTO["id"];
  selectedFormTemplateId?: number;
  handleSelectFormTemplate: (formTemplate?: FormSummaryVm) => void;
  loading: boolean;
  handleSelectFormType: (formTypeId?: FormTypeDTO["id"]) => void;
  formTypes: Array<FormTypeDTO>;
  isSingleItem?: boolean;
}

const SelectFormType = ({
  formTypes,
  handleSelectFormType,
  loading,
  selectedFormTypeId,
  selectedFormTemplateId,
  handleSelectFormTemplate,
  isSingleItem = false,
}: SelectFormTypeProps) => {
  return (
    <Loader loading={loading}>
      <List>
        {sortBy(formTypes, "name")
          .filter((formType) => formType.active)
          .map((formType) => {
            if (
              (selectedFormTypeId && selectedFormTypeId !== formType.id) ||
              !formType.active
            ) {
              return null;
            }
            return (
              <ListItem
                qa={`${Components.ListItem}-${ElementType.Link}-${formType.name}`}
                key={formType.id}
                active={selectedFormTypeId === formType.id}
                onClick={() => {
                  selectedFormTemplateId && handleSelectFormTemplate();
                  handleSelectFormType(formType.id);
                }}
                className={styles.formItem}
              >
                <div className={styles.alignCenter}>
                  <FormTypeIcon
                    formType={formType}
                    className={styles.formTypeIcon}
                  />
                  {formType.name}
                </div>
                {selectedFormTypeId === formType.id && !isSingleItem && (
                  <ChangeTypeButton
                    onClick={() => handleSelectFormType(undefined)}
                  />
                )}
              </ListItem>
            );
          })}
      </List>
    </Loader>
  );
};

export default SelectFormType;
