import { FormSubmissionConstraintDTO } from "@rtslabs/field1st-fe-common";
import { FormikErrors, useFormikContext } from "formik";
import { pick } from "lodash";
import { FBForm, FBItem, FBSection } from "../../../types";

type FBValidationError = {
  error: string;
  errorKey: string;
  itemPath: string;
  errorPath: string;
};

export type FBValidationLevel = "alert" | "error";
export type FBValidationType = "create" | "settings";

function useValidation() {
  const { errors, submitCount } = useFormikContext<FBForm>();

  const alertLevel: FBValidationLevel = submitCount > 0 ? "error" : "alert";

  /**
   * Get errors related to section items
   */
  function getItemErrors(sectionErrors: any, sectionIndex: any) {
    const items = sectionErrors["items"] as FormikErrors<FBItem | FBSection>[];
    const errorsArray: FBValidationError[] = [];

    items.forEach((itemErrors: any, itemIndex) => {
      let errorPath: any;
      if (itemIndex > -1) {
        errorPath = `Section ${sectionIndex + 1}, Item ${itemIndex + 1}`;
      }
      const itemPath = `sections[${sectionIndex}].items[${itemIndex}]`;
      if (itemErrors) {
        Object.keys(itemErrors).forEach((itemErrorKey) => {
          errorsArray.push({
            itemPath,
            error: itemErrors[itemErrorKey],
            errorKey: itemErrorKey,
            errorPath,
          });
        });
      }
    });

    return errorsArray;
  }

  /**
   * Get errors related to form sections/items
   */
  function getSectionErrors(sectionErrors: any) {
    if (!sectionErrors) {
      return [];
    }

    return sectionErrors.reduce(
      (
        errorsArray: FBValidationError[],
        errors: FormikErrors<FBSection>,
        sectionIndex: number
      ): FBValidationError[] => {
        if (errors) {
          const errorSectionPath = `Section ${sectionIndex + 1}`;
          const sectionPath = `sections[${sectionIndex}]`;

          Object.keys(errors).forEach((sectionErrorKey) => {
            // handle item errors
            if (sectionErrorKey === "items" && errors["items"]) {
              errorsArray.push(...getItemErrors(errors, sectionIndex));
            } else {
              // handle section errors
              errorsArray.push({
                itemPath: sectionPath,
                error: (errors as { [key: string]: string })[sectionErrorKey],
                errorKey: sectionErrorKey,
                errorPath: errorSectionPath,
              });
            }
          });
        }
        return errorsArray;
      },
      []
    );
  }

  /**
   * Get flattened validation error array from the supplied error object
   * @param errorObj Object of validation errors
   */
  function getFlattenedErrors(errorObj: any): FBValidationError[] {
    const flattenedErrors: FBValidationError[] = [];
    if (errorObj)
      Object.keys(errorObj).map((errorKey) => {
        if (errorKey === "sections") {
          flattenedErrors.push(...getSectionErrors(errorObj[errorKey]));
        } else {
          let errorPath;
          switch (errorKey) {
            case "name":
              errorPath = "Title";
              break;
            case "clientGroups":
              errorPath = "Group Access";
              break;
            case "formSubmissionConstraint":
            case "hourLimit":
            case "timeLimit":
              errorPath = "Edit Period";
              break;
            default:
              errorPath = "Error";
              break;
          }

          flattenedErrors.push({
            itemPath: "",
            error: errorObj[errorKey],
            errorKey,
            errorPath,
          });
        }
      });
    return flattenedErrors;
  }

  function getSettingsErrors() {
    const formSubmissionConstraintErrors = pick(
      errors,
      "formSubmissionConstraint"
    );
    const clientGroupsErrors = pick(errors, "clientGroups");
    const nameErrors = pick(errors, "name");
    return getFlattenedErrors({
      ...clientGroupsErrors,
      ...nameErrors,
      ...(formSubmissionConstraintErrors.formSubmissionConstraint as unknown as FormSubmissionConstraintDTO),
    });
  }

  function getCreateErrors() {
    const createErrors = pick(errors, "sections");
    return getFlattenedErrors(createErrors);
  }

  /**
   * Get the flattened list of errors by type
   * @param type Form Builder grouping - "create" | "settings"
   */
  function getErrorsByType(type: FBValidationType): FBValidationError[] {
    switch (type) {
      case "create":
        return getCreateErrors();
      case "settings":
        return getSettingsErrors();
      default:
        return [];
    }
  }

  /**
   * Get an array of validation error types
   */
  function getErrorTypes(): FBValidationType[] {
    const errorTypes: FBValidationType[] = [];
    if (getSettingsErrors().length) {
      errorTypes.push("settings");
    }
    if (getCreateErrors().length) {
      errorTypes.push("create");
    }

    return errorTypes;
  }

  function getErrorsByPath(errorPath: any) {
    const allErrors = getFlattenedErrors(errors);
    return allErrors.filter((err) => err.itemPath === errorPath);
  }

  return {
    getValidationErrors: getFlattenedErrors,
    errorTypes: getErrorTypes(),
    getErrorsByType,
    getErrorsByPath,
    alertLevel,
  };
}

export default useValidation;
