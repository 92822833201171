import React, { FC } from "react";
import styles from "./SearchFilters.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";
import { AppliedFiltersProps } from "./types";
import { timeFilterOptions } from "shared/src/data/timeFilters";

interface AppliedFiltersFCProps {
  appliedFilters: AppliedFiltersProps;
  onRemoveFilter: (filterName: string) => void;
}

interface FilterProps {
  filterName: string;
  label: string;
}

export const AppliedFilters: FC<AppliedFiltersFCProps> = ({
  appliedFilters,
  onRemoveFilter,
}) => {
  const appliedFitlersArray = Object.entries(appliedFilters).reduce(
    (results, [filterName, label]) => {
      if (label) {
        if (filterName === "timeFilter") {
          label = timeFilterOptions.find((tfo) => tfo.value === label)?.label;
        }
        results.push({ filterName, label });
      }
      return results;
    },
    [] as FilterProps[]
  );

  return (
    <div className={styles.appliedFilters}>
      {appliedFitlersArray.map((filter) => {
        return (
          <div key={filter.filterName} className={styles.filterPill}>
            <span>{filter.label}</span>
            <div
              className={styles.iconContainer}
              onClick={() => onRemoveFilter(filter.filterName)}
              data-testid="delete_sign"
            >
              <Icon type="delete_sign" className={styles.icon} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
