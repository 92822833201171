import { Dispatch, SetStateAction } from "react";
import { API, FormTypeDTO } from "@rtslabs/field1st-fe-common";
import { serializeError } from "serialize-error";
import * as yup from "yup";

export async function loadFormType(
  id: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setFormType: Dispatch<SetStateAction<FormTypeDTO | undefined>>,
  setError: Dispatch<SetStateAction<"save" | "load" | "">>
) {
  try {
    const res = await API.getFormType({ id });
    setFormType(res);
  } catch (err) {
    const errorString = JSON.stringify(serializeError(err));
    console.error(errorString);
    setError("load");
  } finally {
    setLoading(false);
  }
}

export const validation = yup.object().shape({
  name: yup.string().required("Please enter a name"),
  iconColor: yup
    .string()
    .required("Please select a color")
    .matches(
      /^#(?:[0-9a-fA-F]{6})$/,
      "Please enter a valid 6-digit hexadecimal color code"
    ),
  iconName: yup.string().required("Please select an icon"),
  isGlobal: yup
    .boolean()
    .required()
    .test("has-group", "Please select at least one group", (value, ctx) => {
      if (!!value) return true;
      if (!ctx.parent.clientGroups) return false;
      return ctx.parent.clientGroups.length > 0;
    }),
});
