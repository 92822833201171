import React, { FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import { QAProps, ElementType } from "../../../../qa-slugs";
import { roles as allRoles } from "@rtslabs/field1st-fe-common";

import Button, { ButtonProps } from "../../../common/Button/Button";
import { Icon } from "../../../Icon/Icon";
import WriteContent from "../../permissions/WriteContent";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import styles from "./NoResults.module.scss";

export interface NoResultsContentProps extends QAProps {
  body: string;
  button?: ButtonProps;
  header: string;
  icon: string;
  center?: boolean;
  roles?: string[];
}

const NoResultsContent: FC<NoResultsContentProps> = ({
  icon,
  header,
  body,
  button,
  center = false,
  qa,
  roles = [allRoles.ROLE_CLIENT_REVIEWER],
}) => {
  const theme = useContext(ThemeContext);
  return (
    <div
      className={joinClassNames(
        styles.noResultsContentContainer,
        center && styles.centered
      )}
    >
      <Icon type={icon} color={theme.colors.activeFill} size="100px" />
      <div className={styles.noResultsTitle}>{header}</div>
      <WriteContent roles={roles}>
        <>
          <div className={styles.noResultsBody}>{body}</div>
          {button && (
            <Button
              {...button}
              qa={qa ? `${qa}-${ElementType.Button}` : undefined}
            />
          )}
        </>
      </WriteContent>
    </div>
  );
};

export default NoResultsContent;
