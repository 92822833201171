import { API, StoredObjectDTO } from "@rtslabs/field1st-fe-common";

/**
 * image file PUT to writable URL
 * @returns writableUrl
 */
export async function uploadS3Image(
  fileType: string,
  img: Blob
): Promise<string> {
  const writableUrl = await getWritableUrl();
  await fetch(writableUrl, {
    method: "PUT",
    headers: {
      "Content-Type": fileType,
    },
    body: img,
  });

  return writableUrl;
}

/**
 * generates writable URL
 */
async function getWritableUrl(): Promise<string> {
  const URLs = await API.getAuthJson<StoredObjectDTO>({
    path: "/application-config/presigned-url/writable",
  });
  return URLs.json.writableUrl!;
}
