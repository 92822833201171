import React, { createRef, FC, useEffect, useState } from "react";
import SigPad from "signature_pad";
import { Button, TertiaryButton } from "../../../Button/Button";
import { Icon } from "../../../Icon/Icon";
import styles from "./Signatures.module.scss";

interface Props {
  onAddSignature: (signatureData: string) => void;
  clearSignature: () => void;
  loading: boolean;
  name: string;
}

const SignaturePad: FC<Props> = ({
  name,
  loading,
  clearSignature,
  onAddSignature: attachURL,
}: Props) => {
  const containerRef = createRef<HTMLDivElement>();
  let canvasRef: HTMLCanvasElement | null = null;

  const [canvasWidth, setCanvasWidth] = useState<number>(600);
  const [containsSig, setContainsSig] = useState<boolean>(false);
  const [signaturePad, setSignaturePad] = useState<SigPad | undefined>();

  function on(): void {
    return signaturePad?.on();
  }

  function off(): void {
    return signaturePad?.off();
  }

  function clear(): void {
    setContainsSig(false);
    return signaturePad?.clear();
  }

  function resize(): void {
    setCanvasWidth(() => {
      if (window.innerWidth <= 768) {
        return window.innerWidth * 0.8;
      }
      return 600;
    });
  }

  function toDataURL(): string | undefined {
    if (!signaturePad?.isEmpty()) {
      return signaturePad?.toDataURL("img/png");
    }
    return;
  }

  function onConfirm(): void {
    const signatureDataUrl = toDataURL();
    if (signatureDataUrl) {
      return attachURL(signatureDataUrl);
    }
  }

  useEffect(() => {
    const handleBeginStroke = () => setContainsSig(true);

    let pad: SigPad | null = null;
    if (canvasRef) {
      pad = new SigPad(canvasRef);
      pad.addEventListener("beginStroke", handleBeginStroke);
      setSignaturePad(pad);
      on();
      window.addEventListener("resize", resize);
    }

    return () => {
      off();
      pad?.removeEventListener("beginStroke", handleBeginStroke);
      window.removeEventListener("resize", resize);
    };
  }, [canvasRef]);

  return (
    <div
      className={styles.signaturePad}
      ref={containerRef}
      id={name}
      tabIndex={0}
    >
      {!containsSig ? (
        <div className={styles.centerElement}>
          <Icon type="autograph" className={styles.signHereIcon} />
          <span className={styles.signHereLabel}>Sign Here</span>
        </div>
      ) : (
        <TertiaryButton
          className={styles.clearButton}
          onClick={(e) => {
            e.preventDefault();
            clear();
            clearSignature();
          }}
        >
          <span className={styles.clearButtonLabel}>CLEAR SIGNATURE</span>
        </TertiaryButton>
      )}
      <Button
        className={styles.confirmButton}
        loading={loading}
        onClick={(e) => {
          e.preventDefault();
          onConfirm();
        }}
      >
        {window.innerWidth > 600 ? "CONFIRM SIGNATURE" : "CONFIRM"}
      </Button>
      <canvas
        className={styles.canvas}
        ref={(ref) => (canvasRef = ref)}
        width={canvasWidth}
        height={250}
      />
      <div className={styles.bottomLine} />
    </div>
  );
};

SignaturePad.displayName = "SignaturePad";
export default SignaturePad;
