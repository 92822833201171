// Form
export const RequiredMessage = "Required";
export const emailValidationPattern = RegExp("^.+@.+$");
export const nameValidationPattern = RegExp("^(?! )[0-9a-zA-Z-]*$");

/**
 * Roles
 * These are the roles used by the API and their friendly names
 * isGlobal is configurable on the backend, but the design calls for below
 * fields `id` and `value` are used in partial `Option`
 */
export interface Role {
  id: string;
  value: string;
  isGlobal?: boolean;
  backendManaged?: boolean;
}

export const roles: Role[] = [
  {
    id: "ROLE_CLIENT_ADMIN",
    value: "Client Admin",
    isGlobal: true,
    backendManaged: true,
  },
  { id: "ROLE_CLIENT_REVIEWER", value: "Client Reviewer", isGlobal: true },
  { id: "ROLE_FORM_ARCHITECT", value: "Form Architect" },
  { id: "ROLE_CONTENT_EDITOR", value: "Content Editor" },
  { id: "ROLE_GROUP_MANAGER", value: "Group Manager", isGlobal: false },
  { id: "ROLE_USER", value: "User" },
];
