import React from "react";

interface ClassName {
  className?: string;
  viewBox?: string;
}

export const SortUp = ({ className, viewBox }: ClassName) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox={"0 0 24 24" || viewBox}
      width="24px"
      className={className}
    >
      <path d="M 12.029297 8.5273438 A 1.2200669 1.2200669 0 0 0 11.136719 8.8847656 L 4.6308594 15.390625 A 1.2200669 1.2200669 0 0 0 5.4941406 17.472656 L 18.505859 17.472656 A 1.2200669 1.2200669 0 0 0 19.369141 15.390625 L 12.863281 8.8847656 A 1.2200669 1.2200669 0 0 0 12.029297 8.5273438 z " />
    </svg>
  );
};
