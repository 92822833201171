import React from "react";
import styles from "./RecentDocuments.module.scss";

interface MapContent {
  location?: string | null;
  icon?: {
    type: string;
    color: string;
  };
}

/**
 * **MapContent** returns a map based on the given location value.
 * If the given location value results in unusable data or is missing
 * then we'll render the fallback content (form type icon, grey background)
 */
const MapContent = ({ location }: MapContent) => {
  const coords =
    location
      ?.replace(/\(|\)/gi, "")
      .split(",")
      .map((c) => Number(c.trim())) || [];
  const [latitude, longitude] = coords;
  const hasGeoLocation = longitude && latitude;

  return (
    <div className={styles.mapContent}>
      {hasGeoLocation && (
        <img
          className={styles.mapImage}
          src={`https://api.maptiler.com/maps/streets/static/${longitude},${latitude},10/136x52@2x.png?key=5eMrAi6NUCpm1Rv5zRKC`}
          alt="map"
        />
      )}

      {!hasGeoLocation && (
        <span className={styles.noMapAvailable}>No Map Available</span>
      )}
    </div>
  );
};

export default MapContent;
