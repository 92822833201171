import { Collapse } from "@mui/material";
import { camelCase } from "lodash";
import React, { FC, Fragment, useId } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "shared/src/components/Icon/Icon";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Components, ElementType } from "shared/src/qa-slugs";
import { NavigationMenuLink } from "./NavigationList";
import { NavigationListLink } from "./NavigationListLink";
import styles from "./NavigationListLink.module.scss";

interface Props {
  active: boolean;
  expanded: boolean;
  menuItem: NavigationMenuLink;
  setOpen: (key: string, value: boolean) => void;
}

export const NavigationListExpansion: FC<Props> = ({
  active,
  expanded,
  menuItem,
  setOpen,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const expansionId = useId();

  return (
    <Fragment key={menuItem.id}>
      <button
        aria-controls={expansionId}
        aria-expanded={expanded}
        className={joinClassNames(
          styles.navigationListButton,
          styles.navigationItem,
          active ? styles.active : styles.inactive,
          menuItem.disabled && styles.disabled
        )}
        data-testid={`${Components.SidebarNavigation}-${
          ElementType.Link
        }-${camelCase(menuItem.link)}`}
        onClick={() => {
          if (menuItem.expansion && menuItem.id) {
            setOpen(menuItem.id, !expanded);
          }
        }}
        type="button"
      >
        <Icon type={menuItem.icon} className={styles.navigationItemIcon} />
        <div className={styles.navigationItemLink}>{menuItem.link}</div>
      </button>
      <Collapse id={expansionId} in={expanded} timeout="auto" unmountOnExit>
        {menuItem.expansion &&
          menuItem.expansion.map((s) => (
            <NavigationListLink
              key={`NavigatationListLink-${menuItem.id}-${s.id}`}
              active={s.linkTo === pathname}
              inExpansion={true}
              menuItem={s}
            />
          ))}
      </Collapse>
    </Fragment>
  );
};
