import React, { useState } from "react";
import downloadFileFromBlob from "downloadjs";

import Loader from "../../Loader/Loader";
import { joinClassNames } from "../../../helpers/theme.helpers";
import styles from "./TableSummary.module.scss";
import { API } from "@rtslabs/field1st-fe-common";
import { ActionButton } from "../../ActionButton/ActionButton";
import { Icon, IconProps } from "../../Icon/Icon";
import { LinkButton } from "../../Button/Button";

export interface DownloadParams {
  mediaType: API.SpreadsheetType;
}

interface DownloadResultsButtonProps<T extends DownloadParams> {
  className?: string;
  onExportPress: (args: T) => Promise<API.GetFileResponse>; // has to be a download function
  exportParams: Omit<T, "mediaType">; // has to be download params, but fill in media Type with below
  mediaType: API.SpreadsheetType;
  iconType?: IconProps["type"];
  isTextVariant?: boolean;
  label: string;
  qa?: string;
}

/**
 * DownloadResultsButton wraps the passed children with a clickable span.
 * When clicked, the resulting export is fetched via onExportPress and
 * downloaded as a file.
 **/
export const DownloadResultsButton = <T extends DownloadParams>({
  className,
  onExportPress,
  iconType,
  isTextVariant,
  label,
  exportParams,
  mediaType,
  qa,
}: DownloadResultsButtonProps<T>) => {
  const [loading, setLoading] = useState(false);

  const doExport = async () => {
    setLoading(true);
    try {
      const res = await onExportPress({ ...exportParams, mediaType } as T);
      downloadFileFromBlob(res.blob, res.fileName, mediaType);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      data-testid={qa && qa}
      className={joinClassNames(styles.actionButton, className)}
    >
      <Loader
        className={styles.downloadResultsLoader}
        loading={loading}
        spinnerProps={{ size: 24, style: { lineHeight: 0 } }}
      >
        {isTextVariant ? (
          <LinkButton onClick={doExport}>{label}</LinkButton>
        ) : (
          <ActionButton
            IconComponent={
              <Icon className={styles.icon} type={iconType || "print"} />
            }
            label={label}
            onClick={doExport}
          />
        )}
      </Loader>
    </div>
  );
};
