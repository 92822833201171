import React, { useEffect, useState } from "react";

export const HazardAnalyzingMessage = () => {
  const analyzingMessages = [
    "Detecting dangers.",
    "Scanning for threats, stay safe.",
    "Analyzing hazards, safety first.",
    "Hazard hunt in progress.",
    "Eyes on risks, patience pays off.",
    "Identifying hazards, one pixel at a time.",
    "Safety patrol underway.",
    "Alert analysis ongoing, stay alert.",
    "Risk assessment in motion.",
  ];
  const [currentAnalyzingMessage, setCurrentAnalyzingMessage] = useState(
    analyzingMessages[0]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const itemNum = Math.floor(Math.random() * analyzingMessages.length);
      setCurrentAnalyzingMessage(analyzingMessages[itemNum]);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return <span>{currentAnalyzingMessage}</span>;
};
