const remCalc = (value: number) => `${value / 16}rem`;

const hexToDecimal = (value: string) => {
  return parseInt(value, 16).toString(10);
};

const hexToRgbTuple = (hex: string) => {
  const hexPrefix = "#";
  const hexColor = hex.slice(hex.indexOf(hexPrefix) + hexPrefix.length);
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);
  return [r, g, b];
};

/**
 * Converts a CSS rgb function expression 'rgb(r, g, b)' or hex color '#rrggbb'
 * to 'rgba(r, g, b, a)'.
 */
const toRgba = (rgb: string, a: number): string => {
  const prefix = "rgb(";
  let tupleContents: string;
  if (rgb.startsWith(prefix)) {
    tupleContents = rgb.slice(
      rgb.indexOf(prefix) + prefix.length,
      rgb.lastIndexOf(")")
    );
  } else {
    const hexPrefix = "#";
    const hexColor = rgb.slice(rgb.indexOf(hexPrefix) + hexPrefix.length);
    const r = hexToDecimal(hexColor.slice(0, 2));
    const g = hexToDecimal(hexColor.slice(2, 4));
    const b = hexToDecimal(hexColor.slice(4, 6));
    tupleContents = [r, g, b].join(", ");
  }
  return `rgba(${tupleContents}, ${a})`;
};

const rgbToHexColor = (rgb: string) => {
  const prefix = "rgb(";
  const hexColor = rgb
    .slice(rgb.indexOf(prefix) + prefix.length, rgb.lastIndexOf(")"))
    .split(",")
    .map((value) => parseInt(value).toString(16).padStart(2, "0"))
    .join("");
  return `#${hexColor}`;
};

const transitionsWithSameTiming = (
  properties: string[],
  duration: string,
  timingFunction: string
): string => {
  return properties
    .map((property) => `${property} ${duration} ${timingFunction}`)
    .join(", ");
};

export {
  hexToRgbTuple,
  remCalc,
  rgbToHexColor,
  toRgba,
  transitionsWithSameTiming,
};
