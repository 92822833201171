import {
  API,
  CoreRedux,
  DocumentVm,
  FormVersionDTO,
  FormVm,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { JSXElementConstructor, useState } from "react";
import { setIsPreview } from "../../../../../redux/document/documentActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { getPreviewDocument } from "./getPreviewDocument";
import { StateSetter } from "../../../../../helpers/react.helpers";
import { FormWidgetProps } from "../../../../Document/DocumentForm/types";

export interface HistoryPreviewState {
  document?: DocumentVm;
  fetchError?: string;
  form?: FormVm;
  isLoadingForm: boolean;
  setDocument: StateSetter<DocumentVm | undefined>;
  widget: JSXElementConstructor<FormWidgetProps>;
  documentTerm: string;
}

export function useHistoryPreview(
  formVersion: FormVersionDTO,
  widget: JSXElementConstructor<FormWidgetProps>,
  documentTerm: string
): HistoryPreviewState {
  const [document, setDocument] = useState<DocumentVm | undefined>();
  const [fetchError, setFetchError] = useState<string | undefined>();
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const dispatch = useDispatch();
  const userParticipant = useSelector(CoreRedux.selectUser);
  const [form, setForm] = useState<FormVm | undefined>();

  useAsyncEffect(async () => {
    try {
      setIsLoadingForm(true);
      setFetchError(undefined);
      const form = await API.getFormById({ id: formVersion.formId! });
      setForm(form);
      setDocument(getPreviewDocument(form, userParticipant!));
      dispatch(setIsPreview(true));
    } catch (error) {
      setFetchError("Failed to load the version preview.");
    } finally {
      setIsLoadingForm(false);
    }
  }, [formVersion, userParticipant]);

  return {
    document,
    fetchError,
    form,
    isLoadingForm,
    setDocument,
    widget,
    documentTerm,
  };
}
