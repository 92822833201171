import React from "react";

import {
  Pagination as PaginationType,
  PaginationParams,
} from "@rtslabs/field1st-fe-common";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "../../../../../../shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "../../../../../../shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { Components, ElementType } from "shared/src/qa-slugs";

interface Props {
  params: PaginationParams;
  paginationValues?: Omit<PaginationType, "content">;
  onUpdateParams: (param: Partial<PaginationParams>) => void;
}

const TagLibraryTablePagination = ({
  params,
  paginationValues,
  onUpdateParams,
}: Props) => {
  return (
    <PaginationWrapper>
      <RowsPerPage
        pageSize={params.size}
        onClick={(size) => onUpdateParams({ size })}
        qaBase={`${Components.TagLibraryTablePagination}-${ElementType.SelectInput}`}
      />
      <Pagination
        currentPage={params.page}
        totalPages={paginationValues?.totalPages || 0}
        onClick={(page) => onUpdateParams({ page })}
        qaBase={`${Components.TagLibraryTablePagination}-${ElementType.Link}`}
      />
      <BackToTop
        qa={`${Components.TagLibraryTablePagination}-${ElementType.Link}-backToTop`}
      />
    </PaginationWrapper>
  );
};

export default TagLibraryTablePagination;
