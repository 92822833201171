import React, { FC } from "react";

import { Icon } from "../../Icon/Icon";
import { joinClassNames } from "../../../helpers/theme.helpers";
import scssVariables from "shared/src/sass/jsExports.module.scss";

import styles from "./EditProfile.module.scss";
import { LockedValue } from "./Locked";

export interface ProfileFieldProps {
  children?: React.ReactNode;
  label?: string | null;
  locked?: boolean;
  lockedValue?: LockedValue | JSX.Element;
  variant?: "DEFAULT" | "SELECT" | "TEXT_FIELD" | "GROUP";
  isDesktop?: boolean;
  name: string;
}

export const ProfileField: FC<ProfileFieldProps> = ({
  isDesktop,
  locked,
  label,
  name,
  lockedValue,
  children,
}) => {
  return (
    <div
      className={joinClassNames(
        styles.profileField,
        !isDesktop && styles.profileField_tablet
      )}
    >
      <div className={styles.left}>
        {locked && (
          <div className={styles.lockedLabel}>
            <Icon
              type="lock"
              color={scssVariables.mediumGrey}
              className={styles.lockIcon}
            />
          </div>
        )}
        <label
          htmlFor={name}
          className={joinClassNames(
            styles.label,
            locked && styles.label_locked
          )}
        >
          {label || "..."}
        </label>
      </div>
      <div data-testid={`${label}`} className={styles.right}>
        {locked ? <div className={styles.value}>{lockedValue}</div> : children}
      </div>
    </div>
  );
};
