import {
  DocumentStatus,
  DocumentSubmissionType,
  DocumentVm,
  FormVm,
  ParticipantUserVm,
} from "@rtslabs/field1st-fe-common";

export const getPreviewDocument = (form: FormVm, owner: ParticipantUserVm) => {
  const now = new Date().toISOString();
  const previewDocument: DocumentVm = {
    dateCreated: now,
    form,
    formActionRequests: [],
    formSummary: {
      id: form.id,
      name: form.name,
      rootId: form.rootId,
      type: form.type,
      workflowType: form.workflowType,
    },
    id: -1,
    operationalExperiences: [],
    owner,
    participants: [
      {
        fullName: owner.fullName,
        id: -1,
        name: owner.name,
        role: "SUPERVISOR",
        timeAdded: now,
      },
    ],
    photos: [],
    readOnly: false,
    responses: [],
    resubmissions: [],
    sectionComments: [],
    startDate: now,
    status: DocumentStatus.IN_PROGRESS,
    submissionDate: now,
    submissionId: -1,
    submissionType: DocumentSubmissionType.NEW,
    summary: { title: "Document" },
    title: "Document",
  };
  return previewDocument;
};
