import React from "react";
import { sortBy } from "lodash";

import Loader from "shared/src/components/Loader/Loader";

import { Components, ElementType } from "shared/src/qa-slugs";
import { getSubmissionTimeError } from "../../helpers";
import { ListItem } from "shared/src/components/List/ListItem";
import { ChangeTypeButton } from "shared/src/components/Documents/components/NewForm/ChangeTypeButton";
import styles from "./NewForm.module.scss";
import { FormSummaryVm, FormTypeDTO } from "@rtslabs/field1st-fe-common";

const filterFormTemplatesByFormTypeID = (
  formTypeID: number,
  formTemplates: FormSummaryVm[]
) => {
  return formTemplates.filter((x) => x.type.id === formTypeID);
};

export interface SelectFormProps {
  formTemplates: FormSummaryVm[];
  handleSelectFormTemplate: (formTemplate?: FormSummaryVm) => void;
  loading: boolean;
  selectedFormTemplateId?: number;
  selectedFormTypeId?: FormTypeDTO["id"];
}

/**
 * **SelectFormTemplate** handles the second step of the generalized
 * "Add New Form" feature. It takes in an array of "subTypes" which
 * will usually be based on the selected "type" from the companion
 * component **SelectFormType**
 */
const SelectForm = ({
  loading,
  handleSelectFormTemplate,
  selectedFormTemplateId,
  selectedFormTypeId,
  formTemplates,
}: SelectFormProps) => {
  // render loading based on redux loading flag
  // is loading when API call for documents is in flight
  if (loading) {
    return <Loader loading />;
  }

  // render nothing is user hasn't selected a Form or there
  // are no Form Templates
  if (!selectedFormTypeId || formTemplates.length === 0) {
    return null;
  }

  const formTemplatesOfSelectedFormType = selectedFormTypeId
    ? filterFormTemplatesByFormTypeID(selectedFormTypeId, formTemplates)
    : [];

  return (
    <>
      {sortBy(formTemplatesOfSelectedFormType, (formTemplate) =>
        formTemplate.name.toLowerCase()
      ).map((formTemplate) => {
        if (
          selectedFormTemplateId &&
          selectedFormTemplateId !== formTemplate.id
        ) {
          return null;
        }

        const submissionTimeError = getSubmissionTimeError(
          formTemplate.formSubmissionConstraint
        );

        return (
          <ListItem
            qa={`${Components.ListItem}-${ElementType.Link}-form-${selectedFormTypeId}`}
            disabled={!!submissionTimeError}
            key={formTemplate.id}
            active={selectedFormTemplateId === formTemplate.id}
            onClick={() =>
              !submissionTimeError && handleSelectFormTemplate(formTemplate)
            }
            className={styles.formItem}
          >
            <div>
              {formTemplate.name}
              {submissionTimeError && (
                <p className={styles.disabledForm}>{submissionTimeError}</p>
              )}
            </div>
            {selectedFormTemplateId === formTemplate.id && (
              <ChangeTypeButton
                onClick={() => handleSelectFormTemplate(undefined)}
              />
            )}
          </ListItem>
        );
      })}
    </>
  );
};

export default SelectForm;
