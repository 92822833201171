import {
  API,
  ReportingFormTypeDocumentCountVm,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import { useEffect, useState } from "react";
import {
  getMinMaxSubmissionDatesFromTimeFilter,
  TimeFilter,
} from "shared/src/data/timeFilters";

export function useReportData() {
  const [selectedFormType, setSelectedFormType] =
    useState<ReportingFormTypeDocumentCountVm | null>(null);
  const [selectedFormTypeData, setSelectedFormTypeData] =
    useState<ReportingFormTypeDocumentCountVm | null>(null);
  const [reportCountTotal, setReportCountTotal] = useState<number>(0);
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(
    TimeFilter.THIRTY_DAYS
  );
  const { minSubmissionDate, maxSubmissionDate } =
    getMinMaxSubmissionDatesFromTimeFilter(timeFilter);

  // get form type data
  const allFormTypeStatsCall = useAPI(API.getFormTypesReport, {
    minSubmissionDate,
    maxSubmissionDate,
  });

  const [formTypeData, setFormTypeData] = useState<
    ReportingFormTypeDocumentCountVm[] | null
  >(null);

  useEffect(() => {
    if (allFormTypeStatsCall.data) {
      setFormTypeData(
        allFormTypeStatsCall.data.sort((a, b) => b.totalCount - a.totalCount)
      );
      setSelectedFormType(
        allFormTypeStatsCall.data.length > 0 &&
          allFormTypeStatsCall.data[0].totalCount > 0
          ? allFormTypeStatsCall.data[0]
          : null
      );
      setReportCountTotal(
        allFormTypeStatsCall.data.reduce((acc, rts) => {
          return acc + rts.totalCount;
        }, 0)
      );
    } else {
      setSelectedFormType(null);
    }
  }, [allFormTypeStatsCall.data]);

  useEffect(() => {
    if (selectedFormType?.formType.id) {
      const data = allFormTypeStatsCall.data!.find(
        (d) => d.formType.id === selectedFormType.formType.id
      );
      if (data) {
        setSelectedFormTypeData(data);
        return;
      }
    }
    setSelectedFormTypeData(null);
  }, [selectedFormType]);

  return {
    reportDataLoading: allFormTypeStatsCall.isLoading,
    formTypeData,
    selectedFormType,
    setSelectedFormType,
    selectedFormTypeData,
    reportCountTotal,
    setTimeFilter,
    timeFilter,
  };
}
