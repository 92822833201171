import { LatLng } from "@rtslabs/field1st-fe-common";
import { loadModules } from "esri-loader";

type ServiceConfig = {
  serviceUrl: string;
};

let geocodeServiceConfig: ServiceConfig = {
  serviceUrl:
    "http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer",
};

export const geocode = async (
  address: string
): Promise<{ address: string; geolocation: LatLng } | false> => {
  const [locator] = await loadModules<[__esri.locator]>(["esri/rest/locator"]);

  const serviceUrl = geocodeServiceConfig.serviceUrl;

  const addressCandidates = await locator.addressToLocations(serviceUrl, {
    address: {
      SingleLine: address,
    },
  });

  if (addressCandidates.length === 0) {
    return false;
  }

  return {
    address: addressCandidates[0].address,
    geolocation: {
      latitude: addressCandidates[0].location.latitude,
      longitude: addressCandidates[0].location.longitude,
    },
  };
};

export const reverseGeocode = async (geolocation: LatLng) => {
  const [locator, Point] = await loadModules<
    [__esri.locator, __esri.PointConstructor]
  >(["esri/rest/locator", "esri/geometry/Point"]);

  const serviceUrl = geocodeServiceConfig.serviceUrl;

  const addressCandidate = await locator.locationToAddress(serviceUrl, {
    location: new Point({
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
    }),
  });

  return addressCandidate.address;
};
