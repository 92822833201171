import {
  API,
  CoreRedux,
  DocumentHistoryVm,
  DocumentSummaryVm,
  DocumentVm,
  FormSummaryVm,
  FormTypeDTO,
  RelatedDocumentVm,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { Drawer } from "shared/src/components/Drawer/Drawer";
import { DocumentHeader } from "../Documents/components/DocumentSidebarDrawer/DocumentSidebarDrawerHeader/DocumentSidebarDrawerHeader";
import {
  getSubmissionTimeError,
  prettifyWorkorderID,
} from "../Documents/helpers";
import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import RehuddleSidebar from "../Documents/RehuddleSidebar/RehuddleSidebar";
import FormTypesDrawer from "../Documents/components/DocumentSidebarDrawer/FormTypesDrawer/FormTypesDrawer";
import RelatedDocumentsSidebar from "../Documents/components/DocumentSidebarDrawer/RelatedDocumentsSidebar";
import ShareDocumentSidebar from "shared/src/components/Documents/components/DocumentSidebarDrawer/ShareDocumentSidebar/ShareDocumentSidebar";
import DocumentHistorySidebar from "../Documents/components/DocumentSidebarDrawer/DocumentHistorySidebar";
import DocumentSidebarMenu from "../Documents/components/DocumentSidebarDrawer/DocumentSideBarMenu/DocumentSideBarMenu";
import FormTemplatesDrawer from "../Documents/components/DocumentSidebarDrawer/FormTemplatesDrawer/FormTemplatesDrawer";
import { InterstitialDrawer } from "../Documents/components/DocumentSidebarDrawer/InterstitialDrawer/InterstitialDrawer";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import Loader from "shared/src/components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { DocumentRouteState } from "./Document";
import { Components } from "../../../../shared/src/qa-slugs";

interface DocumentSidebarProps {
  anchor?: "right" | "bottom" | "left" | "top";
  onClose: () => void;
  open: boolean;
  selectedDocument?: DocumentSummaryVm;
  overrideClasses?: {
    [key: string]: string;
  };
}

/**
 * Handles rendering the Document Sidebar and all children sidebar menus
 */
const DocumentSidebar: FC<DocumentSidebarProps> = ({
  anchor = "right",
  onClose,
  open,
  selectedDocument,
}) => {
  const navigate = useNavigate();
  const [formTypes, setFormTypes] = useState<FormTypeDTO[]>([]);
  const [formTypesLoading, setFormTypesLoading] = useState<boolean>(false);
  const [rehuddleIsVisible, setRehuddleIsVisible] = useState(false);
  const [shareDocumentIsVisible, setShareDocumentIsVisible] =
    useState<boolean>(false);
  const [documentHistoryIsVisible, setDocumentHistoryIsVisible] =
    useState<boolean>(false);
  const [relatedDocumentsIsVisible, setRelatedDocumentsIsVisible] =
    useState<boolean>(false);
  const [formTypesDrawerIsVisible, setFormTypesDrawerIsVisible] =
    useState<boolean>(false);
  const [formTemplatesDrawerIsVisible, setFormTemplatesDrawerIsVisible] =
    useState<boolean>(false);
  const [interstitialDrawerIsVisible, setInterstitialDrawerIsVisible] =
    useState<boolean>(false);

  // Related Documents State
  const [relatedDocuments, setRelatedDocuments] = useState<RelatedDocumentVm[]>(
    []
  );
  const [isRelatedDocumentsLoading, setIsRelatedDocumentsLoading] =
    useState<boolean>(false);
  const [hasRelatedDocumentsError, setHasRelatedDocumentsError] =
    useState<boolean>(false);

  useAsyncEffect(async () => {
    setFormTypesLoading(true);
    try {
      const res = await API.getAllFormTypes();
      setFormTypes(res);
    } finally {
      setFormTypesLoading(false);
    }
  }, []);

  useAsyncEffect(async () => {
    if (selectedDocument) {
      setIsRelatedDocumentsLoading(true);
      setHasRelatedDocumentsError(false);
      try {
        const res = await API.getRelatedDocuments({
          documentId: selectedDocument.id,
        });
        setRelatedDocuments(res);
      } catch {
        setHasRelatedDocumentsError(true);
      } finally {
        setIsRelatedDocumentsLoading(false);
      }
    }
  }, [selectedDocument?.id]);

  // Group config terms
  const documentGroupTerm = useSelector((state: CoreRedux.Store.RootState) =>
    CoreRedux.selectTermByVisibleId(state, "document")
  );

  const rehuddleGroupTerm = useSelector((state: CoreRedux.Store.RootState) =>
    CoreRedux.selectTermByVisibleId(state, "refocus")
  );

  // Selected Form Type
  const [selectedFormType, setSelectedFormType] = useState<FormTypeDTO | null>(
    null
  );
  // Selected Form Template
  const [selectedTemplate, setSelectedTemplate] =
    useState<FormSummaryVm | null>(null);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${scssVariables.minDesktop})`,
  });

  // Get relationshipTypes from selected document
  const relationshipTypes = useMemo(
    () => selectedDocument?.formSummary?.relationshipTypes || [],
    [selectedDocument?.id]
  );

  // Filtered form types, by available relationship types
  const filteredFormTypes = formTypes.filter((type) =>
    relationshipTypes.some(
      (relationship) => relationship.formTypeId === type.id
    )
  );

  // On successful related document submission
  const onSuccessfulRelatedDocumentSubmission = (newDocument: DocumentVm) => {
    setInterstitialDrawerIsVisible(false);
    setFormTemplatesDrawerIsVisible(false);
    setFormTypesDrawerIsVisible(false);
    onClose && onClose();
    const state: DocumentRouteState = {
      document: newDocument,
    };
    navigate(`/document/${newDocument.id}`, { state });
  };

  const onSuccessfulRehuddle = () => {
    setRehuddleIsVisible(false);
    onClose && onClose();
  };

  if (!selectedDocument) {
    return null;
  }

  function fetchDocument(id: number): Promise<DocumentHistoryVm[]> {
    return API.getDocumentHistory({ documentId: id });
  }

  const handleShareDocument = async (
    participantIds: number[]
  ): Promise<void> => {
    const share: API.ShareDocumentArgs = {
      share: {
        documentId: selectedDocument.id,
        participantIds,
      },
    };
    await API.shareDocument(share);
  };

  return (
    <>
      <Drawer
        isOpen={open}
        onClose={onClose}
        anchor={isDesktop ? anchor : "bottom"}
        id="DocumentSidebar"
        qa={`${Components.Document}s-drawer`}
      >
        <DrawerContentWrapper>
          <div>
            <DocumentHeader
              formType={selectedDocument.formSummary.name}
              ownerName={selectedDocument.owner.name}
              isRehuddle={selectedDocument.isRehuddle}
              subtitle1={selectedDocument ? selectedDocument.title : ""}
              subtitle2={`ID: ${prettifyWorkorderID(
                selectedDocument.workOrder?.workOrderId || "-"
              )}`}
              subtitle3={
                selectedDocument && selectedDocument.submissionDate
                  ? moment(selectedDocument.submissionDate).format(
                      "MMMM Do, YYYY"
                    )
                  : ""
              }
              parentId={selectedDocument.parentId}
              status={selectedDocument.status}
              title={selectedDocument.formSummary.type.name}
              terms={{
                rehuddle: rehuddleGroupTerm,
              }}
              onClose={onClose}
            />
            <Loader loading={formTypesLoading}>
              <DocumentSidebarMenu
                document={selectedDocument}
                formTypes={filteredFormTypes}
                onSelectFormType={(type: FormTypeDTO) => {
                  setSelectedFormType(type);
                  setFormTypesDrawerIsVisible(false); // Hide this drawer
                  setFormTemplatesDrawerIsVisible(true); // Show next drawer (templates)
                }}
                relatedDocuments={{
                  data: relatedDocuments,
                  isLoading: isRelatedDocumentsLoading,
                  hasError: hasRelatedDocumentsError,
                }}
                toggleDocumentHistory={() => setDocumentHistoryIsVisible(true)}
                toggleFormTypesVisibility={() =>
                  setFormTypesDrawerIsVisible(true)
                }
                toggleRehuddleSidebarVisibility={() =>
                  setRehuddleIsVisible(true)
                }
                toggleRelatedDocumentsVisibility={() =>
                  setRelatedDocumentsIsVisible(true)
                }
                toggleShareDocumentVisibility={() =>
                  setShareDocumentIsVisible(true)
                }
                terms={{
                  document: {
                    singular: documentGroupTerm,
                    plural: documentGroupTerm,
                  },
                  rehuddle: rehuddleGroupTerm,
                }}
              />
            </Loader>
          </div>
        </DrawerContentWrapper>
      </Drawer>

      <RehuddleSidebar
        isOpen={rehuddleIsVisible}
        setIsOpen={setRehuddleIsVisible}
        isDesktop={isDesktop}
        onSuccess={onSuccessfulRehuddle}
        selectedDocument={selectedDocument}
        terms={{
          document: {
            singular: documentGroupTerm,
          },
          rehuddle: rehuddleGroupTerm,
        }}
      />

      <ShareDocumentSidebar
        isOpen={shareDocumentIsVisible}
        onClose={() => setShareDocumentIsVisible(false)}
        onShareDocument={handleShareDocument}
      />

      <DocumentHistorySidebar
        open={documentHistoryIsVisible}
        onClose={() => setDocumentHistoryIsVisible(false)}
        isDesktop={isDesktop}
        document={selectedDocument}
        fetchDocumentHistory={fetchDocument}
        term={documentGroupTerm}
      />

      {/* View Related Documents */}
      <RelatedDocumentsSidebar
        open={relatedDocumentsIsVisible}
        onClose={() => setRelatedDocumentsIsVisible(false)}
        isDesktop={isDesktop}
        document={selectedDocument}
        relatedDocuments={{
          data: relatedDocuments,
          isLoading: isRelatedDocumentsLoading,
          hasError: hasRelatedDocumentsError,
        }}
        terms={{
          document: {
            plural: documentGroupTerm,
          },
          rehuddle: rehuddleGroupTerm,
        }}
      />

      <FormTypesDrawer
        formTypes={filteredFormTypes}
        isDesktop={isDesktop}
        isOpen={formTypesDrawerIsVisible}
        relationshipTypes={relationshipTypes}
        setIsOpen={setFormTypesDrawerIsVisible}
        onSelectFormType={(type: FormTypeDTO) => {
          setSelectedFormType(type); // Store form type
          setFormTypesDrawerIsVisible(false); // Hide this drawer
          setFormTemplatesDrawerIsVisible(true); // Show next drawer (templates)
        }}
      />

      <FormTemplatesDrawer
        isOpen={formTemplatesDrawerIsVisible}
        setIsOpen={setFormTemplatesDrawerIsVisible}
        isDesktop={isDesktop}
        onSelectFormTemplate={(template: FormSummaryVm) => {
          setSelectedTemplate(template); // Store form template
          setInterstitialDrawerIsVisible(true); // Show next drawer (interstitial)
        }}
        selectedFormType={selectedFormType}
        title={`Start Related ${selectedFormType?.name}`}
      />

      <InterstitialDrawer
        formId={selectedTemplate?.id}
        formType={selectedFormType?.name}
        submissionTimeError={getSubmissionTimeError(
          selectedTemplate?.formSubmissionConstraint
        )}
        isDesktop={isDesktop}
        isOpen={interstitialDrawerIsVisible}
        onSuccess={onSuccessfulRelatedDocumentSubmission}
        selectedDocument={selectedDocument}
        setIsOpen={setInterstitialDrawerIsVisible}
        title={selectedTemplate?.name}
      />
    </>
  );
};

export default DocumentSidebar;
