/// <reference types="@types/arcgis-js-api" />

import { loadModules } from "esri-loader";

interface ArcGisConfig {
  apiKey: string;
}

export const configureArcGis = async ({ apiKey }: ArcGisConfig) => {
  const [esriConfig] = await loadModules<[__esri.config]>(["esri/config"]);
  esriConfig.apiKey = apiKey;
};
