import React from "react";
import styles from "./VerticalTabGroup.module.scss";
import { TabGroup, TabGroupProps } from "./TabGroup";
import { joinClassNames } from "../../../helpers/theme.helpers";

export const VerticalTabGroup = ({ classes, ...rest }: TabGroupProps) => (
  <TabGroup
    classes={{
      ...classes,
      tabsContainer: joinClassNames(
        styles.tabsContainer,
        classes && classes.tabsContainer
      ),
      tabList: joinClassNames(styles.tabList, classes && classes.tabList),
      tab: joinClassNames(styles.tab, classes && classes.tab),
    }}
    {...rest}
  />
);
