import { AxisTickProps } from "@nivo/axes";
import { BarSvgProps, ResponsiveBar } from "@nivo/bar";
import React, { CSSProperties, useContext } from "react";
import { useCommonChartProps } from "shared/src/components/Charts/common";
import Loader from "shared/src/components/Loader/Loader";
import { ThemeContext } from "styled-components";
import { FormReportSafetyResponses } from "../../helpers";
import ChartHeader from "./ChartHeader";
import CustomBarElement from "./CustomBar";
import styles from "./SafetyScale.module.scss";

type SafetyScaleProps = {
  dayFilterValue: string;
  loading: boolean;
  ratingsFilter: string[];
  responses: FormReportSafetyResponses;
  totalResponseCount: number;
};

type linesAcc = {
  lines: string[];
  currLine: string;
};

const getTspanGroups = (value: string, maxLineLength = 12, maxLines = 2) => {
  if (typeof value !== "string") return;
  const words = value.split(" ");

  //reduces the words into lines of maxLineLength
  const assembleLines: linesAcc = words.reduce(
    (acc: linesAcc, word: string) => {
      /* if the current line isn't empty and the word + current line is larger than the allowed line size, create a new
       * line and update current line */
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== "") {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }
      //otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine + " " + word,
      };
    },
    { lines: [], currLine: "" }
  );

  //add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  //for now, only take first 2 lines due to tick spacing and possible overflow
  const lines = allLines.slice(0, maxLines);
  const children: JSX.Element[] = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan style={{ marginRight: "1rem" }} x={0} dy={dy} key={i}>
        {
          // if on the second line, and that line's length is within 3 of the max length, add ellipsis
          1 === i && allLines.length > 2
            ? lineText.slice(0, maxLineLength - 3) + "..."
            : lineText
        }
      </tspan>
    );
    //increment dy to render next line text below
    dy += 15;
  });

  return children;
};

const SafetyScale = ({
  dayFilterValue,
  loading,
  ratingsFilter,
  responses,
  totalResponseCount,
}: SafetyScaleProps) => {
  const theme = useContext(ThemeContext);
  const commonChartProps = useCommonChartProps();
  const data = Object.keys(responses).map((k, i) => {
    const responseCountArr = responses[k].map((r) => r.responseCount);
    const value = responseCountArr.length
      ? responseCountArr.reduce((acc, cur) => acc + cur)
      : 0;
    return {
      id: k,
      value,
      label: k,
      totalResponseCount,
    };
  });
  data.reverse();
  const labelFormat = (d) =>
    `${d} (${((d / totalResponseCount) * 100).toFixed(1)}%)`;
  const alteredCommonProps: Omit<
    BarSvgProps<any>,
    "data" | "width" | "height"
  > = {
    ...commonChartProps,
    colorBy: "indexValue",
    labelFormat,
  };
  const renderTick: (props: AxisTickProps<number>) => JSX.Element = ({
    opacity,
    textAnchor,
    textX,
    textY,
    value,
    x,
    y,
  }) => {
    const val = value.toString();
    const textStyle: CSSProperties = {
      fontSize: "12px",
      fill: theme.colors.primary,
      textDecoration: "underline",
      textDecorationColor: theme.colors.primary,
    };
    if (!ratingsFilter.includes(val) && ratingsFilter.length) {
      delete textStyle.fill;
      delete textStyle.textDecoration;
      delete textStyle.textDecorationColor;
    }
    return (
      <g transform={`translate(${x},${y})`} style={{ opacity }}>
        <text
          style={textStyle}
          textAnchor={textAnchor}
          transform={`translate(${textX},${textY})`}
        >
          {getTspanGroups(val, 12, 2)}
        </text>
      </g>
    );
  };
  return (
    <Loader loading={loading} overlay>
      <div className={styles.widgetContainer}>
        <ChartHeader
          totalResponseCount={totalResponseCount}
          dayFilterValue={dayFilterValue}
        />
        <div className={styles.chartContainer}>
          <ResponsiveBar
            {...alteredCommonProps}
            data={data}
            enableGridY={false}
            enableLabel={true}
            borderWidth={1}
            margin={{ bottom: 100, left: 0, right: 0, top: 15 }}
            padding={0.3}
            barComponent={CustomBarElement}
            layout="vertical"
            indexBy="label"
            axisBottom={{
              tickSize: 0,
              tickPadding: 25,
              tickRotation: 0,
              renderTick,
            }}
          />
        </div>
      </div>
    </Loader>
  );
};

export default SafetyScale;
