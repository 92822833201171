import { API, DefenseDTO, useAPI } from "@rtslabs/field1st-fe-common";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";

interface DefensesState {
  defenses: DefenseDTO[];
  isFinalPage: boolean;
  isLoading: boolean;
  loadMoreDefenses(): void;
}

export function useDefenses(): DefensesState {
  const [defenses, setDefenses] = useState<DefenseDTO[]>([]);
  const [defensesPage, setDefensesPage] = useState<number>(0);

  const defensesCall = useAPI(API.getDefenses, {
    page: defensesPage,
    sort: "title",
  });

  function loadMoreDefenses(): void {
    if (defensesCall.data && !defensesCall.data.last)
      setDefensesPage(defensesPage + 1);
  }

  useEffect(() => {
    if (defensesCall.data) {
      setDefenses(
        defensesCall.data.first
          ? defensesCall.data.content
          : (g) => uniqBy([...g, ...defensesCall.data!.content], "id")
      );
    }
  }, [defensesCall.data, setDefenses]);

  return {
    defenses,
    isFinalPage: defensesCall.data?.last ?? false,
    isLoading: defensesCall.isLoading,
    loadMoreDefenses,
  };
}
