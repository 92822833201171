import {
  API,
  ImageMimeType,
  StoredObjectDTO,
} from "@rtslabs/field1st-fe-common";

export class AttachmentUploader {
  protected xhr: XMLHttpRequest;
  protected _attachment: File;
  protected _attachmentBlob?: Blob;
  protected _documentId: number;
  protected onUploadProgress: XMLHttpRequest["upload"]["onprogress"];
  protected appPath?: string;

  constructor(
    attachment: File,
    documentId: number,
    onUploadProgress: XMLHttpRequest["upload"]["onprogress"],
    appPath?: string
  ) {
    this.xhr = new XMLHttpRequest();
    this._attachment = attachment;
    this._documentId = documentId;
    this.onUploadProgress = onUploadProgress;
    this.appPath = appPath;
  }

  public abort() {
    this.xhr.abort();
  }

  public retry() {
    this.startUpload();
  }

  public async startUpload(): Promise<StoredObjectDTO> {
    return new Promise((resolve, reject) => {
      API.getWritableAttachmentUrl({
        documentId: this._documentId,
        appPath: this.appPath,
      })
        .then((URLs: StoredObjectDTO) => {
          this.doUpload(URLs)
            .then(() => resolve(URLs))
            .catch((reason: string) => reject(reason));
        })
        .catch(() => reject("Unable to get AWS Signed URL"));
    });
  }

  protected async doUpload(URLs: StoredObjectDTO): Promise<void> {
    return new Promise((resolve, reject) => {
      this.xhr.upload.onload = () => resolve();
      this.xhr.upload.onerror = () => {
        reject("Upload Failed");
      };
      this.xhr.upload.onabort = () => {
        reject("Upload Cancelled");
      };
      this.xhr.upload.onprogress = this.onUploadProgress;

      this.xhr.open("PUT", URLs.writableUrl!, true);
      this.xhr.setRequestHeader("Content-Type", this._attachment.type);
      this.xhr.send(this._attachmentBlob ?? this._attachment);
    });
  }
}

export class ImageUploader extends AttachmentUploader {
  public validateFileType(validFileTypes: ImageMimeType[]): boolean {
    return validFileTypes.includes(this._attachment.type as ImageMimeType);
  }

  constructor(
    attachment: File,
    documentId: number,
    onUploadProgress: XMLHttpRequest["upload"]["onprogress"],
    appPath?: string
  ) {
    super(attachment, documentId, onUploadProgress, appPath);
  }

  public async startUpload(): Promise<StoredObjectDTO> {
    if (!this.validateFileType([ImageMimeType.jpg, ImageMimeType.png]))
      return Promise.reject(`Invalid File Type`);
    return super.startUpload();
  }
}

export async function uploadSignature(
  writableUrl: string,
  signatureData: string
): Promise<void> {
  const binary = atob(signatureData.split(",")[1]);
  const dataArr: number[] = [];
  for (let i = 0; i < binary.length; i++) {
    dataArr.push(binary.charCodeAt(i));
  }
  const img = new Blob([new Uint8Array(dataArr)], { type: "image/png" });

  await fetch(writableUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "image/png",
    },
    body: img,
  });
}
