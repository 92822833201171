import { API } from "@rtslabs/field1st-fe-common";
import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import Label from "../../../../../../Label/Label";
import {
  RadioButtonOption,
  RadioButtonsGroup,
} from "../../../../../../RadioButtons/RadioButtonsGroup";
import { useDefenses } from "../../../../../../../api/formBuilder/useDefenses";
import AddDefense from "../../customFields/AddDefense/AddDefense";
import ps from "../../styles.module.scss";
import {
  CustomAnswerOptionsDrawerState,
  DefenseType,
} from "./customAnswerOptionsFormUtils";

interface Props {
  initialDefenseId?: number;
}

const AddDefenseSection: React.FC<Props> = ({ initialDefenseId }) => {
  const { values, setFieldValue } =
    useFormikContext<CustomAnswerOptionsDrawerState>();
  const { defenses, isFinalPage, isLoading, loadMoreDefenses } = useDefenses();

  async function getDefense(id: number): Promise<void> {
    try {
      const res = await API.getDefense({ id });
      setFieldValue("previousDefense", res);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (initialDefenseId) {
      getDefense(initialDefenseId);
    }
  }, [initialDefenseId]);

  const defenseTypeField = useField("selectedDefenseType");

  function handleSelectionChange(newDefenseType: DefenseType) {
    setFieldValue("selectedDefenseType", newDefenseType);
  }

  return (
    <div className={ps.drawerSection}>
      <Label className={ps.label} htmlFor="defenseType">
        Add a Defense (optional)
      </Label>
      <p className={ps.help}>
        If you choose to add a defense, it will display in the Defenses section
        when its related custom choice is selected. Choose a defense from the
        global library or create a custom defense.
      </p>
      <RadioButtonsGroup
        {...defenseTypeField}
        name="selectedDefenseType"
        options={addDefenseOptions}
        onChange={handleSelectionChange}
        value={values.selectedDefenseType}
      />
      <AddDefense
        defensesLoading={isLoading}
        defenseType={values.selectedDefenseType}
        globalDefenses={defenses}
        isFinalPage={isFinalPage}
        onLoadMore={loadMoreDefenses}
      />
    </div>
  );
};

const addDefenseOptions: RadioButtonOption<DefenseType>[] = [
  {
    data: DefenseType.Global,
    label: { text: "Choose from Global Defenses Library" },
  },
  {
    data: DefenseType.Custom,
    label: { text: "Create a Custom Defense" },
  },
  {
    data: DefenseType.None,
    label: { text: "No Defense" },
  },
];

export default AddDefenseSection;
