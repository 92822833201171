import React, { FC, useEffect, useMemo, useState } from "react";
import { get, orderBy } from "lodash";

import {
  API,
  DocumentHistoryVm,
  DocumentVm,
  FormVersionDTO,
  FormVersionResponse,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import Drawer from "../../common/Drawer";
import Header from "shared/src/components/common/Drawer/components/Header";
import Loader from "shared/src/components/Loader/Loader";

import Responses from "./drawer/ResponseList";
import ActionBar from "./drawer/ActionBar";
import SubmissionStatus from "./drawer/SubmissionStatus";
import { ReadOnlyDefenses } from "shared/src/components/Document/ReadOnly/ReadOnlyDefenses";
import { ReadOnlySignatures } from "shared/src/components/Document/ReadOnly/ReadOnlySignatures";

import ShareForm from "shared/src/components/ShareForm/ShareForm";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { SidebarDrawerBody, SidebarDrawerHeader } from "./drawer/styles";
import DocumentHistory from "../../Documents/components/DocumentSidebarDrawer/DocumentHistory/DocumentHistory";
import styles from "./DocumentDrawer.module.scss";

interface DocumentDrawerProps {
  closeDrawer: () => void;
  documentId: number;
  onClose: () => void;
  show: "document" | "history" | "share";
}

export enum DocumentDrawerSection {
  Document = "document",
  History = "history",
  Share = "share",
}

export const DocumentDrawer: FC<DocumentDrawerProps> = ({
  closeDrawer,
  documentId,
  onClose,
  show,
}) => {
  // Show View Document, Document History or Share Document
  const [showing, setShowing] = useState<"document" | "history" | "share">(
    "document"
  );
  const [formVersionLoading, setFormVersionLoading] = useState<boolean>(false);
  const [latestFormVersion, setLatestFormVersion] = useState<
    string | number | undefined
  >();

  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();

  useEffect(() => {
    setShowing(show);
  }, [show]);

  const documentCall = useAPI(API.getDocumentById, { id: documentId });

  const responseIds = useMemo(
    () =>
      get(documentCall, "data.responses", [] as DocumentVm["responses"]).map(
        (x) => x.associatedId
      ),
    [documentCall]
  );

  function fetchDocument(docId: number): Promise<DocumentHistoryVm[]> {
    return API.getDocumentHistory({ documentId });
  }

  // Fetch version history for current form
  const fetchVersionHistory = async (formId: number) => {
    setFormVersionLoading(true);
    try {
      const response = await API.getLatestFinalFormVersion({ formId });
      setLatestFormVersion(response?.version || response?.formId);
    } catch (error) {
      console.error(error);
      // setError(error); // todo
    } finally {
      setFormVersionLoading(false);
    }
  };

  const handleShareDocument = async (
    participantIds: number[]
  ): Promise<void> => {
    if (documentCall.data) {
      const share: API.ShareDocumentArgs = {
        share: {
          documentId: documentCall.data.id,
          participantIds,
        },
      };
      await API.shareDocument(share);
    }
  };

  // When document changes, fetch its form history
  React.useEffect(() => {
    if (documentCall.data?.formSummary.id) {
      fetchVersionHistory(documentCall.data.formSummary.id);
    }
  }, [documentCall.data?.id]);

  return (
    <Drawer
      showCloseIcon={false}
      content={
        <div style={{ width: 420 }}>
          <Loader loading={documentCall.isLoading}>
            {documentCall.data && (
              <>
                <Header
                  className={styles.header}
                  closeDrawer={closeDrawer}
                  endAdornment={
                    latestFormVersion && (
                      <span className={styles.version}>
                        {latestFormVersion}
                      </span>
                    )
                  }
                  text={`${documentCall.data.formSummary.name}`}
                />
                <ActionBar setShowing={setShowing} />

                {/* View Document content */}
                {showing === DocumentDrawerSection.Document && (
                  <>
                    <SubmissionStatus document={documentCall.data} />
                    <Responses document={documentCall.data} />
                    <ReadOnlyDefenses
                      documentId={documentId}
                      responseIds={responseIds}
                      document={documentCall.data}
                    />
                    <ReadOnlySignatures
                      participants={documentCall.data.participants || []}
                    />
                  </>
                )}

                {/* Document History content */}
                {showing === DocumentDrawerSection.History && (
                  <div className={styles.documentHistory}>
                    <DocumentHistory
                      document={documentCall.data}
                      fetchDocumentHistory={fetchDocument}
                      term={documentTerm}
                    />
                  </div>
                )}

                {/* Share Document */}
                {showing === DocumentDrawerSection.Share && (
                  <div className={styles.shareDocumentWrapper}>
                    <SidebarDrawerHeader>
                      Share {documentTerm}
                    </SidebarDrawerHeader>
                    <SidebarDrawerBody>
                      Do you want to invite a coworker or manager from your
                      company? When sharing, they’ll be allowed to view the{" "}
                      {documentTermLower} and all of your selections.
                    </SidebarDrawerBody>
                    <ShareForm
                      onShareDocument={handleShareDocument}
                      closeDrawer={onClose}
                    />
                  </div>
                )}
              </>
            )}
          </Loader>
        </div>
      }
      anchor="right"
      id="documentDrawer"
      onClose={onClose}
      // onOpen={() => {}}
      open={!!show}
    />
  );
};
