import React, { FC } from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { QAProps } from "../../../qa-slugs";
import styles from "./TableHeader.module.scss";

interface TableHeaderProps extends QAProps {
  children?: React.ReactNode;
  scope?: "col" | "row";
  className?: string;
}

export const TableHeader: FC<TableHeaderProps> = ({
  children,
  qa,
  scope = "col",
  className,
}) => {
  return (
    <th
      className={joinClassNames(styles.tableHeader, className)}
      data-testid={qa}
      scope={scope}
    >
      {children}
    </th>
  );
};
