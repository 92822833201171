import { useState } from "react";

interface FocusEventHandlers {
  onBlur: () => void;
  onMouseDown: () => void;
}

interface UseMouseFocusResult {
  eventHandlers: FocusEventHandlers;
  isMouseFocused: boolean;
}

/** This pattern is intended for buttons. */
export const useMouseFocus = (): UseMouseFocusResult => {
  const [isMouseFocused, setIsMouseFocused] = useState(false);

  const handleBlur = () => {
    setIsMouseFocused(false);
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  return {
    eventHandlers: {
      onBlur: handleBlur,
      onMouseDown: handleMouseDown,
    },
    isMouseFocused,
  };
};

interface FocusAndBlurEventHandlers extends FocusEventHandlers {
  onFocus: () => void;
}

interface UseMouseFocusAndBlurResult extends UseMouseFocusResult {
  eventHandlers: FocusAndBlurEventHandlers;
  isFocused: boolean;
  wasMouseBlurred: boolean;
}

/** This pattern is intended for text fields. */
export const useMouseFocusAndBlur = (): UseMouseFocusAndBlurResult => {
  const [isFocused, setIsFocused] = useState(false);
  const [isMouseFocused, setIsMouseFocused] = useState(false);
  const [wasMouseBlurred, setWasMouseBlurred] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);
    setWasMouseBlurred(isMouseFocused);
    setIsMouseFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setWasMouseBlurred(false);
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  return {
    eventHandlers: {
      onBlur: handleBlur,
      onFocus: handleFocus,
      onMouseDown: handleMouseDown,
    },
    isFocused,
    isMouseFocused,
    wasMouseBlurred,
  };
};
