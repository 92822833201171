import React, { FC } from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { Components, QAProps } from "../../qa-slugs";

interface DocumentsBreadcrumbProps extends QAProps {}

export const DocumentsBreadcrumb: FC<DocumentsBreadcrumbProps> = ({
  qa = `Documents-${Components.Breadcrumbs}`,
}) => {
  return (
    <div data-testid={qa}>
      <Breadcrumbs
        paths={[
          {
            pathName: "Dashboard",
            href: "/",
          },
          {
            pathName: "Documents",
            href: "/documents",
          },
        ]}
      />
    </div>
  );
};
