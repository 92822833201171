import React from "react";
import { css } from "aphrodite/no-important";

import Label from "../../../Label/Label";

import styles from "./styles";
import { TextInputProps } from "../../../TextInput/types";
import { useMouseFocus } from "../../../../util/hooks/useMouseFocus";
import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import { AssistiveText } from "../../../AssistiveText/AssistiveText";

interface Props extends TextInputProps {
  question?: QuestionDTO;
  type: "date" | "datetime-local" | "time";
}

/** Date picker input element */
export function DateTime({
  required,
  error,
  assistiveText,
  label,
  name,
  value,
  type,
  question,
}: Props) {
  const _required = question?.formProperties?.isRequired || required;
  const { eventHandlers, isMouseFocused } = useMouseFocus();
  const s = styles(isMouseFocused);

  return (
    <div className={css(s.wrapper)}>
      {label && (
        <Label htmlFor={name || ""} required={_required}>
          {label}
        </Label>
      )}
      <input
        className={css(s.input)}
        type={type}
        name={name}
        value={value}
        {...eventHandlers}
      />
      {assistiveText && (
        <AssistiveText>{question?.properties?.assistiveText}</AssistiveText>
      )}
      {error && <span className={css(s.errorText)}>{error}</span>}
    </div>
  );
}
