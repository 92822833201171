import React from "react";

import {
  Pagination as PaginationType,
  PaginationParams,
} from "@rtslabs/field1st-fe-common";
import BackToTop from "../../TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "../../TableUI/Pagination/Pagination";
import { RowsPerPage } from "../../TableUI/RowsPerPage/RowsPerPage";
import { Components, ElementType } from "../../../qa-slugs";

type ResourceTablePagination = {
  params: PaginationParams;
  paginationValues?: Omit<PaginationType, "content">;
  onUpdateParams: (param: Partial<PaginationParams>) => void;
};

const ResourceTablePagination = ({
  params,
  paginationValues,
  onUpdateParams,
}: ResourceTablePagination) => {
  return (
    <PaginationWrapper>
      <RowsPerPage
        pageSize={params.size}
        onClick={(size) => onUpdateParams({ size })}
        qaBase={`${Components.ResourceTablePagination}-${ElementType.SelectInput}`}
      />
      <Pagination
        qaBase={`${Components.ResourceTablePagination}-${ElementType.Link}`}
        currentPage={params.page}
        totalPages={paginationValues?.totalPages || 0}
        onClick={(page) => onUpdateParams({ page })}
      />
      <BackToTop
        qa={`${Components.ResourceTablePagination}-${ElementType.Link}-backToTop`}
      />
    </PaginationWrapper>
  );
};

export default ResourceTablePagination;
