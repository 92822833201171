import { FormikErrors, useFormikContext } from "formik";
import { orderBy } from "lodash";
import React from "react";

import {
  API,
  DocumentQuestionResponseVm,
  DocumentStatus,
  DocumentVm,
  LatLng,
  MapWidgetDTO,
  MapWidgetQuestionDTO,
  PageOfDataSourceValueDTO,
  QuestionDTO,
  ResponseContent,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import {
  createOrUpdateResponse,
  deleteResponse,
} from "shared/src/api/responseRequests";
import { GlMap } from "shared/src/components/GlMap/GlMap";
import { Viewport } from "shared/src/components/GlMap/types";
import {
  calculateZoom,
  formatGeolocation,
  getDistanceBetweenGeoLocations,
  getMidPoint,
} from "shared/src/util/geolocation";
import { LocationField } from "shared/src/components/Document/DocumentForm/LocationField/LocationField";
import styles from "./ClassicMapWidget.module.scss";
import { useAppSelector } from "../../store/hooks";
import { selectIsPreview } from "shared/src/redux/document/documentSelectors";
import { ClassicLocationMarker } from "shared/src/components/Document/DocumentForm/LocationField/geo";

function sortQuestions(questions: Array<QuestionDTO & MapWidgetQuestionDTO>) {
  return orderBy(questions, (question) => question.answerSource?.type, [
    "desc",
  ]);
}

/**
 * Get a calculated viewport that encompasses any pins currently placed on the map
 * @param locations - geo coordinates of all location responses in the widget
 */
function getViewport(locations: LatLng[]): Viewport {
  const { latitude = 0, longitude = 0 } = getMidPoint(locations);
  return {
    width: "100%",
    height: "100%",
    center: { latitude, longitude },
    zoom: calculateZoom(getDistanceBetweenGeoLocations(locations)),
  };
}

interface Props {
  errors: FormikErrors<DocumentVm>;
  getDataSourceValues: <T>(
    params: API.GetDataSourceValuesArgs
  ) => Promise<PageOfDataSourceValueDTO<T>>;
  item: MapWidgetDTO;
  responses?: Array<DocumentQuestionResponseVm>;
  sectionItems: Array<SectionItem>;
  setQuestionResponse: (
    item: QuestionDTO,
    response?: DocumentQuestionResponseVm,
    content?: ResponseContent | null
  ) => void;
  disableControls?: boolean;
}

/**
 * Map widget with optional map and questions
 */
export function ClassicMapWidget({
  item,
  responses,
  sectionItems,
  setQuestionResponse,
  disableControls,
}: Props) {
  const { values } = useFormikContext<DocumentVm>();
  /** QuestionDTOs for updating responses */
  const questionDTOs: Map<number, QuestionDTO> = new Map();

  const isPreview = useAppSelector(selectIsPreview);
  const shouldUpdateForm =
    isPreview || values.status === DocumentStatus.SUBMITTED;

  /** Responses for each question */
  const mapResponses: Map<number, DocumentQuestionResponseVm> = new Map();

  let viewport: Viewport = {
    width: "100%",
    height: "100%",
    center: {
      latitude: 37.54129,
      longitude: -77.43476,
    },
    zoom: 5,
  };

  /* SectionItem matching a map widget's nested question (used for updating form responses) */
  let fullQuestion: SectionItem | undefined;
  /* response matching a map widget's nested question (used for passing text input value) */
  let mapResponse: DocumentQuestionResponseVm | undefined;

  /** QuestionDTOs with added iconColor for pins */
  const mapQuestions: Array<QuestionDTO & MapWidgetQuestionDTO> = item.questions
    ?.length
    ? /* widget has questions - combine them with matching form questions and sort by answerSource type */
      sortQuestions(
        item.questions.reduce(
          (
            result: Array<QuestionDTO & MapWidgetQuestionDTO>,
            mapWidgetQuestion
          ) => {
            fullQuestion = sectionItems.find(
              (item) =>
                item.type === "QUESTION" &&
                mapWidgetQuestion.questionRootId === item.rootId
            );
            if (fullQuestion && "title" in fullQuestion) {
              // update questionDTOs with the form question
              questionDTOs.set(fullQuestion.rootId, fullQuestion);
              // if a response for this question exists, add it to mapResponses
              mapResponse = responses?.find(
                (res) => fullQuestion?.rootId === res.questionRootId
              );
              if (mapResponse) {
                mapResponses.set(fullQuestion.rootId, mapResponse);
              }

              // add the combined parameters to result array
              result.push({
                ...fullQuestion,
                ...mapWidgetQuestion,
                // favor the widgetQuestion's answer source (set in FB) over fullQuestion
                answerSource:
                  mapWidgetQuestion.answerSource || fullQuestion.answerSource,
                id: fullQuestion.id,
                rootId: fullQuestion.rootId,
                properties: {
                  ...fullQuestion.properties,
                  pinColor: mapWidgetQuestion.iconColor,
                },
              });
            }
            return result;
          },
          []
        )
      )
    : /* widget does not have questions - set to empty array */
      [];

  /** Array of geo coordinates for calculating viewport */
  const locations: LatLng[] = [];

  /** Markers representing location responses */
  let markers: ClassicLocationMarker[] = [];

  if (item.includeMap) {
    let response: DocumentQuestionResponseVm | undefined;

    /* Map marker configuration */
    markers = mapQuestions.reduce(
      (result: ClassicLocationMarker[], question) => {
        response = mapResponses.get(question.rootId);

        /* if response found, add it to locations */
        if (response?.associatedLocation) {
          locations.push({
            latitude: response.associatedLocation.latitude,
            longitude: response.associatedLocation.longitude,
          });
        }

        /* create the marker and push to array */
        const marker: ClassicLocationMarker = {
          geolocation: response?.associatedLocation || undefined,
          locationName: response?.answer,
          color: question.iconColor,
          async onUpdate() {
            const questionDTO = questionDTOs.get(question.rootId);
            if (questionDTO) {
              // geolocation is defined (marker was placed on map)
              const markerId = responses?.find(
                (res) => res.questionId === questionDTO.id
              )?.id;
              if (this.geolocation) {
                const updatedResponse = {
                  id: markerId ? markerId : undefined,
                  questionId: questionDTO.id,
                  questionRootId: questionDTO.rootId,
                  answer:
                    this.locationName || formatGeolocation(this.geolocation),
                  timeAnswered: new Date().toISOString(),
                  associatedLocation: this.geolocation,
                };
                const newResponse = shouldUpdateForm
                  ? updatedResponse
                  : await createOrUpdateResponse(values.id, updatedResponse);
                setQuestionResponse(questionDTO, newResponse);
                // geolocation is undefined (marker was removed from  map)
              } else {
                if (!shouldUpdateForm && markerId)
                  await deleteResponse(values.id, markerId);
                setQuestionResponse(questionDTO);
              }
            }
          },
        };
        result.push(marker);

        return result;
      },
      []
    );

    // calculate and set viewport based on marker locations
    if (locations.length > 0) {
      viewport = getViewport(locations);
      // if no markers are placed, attempt to set viewport to user's position
    }
  }

  return (
    <div className={styles.wrapper}>
      {item.includeMap && (
        <div className={styles.map}>
          <GlMap
            initialViewport={viewport}
            markers={markers}
            disableControls={disableControls}
          />
        </div>
      )}
      {mapQuestions.map((widgetQuestion) => (
        <LocationField question={widgetQuestion} key={widgetQuestion.id} />
      ))}
    </div>
  );
}
