import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Tutorial from "../../dashboard/Tutorial";

import styles from "./HelpDesk.module.scss";

import { ElementType, Components, Page } from "shared/src/qa-slugs";
import { getVersionName } from "shared/src/helpers/version";
import { Icon } from "shared/src/components/Icon/Icon";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

export const HelpDesk = () => {
  const [showingTutorial, setShowingTutorial] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <div className={styles.helpDesk}>
      {/* Tutorial */}
      <Tutorial
        open={showingTutorial}
        endTutorial={() => setShowingTutorial(false)}
      />

      {/* Title  */}
      <div
        className={styles.helpDeskTitle}
        data-testid={`${Page.Desktop}-${Components.HelpDesk}`}
      >
        Help Desk
      </div>
      <div className={styles.about}>About Field1st</div>

      {/* Version row */}
      <div className={styles.helpDeskRow}>
        <div className={styles.rowTitle}>Version</div>
        <span className={styles.versionNumber}>{getVersionName()}</span>
      </div>

      {/* Tutorial row */}
      <div
        className={joinClassNames(styles.helpDeskRow, styles.helpDeskMenuItem)}
        onClick={() => setShowingTutorial(true)}
      >
        <div
          className={styles.rowTitle}
          data-testid={`${Components.HelpDesk}-${ElementType.Button}-runTutorialWalkthrough`}
          onClick={() => setShowingTutorial(true)}
        >
          Run Tutorial Walkthrough
        </div>
        <button
          data-testid={`${Components.HelpDesk}-${ElementType.Button}-runTutorialWalkthrough-chevron`}
          onClick={() => setShowingTutorial(true)}
          className={styles.chevronLink}
        >
          <Icon className={styles.chevronLink} type="chevron-right" />
        </button>
      </div>

      {/* Got questions */}
      <div className={styles.gotQuestionsWrapper}>
        <Icon className={styles.gotQuestionsIcon} type="help" />
        <div className={styles.gotQuestionsCTA}>
          <div className={styles.gotQuestionsHeading}>have questions?</div>
          <span
            className={styles.gotQuestionsText}
            data-testid={`${Components.HelpDesk}-${ElementType.Link}-sendFeedback`}
            onClick={() => navigate("/feedback")}
          >
            Send us a message with your feedback
          </span>
        </div>
      </div>
    </div>
  );
};
