import React, {
  PropsWithChildren,
  MouseEventHandler,
  KeyboardEventHandler,
} from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { QAProps } from "../../../qa-slugs";
import styles from "./TableRow.module.scss";

interface TableRowProps extends QAProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  onKeyDown?: KeyboardEventHandler<HTMLTableRowElement>;
}

export const TableRow = ({
  children,
  className,
  onClick,
  onKeyDown,
  qa,
}: PropsWithChildren<TableRowProps>) => {
  return (
    <tr
      data-testid={qa}
      className={joinClassNames(
        styles.tableRow,
        !!onClick && styles.clickable,
        className
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={onClick ? 0 : undefined}
    >
      {children}
    </tr>
  );
};
