import {
  API,
  CoreRedux,
  DocumentParticipantVm,
  DocumentQuestionResponseVm,
  DocumentSubmissionType,
  DocumentVm,
  DocumentVM,
  Functions,
  QuestionDTO,
  ResponseContent,
} from "@rtslabs/field1st-fe-common";
import { FormikProps } from "formik";
import { isEmpty, isEqual } from "lodash";
import React, {
  FC,
  JSXElementConstructor,
  ReactNode,
  RefObject,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import useDebounce from "../../../util/hooks/useDebounce";
import usePrevious from "../../../util/hooks/usePrevious";
import { DocumentForm } from "./DocumentForm";
import { getFormProgress } from "./formEntity.helpers";
import { FormWidgetProps, QuestionsToDisableProps } from "./types";

interface DocumentFormWrapperProps {
  formikProps: FormikProps<DocumentVM>;
  formWidget: JSXElementConstructor<FormWidgetProps>;
  handleAutoSync: (
    values: DocumentVm,
    responsesEqual?: boolean
  ) => Promise<void>;
  setFormProgress: (prog: number) => void;
  documentTerm: string;
  flattenedQuestions: QuestionDTO[];
  itemRefs: Map<number, RefObject<HTMLDivElement>>;
  customFooter?: ReactNode;
  appPath?: string;
  questionsToDisable?: QuestionsToDisableProps[];
  disableSubmit?: boolean;
  disableSave?: boolean;
}

export const DocumentFormWrapper: FC<DocumentFormWrapperProps> = ({
  formikProps,
  formWidget,
  handleAutoSync,
  setFormProgress,
  documentTerm,
  flattenedQuestions,
  itemRefs,
  customFooter,
  appPath,
  questionsToDisable,
  disableSubmit,
  disableSave,
}) => {
  const { values, setValues, submitCount, setSubmitting, dirty } = formikProps;

  const clientOverrides = useSelector(CoreRedux.selectClientOverridesConfig);

  useEffect(() => {
    const formProgress = getFormProgress(values.form, values.responses || []);
    setFormProgress(formProgress);
  }, [values.responses, values.form]);

  /** Update document responses via auto-sync submission */
  async function setDocumentResponses(
    question: QuestionDTO,
    questionResponses: DocumentQuestionResponseVm[],
    content?: ResponseContent | null
  ): Promise<void> {
    let updatedResponses = (values.responses || [])
      .filter((res) => res.questionId !== question.id)
      .concat(questionResponses);

    const { displayConditions, sections } = values.form;
    if (
      !isEmpty(displayConditions) &&
      (content || isEmpty(questionResponses))
    ) {
      updatedResponses = Functions.prefillFromQuestionContent({
        question,
        content,
        displayConditions: displayConditions!,
        sections,
        existingResponses: updatedResponses,
        prefillAlternates: clientOverrides?.properties.form.prefillAlternates,
      });
    }

    /* set new participants to existing by default, update them if question responses are participants */
    const updatedParticipants = Functions.generateParticipants(
      values.participants,
      updatedResponses,
      sections
    );

    const newValues: DocumentVM = {
      ...values,
      submissionType: DocumentSubmissionType.AUTO_SYNC,
      participants: updatedParticipants as DocumentParticipantVm[], // todo fix
      responses: updatedResponses,
    };

    /* set updated values, only validating if submission has already been attempted */
    setValues(newValues, submitCount > 0);
  }

  /**** Effects ****/
  // debounced autosave function
  const autoSync = useDebounce({
    async method(values: DocumentVm, responsesEqual?: boolean): Promise<void> {
      setSubmitting(true);
      await handleAutoSync(values, responsesEqual);
      setSubmitting(false);
    },
  });

  const previousAutoSync = usePrevious(autoSync);
  const previousResponses = usePrevious(values.responses) ?? [];
  const previousValues = usePrevious(values);

  function shouldAutoSync(): boolean {
    if (!previousAutoSync) return false;
    if (!previousResponses) return false;
    if (values.submissionType !== DocumentSubmissionType.AUTO_SYNC)
      return false;
    if (!dirty) return false;

    //compare prev and current values, don't autosync if only response ids have changed
    const currentValues = {
      ...values,
      responses: mapResponsesWithoudIds(values.responses),
    };
    const prevValues = {
      ...previousValues,
      responses: mapResponsesWithoudIds(previousResponses),
    };

    return !isEqual(currentValues, prevValues);
  }

  useEffect(() => {
    if (shouldAutoSync()) {
      const responsesEqual = isEqual(
        mapResponsesWithoudIds(values.responses),
        mapResponsesWithoudIds(previousResponses)
      );
      autoSync(values, responsesEqual);
    }
  }, [values]);

  return (
    <DocumentForm
      formWidget={formWidget}
      documentTerm={documentTerm}
      flattenedQuestions={flattenedQuestions}
      formikProps={formikProps}
      getDataSourceValues={API.getDataSourceValues}
      itemRefs={itemRefs}
      setDocumentResponses={setDocumentResponses}
      customFooter={customFooter}
      appPath={appPath}
      questionsToDisable={questionsToDisable}
      disableSubmit={disableSubmit}
      disableSave={disableSave}
    />
  );
};

const mapResponsesWithoudIds = (responses: DocumentQuestionResponseVm[]) =>
  responses.map((r) => ({
    ...r,
    id: undefined,
  }));
