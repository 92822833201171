import React, { FC } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./List.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export const List: FC<Props> = ({ children, className }) => {
  return <ul className={joinClassNames(styles.list, className)}>{children}</ul>;
};
