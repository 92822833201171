import { DataSource, DataSourceStatus } from "@rtslabs/field1st-fe-common";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import { Button, TertiaryButton } from "shared/src/components/Button/Button";
import { Icon } from "shared/src/components/Icon/Icon";
import { Alert } from "shared/src/components/Alert/Alert";

import { Components, ElementType } from "shared/src/qa-slugs";
import Modal from "shared/src/components/common/Modal";
import { DSTypeOption } from "./DSTypeOption";
import s from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  closeModal: () => void;
}

interface CreateProps extends Props {
  typeSelected: "uploaded" | "api" | "basic" | null;
  setTypeSelected: (ts: "uploaded" | "api" | "basic" | null) => void;
}

export const CreateModal = ({
  open,
  closeModal,
  typeSelected,
  setTypeSelected,
}: CreateProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const qa = `${Components.DataSets}-createModal`;

  return (
    <Modal
      border="none"
      height="auto"
      width="674px"
      padding="24px 36px"
      open={open}
      handleClose={closeModal}
      qa={`${qa}-${ElementType.Modal}`}
      content={
        <>
          <div className={s.modalHeader}>
            <h2 className={s.modalHeading}>Select the Type of Data Set</h2>
            <ActionButton
              IconComponent={
                <Icon color="#000000" size={22} type="delete_sign" />
              }
              label={t("common:modal.closeButtonLabel")}
              onClick={closeModal}
              qa={`${qa}-${ElementType.Button}-close`}
            />
          </div>
          <div className={s.modalText}>
            Choose the type of data set you want to create.
          </div>
          <div
            aria-label="Dataset Type"
            className={s.toggleContainer}
            role="group"
          >
            <DSTypeOption
              icon="justify_left"
              label="Basic"
              onSelect={setTypeSelected}
              qa={`${qa}-${ElementType.Button}-basic`}
              selectedType={typeSelected}
              type="basic"
            />
            <DSTypeOption
              icon="import_csv"
              label="Upload"
              onSelect={setTypeSelected}
              qa={`${qa}-${ElementType.Button}-upload`}
              selectedType={typeSelected}
              type="uploaded"
            />
            <DSTypeOption
              icon="api"
              label="API"
              onSelect={setTypeSelected}
              qa={`${qa}-${ElementType.Button}-api`}
              selectedType={typeSelected}
              type="api"
            />
          </div>
          <div className={s.buttonContainer}>
            <TertiaryButton
              className={s.cancelButton}
              onClick={closeModal}
              qa={`${qa}-${ElementType.Button}-cancel`}
            >
              CANCEL
            </TertiaryButton>
            <Button
              disabled={typeSelected == null}
              onClick={() => navigate(`/forms/data-sets/${typeSelected}`)}
              qa={`${qa}-${ElementType.Button}-create`}
            >
              CREATE DATA SET
            </Button>
          </div>
        </>
      }
    />
  );
};

interface ConfirmationProps extends Props {
  loading: boolean;
  updateSourceStatus: (
    ds: DataSource,
    status: DataSourceStatus.UNPUBLISHED | DataSourceStatus.PUBLISHED
  ) => void;
  sourceSelected: DataSource | null;
}

export const ConfirmModal = ({
  loading,
  open,
  closeModal,
  updateSourceStatus,
  sourceSelected,
}: ConfirmationProps) => {
  const qa = `${Components.DataSets}-confirmModal`;
  const isPublishing =
    sourceSelected && sourceSelected.status === DataSourceStatus.UNPUBLISHED;
  return (
    <Modal
      border="none"
      height="auto"
      width="674px"
      padding="24px 36px"
      open={open}
      handleClose={closeModal}
      qa={`${qa}-${ElementType.Modal}`}
      content={
        <>
          <div className={s.modalHeader}>
            <h2 className={s.modalHeading}>
              {`${isPublishing ? "Publish" : "Unpublish"} Data Set`}
            </h2>
            <ActionButton
              IconComponent={
                <Icon color="#000000" size={22} type="delete_sign" />
              }
              label="Close"
              onClick={closeModal}
              qa={`${qa}-${ElementType.Button}-close`}
            />
          </div>
          <Alert
            className={s.modalAlert}
            title={`Do you wish to ${
              isPublishing ? "publish" : "unpublish"
            } the “${sourceSelected?.title}” data set?`}
            variant="error"
          />
          <div className={s.buttonContainer}>
            <TertiaryButton
              className={s.cancelButton}
              onClick={closeModal}
              qa={`${qa}-${ElementType.Button}-cancel`}
            >
              CANCEL
            </TertiaryButton>
            <Button
              type="submit"
              loading={loading}
              onClick={() => {
                sourceSelected &&
                  updateSourceStatus(
                    sourceSelected,
                    isPublishing
                      ? DataSourceStatus.PUBLISHED
                      : DataSourceStatus.UNPUBLISHED
                  );
              }}
              qa={`${qa}-${ElementType.Button}-publish`}
            >
              {isPublishing ? "Publish" : "Unpublish"}
            </Button>
          </div>
        </>
      }
    />
  );
};
