import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Label from "../../../../../Label/Label";
import TextInputWithSuggestions, {
  TextInputSuggestion,
} from "../../../../../TextInput/TextInputWithSuggestions";

import ps from "../styles.module.scss";
import {
  FBItem,
  Property as PropertyType,
} from "../../../../../clientAdmin/types";
import Property from "../Property";
import Loader from "../../../../../Loader/Loader";
import { BaseRootState } from "../../../../../../redux/reduxTypes";
import {
  API,
  useDebouncedValue,
  QuestionAnswerSourceDTO,
  DataSource,
} from "@rtslabs/field1st-fe-common";
import { Select, SelectOption } from "../../../../../Select/Select";
import { ItemParams } from "../../Create";

function mapSourceColumns(dataSource: DataSource): SelectOption<string>[] {
  return (dataSource.csvMappings || []).map((map) => ({
    label: map.columnName,
    value: map.columnName,
  }));
}

interface Props {
  onUpdate: (
    dataSet?: DataSource,
    sourceColumn?: string,
    sortBy?: string
  ) => void;
  disabled?: boolean;
  label?: string;
  assistiveText?: string;
  initialValues?: QuestionAnswerSourceDTO | null;
  detailedSearchProps?: PropertyType[];
  itemPath?: string;
  item: FBItem;
  appWidgetsList: ItemParams[];
}

const DataSetFields = ({
  initialValues,
  onUpdate,
  disabled,
  label,
  assistiveText,
  detailedSearchProps,
  itemPath,
  item,
  appWidgetsList,
}: Props) => {
  const [selectedDataSet, setSelectedDataSet] = useState<
    DataSource | undefined
  >(undefined);
  const [selectedSourceColumn, setSelectedSourceColumn] = useState<
    string | undefined
  >(undefined);
  const [selectedSortBy, setSelectedSortBy] = useState<string | undefined>();
  const [dataSets, setDataSets] = useState<DataSource[]>([]);
  const [dataSetInputValue, setDataSetInputValue] = useState<string>("");
  const [sourceColumns, setSourceColumns] = useState<SelectOption<string>[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const authToken = useSelector(({ auth }: BaseRootState) => auth.token);

  const getSourceColumns = async (
    dataSet: DataSource
  ): Promise<SelectOption<string>[] | undefined> => {
    // TODO error handling -JA
    const id = Number(dataSet.id);
    if (!isNaN(id)) {
      const ds = await API.getDataSourceById({ id });
      return mapSourceColumns(ds);
    }
  };

  const searchDataSets = async (query: string): Promise<void> => {
    const ds = await API.getDataSources({ query });
    if (ds.content) {
      setDataSets(ds.content);
    }
  };

  // fetch the data source on mount to prefill (or clear) the fields
  // @TODO how can we make this not fetch every time
  useEffect(() => {
    if (
      initialValues?.dataSourceKey !== selectedDataSet?.dataSourceKey &&
      authToken
    ) {
      (async () => {
        if (initialValues?.properties?.dataSourceId) {
          try {
            setLoading(true);
            const ds = await API.getDataSourceById({
              id: Number(initialValues.properties.dataSourceId),
            });
            setSelectedDataSet(ds);
            setDataSetInputValue(ds.title || "");
            const sourceColumns = mapSourceColumns(ds);
            setSourceColumns(sourceColumns);
            // @ts-ignore // todo
            setSelectedSourceColumn(initialValues.properties?.answerField);
            setSelectedSortBy(
              // @ts-ignore // todo
              initialValues.properties?.detailedSearch?.infiniteListSortBy
            );
          } finally {
            setLoading(false);
          }
        }
      })();
    }
  }, [initialValues]);

  function clearDataSetValues() {
    setSelectedDataSet(undefined);
    setSelectedSourceColumn(undefined);
    setSelectedSortBy(undefined);
    onUpdate(undefined, undefined, undefined);
  }

  const debouncedSearchQuery = useDebouncedValue(dataSetInputValue, 600);

  useEffect(() => {
    if (debouncedSearchQuery.length < 3) {
      if (dataSets.length) {
        setDataSets([]);
      }
    } else if (!selectedDataSet) {
      searchDataSets(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery]);

  async function onSelectSuggestion(dataSet: TextInputSuggestion) {
    setSourceColumns([]);
    setDataSets([]);

    setSelectedDataSet(dataSet as DataSource);
    setDataSetInputValue(dataSet.title);

    // get the selected data source's column mappings
    const dataSourceColumns = await getSourceColumns(dataSet as DataSource);

    let sourceColumn: string | undefined;
    let sortBy: string | undefined;

    if (dataSourceColumns?.length) {
      // if the data source has column mappings, display the source column dropdown
      setSourceColumns(dataSourceColumns);
    } else {
      // if it doesn't, it's a simple data source and we can default the sourceColumn to "value"
      sourceColumn = "value";
      sortBy = "displayOrder,value";
    }
    setSelectedSourceColumn(sourceColumn);
    onUpdate(dataSet as DataSource, sourceColumn, sortBy);
  }

  const showProperties =
    selectedDataSet && sourceColumns.length > 0 && itemPath;

  return (
    <>
      {label && (
        <Label className={ps.label} htmlFor="answerSource">
          {label}
        </Label>
      )}
      <Loader loading={loading}>
        {/* Search data sets */}
        <TextInputWithSuggestions
          autoComplete="off"
          className={ps.textInputWithSuggestions}
          disabled={disabled}
          suggestions={dataSets}
          showSuggestions={dataSetInputValue.length >= 3}
          name="dataSet"
          label="Data set"
          assistiveText={assistiveText}
          placeholder="Search data sets by ID or source key"
          onInputChange={(value) => {
            if (selectedDataSet) clearDataSetValues();
            setDataSetInputValue(value);
          }}
          value={dataSetInputValue}
          onSelectSuggestion={onSelectSuggestion}
          labelField="title"
          idField="id"
        />
        {/* Source Column */}
        {selectedDataSet && sourceColumns.length ? (
          <Select
            disabled={disabled}
            options={sourceColumns}
            name="sourceColumn"
            label="Source Column in Data Set"
            onChange={(option) => {
              setSelectedSourceColumn(option?.value);
              onUpdate(selectedDataSet, option?.value, selectedSortBy);
            }}
            value={selectedSourceColumn}
          />
        ) : null}
        {/* if a data source is selected, show options to configure the data source drawer */}
        {showProperties &&
          detailedSearchProps?.map((dsp) => {
            if (dsp.name.includes("SortBy")) {
              return (
                <Select
                  disabled={disabled}
                  options={sourceColumns}
                  name="sortBy"
                  label="Sort by"
                  onChange={(option) => {
                    setSelectedSortBy(option?.value);
                    onUpdate(
                      selectedDataSet,
                      selectedSourceColumn,
                      option?.value
                    );
                  }}
                  value={selectedSortBy}
                />
              );
            }
            return (
              <Property
                itemPath={itemPath}
                property={dsp}
                item={item}
                appWidgetsList={appWidgetsList}
              />
            );
          })}
      </Loader>
    </>
  );
};

export default DataSetFields;
