import {
  FormVersionDTO,
  FormVersionSaveDTO,
  FormWorkflowType,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { JSXElementConstructor, memo, useRef, useState } from "react";
import { Icon } from "../../../Icon/Icon";
import Loader from "../../../Loader/Loader";
import { TabPanel } from "../../../Tabs/TabPanel/TabPanel";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { ElementType } from "../../../../qa-slugs";
import { useMappedRefs } from "../../../../util/hooks/useMappedRefs";
import { useSelectTab } from "../hooks/useSelectTab";
import formBuilderStyles from "../styles.module.scss";
import { FBForm } from "../../types";
import styles from "./FormBuilderHistory.module.scss";
import HistoryPreview from "./preview/HistoryPreview";
import { useHistoryPreview } from "./preview/useHistoryPreview";
import HistorySidebar from "./sidebar/HistorySidebar";
import HistorySortDropdown, {
  HistorySortDropdownProps,
} from "./sortDropdown/HistorySortDropdown";
import { loadPreviousFormAsDraft } from "./loadPreviousFormAsDraft";
import { FormWidgetProps } from "../../../Document/DocumentForm/types";

export interface FormBuilderHistoryProps {
  currentFormId: number;
  formVersionRecords: FormVersionDTO[];
  formWorkflowType: FormWorkflowType;
  isLoadingFormVersions: boolean;
  isLoadingMoreFormVersions: boolean;
  sortOption: HistorySortDropdownProps["sortOption"];
  sortDirection: HistorySortDropdownProps["sortDirection"];
  loadMoreFormVersions(): void;
  onSortChange: HistorySortDropdownProps["onSortChange"];
  onSortDirectionChange: HistorySortDropdownProps["onSortDirectionChange"];
  navigateToCreateTab(): void;
  onVersionUpdate(formVersionUpdate: FormVersionSaveDTO): void;
  setError: (error: string) => void;
  widget: JSXElementConstructor<FormWidgetProps>;
  documentTerm: string;
}

const FormBuilderHistory: React.FC<FormBuilderHistoryProps> = ({
  currentFormId,
  formVersionRecords,
  formWorkflowType,
  isLoadingFormVersions,
  isLoadingMoreFormVersions,
  sortOption,
  sortDirection,
  loadMoreFormVersions,
  onSortChange,
  onSortDirectionChange,
  navigateToCreateTab,
  onVersionUpdate,
  setError,
  widget,
  documentTerm,
}) => {
  const { setValues } = useFormikContext<FBForm>();
  const { handleRef, keyToRef } = useMappedRefs<
    HTMLButtonElement,
    number,
    FormVersionDTO
  >(formVersionRecords);

  const { selectedTabIndex, selectTab, handleKeyDown } = useSelectTab(
    formVersionRecords,
    keyToRef
  );

  const [nextTabIndex, setNextTabIndex] = useState<number>(0);

  function preventTabChange() {
    setNextTabIndex(selectedTabIndex);
  }

  function proceedWithTabChange() {
    selectTab(nextTabIndex);
  }

  const publishedFormVersion = formVersionRecords[0]!;
  const publishedIsSelected = selectedTabIndex === 0;

  const scrollViewRef = useRef<HTMLDivElement>(null);

  function handleScroll() {
    if (scrollViewRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollViewRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        loadMoreFormVersions();
      }
    }
  }

  const historyPreviewState = useHistoryPreview(
    formVersionRecords[selectedTabIndex],
    widget,
    documentTerm
  );

  async function handleLoadClick() {
    let doLoad: boolean;
    if (formWorkflowType !== "FINAL") {
      doLoad = window.confirm("Unsaved changes will be discarded. Proceed?");
    } else {
      doLoad = true;
    }

    if (!doLoad) return;

    try {
      await loadPreviousFormAsDraft(
        historyPreviewState.form!,
        currentFormId,
        setValues
      );
      navigateToCreateTab();
    } catch (err) {
      console.error(err);
      setError("Unable to load form version");
    }
  }

  return (
    <div className={formBuilderStyles.contentWrapper}>
      <div className={styles.tabsContainer} role="tablist">
        <span className={styles.title}>History</span>
        <HistorySortDropdown
          sortOption={sortOption}
          sortDirection={sortDirection}
          onSortChange={onSortChange}
          onSortDirectionChange={onSortDirectionChange}
        />
        <button
          key={publishedFormVersion.formId.toString()}
          aria-controls={publishedFormVersion.formId.toString()}
          aria-selected={publishedIsSelected}
          className={joinClassNames(
            styles.tab,
            publishedIsSelected && styles.tabActive,
            styles.tabPublished
          )}
          id={publishedFormVersion.formId.toString()}
          onClick={() => {
            setNextTabIndex(0);
          }}
          onKeyDown={handleKeyDown}
          ref={(instance: HTMLButtonElement | null) => {
            if (instance) {
              handleRef(publishedFormVersion.formId, instance);
            }
          }}
          role="tab"
          tabIndex={publishedIsSelected ? undefined : -1}
          type="button"
          data-testid={`${ElementType.Button}-${publishedFormVersion.version}`}
        >
          <div className={styles.publishedHeader}>
            <div className={styles.starBanner}>
              <Icon type={"star-filled"} />
            </div>
            <span className={styles.publishedLabel}>Published Version</span>
            <br />
          </div>

          <div className={styles.labelBox}>
            {publishedFormVersion.version ? (
              <span className={styles.tabLabel}>
                ID:{publishedFormVersion.formId} &#8226;{" "}
                {publishedFormVersion.version}
              </span>
            ) : (
              <span className={styles.tabLabel}>
                ID:{publishedFormVersion.formId}
              </span>
            )}
            <br />
            <span className={styles.tabSubLabel}>
              Published on:{" "}
              {moment(publishedFormVersion.lastModifiedDate).format(
                "MM/DD/YYYY [at] h:mma"
              )}
            </span>
          </div>
        </button>
        <div
          className={styles.scrollView}
          ref={scrollViewRef}
          onScroll={handleScroll}
        >
          {(!isLoadingFormVersions || isLoadingMoreFormVersions) &&
            formVersionRecords.slice(1).map((formVersion, index) => {
              const isSelected = index === selectedTabIndex - 1;
              const isBeforeSelected = index === selectedTabIndex - 2;

              return (
                <button
                  key={formVersion.formId.toString()}
                  aria-controls={formVersion.formId.toString()}
                  aria-selected={isSelected}
                  className={joinClassNames(
                    styles.tab,
                    isSelected && styles.tabActive,
                    isBeforeSelected && styles.tabBeforeActive
                  )}
                  id={formVersion.formId.toString()}
                  onClick={() => {
                    setNextTabIndex(index + 1);
                  }}
                  onKeyDown={handleKeyDown}
                  ref={(instance: HTMLButtonElement | null) => {
                    if (instance) {
                      handleRef(formVersion.formId, instance);
                    }
                  }}
                  role="tab"
                  tabIndex={isSelected ? undefined : -1}
                  type="button"
                  data-testid={`${ElementType.Button}-${formVersion.version}`}
                >
                  <div className={styles.labelBox}>
                    {formVersion.version ? (
                      <span className={styles.tabLabel}>
                        ID:{formVersion.formId} &#8226; {formVersion.version}
                      </span>
                    ) : (
                      <span className={styles.tabLabel}>
                        ID:{formVersion.formId}
                      </span>
                    )}
                    <br />
                    <span className={styles.tabSubLabel}>
                      Last saved:{" "}
                      {moment(formVersion.lastModifiedDate).format(
                        "MM/DD/YYYY [at] h:mma"
                      )}
                    </span>
                  </div>
                  {isSelected && historyPreviewState.form && (
                    <div
                      className={styles.tabActionContainer}
                      onClick={handleLoadClick}
                    >
                      <div className={styles.tabActionBox}>
                        <Icon type={"undo2"} className={styles.tabActionIcon} />
                      </div>
                      <span className={styles.tabAction}>Load</span>
                    </div>
                  )}
                </button>
              );
            })}
          <Loader
            className={styles.loader}
            loading={isLoadingMoreFormVersions}
          />
        </div>
        <Loader
          className={styles.loader}
          loading={isLoadingFormVersions && !isLoadingMoreFormVersions}
        />
      </div>
      <div className={formBuilderStyles.content}>
        {formVersionRecords[selectedTabIndex] && (
          <TabPanel
            id={formVersionRecords[selectedTabIndex].formId.toString()}
            key={formVersionRecords[selectedTabIndex].formId.toString()}
            tabId={formVersionRecords[selectedTabIndex].formId.toString()}
            className={styles.tabPanel}
          >
            <HistoryPreview {...historyPreviewState} />
          </TabPanel>
        )}
      </div>
      <HistorySidebar
        currentTabIndex={selectedTabIndex}
        nextTabIndex={nextTabIndex}
        formVersion={formVersionRecords[selectedTabIndex]}
        preventTabChange={preventTabChange}
        proceedWithTabChange={proceedWithTabChange}
        onUpdate={onVersionUpdate}
      />
    </div>
  );
};

export default memo(FormBuilderHistory);
