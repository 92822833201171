import { SelectionChip } from "../../SelectionChip/SelectionChip";
import React, { useRef } from "react";

import Array from "../../../util/array";
import styles from "./styles.module.scss";
import { joinClassNames } from "../../../helpers/theme.helpers";

interface SelectionListOption {
  id: number | null;
  label: string;
}

interface HasFocus {
  focus: () => void;
}

interface SelectionListProps {
  className?: string;
  handleChangeSelection: (options: SelectionListOption[]) => void;
  inputElement: HasFocus | null;
  isFocusDisabled?: boolean;
  options: SelectionListOption[];
}

export const SelectionList = ({
  className,
  handleChangeSelection,
  inputElement,
  isFocusDisabled,
  options,
}: SelectionListProps) => {
  const optionsById = useRef<Map<number | null, HTMLLIElement>>(new Map());

  const handleRemove = (option: SelectionListOption) => {
    const foundIndex = Array.findIndexById(options, option.id);
    if (foundIndex > 0) {
      const optionBefore = options[foundIndex - 1];
      const focusOption = optionsById.current.get(optionBefore.id);
      focusOption?.focus();
    } else {
      inputElement?.focus();
    }
    if (foundIndex !== -1) {
      optionsById.current.delete(option.id);
      handleChangeSelection(Array.remove(options, foundIndex));
    } else {
      handleChangeSelection([]);
    }
  };

  return (
    <ul className={joinClassNames(styles.list, className)}>
      {options.map((option) => {
        const handleClick = () => {
          handleRemove(option);
        };

        const handleRef = (instance: HTMLLIElement | null) => {
          if (instance) {
            optionsById.current.set(option.id, instance);
          }
        };

        return (
          <SelectionChip
            className={styles.option}
            handleClick={handleClick}
            isFocusDisabled={isFocusDisabled}
            key={option.id}
            ref={handleRef}
          >
            {option.label}
          </SelectionChip>
        );
      })}
    </ul>
  );
};
