import React from "react";
import BreadcrumbsBase from "@mui/material/Breadcrumbs/";

import { useNavigate, useLocation } from "react-router-dom";

import style from "./Breadcrumbs.module.scss";
import { Components, ElementType } from "../../qa-slugs";
import { Icon } from "../Icon/Icon";

interface Path {
  href?: string;
  pathName: string;
}

interface BreadCrumbsProps {
  paths: Path[];
}

const Breadcrumbs = ({ paths }: BreadCrumbsProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={style.breadcrumbWrapper}>
      <BreadcrumbsBase
        separator={<Icon type="chevron-right" />}
        aria-label="breadcrumb"
      >
        {paths.map(({ href, pathName }, i) => {
          const currentPath = pathname === href;

          if (href && !currentPath) {
            return (
              <span
                className={style.link}
                color="inherit"
                onClick={() => navigate(href)}
                key={i}
                data-testid={`${Components.Breadcrumbs}-${ElementType.Link}-${pathName}`}
              >
                {pathName}
              </span>
            );
          }

          return (
            <div
              key={i}
              className={currentPath ? style.crumbCurrent : style.crumb}
            >
              {pathName}
            </div>
          );
        })}
      </BreadcrumbsBase>
    </div>
  );
};

export default Breadcrumbs;
