import React from "react";

import {
  DataSource,
  QuestionAnswerSourceDTO,
} from "@rtslabs/field1st-fe-common";

import DataSetFields from "./DataSetFields";
import { FBItem, Property } from "../../../../types";
import { ItemParams } from "../../Create";

export function buildAnswerSource(
  dataSet: DataSource,
  sourceColumn: string,
  sortBy: string,
  answerSource?: QuestionAnswerSourceDTO | null
): QuestionAnswerSourceDTO {
  return {
    ...answerSource,
    dataSourceKey: dataSet.dataSourceKey,
    id: null,
    properties: {
      dataSourceId: dataSet.id,
      autoComplete: false,
      detailedSearch: {
        infiniteListSortBy: sortBy,
        ...answerSource?.properties?.detailedSearch,
      },
      answerField: sourceColumn,
    },
    type: "DATA_SOURCE",
  };
}

interface Props {
  label?: string;
  onChange: (val: QuestionAnswerSourceDTO | null) => void;
  placeholder?: string;
  assistiveText?: string;
  disabled?: boolean;
  property: Property;
  itemPath: string;
  item: FBItem;
  initialValues?: QuestionAnswerSourceDTO | null;
  appWidgetsList: ItemParams[];
}

const AnswerSource = ({
  label,
  onChange,
  disabled,
  assistiveText,
  property,
  itemPath,
  item,
  initialValues,
  appWidgetsList,
}: Props) => {
  return (
    <DataSetFields
      label={label}
      disabled={disabled}
      onUpdate={(
        dataSet?: DataSource,
        sourceColumn?: string,
        sortBy?: string
      ) => {
        if (dataSet && sourceColumn && sortBy) {
          const val = buildAnswerSource(
            dataSet,
            sourceColumn,
            sortBy,
            initialValues
          );
          onChange(val);
        } else {
          onChange(null);
        }
      }}
      assistiveText={assistiveText}
      detailedSearchProps={property.detailedSearchProps}
      itemPath={itemPath}
      item={item}
      initialValues={initialValues}
      appWidgetsList={appWidgetsList}
    />
  );
};

export default AnswerSource;
