import {
  API,
  DefenseDocumentDTO,
  DefenseDocumentSaveVm,
  DefenseDTO,
  OperationalExperienceDTO,
  ResourceDTO,
  ResourceType,
  SortParams,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import * as Yup from "yup";

export type ResourceTypes = ResourceType | -1;
export type ResourceValues = Partial<OperationalExperienceDTO> &
  Partial<DefenseDTO> & {
    // extra
    resourceType: ResourceTypes;
    defenseDocuments?: (DefenseDocumentDTO | DefenseDocumentSaveVm)[];
    newDocuments?: File[];
  };

export const resourceSchema = () =>
  Yup.object().shape({
    description: Yup.string()
      .trim()
      .required("Description is required")
      .min(1, "Description is required"),
    title: Yup.string().trim().required("Title is required"),
    resourceType: Yup.mixed().notOneOf([-1], "Please select a type"),
  });

export const getOeTagsByQuery = async (query: string) => {
  try {
    const res = await API.getOETags({ query, archived: false });
    return res.content;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Retrieve the sorting direction from a supplied comma-delimited string or tuple
 *
 * Changed logic to check if `id` exists in sort before returning direction, otherwise
 * a default `asc` direction is returned. Mirrors sort behavior of MUI -- GK
 *
 * @param id
 * @param sort
 */
export const getSortDirection = (
  id: string,
  sort?: string | SortParams | string[]
) => {
  const isActiveSort: boolean = [id].some((el) => sort?.includes(el));
  if (isActiveSort) {
    return sort && sort.includes("asc") ? "asc" : "desc";
  }
  return "asc";
};

/**
 * Map the resourceType enum to the displayable string
 * @param resourceType - the type of the returned resource
 */
type ResourceTerms = {
  operationalExperience: string;
  defense: string;
};
export const getResourceTypeString = (
  resourceType: string,
  terms?: ResourceTerms
) => {
  switch (resourceType) {
    case "OPERATIONAL_EXPERIENCE":
      return terms?.operationalExperience || "OE";
    case "DEFENSE":
      return terms?.defense || "Defense";
    default:
      return "NA";
  }
};

/**
 * Get the title field key based on the resource type
 * @param resource resource for which to get the title key
 */
export const getResourceTitleKey = (resource: ResourceDTO) => {
  switch (resource.resourceType) {
    case "OPERATIONAL_EXPERIENCE":
      return "incident";
    case "DEFENSE":
      return "title";
    default:
      return "title";
  }
};

/**
 * Build a date by subtracting the supplied days from today's date. Returns the start of the day.
 * Passing -1 will return -1.
 *
 * @param days Days before
 */
export const getBeforeDateFilter = (days: number) => {
  switch (days) {
    case -1:
      return -1;
    case 2: // "YESTERDAY" (before 12:00am on this date)
      return moment().startOf("day").utc().format();
    default:
      // all other options will get results up to the end of today
      return moment().endOf("day").utc().format();
  }
};

/**
 * Build a date by adding the supplied days (minus 1 to account for today) from today's date. Returns the start of the day.
 * Passing -1 will return -1.
 *
 * @param days Days after
 */
export const getAfterDateFilter = (days: number) => {
  switch (days) {
    case -1:
      return -1;
    case 2: // "YESTERDAY" (after 12:00am on yesterday's date)
      return moment().subtract(1, "days").startOf("day").utc().format();
    default:
      // all other dates will get results from the beginning of the day, x days ago
      // -1 is to account for including "today"
      return moment()
        .subtract(days - 1, "days")
        .startOf("day")
        .utc()
        .format();
  }
};

/** Get the display string for the resource status */
export const getResourceStatusString = (status: string) => {
  switch (status) {
    case "ACTIVE":
      return "Active";
    case "ARCHIVED":
      return "Archived";
    case "DRAFT":
      return "Draft";
    default:
      return "";
  }
};
