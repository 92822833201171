import React, { useState } from "react";
import { EditorState } from "draft-js";

import { getLinkFromEditorState } from "./helpers";
import * as S from "./styles";
import LinkBuilder from "./LinkBuilder";
import StyleButton from "./StyleButton";
import { Components, ElementType } from "../../../qa-slugs";
import { VirtualElement } from "../Popper/Popper";

type StyleControlsProps = {
  editorState: EditorState;
  onToggle: (style: string) => void;
};

// Controls for the block type, i.e. lists, headers
const BLOCK_TYPES = [
  {
    id: "ol",
    label: <S.Icon name="numbered_list" />,
    style: "ordered-list-item",
  },
  { id: "ul", label: <S.Icon name="list" />, style: "unordered-list-item" },
];

/** Block styling controls (unordered list, ordered list) */
export const BlockStyleControls = ({
  editorState,
  onToggle,
}: StyleControlsProps) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.id}
          active={type.style === blockType}
          label={type.label}
          onToggle={() => onToggle(type.style)}
          qa={`${Components.StyleControls}-${ElementType.Button}-${type.style}`}
        />
      ))}
    </>
  );
};

// Controls for the text styles, i.e. bold, italic
const INLINE_STYLE_TYPES = [
  { label: <S.B>B</S.B>, style: "BOLD" },
  { label: <S.I>I</S.I>, style: "ITALIC" },
  { label: <S.U>U</S.U>, style: "UNDERLINE" },
  { label: <S.Strike>S</S.Strike>, style: "STRIKETHROUGH" },
];

/** Inline styling controls (bold, italic, underline, strikethrough) */
export const InlineStyleControls = ({
  editorState,
  onToggle,
}: StyleControlsProps) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <>
      {INLINE_STYLE_TYPES.map((type) => (
        <StyleButton
          qa={`${Components.StyleControls}-${ElementType.Button}-${type.style}`}
          key={type.style}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={() => onToggle(type.style)}
        />
      ))}
    </>
  );
};

// Controls for indentation of paragraphs and list items
export type IndentDirection = "increase" | "decrease";
type IndentControlsProps = {
  onToggle: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: IndentDirection
  ) => void;
};

const INDENT_TYPES = [
  {
    id: "indent",
    label: <S.Icon name="outdent" />,
    direction: "increase" as IndentDirection,
  },
  {
    id: "outdent",
    label: <S.Icon name="indent" />,
    direction: "decrease" as IndentDirection,
  },
];

/** Indentation controls for paragraphs and list items */
export const IndentControls = ({ onToggle }: IndentControlsProps) => {
  return (
    <>
      {INDENT_TYPES.map((type) => (
        <StyleButton
          key={type.id}
          label={type.label}
          onToggle={(e) => onToggle(e, type.direction)}
          qa={`${Components.StyleControls}-${ElementType.Button}-${type.direction}`}
        />
      ))}
    </>
  );
};

// Controls for links
type LinkControlsProps = {
  editorState: EditorState;
  onAddLink: (link: string) => void;
  onFocusInput?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlurInput?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onRemoveLink: (e: any) => void;
  popperAnchor: VirtualElement;
};

/** Link controls for adding/editing/removing links */
export const LinkControls = ({
  editorState,
  onAddLink,
  onBlurInput,
  onFocusInput,
  onRemoveLink,
  popperAnchor,
}: LinkControlsProps) => {
  const [displayLinkBuilder, setDisplayLinkBuilder] = useState<boolean>(false);
  const initialUrl = getLinkFromEditorState(editorState);

  const handleToggleLink = (e: any) => {
    e.preventDefault();
    setDisplayLinkBuilder(!displayLinkBuilder);
  };

  const handleConfirm = (link: string) => {
    onAddLink(link);
    setDisplayLinkBuilder(false);
  };

  const isSelectionCollapsed = editorState.getSelection().isCollapsed(); // if there's no text selected
  const disableLinkButton =
    (!initialUrl && isSelectionCollapsed) || !popperAnchor;
  const linkButtonIsActive = !!initialUrl || displayLinkBuilder;

  return (
    <>
      <StyleButton
        disabled={disableLinkButton}
        label={<S.Icon name="link" />}
        active={linkButtonIsActive}
        onToggle={handleToggleLink}
        qa={`${Components.StyleControls}-${ElementType.Button}-createLink`}
      />
      <StyleButton
        disabled={!initialUrl}
        label={<S.UnlinkIcon name="delete_link" />}
        onToggle={onRemoveLink}
        qa={`${Components.StyleControls}-${ElementType.Button}-deleteLink`}
      />
      {displayLinkBuilder && (
        <S.Popper
          anchorEl={popperAnchor}
          onClose={() => setDisplayLinkBuilder(false)}
          open={!!popperAnchor}
          placement="bottom-start"
        >
          <LinkBuilder
            onBlur={onBlurInput}
            onFocus={onFocusInput}
            onConfirm={handleConfirm}
            initialValue={initialUrl}
          />
        </S.Popper>
      )}
    </>
  );
};
