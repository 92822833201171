import React, { FC, useId } from "react";
import { Button } from "shared/src/components/Button/Button";
import { Icon } from "shared/src/components/Icon/Icon";
import { Components } from "shared/src/qa-slugs";
import styles from "./AiChatButton.module.scss";
import { useAppSelector } from "../../../store/hooks";
import { CoreRedux } from "@rtslabs/field1st-fe-common";

interface AiChatButtonProps {
  handleClick: () => void;
}

const AiChatButton: FC<AiChatButtonProps> = ({ handleClick }) => {
  const chatbaseConfig = useAppSelector(CoreRedux.selectChatbaseConfigProps);
  const labelId = useId();

  if (!chatbaseConfig) return null;

  return (
    <div className={styles.container}>
      <Button
        ariaLabelledBy={labelId}
        onClick={handleClick}
        qa={`${Components.Home}-${Components.Button}-ai-chat`}
        className={styles.button}
      >
        <Icon type="ai-bot" size={24} />
      </Button>
    </div>
  );
};

export default AiChatButton;
