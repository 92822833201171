import {
  API,
  ClientGroupDTO,
  FormSaveVm,
  FormTypeDTO,
  FormVm,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { FormikHelpers } from "formik";
import React, { JSXElementConstructor, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import FormikFormBuilder from "./FormikFormBuilder";
import { marshallForm, unmarshallForm } from "./helpers";
import { useFormVersion } from "./hooks/useFormVersion";
import { AppWidgetProps, FBForm } from "../types";
import { isFormSubmissionConstraintValid } from "./validationSchema";
import { ItemParams } from "./create/Create";
import { FormWidgetProps } from "../../Document/DocumentForm/types";

function emptyInitialValues(id: number): FBForm {
  return {
    actions: [],
    clientGroups: [],
    defenses: [],
    displayConditions: [],
    documentSummaryEntries: [],
    formSubmissionConstraint: undefined,
    id,
    rootId: 0,
    name: "",
    properties: undefined,
    sections: [],
    sharingEnabled: true,
    signatureRequired: false,
    startFromWorkOrderEnabled: true,
    isRehuddleEligible: false,
    type: {
      active: true,
      iconColor: undefined,
      iconName: undefined,
      id: -1,
      name: "",
      properties: undefined,
      isGlobal: true,
    },
    workflowType: "DRAFT",
  };
}

interface Params extends Record<string, string> {
  id: string;
}

interface FormBuilderProps {
  appWidgets: JSXElementConstructor<AppWidgetProps>;
  appWidgetsList: ItemParams[];
  widget: JSXElementConstructor<FormWidgetProps>;
  documentTerm: string;
}

const FormBuilder: React.FC<FormBuilderProps> = ({
  appWidgets,
  appWidgetsList,
  widget,
  documentTerm,
}) => {
  const initialFormId = Number(useParams<Params>().id);

  const [form, setForm] = useState<FBForm>(emptyInitialValues(initialFormId));
  const [clientGroups, setClientGroups] = useState<ClientGroupDTO[]>([]);
  const [formTypes, setFormTypes] = useState<FormTypeDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const {
    formVersionRecords,
    historySortDirection,
    historySortSelectOption,
    isLoadingFormVersions,
    isLoadingMoreFormVersions,
    setHistorySortDirection,
    setHistorySortOption,
    createOrUpdateFormVersionRecord,
    loadMoreFormVersions,
  } = useFormVersion(form.id);

  async function fetchForm(): Promise<void> {
    const res = await API.getFormBuilderForm({
      formId: initialFormId,
    });
    setForm(unmarshallForm(res));
  }

  async function fetchClientGroups(): Promise<void> {
    const res = await API.getClientGroups({ size: 2000, sort: "name,asc" });
    setClientGroups(res.content);
  }

  async function fetchFormTypes(): Promise<void> {
    setFormTypes(await API.getAllFormTypes());
  }

  /** Fetch the form on load using form ID from URL params */
  useAsyncEffect(async () => {
    setLoading(true);
    await fetchForm();
    await fetchClientGroups();
    await fetchFormTypes();
    setLoading(false);
  }, []);

  /**
   * Save the form
   * @param values form values
   */
  async function handleSubmitForm(values: FBForm): Promise<FormVm | undefined> {
    return updateForm(marshallForm(values));
  }

  async function updateForm(form: FormSaveVm) {
    try {
      const response = await API.updateForm({ form });
      setError("");
      return response;
    } catch (e) {
      if ((e as any).message) {
        setError((e as any).message);
      } else {
        console.error(e);
        setError("Error occurred while submitting form.");
      }
    }
  }

  /**
   * Submit form as PUBLISHED (workflowType: FINAL)
   * Generate a new form version
   * @param values form values
   * @param formikBag
   */
  async function publishForm(
    values: FBForm,
    { setValues }: FormikHelpers<FBForm>
  ): Promise<FBForm | undefined> {
    const response = await handleSubmitForm({
      ...values,
      workflowType: "FINAL",
    });

    if (response) {
      await createOrUpdateFormVersionRecord({
        formId: response.id,
      });
      const fbForm = unmarshallForm(response);
      setValues(fbForm);
      return fbForm;
    }
  }

  /**
   * Save the form as a draft, without validation
   * (except for values.name)
   * @param values form values
   */
  async function saveForm(values: FBForm): Promise<FormVm | undefined> {
    if (
      !values.name ||
      (values.formSubmissionConstraint &&
        !isFormSubmissionConstraintValid(values.formSubmissionConstraint))
    ) {
      return;
    }
    return await handleSubmitForm(values);
  }

  return (
    <Loader loading={loading} overlay>
      <FormikFormBuilder
        form={form}
        formTypes={formTypes}
        formVersionRecords={formVersionRecords}
        historySortSelectOption={historySortSelectOption}
        historySortDirection={historySortDirection}
        isLoadingFormVersions={isLoadingFormVersions}
        isLoadingMoreFormVersions={isLoadingMoreFormVersions}
        handleHistorySortChange={setHistorySortOption}
        handleHistorySortDirectionChange={setHistorySortDirection}
        handleVersionUpdate={createOrUpdateFormVersionRecord}
        loadMoreFormVersions={loadMoreFormVersions}
        clientGroups={clientGroups}
        publishForm={publishForm}
        saveForm={saveForm}
        error={error}
        setError={setError}
        appWidgets={appWidgets}
        appWidgetsList={appWidgetsList}
        widget={widget}
        documentTerm={documentTerm}
      />
    </Loader>
  );
};

export default FormBuilder;
