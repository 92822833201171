import React, { Dispatch, SetStateAction } from "react";

import { Drawer } from "shared/src/components/Drawer/Drawer";
import FormTemplatesDrawerContent from "./components/FormTemplatesDrawerContent";
import { FormSummaryVm, FormTypeDTO } from "@rtslabs/field1st-fe-common";

interface Props {
  isDesktop?: boolean;
  isOpen: boolean;
  onSelectFormTemplate: (template: FormSummaryVm) => void;
  selectedFormType: FormTypeDTO | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
}

const FormTemplatesDrawer = ({
  isOpen,
  setIsOpen,
  isDesktop,
  onSelectFormTemplate,
  selectedFormType,
  title,
}: Props) => {
  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      id="FormTemplatesDrawer"
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <FormTemplatesDrawerContent
        selectedFormType={selectedFormType}
        title={title}
        onClose={() => setIsOpen(false)}
        onSelectFormTemplate={onSelectFormTemplate}
      />
    </Drawer>
  );
};

export default FormTemplatesDrawer;
