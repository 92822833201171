import React from "react";

import s from "../../styles.module.scss";
import { TextButton } from "../../../../common/Button";

export type MissingWidgetType = "DEFENSES" | "OPERATIONAL_EXPERIENCES";

function getWidgetName(widgetType: MissingWidgetType) {
  switch (widgetType) {
    case "OPERATIONAL_EXPERIENCES":
      return "OE";
    case "DEFENSES":
      return "DEFENSES";
    default:
      return "WIDGET";
  }
}

interface Props {
  type: MissingWidgetType;
  onAddMissing: () => void;
}

const MissingWidgetBanner = ({ type, onAddMissing }: Props) => {
  return (
    <div className={s.alert}>
      <p className={s.alertTitle}>REQUIRED WIDGETS</p>
      <p className={s.alertBody}>
        Some of the options added above have related connections that require
        specific sections to display. Would you like to add the corresponding
        widgets to your form?
      </p>
      <TextButton
        className={s.alertButton}
        variant="link"
        onClick={onAddMissing}
      >
        + Add {getWidgetName(type)} Section
      </TextButton>
    </div>
  );
};

export default MissingWidgetBanner;
