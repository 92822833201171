import ModalBase from "@mui/material/Modal";
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from "react";
import { ThemeTypes } from "../../../themes/colors";
import { toRgba } from "../../../themes/helpers";
import { ThemeContext } from "styled-components";
import { QAProps } from "../../../qa-slugs";

interface ModalProps extends QAProps {
  border?: string;
  boxShadow?: string;
  content: any;
  handleClose: () => void;
  height?: string;
  maxHeight?: string;
  mobile?: boolean;
  open: boolean;
  padding?: string;
  width?: string;
}

interface StyleProps {
  border?: string;
  boxShadow?: string;
  height?: string;
  maxHeight?: string;
  mobile?: boolean;
  padding?: string;
  theme: ThemeTypes;
  width?: string;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    left: `${left}%`,
    outline: 0,
    top: `${top}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles({
  paper: (props: StyleProps) => {
    const {
      border,
      boxShadow,
      height,
      maxHeight,
      mobile,
      padding,
      theme,
      width,
    } = props;

    // redesign, new design will have 3px border top with primary color if no border is provided or none
    const hasBorder = border && border !== "none";

    return {
      backgroundColor: theme && theme.colors.white,
      border: border ? border : "",
      borderRadius: "4px",
      borderTopWidth: hasBorder ? "inherit" : "3px",
      borderTopStyle: hasBorder ? "inherit" : "solid",
      borderTopColor: hasBorder ? "inherit" : theme && theme.colors.primary,
      boxShadow: boxShadow
        ? boxShadow
        : mobile
        ? ""
        : theme && `0px 16px 24px ${toRgba(theme.colors.black, 0.2)}`,
      height: mobile ? "100%" : height ? height : "80%",
      maxHeight: mobile ? "100%" : maxHeight ? maxHeight : "auto",
      padding: padding || "0 55px 44px 55px",
      position: "absolute",
      width: mobile ? "100%" : width ? width : "33%",
    };
  },
});

const Modal = (props: ModalProps) => {
  const {
    border,
    boxShadow,
    content,
    handleClose,
    height,
    maxHeight,
    mobile,
    open,
    padding,
    width,
    qa,
  } = props;

  const [modalStyle] = React.useState(getModalStyle);
  const theme = useContext(ThemeContext);

  const classes = useStyles({
    border,
    boxShadow,
    height,
    maxHeight,
    mobile,
    padding,
    theme,
    width,
  });

  return (
    <ModalBase
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      onClose={handleClose}
      open={open}
    >
      <div data-testid={qa} style={modalStyle} className={classes.paper}>
        {content}
      </div>
    </ModalBase>
  );
};

export default Modal;
