import {
  DocumentQuestionResponseVm,
  ResponseContent,
} from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { FieldErrorType } from "../../../../api/form.types";
import { QAProps } from "../../../../qa-slugs";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { SelectionPhotoWrapper } from "../SelectionPhotoWrapper";
import { CheckboxField } from "./CheckboxField";
import styles from "./CheckboxGroup.module.scss";
import { CheckboxLabel } from "./CheckboxLabel";

export interface CheckboxGroupOption {
  id?: number | null;
  rootId?: number | null;
  value: number;
  label: {
    icon?: {
      name: string;
      color: string;
    };
    text: string;
  };
  hideLabel?: boolean;
  comment?: string;
  commentRequired?: boolean;
  commentError?: string;
  content?: ResponseContent | null;
}

export interface CheckboxGroupProps extends QAProps {
  allowPhotos?: boolean;
  children?: React.ReactNode;
  enableComments?: boolean;
  error?: FieldErrorType;
  handleBlur?: () => void;
  onChange: (option: CheckboxGroupOption, checked: boolean) => void;
  selected: CheckboxGroupOption[];
  options: CheckboxGroupOption[];
  onUpdateComment?: (value: string) => void;
  onClearComment?: (response?: DocumentQuestionResponseVm) => void;
  photoButtonPerOption?: boolean;
  questionRootId?: number;
  responses?: DocumentQuestionResponseVm[];
  appPath?: string;
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  options,
  onChange,
  selected,
  enableComments,
  onUpdateComment,
  onClearComment,
  photoButtonPerOption,
  qa,
  questionRootId,
  responses,
  appPath,
}) => {
  return (
    <>
      {options.map((option) => {
        const curSelection = selected.find(
          (sel) =>
            sel.value === option.value ||
            (sel.label.icon === option.label.icon &&
              sel.label.text?.toLowerCase() ===
                option.label.text?.toLowerCase())
        );
        const curResponse = responses?.find(
          (res) => res.id === curSelection?.id
        );
        const checked = !!curSelection;
        const key =
          option.value ||
          (option.label.icon?.name ?? "icon" + option.label.text ?? "text");
        const optionTestId = qa ? `${qa}-${key}` : undefined;
        const child = (
          <div
            className={joinClassNames(
              styles.container,
              !photoButtonPerOption && styles.containerWithoutPhoto
            )}
            key={key}
          >
            <CheckboxField
              checked={checked}
              className={styles.checkbox}
              name={`${option.value}`}
              label={
                <CheckboxLabel
                  icon={option.label.icon}
                  text={(!option.hideLabel && option.label.text) || ""}
                  qa={qa}
                />
              }
              id={option.value}
              onChange={(checked) => onChange(option, checked)}
              commentEnabled={!!enableComments && checked}
              commentValue={option.comment}
              commentRequired={!!option.commentRequired}
              commentError={option.commentError}
              onUpdateComment={onUpdateComment}
              onClearComment={onClearComment}
              qa={optionTestId}
              response={curResponse}
            />
          </div>
        );
        return photoButtonPerOption ? (
          <SelectionPhotoWrapper
            allowPhotos={photoButtonPerOption}
            disabled={!checked}
            key={key}
            qa={optionTestId ? `${optionTestId}-photos` : undefined}
            questionRootId={questionRootId ?? 0}
            questionSelectionId={option.value}
            appPath={appPath}
          >
            {child}
          </SelectionPhotoWrapper>
        ) : (
          child
        );
      })}
    </>
  );
};

export default CheckboxGroup;
