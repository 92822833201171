import React from "react";
import { css } from "aphrodite/no-important";

import { Drawer as CommonDrawer } from "../../../../../common/Drawer/components/Drawer";
import drawerStyles from "./styles";

const DrawerSectionHeader = ({ children }: { children: React.ReactNode }) => {
  const ds = drawerStyles();
  return <h5 className={css(ds.sectionHeader)}>{children}</h5>;
};

const DrawerContent = ({ children }: { children: React.ReactNode }) => {
  const ds = drawerStyles();
  return <div className={css(ds.drawerContent)}>{children}</div>;
};

const DrawerSection = ({ children }: { children: React.ReactNode }) => {
  const ds = drawerStyles();
  return <div className={css(ds.section)}>{children}</div>;
};

const DrawerDescription = ({ children }: { children: React.ReactNode }) => {
  const ds = drawerStyles();
  return <div className={css(ds.description)}>{children}</div>;
};

const DrawerButtonContainer = ({ children }: { children: React.ReactNode }) => {
  const ds = drawerStyles();
  return <div className={css(ds.addButtonContainer)}>{children}</div>;
};

/** @deprecated Use /packages/shared/src/components/Drawer instead. */
const Drawer = (props: any) => {
  return <CommonDrawer {...props} />;
};

Drawer.SectionHeader = DrawerSectionHeader;
Drawer.Content = DrawerContent;
Drawer.Section = DrawerSection;
Drawer.Description = DrawerDescription;
Drawer.ButtonContainer = DrawerButtonContainer;

export default Drawer;
