import React from "react";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { Icon } from "../Icon/Icon";
import styles from "./GlMap.module.scss";

interface Props {
  color?: string;
  disabled: boolean;
  onMouseUp?: () => void;
}

export default function MarkerControl({ color, disabled, onMouseUp }: Props) {
  return (
    <div onMouseUp={onMouseUp} className={styles.circle}>
      <Icon
        type="place_marker"
        color={disabled ? scssVariables.lightGrey : color}
        className={styles.placeMarker}
      />
    </div>
  );
}
