import { SortDirection } from "@rtslabs/field1st-fe-common";
import { SortOrder } from "../components/TableUI/TableHeader/SortableTableHeader";

/** Transforms a table sort key (ie "ascending") to an API sort key (ie "asc") */
export function getSortDirection(
  sortOrder: SortOrder
): SortDirection | undefined {
  switch (sortOrder) {
    case "ascending":
      return "asc";
    case "descending":
      return "desc";
    default:
      return undefined;
  }
}

/** Transforms a API sort key (ie "asc") to a table sort key (ie "ascending") */
export function getSortOrder(sortDirection: SortDirection): SortOrder {
  switch (sortDirection) {
    case "asc":
      return "ascending";
    case "desc":
      return "descending";
    default:
      return "none";
  }
}
