import React from "react";
import styles from "../Checkbox.module.scss";

export const CheckBoxFilledBlank = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      className={styles.checkmark}
    >
      <path className={styles.background} d="M 5,5 H 19 V 19 H 5 Z" />
      <path
        className={styles.fill}
        d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1zm1-16H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      />
    </svg>
  );
};
