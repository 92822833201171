import React from "react";

import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import PrivacyPolicyContent from "./PrivacyPolicyContent";
import { ContentWrapper } from "../Wrappers/Wrappers";
import useGroupTerm from "../../util/hooks/useGroupTerm";

const Privacy = () => {
  const terms = {
    employee: {
      singular: useGroupTerm("employee", "noun", undefined, "Employee"),
      plural: useGroupTerm("employee", "noun", "plural", "Employees"),
    },
  };

  return (
    <ContentWrapper>
      <Breadcrumbs paths={[{ pathName: "Privacy" }]} />
      <PrivacyPolicyContent terms={terms} />
    </ContentWrapper>
  );
};

export default Privacy;
