import React from "react";

import FeedbackForm from "../components/FeedbackForm";
import { ResponsiveContentWrapper } from "shared/src/components/Wrappers/Wrappers";

import styles from "./Feedback.module.scss";
import { Components, Page } from "shared/src/qa-slugs";

const Feedback = () => {
  return (
    <ResponsiveContentWrapper>
      <div
        className={styles.title}
        data-testid={`${Page.Desktop}-${Components.Feedback}`}
      >
        Send Feedback
      </div>
      <div className={styles.subTitle}>
        Your input is important for improving the app. Select a feedback type,
        enter a comment, and our support team will address it.
      </div>

      <FeedbackForm />
    </ResponsiveContentWrapper>
  );
};

export default Feedback;
