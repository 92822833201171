import {
  API,
  ContentDTO,
  CoreRedux,
  DefenseDTO,
  DisplayConditionDTO,
  DocumentParticipantVm,
  DocumentQuestionResponseVm,
  DocumentSubmissionType,
  DocumentVm,
  DocumentVM,
  FormSectionVm,
  Functions,
  PageOfDataSourceValueDTO,
  QuestionDTO,
  ResponseContent,
  SafetyRatingWidgetDTO,
  SectionItem,
  WidgetDTO,
} from "@rtslabs/field1st-fe-common";
import { FormikErrors, FormikProps, useFormikContext } from "formik";
import React, {
  createRef,
  FC,
  JSXElementConstructor,
  RefObject,
  useEffect,
  useMemo,
} from "react";
// import { createMarkup } from "../../../util/createMarkup";
// import Content from "./Content";
import styles from "../DocumentForm/documentForm.module.scss";
import Content from "../DocumentForm/Content";
import {
  FormWidgetProps,
  QuestionsToDisableProps,
} from "../DocumentForm/types";
import { useDocumentSection } from "../../../components/Document/DocumentSection/hooks/useDocumentSection";
import { useSectionalContext } from "../../../components/Document/DocumentSection/hooks/useSectionalContext";
import {
  getFlattenedItems,
  getFlattenedQuestions,
  isWidgetQuestion,
} from "../DocumentForm/formEntity.helpers";
import { Question } from "../DocumentForm/Question";
import { QAProps } from "../../../qa-slugs";
import { QuestionWrapper } from "../DocumentForm/QuestionWrapper";
import { useQuestion } from "../DocumentForm/useQuestion";
import { validate } from "../DocumentForm/validation";
import useErrorScroll from "./hooks/useErrorScroll";
import { isEmpty } from "lodash";
import { useAppSelector } from "../../../redux/reduxHooks";
// import { isWidgetQuestion, shouldShowSafetyScale } from "./formEntity.helpers";
// import { FormSection } from "./FormSection";
// import { Question } from "./Question";
// import { RatingScale } from "./RatingField/RatingScale";
// import { FormWidgetProps, QuestionsToDisableProps } from "./types";

interface SectionPropertiesProps {
  headerDescription?: string;
  headerTitle?: string;
}

const SectionProperties: FC<SectionPropertiesProps> = ({
  headerDescription,
  headerTitle,
}) => (
  <div>
    SectionProperties goes here
    {/* {headerTitle && (
      <div
        className={styles.title}
        dangerouslySetInnerHTML={createMarkup(headerTitle)}
      />
    )}
    {headerDescription && (
      <div
        className={styles.header}
        dangerouslySetInnerHTML={createMarkup(headerDescription)}
      />
    )} */}
  </div>
);

interface SectionProps extends QAProps {
  //   formikProps: FormikProps<DocumentVM>;
  //   responses: DocumentQuestionResponseVm[];
  //   displayConditions?: DisplayConditionDTO[] | null;
  //   defenses?: DefenseDTO[];
  //   flattenedQuestions: QuestionDTO[];
  formWidget: JSXElementConstructor<FormWidgetProps>;
  //   getDataSourceValues: <T>(
  //     params: API.GetDataSourceValuesArgs
  //   ) => Promise<PageOfDataSourceValueDTO<T>>;
  itemRefs: Map<number, RefObject<HTMLDivElement>>;
  //   setDocumentResponses: (
  //     question: QuestionDTO,
  //     responses: DocumentQuestionResponseVm[],
  //     content?: ResponseContent | null
  //   ) => void;
  //   errors: FormikErrors<DocumentVm>;
  appPath?: string;
  //   questionsToDisable?: QuestionsToDisableProps[];
}

export const Section: FC<SectionProps> = ({
  //   formikProps,
  //   responses,
  //   displayConditions,
  //   defenses,
  //   flattenedQuestions,
  formWidget,
  //   getDataSourceValues,
  itemRefs,
  //   setDocumentResponses,
  //   errors,
  appPath,
  //   questionsToDisable,
  qa = "DocSec-section",
}) => {
  const { values, errors, setValues, submitCount } =
    useFormikContext<DocumentVm>();
  const { currentSection } = useSectionalContext();
  useErrorScroll({ itemRefs });
  const clientOverrides = useAppSelector(CoreRedux.selectClientOverridesConfig);
  //   const targetIds = displayConditions?.map((item) => item.targetRootId);

  const { responses } = values;

  const visibleItems = currentSection.items!.filter((item) =>
    Functions.isTargetVisible(
      item.rootId,
      item.type,
      values.responses,
      values.form.displayConditions
    )
  );
  //   console.log("🚀 ~ file: Section.tsx:117 ~ visibleItems:", visibleItems);

  //   /** All safety rating widgets */
  //   const safetyRatingWidgets = visibleItems.filter(
  //     (question) => question.subType === "SAFETY_RATING"
  //   ) as SafetyRatingWidgetDTO[];

  const Widget = formWidget;

  const flattenedQuestions: QuestionDTO[] = getFlattenedQuestions(
    values.form.sections
  );

  /** Update document responses via auto-sync submission */
  async function setDocumentResponses(
    question: QuestionDTO,
    questionResponses: DocumentQuestionResponseVm[],
    content?: ResponseContent | null
  ): Promise<void> {
    let updatedResponses = (values.responses || [])
      .filter((res) => res.questionId !== question.id)
      .concat(questionResponses);

    const { displayConditions, sections } = values.form;
    if (
      !isEmpty(displayConditions) &&
      (content || isEmpty(questionResponses))
    ) {
      updatedResponses = Functions.prefillFromQuestionContent({
        question,
        content,
        displayConditions: displayConditions!,
        sections,
        existingResponses: updatedResponses,
        prefillAlternates: clientOverrides?.properties.form.prefillAlternates,
      });
    }

    /* set new participants to existing by default, update them if question responses are participants */
    const updatedParticipants = Functions.generateParticipants(
      values.participants,
      updatedResponses,
      sections
    );

    const newValues: DocumentVM = {
      ...values,
      submissionType: DocumentSubmissionType.AUTO_SYNC,
      participants: updatedParticipants as DocumentParticipantVm[], // todo fix
      responses: updatedResponses,
    };

    /* set updated values, only validating if submission has already been attempted */
    setValues(newValues, submitCount > 0);
  }

  return (
    <>
      {/* {section.properties && Object.keys(section.properties).length > 0 && (
        <SectionProperties
          headerDescription={section.properties.headerDescription}
          headerTitle={section.properties.headerTitle}
        />
      )} */}
      {/* {section.title && <h3 className={styles.title}>{section.title}</h3>} */}

      {visibleItems.map((item) => {
        // const isDecisionBlock = targetIds?.includes(item.id);
        // const isQuestionDisabled = !!questionsToDisable?.find(
        //   (q) => q.questionId === item.id && q.questionRootId === item.rootId
        // );

        /* Question items */
        if (
          item.type === "QUESTION" &&
          !isWidgetQuestion(item, ["LOCATION", "TEXT_LINE"])
        ) {
          const questionResponses =
            responses.filter(
              (response) => response.questionRootId === item.rootId
            ) || [];
          //   console.log(
          //     "🚀 ~ file: Section.tsx:146 ~ {visibleItems.map ~ questionResponses:",
          //     questionResponses,
          //     item
          //   );
          return (
            <QuestionWrapper
              itemRefs={itemRefs}
              key={item.id}
              item={item}
              qa={qa}
              appPath={appPath}
            />
          );
        }

        /* Widget items */
        if (item.type === "WIDGET") {
          return (
            <div
              key={item.id}
              data-testid={`${qa}-widget`}
              ref={itemRefs.get(item.id)}
              className={styles.mainBlock}
            >
              <Widget
                defenses={values.form.defenses || []}
                flattenedQuestions={flattenedQuestions}
                getDataSourceValues={API.getDataSourceValues}
                item={item as WidgetDTO}
                sectionItems={visibleItems}
                setDocumentResponses={setDocumentResponses}
                responses={responses!}
                // validation error
                errors={errors}
              />
            </div>
          );
        }

        /* Content items */
        if (item.type === "CONTENT") {
          return (
            <div
              key={item.id}
              data-testid={`${qa}-content`}
              className={styles.mainBlock}
            >
              <Content item={item as ContentDTO} />
            </div>
          );
        }
      })}
    </>
  );
};
