import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Menu, { MenuItemIcon, MenuItems } from "../../Menu/Menu";

import styles from "./ProfileMenu.module.scss";

/**
 * Uses a hook to determine which component
 * to render based on the view port
 */
export const ProfileMenu = () => {
  const navigate = useNavigate();

  const menu: MenuItems = useMemo(() => {
    return [
      {
        icon: MenuItemIcon.PENCIL,
        label: "Edit Profile",
        onClick: () => navigate("/profile/edit"),
      },
      {
        icon: MenuItemIcon.PHONE_SETTINGS,
        label: "Settings",
        onClick: () => navigate("/profile/settings"),
      },
    ];
  }, [navigate]);
  return (
    <div className={styles.profileMenu}>
      <Menu menuItems={menu} />
    </div>
  );
};

export default ProfileMenu;
