/**
 * The version name determined by the branch/tag (injected by jenkins)
 * typically one of the following:
 *  - <client>/develop for dev
 *  - release/<client>-web-<version> for qa or uat
 *  - <client>-web-<version> for production
 */
export function getVersionName(): string {
  const targetVersion = process.env.REACT_APP_VERSION;
  if (targetVersion && targetVersion.startsWith("release/")) {
    return targetVersion.substr(8) + " candidate";
  }
  return targetVersion || "UNKNOWN";
}

/**
 * the specific commit hash this was built with (injected by jenkins)
 */
export function getGitCommitHash(): string {
  return process.env.REACT_APP_LATEST_COMMIT || "unknown";
}

/**
 * which environment this was built for (injected by jenkins)
 * typically dev, qa, uat, demo, preprod, or prod
 */
export function getBuildEnvironment(): string {
  return process.env.REACT_APP_CURRENTENV || "local";
}
