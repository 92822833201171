type Id = number | string;

interface HasId {
  id: Id | null;
}

function add<T>(array: T[], value: T, index: number): T[] {
  return array.slice(0, index).concat(value, array.slice(index));
}

function findById<T extends HasId>(array: T[], id: Id): T | undefined {
  return array.find((value) => value.id === id);
}

function findIndexById(array: HasId[], id: Id | null): number {
  return array.findIndex((value) => value.id === id);
}

function findNext<T>(
  items: T[],
  index: number,
  predicate: (item: T, index: number) => boolean
): T | null {
  for (let i = index; i < items.length; i++) {
    const nextItem = items[i];
    if (predicate(nextItem, i)) {
      return nextItem;
    }
  }
  return null;
}

function findPrevious<T>(
  items: T[],
  index: number,
  predicate: (item: T, index: number) => boolean
): T | null {
  for (let i = index; i >= 0; i--) {
    const nextItem = items[i];
    if (predicate(nextItem, i)) {
      return nextItem;
    }
  }
  return null;
}

function push<T>(array: T[] | undefined, value: T): T[] {
  return array ? array.concat(value) : [value];
}

function remove<T>(array: T[], index: number): T[] {
  return array.slice(0, index).concat(array.slice(index + 1));
}

export default {
  add,
  findById,
  findIndexById,
  findNext,
  findPrevious,
  push,
  remove,
};
