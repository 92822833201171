import {
  API,
  ApplicationConfigKey,
  CoreRedux,
  DocumentVm,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { serializeError } from "serialize-error";
import { DocumentHeader } from "shared/src/components/Document/DocumentHeader/DocumentHeader";
import { ReadOnlyDoc } from "shared/src/components/Document/ReadOnly/ReadOnlyDoc";
import Loader from "shared/src/components/Loader/Loader";
import { setIsPreview } from "../../../../shared/src/redux/document/documentActionCreators";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import styles from "./Document.module.scss";
import DocumentSidebarDrawer from "./DocumentSidebarDrawer";
import { FormikDocument } from "./FormikDocument";
import { usePutDocument } from "./usePutDocument";
import AiChatDrawer from "./AiChatDrawer/AiChatDrawer";
import AiChatButton from "./AiChatDrawer/AiChatButton";

interface Params extends Record<string, string> {
  documentId: string;
}

export interface DocumentRouteState {
  document?: DocumentVm;
}

const Document: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [loadingDoc, setLoadingDoc] = useState<boolean>(false);
  const [showSidebarDrawer, setShowSidebarDrawer] = useState<boolean>(false);
  const [showChatDrawer, setShowChatDrawer] = useState<boolean>(false);
  const [formProgress, setFormProgress] = useState<number>(0);
  const [document, setDocument] = useState<DocumentVm | undefined>(
    (location.state as DocumentRouteState)?.document
  );

  const clientOverrides = useAppSelector(CoreRedux.selectClientOverridesConfig);
  const params = useParams<Params>();
  const documentId = parseInt(params.documentId!);

  const [fetchError, setFetchError] = useState<string>("");

  /* fetch the document with id from url params on mount and
   * add the current user to the participants array if not existing */
  useEffect(() => {
    if (
      (!document || documentId !== document.id) &&
      !loadingDoc &&
      !fetchError
    ) {
      setLoadingDoc(true);
      (async () => {
        try {
          const res = await API.getDocumentById({ id: documentId });
          setDocument(res);
          dispatch(setIsPreview(false));
        } catch (err) {
          const errorString = JSON.stringify(serializeError(err));
          setFetchError(errorString);
        } finally {
          setLoadingDoc(false);
        }
      })();
    }
  }, [documentId, loadingDoc, document]);

  useEffect(() => {
    if (!clientOverrides) {
      dispatch(
        CoreRedux.getApplicationConfig(ApplicationConfigKey.clientOverrides)
      );
    }
  }, [clientOverrides]);

  function handleGoBack(): void {
    // TODO why do we need to check for length here? -JA
    /* if document was accessed from a location other than Dashboard, navigate to previous screen */
    if (history.length > 2) {
      return navigate(-1);
    }
    /* else return to Dashboard */
    return navigate("/");
  }

  function returnToDocs(): void {
    return navigate("/documents");
  }

  const { handlePutDocument, submissionSuccessful, error, setError } =
    usePutDocument();

  async function onPutDocument(values: DocumentVm): Promise<void> {
    const newValues = await handlePutDocument(values);
    if (newValues) setDocument(newValues);
  }

  return (
    <Loader loading={loadingDoc}>
      {document && (
        <>
          <DocumentHeader
            formProgress={formProgress}
            handleClickOnBackArrow={handleGoBack}
            handleOnClickMenu={() => setShowSidebarDrawer(true)}
            title={document.form.name}
          />
          <div className={styles.document}>
            <DocumentSidebarDrawer
              onClose={() => setShowSidebarDrawer(false)}
              open={showSidebarDrawer}
              selectedDocument={document}
            />
            {/* <AiChatDrawer
              open={showChatDrawer}
              onClose={() => setShowChatDrawer(false)}
              selectedDocument={document}
            /> */}
            {document.readOnly ? (
              <ReadOnlyDoc document={document} />
            ) : (
              <FormikDocument
                document={document}
                error={error}
                submissionSuccessful={submissionSuccessful}
                handleAutoSync={onPutDocument}
                handleSubmit={onPutDocument}
                returnToDocs={returnToDocs}
                setError={setError}
                setFormProgress={setFormProgress}
              />
            )}
            {/* <AiChatButton handleClick={() => setShowChatDrawer(true)} /> */}
          </div>
        </>
      )}
    </Loader>
  );
};

Document.displayName = "Document";
export default Document;
