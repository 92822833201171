import {
  API,
  DisplayConditionDTO,
  FormItemType,
  FormSaveVm,
  FormSectionSaveVm,
  FormSectionVm,
  FormSubmissionConstraintDTO,
  FormVm,
  QuestionAnswerSourceDTO,
  QuestionSaveVm,
  roles,
  SectionItem,
  SectionItemSaveVm,
  WorkflowType,
} from "@rtslabs/field1st-fe-common";
import { setIn } from "formik";
import { sortBy } from "lodash";
import { toTitleCase } from "../../../util/toTitleCase";
import { buildConditionFields } from "./create/properties/customFields/PrefillFromWorkOrder/PrefillFromWorkOrder";
import { getQuestionHasTags } from "./create/properties/customFields/Tags/helpers";
import { MissingWidgetType } from "./create/properties/MissingWidgetBanner";
import {
  FBDisplayCondition,
  FBForm,
  FBItem,
  FBQuestion,
  FBSection,
  FBSubmissionConstraint,
} from "../types";

export const getFormStatusString = (status?: WorkflowType) => {
  if (status === "FINAL") {
    return "Published";
  }
  return toTitleCase(status);
};

export function getSecondsFromMidnight(timeString: string) {
  const [hours, minutes] = timeString.split(":");
  return Number(hours) * 3600 + Number(minutes) * 60;
}

export function getTimeFromSeconds(seconds: number) {
  const date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  return date.toISOString().substr(11, 8);
}

/** type guards **/
export function isQuestionSaveVm(
  item: SectionItemSaveVm
): item is QuestionSaveVm {
  return item.type === "QUESTION";
}

/** type guards **/
export function isFBQuestion(item: FBItem): item is FBQuestion {
  return item.type === "QUESTION";
}

/** Filters out any display conditions which have not been completed */
function getCompletedDisplayConditions(
  displayConditions: FBDisplayCondition[]
): DisplayConditionDTO[] {
  return displayConditions?.filter(
    (dc) =>
      dc.action &&
      dc.targetRootId &&
      dc.targetType &&
      (dc.sourceQuestionRootId || dc.action === "WORK_ORDER_PREFILL")
  ) as DisplayConditionDTO[];
}

/**
 * Converts an existing form question into a Form Builder Item for processing
 */
function unmarshallItem(
  formItem: SectionItem,
  section: FormSectionVm,
  itemIndex: number,
  displayConditions?: DisplayConditionDTO[] | null
): FBItem {
  const item: FBItem = {
    ...formItem,
    parentSectionId: section.id,
    parentSectionRootId: section.rootId,
    itemIndex,
  };

  if (item.type === "QUESTION") {
    if (!item.formProperties) {
      item.formProperties = {
        isCloneable: false,
        isRequired: false,
        isSearchable: false,
      };
    }

    const displayCondition = displayConditions?.find(
      (dc) =>
        dc.action === "WORK_ORDER_PREFILL" && dc.targetRootId === item.rootId
    );

    if (displayCondition?.prefillAnswerField) {
      item.conditionFields = buildConditionFields(
        displayCondition.prefillAnswerField
      );
    }
  }

  return item;
}

/**
 * Converts an existing form section into a Form Builder Section for processing
 */
export function unmarshallSection(
  formSection: FormSectionVm,
  sectionIndex: number,
  displayConditions?: DisplayConditionDTO[] | null
): FBSection {
  // unmarshall each question in the section
  const items = formSection.items.map((fItem, i) =>
    unmarshallItem(fItem, formSection, i, displayConditions)
  );
  return {
    ...formSection,
    items,
    sectionIndex,
    type: "SECTION",
    subType: "SECTION",
  };
}

function unmarshalFormSubmissionConstraint(
  constraint?: FormSubmissionConstraintDTO | null
): FBSubmissionConstraint | undefined | null {
  if (constraint) {
    const { type, hourLimit } = constraint;
    if (type === "HOUR" && hourLimit) {
      const hourType = hourLimit % 24 === 0 ? "days" : "hours";
      return {
        ...constraint,
        hourType,
        hourLimit: hourType === "days" ? hourLimit / 24 : hourLimit,
      };
    }
  }
  return constraint;
}

/**
 * Converts an existing form to an array of Form Builder Sections for processing
 * @param form
 */
export function unmarshallForm(form: FormVm): FBForm {
  // unmarshall each section in the form
  const sections = form.sections.map((fSection, i) =>
    unmarshallSection(fSection, i, form.displayConditions)
  );

  // these are not sorted by the backend, but should be sorted here for consistency in form builder
  const displayConditions = sortBy(form.displayConditions || [], ["id"]);

  const formSubmissionConstraint = unmarshalFormSubmissionConstraint(
    form.formSubmissionConstraint
  );

  return { ...form, sections, displayConditions, formSubmissionConstraint };
}

/***** MARSHALL FORM *****/

/**
 * Converts a Form Builder Item into a form item for processing
 */
export function marshallItem<T extends SectionItemSaveVm>(
  formItem: FBItem<T>
): T {
  const saveVm: T = { ...formItem };
  setIn(saveVm, "parentSectionId", undefined);
  setIn(saveVm, "parentSectionRootId", undefined);
  setIn(saveVm, "itemIndex", undefined);
  setIn(saveVm, "conditionFields", undefined);

  if (isQuestionSaveVm(saveVm)) {
    saveVm.selections = saveVm.selections?.map((sel) => {
      // we need to strip out the temporary string ids from the new selections
      // they'll match the title because that's what MultiInput returns if no
      // id exists
      if (String(sel.id) === sel.title) {
        return { ...sel, id: null };
      }
      return sel;
    });
  }

  return formItem;
}

/**
 * Converts a Form Builder Section into a form section for processing
 */
export function marshallSection(formSection: FBSection): FormSectionSaveVm {
  // marshall each question in the section
  const items = formSection.items.map((fItem) => marshallItem(fItem));
  const saveVm: FormSectionSaveVm = {
    ...formSection,
    items,
  };
  setIn(saveVm, "sectionIndex", undefined);
  setIn(saveVm, "type", undefined);
  setIn(saveVm, "subType", undefined);

  return saveVm;
}

function marshalFormSubmissionConstraint(
  constraint?: FBSubmissionConstraint | null
): FormSubmissionConstraintDTO | undefined | null {
  if (constraint && constraint.type) {
    const {
      id,
      noSubmissionsUntilNextDay,
      type,
      timeLimit,
      hourLimit,
      hourType,
    } = constraint;
    return {
      id,
      noSubmissionsUntilNextDay,
      type,
      timeLimit,
      hourLimit: hourLimit && hourType === "days" ? 24 * hourLimit : hourLimit,
    };
  }
  return null;
}

/**
 * Converts an updated form to the correct shape to submit to the API
 * @param form
 */
export function marshallForm(form: FBForm): FormSaveVm {
  // filter out any incomplete display conditions
  // @TODO should we show a warning/validation when a user is about to lose the incompleted display conditions?
  // on unmount/switch item/close form
  const displayConditions: DisplayConditionDTO[] =
    getCompletedDisplayConditions(form.displayConditions);

  // marshall each section in the form
  const sections = form.sections.map((fSection) => marshallSection(fSection));

  const formSubmissionConstraint = marshalFormSubmissionConstraint(
    form.formSubmissionConstraint
  );

  return { ...form, sections, displayConditions, formSubmissionConstraint };
}

/**
 * Get a flat array of all the items on the form
 * @param form
 */
export function getAllFormItems(form: FBForm): FBItem[] {
  return form.sections?.flatMap((section: FBSection) => section.items);
}

/**
 * Get a form section item (question/widget/content) from the form by its id
 */
export const getFormItemById = (
  form: FBForm,
  id: number
): FBItem | undefined => {
  const items: FBItem[] | undefined = getAllFormItems(form);
  return items?.find((item: FBItem) => item.id === id);
};

/**
 * Get a form section item (question/widget/content) from the form by its root id
 */
export const getFormItemByRootId = (
  form: FBForm,
  rootId: number
): FBItem | undefined => {
  const items: FBItem[] | undefined = getAllFormItems(form);
  return items?.find((item: FBItem) => item.rootId === rootId);
};

/**
 * Returns true if a question has OEs or Defenses attached to its selections,
 * but the form is missing the associated widget
 *
 * @param form
 * @param item Item to check for OEs/Defenses
 */
export function getMissingWidgets(form: FBForm, item: QuestionSaveVm) {
  const formItems = getAllFormItems(form);
  const missingWidgets: MissingWidgetType[] = [];

  if (getQuestionHasTags(item)) {
    if (
      !formItems.find(
        (item) =>
          item.type === "WIDGET" && item.subType === "OPERATIONAL_EXPERIENCES"
      )
    ) {
      missingWidgets.push("OPERATIONAL_EXPERIENCES");
    }
  }
  if (item.selections?.some((selection) => selection.defenseIds!.length > 0)) {
    if (
      !formItems.find(
        (item) => item.type === "WIDGET" && item.subType === "DEFENSES"
      )
    ) {
      missingWidgets.push("DEFENSES");
    }
  }

  return missingWidgets;
}

/**
 * Scroll to an element with the given id
 * @param elementId
 */
export function scrollToElement(elementId: string, alignToTop = true) {
  const node = document.getElementById(elementId);
  node?.scrollIntoView({
    behavior: "smooth",
    block: alignToTop ? "start" : "nearest",
  });
}

/**
 * Get the section index of the currently selected item or section
 * @param selectedItem currently selected item
 * @param sections all sections on the form
 */
export function getSelectedSectionIndex(
  selectedItem: FBItem | FBSection | null,
  sections: FBSection[]
) {
  if (!selectedItem) return -1;
  if (selectedItem.type === "SECTION") {
    return selectedItem.sectionIndex;
  } else {
    return sections.findIndex(
      (section) => section.id === selectedItem.parentSectionId
    );
  }
}

/**
 *
 * @param itemType item type which is being added
 * @param sectionIndex section index of the currently selected item
 * @param selectedItem currently selected item
 * @param sections all sections on the form
 */
export function getAddedItemIndex(
  itemType: FormItemType | "SECTION", // type of added item
  itemSubType: string, // subType of added item
  sectionIndex: number | undefined, // section index of currently selected item
  selectedItem: FBItem | FBSection | null, // currently selected item
  sections: FBSection[]
) {
  // adding a section
  if (itemType === "SECTION") {
    return typeof sectionIndex === "number" ? sectionIndex + 1 : undefined;
  }
  // adding a Photo Gallery widget within a section
  if (itemSubType === "PHOTO_GALLERY") {
    // filters out child sections so section index is accurate
    return sections.filter((s) => !s.parentWidgetRootId).length;
  }
  // adding an item to a section
  if (selectedItem?.type === "SECTION") {
    if (typeof sectionIndex === "number") {
      return sections[sectionIndex].items.length;
    } else {
      return;
    }
  }
  if (typeof selectedItem?.itemIndex === "number") {
    return selectedItem?.itemIndex + 1;
  }
}

/**
 * Strip the section and item indices from an item's path
 * @param itemPath - path to an item in formik values, e.g. section[0].items[3]
 */
export function getPathIndices(itemPath: string): [number?, number?] {
  const indices = itemPath
    .replace(/[a-z\[\]]/g, "")
    .split(".")
    .map((idx) => Number(idx));
  if (typeof indices?.[0] === "number") {
    return [indices[0], indices[1]];
  }
  return [undefined, undefined];
}

export const initialParticipantAnswerSource: QuestionAnswerSourceDTO = {
  type: "DATA_SOURCE",
  dataSourceKey: "PARTICIPANT",
  properties: {
    answerField: "fullName",
    autoComplete: false,
    detailedSearch: {
      title: "Add Participant",
      enabled: true,
      linkName: "Add Participant",
      searchBy: "Search by name or member ID",
      subtitle: "Who would you like to add?",
      infiniteListTitle: "Participants by Alphabetical Order",
      infiniteListSortBy: "fullName",
      recentAnswersTitle: "Recently Added Participants",
      customAnswerEnabled: true,
      infiniteListEnabled: true,
      recentAnswersEnabled: true,
    },
  },
};

export function canViewForm(form: FormVm | FBForm): boolean {
  if (!form.clientGroups?.length)
    return API.Environment.hasRoleAccess([roles.ROLE_CLIENT_ADMIN]);
  return API.Environment.hasGroupAccess(
    form.clientGroups.map((group) => group.id),
    [roles.ROLE_FORM_ARCHITECT],
    false
  );
}

export function canEditForm(form: FormVm | FBForm): boolean {
  if (!form.clientGroups?.length)
    return API.Environment.hasRoleAccess([roles.ROLE_FORM_ARCHITECT]);
  return API.Environment.hasGroupAccess(
    form.clientGroups.map((group) => group.id),
    [roles.ROLE_FORM_ARCHITECT],
    true
  );
}
