import React from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps } from "../../qa-slugs";

export interface MulticolorIconProps extends QAProps {
  className?: string;
  type: string;
  size?: number | string;
  qa?: string;
}

export const MulticolorIcon = ({
  className,
  qa,
  size,
  type,
}: MulticolorIconProps) => {
  const fullType = type.startsWith("icon-") ? type : `icon-${type}`;

  return (
    <span
      aria-hidden="true"
      className={joinClassNames("icon", fullType, className)}
      data-testid={qa}
      style={{ fontSize: size }}
    >
      {
        // Multi-color icons in Icomoon use one or more elements which always
        // have the classnames "path1", "path2", etc. There's a variable number
        // of paths in different icons, and the icomoon data doesn't appear to
        // give us a way to find out how many. So, this supports the first 8
        // paths. If there's fewer than 8 paths, the remaining spans are unused.
        //
        // Below is an explanation from Icomoon's documentation.
        //
        // Font glyphs cannot have more than one color by default. Using CSS, IcoMoon
        // layers multiple glyphs on top of each other to make color glyphs possible.
        // As a result, these glyphs take more than one character code and cannot
        // have ligatures.
      }
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
    </span>
  );
};
