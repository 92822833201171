import React from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import styles from "../OperationalExperiences.module.scss";

interface Props {
  collapsed: boolean;
  onClick: () => void;
}

function ShowMoreButton({ collapsed, onClick }: Props) {
  return (
    <button type="button" onClick={onClick} className={styles.showMoreBtn}>
      <span>{collapsed ? "SHOW MORE" : "SHOW LESS"}</span>
      <Icon type="expand_arrow" className={styles.showMoreIcon} />
    </button>
  );
}

export default ShowMoreButton;
