import React from "react";
import { Icon } from "../Icon/Icon";

import { MenuItem as MenuItemType, MenuItemIcon } from "./types";
import styles from "./Menu.module.scss";

/**
 * Renders an Icon based on the given enum icon
 * ie: If you Menu config has:
 * `icon: MenuItemIcon.PENCIL`
 * Then it'll meet the requirements to render
 * the pencil icon. You'll need to import
 * and use the enum. (Exported from Menu)
 */
const renderIcon = (icon: MenuItemIcon) => {
  const iconToRender = () => {
    switch (icon) {
      case MenuItemIcon.PENCIL:
        return <Icon type="pencil_fill" className={styles.pencil} />;
      case MenuItemIcon.PHONE_SETTINGS:
        return <Icon type="phone_settings" className={styles.phoneSettings} />;
      default:
        return null;
    }
  };

  return <div className={styles.icon}>{iconToRender()}</div>;
};

interface Props {
  menuItem: MenuItemType;
}

/**
 * Renders a Menu Item element and contains logic to
 * render an Icon, label, and a hard coded Chevron at the end.
 */
const MenuItem = ({ menuItem: { label, icon, onClick } }: Props) => {
  return (
    <button onClick={onClick} className={styles.menuItem}>
      <div className={styles.left}>
        {icon && renderIcon(icon)} <div className={styles.label}>{label}</div>
      </div>
      <Icon className={styles.chevronRight} type="chevron-left" />
    </button>
  );
};

export default MenuItem;
