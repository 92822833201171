import { Moment } from "moment";

export const isAfterDate = (
  date: Moment,
  dateToCompare?: Moment | string | null
) => {
  return dateToCompare && date.isAfter(dateToCompare);
};

export const isBeforeDate = (
  date: Moment,
  dateToCompare?: Moment | string | null
) => {
  return dateToCompare && date.isBefore(dateToCompare);
};
