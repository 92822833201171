import React from "react";
import { Button } from "shared/src/components/Button/Button";
import styles from "../OperationalExperiences.module.scss";

interface Props {
  loading: boolean;
  reshuffle: () => void;
  disabled?: boolean;
}

function Reshuffle({ disabled, loading, reshuffle }: Props) {
  return (
    <div className={styles.reshuffle}>
      <Button loading={loading} onClick={reshuffle} disabled={disabled}>
        RESHUFFLE
      </Button>

      <p>Reshuffle the deck for more scenarios.</p>
    </div>
  );
}

export default Reshuffle;
