import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  API,
  ParticipantSummary,
  SortDirection,
  useAPI,
  UserStatus,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import Loader from "shared/src/components/Loader/Loader";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { Components, ElementType } from "shared/src/qa-slugs";
import { userWriteRoles } from "../../../routes/constants/permissionSets";
import Modal from "shared/src/components/common/Modal";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import Popper from "shared/src/components/common/Popper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "shared/src/components/common/styled/Table";
import DefaultCell from "../../common/TableUI/DefaultCell";
import ExpandableTableCell from "../../common/TableUI/ExpandableTableCell";
import NoResults from "shared/src/components/common/TableUI/NoResults";
import { SubmitButton, Text } from "shared/src/components/clientAdmin/styles";
import { DeleteUserModal } from "./DeleteUserModal";
import {
  getActivationStatusForPayload,
  getEnabledOrDisabledWording,
  showDisableEnableItem,
  showEmailValidationItem,
} from "./helpers";
import { ResendVerificationEmailConfirmation } from "./modalContent/ResendVerificationEmailConfirmation";
import { UserActivationStatusConfirmation } from "./modalContent/UserActivationStatusConfirmation";
import { ReadOnlyUserStatus } from "./ReadOnlyUserStatus";
import * as S from "./styles";
import { getRoleLabel } from "./usersConstants";
import { UserStatusDropDown } from "./UserStatusDropDown";

interface Sorting {
  [key: string]: {
    direction: SortDirection;
    type?: string;
  };
}

export const UsersTable = ({ participantQueryParams, page, setPage }) => {
  const [size, setSize] = useState<number>(10);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Popper context menu
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorUser, setAnchorUser] = useState<ParticipantSummary>();
  const [popperIsOpen, setPopperIsOpen] = useState(false);

  // USER ACTIVATION STATUS
  const [activationStatusError, setActivationStatusError] = useState(false);
  const [activationStatusIsLoading, setActivationStatusIsLoading] =
    useState(false);
  const [
    userActivationConfirmationModalIsVisible,
    setUserActivationConfirmationModalIsVisible,
  ] = useState(false);
  const [
    userDeleteConfirmationModalIsVisible,
    setUserDeleteConfirmationModalIsVisible,
  ] = useState(false);
  const [
    userActivationOutcomeModalIsVisible,
    setUserActivationOutcomeModalIsVisible,
  ] = useState(false);
  const enabledOrDisabled = useMemo(
    () => getEnabledOrDisabledWording(anchorUser && anchorUser.status),
    [anchorUser && anchorUser.status]
  );

  // Resend verification email
  const [validationEmailError, setValidationEmailError] = useState(false); // API call resulted in error
  const [validationEmailIsLoading, setValidationEmailIsLoading] =
    useState(false); // API call is pending
  // First modal (confirmation)
  const [
    validationEmailConfirmationModalIsVisible,
    setValidationEmailConfirmationModalIsVisible,
  ] = useState(false);
  // Second modal (outcome)
  const [
    validationEmailOutcomeModalIsVisible,
    setValidationEmailOutcomeModalIsVisible,
  ] = useState(false);

  // Sort
  const [sorting, setSorting] = useState<Sorting>({
    // Order is by appearance in table
    firstName: {
      direction: "asc",
    },
    groupName: {
      direction: "asc",
    },
    lastLogin: {
      direction: "asc",
    },
    status: {
      direction: "asc",
    },
    primarySort: {
      // Not a column, table's sorted by 1 column at all times
      direction: "asc",
      type: "firstName",
    },
  });

  const sortQuery = useMemo(
    () => `${sorting.primarySort.type},${sorting.primarySort.direction}`,
    [sorting.primarySort.type, sorting.primarySort.direction]
  );

  const usersCall = useAPI(API.getParticipants, {
    ...participantQueryParams,
    page,
    size: size,
    sort: sortQuery,
  });

  const handleSort = (type, direction) => {
    setSorting({
      ...sorting,
      [type]: {
        direction,
      },
      primarySort: {
        direction,
        type,
      },
    });
  };

  return (
    <>
      {/* Amazing modal for the outcome when changing a user's activation status */}
      <Modal
        border="none"
        open={userActivationOutcomeModalIsVisible}
        handleClose={() => {
          setActivationStatusError(false);
          setUserActivationOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <S.ModalPromptWrapper>
            <S.ModalPromptTitle>
              {activationStatusError
                ? `Error - Unable to ${
                    enabledOrDisabled === "disable" ? "disable" : "enable"
                  } user`
                : `User has been ${
                    enabledOrDisabled === "disable" ? "disabled" : "enabled"
                  }`}
            </S.ModalPromptTitle>
            <S.ModalPromptSubtitle>
              {activationStatusError
                ? `The system encountered an error while trying to ${
                    enabledOrDisabled === "disable" ? "disable" : "enable"
                  } the selected user. Please try again.`
                : `The selected user’s account has been successfully ${
                    enabledOrDisabled === "disable" ? "disabled" : "enabled"
                  }. User will ${
                    enabledOrDisabled == "disable" ? "no longer" : "now"
                  } be able to log in.`}
            </S.ModalPromptSubtitle>
            <S.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  setActivationStatusError(false);
                  setUserActivationOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </S.DisableUserButtonsWrapper>
            <S.ModalPromptCloseIcon
              onClick={() => {
                setActivationStatusError(false);
                setUserActivationOutcomeModalIsVisible(false);
              }}
            />
          </S.ModalPromptWrapper>
        }
      />
      {/* // Amazing modal for changing a user's activation status */}
      <Modal
        border="none"
        open={userActivationConfirmationModalIsVisible}
        handleClose={() => {
          setUserActivationConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <UserActivationStatusConfirmation
            // user data
            email={anchorUser && anchorUser.email}
            firstName={anchorUser && anchorUser.firstName}
            groupNames={anchorUser && anchorUser.groupNames}
            status={anchorUser && anchorUser.status}
            lastLogin={anchorUser && anchorUser.lastLogin}
            lastName={anchorUser && anchorUser.lastName}
            // rest
            enabledOrDisabled={enabledOrDisabled}
            loading={activationStatusIsLoading}
            onCancel={() => setUserActivationConfirmationModalIsVisible(false)}
            onSubmit={() => {
              let payload = {};
              if (anchorUser) {
                payload = {
                  isActive: getActivationStatusForPayload(anchorUser.status), // Function sets it to opposite
                  participantId: anchorUser.id,
                  userId: anchorUser.userId,
                };
              }
              setActivationStatusIsLoading(true);
              API.changeActivationStatus({ vm: payload })
                .then(() => {
                  setActivationStatusError(false);
                  setUserActivationConfirmationModalIsVisible(false);
                  setUserActivationOutcomeModalIsVisible(true);
                  usersCall.refresh();
                })
                .catch(() => setActivationStatusError(true))
                .finally(() => setActivationStatusIsLoading(false));
            }}
          />
        }
      />
      <DeleteUserModal
        anchorUser={anchorUser}
        open={userDeleteConfirmationModalIsVisible}
        onClose={() => {
          setUserDeleteConfirmationModalIsVisible(false);
          usersCall.refresh();
        }}
      />
      {/* Modal for the outcome when resending verification email */}
      <Modal
        border="none"
        open={validationEmailOutcomeModalIsVisible}
        handleClose={() => {
          setValidationEmailError(false);
          setValidationEmailOutcomeModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <S.ModalPromptWrapper>
            <S.ModalPromptTitle>
              {validationEmailError
                ? "Error - Validation Email NOT sent"
                : "Validation Email has sent"}
            </S.ModalPromptTitle>
            <S.ModalPromptSubtitle>
              {validationEmailError
                ? `The system encountered an error while trying to send a Validation email to 
                the selected user. Please try again.`
                : "A Validation email has been sent to the selected user."}
            </S.ModalPromptSubtitle>
            <S.DisableUserButtonsWrapper>
              <SubmitButton
                onClick={() => {
                  setValidationEmailError(false);
                  setValidationEmailOutcomeModalIsVisible(false);
                }}
              >
                <span>close </span>
              </SubmitButton>
            </S.DisableUserButtonsWrapper>
            <S.ModalPromptCloseIcon
              onClick={() => {
                setValidationEmailError(false);
                setValidationEmailOutcomeModalIsVisible(false);
              }}
            />
          </S.ModalPromptWrapper>
        }
      />
      {/* // Modal for resending verification email */}
      <Modal
        border="none"
        open={validationEmailConfirmationModalIsVisible}
        handleClose={() => {
          setValidationEmailConfirmationModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <ResendVerificationEmailConfirmation
            // user data
            email={anchorUser && anchorUser.email}
            firstName={anchorUser && anchorUser.firstName}
            groupNames={anchorUser && anchorUser.groupNames}
            // status={anchorUser && anchorUser.status}
            lastLogin={anchorUser && anchorUser.lastLogin}
            lastName={anchorUser && anchorUser.lastName}
            // rest
            loading={validationEmailIsLoading}
            onCancel={() => setValidationEmailConfirmationModalIsVisible(false)}
            onSubmit={() => {
              if (anchorUser && anchorUser.email) {
                setValidationEmailIsLoading(true);
                API.resendValidationEmail({ email: anchorUser.email })
                  .then(() => {
                    setValidationEmailError(false);
                    setValidationEmailConfirmationModalIsVisible(false);
                    setValidationEmailOutcomeModalIsVisible(true);
                  })
                  .catch(() => setValidationEmailError(true))
                  .finally(() => setValidationEmailIsLoading(true));
              }
            }}
          />
        }
      />
      <Popper
        qa={`${Components.Users}-${ElementType.MenuModal}-${
          anchorUser && anchorUser.id
        }`}
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <S.PopperWrapper>
          <S.PopperRow
            data-testid={`${Components.Users}-${
              ElementType.MenuItem
            }-viewEditProfile-${anchorUser && anchorUser.id}`}
            onClick={() =>
              navigate(`/people/users/view/${anchorUser && anchorUser.id}`)
            }
          >
            <WriteContent
              altComponent={<span>View user profile</span>}
              roles={userWriteRoles}
            >
              <span>View/edit user profile</span>
            </WriteContent>
            <S.PopperChevron />
          </S.PopperRow>
          <S.PopperRow
            data-testid={`${Components.Users}-${
              ElementType.MenuItem
            }-viewDetailedReports-${anchorUser && anchorUser.id}`}
            disabled
          >
            <span>View detailed reports for user</span>
            <S.PopperChevron />
          </S.PopperRow>
          {/* Resend validation/activation email menu item  */}
          {showEmailValidationItem(anchorUser?.status) && (
            <WriteContent roles={userWriteRoles}>
              <S.PopperRow
                onClick={() => {
                  setValidationEmailConfirmationModalIsVisible(true);
                }}
              >
                <div className="d-flex flex-row align-items-center">
                  <div className="mr-3">
                    {anchorUser?.status === "DISABLED"
                      ? "Resend validation email"
                      : "Send activation email"}
                  </div>
                </div>
                <S.PopperChevron />
              </S.PopperRow>
            </WriteContent>
          )}
          {/* Delete user menu item  */}
          {showDisableEnableItem(anchorUser && anchorUser.status) && (
            <WriteContent roles={userWriteRoles}>
              <S.PopperRow
                data-testid={`${Components.Users}-${
                  ElementType.Button
                }-deleteUser-${anchorUser && anchorUser.id}`}
                error={true}
                onClick={() => {
                  setUserDeleteConfirmationModalIsVisible(true);
                }}
              >
                <span>Delete User</span>
                <S.PopperChevron />
              </S.PopperRow>
            </WriteContent>
          )}
        </S.PopperWrapper>
      </Popper>
      <Loader loading={usersCall.isLoading}>
        <TableSummary
          pageSize={size}
          currentPage={page}
          totalElements={usersCall.data?.totalElements}
          ofWhat="users"
          exports={["print", "xls", "csv"]}
          onExport={API.downloadParticipants}
          exportParams={participantQueryParams}
        />
        <Table>
          <TableHead>
            <TableRow noBottomBorder>
              <TableCell
                qa={`${Components.Users}-${ElementType.TableCell}-sort-userName`}
                active={sorting.primarySort.type === "firstName"}
                onClick={(dir) => handleSort("firstName", dir)}
                sortDirection={sorting.firstName.direction}
              >
                User Name
              </TableCell>
              <TableCell
                qa={`${Components.Users}-${ElementType.TableCell}-sort-role`}
              >
                Role
              </TableCell>
              <TableCell
                qa={`${Components.Users}-${ElementType.TableCell}-sort-group`}
                width="200px"
                active={sorting.primarySort.type === "groupName"}
                onClick={(dir) => handleSort("groupName", dir)}
                sortDirection={sorting.groupName.direction}
              >
                Group
              </TableCell>
              <TableCell
                qa={`${Components.Users}-${ElementType.TableCell}-sort-lastLogin`}
                active={sorting.primarySort.type === "lastLogin"}
                onClick={(dir) => handleSort("lastLogin", dir)}
                sortDirection={sorting.lastLogin.direction}
                width="150px"
              >
                Last Login
              </TableCell>
              <TableCell
                qa={`${Components.Users}-${ElementType.TableCell}-sort-status`}
                active={sorting.primarySort.type === "status"}
                onClick={(dir) => handleSort("status", dir)}
                sortDirection={sorting.status.direction}
                width="160px"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersCall.data && usersCall.data.content.length > 0 ? (
              usersCall.data.content.map((user, i) => (
                <TableRow
                  key={i}
                  qa={`${Components.Users}-${ElementType.TableRow}-${user.id}`}
                >
                  <TableCell
                    qa={`${Components.Users}-${ElementType.TableCell}-user-${user.id}`}
                  >
                    <Text
                      data-testid={`${Components.Users}-${ElementType.Text}-name-${user.id}`}
                      fontSize={"0.875rem"}
                      fontWeight={700}
                    >
                      {user.firstName} {user.lastName}
                    </Text>
                    <S.EmailText
                      data-testid={`${Components.Users}-${ElementType.Text}-email-${user.id}`}
                    >
                      {user.email || "-"}
                    </S.EmailText>
                  </TableCell>
                  <TableCell
                    qa={`${Components.Users}-${ElementType.TableCell}-role-${user.id}`}
                  >
                    <DefaultCell value={getRoleLabel(user.roleName)} />
                  </TableCell>
                  <ExpandableTableCell
                    qa={`${Components.Users}-${ElementType.TableCell}-group-${user.id}`}
                    items={user.groupNames}
                  />
                  <TableCell
                    qa={`${Components.Users}-${ElementType.TableCell}-login-${user.id}`}
                  >
                    <DefaultCell
                      value={
                        (user.lastLogin &&
                          moment(user.lastLogin).format("MM/DD/YYYY")) ||
                        "-"
                      }
                    />
                  </TableCell>
                  <TableCell
                    qa={`${Components.Users}-${ElementType.TableCell}-status-${user.id}`}
                  >
                    <WriteContent
                      altComponent={
                        <ReadOnlyUserStatus
                          currentStatus={user.status}
                          qa={`${Components.Users}-${ElementType.TableCell}-userStatus`}
                        />
                      }
                      roles={userWriteRoles}
                    >
                      <ClickAwayListener
                        onClickAway={() => {
                          if (popperIsOpen) {
                            setPopperIsOpen(false);
                          }
                        }}
                      >
                        <div className="d-flex flex-row justify-content-between">
                          <UserStatusDropDown
                            qaBase={`${Components.UserStatusDropdown}`}
                            currentStatus={user.status}
                            onChangeStatus={(newStatus: UserStatus) => {
                              if (newStatus !== user.status) {
                                setAnchorUser(user);
                                setUserActivationConfirmationModalIsVisible(
                                  true
                                );
                              }
                            }}
                          />
                        </div>
                      </ClickAwayListener>
                    </WriteContent>
                  </TableCell>
                  <TableCell>
                    {user.id && (
                      <ActionButton
                        qa={`${Components.Users}-${ElementType.Menu}-${user.id}`}
                        label={t("common:table.rowMenuButtonLabel")}
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          setAnchorUser(user);
                          setActivationStatusError(false);
                          setTimeout(() => {
                            setPopperIsOpen(true);
                          }, 100);
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <NoResults
                icon="user"
                header="No users were found."
                body="Adjust your filters, or create a new user."
                button={{
                  children: "Create a user",
                  onClick: () => navigate("/people/users/add-user"),
                }}
                qa={`${Components.Users}-${Components.NotFound}`}
              />
            )}
          </TableBody>
        </Table>
      </Loader>

      {/* // Pagination */}
      {usersCall.data && (
        <PaginationWrapper>
          <RowsPerPage
            qaBase={`${Components.Users}-view`}
            pageSize={size}
            onClick={(size) => {
              setSize(size);
            }}
          />
          <Pagination
            qaBase={`${Components.Users}-pagination`}
            currentPage={page}
            onClick={(page) => setPage(page)}
            totalPages={usersCall.data.totalPages}
          />
          <BackToTop qa={`${Components.Users}-${ElementType.Link}-backToTop`} />
        </PaginationWrapper>
      )}
    </>
  );
};
