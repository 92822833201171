import React, { JSXElementConstructor, useContext, useState } from "react";
import { css } from "aphrodite/no-important";
import { ThemeContext } from "styled-components";

import { ActionButton } from "../../../../ActionButton/ActionButton";
import { ActionMenu, ActionMenuItem } from "../../../../common/ActionMenu";
import { ItemField, ItemSelection } from "./ItemField";
import CollapsibleDiv from "../../../../common/CollapsibleDiv";
import { Icon } from "../../../../Icon/Icon";
import Loader from "../../../../Loader/Loader";
import { Modal } from "../../../../Modal/Modal";

import { elements } from "../items/Elements";
import { AppWidgetProps, FBItem, FBQuestion } from "../../../types";
import { fields } from "../items/Fields";
import { ItemParams, ItemsMutator } from "../Create";
import styles from "./styles";
import useValidation from "../validation/useValidation";
import ValidationStatus from "../validation/ValidationStatus";
import {
  API,
  DataSourceValueDTO,
  PageOfDataSourceValueDTO,
  QuestionProperties,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import { Components } from "../../../../../qa-slugs";
import { useTranslation } from "react-i18next";

/**
 * Generate selections for an item from its data source (if existing) or from its explicitly defined "selections"
 * property. If neither exist, return a single selection prompting user to add selections.
 */
function getSelections(
  item: FBQuestion,
  dataSourceValues?: PageOfDataSourceValueDTO | null
): Array<ItemSelection> {
  let selections: Array<ItemSelection> = [];

  /* item has an answer source - use it to populate selections */
  if (dataSourceValues) {
    const contentProp = item.answerSource?.properties?.answerField || 0;
    selections = dataSourceValues.content.map((as: DataSourceValueDTO) => ({
      id: as.id,
      rootId: as.id,
      title: (as.content as { [key: string]: string })[contentProp],
    }));
  } else if (item.selections?.length) {
    /* item has defined selections */
    selections = item.selections.map((sel) => ({
      id: sel.id,
      rootId: sel.rootId || null,
      title: sel.title,
      properties: sel.properties,
    }));
  }

  return selections;
}

// helper method to call api only wen valid args given
async function optionalGetDataSourceValues(
  args: Partial<API.GetDataSourceValuesArgs>
): Promise<PageOfDataSourceValueDTO | undefined> {
  if (!!args.dataSourceKey) {
    return API.getDataSourceValues(args as API.GetDataSourceValuesArgs);
  }
  return undefined;
}

/**
 * Build the item's name based on its type and subtype
 */
export function getItemName(
  {
    type,
    subType,
    properties,
  }: {
    type: string;
    subType: string;
    properties?: QuestionProperties | null;
  },
  appWidgetsList: ItemParams[]
): string {
  if (properties?.fakeWidgetType) {
    const widget = appWidgetsList.find(
      (i) =>
        i.type === "QUESTION" &&
        i.properties?.fakeWidgetType === properties.fakeWidgetType
    );
    if (widget?.name) {
      return widget.name;
    }
  }
  /** Array of objects containing item names, types and subtypes */
  let itemNames = [...elements, ...fields, ...appWidgetsList];

  const item = itemNames.find((i) => i.type === type && i.subType === subType);
  return item?.name || "";
}

interface Props {
  incrementItemIndex: (item: FBItem, pos: number, arrLen: number) => void;
  decrementItemIndex: (item: FBItem, pos: number) => void;
  item: FBItem;
  position: number;
  removeItem: ItemsMutator;
  setCurrentItem: (item: FBItem) => void;
  selected: boolean;
  siblings: Array<FBItem>;
  itemPath: string;
  appWidgets: JSXElementConstructor<AppWidgetProps>;
  appWidgetsList: ItemParams[];
}

function Item({
  incrementItemIndex,
  decrementItemIndex,
  item,
  position,
  removeItem,
  setCurrentItem,
  selected,
  siblings,
  itemPath,
  appWidgets,
  appWidgetsList,
}: Props) {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation("formBuilder");

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [apiStatus, setApiStatus] = useState<"initial" | "loading" | "error">(
    "initial"
  );

  const { getErrorsByPath, alertLevel } = useValidation();
  const itemErrors = getErrorsByPath(itemPath);

  const firstItem = position === 0;
  const lastItem = position === siblings.length - 1;

  const dataSourceCall = useAPI(optionalGetDataSourceValues, {
    dataSourceKey:
      (item.type === "QUESTION" && item.answerSource?.dataSourceKey) || "", // api only called if valid
    size: 10,
    sort:
      item.type === "QUESTION"
        ? item.answerSource?.properties?.detailedSearch?.infiniteListSortBy
        : "",
  });

  async function handleDeleteItem() {
    setApiStatus("loading");
    const success = await removeItem(item);
    if (!success) {
      setApiStatus("error");
    } else {
      setApiStatus("initial");
    }
  }

  function handleCloseModal() {
    setModalOpen(false);
    setApiStatus("initial");
  }

  const s = styles();

  if ("parentWidgetRootId" in item && item.parentWidgetRootId) {
    // filter out the items which are auto-created by a widget
    return null;
  }

  const selections =
    item.type === "QUESTION" ? getSelections(item, dataSourceCall.data) : [];

  const error = apiStatus === "error";
  const alertCount = itemErrors?.length;

  return (
    <>
      <Modal
        action={{
          text: error ? "okay" : "yes, delete",
          callback: error ? handleCloseModal : handleDeleteItem,
          loading: apiStatus === "loading",
        }}
        alert={{
          variant: error ? "error" : "warning",
          title: error
            ? "An error occurred"
            : "Are you sure you want to delete this question?",
          message: error
            ? "The question could not be deleted. If the issue persists, please contact your system administrator."
            : "This action cannot be undone.",
          isVisible: true,
        }}
        cancellable={apiStatus === "initial"}
        handleClose={handleCloseModal}
        open={modalOpen}
        qaBase={`${Components.FormBuilder}-deleteConfirmation`}
        title="Are you sure?"
      />
      <div
        className={css([
          s.item,
          selected && s.selected,
          alertCount && s[`${alertLevel}Item`],
          alertCount && selected && s[`${alertLevel}ItemSelected`],
        ])}
        id={item?.id?.toString()}
        onClick={(event) => {
          event.stopPropagation();
          setCurrentItem(item);
        }}
      >
        <div className={css(s.itemContent)}>
          <div
            className={css([
              s.itemNameWrapper,
              firstItem && lastItem ? s.padding : "",
            ])}
          >
            <div className={css(s.row)}>
              <Icon
                type="drag_reorder"
                color={theme.masterColors.darkGrey}
                size="20px"
              />
              <div className={css(s.itemName)}>
                {getItemName(item, appWidgetsList)}
              </div>
            </div>
            <div className={css(s.row)}>
              {!!alertCount && (
                <ValidationStatus
                  alertLevel={alertLevel}
                  errorCount={alertCount}
                />
              )}
              {!firstItem && (
                <ActionButton
                  onClick={() => decrementItemIndex(item, position)}
                  label="move item up"
                  IconComponent={
                    <i className="icon-icons8-sort_up" style={{ height: 20 }} />
                  }
                />
              )}
              {!lastItem && (
                <ActionButton
                  onClick={() =>
                    incrementItemIndex(item, position, siblings.length)
                  }
                  label="move item down"
                  IconComponent={
                    <i
                      className="icon-icons8-sort_down"
                      style={{ height: 20 }}
                    />
                  }
                />
              )}
              <ActionMenu
                buttonLabel={t("create.itemMenuButtonLabel")}
                closeOnClick
              >
                <ActionMenuItem
                  onClick={() => setCollapsed((currState) => !currState)}
                  label={`${collapsed ? "Expand" : "Collapse"} question`}
                />
                <ActionMenuItem
                  danger
                  onClick={() => setModalOpen(true)}
                  label="Delete question"
                />
              </ActionMenu>
            </div>
          </div>
          <CollapsibleDiv collapsed={collapsed}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Loader loading={dataSourceCall.isLoading} overlay>
                <ItemField
                  item={item}
                  selected={selected}
                  selections={selections}
                  totalSelections={
                    dataSourceCall.data?.totalElements || selections.length
                  }
                  siblings={siblings}
                  appWidgets={appWidgets}
                />
              </Loader>
            </div>
          </CollapsibleDiv>
        </div>
      </div>
    </>
  );
}

export default Item;
