import React from "react";
import { Icon } from "../../Icon/Icon";
import { ReactComponent as IconPicker } from "../../../assets/svg/icon_picker.svg";
import { IconValue } from "./IconSelector";
import styles from "./IconSelectorButton.module.scss";

interface IconSelectorButtonProps {
  expanded: boolean;
  selectedIcon?: IconValue;
}

export const IconSelectorButton = ({
  expanded,
  selectedIcon,
}: IconSelectorButtonProps) => {
  const color = expanded ? "#0072CE" : "#666666";

  return (
    <div className={styles.button}>
      <Icon
        color={color}
        size="1rem"
        type={expanded ? "cheveron-down" : "cheveron-up"}
        className={expanded ? styles.chevronDown : styles.chevronUp}
      />
      {expanded && <div className={styles.arrow}></div>}
      {selectedIcon?.icon && selectedIcon?.color ? (
        <Icon type={selectedIcon.icon} color={selectedIcon.color} size={20} />
      ) : (
        <IconPicker />
      )}
    </div>
  );
};
