import React, { FC, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { TextInput } from "../TextInput/TextInput";
import { Button } from "../Button/Button";
import { Components, ElementType } from "../../qa-slugs";
import styles from "./auth.module.scss";
import AuthErrorMessage from "./AuthErrorMessage";
import { LoginFailureResponse, LoginVM } from "@rtslabs/field1st-fe-common";
import { joinClassNames } from "../../helpers/theme.helpers";

interface FormikLoginProps {
  onLogin: (args: LoginVM) => Promise<void>;
  error?: LoginFailureResponse;
}

const loginSchema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

const FormikLogin: FC<FormikLoginProps> = ({ onLogin, error }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={loginSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        await onLogin(values);
        setSubmitting(false);
      }}
    >
      {({ errors, handleSubmit, handleChange, isSubmitting, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TextInput
              name="username"
              placeholder="Email Address"
              error={errors["username"]}
              onChange={handleChange}
              qa={`${Components.Login}-${ElementType.TextInput}-email`}
            />
            <TextInput
              name="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              error={errors["password"]}
              onChange={handleChange}
              endAdornment={{
                visible: true,
                button: false,
                className: joinClassNames(styles.link, styles.showPasswordLink),
                label: showPassword ? "Hide" : "Show",
                handleClick: () => setShowPassword((sp) => !sp),
                qa: `${Components.Login}-${ElementType.TextInput}-password-show-hide-${ElementType.Button}`,
              }}
              qa={`${Components.Login}-${ElementType.TextInput}-password`}
            />
            {error && <AuthErrorMessage {...error} />}
            <Button
              loading={isSubmitting}
              className={styles.submitButton}
              type="submit"
              qa={`${Components.Login}-${ElementType.Button}-submit`}
            >
              SIGN IN
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormikLogin;
