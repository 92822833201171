import {
  DocumentVm,
  QuestionDTO,
  SafetyRatingWidgetDTO,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import React, { FC, useEffect, useState } from "react";
import { Components } from "../../../../qa-slugs";
import { Icon } from "../../../Icon/Icon";
import Label from "../../../Label/Label";
import { PhotoArea } from "../../../Photos/PhotoArea";
import { RadioButtonOption } from "../../../RadioButtons/RadioButtonsGroup";
import { Ratings } from "../../../Ratings/Ratings";
import { CameraButton } from "../CameraButton/CameraButton";
import CommentField from "../CommentField/CommentField";
import { usePhotos } from "../usePhotos";
import { useQuestion } from "../useQuestion";
import styles from "./RatingField.module.scss";

interface Props {
  question: QuestionDTO;
  appPath?: string;
}

export const RatingField: FC<Props> = ({ question, appPath }) => {
  const { values } = useFormikContext<DocumentVm>();
  const {
    label,
    name,
    handleChangeBasicResponse,
    value,
    error,
    commentValue,
    onChangeComment,
    questionSelections,
    response,
    ...inputProps
  } = useQuestion(question);

  const [photosExpanded, setPhotosExpanded] = useState<boolean>(false);

  const options: RadioButtonOption<string>[] =
    questionSelections?.map((s) => ({
      data: s.id.toString(),
      label: { text: s.title },
      rootId: s.rootId,
    })) || [];

  // find the Safety Rating Parent Question
  const parentQuestion = values.form.sections
    .flatMap((section) =>
      (section.items as QuestionDTO[]).find(
        (item) => item.parentWidgetRootId === question.parentWidgetRootId
      )
    )
    .find(Boolean);

  // find the parent widget to fetch information about this rating
  const currentSafetyRating = values.form.sections.flatMap((section) =>
    section.items.filter((item) => item.rootId === question.parentWidgetRootId)
  )[0] as SafetyRatingWidgetDTO;

  const allowPhotos = parentQuestion?.properties?.allowPhotos;
  const photoHandling = usePhotos(question?.rootId, undefined, appPath);
  const { allowComments, requireCommentsFor } = currentSafetyRating;

  const [showAddCommentButton, setShowAddCommentButton] =
    useState<boolean>(allowComments);
  const [showAddCommentField, setShowAddCommentField] =
    useState<boolean>(false);

  const commentRequired =
    !!response?.answer && requireCommentsFor?.includes(response.answer);

  const commentColor = showAddCommentField ? "#0072CE" : "#666666";

  useEffect(() => {
    if (commentRequired || commentValue) {
      setShowAddCommentField(allowComments && !!value);
    }
  }, [allowComments, commentRequired, commentValue, value]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const option = options?.find((s) => s.data === e.target.value);

    if (option) {
      handleChangeBasicResponse({
        id: response?.id ?? undefined,
        answer: option.label.text,
        associatedId: Number(option.data),
        associatedRootId: option.rootId,
      });
    }
  }

  const handleChangeComment = (comments: string) => {
    onChangeComment(comments);
  };

  const handleClearComment = () => {
    onChangeComment("");
  };

  return (
    <div className={styles.column}>
      <div className={styles.ratings}>
        <div className={styles.labelContainer}>
          <Label className={styles.label}>{label}</Label>
          {/* Comment and Camera Buttons */}
          <div className={styles.row}>
            {showAddCommentButton && (
              <button
                style={{ borderColor: commentColor }}
                className={styles.addCommentBtn}
                type="button"
                onClick={() => setShowAddCommentField(!showAddCommentField)}
              >
                <Icon
                  className={styles.accessory}
                  color={commentColor}
                  size="0.75rem"
                  type={showAddCommentField ? "delete_sign" : "plus_math"}
                />
                <Icon color={commentColor} type="comments" />
              </button>
            )}

            {/* gets allowPhotos using Safety Rating Parent Question */}
            {allowPhotos && (
              <CameraButton
                photoCount={photoHandling.photos.length}
                expanded={photosExpanded}
                onClick={() => setPhotosExpanded(!photosExpanded)}
              />
            )}
          </div>
        </div>
        <div className={styles.ratingContainer}>
          {options.length && (
            <Ratings
              {...inputProps}
              label={""}
              name={name}
              error={!!error}
              options={options}
              value={value}
              onChange={handleChange}
            />
          )}

          {allowComments && !!onChangeComment && (
            <CommentField
              commentValue={commentValue}
              disabled={!showAddCommentField}
              handleClearComment={handleClearComment}
              handleUpdateComment={handleChangeComment}
              name={`${name}_comment`}
              className={styles.comment}
              required={commentRequired}
              response={response}
            />
          )}
        </div>
      </div>

      {/* handles photos by child RATING questions */}
      {allowPhotos && (
        <PhotoArea
          {...photoHandling}
          photosExpanded={photosExpanded}
          qa={`${Components.PhotoArea}-question`}
          type="IMAGES_BELOW"
        />
      )}
    </div>
  );
};
