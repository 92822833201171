import { StoredObjectDTO } from "@rtslabs/field1st-fe-common";
import { useState } from "react";
import { ImageUploader } from "../../helpers/attachment.helpers";
import { useHazardContext } from "../HazardAnalysis/hooks/useHazardContext";
import { updateImageUrl } from "../HazardAnalysis/hazard.helpers";

export interface UploadingPhoto {
  key: number;
  src: string;
  abort: () => void;
  loaded: number;
  total: number;
  file: File;
  error?: string;
}

export interface PhotosHandling {
  handleUploadPhoto: (newFiles: File[]) => Promise<void>[];
}

export function usePhotoWidget(appPath?: string): PhotosHandling {
  const [photosUploading, setPhotosUploading] = useState<UploadingPhoto[]>([]);
  const { setHazardImageUrl, setPhotosUploadInProgress } = useHazardContext();

  function updatePhotoUploading(upload: UploadingPhoto) {
    setPhotosUploading((prev) =>
      prev.map((p) => (p.key === upload.key ? upload : p))
    );
  }

  function handleUploadPhoto(newFiles: File[]) {
    setPhotosUploadInProgress(true);
    let key = photosUploading.values.length;
    const uploads: UploadingPhoto[] = newFiles.map((file) => ({
      key: key++,
      file: file,
      src: URL.createObjectURL(file),
      abort: () => {},
      loaded: 0,
      total: file.size,
    }));

    setPhotosUploading((prev) => prev.concat(uploads));

    return uploads.map((upload) => processIndividualUpload(upload));
  }

  async function processIndividualUpload(upload: UploadingPhoto) {
    try {
      await addPhoto(upload);
    } catch (error) {
      throw new Error(`${upload.file.name} Photo Upload Failed`);
    }
  }

  async function addPhoto(photo: UploadingPhoto) {
    const imageUrl = await uploadPhotoToS3(photo, appPath);
    setHazardImageUrl(updateImageUrl(imageUrl.readableUrl!));
    setPhotosUploadInProgress(false);
  }

  function uploadPhotoToS3(
    upload: UploadingPhoto,
    appPath?: string
  ): Promise<StoredObjectDTO> {
    return new Promise(async (resolve, reject) => {
      try {
        const uploader = new ImageUploader(
          upload.file,
          0, // documentId. Default to 0 since we aren't using it.
          (ev: ProgressEvent<EventTarget>) => {
            updatePhotoUploading({ ...upload, loaded: ev.loaded });
          },
          appPath
        );
        upload.abort = uploader.abort;
        updatePhotoUploading(upload);
        const value = await uploader.startUpload();
        setPhotosUploading((prev) => prev.filter((p) => p.key !== upload.key));
        resolve(value);
      } catch (reason) {
        if (typeof reason === "string") {
          updatePhotoUploading({ ...upload, error: reason });
        }
        reject(reason);
      }
    });
  }

  return {
    handleUploadPhoto,
  };
}
