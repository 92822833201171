import styled from "styled-components";
import { remCalc } from "../../themes/helpers";

export const CommonStyles = {
  FlexDiv: styled.div`
    display: flex;
  `,

  get ColumnDiv() {
    // @ts-ignore TODO circular reference -JA
    return styled(this.FlexDiv)`
      flex-direction: column;
    `;
  },

  get RowDiv() {
    return styled(this.FlexDiv)`
      flex-direction: row;
    `;
  },

  BlockSpan: styled.span`
    display: block;
  `,

  DivWithBottomMargin: styled.div<{ margin: number }>`
    margin-bottom: ${({ margin }) => remCalc(margin)};
  `,
};
