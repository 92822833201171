import React from "react";
import { Icon } from "./Icon";
import { joinClassNames } from "../..//helpers/theme.helpers";
import styles from "./iconBoxStyles.module.scss";

interface IconBoxProps {
  className?: string;
  color?: string;
  type: string;
}

export const IconBox = ({ color, className, type }: IconBoxProps) => {
  return (
    <div className={joinClassNames(styles.iconBox, className)}>
      <Icon className={styles.icon} color={color} type={type} />
    </div>
  );
};
