import React, { FC } from "react";

import { joinClassNames } from "../../../helpers/theme.helpers";

import styles from "./EditProfile.module.scss";

interface Props {
  values?: Array<string>;
  label?: string | null;
  onClick?: () => void;
  locked?: boolean;
  isDesktop?: boolean;
}

export const RenderGroups: FC<Props> = ({
  values,
  label,
  isDesktop,
  locked,
}) => {
  if (!values) return null;
  return (
    <div
      className={joinClassNames(
        styles.renderGroups,
        isDesktop && styles.renderGroups_desktop,
        locked && styles.renderGroups_locked
      )}
    >
      {!isDesktop && <p className={styles.unlockedLabel}>{label}</p>}
      <ul className={styles.groupList}>
        {values.map((x) => (
          <li
            className={joinClassNames(
              styles.groupLabel,
              locked && styles.groupLabel_locked
            )}
            key={x}
          >
            {x}
          </li>
        ))}
      </ul>
    </div>
  );
};
