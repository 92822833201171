import * as yup from "yup";
import { getApiHost } from "../../api/network";
import { PASSWORD_REGEX } from "./constants";

const oauthBaseRoute = `${getApiHost()}/saml/login`;

export const oauthRedirect =
  (provider: string, redirect = "") =>
  () => {
    const safeRedirect = redirect?.startsWith("/login") ? "/" : redirect;
    const afterLoginRedirect = !!redirect
      ? `?redirectAfterLogin=${encodeURIComponent(safeRedirect)}`
      : "";
    const redirectURI = encodeURIComponent(
      `${window.location.origin}${window.location.pathname}${afterLoginRedirect}`
    );
    window.location.href = `${oauthBaseRoute}/${provider}?redirect_uri=${redirectURI}`;
  };

export const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      PASSWORD_REGEX.FULL,
      "Password does not meet standard requirements"
    )
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Password confirmation is required")
    .nullable()
    .oneOf([yup.ref("password"), null], "Passwords do not match"),
});
