import React, { FC } from "react";
import { QAProps } from "../../../../../../../qa-slugs";
import styles from "./PhotoGalleryWidget.module.scss";

export const PhotoGalleryWidget: FC<QAProps> = ({ qa }) => {
  return (
    <div data-testid={qa}>
      <h1 className={styles.title}>Photo Gallery</h1>
      <p className={styles.text}>All added photos will display here</p>
    </div>
  );
};
