import { FBForm, FBItem, FBQuestion, FBSection } from "../../../types";

/** get question from specified question */
export function getItemInSection(
  form: FBForm,
  sectionRootId: number,
  itemRootId: number
): FBQuestion | undefined {
  const sectionIndex = form.sections.findIndex(
    (section: FBSection) => section.rootId === sectionRootId
  );
  const item = form.sections[sectionIndex]?.items?.find(
    (pItem: FBItem) => pItem.rootId === itemRootId
  );
  return item?.type === "QUESTION" ? item : undefined;
}
