import { HazardActionTypes, HazardActions } from "./actions";
import { HazardContextProps } from "../types";

const reducer = (
  state: HazardContextProps,
  action: HazardActions
): HazardContextProps => {
  switch (action.type) {
    case HazardActionTypes.SET_HAZARD_IMAGE_URL:
      return {
        ...state,
        hazardImageUrl: action.value,
      };
    case HazardActionTypes.SET_PHOTOS_UPLOAD_IN_PROGRESS:
      return {
        ...state,
        photosUploadInProgress: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
