import React from "react";
import { QAProps } from "../../../../../../shared/src/qa-slugs";

import * as S from "./styles";

interface DefaultCell extends QAProps {
  color?: string;
  onClick?: () => void;
  value?: any;
}

const DefaultCell = ({ qa, color, onClick, value }: DefaultCell) => {
  return (
    <S.Wrapper data-testid={qa} color={color} onClick={onClick}>
      {value}
    </S.Wrapper>
  );
};

export default DefaultCell;
