import React, { useState, createContext, ReactNode } from "react";
import NewDocumentDrawer from "../Documents/components/NewDocumentDrawer/NewDocumentDrawer";
import {
  API,
  CoreRedux,
  PageOfWorkOrderDTO,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import { createNewDocument } from "shared/src/components/Document/DocumentForm/prefills.helpers";
import { useAppSelector } from "shared/src/redux/reduxHooks";
import { DocumentRouteState } from "../Document/Document";
import { useNavigate } from "react-router-dom";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";

interface State {
  drawerIsVisible: boolean;
  setDrawerIsVisible: (visible: boolean) => void;
}

const BottomNavigationContext = createContext<State>({
  drawerIsVisible: false,
  setDrawerIsVisible: () => {},
});

interface Props {
  children: ReactNode;
}

/**
 * Used to share state with DashboardDrawer (Start New Doc)
 * and the Bottom Navigation component
 */
const BottomNavigationContextProvider = ({ children }: Props) => {
  const [drawerIsVisible, setDrawerIsVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  const user = useAppSelector(CoreRedux.selectUser);
  const prefillAlternates = useAppSelector(CoreRedux.selectPrefillAlternates);

  const formTypesCall = useAPI(API.getAllFormTypes);
  const formsCall = useAPI(API.getAllForms, {
    format: API.FormVmFormat.SUMMARY,
  });

  const formTypes = formTypesCall.data || [];

  const documentsTerm = useGroupTerm(
    "documents",
    "noun",
    "plural",
    "Documents"
  );
  const documentTerm = useGroupTerm("document", "noun", "singular", "Document");
  const rehuddleTerm = useGroupTerm("refocus", "noun", "singular", "Rehuddle");
  const workOrderTerm = useGroupTerm(
    "workOrder",
    "noun",
    "singular",
    "Work Order"
  );

  const terms = {
    document: {
      plural: documentsTerm,
      singular: documentTerm,
    },
    rehuddle: {
      singular: rehuddleTerm,
    },
    workOrder: {
      singular: workOrderTerm,
    },
  };

  async function onCreateDocument(formId: number, workOrderId?: number) {
    const document = await createNewDocument({
      formId,
      owner: user!,
      workOrderId,
      prefillAlternates,
    });
    const state: DocumentRouteState = { document };
    setDrawerIsVisible(false);
    navigate(`/document/${document.id}`, { replace: true, state });
    window.location.reload();
  }

  async function onSearchWorkOrders(
    workOrderId: string
  ): Promise<PageOfWorkOrderDTO> {
    return await API.getWorkOrders({ partialWorkOrderId: workOrderId });
  }

  return (
    <BottomNavigationContext.Provider
      value={{
        drawerIsVisible,
        setDrawerIsVisible,
      }}
    >
      <NewDocumentDrawer
        isVisible={drawerIsVisible}
        setIsVisible={setDrawerIsVisible}
        formTypes={formTypes}
        onCreateDocument={onCreateDocument}
        onSearchWorkOrders={onSearchWorkOrders}
        formTypesLoading={formTypesCall.isLoading}
        forms={formsCall.data || []}
        formsLoading={formsCall.isLoading}
        terms={terms}
      />
      {children}
    </BottomNavigationContext.Provider>
  );
};

export { BottomNavigationContext as default, BottomNavigationContextProvider };
