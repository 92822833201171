import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { ContentWrapper } from "../../../../../../shared/src/components/Wrappers/Wrappers";
import { API, TagDTO } from "@rtslabs/field1st-fe-common";
import TagForm from "../components/TagForm";
import Loader from "shared/src/components/Loader/Loader";

export const AddTag = () => {
  const [tag, setTag] = useState<TagDTO>({
    createdDate: "",
    id: 0,
    name: "",
    useCount: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // get tag id from url params
  const { id: tagId } = useParams<{ id: string }>();

  const fetchTag = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await API.getOETag({ id: Number(id) });
      setTag(res);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tagId) {
      fetchTag(tagId);
    }
  }, []);

  const currentPathName = tagId ? "Edit Tag" : "Add Tag";

  return (
    <ContentWrapper>
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Tag Library",
            href: "/content/tag-library",
          },
          {
            pathName: currentPathName,
            // href: `/content/tag-libraries/tag/${tagId}`,
          },
        ]}
      />
      <Loader loading={isLoading}>
        <TagForm initialValues={tag} />
      </Loader>
    </ContentWrapper>
  );
};
