import styled from "styled-components";

import { TextButton } from "../Button";
import PopperBase from "../Popper";
import Text from "../Text";
import { TextInput } from "../../TextInput/TextInput";
import { toRgba } from "../../../themes/helpers";

export const Link = styled(Text)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
`;

export const Popper = styled(PopperBase)`
  z-index: 10;

  .MuiPaper-root {
    position: relative;
    top: 16px;
    left: -20px;
  }
`;

export const LinkBuilderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 395px;

  &:before {
    content: "";
    position: absolute;
    left: 18px;
    top: -31px;
    width: 20px;
    height: 20px;
    background-color: ${({ theme }) => theme.colors.white};
    border-right: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
    border-bottom: 1px solid ${({ theme }) => toRgba(theme.colors.black, 0.1)};
    transform: rotate(-135deg) translate(-20px, -10px);
  }
`;

export const LinkField = styled(TextInput)`
  margin-right: 12px;
  .input {
    padding: 12px;
  }
`;

export const ApplyButton = styled(TextButton)`
  padding: 10px;
  margin: 0;
`;

export const EditorWrapper = styled.div`
  margin-bottom: 16px;
`;

export const EditorField = styled.div<{ ["data-testid"]?: string }>`
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.masterColors.black}`};
  cursor: text;
  min-height: 200px;
  padding: 16px;

  &.RichEditor-hidePlaceholder {
    .public-DraftEditorPlaceholder-inner {
      display: none;
    }
  }

  &.error {
    border: ${({ theme }) => `2px solid ${theme.colors.error}`};
  }

  .DraftEditor-editorContainer,
  .public-DraftEditorPlaceholder-root {
    z-index: 0;
  }
`;

export const StyleButtonsWrapper = styled.div`
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.masterColors.black}`};
  display: inline-flex;
  margin-bottom: 8px;
  padding: 8px;
`;

export const StyleButton = styled.button<{
  active?: boolean;
  disabled?: boolean;
  ["data-testid"]?: string;
}>`
  background: ${({ active, theme }) => (active ? theme.colors.activeFill : "")};
  color: ${({ active, disabled, theme }) =>
    active
      ? theme.colors.primary
      : disabled
      ? theme.masterColors.mediumGrey
      : theme.colors.black};
  font-size: 20px;
  padding: 2px;
  width: 32px;
  display: flex;
  margin: 0 1px 0 0;
  align-items: center;
  justify-content: center;
  border: 0;

  &:focus {
    outline: ${({ theme }) =>
      `2px solid ${theme.colors.focusIndicatorOutline}`};
  }
`;

export const B = styled.span`
  font-weight: bold;
`;

export const I = styled.span`
  font-style: italic;
`;

export const U = styled.span`
  text-decoration: underline;
`;

export const Strike = styled.span`
  text-decoration: line-through;
`;

export const Icon = styled.i.attrs((props: { name: string }) => ({
  className: `icon-icons8-${props.name}`,
}))<{ name: string }>`
  font-size: 24px;
`;

export const UnlinkIcon = styled(Icon)`
  position: relative;
  top: 3px;
`;
