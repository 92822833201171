import React, { FC, useCallback } from "react";
import styles from "./BackToTop.module.scss";
import { Icon } from "../../Icon/Icon";

interface BackToTopProps {
  qa?: string;
}

export const BackToTop: FC<BackToTopProps> = ({ qa }) => {
  const wrapperDiv = document.getElementById("contentWrapper");

  /**
   * Within the rendered view, you'll need an element
   * with the ID of 'contentWrapper' in order for this to work.
   */
  const onClick = useCallback(() => {
    if (wrapperDiv) {
      return wrapperDiv.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [wrapperDiv]);

  return (
    <div className={styles.wrapper} data-testid={qa} onClick={onClick}>
      <div className={styles.link}>Back to top</div>
      <Icon type="expand_arrow" className={styles.arrowUp} />
    </div>
  );
};

export default BackToTop;
