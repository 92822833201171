import { TagDTO } from "@rtslabs/field1st-fe-common";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Components, ElementType } from "shared/src/qa-slugs";
import styled from "styled-components";
// Resources
import {
  PopperChevron,
  PopperRow,
} from "shared/src/components/clientAdmin/resources/styles";
import { ActionMenu } from "shared/src/components/common/ActionMenu";
import { prettifyTagStatusMenuItem } from "../helpers";

const ChangeTagStatusItem = styled.span`
  color: ${({ theme }) => theme.colors.error};
`;

interface Props {
  onTagStatusUpdate: (tag: TagDTO) => void;
  tag: TagDTO;
}

const TagActionMenu = ({ onTagStatusUpdate, tag }: Props) => {
  const { t } = useTranslation();

  // Archive or Activate tag menu item label
  const statusMenuLabel = useMemo(
    () => prettifyTagStatusMenuItem(tag.archived),
    [tag.archived]
  );

  return (
    <ActionMenu
      buttonLabel={t("common:table.rowMenuButtonLabel")}
      qaBase={`${Components.TagActionMenu}-${ElementType.Button}-${
        tag.id && tag.id
      }_actionMenu`}
    >
      <PopperRow
        data-testid={`${Components.TagActionMenu}-${ElementType.Button}-${
          tag.id && tag.id
        }_updateStatus`}
        onClick={() => onTagStatusUpdate(tag)}
      >
        <ChangeTagStatusItem>{statusMenuLabel}</ChangeTagStatusItem>
        <PopperChevron />
      </PopperRow>
    </ActionMenu>
  );
};

export default TagActionMenu;
