import React from "react";
import { ErrorMessage } from "formik";

import s from "./styles.module.scss";

const FieldErrorMessage = ({ fieldName }: { fieldName: string }) => {
  return (
    <ErrorMessage component="span" className={s.fieldError} name={fieldName} />
  );
};

export default FieldErrorMessage;
