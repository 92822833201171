import React from "react";
import { QAProps } from "../../qa-slugs";
import { PillRadioButton } from "../RadioButtons/RadioButton";
import { RadioButtonsGroup } from "../RadioButtons/RadioButtonsGroup";

interface YesOrNoProps {
  disabled?: boolean;
  error?: string;
  label: string;
  name: string;
  onChange: (data: string) => void;
  required?: boolean;
  value?: boolean;
}

const YesOrNo = ({
  disabled,
  error,
  label,
  name,
  onChange,
  qa,
  required,
  value,
}: YesOrNoProps & QAProps) => {
  return (
    <RadioButtonsGroup
      RadioButtonComponent={PillRadioButton}
      name={name}
      row
      label={label}
      options={[
        { label: { text: "Yes" }, data: "true", disabled },
        { label: { text: "No" }, data: "false", disabled },
      ]}
      value={value?.toString() || ""}
      onChange={onChange}
      qa={qa}
      required={required}
      error={error}
    />
  );
};

export default YesOrNo;
