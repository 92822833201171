import React from "react";

import s from "./styles.module.scss";
import { joinClassNames } from "../../../../../helpers/theme.helpers";

interface Props {
  className?: string;
}

const ValidationStatusIndicator = ({ className }: Props) => {
  return <div className={joinClassNames(s.statusIndicator, className)} />;
};

export default ValidationStatusIndicator;
