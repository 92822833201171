import { Drawer } from "shared/src/components/Drawer/Drawer";

import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import React, { FC } from "react";
import { DocumentSummaryVm, DocumentVm } from "@rtslabs/field1st-fe-common";
import { RelatedDocuments as RelatedDocumentsType } from "../DocumentSidebarDrawer/DocumentSideBarMenu/DocumentSideBarMenu";
import RelatedDocuments from "../DocumentSidebarDrawer/relatedDocuments/RelatedDocuments";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";

interface Props {
  document: DocumentVm | DocumentSummaryVm;
  open: boolean;
  onClose: () => void;
  isDesktop: boolean;
  relatedDocuments?: RelatedDocumentsType;
  terms: {
    document: {
      plural: string;
    };
    rehuddle: string;
  };
}

const RelatedDocumentsSidebar: FC<Props> = ({
  document,
  open,
  onClose,
  isDesktop,
  relatedDocuments,
  terms,
}) => {
  const documentsTerm = conjugateTerm({
    term: terms.document.plural,
    role: "noun",
    modifier: "plural",
    fallback: "Documents",
  });

  const rehuddleTerm = conjugateTerm({
    term: terms.rehuddle,
    role: "noun",
    modifier: undefined,
    fallback: "Rehuddle",
  });

  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      id="DocumentHistory"
      onClose={onClose}
      isOpen={open}
      disableBackdrop
    >
      <>
        {/* Header */}
        <DrawerHeader
          title={`View Related ${documentsTerm}`}
          onClose={onClose}
        />
        {/* Content */}
        <DrawerContentWrapper>
          {document && (
            <RelatedDocuments
              document={document}
              relatedDocuments={relatedDocuments?.data || []}
              loading={relatedDocuments?.isLoading}
              error={relatedDocuments?.hasError}
              terms={{
                documents: documentsTerm,
                rehuddle: rehuddleTerm,
              }}
            />
          )}
        </DrawerContentWrapper>
      </>
    </Drawer>
  );
};

export default RelatedDocumentsSidebar;
