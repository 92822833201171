import React from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps } from "../../qa-slugs";

export interface IconProps extends QAProps {
  className?: string;
  color?: string;
  type: string;
  size?: number | string;
  qa?: string;
}

export const Icon = ({ qa, className, color, type, size }: IconProps) => {
  const fullType = type.startsWith("icon-icons8-")
    ? type
    : `icon-icons8-${type}`;

  return (
    <i
      aria-hidden="true"
      className={joinClassNames(`icon ${fullType} icon-${type}`, className)}
      style={{ color, fontSize: size }}
      data-testid={qa}
    ></i>
  );
};
