import { useRef, useCallback, useEffect } from "react";

/**
 * Stores a map of refs that correspond to the values in some array.
 *
 * For example, to keep track of the buttons that correspond to a list of tabs.
 * Each ref would be to an 'HTMLButtonElement', the key would be the tab ID, and
 * the value would be the tab.
 */
export function useMappedRefs<Ref, Key, Value>(values: Value[]) {
  const keyToRef = useRef<Map<Key, Ref>>(new Map());

  const handleRef = useCallback((key: Key, instance: Ref) => {
    keyToRef.current.set(key, instance);
  }, []);

  useEffect(() => {
    return () => keyToRef.current.clear();
  }, [values]);

  return { handleRef, keyToRef };
}
