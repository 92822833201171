import React, { useCallback, useMemo } from "react";
import { dateToString } from "../../helpers";
import { WorkOrderDTO } from "@rtslabs/field1st-fe-common";

import styles from "./NewForm.module.scss";
import { ListItem } from "shared/src/components/List/ListItem";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

interface Props extends Partial<WorkOrderDTO> {
  iconUrl?: string;
  iconColor?: string;
  isActive?: boolean;
  qa?: string;
  handleSelectWorkOrder?: (value: number) => void;
}

/**
 * Renders a list item for the given WorkOrder.
 * Conditionally renders elements and if @id is missing
 * then we'll render in a disabled state
 */
const WorkOrderListItem = ({
  description,
  workOrderId,
  workLocation,
  dateOfParticipantCreation,
  isActive,
  handleSelectWorkOrder,
  id,
  qa,
}: Props) => {
  const onClick = useCallback(() => {
    if (!id || !handleSelectWorkOrder) return null;
    handleSelectWorkOrder(id);
  }, [handleSelectWorkOrder, id]);

  const date = useMemo(
    () => dateOfParticipantCreation && dateToString(dateOfParticipantCreation),
    [dateOfParticipantCreation]
  );

  return (
    <ListItem
      active={isActive}
      onClick={onClick}
      disabled={!id}
      qa={qa}
      className={styles.workOrderItem}
    >
      <span>{description}</span>
      <div className={joinClassNames(styles.alignCenter, styles.details)}>
        <div className={styles.overflow}>
          {workOrderId && <span>ID: {workOrderId}</span>}
          {workLocation && <span> {workLocation.name}</span>}
        </div>
        {date && <span className={styles.secondaryText}>{date}</span>}
      </div>
    </ListItem>
  );
};

export default WorkOrderListItem;
