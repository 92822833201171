/**
 * Used with React's dangerouslySetInnerHTML prop
 */
export const createMarkup = (content?: string | null) => {
  if (content) {
    return {
      __html: content,
    };
  }

  return {
    __html: "<div />",
  };
};
