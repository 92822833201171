import React, { useMemo } from "react";

import { StyledLinkCaretRight } from "shared/src/components/StyledLinkCaretRight/StyledLinkCaretRight";

import ListSimple from "shared/src/components/ListSimple/ListSimple";
import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import styles from "./FormTypesDrawerContent.module.scss";

import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";

interface Props {
  formTypes: FormTypeDTO[];
  onClose: () => void;
  onSelectFormType: (type: FormTypeDTO) => void;
  title?: string;
}

const FormTypesDrawerContent = ({
  formTypes = [],
  onClose,
  onSelectFormType,
  title = "Start Related Document",
}: Props) => {
  // First 2 form types
  const firstTwoFormTypes = useMemo(() => {
    if (formTypes) {
      const firstTwoFormTypes: FormTypeDTO[] = formTypes.slice(0, 1);
      return firstTwoFormTypes.map((type: FormTypeDTO) => ({
        primary: <span className={styles.navLabel}>Start {type.name}</span>,
        rightSideItem: <StyledLinkCaretRight />,
        onClick: () => onSelectFormType(type),
        showLink: true,
      }));
    }
    return false;
  }, [formTypes.length]);

  // Remaining form types
  const remainingFormTypes = useMemo(() => {
    if (formTypes && formTypes.length > 2) {
      const remainingFormTypes: FormTypeDTO[] = formTypes.slice(2);
      return remainingFormTypes.map((type: FormTypeDTO) => ({
        primary: <span className={styles.navLabel}>Start {type.name}</span>,
        rightSideItem: <StyledLinkCaretRight />,
        onClick: () => onSelectFormType(type),
        showLink: true,
      }));
    }
    return false;
  }, [formTypes.length]);

  return (
    <>
      {/* Header */}
      <DrawerHeader title={title} onClose={onClose} />
      {/* Content */}
      <DrawerContentWrapper>
        <div className={styles.body}>
          <ListSimple
            bottomDivider={!!remainingFormTypes}
            listItems={firstTwoFormTypes || []}
            qa="firstTwoformTypes"
          />
          <ListSimple
            listItems={remainingFormTypes || []}
            qa="remainingformTypes"
          />
        </div>
      </DrawerContentWrapper>
    </>
  );
};

export default FormTypesDrawerContent;
