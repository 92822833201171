import { ClickAwayListener } from "@mui/material";
import React, {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  useRef,
  useState,
} from "react";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import Popper from "shared/src/components/common/Popper";
import { MenuList } from "./MenuList";
import styles from "./TableRowMenu.module.scss";

export type MenuOption = {
  iconType?: string;
  id: string;
  label: string;
  onSelect?: () => void;
  variant?: "error" | "success";
};

interface Props {
  buttonId: string;
  label: string;
  menuId: string;
  menuOptions: (MenuOption | false | undefined)[];
}

const TableRowMenu: FC<Props> = ({
  buttonId,
  label,
  menuOptions: menuOptionsProp,
  menuId,
}) => {
  const menuOptions = menuOptionsProp.filter(
    (option): option is MenuOption => !!option
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [selectedOption, setSelectedOption] = useState<MenuOption>(
    menuOptions[0]
  );
  const listRef = useRef<HTMLUListElement | null>(null);

  const handleChangeSelection = (
    selectedOption: MenuOption,
    wasClicked: boolean
  ) => {
    setSelectedOption(selectedOption);

    if (wasClicked) {
      if (selectedOption.onSelect) {
        selectedOption.onSelect();
      }
      setIsMenuOpen(false);
    }
  };

  const handleClickActions: MouseEventHandler<HTMLButtonElement> = (event) => {
    setMenuAnchor(event.currentTarget);
    setIsMenuOpen((priorIsOpen) => !priorIsOpen);
    listRef.current?.focus();
  };

  const handleClose = () => {
    setMenuAnchor(null);
    setIsMenuOpen(false);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLUListElement> = (event) => {
    switch (event.key) {
      case " ":
      case "Enter":
        if (selectedOption?.onSelect) {
          event.preventDefault();
          handleClose();
          selectedOption.onSelect();
        }
        break;

      case "Escape":
      case "Tab":
        event.preventDefault();
        menuAnchor?.focus();
        handleClose();
        break;
    }
  };

  if (!menuOptions.length) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <ActionButton
          ariaControls={menuId}
          ariaExpanded={isMenuOpen}
          id={buttonId}
          label={label}
          onClick={handleClickActions}
          qa={buttonId}
        />
        <Popper
          arrow
          anchorEl={menuAnchor}
          className={styles.popper}
          open={isMenuOpen}
          placement="left-start"
        >
          <MenuList
            autoFocus={true}
            className={styles.menuList}
            labelId={buttonId}
            onBlur={handleClose}
            onChangeSelection={handleChangeSelection}
            onKeyDown={handleKeyDown}
            options={menuOptions}
            qa={menuId}
            ref={listRef}
            selectedOption={selectedOption}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default TableRowMenu;
