import { DocumentVm, SectionItem } from "@rtslabs/field1st-fe-common";
import React, { createRef, useMemo } from "react";
import { Alert } from "../../../../Alert/Alert";
import Loader from "../../../../Loader/Loader";
import styles from "./HistoryPreview.module.scss";
import { HistoryPreviewState } from "./useHistoryPreview";
import { Formik, FormikProps } from "formik";
import { DocumentFormWrapper } from "../../../../Document/DocumentForm/DocumentFormWrapper";
import { getFlattenedItems } from "../../../../Document/DocumentForm/formEntity.helpers";

function HistoryPreview({
  document,
  fetchError,
  isLoadingForm,
  widget,
  documentTerm,
}: HistoryPreviewState) {
  const flattenedItems: SectionItem[] = useMemo(
    () => getFlattenedItems(document?.form.sections || []),
    [document]
  );

  const itemRefs: Map<number, React.RefObject<HTMLDivElement>> = useMemo(
    () =>
      flattenedItems.reduce((result, fi) => {
        result.set(fi.id, createRef<HTMLDivElement>());
        return result;
      }, new Map<number, React.RefObject<HTMLDivElement>>()),
    [flattenedItems]
  );

  const Widget = widget;

  return (
    <section className={styles.historyPreview}>
      <h2 className={styles.previewTab}>Preview Only</h2>
      <div className={styles.backdrop}>
        {!!fetchError && (
          <Alert
            title="Something went wrong"
            message={fetchError}
            variant="error"
          />
        )}
        <Loader loading={isLoadingForm}>
          {!!document && (
            <Formik initialValues={document} onSubmit={() => {}}>
              {(formikProps: FormikProps<DocumentVm>) => {
                return (
                  <DocumentFormWrapper
                    formikProps={formikProps}
                    formWidget={Widget}
                    handleAutoSync={async () => {}}
                    setFormProgress={() => {}}
                    documentTerm={documentTerm}
                    flattenedQuestions={[]}
                    itemRefs={itemRefs}
                  />
                );
              }}
            </Formik>
          )}
        </Loader>
      </div>
    </section>
  );
}

export default HistoryPreview;
