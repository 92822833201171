import { useContext, CSSProperties } from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";

import { remCalc } from "../../../themes/helpers";

export default function (type: "warning" | "error"): {
  [key: string]: CSSProperties;
} {
  const theme = useContext(ThemeContext);

  return StyleSheet.create({
    notice: {
      background: theme.masterColors[`${type}Fill`],
      padding: "0.5rem",
      border: `1px solid ${theme.masterColors[type]}`,
      borderRadius: "4px",
      fontSize: remCalc(14),
      color: theme.masterColors.darkGrey,
      margin: "0.5rem 0",
    },
  });
}
