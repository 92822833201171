import {
  DefenseDocumentSaveVm,
  API,
  DefenseDTO,
} from "@rtslabs/field1st-fe-common";

export interface CustomDefense {
  description: string;
  files?: File[];
  title: string;
}

export async function addCustomDefense(
  customDefense: CustomDefense
): Promise<DefenseDTO | undefined> {
  const defenseDocuments: DefenseDocumentSaveVm[] = customDefense.files?.length
    ? await uploadDefenseDocuments(0, customDefense.files) // cheat with the defense id (not used)
    : [];

  return await API.saveDefense({
    defense: {
      description: customDefense.description,
      title: customDefense.title,
      defenseDocuments,
    },
  });
}

async function uploadDefenseAttachment(
  defenseId: number,
  file: File
): Promise<DefenseDocumentSaveVm> {
  const { writableUrl } = await API.getNewWritableDefenseDocumentUrl({
    defenseId,
  });
  const uploadResponse = await API.uploadFileToWritableUrl({
    file,
    writableUrl: writableUrl!,
  });
  if (!uploadResponse.ok) {
    throw new Error("Problem uploading attachment");
  }
  return {
    title: file.name,
    url: writableUrl!,
  };
}

/**
 *  uploads new files for a defense
 */
export function uploadDefenseDocuments(
  defenseId: number,
  files: File[]
): Promise<DefenseDocumentSaveVm[]> {
  const uploadedDocuments: DefenseDocumentSaveVm[] = [];

  const uploads = files.map(async (file) => {
    const attachment = await uploadDefenseAttachment(defenseId, file);
    uploadedDocuments.push(attachment);
  });

  return Promise.all(uploads).then(() => {
    return uploadedDocuments;
  });
}
