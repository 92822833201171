import React, { memo } from "react";
import { css } from "aphrodite/no-important";

import FormItem from "./FormItem";
import styles from "./styles";
import { getItemIcon } from "./iconMap";
import { AnyItemParams, GeneratorFn } from "../Create";

export const elements: AnyItemParams[] = [
  {
    name: "Section",
    type: "SECTION",
    subType: "SECTION",
    items: [],
    workflowType: "DRAFT",
  },
  {
    name: "Content Block",
    type: "CONTENT",
    subType: "BLOCK",
    content: "",
  },
  {
    name: "Line Break",
    type: "CONTENT",
    subType: "LINE_BREAK",
  },
  {
    name: "Banner Message",
    type: "CONTENT",
    subType: "BANNER_MESSAGE",
    content: "",
    backgroundColor: "#FFFFFF",
  },
];

interface Props {
  add: GeneratorFn;
  itemSelected: boolean;
}

const Elements = ({ add, itemSelected }: Props) => {
  const S = styles({});
  return (
    <div className={css(S.ItemsContainer)}>
      <span className={css(S.Label)}>Elements</span>
      {elements.map((element) => (
        <FormItem
          disabled={element.type !== "SECTION" && !itemSelected}
          key={element.name}
          name={element.name}
          icon={getItemIcon(element.subType)}
          onClick={() => add(element)}
        />
      ))}
    </div>
  );
};

export default memo(Elements);
