import React, {
  PointerEventHandler,
  PropsWithChildren,
  useRef,
  useState,
} from "react";

import styles from "./styles.module.scss";
import { joinClassNames } from "../../helpers/theme.helpers";

export const Carousel = ({ children }: PropsWithChildren<{}>) => {
  const carousel = useRef<HTMLDivElement | null>(null);
  const [startMouseX, setStartMouseX] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);
  const [isPointerDown, setIsPointerDown] = useState(false);

  const handlePointerDown: PointerEventHandler<HTMLDivElement> = (event) => {
    const target = carousel.current;
    if (event.pointerType !== "mouse" || !target) {
      return;
    }
    setIsPointerDown(true);
    setStartMouseX(event.clientX);
    setStartScrollLeft(target.scrollLeft);
  };

  const handlePointerMove: PointerEventHandler<HTMLDivElement> = (event) => {
    const target = carousel.current;
    if (event.pointerType !== "mouse" || !target || !isPointerDown) {
      return;
    }
    target.setPointerCapture(event.pointerId);
    const deltaX = event.clientX - startMouseX;
    target.scrollLeft = startScrollLeft - deltaX;
  };

  const handlePointerUp: PointerEventHandler<HTMLDivElement> = (event) => {
    const target = carousel.current;
    if (event.pointerType !== "mouse" || !target) {
      return;
    }
    setIsPointerDown(false);
    target.releasePointerCapture(event.pointerId);
  };

  // check if the area is scrollable in order to change cursor and fade
  const hasOverflow =
    !carousel.current ||
    (carousel.current?.scrollWidth || 0) > (carousel.current?.clientWidth || 0);

  return (
    <div
      className={joinClassNames(
        styles.carouselContainer,
        hasOverflow && styles.carouselOverflow
      )}
    >
      <div
        className={styles.carousel}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        ref={carousel}
      >
        {children}
      </div>
      {hasOverflow && <div className={styles.carouselFade} />}
    </div>
  );
};
