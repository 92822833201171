import nlp from "compromise/three";

type Modifier = "past" | "future" | "plural" | "singular";

export const handleNoun = (noun: string, modifier?: Modifier) => {
  const _noun = nlp(noun);

  // If we add more cases, convert to switch -- GK
  if (modifier === "plural") {
    _noun.nouns().toPlural();
  }
  if (modifier === "singular") {
    _noun.nouns().toSingular();
  }
  return _noun.text() || noun;
};

const handleVerb = (verb: string, modifier?: Modifier) => {
  const _verb = nlp(verb);

  if (modifier === "past") {
    _verb.verbs().toPastTense();
  }

  return _verb.text() || verb;
};

interface ConjugateTermArgs {
  term?: string;
  role?: "verb" | "noun";
  modifier?: Modifier;
  fallback?: string;
}

export const conjugateTerm = ({
  term,
  role,
  modifier,
  fallback,
}: ConjugateTermArgs) => {
  let result = fallback || "";

  if (term && term !== "") {
    switch (role) {
      case "noun":
        result = handleNoun(term, modifier);
        break;
      case "verb":
        result = handleVerb(term, modifier);
        break;
      default:
        break;
    }
  }

  return result;
};
