import { getMapModules } from "../../components/common/ArcGisMap/esriMapUtilities";

export async function addSymbolToFeature(feature: __esri.Graphic) {
  const { SimpleFillSymbol, SimpleLineSymbol, SimpleMarkerSymbol } =
    getMapModules();

  switch (feature.geometry.type) {
    case "polygon":
      feature.symbol = new SimpleFillSymbol({
        color: [249, 172, 25, 0.5],
        outline: {
          color: "#F9AC19",
          width: 2,
        },
        style: "solid",
      });
      break;
    case "polyline":
      feature.symbol = new SimpleLineSymbol({
        color: "#F9AC19",
        width: "4px",
        style: "solid",
      });
      break;
    case "point":
      feature.symbol = new SimpleMarkerSymbol({
        color: "#F9AC19",
        outline: {
          width: 0,
        },
        path: "M16 0c-6.187 0-11.2 5.015-11.2 11.2 0 8.001 11.2 20.799 11.2 20.799s11.2-12.8 11.2-20.799c0-6.187-5.015-11.2-11.2-11.2zM16 15.202c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4c0 2.209-1.791 4-4 4z",
        size: 24,
        yoffset: 12,
      });
      break;
    default:
      throw "Unsupported geometry type";
  }
}
