import React from "react";

import Loader from "../../../Loader/Loader";
import { LinkButton } from "../../../Button/Button";
import { Icon } from "../../../Icon/Icon";
import styles from "./moreOptionsLoader.module.scss";

interface Props {
  onLoadMore: () => void;
  isLoadingMore?: boolean;
  isFinalPage?: boolean;
}

const MoreOptionsLoader = ({
  onLoadMore,
  isLoadingMore,
  isFinalPage,
}: Props) => {
  if (isFinalPage) return null;

  return (
    <div className={styles.moreOptionsLoader}>
      <div className={styles.moreOptionsButton}>
        <Loader loading={isLoadingMore}>
          <Icon type="add" className={styles.icon} />
          <LinkButton
            onClick={() => {
              if (!isLoadingMore && onLoadMore) {
                onLoadMore();
              }
            }}
          >
            Load more options
          </LinkButton>
        </Loader>
      </div>
      <div className={styles.borderLine} />
    </div>
  );
};

export default MoreOptionsLoader;
