import React, { useState } from "react";

import { QAProps } from "shared/src/qa-slugs";
import { Text } from "shared/src/components/clientAdmin/styles";
import { TableCell } from "shared/src/components/common/styled/Table";
import DefaultCell from "../../../common/TableUI/DefaultCell";

/**
 * Renders a TableCell with a list of table items that shows only the first two by default,
 * but can be expanded to show the remaining items, and collapsed to hide them again. If there
 * are no table items, returns a "-" instead.
 */
interface ExpandableTableCellProps extends QAProps {
  items: string[] | undefined | null;
}

const ExpandableTableCell = ({ items, qa }: ExpandableTableCellProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const getTableCellContent = () => {
    if (items && items.length > 0) {
      // When collapsed, we only show the first two items
      const itemsToShow = isExpanded ? items : items.slice(0, 2);
      const remainingItemsCount = items.length - 2;
      const buttonText = isExpanded
        ? "Collapse ^"
        : `+ ${remainingItemsCount} others`;

      return (
        <>
          {itemsToShow.map((item) => (
            <DefaultCell key={item} value={item} />
          ))}
          {remainingItemsCount > 0 && (
            <Text
              fontSize={"0.875rem"}
              fontWeight={400}
              textDecoration="underline"
              onClick={handleToggleExpanded}
            >
              {buttonText}
            </Text>
          )}
        </>
      );
    } else {
      return "-";
    }
  };

  return <TableCell qa={qa}>{getTableCellContent()}</TableCell>;
};

export default ExpandableTableCell;
