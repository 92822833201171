import React, { FC, useEffect, useState } from "react";

import {
  API,
  DocumentOEVm,
  DocumentVm,
  OperationalExperiencesWidgetDTO,
  useSelectedTags,
} from "@rtslabs/field1st-fe-common";
import { FormikProps, useField, useFormikContext } from "formik";
import { validateOEs } from "shared/src/components/Document/DocumentForm/validation";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import { selectIsPreview } from "shared/src/redux/document/documentSelectors";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { useAppSelector } from "../../../store/hooks";
import OEHeader from "./components/OEHeader";
import RenderOEs from "./components/RenderOEs";
import { RequiredOEsText } from "./components/RequiredText";
import Reshuffle from "./components/Reshuffle";
import { getPreviewOEs } from "./MockData/DocumentOEs";
import styles from "./OperationalExperiences.module.scss";

export const OES_DISPLAYED = 6;

interface Props {
  widget: OperationalExperiencesWidgetDTO;
  error: boolean;
  includedOes: DocumentOEVm[];
  onUpdateOes: (includedOes: DocumentOEVm[]) => Promise<void>;
}

export const OperationalExperiences: FC<Props> = ({
  widget,
  includedOes,
  onUpdateOes,
  error,
}: Props) => {
  const [displayedOes, setDisplayedOes] = useState<DocumentOEVm[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { values, isSubmitting } = useFormikContext<DocumentVm>();
  const isPreview = useAppSelector(selectIsPreview);
  const [availableOes, setAvailableOes] = useState<DocumentOEVm[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [oeSkipCount, setOeSkipCount] = useState<number>(0);

  const fieldId = widget.id;
  const requiredOEs = widget.numberRequired;
  const OE_PAGE_SIZE = 20;

  /** get list of tags selected by question responses */
  const selectedTags = useSelectedTags(values.form, widget, values.responses);

  const getOes = async (append = false): Promise<void> => {
    if (values.readOnly) return;

    try {
      setLoading(true);
      const res =
        selectedTags.length > 0
          ? isPreview
            ? getPreviewOEs(values)
            : await API.findOEs({
                numberOfOEsToGenerate: OE_PAGE_SIZE,
                oeIds: includedOes.map((oe) => oe.id),
                oeTagIds: selectedTags,
                skipCount: oeSkipCount,
              })
          : [];
      setAvailableOes(res);
      setOeSkipCount((currSkipCount) =>
        append && res.length === OE_PAGE_SIZE ? currSkipCount + OE_PAGE_SIZE : 0
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const oeTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );

  const numIncluded = includedOes.length;

  const reshuffleDisabled =
    availableOes.length <= OES_DISPLAYED || numIncluded == OES_DISPLAYED;

  // create a new array of the included OEs followed by the available OEs
  const updateDisplayedOes = () => {
    const filteredOperationalExperiences = availableOes.filter(
      (oe) => includedOes.findIndex((dOe) => dOe.id === oe.id) === -1
    );
    const updatedOes = [
      ...includedOes,
      ...filteredOperationalExperiences.slice(startIndex, endIndex),
    ];
    setDisplayedOes(updatedOes);
  };

  useEffect(() => {
    // Fetch the OEs once the submission is complete, So we know we can display them based on the responses saved in the system
    if (!isSubmitting) {
      getOes();
    }
  }, [selectedTags]);

  useEffect(() => {
    updateDisplayedOes();
  }, [availableOes, pageNumber, includedOes]);

  // variables to determine how many OEs to display
  const pageLength = OES_DISPLAYED - includedOes.length;
  const startIndex = (pageNumber - 1) * pageLength;
  const endIndex = startIndex + pageLength;

  /** we've reached the end of the deck if the last OE in store is currently displayed AND
   the most recent OEs fetch returned fewer OEs than the default number returned by the API (OES_COUNT) */
  const endOfDeck =
    endIndex >= availableOes.length && availableOes.length < OE_PAGE_SIZE;

  async function reshuffleOes() {
    const endOfOes = endIndex + includedOes.length === availableOes.length;

    // if we've reached the end of the OEs from the api, go back to page 1
    if (endOfDeck) {
      setPageNumber(1);
    } else if (endOfOes) {
      // if we've run out of stored OEs, fetch the next set of document OEs
      await getOes(true);
      // go back to page 1
      setPageNumber(1);
    } else {
      // go to the next page
      setPageNumber(pageNumber + 1);
    }
  }

  async function toggleInclude(
    oe: DocumentOEVm,
    included?: boolean
  ): Promise<void> {
    let updatedOes = [...includedOes];
    if (included) {
      updatedOes.push(oe);
    } else {
      updatedOes = updatedOes.filter((iOe) => iOe.id !== oe.id);
    }
    await onUpdateOes(updatedOes);
  }

  const validate = () =>
    validateOEs(
      values.operationalExperiences || [],
      requiredOEs,
      !!availableOes &&
        availableOes.length > 0 &&
        values.submissionType === "SUBMIT",
      oeTerm
    );

  const [oeField] = useField({ name: `${fieldId}`, validate });

  return (
    <div {...oeField} className={error ? styles.oeWrapperError : ""}>
      <OEHeader
        availableCount={availableOes.length}
        requiredCount={requiredOEs}
        oeTerm={oeTerm}
      />
      {availableOes.length > 0 && (
        <RequiredOEsText
          includedCount={numIncluded}
          requiredCount={requiredOEs}
        />
      )}
      <RenderOEs
        loading={loading && availableOes.length < OES_DISPLAYED}
        toggleInclude={toggleInclude}
        displayedOEs={displayedOes}
        includedOes={includedOes}
      />
      {availableOes.length > 0 && (
        <>
          <RequiredOEsText
            includedCount={numIncluded}
            requiredCount={requiredOEs}
          />
          <Reshuffle
            loading={loading}
            reshuffle={reshuffleOes}
            disabled={reshuffleDisabled}
          />
        </>
      )}
      {error && (
        <ErrorText>
          You must include at least {requiredOEs} {oeTerm}
        </ErrorText>
      )}
    </div>
  );
};
