import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import NewForm from "../NewForm/NewForm";
import styles from "./NewDocumentDrawer.module.scss";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import {
  FormSummaryVm,
  FormTypeDTO,
  PaginatedResponse,
  WorkOrderDTO,
} from "@rtslabs/field1st-fe-common";
import { DocumentScreenTerms } from "../../types";
import { Components, ElementType } from "shared/src/qa-slugs";

interface Props {
  formTypes: FormTypeDTO[];
  onCreateDocument: (formId: number, workOrderId?: number) => Promise<void>;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  initialFormType?: FormTypeDTO;
  onSearchWorkOrders: (
    workOrderId: string
  ) => Promise<PaginatedResponse<WorkOrderDTO>>;
  formTypesLoading: boolean;
  forms: FormSummaryVm[];
  formsLoading: boolean;
  terms: DocumentScreenTerms;
}

/**
 * Contains Drawer elements used to start a new Document
 */
const NewDocumentDrawer: FC<Props> = ({
  formTypes,
  onCreateDocument,
  isVisible,
  setIsVisible,
  initialFormType,
  onSearchWorkOrders,
  formTypesLoading,
  forms,
  formsLoading,
  terms,
}) => {
  const handleClose = () => setIsVisible(false);
  const isDesktop = useMediaQuery({
    query: `(min-device-width: ${scssVariables.desktop})`,
  });
  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      isOpen={isVisible}
      id="NewDocumentDrawer"
      onClose={handleClose}
      qa={`${Components.MyDocuments}-${ElementType.Drawer}-create-new`}
    >
      <div className={styles.drawerContent}>
        <NewForm
          title="New Document"
          handleClose={handleClose}
          formTypes={formTypes}
          onCreateDocument={onCreateDocument}
          onSearchWorkOrders={onSearchWorkOrders}
          initialFormType={initialFormType}
          formTypesLoading={formTypesLoading}
          forms={forms}
          formsLoading={formsLoading}
          terms={terms}
        />
      </div>
    </Drawer>
  );
};

export default NewDocumentDrawer;
