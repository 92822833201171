import React, { FC } from "react";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { Components, Page } from "shared/src/qa-slugs";
import { PermissionTable } from "./PermissionTable";
import s from "./styles.module.scss";

export const Permissions: FC = () => {
  return (
    <>
      {/* Breadcrumb Nav */}
      <div id="mainContent">
        <Breadcrumbs
          paths={[
            {
              pathName: "Settings",
            },
            {
              pathName: "Permissions",
              href: "/settings/permissions",
            },
          ]}
        />
      </div>

      {/* Title */}
      <div className={s.titleWrapper}>
        <h1
          className={s.h1}
          data-testid={`${Page.Desktop}-${Components.Permissions}`}
        >
          Permissions
        </h1>
      </div>

      <PermissionTable />
    </>
  );
};
