import { SortDirection } from "@rtslabs/field1st-fe-common";
import React from "react";
import ReactSelect from "react-select";
import { Icon } from "../../../../Icon/Icon";
import Label from "../../../../Label/Label";
import { joinClassNames } from "../../../../../helpers/theme.helpers";
import Tooltip from "../../../../Tooltip/Tooltip";
import styles from "./HistorySortDropdown.module.scss";
import {
  getHistorySortLabel,
  HistorySortOption,
  HistorySortSelectOption,
} from "./HistorySortDropdownOptions";
import { useTranslation } from "react-i18next";

export interface HistorySortDropdownProps {
  sortOption: HistorySortSelectOption;
  sortDirection: SortDirection;
  onSortChange(newValue: HistorySortSelectOption): void;
  onSortDirectionChange(newDirection: SortDirection): void;
}

const HISTORY_SORT_BY = "history-sort-by";

function HistorySortDropdown({
  onSortChange,
  onSortDirectionChange,
  sortOption,
  sortDirection,
}: HistorySortDropdownProps) {
  const { t } = useTranslation("formBuilder");
  return (
    <div className={styles.sortContainer}>
      <Label
        className={styles.sortLabel}
        containerClassName={styles.sortLabelWrapper}
        htmlFor={HISTORY_SORT_BY}
      >
        Sort by:
      </Label>
      <ReactSelect
        inputId={HISTORY_SORT_BY}
        className={styles.sortDropdown}
        options={[
          {
            label: getHistorySortLabel(HistorySortOption.PublishedDate),
            value: HistorySortOption.PublishedDate,
          },
          {
            label: getHistorySortLabel(HistorySortOption.VersionName),
            value: HistorySortOption.VersionName,
          },
        ]}
        value={sortOption}
        onChange={(newValue) => {
          onSortChange(newValue!);
        }}
      />
      <Tooltip
        arrow={true}
        placement="top"
        title={t("history.sortDirectionButtonLabel")}
      >
        <button
          className={joinClassNames(
            styles.sortDirectionBox,
            sortDirection === "asc"
              ? styles.sortDirectionBoxAsc
              : styles.sortDirectionBoxDesc
          )}
          onClick={() =>
            onSortDirectionChange(sortDirection === "asc" ? "desc" : "asc")
          }
          type="button"
        >
          <Icon type={"tab"} className={styles.sortDirectionIcon} />
        </button>
      </Tooltip>
    </div>
  );
}

export default HistorySortDropdown;
