import React, { useEffect, useMemo, useState } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { API, DocumentVm, useAPI } from "@rtslabs/field1st-fe-common";
import { CircularProgress } from "@mui/material";
import { Components, Page } from "shared/src/qa-slugs";
import { Formik } from "formik";
import { DocumentSectionProvider } from "shared/src/components/Document/DocumentSection/DocumentSectionProvider";
import { Header } from "../../navigation/Header/Header";
import { Nav } from "../../navigation/Nav/Nav";
import { validate } from "shared/src/components/Document/DocumentForm/validation";
import { DocumentToasts } from "shared/src/components/Document/DocumentToasts";
import Widget from "../Widget";
import { usePutDocument } from "../usePutDocument";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import DocumentSidebarDrawer from "../DocumentSidebarDrawer";
import AiChatDrawer from "../AiChatDrawer/AiChatDrawer";
import AiChatButton from "../AiChatDrawer/AiChatButton";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { ReadOnlyDoc } from "../../../../../shared/src/components/Document/ReadOnly/ReadOnlyDoc";
import DashboardBottomNavigation from "../../navigation/DashboardBottomNavigation";

export const DocumentSection = () => {
  const params = useParams<Params>();
  const navigate = useNavigate();
  const [showSidebarDrawer, setShowSidebarDrawer] = useState<boolean>(false);
  const [showChatDrawer, setShowChatDrawer] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [document, setDocument] = useState<DocumentVm>();
  const { handlePutDocument, submissionSuccessful } = usePutDocument();
  const documentTerm = useGroupTerm("document", "noun", "singular", "Document");
  const documentsTerm = useGroupTerm(
    "documents",
    "noun",
    "plural",
    "Documents"
  );
  const documentId = parseInt(params.documentId!);

  const documentCall = useAPI(API.getDocumentById, { id: documentId });
  const loadingDoc = !documentCall.data && documentCall.isLoading;

  const qaBase = `${Page.Desktop}-${Components.DocumentSection}`;

  const onPutDocument = async (values: DocumentVm): Promise<void> => {
    const newValues = await handlePutDocument(values);
    if (newValues) setDocument(newValues);
  };

  const returnToDocs = (): void => {
    return navigate("/documents");
  };

  const AppHeader = () => (
    <Header breakpointClassName={scssVariables.breakpointLarge} />
  );

  useEffect(() => {
    if (documentCall.data) setDocument(documentCall.data);
  }, [documentCall.data]);

  return (
    <>
      {loadingDoc && <CircularProgress />}

      {/* TEMP - TODO: Section Design ReadOnly CORE-1541 to replace this */}
      {!loadingDoc && document && document.readOnly && (
        <ReadOnlyDoc document={document} />
      )}

      {!loadingDoc && document && !document.readOnly && (
        <>
          <DocumentSidebarDrawer
            onClose={() => setShowSidebarDrawer(false)}
            open={showSidebarDrawer}
            selectedDocument={document}
          />
          {/* <AiChatDrawer
            open={showChatDrawer}
            onClose={() => setShowChatDrawer(false)}
            selectedDocument={document}
          /> */}
          <Formik
            initialValues={document}
            onSubmit={onPutDocument}
            validate={validate}
            enableReinitialize
            validateOnBlur={false}
          >
            {({ values, isValid }) => {
              const hasErrors = useMemo(
                () => !isValid || !!error,
                [isValid, error]
              );
              return (
                <>
                  <DocumentToasts
                    documentTerm={documentTerm}
                    documentsTerm={documentsTerm}
                    hasErrors={hasErrors}
                    error={error}
                    submissionSuccessful={submissionSuccessful}
                    initialFormValues={values}
                    formValues={values}
                    returnToDocs={returnToDocs}
                    setError={setError}
                    autoClose={5000}
                  />
                  <DocumentSectionProvider
                    documentTerm={documentTerm}
                    onPutDocument={onPutDocument}
                    appHeader={AppHeader}
                    appNavBottom={DashboardBottomNavigation}
                    appNav={Nav}
                    formWidget={Widget}
                    setShowSidebarDrawer={setShowSidebarDrawer}
                    qaBase={qaBase}
                  />
                </>
              );
            }}
          </Formik>
          {/* <AiChatButton handleClick={() => setShowChatDrawer(true)} useTab /> */}
        </>
      )}
    </>
  );
};
