// @TODO future? - convert attributes to {label: "", type: ""} objects,
// then use type to filter possible values by field type and use label to display in dropdown

const addressDto = {
  addressString: "Address String",
  city: "City",
  id: "ID",
  line1: "Line 1",
  line2: "Line 2",
  line3: "Line 3",
  state: "State",
  zip: "Zip",
};

const geoLocationDto = {
  gpsString: "GPS String",
  latitude: "Latitude",
  longitude: "Longitude",
  // valid: "boolean",
};

const workLocationDto = {
  // dataSourceId: "Data Source ID",
  emergencyAddress: addressDto,
  geolocation: geoLocationDto,
  id: "ID",
  // lastModifiedDate: "Last Modified Date",
  locationId: "Location ID",
  // locationInformationUrl: "Location Information URL",
  // locationType: "Location Type",
  name: "Name",
  nickname: "Nickname",
  physicalAddress: addressDto,
  // preferUsersLocation: "Prefer User's Location",
  // softDeleted: "Soft Deleted",
  // source: "Source",
  get supervisor() {
    return participantDto;
  },
};

const participantDto = {
  clientGroups: [
    {
      dataSourceId: "Data Source ID",
      detail: "Detail",
      externalGroupId: "External Group ID",
      id: "ID",
      // lastModifiedDate: "Last Modified Date",
      name: "Name",
      // softDeleted: "Soft Deleted",
      // source: "Source"
    },
  ],
  // dataSourceId: "Data Source ID",
  email: "Email",
  firstName: "First Name",
  fullName: "Full Name",
  id: "ID",
  // lastModifiedDate: "Last Modified Date",
  lastName: "Last Name",
  name: "Name",
  nickname: "Nickname",
  participantType: "Participant Type",
  primaryGroupId: "Participant Group ID",
  // softDeleted: "Soft Deleted",
  // source: "Source",
  get supervisor() {
    return participantDto;
  },
  teams: [
    {
      dataSourceId: "Data Source ID",
      description: "Description",
      externalId: "External ID",
      id: "ID",
      name: "Name",
      // softDeleted: "Soft Deleted",
      // source: "Source"
    },
  ],
  workLocation: workLocationDto,
};

export const workOrderDto = {
  // dataSourceId: "Data Source ID",
  // dateOfParticipantCreation: "Date of Participant Creation",
  description: "Description",
  geolocation: geoLocationDto,
  gpsString: "GPS String",
  // id: "ID",
  // lastModifiedDate: "Last Modified Date",
  participantCreatedBy: participantDto,
  participants: [participantDto],
  // softDeleted: "Soft Deleted",
  // source: "Source",
  supervisor: participantDto,
  workLocation: workLocationDto,
  workOrderId: "number",
};
