import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Drawer } from "shared/src/components/Drawer/Drawer";
import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import { Components } from "shared/src/qa-slugs";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import styles from "./DocumentsFiltersDrawer.module.scss";
import { Back } from "shared/src/assets/svg/back";
import { Button, SecondaryButton } from "shared/src/components/Button/Button";
import {
  API,
  DocumentRequestParameters,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";
import { SearchFilters } from "./SearchFilters";
import { CircularProgress } from "@mui/material";
import { AppliedFiltersProps } from "./types";

interface DocumentsFiltersDrawerProps {
  onClose: () => void;
  open: boolean;
  filters: API.GetDocumentsArgs;
  formTypes: Array<FormTypeDTO>;
  onFilterChange: (params: Partial<DocumentRequestParameters>) => void;
  onFilterReset: () => void;
  resultsCount: number;
  isLoading: boolean;
  appliedFilters: AppliedFiltersProps;
  setAppliedFilters: Dispatch<SetStateAction<AppliedFiltersProps>>;
}

export const DocumentsFiltersDrawer: FC<DocumentsFiltersDrawerProps> = ({
  open,
  onClose,
  filters,
  formTypes,
  onFilterChange,
  onFilterReset,
  resultsCount,
  isLoading,
  appliedFilters,
  setAppliedFilters,
}) => {
  const isTabletDesktop = useMediaQuery({
    query: `(min-width: ${scssVariables.minTablet})`,
  });

  useEffect(() => {
    // When a user resize the browser (or flip tablet screen), it checks
    // to see if drawer needs to close
    isTabletDesktop && open && onClose();
  }, [isTabletDesktop]);

  return (
    <>
      <Drawer
        isOpen={open}
        onClose={onClose}
        anchor={"right"}
        id="DocumentSidebar"
        qa={`${Components.Document}filter-drawer`}
      >
        <DrawerContentWrapper>
          <div className={styles.filterDrawer}>
            <h6 className={styles.header}>
              <span onClick={onClose}>
                <Back className={styles.back} />
              </span>{" "}
              Search Filter
            </h6>
            <div className={styles.filterOptionsDrawer}>
              <SearchFilters
                filters={filters}
                formTypes={formTypes}
                onFilterChange={onFilterChange}
                className={styles.mobilePhone}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
              />
            </div>
            <div className={styles.filterButtonsContainer}>
              <Button className={styles.showResults} onClick={onClose}>
                Show Results (
                {isLoading && (
                  <CircularProgress className={styles.loadingCount} size={14} />
                )}
                {!isLoading && resultsCount})
              </Button>
              <SecondaryButton
                className={styles.reset}
                onClick={() => {
                  onClose();
                  onFilterReset();
                }}
              >
                Reset
              </SecondaryButton>
            </div>
          </div>
        </DrawerContentWrapper>
      </Drawer>
    </>
  );
};
