import React, { KeyboardEvent, useState } from "react";

interface SelectedTabState {
  selectedTabIndex: number;
  selectTab(index: number): void;
  handleKeyDown(event: KeyboardEvent<HTMLButtonElement>): void;
}

export interface HasFormId<T> {
  formId: T;
}

export function useSelectTab<T>(
  tabs: HasFormId<T>[],
  tabIdsToButtonElements: React.MutableRefObject<Map<T, HTMLButtonElement>>
): SelectedTabState {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  function selectTab(index: number): void {
    setSelectedTabIndex(index);
    const newTab = tabs[index];
    const tab = tabIdsToButtonElements.current.get(newTab.formId);
    tab?.focus();
  }

  function handleKeyDown(event: KeyboardEvent<HTMLButtonElement>): void {
    switch (event.key) {
      case "ArrowLeft":
        if (selectedTabIndex > 0) {
          event.preventDefault();
          selectTab(selectedTabIndex - 1);
        }
        break;

      case "ArrowRight":
        if (selectedTabIndex < tabs.length - 1) {
          event.preventDefault();
          selectTab(selectedTabIndex + 1);
        }
        break;

      case "End":
        event.preventDefault();
        selectTab(tabs.length - 1);
        break;

      case "Home":
        event.preventDefault();
        selectTab(0);
        break;
    }
  }

  return {
    selectedTabIndex,
    selectTab,
    handleKeyDown,
  };
}
