import {
  ActivationStatusVm,
  API,
  ParticipantSummary,
  UserStatus,
} from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import Modal from "shared/src/components/common/Modal";
import { DeleteUserConfirmation } from "./modalContent/DeleteUserConfirmation";
import { DeleteUserFailure } from "./modalContent/DeleteUserFailure";
import { DeleteUserSuccess } from "./modalContent/DeleteUserSuccess";

interface Props {
  anchorUser?: ParticipantSummary;
  open: boolean;
  onClose: () => void;
}

export const DeleteUserModal: FC<Props> = ({ anchorUser, onClose, open }) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<"CONFIRM" | "SUCCESS" | "FAIL">("CONFIRM");
  return (
    <Modal
      border="none"
      open={open}
      handleClose={onClose}
      height="auto"
      width="652px"
      padding="24px 23px"
      content={
        <>
          {step == "CONFIRM" && (
            <DeleteUserConfirmation
              displayName={anchorUser?.fullName ?? ""}
              loading={loading}
              onCancel={onClose}
              onSubmit={() => {
                const payload: ActivationStatusVm = anchorUser
                  ? {
                      isActive: false, // Function sets it to opposite
                      participantId: anchorUser.id,
                      userId: anchorUser.userId,
                      status: UserStatus.DELETED,
                    }
                  : {};
                setLoading(true);
                API.changeActivationStatus({ vm: payload })
                  .then(() => {
                    setStep("SUCCESS");
                  })
                  .catch(() => setStep("FAIL"))
                  .finally(() => setLoading(false));
              }}
            />
          )}
          {step === "SUCCESS" && <DeleteUserSuccess onClose={onClose} />}
          {step === "FAIL" && <DeleteUserFailure onClose={onClose} />}
        </>
      }
    />
  );
};
