import {
  API,
  BuilderFormSummaryVM,
  CoreRedux,
  SortParams,
  useAPI,
  WorkflowType,
} from "@rtslabs/field1st-fe-common";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import Loader from "../../Loader/Loader";
import { SearchBar } from "../../SearchBar/SearchBar";
import { FilterSelect } from "../../Select/FilterSelect";
import BackToTop from "../../TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "../../TableUI/Pagination/Pagination";
import { RowsPerPage } from "../../TableUI/RowsPerPage/RowsPerPage";
import { TableSummary } from "../../TableUI/TableSummary/TableSummary";
import { ContentWrapper } from "../../Wrappers/Wrappers";
import { Components, ElementType, Page } from "../../../qa-slugs";
import useDebounce from "../../../util/hooks/useDebounce";
import { Button } from "../../Button/Button";
// import { formTemplateWriteRoles } from "../../routes/constants/permissionSets";
import WriteContent from "../../common/permissions/WriteContent";
import { H1 } from "../../clientAdmin/styles";
import { ConfirmationModal } from "./ConfirmationModal";
import { getWorkflowTypes, statusOptions } from "./formConstants";
import { FormListModal } from "./FormListModal";
import { FormListTable } from "./FormListTable";
import { FormListStyles as S } from "./styles";

interface FormListProps {
  accessRoles: any;
}

function FormList({ accessRoles }: FormListProps) {
  const formTemplateWriteRoles = accessRoles; // it should be same access as menu item
  const navigate = useNavigate();
  const accessibleGroups = useSelector(CoreRedux.getUserGroups);

  const [searchParams, setSearchParams] = useSearchParams();
  const formTypeParam = searchParams.get("formType");
  const groupIdParam = searchParams.get("group");
  const searchQueryParam = searchParams.get("query");
  const statusParam = searchParams.get("status");
  const initialFormType = formTypeParam ? parseInt(formTypeParam) : undefined;
  const initialGroupId = groupIdParam ? parseInt(groupIdParam) : undefined;
  const initialStatusIndex = statusParam ? parseInt(statusParam) : undefined;
  const initialQuery = searchQueryParam ? searchQueryParam : undefined;

  /* loading statuses */
  const [workflowTypeLoading, setWorkflowTypeLoading] =
    useState<boolean>(false);

  /* modals */
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [modalDesiredWorkflowType, setModalDesiredWorkflowType] = useState<
    WorkflowType | undefined
  >(undefined);

  /* form and sort data */
  const [currSummary, setCurrSummary] = useState<
    BuilderFormSummaryVM | undefined
  >();
  const [sort, setSort] = useState<SortParams>(["name", "asc"]);
  const [groupId, setGroupId] = useState<number | undefined>(initialGroupId);
  const [formType, setFormType] = useState<number | undefined>(initialFormType);
  const [statusIndex, setStatusIndex] = useState<number | undefined>(
    initialStatusIndex
  );
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [query, setQuery] = useState<string | undefined>(initialQuery);

  const debouncedSetQuery = useDebounce({
    method: (value: string) => {
      setQuery(value);
      setQueryParam("query", value);
    },
    delayAmount: 1000,
  });

  /** Format group filter options */
  const groupOptions = accessibleGroups.map((g) => ({
    label: g.name || "No group name",
    value: g.id,
  }));

  // TODO getAndSetForms is always one state mutation behind, can be improved -jA
  /** Search for forms using sort state and possible supplied query */
  const formsCall = useAPI(API.getFormTemplates, {
    sort: `${sort[0]},${sort[1]}`,
    page,
    size,
    formTypeId: formType ? [formType] : undefined,
    clientGroupIds: groupId ? [groupId] : undefined,
    workflowTypes: getWorkflowTypes(statusIndex || 0),
    query,
  });

  /**
   * Get a list of all available form types for creating a new form
   */
  const formTypesCall = useAPI(API.getAllFormTypes);

  /** Map form types to options for filters + FormListModal */
  const formTypeOptions = (formTypesCall.data || []).map((type) => ({
    label: type.name,
    value: type.id,
  }));

  /**
   * Clone a form with given id
   * @param id
   */
  const clone = async (id: number) => {
    const res = await API.cloneForm({ formId: id });
    navigate(`/forms/form/${res.id}`);
  };

  const setQueryParam = (key: string, value: string | undefined) => {
    if (value) {
      searchParams.set(key, value);
      setSearchParams(searchParams, { replace: true });
    }
  };

  /** Deactivate a form corresponding with formId in state */
  async function setWorkflowType(workflowType: WorkflowType) {
    const formId = currSummary?.id || -1;
    if (formId > -1) {
      setWorkflowTypeLoading(true);
      try {
        await API.updateFormStatus({ formId, status: workflowType });
        formsCall.refresh();
      } catch (e) {
        console.error(e);
      }
    }
    setWorkflowTypeLoading(false);
    setModalDesiredWorkflowType(undefined);
    return false;
  }

  return (
    <ContentWrapper id="mainContent">
      <FormListModal
        qaBase={`${Components.FormList}`}
        modalOpen={createModalOpen}
        closeModal={() => setCreateModalOpen(false)}
        formTypeOptions={formTypeOptions}
        formTypes={formTypesCall.data || []}
      />
      <ConfirmationModal
        qaBase={`${Components.FormList}-${modalDesiredWorkflowType}`}
        modalOpen={!!modalDesiredWorkflowType}
        desiredWorkflowType={
          modalDesiredWorkflowType || currSummary?.workflowType || "DRAFT"
        }
        closeModal={() => setModalDesiredWorkflowType(undefined)}
        setWorkflowType={setWorkflowType}
        loading={workflowTypeLoading}
      />
      <Breadcrumbs paths={[{ pathName: "Forms" }]} />
      <S.TitleWrapper>
        <H1 data-testid={`${Page.Desktop}-${Components.FormList}`}>Forms</H1>
        <WriteContent roles={formTemplateWriteRoles}>
          <Button
            qa={`${Components.FormList}-${ElementType.Button}-create-new-form`}
            onClick={() => setCreateModalOpen(true)}
          >
            CREATE NEW FORM
          </Button>
        </WriteContent>
      </S.TitleWrapper>
      <S.FilterWrapper>
        <S.SelectWrapper style={{ minWidth: "185px" }}>
          <FilterSelect
            qa={`${Components.FormList}-${ElementType.SelectInput}-groupsFilter`}
            name="groupId"
            value={groupId}
            label=""
            placeholder="All Groups"
            options={[{ value: 0, label: "All Groups" }, ...groupOptions]}
            onChange={(option) => {
              setPage(0);
              setGroupId(option?.value);
              setQueryParam("group", option?.value?.toString());
            }}
          />
        </S.SelectWrapper>
        <S.SelectWrapper style={{ minWidth: "215px" }}>
          <FilterSelect
            qa={`${Components.FormList}-${ElementType.SelectInput}-formTypeFilter`}
            name="formType"
            value={formType}
            label=""
            placeholder="All Document Types"
            options={[
              { value: 0, label: "All Document Types" },
              ...formTypeOptions,
            ]}
            onChange={(option) => {
              setPage(0);
              setFormType(option?.value);
              setQueryParam("formType", option?.value?.toString());
            }}
          />
        </S.SelectWrapper>
        <S.SelectWrapper style={{ minWidth: "215px" }}>
          <FilterSelect
            qa={`${Components.FormList}-${ElementType.SelectInput}-statusesFilter`}
            name="status"
            value={statusIndex}
            label=""
            placeholder="All Active Statuses"
            options={statusOptions}
            onChange={(option) => {
              setStatusIndex(option?.value);
              setQueryParam("status", option?.value?.toString());
              setPage(0);
            }}
          />
        </S.SelectWrapper>
        <SearchBar
          qa={`${Components.FormList}-${ElementType.TextInput}-searchBar`}
          initialQuery={initialQuery}
          onSearch={debouncedSetQuery}
        />
      </S.FilterWrapper>
      <Loader loading={formsCall.isLoading}>
        <TableSummary
          pageSize={size}
          currentPage={page}
          totalElements={formsCall.data?.totalElements}
          ofWhat="forms"
          exports={["print", "xls", "csv"]}
          onExport={API.downloadFormTemplates}
          exportParams={{
            formTypeId: formType ? [formType] : undefined,
            clientGroupIds: groupId ? [groupId] : undefined,
            query,
          }}
        />
        <FormListTable
          sort={sort}
          setSort={setSort}
          forms={formsCall.data?.content || []}
          onCreateNew={setCreateModalOpen}
          setDesiredWorkflowType={setModalDesiredWorkflowType}
          setCurrSummary={setCurrSummary}
          cloneForm={clone}
          formTemplateWriteRoles={formTemplateWriteRoles}
        />
      </Loader>
      {!formsCall.isLoading && (
        <PaginationWrapper>
          <RowsPerPage
            qaBase={`${Components.FormList}-view`}
            pageSize={size || 10}
            onClick={(size) => {
              setPage(0);
              setSize(size);
            }}
          />
          <Pagination
            qaBase={`${Components.FormList}-pagination`}
            currentPage={page}
            totalPages={formsCall.data?.totalPages}
            onClick={(page) => setPage(page)}
          />
          <BackToTop
            qa={`${Components.FormList}-${ElementType.Link}-backToTop`}
          />
        </PaginationWrapper>
      )}
    </ContentWrapper>
  );
}

export default FormList;
