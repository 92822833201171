import React from "react";

import styles from "./ShareDocumentSidebarContent.module.scss";
import DrawerContentWrapper from "../../../../Drawer/DrawerContentWrapper/DrawerContentWrapper";
import ShareForm from "../../../../ShareForm/ShareForm";
import DrawerHeader from "../../../../Drawer/DrawerHeader";

interface Props {
  onShareDocument: (participantIds: number[]) => Promise<void>;
  toggleShareDocumentVisibility: () => void;
}

const ShareDocumentSidebarContent = ({
  onShareDocument,
  toggleShareDocumentVisibility,
}: Props) => {
  return (
    <>
      {/* Header */}
      <DrawerHeader title="Share" onClose={toggleShareDocumentVisibility} />
      {/* Content */}
      <DrawerContentWrapper>
        <p className={styles.sidebarDrawerBody}>
          Do you want to invite a coworker or manager from your company? When
          sharing, they’ll be allowed to view the document and all of your
          selections.
        </p>
        <ShareForm
          onShareDocument={onShareDocument}
          closeDrawer={toggleShareDocumentVisibility}
        />
      </DrawerContentWrapper>
    </>
  );
};

export default ShareDocumentSidebarContent;
