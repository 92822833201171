import React, { FC } from "react";
import { ElementType, QAProps } from "../../qa-slugs";
import styles from "./ErrorText.module.scss";

export interface ErrorTextProps extends QAProps {
  children?: React.ReactNode;
}
const ErrorText: FC<ErrorTextProps> = ({ children, qa }) => (
  <span
    className={styles.errorText}
    data-testid={qa ? `${qa}-${ElementType.Text}` : undefined}
  >
    {children}
  </span>
);

export default ErrorText;
