import { API, TagDTO } from "@rtslabs/field1st-fe-common";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import styled from "styled-components";
import * as Yup from "yup";
import { ErrorToast } from "shared/src/components/Toast/ErrorToast";
import Button, { TextButton } from "shared/src/components/common/Button";

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

const Wrapper = styled.div`
  width: 398px;
`;

interface Props {
  initialValues: TagDTO;
}

const TagForm = ({ initialValues }: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorToastVisible, setErrorToastVisible] = useState(false);

  // const _updateTag = async (values: TagDTO) => {
  //   const res = await dispatch(updateTag(values));
  //   setLoading(false);
  //   if (res.type === UPDATE_TAG.SUCCESS) {
  //     history.replace("/content/tag-library");
  //   } else {
  //     setErrorToastVisible(true);
  //   }
  // };

  const _addTag = async (values: TagDTO) => {
    try {
      await API.createOETag({ tag: values });
    } catch (e) {
      console.error(e);
      setErrorToastVisible(true);
    } finally {
      setLoading(false);
      navigate("/content/tag-library", { replace: true });
    }
  };

  const handleSubmit = (values: TagDTO) => {
    setLoading(true);
    // if (values?.id) {
    //   _updateTag(values);
    // } else {
    //   _addTag(values);
    // }
    _addTag(values);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema,
  });

  return (
    <Wrapper data-testid={`${Page.Desktop}-${Components.TagForm}`}>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          error={formik.touched["name"] && formik.errors["name"]}
          label="Tag"
          name="name"
          onChange={formik.handleChange}
          placeholder="Tag"
          value={formik.values["name"]}
          qa={`${Components.TagForm}-${ElementType.TextInput}-add`}
        />

        <ErrorToast
          onClick={() => setErrorToastVisible(false)}
          visible={errorToastVisible}
        >
          Tag with that name already exists
        </ErrorToast>
        <br />
        <Button
          qa={`${Components.TagForm}-${ElementType.Button}-save`}
          type="submit"
          loading={loading}
        >
          Save
        </Button>
        <TextButton
          qa={`${Components.TagForm}-${ElementType.Button}-cancel`}
          variant="cancel"
          onClick={() => navigate(-1)}
        >
          Cancel
        </TextButton>
      </form>
    </Wrapper>
  );
};

export default TagForm;
