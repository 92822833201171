import { API, roles as allRoles } from "@rtslabs/field1st-fe-common";

interface Props {
  altComponent?: JSX.Element;
  children: JSX.Element;
  roles?: string[];
}

const WriteContent = ({
  altComponent,
  children,
  roles = [allRoles.ROLE_CLIENT_ADMIN, allRoles.ROLE_SUPER_ADMIN],
}: Props) => {
  if (API.Environment.hasRoleAccess(roles)) {
    return children;
  } else if (altComponent) {
    return altComponent;
  } else {
    return null;
  }
};

export default WriteContent;
