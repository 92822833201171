import React from "react";

import * as S from "./styles";

export type TextVariant = "default" | "error" | "field" | "helper";

export interface TextProps {
  as?: React.ElementType;
  className?: any;
  children: React.ReactNode;
  id?: string;
  variant: TextVariant;
}

const Text = ({ as, className, children, id, variant }: TextProps) => {
  return (
    <S.Text className={className} as={as} id={id} variant={variant}>
      {children}
    </S.Text>
  );
};

Text.defaultProps = {
  as: "span",
  variant: "default",
};

export default Text;
