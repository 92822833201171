import React, { CSSProperties } from "react";
import { remCalc } from "../../../../../../themes/helpers";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";

export default function (): { [key: string]: CSSProperties } {
  const theme = React.useContext(ThemeContext);

  return StyleSheet.create({
    drawerContent: {
      width: "30vw",
      textAlign: "left",

      ":nth-child(1n) .MuiFormLabel-root": {
        fontSize: "1rem",
      },
    },

    description: {
      fontSize: remCalc(12),
      color: theme.masterColors.darkGrey,
    },

    section: {
      marginBottom: "1rem",
    },

    sectionHeader: {
      fontSize: "1rem",
      marginBottom: "0.05rem",
      marginTop: "0.5rem",
      fontWeight: 500,
    },

    addButtonContainer: {
      textAlign: "right",
      display: "flex",
    },

    option: {
      marginBottom: "0.25rem",
    },

    deleteAreaButton: {
      flexGrow: 1,
      textAlign: "center",
    },
  });
}
