import React, { FC, useState } from "react";
import { Components, ElementType, QAProps } from "../../../qa-slugs";
import { PhotoArea } from "../../Photos/PhotoArea";
import { CameraButton } from "./CameraButton/CameraButton";
import styles from "./QuestionPhotoWrapper.module.scss";
import { usePhotos } from "./usePhotos";

interface Props extends QAProps {
  children?: React.ReactNode;
  allowPhotos?: boolean;
  questionOrResponseTitle?: string;
  questionRootId: number;
  questionSelectionId?: number | null;
  disabled: boolean;
  appPath?: string;
}

export const SelectionPhotoWrapper: FC<Props> = ({
  allowPhotos,
  questionRootId,
  questionSelectionId,
  disabled,
  children,
  qa,
  appPath,
}) => {
  const photoHandling = usePhotos(questionRootId, questionSelectionId, appPath);
  const { photos } = photoHandling;
  const [photosExpanded, setPhotosExpanded] = useState<boolean>(false);

  return (
    <>
      <div className={styles.selectionPhotoWrapper}>
        <div className={styles.question}>{children}</div>
        {allowPhotos && (
          <CameraButton
            qa={
              qa
                ? `${qa}-${Components.Document}-${ElementType.Button}-radio`
                : undefined
            }
            photoCount={photos.length}
            expanded={photosExpanded}
            onClick={() => setPhotosExpanded(!photosExpanded)}
            disabled={disabled}
          />
        )}
      </div>
      {allowPhotos && !disabled && (
        <PhotoArea
          {...photoHandling}
          photosExpanded={photosExpanded}
          type="IMAGES_BELOW"
          qa={qa ? `${qa}-${Components.PhotoArea}-response` : undefined}
        />
      )}
    </>
  );
};
