import { Drawer } from "shared/src/components/Drawer/Drawer";

import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import React, { FC } from "react";
import {
  DocumentSummaryVm,
  DocumentHistoryVm,
} from "@rtslabs/field1st-fe-common";
import DocumentHistory from "../DocumentSidebarDrawer/DocumentHistory/DocumentHistory";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";

type GetForDocument<T> = (id: number) => Promise<T>;
interface Props {
  document: DocumentSummaryVm;
  open: boolean;
  onClose: () => void;
  isDesktop: boolean;
  fetchDocumentHistory: GetForDocument<DocumentHistoryVm[]>;
  term: string;
}

const DocumentHistorySidebar: FC<Props> = ({
  document,
  open,
  onClose,
  isDesktop,
  fetchDocumentHistory,
  term,
}) => {
  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      id="DocumentHistory"
      onClose={onClose}
      isOpen={open}
      disableBackdrop
    >
      <>
        {/* Header */}
        <DrawerHeader title={`${term} History`} onClose={onClose} />
        {/* Content */}
        <DrawerContentWrapper>
          {document && (
            <DocumentHistory
              document={document}
              fetchDocumentHistory={fetchDocumentHistory}
              term={term}
            />
          )}
        </DrawerContentWrapper>
      </>
    </Drawer>
  );
};

export default DocumentHistorySidebar;
