import React, { useMemo } from "react";

import s from "../../styles.module.scss";
import { TextButton } from "../../../../common/Button";
import {
  FBDisplayCondition,
  FBForm,
  FBItem,
} from "../../../../clientAdmin/types";
import { getFormItemByRootId, scrollToElement } from "../../helpers";
import { useFormikContext } from "formik";
import { joinClassNames } from "../../../../../helpers/theme.helpers";

interface Props {
  sourceDisplayConditions: FBDisplayCondition[];
  onSelectItem: (item: FBItem) => void;
}

const DisplayConditionsBanner = ({
  sourceDisplayConditions,
  onSelectItem,
}: Props) => {
  const { values } = useFormikContext<FBForm>();

  /**
   * Select and scroll to the source item of a display condition
   * @param item Source item for the display condition
   */
  function onJumpToDisplayConditionItem(item: FBItem) {
    // select the item
    onSelectItem(item);
    // scroll to the item
    scrollToElement(item.id!.toString());
  }

  const sourceQuestions = useMemo(
    () =>
      sourceDisplayConditions.map((cond) => {
        const item = getFormItemByRootId(values, cond.sourceQuestionRootId!);
        if (item?.id && item.type === "QUESTION") {
          return (
            <TextButton
              key={item.id}
              className={joinClassNames(s.alertButton, s.lowercase)}
              variant="link"
              onClick={() => onJumpToDisplayConditionItem(item)}
            >
              {item.title || `Untitled ${item.subType.toLowerCase()} question`}
            </TextButton>
          );
        }
        return null;
      }),
    [sourceDisplayConditions]
  );

  return (
    <div className={s.alert}>
      <p className={s.alertTitle}>CONDITIONAL LOGIC</p>
      <p className={s.alertBody}>
        The display of this question is affected by conditional logic rules on
        these questions:
      </p>

      {sourceQuestions}
    </div>
  );
};

export default DisplayConditionsBanner;
