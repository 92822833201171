import React, { useMemo } from "react";

import ListSimple from "shared/src/components/ListSimple/ListSimple";
import {
  API,
  FormSummaryVm,
  FormTypeDTO,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import { StyledLinkCaretRight } from "shared/src/components/StyledLinkCaretRight/StyledLinkCaretRight";

import styles from "./FormTemplatesDrawerContent.module.scss";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";

interface Props {
  onClose: () => void;
  onSelectFormTemplate: (type: FormSummaryVm) => void;
  selectedFormType: FormTypeDTO | null;
  title?: string;
}

const FormTemplatesDrawerContent = ({
  selectedFormType,
  onClose,
  onSelectFormTemplate,
  title = "Start Related Form",
}: Props) => {
  // const [OEState, OEDispatchState] = useReducer(OEReducer, initialState);

  const formsCall = useAPI(API.getAllForms, {
    format: API.FormVmFormat.SUMMARY,
  });

  const templateList = useMemo(() => {
    if (formsCall.data && selectedFormType) {
      return formsCall.data
        .filter((e) => e.type.id === selectedFormType.id)
        .map((template: FormSummaryVm) => ({
          primary: (
            <span className={styles.navLabel}>Start {template.name}</span>
          ),
          rightSideItem: <StyledLinkCaretRight />,
          onClick: () => onSelectFormTemplate(template),
          showLink: true,
        }));
    }
    return false;
  }, [formsCall.data]);

  return (
    <>
      <DrawerHeader title={title} onClose={onClose} />
      {/* Content */}
      <div className={styles.body}>
        <ListSimple listItems={templateList || []} qa="formTemplates" />
      </div>
    </>
  );
};

export default FormTemplatesDrawerContent;
