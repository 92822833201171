import React, { FC } from "react";
import { Components, ElementType } from "../../qa-slugs";
import { Icon } from "../Icon/Icon";
import styles from "./DrawerHeader.module.scss";

interface DrawerHeaderProps {
  onClose: () => void;
  title: string;
  subtitle?: string;
}

const DrawerHeader: FC<DrawerHeaderProps> = ({ onClose, title, subtitle }) => {
  return (
    <div data-testid={`${title}-header`} className={styles.wrapper}>
      {/* Close button */}
      <button
        data-testid={`${Components.DashboardDocumentDrawer}-${ElementType.Button}-back`}
        className={styles.backButton}
        onClick={onClose}
      >
        <Icon type="chevron-left" className={styles.icon} />
      </button>

      <div className={styles.body}>
        {/* Title */}
        <h6 className={styles.title}>{title}</h6>
        {/* Subtitle */}
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    </div>
  );
};

export default DrawerHeader;
