import * as React from "react";
import styled from "styled-components";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCellBase, {
  TableCellProps as TableCellPropsBase,
} from "@mui/material/TableCell";
import TableHeadBase from "@mui/material/TableHead";
import TableRowBase from "@mui/material/TableRow";
import { SortDirection } from "@rtslabs/field1st-fe-common";

const TableHead = styled(TableHeadBase)`
  th {
    color: ${({ theme }) => theme.masterColors.darkGrey};
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 1.88px;
    text-transform: uppercase;
  }
  &&.reports {
    th {
      font-size: 12px;
      color: ${({ theme }) => theme.masterColors.darkGrey};
    }
  }
  &&.reports {
    th {
      font-size: 12px;
      color: ${({ theme }) => theme.masterColors.darkGrey};
    }
  }
`;

const TableCellContent = styled.span<{
  sortable?: boolean;
  active?: boolean;
  ["data-qa-tab"]?: string;
}>`
  color: ${({ active, theme }) =>
    active ? theme.colors.black : theme.masterColors.darkGrey};
  ${({ active }) => (active ? "font-weight: 700;" : "")}
  cursor: ${({ sortable }) => sortable && "pointer"};

  &:hover {
    ${({ sortable, theme }) =>
      sortable ? `color: ${theme.colors.black};` : ""}
  }
`;

const SortCaret = styled.i.attrs({
  className: "icon-icons8-sort_down",
})<{
  sortDirection?: SortDirection;
}>`
  display: inline-block;
  transform: ${({ sortDirection }) =>
    sortDirection === "asc" ? "rotate(180deg)" : ""};
`;

export interface TableCellProps extends Omit<TableCellPropsBase, "onClick"> {
  active?: boolean;
  minWidth?: string;
  onClick?: (sortDirection: SortDirection) => void;
  sortDirection?: SortDirection;
  width?: number | string;
  qa?: string;
}

const TableCell = styled<React.FC<TableCellProps>>(
  ({
    active,
    children,
    minWidth,
    onClick,
    sortDirection,
    width,
    qa,
    ...otherProps
  }) => {
    const handleSortClick = () => {
      // if already sorting desc on this column, toggle to asc.
      if (onClick) {
        if (sortDirection === "desc") {
          onClick("asc");
        } else {
          onClick("desc");
        }
      }
    };
    const sortable = !!sortDirection;
    return (
      <TableCellBase {...otherProps} classes={{ root: "root" }}>
        <TableCellContent
          active={active}
          sortable={sortable}
          onClick={(sortable && handleSortClick) || undefined}
          data-testid={qa}
        >
          {children} {sortable && <SortCaret sortDirection={sortDirection} />}
        </TableCellContent>
      </TableCellBase>
    );
  }
)`
  &&.root {
    min-width: ${({ minWidth }) => minWidth};
    padding: 21px 12px;
    text-align: ${({ align }) => align};
    width: ${({ width }) => (typeof width === "number" ? `${width}px` : width)};
  }
`;

const TableRow = styled(({ noBottomBorder, ...otherProps }) => (
  <TableRowBase {...otherProps} classes={{ root: "root" }} />
))`
  & .root {
    border-bottom: ${({ noBottomBorder }) => noBottomBorder && 0};
  }
`;

export { Table, TableBody, TableCell, TableHead, TableRow };
