import { CoreRedux } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import MediaQuery from "react-responsive";
import { Outlet } from "react-router-dom";
import { Components } from "shared/src/qa-slugs";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { useAppSelector } from "../../../store/hooks";
import DashboardBottomNavigation from "../DashboardBottomNavigation";
import { Header } from "../Header/Header";
import { Nav } from "../Nav/Nav";
import styles from "./MainLayout.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

interface MainLayoutProps {
  legacy?: boolean;
}

export const MainLayout: FC<MainLayoutProps> = ({ legacy }) => {
  const mainNavigationConfig = useAppSelector(
    CoreRedux.getMainNavigationConfigProperties
  );

  return (
    <>
      <Header />
      <div
        className={styles.navbarWrapper}
        data-testid={`${Components.SidebarNavigation}-Container`}
      >
        <MediaQuery minWidth={scssVariables.breakpointExtraLarge}>
          {/* Web navigation */}
          <Nav />
        </MediaQuery>
        {/* Page content, used for both mobile-responsive and web */}
        <div
          className={joinClassNames(
            styles.mainContent,
            legacy && styles.legacy
          )}
          id="contentWrapper"
        >
          <Outlet />
        </div>
        <MediaQuery maxWidth={scssVariables.breakpointExtraLarge}>
          {/* Mobile navigation */}
          <DashboardBottomNavigation
            drawerContent={<Nav />}
            mainNavigationConfig={mainNavigationConfig}
          />
        </MediaQuery>
      </div>
    </>
  );
};
