import styled from "styled-components";

const getColor = (color, theme) => {
  if (color) {
    // Can add more and make it an array of colors if needed
    if (color === "primary") {
      return theme.colors.primary;
    } else {
      return color;
    }
  }
  return theme.masterColors.darkGrey;
};

type WrapperType = {
  color?: string;
  onClick?: () => void;
};

export const Wrapper = styled.div<WrapperType>`
  color: ${({ color, theme }) => getColor(color, theme)};
  cursor: ${({ onClick }) => onClick && "pointer"};
`;
