import { BarSvgProps } from "@nivo/bar";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ChartData } from "./BarChart/BarChart";
import colors from "./PieChart/PieChart.module.scss";

export const useCommonChartProps = (): {
  colors: any;
  colorBy: BarSvgProps<ChartData>["colorBy"];
  defs: any;
  fill: any;
  theme: any;
} => {
  const theme = useContext(ThemeContext);
  return {
    colorBy: "id", // TODO this not working? Want different-er colors for bars. Passing colorBy index on BarChart - JB
    colors:
      // { scheme:
      [
        colors.success,
        colors.warning,
        colors.error,
        colors.secondary,
        colors.visited,
      ],
    // Defining gradients
    defs: [
      {
        id: "gradientA",
        colors: [
          { offset: 0, color: "inherit" },
          { offset: 100, color: "inherit", opacity: 0.5 },
        ],
        type: "linearGradient",
      },
    ],
    // Defining rules to apply those gradients
    fill: [{ match: "*", id: "gradientA" }],
    theme: {
      legends: {
        text: { fontSize: 16 },
      },
      axis: {
        ticks: {
          text: {
            fontSize: 16,
            fontFamily: "Roboto",
            fill: colors.darkGreyBlue,
            fontWeight: 500,
          },
          line: {
            stroke: colors.darkGreyBlue,
          },
        },
      },
      labels: {
        text: { fontSize: 16 },
      },
      border: "#0000001F",
    },
  };
};
