import ClickAwayListener from "@mui/material/ClickAwayListener";
import moment from "moment";
import React from "react";

import { FadedText, Text } from "shared/src/components/clientAdmin/styles";

import { DocumentSummaryVm, SortDirection } from "@rtslabs/field1st-fe-common";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import {
  CurrentPage,
  PageSize,
} from "shared/src/components/TableUI/Pagination/types";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "shared/src/components/common/styled/Table";
import DefaultCell from "../../../common/TableUI/DefaultCell";

import { useTranslation } from "react-i18next";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import Loader from "shared/src/components/Loader/Loader";
import { Components, ElementType } from "shared/src/qa-slugs";
import { prettifyDocumentStatus } from "../../../Documents/helpers";
import { DocumentDrawerSection } from "../../documents/DocumentDrawer";
import s from "../styles.module.scss";
import * as S from "./styles";

export const DateAndTime = ({
  dateTime,
}: {
  dateTime: string | undefined | null;
}) => {
  if (!dateTime) {
    return null;
  }

  const date = moment(dateTime).format("MM/DD/YY");
  const time = moment(dateTime).format("hh:mm A");
  return (
    <>
      <span className={s.subText}>{date}</span>
      <FadedText>{time}</FadedText>
    </>
  );
};

export type DocumentsPopperProps = {
  popperIsOpen: boolean;
  setAnchorDocument: (document: DocumentSummaryVm) => void;
  setAnchorEl: (e: EventTarget & HTMLButtonElement) => void;
  setPopperIsOpen: (flag: boolean) => void;
  setShowDocumentDrawer: (
    tab:
      | typeof DocumentDrawerSection.Document
      | typeof DocumentDrawerSection.History
  ) => void;
};

type ReportsDocumentsSort = {
  direction: SortDirection;
  type: string;
};

type ReportsDocumentsPrimarySort = {
  direction: SortDirection;
};

export type ReportsDocumentsSorting = {
  dateCreated: ReportsDocumentsPrimarySort;
  ["clientGroup.name"]: ReportsDocumentsPrimarySort;
  ["owner.lastName"]: ReportsDocumentsPrimarySort;
  primarySort: ReportsDocumentsSort;
  submissionDate: ReportsDocumentsPrimarySort;
  title: ReportsDocumentsPrimarySort;
};

export type DocumentsSortingProps = {
  sorting: ReportsDocumentsSorting;
  setSorting: (sorting: ReportsDocumentsSorting) => void;
};

export type DocumentsPaginationProps = {
  currentPage: CurrentPage;
  pageSize: PageSize;
};

interface DocumentsTableProps {
  totalPages: number;
  documents: DocumentSummaryVm[];
  loading: boolean;
  onPaginate: (pageNum: number, numPerPage: number) => void;
  pagination: DocumentsPaginationProps;
  popper: DocumentsPopperProps;
  sorting: DocumentsSortingProps;
}

export const DocumentsTable = ({
  documents,
  totalPages,
  loading,
  onPaginate,
  pagination: { currentPage, pageSize },
  popper: {
    popperIsOpen,
    setAnchorDocument,
    setAnchorEl,
    setPopperIsOpen,
    setShowDocumentDrawer,
  },
  sorting: { setSorting, sorting },
}: DocumentsTableProps) => {
  const { t } = useTranslation();

  const sortBy = (type: string, direction: SortDirection) => {
    setSorting({
      ...sorting,
      [type]: {
        direction,
      },
      primarySort: {
        direction,
        type,
      },
    });
  };

  // Open document in sidebar
  const viewDocument = (document: DocumentSummaryVm) => {
    setAnchorDocument(document);
    setShowDocumentDrawer(DocumentDrawerSection.Document); // View document
  };

  return (
    <Loader loading={loading}>
      <Table>
        <TableHead>
          <TableRow noBottomBorder>
            <TableCell
              active={sorting.primarySort.type === "title"}
              onClick={(dir) => sortBy("title", dir)}
              sortDirection={sorting.title.direction}
              minWidth="170px"
              qa={`${Components.Reports}-documents`}
            >
              Title & Work ID
            </TableCell>
            <TableCell
              active={sorting.primarySort.type === "clientGroup.name"}
              onClick={(dir) => sortBy("clientGroup.name", dir)}
              sortDirection={sorting["clientGroup.name"].direction}
              width="150px"
              qa={`${Components.Reports}-group`}
            >
              Group
            </TableCell>
            <TableCell
              active={sorting.primarySort.type === "owner.lastName"}
              onClick={(dir) => sortBy("owner.lastName", dir)}
              sortDirection={sorting["owner.lastName"].direction}
              width="100px"
              qa={`${Components.Reports}-owner`}
            >
              Owner
            </TableCell>
            <TableCell
              active={sorting.primarySort.type === "dateCreated"}
              onClick={(dir) => sortBy("dateCreated", dir)}
              sortDirection={sorting.dateCreated.direction}
              width="120px"
              qa={`${Components.Reports}-created`}
            >
              Created
            </TableCell>
            <TableCell
              active={sorting.primarySort.type === "submissionDate"}
              onClick={(dir) => sortBy("submissionDate", dir)}
              sortDirection={sorting.submissionDate.direction}
              width="135px"
              qa={`${Components.Reports}-submitted`}
            >
              Submitted
            </TableCell>
            <TableCell qa={`${Components.Reports}-status`}>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <TableRow
              key={document.id}
              qa={`${Components.Reports}-${ElementType.TableRow}-${document.id}`}
            >
              <TableCell
                qa={`${Components.Reports}-${ElementType.TableCell}-title-${document.id}`}
              >
                <Text
                  fontSize={"0.875rem"}
                  fontWeight={400}
                  lineClamp={2}
                  onClick={() => viewDocument(document)}
                  textDecoration="underline"
                >
                  {`${document.title}`}
                </Text>
                <S.IdLabel>{`ID: ${document.id || "-"}`}</S.IdLabel>
              </TableCell>
              <TableCell
                qa={`${Components.Reports}-${ElementType.TableCell}-group-${document.id}`}
              >
                {document.clientGroup && document.clientGroup.name}
              </TableCell>
              <TableCell
                qa={`${Components.Reports}-${ElementType.TableCell}-owner-${document.id}`}
              >
                {document.owner && document.owner.name}
              </TableCell>
              <TableCell
                qa={`${Components.Reports}-${ElementType.TableCell}-created-${document.id}`}
              >
                <DateAndTime dateTime={document.startDate} />
              </TableCell>
              <TableCell
                qa={`${Components.Reports}-${ElementType.TableCell}-submitted-${document.id}`}
              >
                <DateAndTime dateTime={document.submissionDate} />
              </TableCell>
              {/* <TableCell>{document.status && prettifyDocumentStatus(document.status)}</TableCell> */}
              <TableCell>
                {/* <DocumentActionMenu document={document} /> */}
                <div className="d-flex flex-row justify-content-between">
                  <DefaultCell
                    qa={`${Components.Reports}-${ElementType.TableCell}-status-${document.id}`}
                    value={prettifyDocumentStatus(document.status)}
                  />
                  {/* Context menu and sidebar */}
                  <ClickAwayListener
                    onClickAway={() => {
                      if (popperIsOpen) {
                        setPopperIsOpen(false);
                      }
                    }}
                  >
                    <div className="d-flex flex-row justify-content-end">
                      <ActionButton
                        label={t("common:table.rowMenuButtonLabel")}
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          setAnchorDocument(document);
                          setTimeout(() => {
                            setPopperIsOpen(true);
                          }, 100);
                        }}
                        qa={`${Components.Reports}-${ElementType.Button}-action-${document.id}`}
                      />
                    </div>
                  </ClickAwayListener>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* // Pagination */}
      {documents && (
        <PaginationWrapper>
          <RowsPerPage
            pageSize={pageSize}
            onClick={(size) => onPaginate(0, size)}
            qaBase={`${Components.Reports}-documents-table`}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onClick={(page) => onPaginate(page, pageSize)}
            qaBase={`${Components.Reports}-documents-table`}
          />
          <BackToTop
            qa={`${Components.Reports}-${ElementType.Button}-back-to-top`}
          />
        </PaginationWrapper>
      )}
    </Loader>
  );
};
