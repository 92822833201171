import React from "react";
import ValidationStatusIndicator from "./ValidationStatusIndicator";
import s from "./styles.module.scss";
import { FBValidationLevel } from "./useValidation";
import { TextButton } from "../../../../common/Button";

interface Props {
  errorCount: number;
  alertLevel: FBValidationLevel;
  onClick?: () => void;
}

const ValidationStatus = ({ errorCount, alertLevel, onClick }: Props) => {
  if (!errorCount) return null;

  const text = `${errorCount} ${alertLevel}s`;

  return (
    <div className={s.validationStatus}>
      <ValidationStatusIndicator />
      {onClick ? (
        <TextButton variant="link" onClick={onClick}>
          {text}
        </TextButton>
      ) : (
        text
      )}
    </div>
  );
};

export default ValidationStatus;
