import { Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import {
  Button,
  TertiaryButton,
} from "../../../../../shared/src/components/Button/Button";
import Checkbox from "../../../../../shared/src/components/Checkbox/Checkbox";
import { TextInput } from "../../../../../shared/src/components/TextInput/TextInput";
import { Components, ElementType } from "../../../../../shared/src/qa-slugs";
import s from "./APIDS.module.scss";
import { APIForm, apiFormSchema } from "./apiDSHelpers";

interface APIFormikFormProps {
  initialValues: APIForm;
  isLoadingTest?: boolean;
  isTestSuccessful?: boolean;
  onCancel: () => void;
  onSubmit: (
    values: APIForm,
    formikHelpers: FormikHelpers<APIForm>
  ) => Promise<void>;
  onTest: (values: APIForm) => Promise<void>;
  testResponse: string;
}

export const APIFormikForm = ({
  initialValues,
  isLoadingTest,
  isTestSuccessful,
  onCancel,
  onSubmit,
  onTest,
  testResponse,
}: APIFormikFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={apiFormSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        getFieldProps,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        setTouched,
        touched,
        values,
      }) => {
        const handleClickTest = async () => {
          try {
            await onTest(values);
          } finally {
            // Touch all fields and force validation.
            const touched = Object.keys(values).reduce(
              (touched, name) => ({ ...touched, [name]: true }),
              {}
            );
            setTouched(touched, true);
          }
        };

        useEffect(() => {
          setFieldValue("data", testResponse);
        }, [testResponse]);

        return (
          <form onSubmit={handleSubmit} noValidate>
            <div style={{ maxWidth: "398px" }}>
              {/* API Name */}
              <TextInput
                qa={`${Components.APIFormikForm}-${ElementType.TextInput}-name`}
                label="Name"
                placeholder="API Name"
                error={!!touched.name && errors.name}
                {...getFieldProps("name")}
              />

              {/* API Description */}
              <TextInput
                qa={`${Components.APIFormikForm}-${ElementType.TextInput}-description`}
                label="Description"
                placeholder="API Description"
                error={!!touched.description && errors.description}
                {...getFieldProps("description")}
              />

              {/* Unique ID Field Name */}
              <TextInput
                qa={`${Components.APIFormikForm}-${ElementType.TextInput}-uniqueId`}
                label="Unique ID Field Name"
                placeholder="Field Name"
                error={!!touched.uniqueIdFieldName && errors.uniqueIdFieldName}
                {...getFieldProps("uniqueIdFieldName")}
              />

              {isTestSuccessful && !errors.uniqueIdFieldName && (
                <p className={s.successMessage}>Connected</p>
              )}
            </div>

            {/* Data Set Key */}
            <TextInput
              qa={`${Components.APIFormikForm}-${ElementType.TextInput}-dataset`}
              label="Data Set Key"
              placeholder="Data Set Key"
              error={!!touched.datasetKey && errors.datasetKey}
              {...getFieldProps("datasetKey")}
            />

            {/* URL */}
            <TextInput
              qa={`${Components.APIFormikForm}-${ElementType.TextInput}-url`}
              type="url"
              label="URL"
              placeholder="URL"
              error={!!touched.url && errors.url}
              {...getFieldProps("url")}
            />

            <Checkbox
              {...getFieldProps({ name: "paginated", type: "checkbox" })}
              containerClassName={s.checkboxField}
              label="Paginated"
              onChange={(checked) => setFieldValue("paginated", checked)}
              qa={`${Components.APIFormikForm}-${ElementType.Checkbox}-paginated`}
            />

            {/* Test & Save Buttons */}
            <div className={s.apiDSButtonsWrapper}>
              <Button
                qa={`${Components.APIFormikForm}-${ElementType.Button}-test`}
                onClick={handleClickTest}
                loading={isLoadingTest}
              >
                Test
              </Button>
              <Button
                type="submit"
                className={s.buttonMargin}
                disabled={!isValid}
                qa={`${Components.APIFormikForm}-${ElementType.Button}-save`}
                loading={isSubmitting}
              >
                Save & Close
              </Button>
              <TertiaryButton
                className={s.buttonMargin}
                onClick={onCancel}
                qa={`${Components.APIFormikForm}-${ElementType.Button}-cancel`}
              >
                Cancel
              </TertiaryButton>
            </div>

            {/* Data */}
            <TextInput
              qa={`${Components.APIFormikForm}-${ElementType.TextInput}-data`}
              disabled={true}
              label="Data"
              placeholder="Data String"
              multiline={true}
              rows={10}
              {...getFieldProps("data")}
            />
          </form>
        );
      }}
    </Formik>
  );
};
