import React from "react";

import Banner from "../../../../../../common/Banner/Banner";
import { TextButton } from "../../../../../../common/Button";

import ps from "../../styles.module.scss";

interface Props {
  dataSourceKey: string;
  onRemoveDataSet: () => void;
}

const DataSetBanner = ({ dataSourceKey, onRemoveDataSet }: Props) => {
  return (
    <Banner type="warning">
      <p>This question is using data set {dataSourceKey} for options</p>
      <p>
        The first 10 options will display as a sample in the form preview panel
      </p>
      <TextButton className={ps.link} variant="link" onClick={onRemoveDataSet}>
        Switch to custom options
      </TextButton>
    </Banner>
  );
};

export default DataSetBanner;
