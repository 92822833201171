import { LogoImgProps } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { ElementType } from "../qa-slugs";
import { Link } from "./Link/Link";

export const BACKUP_IMAGE = "/assets/logo/Field1stLogoCondensed@2x.png";
export const BACKUP_ALT_TEXT = "Dashboard";
export const DEFAULT_HEIGHT = "32";

interface LogoProps {
  logo?: LogoImgProps;
  height?: number;
}

const LogoCondensed: FC<LogoProps> = ({ logo, height }) => {
  return (
    <Link to="/" qa={`${ElementType.Logo}-${ElementType.Link}-toHome`}>
      <img
        src={logo?.src ? logo.src : BACKUP_IMAGE}
        height={height || DEFAULT_HEIGHT}
        alt={logo?.alt ? logo.alt : BACKUP_ALT_TEXT}
      />
    </Link>
  );
};

export default LogoCondensed;
