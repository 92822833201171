import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Components, ElementType } from "shared/src/qa-slugs";
import Modal from "shared/src/components/common/Modal";
import { DeleteFormTypeConfirmation } from "./DeleteFormTypeConfirmation";
import { FormTypeToasts } from "./FormTypeToasts";

interface DeleteFormTypeModalProps {
  formType: FormTypeDTO;
  deleteFormTypeIsVisible: boolean;
  setDeleteFormTypeIsVisible: Dispatch<SetStateAction<boolean>>;
  refreshFormTypes: () => void;
}

export const DeleteFormTypeModal = ({
  formType,
  deleteFormTypeIsVisible,
  setDeleteFormTypeIsVisible,
  refreshFormTypes,
}: DeleteFormTypeModalProps) => {
  const [deleteStatus, setDeleteStatus] = useState<
    "CONFIRM" | "SUCCESS" | "FAIL"
  >("CONFIRM");

  return (
    <>
      {/* Toasts */}
      <FormTypeToasts
        status={deleteStatus}
        onDelay={() => setDeleteStatus("CONFIRM")}
        successMessage={`Form Type "${formType.name}" Deleted`}
        failMessage={`Error - Unable to delete "${formType.name}" form type`}
        qa={`${Components.FormTypes}-${ElementType.Toast}-delete`}
      />

      {/* Delete Form Type Modal */}
      <Modal
        qa={`${Components.FormTypes}-${ElementType.Modal}-delete-modal`}
        border="none"
        open={deleteFormTypeIsVisible}
        handleClose={() => setDeleteFormTypeIsVisible(false)}
        height="auto"
        width="652px"
        padding="24px 23px"
        content={
          <DeleteFormTypeConfirmation
            refreshFormTypes={refreshFormTypes}
            onCancel={() => setDeleteFormTypeIsVisible(false)}
            formType={formType}
            setDeleteFormTypeIsVisible={setDeleteFormTypeIsVisible}
            setDeleteStatus={setDeleteStatus}
          />
        }
      />
    </>
  );
};
