/**
 * Password standard regex
 * (?=.*[a-z])        : This matches the presence of at least one lowercase letter.
 * (?=.*d)            : This matches the presence of at least one digit i.e. 0-9.
 * (?=.*[!@#$%^&*()]) : This matches the presence of at least one special character.
 * ((?=.*[A-Z])       : This matches the presence of at least one capital letter.
 * {8,100}            : This limits the length of password from minimum 8 letters to maximum 100 letters.
 */
export const PASSWORD_REGEX = {
  DIGIT: /(\d)/,
  FULL: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,100}$/,
  LOWERCASE: /(?=.*[a-z])/,
  SPECIAL: /([!@#$%^&*()])/,
  UPPERCASE: /(?=.*[A-Z])/,
};
