import React, { FC, Fragment } from "react";
import { PermissionCell } from "./PermissionCell";
import styles from "./PermissionTable.module.scss";

interface RolePermission {
  read?: boolean;
  write?: boolean;
}

interface PermissionFeature {
  canBeRestrictedPerGroup?: boolean;
  name: string;
  rolePermissions: RolePermission[];
}

const permissions: PermissionFeature[] = [
  {
    canBeRestrictedPerGroup: true,
    name: "Documents",
    rolePermissions: [
      { read: true, write: true },
      { read: true },
      { read: true },
      {},
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    canBeRestrictedPerGroup: true,
    name: "Reporting",
    rolePermissions: [
      { read: true },
      { read: true },
      { read: true },
      {},
      {},
      { read: true },
      { read: true },
    ],
  },
  {
    canBeRestrictedPerGroup: true,
    name: "Groups",
    rolePermissions: [
      {},
      { read: true },
      {},
      {},
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    canBeRestrictedPerGroup: true,
    name: "Participants (Users)",
    rolePermissions: [
      {},
      { read: true },
      {},
      {},
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    name: "Feedback",
    rolePermissions: [
      { write: true },
      {},
      {},
      {},
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    name: "Work Orders",
    rolePermissions: [
      { read: true },
      {},
      {},
      {},
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    canBeRestrictedPerGroup: true,
    name: "Form List",
    rolePermissions: [
      { read: true },
      {},
      {},
      { read: true },
      {},
      { read: true },
      { read: true },
    ],
  },
  {
    canBeRestrictedPerGroup: true,
    name: "Form Templates",
    rolePermissions: [
      {},
      {},
      {},
      { read: true, write: true },
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    canBeRestrictedPerGroup: true,
    name: "Form Types",
    rolePermissions: [
      { read: true },
      {},
      {},
      { read: true, write: true },
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    name: "Data Sets",
    rolePermissions: [
      {},
      {},
      {},
      { read: true, write: true },
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    name: "Data Set Values",
    rolePermissions: [
      { read: true },
      {},
      {},
      { read: true, write: true },
      {},
      { read: true },
      { read: true, write: true },
    ],
  },
  {
    name: "Resources (OE's and Defences)",
    rolePermissions: [
      { read: true },
      {},
      {},
      { read: true, write: true },
      { read: true, write: true },
      { read: true },
      { read: true, write: true },
    ],
  },
];

export const PermissionTable: FC = () => {
  return (
    <table className={styles.table}>
      <colgroup span={1}></colgroup>
      <colgroup span={1}></colgroup>
      <colgroup span={2}></colgroup>
      <colgroup span={2}></colgroup>
      <colgroup span={2}></colgroup>
      <colgroup span={2}></colgroup>
      <colgroup span={2}></colgroup>
      <colgroup span={2}></colgroup>
      <colgroup span={2}></colgroup>
      <thead className={styles.headerGroup}>
        <tr className={styles.level1Headers}>
          <td className={styles.pin}></td>
          <th scope="col">Can be restricted per group</th>
          <th colSpan={2} scope="colgroup">
            User
          </th>
          <th colSpan={2} scope="colgroup">
            Group Manager
          </th>
          <th colSpan={2} scope="colgroup">
            Reporter
          </th>
          <th colSpan={2} scope="colgroup">
            Form Architect
          </th>
          <th colSpan={2} scope="colgroup">
            Content Editor
          </th>
          <th colSpan={2} scope="colgroup">
            Client Reviewer
          </th>
          <th colSpan={2} scope="colgroup">
            Client Admin
          </th>
        </tr>
        <tr className={styles.level2Headers}>
          <td className={styles.pin}></td>
          <td></td>
          <th scope="col">Read</th>
          <th scope="col">Write</th>
          <th scope="col">Read</th>
          <th scope="col">Write</th>
          <th scope="col">Read</th>
          <th scope="col">Write</th>
          <th scope="col">Read</th>
          <th scope="col">Write</th>
          <th scope="col">Read</th>
          <th scope="col">Write</th>
          <th scope="col">Read</th>
          <th scope="col">Write</th>
          <th scope="col">Read</th>
          <th scope="col">Write</th>
        </tr>
      </thead>
      <tbody className={styles.body}>
        {permissions.map((permission) => (
          <tr key={permission.name}>
            <th scope="row">{permission.name}</th>
            <PermissionCell checked={permission.canBeRestrictedPerGroup} />
            {permission.rolePermissions.map((rolePermssion, index) => (
              <Fragment key={index}>
                <PermissionCell checked={rolePermssion.read} />
                <PermissionCell checked={rolePermssion.write} />
              </Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
