import React from "react";

import styles from "./Loader.module.scss";
import { joinClassNames } from "../../helpers/theme.helpers";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

export interface LoaderProps {
  loading?: boolean;
  children?: React.ReactNode;
  className?: string;
  overlay?: boolean;
  spinnerProps?: CircularProgressProps;
}

const Loader = ({
  children,
  overlay,
  loading,
  className,
  spinnerProps,
}: LoaderProps) => {
  if (loading) {
    return (
      <>
        <div
          data-testid="loader"
          className={joinClassNames(
            overlay && styles.overlay,
            loading && styles.loading,
            className
          )}
        >
          <CircularProgress
            classes={{ colorSecondary: styles.secondaryProgress }}
            className={styles.progress}
            {...spinnerProps}
          />
        </div>
        {/* if displaying as an overlay, display the children underneath */}
        {overlay ? children : null}
      </>
    );
  }

  return <>{children}</>;
};

export default Loader;
