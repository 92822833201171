import { css } from "aphrodite/no-important";
import React from "react";
import { GeneratorFn, ItemParams } from "../Create";
import FormItem from "./FormItem";
import { getItemIcon } from "./iconMap";
import styles from "./styles";
import { useAppSelector } from "../../../../../redux/reduxHooks";

interface Props {
  add: GeneratorFn;
  itemSelected: boolean;
  disableAddGallery?: boolean;
  disableAddMap?: boolean;
  appWidgetsList: ItemParams[];
}

const Widgets = ({
  add,
  itemSelected,
  disableAddMap,
  disableAddGallery,
  appWidgetsList,
}: Props) => {
  const isEsriActive = useAppSelector(
    (state) => state.appConfigs.arcGisConfig?.active
  );
  const S = styles({});

  if (!isEsriActive) {
    appWidgetsList = appWidgetsList.filter(
      (item) => !(item.type === "WIDGET" && item.subType === "ESRI_CONTENT")
    );
  }

  return (
    <div className={css(S.ItemsContainer)}>
      <span className={css(S.Label)}>Widgets</span>
      {appWidgetsList.map((widget) => (
        <FormItem
          disabled={
            !itemSelected ||
            (widget.subType === "MAP" && disableAddMap) ||
            (widget.subType === "PHOTO_GALLERY" && disableAddGallery)
          }
          key={widget.name}
          name={widget.name}
          icon={getItemIcon(
            (widget.type === "QUESTION" && widget.properties?.fakeWidgetType) ||
              widget.subType
          )}
          onClick={() => add(widget)}
        />
      ))}
    </div>
  );
};

export default Widgets;
