import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { useState } from "react";

import {
  DataSource,
  QuestionAnswerSourceDTO,
  QuestionSelectionsDTO,
  QuestionSelectionsSaveVm,
} from "@rtslabs/field1st-fe-common";
import Label from "../../../../../../Label/Label";
import { TextInputProps } from "../../../../../../TextInput/types";
import {
  FBDisplayCondition,
  FBForm,
  FBItem,
  Property,
} from "../../../../../types";

import DataSetDrawer from "../../drawers/DataSetDrawer";
import { buildAnswerSource } from "../AnswerSource";
import DataSetBanner from "./DataSetBanner";
import SwitchOptionsTypeModal from "./SwitchOptionsTypeModal";

import FieldErrorMessage from "../../../validation/FieldErrorMessage";
import ps from "../../styles.module.scss";
import CustomAnswerOptions from "../CustomAnswerOptions";
import { useFBConfigs } from "../../../../../../../util/hooks/useFBConfigs";
import { ItemParams } from "../../../Create";

interface Props extends TextInputProps {
  touched?: boolean;
  detailedSearchProps?: Property[];
  label: string;
  assistiveText: string;
  selectedValues: QuestionSelectionsDTO[];
  name: string;
  itemPath: string;
  item: FBItem;
  itemRootId: number;
  onOptionUpdate: (
    values: QuestionSelectionsSaveVm[]
  ) => Promise<QuestionSelectionsDTO[]>;
  appWidgetsList: ItemParams[];
}

const AnswerOptions = ({
  label,
  assistiveText,
  selectedValues,
  name,
  itemPath,
  itemRootId,
  onOptionUpdate,
  detailedSearchProps,
  error,
  touched,
  item,
  appWidgetsList,
}: Props) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<
    "ds" | "options" | "replaceDs" | "removeOption" | undefined
  >(undefined);
  const [modalCallbackValues, setModalCallbackValues] = useState<unknown>();
  const { formBuilderConfigs } = useFBConfigs();
  const { enableDataSets } = formBuilderConfigs;

  const { values, setFieldValue } = useFormikContext<FBForm>();

  const existingSource: QuestionAnswerSourceDTO | null = get<FBForm, string>(
    values,
    `${itemPath}.answerSource`
  );
  const allDisplayConditions: FBDisplayCondition[] = get(
    values,
    "displayConditions",
    []
  )!;
  const itemDisplayConditions = allDisplayConditions.filter(
    (dc) => dc.sourceQuestionRootId === itemRootId
  );

  function closeModal() {
    setShowModal(false);
    setModalType(undefined);
  }

  function removeDataSet() {
    setFieldValue(`${itemPath}.answerSource`, null);
  }

  function openDataSetDrawer() {
    setShowDrawer(true);
  }

  function clearAllSelectionValues() {
    setFieldValue(name, []);
  }

  function removeSelection(selectionIndex: number) {
    const updatedSelections = [
      ...selectedValues.slice(0, selectionIndex),
      ...selectedValues.slice(selectionIndex + 1),
    ];

    setFieldValue(name, updatedSelections);
  }

  function removeSelectionCondition(selectionRootId: number) {
    const updatedDisplayConditions = itemDisplayConditions.filter(
      (dc) => dc.sourceConditionRootId !== selectionRootId
    );
    setFieldValue("displayConditions", updatedDisplayConditions);
  }

  function clearItemConditions() {
    const updatedDisplayConditions = allDisplayConditions?.filter(
      (dc) => dc.sourceQuestionRootId !== itemRootId
    );
    setFieldValue("displayConditions", updatedDisplayConditions);
  }

  function addDataSet(
    dataSet: DataSource,
    sourceColumn: string,
    sortBy: string
  ) {
    const updatedAnswerSource = buildAnswerSource(
      dataSet,
      sourceColumn,
      sortBy
    );
    setFieldValue(`${itemPath}.answerSource`, updatedAnswerSource);
  }

  // function handleRemoveCustomSelection(value: SuggestionType, index: number) {
  //   if (itemDisplayConditions.find((dc) => dc.sourceConditionRootId === value.rootId)) {
  //     setModalType("removeOption");
  //     setShowModal(true);
  //     setModalCallbackValues({ value, index });
  //   } else {
  //     removeSelection(index);
  //   }
  // }

  function handleRemoveDataSet() {
    if (itemDisplayConditions?.length) {
      setModalType("options");
      setShowModal(true);
    } else {
      removeDataSet();
    }
  }

  function handleAddDataSet() {
    // if we're replacing custom selections
    if (get(values, name)?.length) {
      setModalType("ds");
      setShowModal(true);
      // if we're replacing an existing answerSource and there are display conditions associated
    } else if (existingSource && itemDisplayConditions.length) {
      setModalType("replaceDs");
      setShowModal(true);
    } else {
      openDataSetDrawer();
    }
  }

  function acceptDsChange() {
    clearItemConditions();
    clearAllSelectionValues();
    openDataSetDrawer();
  }

  function acceptOptionsChange() {
    clearItemConditions();
    removeDataSet();
  }

  function acceptReplaceDsChange() {
    clearItemConditions();
    openDataSetDrawer();
  }

  function acceptRemoveOptionChange(callbackValues: {
    value: { rootId: number };
    index: number;
  }) {
    if (callbackValues) {
      const { value, index } = callbackValues;
      value.rootId && removeSelectionCondition(value.rootId);
      removeSelection(index);
    }
  }

  function acceptUpdate(callbackValues?: unknown) {
    closeModal();

    switch (modalType) {
      case "ds":
        return acceptDsChange();
      case "options":
        return acceptOptionsChange();
      case "replaceDs":
        return acceptReplaceDsChange();
      case "removeOption":
        return acceptRemoveOptionChange(
          callbackValues as { value: { rootId: number }; index: number }
        );
    }
  }

  const dataSetLink = enableDataSets
    ? {
        onClick: handleAddDataSet,
        label: `${existingSource ? "Edit" : "Add"} Data Set`,
      }
    : null;

  return (
    <>
      <div className={error && touched ? ps.errorWrapper : ""}>
        {modalType && (
          // warning modal for switching selections source (custom vs data set)
          // or deleting a selection with associated display conditions
          <SwitchOptionsTypeModal
            show={showModal}
            modalType={modalType}
            modalCallbackValues={modalCallbackValues}
            onAccept={acceptUpdate}
            onDecline={closeModal}
          />
        )}

        <Label className={ps.label} htmlFor={name} assistiveLink={dataSetLink}>
          {label}
        </Label>

        <span className={ps.text}>{assistiveText}</span>

        {existingSource?.dataSourceKey ? (
          <DataSetBanner
            dataSourceKey={existingSource.dataSourceKey}
            onRemoveDataSet={handleRemoveDataSet}
          />
        ) : (
          <CustomAnswerOptions
            itemSubType={item.subType}
            name={name}
            onUpdate={onOptionUpdate}
            selectedOptions={selectedValues}
          />
        )}

        {enableDataSets && (
          <>
            <DataSetDrawer
              show={showDrawer}
              onAddDataSet={addDataSet}
              closeDrawer={() => setShowDrawer(false)}
              detailedSearchProps={detailedSearchProps}
              initialValues={existingSource}
              itemPath={itemPath}
              item={item}
              appWidgetsList={appWidgetsList}
            />
          </>
        )}
      </div>

      <FieldErrorMessage fieldName={name} />
    </>
  );
};

export default AnswerOptions;
