import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { PillRadioButton } from "../../../RadioButtons/RadioButton";
import {
  RadioButtonOption,
  RadioButtonsGroup,
} from "../../../RadioButtons/RadioButtonsGroup";
import CommentField from "../CommentField/CommentField";
import MoreOptionsLoader from "../MoreOptionsLoader/MoreOptionsLoader";
import styles from "../RadioField/RadioField.module.scss";
import { useQuestion } from "../useQuestion";
import { FieldMessagingWrapper } from "../FieldMessagingWrapper";

interface Props {
  question: QuestionDTO;
}
const options: RadioButtonOption<string>[] = [
  { data: "true", label: { text: "Yes" } },
  { data: "false", label: { text: "No" } },
];

export const YesNoField: FC<Props> = ({ question }) => {
  const {
    error,
    onBlur,
    questionSelections,
    label = "",
    name,
    assistiveText,
    value,
    handleChangeBasicResponse,
    required,
    response,
    onChangeComment,
    commentRequired,
    enableComments,
    commentValue,
    commentError,
    qa,
  } = useQuestion(question);
  if (!questionSelections && !options) return null;

  let loadMore: () => void | undefined;
  let isLoading: boolean;
  let isLastPage: boolean;
  isLoading = false;
  isLastPage = true;

  const handleChange = (data: string): void => {
    handleChangeBasicResponse({
      id: response?.id ?? undefined,
      answer: data,
    });
  };

  const handleChangeComment = (comments: string) => {
    onChangeComment(comments);
  };

  function handleClearComment(): void {
    onChangeComment("");
  }

  return (
    <FieldMessagingWrapper
      assistiveText={assistiveText}
      error={error}
      qa={qa}
      comments={
        enableComments &&
        value && (
          <CommentField
            className={styles.commentField}
            commentValue={commentValue}
            error={commentError}
            name={`${name}_comment`}
            handleClearComment={handleClearComment}
            handleUpdateComment={handleChangeComment}
            required={commentRequired}
            response={response}
            useDynamicTextArea
          />
        )
      }
    >
      <div
        className={joinClassNames(styles.container, error && styles.error)}
        onBlur={onBlur}
      >
        <RadioButtonsGroup
          label={label}
          name={name.toString()}
          onChange={handleChange}
          options={options}
          qa={qa}
          required={required}
          RadioButtonComponent={PillRadioButton}
          row={true}
          value={value || ""}
          error={error}
        />
        {question.answerSource?.dataSourceKey && (
          <MoreOptionsLoader
            onLoadMore={loadMore!}
            isLoadingMore={isLoading}
            isFinalPage={isLastPage}
          />
        )}
      </div>
    </FieldMessagingWrapper>
  );
};
