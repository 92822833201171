import { DefenseDTO, OETagDTO } from "@rtslabs/field1st-fe-common";
import * as yup from "yup";
import { CustomDefense } from "../../../../../../../api/formBuilder/defenseResource";
import { IconValue } from "../../../../../../common/Icon/IconSelector";

export type OETagDTOWithId = OETagDTO & { id: number };

export enum DefenseType {
  Global = "global",
  Custom = "custom",
  None = "none",
}

export interface CustomAnswerOptionsDrawerState {
  commentRequired: boolean;
  hideChoiceLabel: boolean;
  icon?: IconValue;
  newGlobalDefense?: DefenseDTO;
  newCustomDefense?: Partial<CustomDefense>;
  previousDefense?: DefenseDTO;
  selectedDefenseType: DefenseType;
  tags: OETagDTOWithId[];
  title: string; // choice label
}

export const validationSchema = yup.object().shape({
  commentRequired: yup.boolean(),
  hideChoiceLabel: yup.boolean(),
  newGlobalDefense: yup
    .object()
    .when(["selectedDefenseType", "previousDefense"], {
      is: (selectedDefenseType: DefenseType, previousDefense: DefenseDTO) =>
        selectedDefenseType === DefenseType.Global && !previousDefense,
      then: (schema) => schema.required("Please choose a defense"),
    }),
  newCustomDefense: yup.object().when("selectedDefenseType", {
    is: DefenseType.Custom,
    then: (schema) => {
      return schema.shape({
        description: yup.string().required("Please enter a description"),
        title: yup.string().required("Please enter a title"),
      });
    },
  }),
  tags: yup.array(),
  title: yup.string().trim().required("Please enter a label"),
});
