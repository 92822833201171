import { CoreRedux, NWS } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import Loader from "shared/src/components/Loader/Loader";
import { useAppSelector } from "../../../store/hooks";
import styles from "./WelcomeUserContainer.module.scss";
import { Components } from "shared/src/qa-slugs";
import { MulticolorIcon } from "shared/src/components/Icon/MulticolorIcon";
import { formatDayOfWeek, getTimeOfDay } from "../../../util/dateUtils";
import { getLocation } from "../../../../../shared/src/api/geolocationAPI";

interface WelcomeUserContainerProps {
  className?: string;
}

const formatTodaysDate = (date: Date) => {
  const dateString = new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "long",
    weekday: "long",
  }).format(date);
  return `Today is ${dateString}`;
};

const formatWelcome = (date: Date, name: string | undefined) => {
  switch (getTimeOfDay(date)) {
    case "afternoon":
      return name ? `Good Afternoon, ${name}` : "Good Afternoon";
    case "evening":
      return name ? `Good Evening, ${name}` : "Good Evening";
    case "morning":
      return name ? `Good Morning, ${name}` : "Good Morning";
  }
};

export const WelcomeUserContainer: FC<WelcomeUserContainerProps> = ({
  className,
}) => {
  const user = useAppSelector(CoreRedux.selectUser);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [weatherUpdate, setWeatherUpdate] = useState<NWS.WeatherUpdate>();

  useEffect(() => {
    const update = async () => {
      try {
        const geolocation = await getLocation(user?.workLocation);
        if (!geolocation) {
          setIsDataAvailable(false);
          return;
        }
        const update = await NWS.API.getWeatherUpdate(geolocation);
        if (update) {
          setIsDataAvailable(true);
          setWeatherUpdate(update);
        } else {
          setIsDataAvailable(false);
        }
      } catch (error) {
        setIsDataAvailable(false);
        console.error("Failed to get the forecast.", error);
      } finally {
        setHasLoadedOnce(true);
      }
    };

    const oneHourInMs = 3.6e6;
    update();
    const intervalId = setInterval(() => update(), oneHourInMs);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return (
    <section className={joinClassNames(styles.welcomeUserContainer, className)}>
      <h2
        className={styles.heading}
        data-testid={`${Components.Welcome}-heading`}
      >
        {formatWelcome(new Date(), user?.firstName)}
      </h2>
      <p
        className={styles.dateArea}
        data-testid={`${Components.Welcome}-currentDate`}
      >
        {formatTodaysDate(new Date())}
      </p>
      <Loader className={styles.loader} loading={!hasLoadedOnce}>
        {isDataAvailable && !!weatherUpdate ? (
          <>
            <div className={styles.locationArea}>
              <p
                className={styles.importantText}
                data-testid={`${Components.Welcome}-weatherLocation`}
              >
                {weatherUpdate.location}
              </p>
              <p
                className={styles.dayOfWeek}
                data-testid={`${Components.Welcome}-weatherUpdateDate`}
              >
                {formatDayOfWeek(new Date(weatherUpdate.updateTime))}
              </p>
              <p data-testid={`${Components.Welcome}-weatherConditions`}>
                {`Currently: ${weatherUpdate.shortForecast}`}
              </p>
            </div>
            <div className={styles.iconArea}>
              <MulticolorIcon type={weatherUpdate.icon.type} size={52} />
              <p
                className={styles.temperature}
                data-testid={`${Components.Welcome}-temperature`}
              >
                {weatherUpdate.temperature}
                <span className={styles.temperatureUnit}>°F</span>
              </p>
            </div>
            <div className={styles.metricsArea}>
              <p
                className={styles.secondaryText}
                data-testid={`${Components.Welcome}-humidity`}
              >
                {`Humidity: ${weatherUpdate.humidity}`}
              </p>
              <p
                className={styles.secondaryText}
                data-testid={`${Components.Welcome}-precipitation`}
              >
                {`Precipitation: ${weatherUpdate.precipitation}`}
              </p>
              <p
                className={styles.secondaryText}
                data-testid={`${Components.Welcome}-windSpeed`}
              >
                {`Wind: ${weatherUpdate.windSpeed}`}
              </p>
            </div>
          </>
        ) : (
          <div className={styles.noWeatherDataArea}>
            <div>
              <p
                className={styles.importantText}
                data-testid={`${Components.Welcome}-noWeatherDataMessage`}
              >
                Could not retrieve the weather
              </p>
              <p className={styles.secondaryText}>
                An unknown error has occurred.
              </p>
            </div>
            <MulticolorIcon
              className={styles.noWeatherDataIcon}
              type="weather-error"
              size={50}
            />
          </div>
        )}
      </Loader>
    </section>
  );
};
