import React, { FC, memo, useState } from "react";
import s from "../styles.module.scss";
import styles from "./FormBuilderSettings.module.scss";
import ss from "../baseUi/sidebar/styles.module.scss";
import { VerticalTabGroup } from "../../../Tabs/TabGroup/VerticalTabGroup";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { AdminSettings } from "./AdminSettings";

interface Props {
  id: number;
}

const QATestingPanel: FC<Props> = ({ id }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  return (
    <div className={s.contentWrapper}>
      <VerticalTabGroup
        variant="white"
        setSelectedTab={setSelectedTab}
        classes={{
          tabList: joinClassNames(styles.tabsContainer),
          tab: styles.settingsTab,
          tabActive: styles.tabActive,
          content: s.content,
          tabSubLabel: styles.tabSubLabel,
          tabLabel: styles.tabLabel,
        }}
        tabs={[
          {
            label: "Configuration Settings",
            tabId: "config-settings",
            subLabel: "Form properties to show/hide",
            tabPanelId: "config-settings",
            tabPanelContent: <AdminSettings id={id} />,
          },
        ]}
      />
      {/* placeholder so width matches Create tab */}
      <div className={ss.sidebarContainer} />
    </div>
  );
};

export default memo(QATestingPanel);
