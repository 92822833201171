import { DocumentVm } from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import React, { FC, useRef, useState } from "react";
import {
  default as ImageGallery,
  default as ReactImageGallery,
} from "react-image-gallery";
import { getFormItemByRootId } from "../../helpers/document.helpers";
import { QAProps } from "../../qa-slugs";
import { PhotosHandling } from "../Document/DocumentForm/usePhotos";
import { ModalPromptCloseButton } from "../Modal/ModalPromptCloseButton";
import { Photo } from "./Photo";
import styles from "./PhotoArea.module.scss";
import { PhotoGroup } from "./PhotoGroup";
import { PhotoInProgress } from "./PhotoInProgress";
import PhotoSlide from "./PhotoSlide";
import { PhotoTarget } from "./PhotoTarget";

interface Props {
  type: "IMAGES_ABOVE" | "IMAGES_BELOW";
  photosExpanded?: boolean;
  readOnly?: boolean;
}

type PhotoAreaProps = Props & PhotosHandling & QAProps;

const getQuestionTitleByRootId = (
  document: DocumentVm,
  rootId?: number | null
) => {
  const photoQuestion = getFormItemByRootId(document, Number(rootId));
  return photoQuestion?.type === "QUESTION"
    ? photoQuestion.title
    : "Photo Gallery";
};

export const PhotoArea: FC<PhotoAreaProps> = ({
  galleryPhotos,
  photoQuestionTitle,
  photosUploading,
  photos,
  handleDeletePhoto,
  handleUpdatePhoto,
  handleUploadPhoto,
  handleRetryPhoto,
  photosExpanded,
  qa,
  type,
  readOnly,
}) => {
  const { values } = useFormikContext<DocumentVm>();
  const [isCarouselVisible, setIsCarouselVisible] = useState<boolean>(false);
  const [currentPhoto, setCurrentPhoto] = useState<number>(0);
  const gallery = useRef<ReactImageGallery | null>(null);

  const slides = galleryPhotos.map((photo) => ({
    original: photo.imageUrl,
    thumbnail: photo.imageUrl,
    thumbnailHeight: 70,
    renderItem: () => {
      const questionTitle = getQuestionTitleByRootId(
        values,
        photo?.questionRootId
      );
      return (
        <PhotoSlide
          photoParticipantName={photo?.participant.firstName}
          photoQuestionTitle={questionTitle}
          original={photo.imageUrl}
          originalHeight={550}
          originalWidth={1000}
          description={photo.description}
          originalTitle={photo.description}
          originalAlt={photo.description}
        />
      );
    },
  }));

  const handleClick = (photoId: number) => {
    const selectedPhoto = galleryPhotos.findIndex((p) => p.id === photoId);
    gallery?.current?.fullScreen();
    setIsCarouselVisible(true);
    setCurrentPhoto(selectedPhoto);
  };

  const handleClose = () => {
    gallery?.current?.exitFullScreen();
  };

  return (
    <>
      {/* Photo Carousel */}
      <div className={isCarouselVisible ? styles.visible : styles.hidden}>
        <ImageGallery
          ref={gallery}
          onScreenChange={() => setIsCarouselVisible(false)}
          items={slides}
          showPlayButton={false}
          startIndex={currentPhoto}
          useBrowserFullscreen={false}
          renderFullscreenButton={() => (
            <ModalPromptCloseButton
              onClick={handleClose}
              className={styles.closeButton}
            />
          )}
        />
      </div>

      {/* Photo Gallery Photo Area */}
      {type === "IMAGES_ABOVE" && (
        <>
          <div className={styles.row}>
            {photos.map((p, idx) => {
              const questionTitle = getQuestionTitleByRootId(
                values,
                p?.questionRootId
              );
              return (
                <Photo
                  key={p.id}
                  onClick={() => handleClick(p.id)}
                  photo={p}
                  onDeletePhoto={handleDeletePhoto}
                  onUpdatePhoto={handleUpdatePhoto}
                  type={type}
                  questionTitle={questionTitle}
                  qa={qa ? `${qa}-${p.id}` : undefined}
                  readOnly={readOnly}
                />
              );
            })}
            {!readOnly && (
              <PhotoTarget
                type={type}
                questionTitle={photoQuestionTitle}
                qa={qa}
                onUploadPhoto={handleUploadPhoto}
              />
            )}
          </div>
          {photosUploading.map((p, idx) => (
            <PhotoInProgress
              key={idx}
              photo={p}
              onRetryPhoto={handleRetryPhoto}
              qa={qa ? `${qa}-${p.key}` : undefined}
            />
          ))}
        </>
      )}

      {/* Question Photo Area */}
      {type === "IMAGES_BELOW" && photosExpanded && (
        <div className={styles.photoAreaWrapper}>
          {!readOnly && (
            <PhotoTarget
              type={type}
              onUploadPhoto={handleUploadPhoto}
              qa={qa}
              questionTitle={photoQuestionTitle}
            />
          )}
          <PhotoGroup
            type={type}
            handleClick={handleClick}
            onRetryPhoto={handleRetryPhoto}
            onDeletePhoto={handleDeletePhoto}
            onUpdatePhoto={handleUpdatePhoto}
            photos={photos}
            photosUploading={photosUploading}
            questionTitle={photoQuestionTitle}
            qa={qa}
            readOnly={readOnly}
          />
        </div>
      )}
    </>
  );
};
