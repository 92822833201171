import React, { FC, Fragment } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { NavigationListExpansion } from "./NavigationListExpansion";
import { NavigationListLink } from "./NavigationListLink";

interface NavigationListProps {
  menuData: NavigationMenuLink[];
}

export interface NavigationMenuLink {
  disabled?: boolean;
  expansion?: NavigationMenuLink[];
  icon: string;
  id: string;
  link: any;
  linkTo?: string;
  roles?: string[];
  /** Routes that exist under the parent route but do not have their own navigation item
   *
   * Used for keeping the "parent" navigation item in active state when on a subroute
   */
  subRoutes?: string[];
}

// Builds an object that drives what's collapsed
// If a navigation item has an expansion property, then that property has a sub item with
// a `linkTo` of exact or partial `currentPath`, then the sub item's parent ID is added
// to an object that is then used for initial `expanded` value.
const getDefaultExpandedItems = (
  items: NavigationMenuLink[],
  pathName: string
) => {
  const result: { [id: string]: boolean } = {};
  if (items) {
    items.forEach((item) => {
      const expansionArray = item.expansion || [];
      if (expansionArray.length > 0) {
        if (
          expansionArray.some(
            (expansionItem) =>
              expansionItem.linkTo && pathName.includes(expansionItem.linkTo)
          )
        ) {
          result[item.id] = true;
        }
      }
    });
  }
  return result;
};

const NavigationList: FC<NavigationListProps> = ({ menuData }) => {
  const location = useLocation();
  const { pathname } = location;
  const defaultExpanded = getDefaultExpandedItems(menuData, pathname);
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const setOpen = (key: string, value: boolean) => {
    setExpanded({
      ...expanded,
      [key]: value,
    });
  };

  // determines if one of the nav items subroutes matches the current location
  const matchSubRoute = (subRoutes: string[]): boolean => {
    return subRoutes.some(
      (subRoute) => !!matchPath({ path: subRoute, end: true }, pathname)
    );
  };

  return (
    <Fragment>
      {menuData.map((menuItem: NavigationMenuLink) => {
        let active =
          pathname === menuItem.linkTo ||
          (!!menuItem.subRoutes && matchSubRoute(menuItem.subRoutes));
        return menuItem.expansion ? (
          <NavigationListExpansion
            active={active}
            expanded={expanded[menuItem.id || ""]}
            menuItem={menuItem}
            setOpen={setOpen}
            key={menuItem.id}
          />
        ) : (
          <NavigationListLink
            active={active}
            menuItem={menuItem}
            key={menuItem.id}
          />
        );
      })}
    </Fragment>
  );
};

export default NavigationList;
