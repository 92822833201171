import { API, DocumentQuestionResponseVm } from "@rtslabs/field1st-fe-common";

export async function createOrUpdateResponse(
  documentId: number,
  responseToUpdate: DocumentQuestionResponseVm
): Promise<DocumentQuestionResponseVm> {
  const res = await API.createOrUpdateDocumentResponse({
    documentId,
    response: responseToUpdate,
  });
  return res;
}

export const deleteResponse = async (
  documentId: number,
  responseId: number
): Promise<void> => {
  return API.deleteDocumentResponseById({ documentId, responseId });
};
