import React, { useState } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { Drawer, DrawerProps } from "../Drawer/Drawer";
import { Icon } from "../Icon/Icon";
import { TextInput } from "../TextInput/TextInput";
import { AdornmentProps } from "../TextInput/types";
import styles from "./SearchBar.module.scss";

interface SearchBarProps {
  autoFocus?: boolean;
  filterDrawer?: {
    props: DrawerProps & { onOpen: () => void };
    filterComponent: JSX.Element;
  };
  error?: string;
  assistiveText?: string;
  initialQuery?: string;
  label?: string;
  mobile?: boolean;
  name?: string;
  onSearch: (value: string) => void;
  openMobileFilters?: () => void;
  placeholder?: string;
  qa?: string;
  className?: string;
  waitForEnter?: boolean;
}

/**
 * Base component for search bar filters. Handles blur/focus/change/reset functions.
 */
export const SearchBar = ({
  autoFocus = false,
  filterDrawer: drawer,
  error,
  assistiveText,
  initialQuery = "",
  label,
  mobile,
  name = "searchBar",
  onSearch,
  openMobileFilters,
  placeholder,
  qa,
  className,
  waitForEnter,
}: SearchBarProps) => {
  const [query, setQuery] = useState(initialQuery);

  const search = (value: string) => {
    setQuery(value);
    if (
      !waitForEnter &&
      (value.length === 0 || // backspaced through search
        (value && value.length > 2))
    ) {
      onSearch(value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    search(e.target.value);
  };

  const resetSearch = () => {
    search("");
  };

  const enterCheck = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") onSearch(query);
  };

  const endAdornment: AdornmentProps = mobile
    ? {
        visible: !!query || !!drawer?.filterComponent,
        label: (
          <>
            {!!query && (
              <span onClick={resetSearch} className={styles.clearSearch}>
                Clear Search
              </span>
            )}
            {!!drawer?.filterComponent && (
              <span
                onClick={() => {
                  openMobileFilters?.();
                }}
              >
                <Icon type="mail_filter" />
              </span>
            )}
          </>
        ),
      }
    : {
        visible: !!query || !!drawer?.filterComponent,
        label: (
          <div className={styles.filtersContainer}>
            {!!query && (
              <span
                className={`mr-2 ${styles.clearSearch}`}
                data-testid={qa ? `${qa}-clearSearch` : undefined}
                onClick={resetSearch}
              >
                CLEAR
              </span>
            )}
            {!!drawer?.filterComponent && (
              <span onClick={drawer?.props.onOpen} className={styles.filter}>
                FILTERS
              </span>
            )}
          </div>
        ),
      };

  const startAdornment: AdornmentProps = {
    visible: !mobile,
    icon: "search",
    className: styles.searchIcon,
  };

  return (
    <div className={styles.searchBarWrapper}>
      <TextInput
        autoComplete="off"
        autoFocus={autoFocus}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        error={error}
        onKeyDown={enterCheck}
        onChange={handleChange}
        assistiveText={assistiveText}
        label={label}
        name={name}
        placeholder={placeholder || "Search"}
        rounded={true}
        value={query}
        qa={qa}
        wrapperClassName={joinClassNames(styles.searchInput, className)}
      />
      {drawer && (
        <Drawer
          isOpen={drawer.props.isOpen}
          anchor={drawer.props.anchor || "right"}
          className={drawer.props.className}
          labelId={drawer.props.labelId}
          id={drawer.props.id}
          onClose={drawer.props.onClose}
          qa={qa}
        >
          {drawer.filterComponent}
        </Drawer>
      )}
    </div>
  );
};

export default SearchBar;
