declare global {
  interface Navigator {
    msSaveBlob?(blob: Blob, title: string): void;
  }
}

/**
 * Prompt the user to download a file
 * @param blob  - Blob with file contents
 * @param title - title (with extension) of file to be downloaded
 */
function downloadFile(blob: Blob, title: string) {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, title);
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

/**
 * Convert a string to kebab case. Kebab case is lowercase-with-hypens.
 * @param value
 */
function toKebabCase(value?: string): string {
  return value ? value.toLowerCase().split(" ").join("-") : "";
}

export { downloadFile, toKebabCase };
