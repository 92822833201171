import { LogoImgProps } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import Logo from "../Logo";
import styles from "./auth.module.scss";

interface Props {
  status: string;
  logo?: LogoImgProps;
}

const SplashScreen: FC<Props> = ({ logo, status }) => (
  <div className={styles.splashScreen}>
    <div className={styles.content}>
      <Logo logo={logo} className={styles.logo} />
      <i className={styles.status}>{status}</i>
    </div>
  </div>
);

SplashScreen.displayName = "SplashScreen";
export default SplashScreen;
