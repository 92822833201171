import { useDebouncedValue } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { SIGNATURE_INPUT_DELAY } from "../../../../util/debounceDelays";
import { TextInput } from "../../../TextInput/TextInput";

interface Props {
  onUpdateSignature: (value: string) => void;
  initialValue: string;
  onStartDrawMode: () => void;
  label: string;
  participantId: string;
  error?: boolean;
}

export const TypeSignatureField: FC<Props> = ({
  onUpdateSignature,
  initialValue,
  onStartDrawMode,
  label,
  participantId,
  error,
}) => {
  const [textValue, setTextValue] = useState<string>(initialValue);

  const debouncedTextValue = useDebouncedValue(
    textValue,
    SIGNATURE_INPUT_DELAY
  );

  useEffect(() => {
    if (debouncedTextValue !== initialValue) {
      onUpdateSignature(debouncedTextValue);
    }
  }, [debouncedTextValue, initialValue]);

  return (
    <TextInput
      autoComplete="off"
      value={textValue}
      assistiveText="Enter name or email address of the employee or select “Add Signature” to digitally sign in the field"
      label={label}
      onChange={(e) => setTextValue(e.target.value)}
      name={participantId}
      placeholder="Enter an email address"
      assistiveLink={{
        label: "Add Signature",
        onClick: onStartDrawMode,
      }}
      error={error && "Please type a signature"}
      id={participantId}
    />
  );
};
