import {
  API,
  DocumentSubmissionType,
  DocumentSummaryVm,
  DocumentVm,
  FormVm,
  Functions,
  ParticipantUser,
} from "@rtslabs/field1st-fe-common";
import { get } from "lodash";
import moment from "moment";
import { useCallback } from "react";
import { matchPath, useNavigate } from "react-router-dom";
import { CreateRehuddleDocument } from "./types";

const getDocumentIDs = (selectedDocument?: DocumentSummaryVm | DocumentVm) => ({
  formId: get(selectedDocument, "formSummary.id"),
  parentId: get(selectedDocument, ["id"]),
  workOrderId: get(selectedDocument, ["workOrder", "id"]),
});

const pushOrReplaceIfPathMatches = (
  navigate: ReturnType<typeof useNavigate>,
  path: string,
  pathFormat: string
) => {
  const match = matchPath(
    {
      caseSensitive: false,
      end: true,
      path: pathFormat,
    },
    location.pathname
  );
  if (match !== null) {
    navigate(path, { replace: true });
  } else {
    navigate(path);
  }
};

interface HandleRehuddle {
  onSuccess: () => void;
  selectedDocument: DocumentSummaryVm | DocumentVm;
}

interface Return {
  handleRehuddle: (args: HandleRehuddle) => void;
}

/**
 * example:
 * const { handleRehuddle } = useHandleRehuddle()
 *
 * handleRehuddle(parentDocument)
 *
 * `handleRehuddle` will create and clone responses to a
 * new Document then push navigation to that new Document.
 */
const useHandleRehuddle = (user: ParticipantUser | null): Return => {
  const navigate = useNavigate();

  /**
   * Creates and returns a new (cloned) document based on the given values
   */

  const createRehuddleDocument = useCallback(
    async (documentIds: CreateRehuddleDocument) => {
      const submissionDate = moment().utc().format();
      return await API.createDocument({
        document: {
          ...documentIds,
          submissionDate: submissionDate,
          isRehuddle: true,
        },
      });
    },
    []
  );

  /**
   * Using the given Document, we'll create a new Document
   * with the same FormID then clone the responses of the
   * given Document into the new Document.
   */
  const handleRehuddle = useCallback(
    async ({ onSuccess, selectedDocument }: HandleRehuddle) => {
      const { parentId, formId, workOrderId } =
        getDocumentIDs(selectedDocument);
      if (parentId && formId && user) {
        const parentDocument = await API.getDocumentById({
          id: parentId,
        });

        const rehuddledDocument = await createRehuddleDocument({
          workOrderId,
          parentId,
          formId,
          ownerId: user.participantId,
          clientGroupId: user.primaryGroupId,
        });

        // If selectedDocument is a DocumentSummaryVm, then fetch the full form
        let childForm: FormVm;
        if ((selectedDocument as DocumentVm).form) {
          childForm = (selectedDocument as DocumentVm).form;
        } else {
          childForm = await API.getFormById({
            id: selectedDocument.formSummary.id,
          });
        }

        const clonedData = Functions.cloneRelatedDocumentResponsesAndPhotos({
          parentPhotos: parentDocument.photos,
          parentResponses: parentDocument.responses,
          parentSections: parentDocument.form.sections,
          childSections: childForm.sections,
        });

        await API.updateDocument({
          document: {
            ...rehuddledDocument,
            participants: parentDocument.participants ?? [],
            photos: clonedData.photos,
            responses: clonedData.responses,
            submissionType: DocumentSubmissionType.AUTO_SYNC,
            submissionDate: moment.utc().format(),
          },
        });

        onSuccess();
        pushOrReplaceIfPathMatches(
          navigate,
          `/document/${rehuddledDocument.id}`,
          "/document/:id"
        );
      }
    },
    []
  );

  return {
    handleRehuddle,
  };
};

export default useHandleRehuddle;
