import { camelCase } from "lodash";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Icon } from "shared/src/components/Icon/Icon";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Components, ElementType } from "shared/src/qa-slugs";
import { NavigationMenuLink } from "./NavigationList";
import styles from "./NavigationListLink.module.scss";

interface Props {
  active: boolean;
  inExpansion?: boolean;
  menuItem: NavigationMenuLink;
}

export const NavigationListLink: FC<Props> = ({
  active,
  inExpansion,
  menuItem,
}) => {
  return (
    <Link
      aria-current={active ? "page" : undefined}
      className={joinClassNames(
        styles.navigationListLink,
        styles.navigationItem,
        inExpansion && styles.subNavigationItem,
        active ? styles.active : styles.inactive,
        menuItem.disabled && styles.disabled
      )}
      data-testid={`${Components.SidebarNavigation}-${
        ElementType.Link
      }-${camelCase(menuItem.link)}`}
      to={menuItem.linkTo!}
    >
      <Icon type={menuItem.icon} className={styles.navigationItemIcon} />
      <div className={styles.navigationItemLink}>{menuItem.link}</div>
    </Link>
  );
};
