import React from "react";
import {
  DocumentHistoryType,
  DocumentSummaryVm,
  DocumentHistoryVm,
} from "@rtslabs/field1st-fe-common";
import {
  DocumentHistoryStatus,
  prettifyDocumentHistoryStatus,
} from "shared/src/helpers/document.helpers";
import { Table } from "shared/src/components/TableUI/Table/Table";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import { TableHead } from "shared/src/components/TableUI/TableHead/TableHead";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import HistoryDateCell from "shared/src/components/Documents/components/DocumentSidebarDrawer/DocumentHistory/HistoryDateCell";
import Loader from "shared/src/components/Loader/Loader";
import styles from "./documentHistory.module.scss";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
interface DocumentHistoryTableProps {
  document: DocumentSummaryVm;
  documentHistoryData: DocumentHistoryVm[];
  isDocumentHistoryDataLoading: boolean;
  isDocumentHistoryError: boolean;
  term: string;
}

const DocumentHistoryTable = ({
  document,
  documentHistoryData,
  isDocumentHistoryDataLoading,
  isDocumentHistoryError,
  term,
}: DocumentHistoryTableProps) => {
  const documentsTerm = conjugateTerm({
    term: term,
    role: "noun",
    modifier: undefined,
    fallback: "Document",
  }).toLowerCase();

  return (
    <Loader loading={isDocumentHistoryDataLoading}>
      {documentHistoryData.length > 0 ? (
        <Table>
          <TableHead
            columns={[
              { id: "action", label: "Action", isUnsortable: true },
              { id: "changedBy", label: "Changed By", isUnsortable: true },
              { id: "date", label: "Date", isUnsortable: true },
            ]}
          />
          <tbody>
            {documentHistoryData.map((d: DocumentHistoryVm, index: number) => {
              return (
                <TableRow key={`row-${index}`}>
                  <TableCell qa={d?.title}>
                    {getHistoryAction(
                      document.id,
                      d.documentId,
                      d.historyType,
                      documentsTerm,
                      d.title
                    )}
                  </TableCell>
                  <TableCell>
                    {(d.participant && d.participant.name) || "-"}
                  </TableCell>
                  <TableCell>
                    <HistoryDateCell historyDate={d.historyDate} />
                  </TableCell>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <span className={styles.noData}>No data available</span>
      )}
      {isDocumentHistoryError && (
        <ErrorText>{isDocumentHistoryError}</ErrorText>
      )}
    </Loader>
  );
};

export default DocumentHistoryTable;

function getHistoryAction(
  documentId: number,
  historyDocumentId: number | null | undefined,
  historyType: DocumentHistoryType,
  documentsTerm: string,
  qa: string | null | undefined
) {
  const status = prettifyDocumentHistoryStatus(
    historyType,
    documentId,
    historyDocumentId
  );
  if (
    historyDocumentId &&
    (status === DocumentHistoryStatus.original ||
      status === DocumentHistoryStatus.rehuddled)
  ) {
    return (
      <a
        href={`/${documentsTerm}/${historyDocumentId}`}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={qa}
      >
        {status}
      </a>
    );
  }
  return status;
}
