import React, {
  FC,
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./SectionNav.module.scss";
import { Icon } from "../../../Icon/Icon";
import { SectionItem } from "./SectionItem";
import { useNavigate } from "react-router-dom";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { Components, ElementType, QAProps } from "../../../../qa-slugs";
import { useSectionalContext } from "../hooks/useSectionalContext";
import { Sections } from "../../../../assets/svg/Sections";
import { useFormikContext } from "formik";
import { DocumentVm } from "@rtslabs/field1st-fe-common";
import { isEmpty } from "lodash";
import { getErrorsInSection } from "../../document.helpers";
import { useDocumentSection } from "../hooks/useDocumentSection";

interface SectionNavProps extends QAProps {}

export const SectionNav: FC<SectionNavProps> = ({
  qa = Components.SidebarNavigation,
}) => {
  const { currentSection, visibleSections } = useSectionalContext();
  const { getCurrentSectionIndex } = useDocumentSection();
  const { errors } = useFormikContext<DocumentVm>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentSectionIndex = getCurrentSectionIndex();

  const sectionRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  sectionRefs.current = visibleSections.map(
    (_, i) => sectionRefs.current[i] ?? createRef()
  );

  // this should make sure tab glows if errors are not in current section they are on
  const hasErrors = !isEmpty(errors);
  const errorsInSection = getErrorsInSection(currentSection, errors);
  const showGlowingErrorTab = hasErrors && !isActive && !errorsInSection.length;

  useEffect(() => {
    if (currentSectionIndex) {
      sectionRefs.current[currentSectionIndex].current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentSectionIndex]);

  return (
    <div
      className={joinClassNames(
        styles.sectionsDrawer,
        isActive && styles.active,
        showGlowingErrorTab && styles.errors
      )}
    >
      <div className={styles.sectionsTabWrapper}>
        <span
          className={styles.sectionsTab}
          onClick={() => setIsActive(!isActive)}
        >
          <Sections className={styles.sectionsIcon} /> Sections
        </span>
      </div>
      <div className={styles.mainNav}>
        <div
          className={joinClassNames(styles.navItem, styles.goBack)}
          onClick={() => navigate("/documents")}
          data-testid={`${qa}-${ElementType.MenuItem}-goBack`}
        >
          <Icon type={"chevron-left"} size={18} />
          Go Back
        </div>

        <div className={styles.navContainer}>
          {visibleSections.map((section, index) => {
            return (
              <div
                onClick={() => setIsActive(false)}
                key={index}
                ref={sectionRefs.current[index]}
              >
                <SectionItem
                  index={index}
                  section={section}
                  qa={`${qa}-${ElementType.MenuItem}-section${index}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
