import React from "react";
import { get } from "lodash";
import { useFormikContext } from "formik";

import { FBForm, FBItem, FBSection } from "../../../../clientAdmin/types";
import bs from "../../styles.module.scss";
import s from "../../create/validation/styles.module.scss";
import useValidation, {
  FBValidationLevel,
  FBValidationType,
} from "../../create/validation/useValidation";
import ValidationLink from "./ValidationLink";
import { joinClassNames } from "../../../../../helpers/theme.helpers";

const getCopy = (
  alertLevel: FBValidationLevel
): { title: string; description: string } => {
  if (alertLevel === "error") {
    return {
      title: "We’re sorry, we couldn’t save your form.",
      description:
        "You’re missing some required fields. Please correct the errors below and submit your form again.",
    };
  }
  return {
    title: "You’ll need to correct the issues below before publishing",
    description:
      "You’re missing some required fields. Please correct the fields below before publishing your form.",
  };
};

interface Props {
  onJumpToError: (errorKey: string, errorItem?: FBItem | FBSection) => void;
  errorType: FBValidationType;
}

const ValidationBanner = ({ onJumpToError, errorType }: Props) => {
  const { values } = useFormikContext<FBForm>();

  const { alertLevel, getErrorsByType } = useValidation();

  const validationErrors = getErrorsByType(errorType);

  const copy = getCopy(alertLevel);

  if (!validationErrors.length) return null;

  return (
    <div
      id={`fb-validation-${errorType}`}
      className={joinClassNames(
        bs.alert,
        alertLevel === "error"
          ? s.validationBannerError
          : s.validationBannerWarning,
        s.s16
      )}
    >
      <p className={joinClassNames(bs.alertTitle, s.s16)}>{copy.title}</p>
      <div className={joinClassNames(bs.alertBody, s.s16)}>
        <p>{copy.description}</p>

        {validationErrors?.map((valError) => {
          const { itemPath, errorPath, errorKey, error } = valError;
          const item = get(values, itemPath);

          return (
            <ValidationLink
              key={`${itemPath}-${errorKey}-error`}
              onClick={() => onJumpToError(errorKey, item)}
              title={errorPath}
              error={error}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ValidationBanner;
