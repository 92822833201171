import {
  API,
  DeviceEnum,
  DocumentUpdateVm,
  DocumentVm,
} from "@rtslabs/field1st-fe-common";
import { useState } from "react";
import { serializeError } from "serialize-error";

export function usePutDocument() {
  const [error, setError] = useState<string>("");
  const [submissionSuccessful, setSubmissionSuccessful] =
    useState<boolean>(false);

  async function handlePutDocument(
    values: DocumentVm
  ): Promise<DocumentVm | null> {
    try {
      const documentUpdateVm: DocumentUpdateVm = {
        documentDevice: DeviceEnum.WEB,
        formActionRequests:
          values.form.actions?.map((fa) => ({
            actionId: fa.id,
            requestBody: fa.actionType,
          })) || [],
        id: values.id,
        operationalExperiences: values.operationalExperiences,
        participants: values.participants,
        photos: values.photos,
        responses: values.responses,
        sectionComments: values.sectionComments,
        submissionDate: new Date().toISOString(),
        submissionType: values.submissionType,
      };
      const res = await API.updateDocument({
        document: documentUpdateVm,
      });

      if (
        res.submissionType === "SUBMIT" ||
        res.submissionType === "SAVE_DRAFT"
      ) {
        setSubmissionSuccessful(true);
      }
      return res;
    } catch (e) {
      const errorString = JSON.stringify(serializeError(e));
      setError(errorString);
      return null;
    }
  }

  return {
    error,
    setError,
    submissionSuccessful,
    handlePutDocument,
  };
}
