import { useEffect } from "react";
import { useFormikContext } from "formik";

import usePrevious from "../../../../util/hooks/usePrevious";

import { FBForm } from "../../types";
import { isEqual } from "lodash";
import { FormVm, useDebouncedValue } from "@rtslabs/field1st-fe-common";
import { AUTOSAVE_DEBOUNCE_DELAY } from "../../../../util/debounceDelays";

interface Props {
  onSave: (values: FBForm) => Promise<FormVm | undefined>;
  /** The values for which we want to trigger an auto-save */
  listeners: Partial<FBForm>;
}

const AutoSaveForm = ({ onSave, listeners }: Props): null => {
  const { values, isSubmitting, setSubmitting } = useFormikContext<FBForm>();
  /**
   * AutoSave the form
   *
   * Note: we don't want to unmarshall the form on every autosave, so if a
   * response is ever meant to be updated by the backend, we should explicitly
   * call handleSubmit for that case
   *
   * @param form
   */
  async function autoSave(form: FBForm) {
    setSubmitting(true);
    await onSave(form);
    setSubmitting(false);
  }

  const debouncedValues = useDebouncedValue(listeners, AUTOSAVE_DEBOUNCE_DELAY);
  const previousValues = usePrevious(debouncedValues);

  useEffect(() => {
    const hasDiff = !isEqual(debouncedValues, previousValues);
    // don't attempt submit if we're already submitting or on initial load
    if (
      values.workflowType !== "FINAL" &&
      values.workflowType !== "DEACTIVATED" &&
      previousValues &&
      hasDiff &&
      !isSubmitting
    ) {
      autoSave(values);
    }
  }, [debouncedValues]);

  return null;
};

export default AutoSaveForm;
