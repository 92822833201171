import {
  clampDate,
  getMonthYear,
  getStartOfToday,
} from "./datePickerFunctions";
import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { Calendar } from "./Calendar";
import { NavButton } from "./NavButton";

import styles from "./DatePicker.module.scss";

interface DatePickerProps {
  handleSelectionChange: (date: Moment) => void;
  isAutoFocusDisabled?: boolean;
  isSelectingEndDate?: boolean;
  maxDate?: Moment;
  minDate?: Moment;
  name: string;
  selectedEndDate?: Moment | null;
  selectedStartDate: Moment | null;
  shouldHideAutoFocus?: boolean;
}

const controlInstructions = "Cursor keys can navigate dates.";

export const DatePicker = ({
  handleSelectionChange,
  isAutoFocusDisabled,
  isSelectingEndDate,
  maxDate,
  minDate,
  name,
  selectedEndDate,
  selectedStartDate,
  shouldHideAutoFocus = true,
}: DatePickerProps) => {
  const [focusedDate, setFocusedDate] = useState(
    (isSelectingEndDate ? selectedEndDate : selectedStartDate) ||
      getStartOfToday()
  );
  const [instructions, setInstructions] = useState("");
  const [monthYear, setMonthYear] = useState(getMonthYear(focusedDate));
  const [shouldAutoFocus, setShouldAutoFocus] = useState(true);
  const [isAutoFocusHidden, setIsAutoFocusHidden] = useState(true);

  const headingLabelId = `${name}-heading`;

  const changeFocus = (date: Moment) => {
    const clampedDate = clampDate(date, minDate, maxDate);
    setFocusedDate(clampedDate);
    setMonthYear(getMonthYear(clampedDate));
  };

  const handleBlur = () => {
    setInstructions("");
  };

  const handleClickNextMonth = () => {
    const nextMonth = focusedDate.clone();
    nextMonth.add(1, "month");
    changeFocus(nextMonth);
    setShouldAutoFocus(false);
    setIsAutoFocusHidden(shouldHideAutoFocus);
  };

  const handleClickPreviousMonth = () => {
    const previousMonth = focusedDate.clone();
    previousMonth.subtract(1, "month");
    changeFocus(previousMonth);
    setShouldAutoFocus(false);
    setIsAutoFocusHidden(shouldHideAutoFocus);
  };

  const handleFocus = () => {
    setInstructions(controlInstructions);
  };

  const handleFocusChange = (date: Moment) => {
    changeFocus(date);
    setShouldAutoFocus(true);
    setIsAutoFocusHidden(false);
  };

  useEffect(() => {
    setFocusedDate(
      (isSelectingEndDate ? selectedEndDate : selectedStartDate) ||
        getStartOfToday()
    );
    setMonthYear(getMonthYear(focusedDate));
    setIsAutoFocusHidden(shouldHideAutoFocus);
  }, [isSelectingEndDate]);

  return (
    <div className={styles.datePicker}>
      <div className={styles.header}>
        <NavButton
          handleClick={handleClickPreviousMonth}
          iconStyle="back"
          label="Previous month"
        />
        <h2 aria-live="polite" className={styles.heading} id={headingLabelId}>
          {monthYear}
        </h2>
        <NavButton
          handleClick={handleClickNextMonth}
          iconStyle="forward"
          label="Next month"
        />
      </div>
      <Calendar
        focusedDate={focusedDate}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        handleFocusChange={handleFocusChange}
        handleSelectionChange={handleSelectionChange}
        labelId={headingLabelId}
        minDate={minDate}
        maxDate={maxDate}
        selectedEndDate={selectedEndDate}
        selectedStartDate={selectedStartDate}
        shouldAutoFocus={shouldAutoFocus && !isAutoFocusDisabled}
        shouldHideAutoFocus={isAutoFocusHidden}
      />
      <div aria-live="polite" className="sr-only">
        {instructions}
      </div>
    </div>
  );
};
