import { StyleSheet } from "aphrodite";
import { CSSProperties, useContext } from "react";
import { ThemeContext } from "styled-components";
import { remCalc } from "shared/src/themes/helpers";

export default function (): { [key: string]: CSSProperties } {
  const theme = useContext(ThemeContext);
  return StyleSheet.create({
    container: {
      color: theme.colors.primary,
    },

    possibleDefensesRow: {
      display: "flex",
      flexDirection: "row",
    },

    numDefenses: {
      fontSize: remCalc(48),
      lineHeight: remCalc(63),
    },

    possibleDefenses: {
      fontSize: remCalc(12),
      letterSpacing: remCalc(1.88),
      marginBottom: remCalc(36),
    },

    detailsContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      border: `1px solid ${theme.colors.primary}`,
      borderRadius: remCalc(4),
      backgroundColor: theme.colors.activeFill,
      width: remCalc(414),
    },

    selectedField: {
      display: "flex",
      flexDirection: "row",
      position: "absolute",
      top: `-${remCalc(20)}`,
      fontSize: remCalc(20),
      height: remCalc(40),
      width: remCalc(364),
      letterSpacing: remCalc(0.19),
      lineHeight: remCalc(26),
      padding: `${remCalc(6)} ${remCalc(10)}`,
      marginLeft: remCalc(25),
      color: theme.colors.white,
      backgroundColor: theme.colors.primary,
      borderRadius: remCalc(4),
    },

    detail: {
      display: "block",
      fontSize: remCalc(14),
      letterSpacing: remCalc(0.44),
      lineHeight: remCalc(19),
      color: theme.masterColors.darkGrey,
      padding: `${remCalc(31)} ${remCalc(25)} ${remCalc(13)}`,
    },

    triangle: {
      background: "transparent",
      width: 0,
      height: 0,
      borderLeft: `${remCalc(14)} solid transparent`,
      borderRight: `${remCalc(14)} solid transparent`,
      borderBottom: `${remCalc(14)} solid ${theme.colors.white}`,
      marginLeft: remCalc(25),
    },

    relatedCommentContainer: {
      backgroundColor: theme.colors.white,
      padding: `0 ${remCalc(25)} ${remCalc(16)}`,
      borderBottomRightRadius: remCalc(4),
      borderBottomLeftRadius: remCalc(4),
    },

    textField: {
      height: remCalc(30),
    },

    relatedCommentLabel: {
      fontSize: remCalc(14),
      letterSpacing: remCalc(0.25),
      lineHeight: remCalc(19),
      marginTop: remCalc(14),
      fontWeight: 500,
    },
  });
}
