import React from "react";
import { Button, TertiaryButton } from "../../Button/Button";
import { ModalPromptCloseButton } from "../../Modal/ModalPromptCloseButton";
import { ElementType } from "../../../qa-slugs";
import s from "./PillBox.module.scss";

interface MoreResultsConfirmationProps {
  handleClose: () => void;
  handleSubmit: () => void;
  search: string;
  component: React.ReactElement;
  qaBase?: string;
}

export const MoreResultsConfirmation = ({
  handleClose,
  handleSubmit,
  search,
  component,
  qaBase = ElementType.Modal,
}: MoreResultsConfirmationProps) => {
  return (
    <div className={s.modalContentWrapper}>
      <h1 className={s.modalPromptTitle}>{`All Results for "${search}"`}</h1>
      <ModalPromptCloseButton
        qa={`${qaBase}-${ElementType.Button}-close`}
        onClick={handleClose}
      />
      {component}
      <div className={s.modalButtonsWrapper}>
        <TertiaryButton
          qa={`${qaBase}-${ElementType.Button}-cancel`}
          className={s.buttonMargin}
          onClick={handleClose}
        >
          cancel
        </TertiaryButton>
        <Button
          qa={`${qaBase}-${ElementType.Button}-add-results`}
          onClick={handleSubmit}
        >
          Add Results
        </Button>
      </div>
    </div>
  );
};
