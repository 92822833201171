import React from "react";
import { css } from "aphrodite";

import styles from "./styles";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: Array<{ label: string; value: string }>;
  value: string;
}

export function Switch({ name, options, value, onChange }: Props) {
  const s = styles();
  return (
    <div className={css(s.switchWrapper)}>
      {options.map((opt, i) => (
        <>
          <input
            className={css(s.input)}
            type="radio"
            id={`${name}_${i}`}
            name={name}
            value={opt.value}
            checked={opt.value === value}
            onChange={onChange}
          />
          <label className={css(s.label)} htmlFor={`${name}_${i}`}>
            {opt.label}
          </label>
        </>
      ))}
    </div>
  );
}
