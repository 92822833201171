import ResourceDefenseDocUploadField from "../../../../../resources/fields/ResourceDefenseDocUploadField";
import ResourceDescriptionField from "../../../../../resources/fields/ResourceDescriptionField";
import ResourceTitleField from "../../../../../resources/fields/ResourceTitleField";
import { useField, useFormikContext } from "formik";
import React from "react";
import styles from "./AddDefense.module.scss";
import { UploadedFile } from "../../../../../../common/form/FileUpload/FileUploadField";
import { CustomAnswerOptionsDrawerState } from "../../drawers/CustomAnswerOptionsDrawer/customAnswerOptionsFormUtils";

const CustomDefenseForm: React.FC = () => {
  const { submitCount } = useFormikContext<CustomAnswerOptionsDrawerState>();
  const [titleField, titleMeta] = useField<string>("newCustomDefense.title");
  const [descriptionField, descriptionMeta, descriptionHelpers] =
    useField<string>("newCustomDefense.description");
  const [filesField, _filesMeta, filesHelpers] = useField<
    (UploadedFile | File)[]
  >("newCustomDefense.files");

  return (
    <>
      <ResourceTitleField
        {...titleField}
        error={
          submitCount > 0 || titleMeta.touched ? titleMeta.error : undefined
        }
        labelClass={styles.label}
        required
      />
      <ResourceDescriptionField
        {...descriptionField}
        error={
          submitCount > 0 || descriptionMeta.touched
            ? descriptionMeta.error
            : undefined
        }
        label="Description"
        labelClassName={styles.label}
        onBlur={() => descriptionHelpers.setTouched(true)}
        onChangeContent={(content?: string) =>
          descriptionHelpers.setValue(content ?? "")
        }
        placeholder="Resource description"
        required
      />
      <ResourceDefenseDocUploadField
        {...filesField}
        files={filesField.value}
        labelClass={styles.label}
        onUpdateFiles={(files: (UploadedFile | File)[]) =>
          filesHelpers.setValue(files)
        }
      />
    </>
  );
};

export default CustomDefenseForm;
