import {
  DocumentParticipantVm,
  DocumentVm,
  FormSectionVm,
  Functions,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import { validate } from "./DocumentForm/validation";
import { FormikErrors } from "formik";

export const getErrorsInSection = (
  section: FormSectionVm,
  errors: FormikErrors<DocumentVm>
): SectionItem[] => {
  const errorIds = Object.keys(errors);
  return section.items.filter((item) => errorIds.includes(item.id.toString()));
};

export const getDocumentRehuddleInfo = (formValues: DocumentVm) => {
  return `Rehuddle started at ${moment(formValues.dateCreated).format(
    "h:mma M/DD/YYYY"
  )}`;
};

export const getDocumentSubmissionInfo = (
  formValues: DocumentVm,
  altToastTitle?: string
): string => {
  if (formValues.submissionType === "SUBMIT") {
    return `Submitted ${altToastTitle || formValues.title} by ${
      formValues.latestAuthorName || formValues.owner.name
    } at ${moment(formValues.submissionDate).format("h:mma M/DD/YYYY")}`;
  } else {
    return `Saved (draft) ${altToastTitle || formValues.title} by ${
      formValues.latestAuthorName || formValues.owner.name
    } at ${moment(formValues.submissionDate).format("h:mma M/DD/YYYY")}`;
  }
};

export const getIsSectionValidated = (
  section: FormSectionVm,
  formValues: DocumentVm
): boolean => {
  const requiredItems = getRequiredItemsNotValidated(section, formValues);
  return !requiredItems.length;
};

export const getParticipantsNeedToSign = (
  sectionItems: SectionItem[],
  values: DocumentVm
): DocumentParticipantVm[] | undefined => {
  const signatureInSection = sectionItems.find(
    (item) => item.subType === "SIGNATURE"
  );
  const participantsInSection = sectionItems.filter(
    (item) => item.subType === "PARTICIPANT"
  );
  const hasSignAndPartQuestInSect =
    !!signatureInSection && !!participantsInSection.length;

  if (!hasSignAndPartQuestInSect) return undefined;

  const filteredParticipantsInSection = values.responses.filter((response) =>
    participantsInSection.some((pq) => pq.rootId === response.questionRootId)
  );

  const filteredParticipantsNeedToSign = values.participants.filter((sp) =>
    filteredParticipantsInSection?.some(
      (fp) => fp.associatedId === sp.participantId
    )
  );

  return filteredParticipantsNeedToSign;
};

export const getRequiredItemsNotValidated = (
  section: FormSectionVm,
  formValues: DocumentVm
): string[] => {
  const visibleItems = getVisibleItems(section, formValues);
  const requiredItemsIDs = visibleItems
    .filter(
      (vi) =>
        (vi.type === "QUESTION" && vi.formProperties?.isRequired) ||
        (vi.subType === "SIGNATURE" &&
          vi.signatureRequired &&
          formValues.participants.length)
    )
    .map((requiredItem) => requiredItem.id.toString());
  const filteredParticipantsNeedToSign = getParticipantsNeedToSign(
    section.items,
    formValues
  );
  const validateItemIDs = Object.keys(
    validate(formValues, filteredParticipantsNeedToSign, true)
  );
  const itemsNotValidated = validateItemIDs.filter((vid) =>
    requiredItemsIDs.includes(vid)
  );

  return itemsNotValidated;
};

export const getVisibleItems = (
  section: FormSectionVm,
  formValues: DocumentVm
): SectionItem[] => {
  return section.items!.filter((item) =>
    Functions.isTargetVisible(
      item.rootId,
      item.type,
      formValues.responses,
      formValues.form.displayConditions
    )
  );
};

export const getVisibleSections = (formValues: DocumentVm): FormSectionVm[] => {
  const visibleSections = formValues.form.sections.reduce(
    (results, section) => {
      if (
        Functions.isSectionVisible(
          section.rootId,
          formValues.responses,
          formValues.form.displayConditions
        )
      ) {
        results.push(section);
      }

      return results;
    },
    [] as FormSectionVm[]
  );

  return visibleSections;
};
