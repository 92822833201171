import {
  FormVersionDTO,
  FormVersionSaveDTO,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../Button/Button";
import { joinClassNames } from "../../../../../helpers/theme.helpers";
import { Components, ElementType } from "../../../../../qa-slugs";
import sidebarStyles from "../../baseUi/sidebar/styles.module.scss";
import { EditButton } from "../../baseUi/editButton/EditButton";
import styles from "./HistorySidebar.module.scss";

interface Props {
  currentTabIndex: number;
  nextTabIndex: number;
  formVersion: FormVersionDTO;
  preventTabChange(): void;
  proceedWithTabChange(): void;
  onUpdate(formVersionUpdate: FormVersionSaveDTO): void;
}

function HistorySidebar({
  currentTabIndex,
  nextTabIndex,
  formVersion,
  preventTabChange,
  proceedWithTabChange,
  onUpdate,
}: Props) {
  const [isEditingVersion, setIsEditingVersion] = useState(false);
  const [version, setVersion] = useState(formVersion.version || "");
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notes, setNotes] = useState(formVersion.notes || "");
  const [touched, setTouched] = useState(false);

  function shouldDiscardUnsavedChanges(): boolean {
    if (!touched) return true;
    return window.confirm("Unsaved changes will be discarded. Proceed?");
  }

  function reinitializeState(newFormVersion: FormVersionDTO) {
    setVersion(newFormVersion.version || "");
    setNotes(newFormVersion.notes || "");
    setTouched(false);
    setIsEditingNotes(false);
    setIsEditingVersion(false);
  }

  useEffect(() => {
    reinitializeState(formVersion);
  }, [formVersion]);

  useEffect(() => {
    if (nextTabIndex !== currentTabIndex) {
      shouldDiscardUnsavedChanges()
        ? proceedWithTabChange()
        : preventTabChange();
    }
  }, [nextTabIndex]);

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!touched) setTouched(true);
    setVersion(event.target.value);
  }

  function handleNotesChange(
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void {
    if (!touched) setTouched(true);
    setNotes(event.target.value);
  }

  function handleUpdatePress(): void {
    onUpdate({
      formId: formVersion.formId,
      notes,
      version,
    });
    setTouched(false);
  }

  return (
    <div className={sidebarStyles.sidebarContainer}>
      <div className={joinClassNames(sidebarStyles.sidebar, styles.container)}>
        <span className={styles.title}>
          Version Name{" "}
          <EditButton
            onClick={() => {
              setIsEditingVersion((currentValue) => !currentValue);
            }}
          />
        </span>
        <input
          type="text"
          className={
            isEditingVersion
              ? joinClassNames(styles.content, styles.contentEditable)
              : styles.content
          }
          readOnly={isEditingVersion ? undefined : true}
          value={version}
          onChange={handleNameChange}
        />
        <span className={styles.title}>Published</span>
        <span className={styles.content}>
          {moment(formVersion.lastModifiedDate).format("MM/DD/YYYY [at] h:mma")}
        </span>
        <span className={styles.title}>
          Version Notes{" "}
          <EditButton
            onClick={() => {
              setIsEditingNotes((currentValue) => !currentValue);
            }}
          />
        </span>
        <textarea
          className={
            isEditingNotes
              ? joinClassNames(styles.content, styles.contentEditable)
              : styles.content
          }
          readOnly={isEditingNotes ? undefined : true}
          value={notes}
          onChange={handleNotesChange}
        />
        <span className={styles.title}>Form Architect</span>
        <span className={styles.content}>{formVersion.lastModifiedBy}</span>
        <Button
          className={styles.button}
          qa={`${Components.FormList}-${ElementType.Button}-create-new-form`}
          onClick={handleUpdatePress}
          disabled={!touched}
        >
          {touched ? "UPDATE" : "UPDATED"}
        </Button>
      </div>
    </div>
  );
}

export default HistorySidebar;
