import React, { FC } from "react";
import { orderBy } from "lodash";

import { Drawer } from "shared/src/components/common/Drawer/components/Drawer";
import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import {
  ContentWrapper,
  Header,
  Value,
  ValueWrapper,
} from "../../common/StatsCard/styles";
import DrawerHeader from "shared/src/components/common/Drawer/components/Header";

import {
  Dot,
  GroupWrapper,
  LinkButton,
  Statistic,
} from "../reports/statistics/styles";
import { FormTypeStats } from "shared/src/components/clientAdmin/helpers";
import PercentLineGraph from "../reports/statistics/PercentLineGraph";
import { ElementType } from "shared/src/qa-slugs";
import Loader from "shared/src/components/Loader/Loader";
import { IconBox } from "shared/src/components/Icon/IconBox";

type Props = {
  closeDrawer: () => void;
  formStats: FormTypeStats;
  formType: FormTypeDTO;
  onGroupClick: (g: number) => void;
  show: boolean;
  isLoading: boolean;
  qa?: string;
};

export const GroupsListDrawer: FC<Props> = ({
  closeDrawer,
  formStats,
  formType,
  onGroupClick,
  show,
  isLoading,
  qa,
}) => {
  const groupsSorted = orderBy(
    formStats.groupCounts,
    ["percent"],
    ["desc"]
  ).filter((g) => g.total !== 0);

  return (
    <Drawer
      anchor="right"
      content={
        <div style={{ width: "30vw" }}>
          <DrawerHeader closeDrawer={closeDrawer} text={formType.name} />
          <Loader loading={isLoading}>
            <ContentWrapper>
              <div>
                <Header>{formType.name}</Header>
                <ValueWrapper>
                  <Value>{formStats.totalCount}</Value>
                </ValueWrapper>
              </div>
              {formType.iconName && (
                <IconBox
                  type={formType.iconName}
                  color={formType.iconColor ?? undefined}
                />
              )}
            </ContentWrapper>

            <PercentLineGraph
              total={formStats.totalCount}
              data={formStats.groupCounts}
            />

            {groupsSorted.map((group) => (
              <GroupWrapper
                key={group.id}
                className="d-flex w-100 justify-content-between my-2"
              >
                <div className="d-flex align-items-center">
                  <Dot color={group.color} className="mr-2" />
                  <LinkButton
                    data-testid={`${ElementType.Button}-${group.name}`}
                    onClick={() => onGroupClick(group.id)}
                  >
                    {group.name}
                  </LinkButton>
                </div>
                <div className="d-flex align-items-center">
                  <Statistic className="mr-3">{group.total}</Statistic>
                  <Statistic>{group.percent}%</Statistic>
                </div>
              </GroupWrapper>
            ))}
          </Loader>
        </div>
      }
      id="groupListDrawer"
      onClose={closeDrawer}
      onOpen={() => {}}
      open={show}
      showCloseIcon={false}
      qa={qa}
    />
  );
};
