import React, { FC } from "react";
import styled from "styled-components";

// This drawer still uses padding which we then remove
const Wrapper = styled.div`
  margin: -10px;
`;

const Header = styled.div`
  font-weight: 600;
  margin-left: 15px;
  position: relative;
  text-align: left;
  top: 20px;
`;

export const NavigationExpansionDrawerContent: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <Wrapper>
      <Header>Menu</Header>
      {children}
    </Wrapper>
  );
};
