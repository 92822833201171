import { ApplicationConfigKey } from "@rtslabs/field1st-fe-common";
import * as FS from "@fullstory/browser";
import {
  getApplicationConfigByName,
  getParticipantConfigForUser,
  getUser,
  getAllDefaultTermDefinitions,
  getClientGroupConfig,
  logout as CoreReduxLogout,
} from "@rtslabs/field1st-fe-common/dist/main/redux";
import { useAppDispatch } from ".";

export function useAuth() {
  const dispatch = useAppDispatch();

  async function getUserConfigs() {
    const user = await dispatch(getUser());

    FS.identify(user.userId.toString(), {
      displayName: user.fullName,
      ...user,
    });
    dispatch(
      getApplicationConfigByName({
        keyName: ApplicationConfigKey.clientOverrides,
      })
    );
    dispatch(
      getApplicationConfigByName({
        keyName: ApplicationConfigKey.mainNavigation,
      })
    );
    dispatch(
      getApplicationConfigByName({
        keyName: ApplicationConfigKey.dashboardSidebar,
      })
    );

    const tutorialDefaultState = {
      active: true,
      keyName: "tutorial",
      participantId: user.participantId,
      properties: {
        showTutorial: true,
      },
    };
    await dispatch(
      getParticipantConfigForUser({
        keyName: tutorialDefaultState.keyName,
        defaultState: tutorialDefaultState,
      })
    );

    const termsConfig = await dispatch(
      getClientGroupConfig({
        clientGroupId: user.primaryGroupId,
        keyName: "terms",
      })
    );
    const terms = termsConfig?.properties.terms;
    if (!terms || terms.length === 0) {
      dispatch(getAllDefaultTermDefinitions());
    }
  }

  function logout(): void {
    dispatch(CoreReduxLogout());
  }

  return {
    getUserConfigs,
    logout,
  };
}
