import { API, FormTypeDTO } from "@rtslabs/field1st-fe-common";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, TertiaryButton } from "../../Button/Button";
import { Select, SelectOption } from "../../Select/Select";
import { TextInput } from "../../TextInput/TextInput";
import * as yup from "yup";
import { ModalPromptCloseButton } from "../../Modal/ModalPromptCloseButton";
import { ElementType } from "../../../qa-slugs";
import Modal from "../../common/Modal";
import { FormListStyles as S } from "./styles";
import styles from "./styles.module.scss";

interface Values {
  formName: string;
  formType?: number;
}

interface Props {
  modalOpen: boolean;
  closeModal: () => void;
  formTypes: FormTypeDTO[];
  formTypeOptions: SelectOption<number>[];
  qaBase?: string;
}

const validation: yup.SchemaOf<Values> = yup.object().shape({
  formName: yup.string().required("Please enter a form name"),
  formType: yup.number().required("Please select a form type"),
});

export const FormListModal = ({
  modalOpen,
  closeModal,
  formTypes,
  formTypeOptions,
  qaBase,
}: Props) => {
  const navigate = useNavigate();
  const formik = useFormik<Values>({
    initialValues: {
      formName: "",
      formType: undefined,
    },
    onSubmit: (values: Values) => {
      const chosenFormType = formTypes.find(
        (formType) => formType.id === values.formType
      );
      chosenFormType &&
        API.updateForm({
          form: {
            name: values.formName,
            sections: [],
            type: chosenFormType,
            workflowType: "DRAFT",
            sharingEnabled: true,
            startFromWorkOrderEnabled: true,
            isRehuddleEligible: true,
          },
        }).then((res) => {
          navigate(`/forms/form/${res.id}`);
        });
    },
    validationSchema: validation,
    validateOnChange: false,
  });

  const handleClose = () => {
    formik.resetForm();
    closeModal();
  };

  return (
    <Modal
      qa={`${qaBase}-${ElementType.Modal}-createForm`}
      border="none"
      height="auto"
      width="456px"
      padding="24px"
      open={modalOpen}
      handleClose={handleClose}
      content={
        <div>
          <S.RowDiv>
            <S.ColumnDiv>
              <S.ModalHeader>Create a New Form</S.ModalHeader>
              <S.ModalText>
                To begin, add a form name and select the form type.
              </S.ModalText>
            </S.ColumnDiv>
            <ModalPromptCloseButton
              onClick={handleClose}
              qa={`${qaBase}-${ElementType.Button}-close`}
            />
          </S.RowDiv>
          <form onSubmit={formik.handleSubmit}>
            <TextInput
              name="formName"
              label="Form Name"
              placeholder="Form Name"
              onChange={formik.handleChange}
              error={formik.touched.formName && formik.errors.formName}
              qa={`${qaBase}-${ElementType.TextInput}-formName`}
            />
            <Select
              name="formType"
              label="Form Type"
              value={formik.values.formType}
              options={formTypeOptions}
              onChange={(option: SelectOption<number> | null) =>
                formik.setFieldValue("formType", option?.value)
              }
              error={formik.errors.formType}
              qa={`${qaBase}-${ElementType.SelectInput}-formType`}
            />
            <div className={styles.buttonWrapper}>
              <TertiaryButton
                className={styles.cancelButton}
                qa={`${qaBase}-${ElementType.Button}-cancel`}
                onClick={handleClose}
                type="reset"
              >
                CANCEL
              </TertiaryButton>
              <Button
                qa={`${qaBase}-${ElementType.Button}-createForm`}
                type="submit"
                loading={formik.isSubmitting}
                disabled={!formik.isValid}
              >
                {formik.isSubmitting ? "" : "CREATE FORM"}
              </Button>
            </div>
          </form>
        </div>
      }
    />
  );
};
