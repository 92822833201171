import React, { useState, FC } from "react";
import Loader from "../Loader/Loader";
import { Field, Form, Formik } from "formik";
import { newPasswordSchema } from "./helpers";
import { Components, ElementType } from "../../qa-slugs";
import styles from "./auth.module.scss";
import { TextInput } from "../TextInput/TextInput";
import { AuthRequestStatus } from "./types";
import AuthAnimatedButton from "./AuthAnimatedButton";
import Logo from "../Logo";
import { getVersionName } from "../../helpers/version";
import { LogoImgProps, PasswordResetVm } from "@rtslabs/field1st-fe-common";

const buttonText = (status?: AuthRequestStatus): string => {
  switch (status) {
    case "success":
      return "New Password Saved";
    case "failure":
      return "Unable to Save Password";
    default:
      return "Activate";
  }
};

interface Props {
  activationStatus?: AuthRequestStatus;
  keyValidationStatus?: AuthRequestStatus;
  loadingActivation?: boolean;
  loadingValidation?: boolean;
  onReturnToLogin: () => void;
  username: string | null;
  logo?: LogoImgProps;
  onActivate: (newPassword: PasswordResetVm["newPassword"]) => Promise<void>;
}

export const Activation: FC<Props> = ({
  activationStatus,
  keyValidationStatus,
  loadingActivation,
  loadingValidation,
  onReturnToLogin,
  onActivate,
  username,
  logo,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div className={styles.activation}>
      <Logo logo={logo} />
      <span className="Version">VERSION {getVersionName()}</span>

      <Loader loading={loadingValidation}>
        {keyValidationStatus === "failure" ? (
          <span className={styles.validationError}>
            Your key has expired. Please contact your system administrator for
            support.
          </span>
        ) : (
          <>
            <span className={styles.header}>Choose a Password</span>
            {username && (
              <span className={styles.message}>
                Choose a new password for username: <b>{username}</b>
              </span>
            )}

            <Formik
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              validationSchema={newPasswordSchema}
              onSubmit={(values) => onActivate(values.password)}
            >
              {({ errors }) => (
                <Form>
                  <Field
                    as={TextInput}
                    name="password"
                    label="New Password"
                    smallLabel
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    error={!showTooltip && errors["password"]}
                    onBlur={() => setShowTooltip(false)}
                    onFocus={() => setShowTooltip(true)}
                    endAdornment={{
                      visible: true,
                      button: true,
                      label: showPassword ? "Hide" : "Show",
                      handleClick: () => setShowPassword((sp) => !sp),
                    }}
                    qa={`${Components.Activation}-${ElementType.TextInput}-password`}
                  />
                  <Field
                    as={TextInput}
                    name="confirmPassword"
                    label="Confirm New Password"
                    smallLabel
                    placeholder="Password"
                    type="password"
                    error={errors["confirmPassword"]}
                    qa={`${Components.Activation}-${ElementType.TextInput}-confirmPassword`}
                  />
                  <AuthAnimatedButton
                    loading={loadingActivation}
                    className={styles.submitButton}
                    qa={`${Components.Activation}-${ElementType.Button}-submit`}
                    onComplete={onReturnToLogin}
                  >
                    {buttonText(activationStatus)}
                  </AuthAnimatedButton>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Loader>
    </div>
  );
};
