import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import withTheme from "@mui/styles/withTheme";

import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import Loader from "shared/src/components/Loader/Loader";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import Button, { TextButton } from "shared/src/components/common/Button";
import { downloadFile } from "../../../util";
import DragToUpload from "../../common/Upload/DragToUpload";
import s from "./styles.module.scss";
import { DSForm } from "./DSForm";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import {
  API,
  CSVMapping,
  DataSource,
  DataSourceStatus,
  DataSourceSyncStatus,
} from "@rtslabs/field1st-fe-common";

/* Component behavior is dictated by the existence of an "id" param in the URL:
 * 1. "id" exists: user is editing an existing source. fetch source and pre-fill form.
 * 2. "id" undefined: user is creating a new source. provide empty form. */

/* How source submission works:
 * 1. User fills out the metadata and column headers of the form
 *   - when this form is submitted:
 *     . a data source is created in "DRAFT" status
 *     . form complete boolean is set to true
 *     . data source is set in state
 *     . column headers are set in state
 * 2. Since formComplete is true, step 2 is enabled
 *   - user downloads the source headers in CSV format
 *   - user fills in any data records into the CSV and saves it locally
 *   - templateDownloaded is set to true
 * 3. Since formComplete and templateDownloaded are true, step 3 is enabled
 *   - user drags to upload or selects the file manually to upload
 *   - user saves the form, returning them to /forms/data-sets */

type Params = {
  id: string;
};

/**
 * Format an incoming data list into a EOL delimited string for rendering / user interaction
 * @param csvMappings
 */
const formatDataList = (csvMappings: CSVMapping[]) =>
  csvMappings.map((m) => m.columnName.replace("*", ""));

/** Parent view for adding, editing and viewing an uploaded Data Set */
function UploadedDS() {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<DataSource | null>(null);
  const [formComplete, setFormComplete] = React.useState<boolean>(false);
  const [colHeaders, setColHeaders] = React.useState<string[]>([]);
  const [templateDownloaded, setTemplateDownloaded] =
    React.useState<boolean>(false);
  const [csvUploaded, setCsvUploaded] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | null>(null);
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

  const dataSourceId = params.id ? Number(params.id) : undefined;

  /** Format and set column headers for display */
  const fmtAndSetHeaders = (csvMappings) =>
    setColHeaders(formatDataList(csvMappings));

  /** If an ID was supplied in the URL, fetch and store the data source matching it */
  React.useEffect(() => {
    (async () => {
      if (dataSourceId && !isNaN(dataSourceId)) {
        try {
          setLoading(true);
          const ds = await API.getDataSourceById({ id: dataSourceId });
          setDataSource(ds);
          fmtAndSetHeaders(ds.csvMappings);
          setFormComplete(true);
        } catch (err) {
          // TODO error handling -JA
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  /** When a file is added to the form, upload it */
  React.useEffect(() => {
    (async () => {
      setCsvUploaded(!!file);
    })();
  }, [file]);

  /** Fetch the CSV template (column headers) for a data source */
  const downloadTemplate = async (): Promise<void> => {
    if (dataSource?.id && dataSource.title) {
      try {
        const dsvTemplate = await API.downloadDataSourceValueTemplate({
          dataSourceId: dataSource.id,
          mediaType: "text/csv",
        });
        downloadFile(dsvTemplate.blob, dsvTemplate.fileName);
        setTemplateDownloaded(true);
      } catch (err) {
        // TODO error handling -JA
        console.error(err);
      }
    }
  };

  /** Fetch a data source CSV for an existing data source */
  const downloadSource = async (): Promise<void> => {
    if (dataSource?.id && dataSource.title) {
      try {
        const csv = await API.downloadDataSourceValues({
          dataSourceId: dataSource.id,
          mediaType: "text/csv",
        });
        downloadFile(csv.blob, csv.fileName);
      } catch (err) {
        // TODO error handling -JA
        console.error(err);
      }
    }
  };

  const publishSource = async (): Promise<void> => {
    if (dataSource) {
      if (file && dataSource?.id) {
        try {
          setSaveLoading(true);
          const dsv = await API.uploadDataSourceFile({
            dataSourceId: dataSource.id,
            file,
          });
          if (dsv.status === DataSourceSyncStatus.FILE_UPLOAD_SUCCESS) {
            const ds = {
              ...dataSource,
              softDeleteMissing: false,
              status: DataSourceStatus.PUBLISHED,
            };
            await API.updateDataSource({ dataSource: ds });
          } else {
            console.error("error");
          }
        } catch (e) {
          console.error(e);
        } finally {
          setSaveLoading(false);
        }
      } else {
        console.error("error");
      }
    }
  };

  return (
    <ContentWrapper>
      <Breadcrumbs
        paths={[
          { pathName: "Forms" },
          { pathName: "Data Sets", href: "/forms/data-sets" },
          {
            pathName: `Upload a Data Set${
              dataSource?.title ? `: ${dataSource.title}` : ""
            }`,
          },
        ]}
      />
      <Loader loading={loading}>
        <h1
          className={s.title}
          data-testid={`${Page.Desktop}-${Components.UploadedDS}`}
        >
          {dataSource?.title ? dataSource.title : "Upload a Data Set"}
        </h1>
        <span className={s.subtitle}>
          Uploaded data sets allow for multiple multi-columned rows that can be
          uploaded through the use of a CSV file. The three step process below
          can be used to create the data set. These data sets can then be used
          when building a form in Form Builder. If you have a short list of data
          with only one column,&nbsp;
          <span
            className={s.link}
            onClick={() => navigate("/forms/data-sets/basic")}
          >
            create a basic data set instead.
          </span>
        </span>
        <div className={s.titleWrapper}>
          <span className={s.sectionHeader}>
            1. Create title, description, and column headers
          </span>
          <TextButton
            className={s.editButton}
            onClick={() => setFormComplete(false)}
          >
            EDIT
          </TextButton>
        </div>
        <div className={s.formSection}>
          {!formComplete ? (
            <DSForm
              dataSource={dataSource}
              setFormComplete={setFormComplete}
              setDataSource={setDataSource}
              setColumnHeaders={fmtAndSetHeaders}
              uploaded
            />
          ) : (
            <div>
              {colHeaders.map((ch) => (
                <span className={s.blockSpan} key={`col_header_${ch}`}>
                  {ch}
                </span>
              ))}
            </div>
          )}
        </div>
        <hr />
        <span
          className={
            !formComplete && !dataSource
              ? s.sectionHeaderDisabled
              : s.sectionHeader
          }
        >
          2.{" "}
          {dataSourceId
            ? "Download current data set (CSV template)"
            : "Download template"}
        </span>
        {formComplete && dataSource && (
          <div className={s.formSection}>
            {dataSourceId ? (
              <Button
                qa={`${Components.UploadedDS}-${ElementType.Button}-downloadDataSet`}
                onClick={() => downloadSource()}
              >
                DOWNLOAD DATA SET
              </Button>
            ) : (
              <>
                <span className={s.subtitle}>
                  The column headers assigned in Step 1 are used to create a
                  template for the data set. The step is optional and allows you
                  to download an empty template to ensure column headers and
                  data are mapped correctly. You can skip this step if you
                  already have a data file that you&apos;re confident matches
                  the column headers above.
                </span>
                <Button
                  qa={`${Components.UploadedDS}-${ElementType.Button}-downloadTemplate`}
                  onClick={() => downloadTemplate()}
                >
                  {templateDownloaded ? "RE-DOWNLOAD" : "DOWNLOAD"} TEMPLATE
                </Button>
              </>
            )}
          </div>
        )}
        <hr />
        <span
          className={formComplete ? s.sectionHeader : s.sectionHeaderDisabled}
        >
          3. Import data set
        </span>
        {formComplete && (
          <div className={s.formSection}>
            <span className={s.subtitle}>
              Once you have a CSV file with all the pertinent data, upload it.
              Select the CSV file you wish to upload by locating on your local
              machine and dragging the file into the blue box below or click the
              link to browse for the file. Once the system indicates the upload
              is complete, select Save &amp; Continue.
            </span>
            <DragToUpload
              handleFile={setFile}
              parentLoading={uploading}
              successState={csvUploaded}
            />
            <Button
              qa={`${Components.UploadedDS}-${ElementType.Button}-saveAndContinue`}
              onClick={async () => {
                await publishSource();
                navigate(
                  "/forms/data-sets" +
                    (dataSource?.id ? `/uploaded/${dataSource.id}/view` : "")
                );
              }}
              disabled={!csvUploaded}
              loading={saveLoading}
            >
              SAVE & CONTINUE
            </Button>
          </div>
        )}
      </Loader>
    </ContentWrapper>
  );
}

export default withTheme(UploadedDS);
