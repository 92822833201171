import * as React from "react";
import styles from "./styles.module.scss";

import {
  APIState,
  PageOfDocumentSummaryVms,
  ReportingFormStatsVm,
} from "@rtslabs/field1st-fe-common";

import { TabGroup } from "shared/src/components/Tabs/TabGroup/TabGroup";

import {
  DocumentsTable,
  DocumentsPaginationProps,
  DocumentsPopperProps,
  DocumentsSortingProps,
} from "./DocumentsTable";
import FormReportSummary from "./ResponseSummaries";
import { SafetyReport } from "./SafetyObservationReport/SafetyReport";

import { FormReportSafetyResponses } from "./helpers";

interface FormTabsProps {
  formId: number;
  formStats: APIState<ReportingFormStatsVm>;
  documents: APIState<PageOfDocumentSummaryVms>;
  handleTabChange: (event: any, newValue: number) => void;
  onPaginate: (pageNum: number, numPerPage: number) => void;
  pagination: DocumentsPaginationProps;
  popper: DocumentsPopperProps;
  safetyReportProps: {
    totalResponses: number;
    ratingsFilter: string[];
    mappedRiskResponses: FormReportSafetyResponses;
  };
  shouldRenderSafetyTab: boolean;
  sorting: DocumentsSortingProps;
  value: number;
}

const FormTabs = React.forwardRef(
  (
    {
      documents,
      formId,
      formStats,
      handleTabChange,
      onPaginate,
      pagination,
      popper,
      safetyReportProps,
      shouldRenderSafetyTab,
      sorting,
      value,
    }: FormTabsProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    // const formVersionCall = useAPI(API.getFormVersionRecords, { formId });

    const reportComponents = {
      SafetyReport: <SafetyReport formId={formId} {...safetyReportProps} />,
      FormReportSummary: (
        <FormReportSummary
          formId={formId}
          formStats={formStats}
          switchToHistory={() => handleTabChange(undefined, 2)}
          visible={value === 0}
        />
      ),
      DocumentsTable: (
        <DocumentsTable
          documents={documents.data?.content || []}
          loading={documents.isLoading}
          totalPages={documents.data?.totalPages || 0}
          onPaginate={onPaginate}
          pagination={pagination}
          popper={popper}
          sorting={sorting}
        />
      ),
    };

    const PrintReport = (props) => {
      return (
        <div ref={props.refValue} className={styles.printReport}>
          {/* Safety Report */}
          {shouldRenderSafetyTab && (
            <div className={styles.pageBreak}>
              <h1>Safety Report</h1>
              {reportComponents.SafetyReport}
            </div>
          )}
          {/* Summary */}
          <div className={styles.pageBreak}>
            <h1>Summary</h1>
            {reportComponents.FormReportSummary}
          </div>
          {/* Details */}
          <div className={styles.pageBreak}>
            <h1>Details</h1>
            {reportComponents.DocumentsTable}
          </div>
        </div>
      );
    };

    const tabs = [
      {
        label: "Safety Report",
        tabId: "safety",
        tabPanelId: "safety",
        tabPanelContent: reportComponents.SafetyReport,
      },
      {
        label: "Summary",
        tabId: "summary",
        tabPanelId: "summary",
        tabPanelContent: reportComponents.FormReportSummary,
      },
      {
        label: "Details",
        tabId: "details",
        tabPanelId: "details",
        tabPanelContent: reportComponents.DocumentsTable,
      },
    ];

    return (
      <>
        <TabGroup
          variant="white"
          tabs={
            shouldRenderSafetyTab
              ? tabs
              : tabs.filter((tab) => tab.tabId !== "safety")
          }
        />
        {/* hide report unless printing */}
        <div style={{ display: "none" }}>
          <PrintReport refValue={ref} />
        </div>
      </>
    );
  }
);

export default FormTabs;
