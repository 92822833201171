import {
  API,
  FormVersionDTO,
  FormVersionSaveDTO,
  SortDirection,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useMergeState } from "../../../../util/hooks/useMergeState";
import {
  getHistorySortLabel,
  HistorySortOption,
  HistorySortSelectOption,
} from "../history/sortDropdown/HistorySortDropdownOptions";

export interface FormVersionState {
  formVersionRecords: FormVersionDTO[];
  historySortDirection: SortDirection;
  historySortSelectOption: HistorySortSelectOption;
  isLoadingFormVersions: boolean;
  isLoadingMoreFormVersions: boolean;
  setHistorySortDirection(newSortDirection: SortDirection): void;
  setHistorySortOption(newSortOption: HistorySortSelectOption): void;
  createOrUpdateFormVersionRecord: (
    formVersionRecord: FormVersionSaveDTO
  ) => Promise<FormVersionDTO | void>;
  loadMoreFormVersions(): void;
}

export function useFormVersion(formId: number): FormVersionState {
  const [filters, mergeFilters] = useMergeState<API.GetFormVersionRecordsArgs>({
    formId,
    sort: [HistorySortOption.PublishedDate, "desc"],
    page: 0,
  });

  const historySortOption = filters.sort![0] as HistorySortOption;
  const historySortSelectOption = {
    label: getHistorySortLabel(historySortOption),
    value: historySortOption,
  };
  const historySortDirection = filters.sort![1] as SortDirection;

  const formVersionRecordsCall = useAPI(API.getFormVersionRecords, {
    ...filters,
  });

  const [formVersionRecords, setFormVersionRecords] = useState<
    FormVersionDTO[]
  >([]);

  function setHistorySortDirection(sortDirection: SortDirection) {
    mergeFilters({ sort: [filters.sort![0], sortDirection], page: 0 });
  }

  function setHistorySortOption(sortOption: HistorySortSelectOption) {
    mergeFilters({ sort: [sortOption.value, filters.sort![1]], page: 0 });
  }

  function loadMoreFormVersions() {
    if (formVersionRecordsCall.data && !formVersionRecordsCall.data.last) {
      mergeFilters({ page: (filters.page || 0) + 1 });
    }
  }

  useEffect(() => {
    if (formVersionRecordsCall.data) {
      if (formVersionRecordsCall.data.first) {
        setFormVersionRecords(formVersionRecordsCall.data.content);
      } else {
        setFormVersionRecords((prevRecords) =>
          uniqBy(
            [...prevRecords, ...formVersionRecordsCall.data!.content],
            "formId"
          )
        );
      }
    }
  }, [formVersionRecordsCall.data]);

  const latestFinalFormVersion = useAPI(API.getLatestFinalFormVersion, {
    formId,
  });

  // Pin the latest final form version to the beginning of the list
  const pinnedFormVersions = latestFinalFormVersion.data
    ? [latestFinalFormVersion.data].concat(
        formVersionRecords.filter(
          (fv) => fv.formId !== latestFinalFormVersion.data!.formId
        )
      )
    : formVersionRecords;

  async function createOrUpdateFormVersionRecord(
    formVersionRecord: FormVersionSaveDTO
  ): Promise<void> {
    await API.createOrUpdateFormVersionRecord(formVersionRecord);
    formVersionRecordsCall.refresh();
    latestFinalFormVersion.refresh();
  }

  return {
    formVersionRecords: pinnedFormVersions,
    historySortDirection,
    historySortSelectOption,
    isLoadingFormVersions: formVersionRecordsCall.isLoading,
    isLoadingMoreFormVersions:
      formVersionRecordsCall.isLoading && filters.page !== 0,
    setHistorySortDirection,
    setHistorySortOption,
    createOrUpdateFormVersionRecord,
    loadMoreFormVersions,
  };
}
