import {
  API,
  DeviceEnum,
  DocumentSubmissionType,
  DocumentUpdateVm,
  DocumentVm,
  Functions,
  ParticipantUser,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";

export interface CreateRelatedDocArgs {
  parentDocumentId: number;
  targetFormId: number;
  targetOwner: ParticipantUser;
}

/**
 * createRelatedDoc is the first part of the "start related doc" feature
 * Namely, using a previously existing document, this function creates a new document of a different form template,
 * and copies over relevant responses.
 */
export async function createRelatedDoc({
  parentDocumentId,
  targetFormId,
  targetOwner,
}: CreateRelatedDocArgs): Promise<DocumentVm> {
  // Request parent document
  const parentDocument = await API.getDocumentById({
    id: parentDocumentId,
  });

  // Create related document
  const newDocument = await API.createDocument({
    document: {
      formId: targetFormId,
      parentId: parentDocumentId,
      workOrderId: parentDocument.workOrder?.id,
      ownerId: targetOwner.participantId,
      clientGroupId: targetOwner.primaryGroupId,
      submissionDate: moment().utc().format(),
      isRehuddle: false,
    },
  });

  // Clone cloneable questions and questions with the same title & type
  const clonedData = Functions.cloneRelatedDocumentResponsesAndPhotos({
    parentPhotos: parentDocument.photos,
    parentSections: parentDocument.form.sections,
    parentResponses: parentDocument.responses,
    childSections: newDocument.form.sections,
  });

  // if no responses were copied, then no need to update the document
  if (clonedData.responses.length === 0 && clonedData.photos.length === 0) {
    return newDocument;
  }

  // create participants on new related document
  const participants = Functions.generateParticipants(
    [],
    clonedData.responses,
    newDocument.form.sections
  );

  const documentUpdate: DocumentUpdateVm = {
    participants,
    photos: clonedData.photos,
    responses: clonedData.responses,
    id: newDocument.id,
    sectionComments: newDocument.sectionComments,
    formActionRequests: newDocument.formActionRequests,
    documentDevice: DeviceEnum.WEB,
    submissionType: DocumentSubmissionType.AUTO_SYNC,
    submissionDate: moment.utc().format(),
    // defenseSubmissions todo do we need these?
  };

  // Submit updated related document
  const updatedDocument = await API.updateDocument({
    document: documentUpdate,
  });

  return updatedDocument;
}
