import { QuestionSelectionsDTO } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { joinClassNames } from "../../../../../../../helpers/theme.helpers";
import { Icon } from "../../../../../../Icon/Icon";
import ps from "../../../properties/styles.module.scss";
import ss from "./styles.module.scss";
import ReorderButtons from "./ReorderButtons";
import { pluralize } from "../../../../../../../helpers/utils";
import { useFBConfigs } from "../../../../../../../util/hooks/useFBConfigs";

type ItemOptionProps = {
  item: QuestionSelectionsDTO;
  index: number;
  total: number;
  onRemoveItem: (id: number) => void;
  onClickItem: (id: number) => void;
  onMoveItem: (id: number, updatedIndex: number) => void;
};

const ItemOption: FC<ItemOptionProps> = ({
  item,
  index,
  total,
  onRemoveItem,
  onClickItem,
  onMoveItem,
}) => {
  const { formBuilderConfigs } = useFBConfigs();
  const { enableDefense, enableOE } = formBuilderConfigs;

  function handleRemoveItem(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    // LastPass causes the form to reset when clicking this sometimes. These e methods block it from wacking out
    e.preventDefault();
    e.stopPropagation();
    onRemoveItem(item.id);
  }

  function renderSelectionSubLabel(selection: QuestionSelectionsDTO): string {
    if (enableDefense || enableOE) {
      const { defenseIds, tags } = selection;
      const defenses = enableDefense
        ? `${defenseIds!.length} ${pluralize(
            "Defense",
            defenseIds?.length || 0,
            false
          )}`
        : "";
      const oes = enableOE
        ? `${tags!.length} ${pluralize("OE tag", tags?.length || 0, false)}`
        : "";

      return `Related: ${defenses}${
        enableDefense && enableOE ? ", " : ""
      }  ${oes}`;
    }

    return "";
  }

  return (
    <li className={ss.answerOptionItem} onClick={() => onClickItem(item.id)}>
      <ReorderButtons
        hide={{
          top: index === 0,
          bottom: index === total - 1,
        }}
        onReorder={(diff: number) => onMoveItem(item.id, index + diff)}
      />
      <div className={ss.answerOptionTitleBox}>
        <span className={ss.answerOptionTitle}>{item.title}</span>
        <p className={ss.answerOptionSubTitle}>
          {renderSelectionSubLabel(item)}
        </p>
      </div>
      <div className={ss.endAdornment} onClick={handleRemoveItem}>
        <Icon type={"delete_sign"} />
      </div>
    </li>
  );
};

interface CustomerAnswerOptionsListProps {
  onRemoveItem: (id: number) => void;
  onClickItem: (id: number) => void;
  onMoveItem: (id: number, updatedIndex: number) => void;
  items: QuestionSelectionsDTO[];
}

export const CustomerAnswerOptionsList = ({
  items,
  onRemoveItem,
  onClickItem,
  onMoveItem,
}: CustomerAnswerOptionsListProps) => {
  return (
    <div className={joinClassNames(ps.multiInput, ss.multiInput)}>
      <div className={ss.multiInputContent}>
        <ul className={ss.multiInputList}>
          {items.map((item, index) => (
            <ItemOption
              key={item.id}
              item={item}
              index={index}
              total={items.length}
              onRemoveItem={onRemoveItem}
              onClickItem={onClickItem}
              onMoveItem={onMoveItem}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};
