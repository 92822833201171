import React from "react";

import * as S from "./styles";

type StyleButtonProps = {
  active?: boolean;
  disabled?: boolean;
  label: string | React.ReactNode;
  onToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  qa?: string;
};

const StyleButton = ({
  active,
  disabled,
  label,
  onToggle,
  qa,
}: StyleButtonProps) => {
  const handleToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onToggle(e);
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // prevent the button from stealing focus
    e.preventDefault();
  };

  return (
    <S.StyleButton
      className="wysiwyg-style-button"
      disabled={disabled}
      type="button"
      active={active}
      onMouseDown={handleMouseDown}
      onClick={handleToggle}
      data-testid={qa}
    >
      {label}
    </S.StyleButton>
  );
};

export default StyleButton;
