import React from "react";

import s from "../styles.module.scss";

const PublishedBanner: React.FC<{ visible: boolean }> = ({ visible }) => {
  if (!visible) return null;

  return (
    <div className={s.publishedBanner}>
      This form is published. To make changes, click Edit and convert the form
      to a draft.
    </div>
  );
};

export default PublishedBanner;
