import React, { FC } from "react";
import { MapWidget } from "./MapWidget";
import DefensesWidget from "./widgets/DefensesWidget";
import { EsriContentWidget } from "./widgets/EsriContentWidget/EsriContentWidget";
import OEWidget from "./widgets/OEWidget";
import { PhotoGalleryWidget } from "shared/src/components/clientAdmin/formBuilder/create/content/widgets/PhotoGalleryWidget/PhotoGalleryWidget";
import RatingsWidget from "shared/src/components/clientAdmin/formBuilder/create/content/widgets/RatingsWidget";
import { WidgetType } from "shared/src/qa-slugs";
import { SignatureType, UnitOfLength } from "@rtslabs/field1st-fe-common";
import { initialParticipantAnswerSource } from "shared/src/components/clientAdmin/formBuilder/helpers";
import { ItemParams } from "shared/src/components/clientAdmin/formBuilder/create/Create";
import {
  AppWidgetProps,
  FBOEWidget,
} from "shared/src/components/clientAdmin/types";
import { TextInput } from "shared/src/components/TextInput/TextInput";

export const appWidgetsList: ItemParams[] = [
  {
    name: "Map",
    type: "WIDGET",
    subType: "MAP",
    includeMap: false,
    questions: [],
  },
  {
    name: "Defenses",
    type: "WIDGET",
    subType: "DEFENSES",
    autoAppendComment: false,
  },
  {
    name: "Operational Experiences",
    type: "WIDGET",
    subType: "OPERATIONAL_EXPERIENCES",
    numberRequired: 0,
  },
  {
    name: "Signatures",
    type: "WIDGET",
    subType: "SIGNATURE",
    allowedTypes: [SignatureType.DRAWN],
  },
  {
    name: "Safety Rating",
    type: "WIDGET",
    subType: "SAFETY_RATING",
    requireCommentsFor: [],
    allowComments: true,
    displayDescription: true,
    parentQuestionRootId: -1, // created in Create.tsx
  },
  {
    name: "Document Creator",
    title: "",
    type: "QUESTION",
    subType: "PARTICIPANT",
    participantRole: "SUPERVISOR",
    workflowType: "DRAFT",
    answerSource: {
      properties: {
        answerField: "fullName",
        associatedIdField: "participantId",
        autoComplete: true,
        readOnly: true,
      },
      type: "CURRENT_PARTICIPANT",
    },
    properties: {
      fakeWidgetType: "DOCUMENT_CREATOR",
    },
  },
  {
    name: "Supervisor",
    title: "",
    type: "QUESTION",
    subType: "PARTICIPANT",
    workflowType: "DRAFT",
    participantRole: "SUPERVISOR",
    properties: {
      fakeWidgetType: "SUPERVISOR",
      allowMultipleAnswers: true,
      excludeFromSignatures: true,
    },
    answerSource: {
      dataSourceKey: "PARTICIPANT",
      properties: {
        ...initialParticipantAnswerSource.properties,
        prefillAnswerField: "supervisor.fullName",
        prefillAssociatedIdField: "supervisor.id",
      },
      type: "CURRENT_PARTICIPANT",
    },
  },
  {
    name: "Photo Gallery",
    type: "WIDGET",
    subType: "PHOTO_GALLERY",
    questions: [],
  },
  {
    includeMap: false,
    esriFieldResponsesAreRequired: false,
    name: "Esri Content",
    subType: "ESRI_CONTENT",
    type: "WIDGET",
    layerId: -1,
    portalItemId: "",
    maxDistance: 0,
    unitOfMeasure: UnitOfLength.FEET,
  },
];

export const AppWidgets: FC<AppWidgetProps> = ({
  item,
  qaBase,
  selected,
  siblings,
  itemName,
}) => {
  switch (item.subType) {
    case "DEFENSES":
      return (
        <DefensesWidget
          qa={`${qaBase}-${WidgetType.OperationalExperiences}-${item.itemIndex}`}
          item={item}
        />
      );
    case "OPERATIONAL_EXPERIENCES":
      return (
        <OEWidget
          qa={`${qaBase}-${WidgetType.OperationalExperiences}-${item.itemIndex}`}
          selected={selected}
          item={item as FBOEWidget}
        />
      );
    case "MAP":
      return (
        <MapWidget
          qa={`${qaBase}-${WidgetType.Map}-${item.itemIndex}`}
          item={item}
          siblings={siblings}
          disableControls
        />
      );
    case "SAFETY_RATING":
      return (
        <RatingsWidget
          qa={`${qaBase}-${WidgetType.SafetyRating}-${item.itemIndex}`}
          item={item}
        />
      );
    case "SIGNATURE":
      return (
        <TextInput
          qa={`${qaBase}-${WidgetType.Signature}-${item.itemIndex}`}
          name={itemName}
          value=""
          placeholder={
            item.allowedTypes.indexOf(SignatureType.TYPED_EMAIL) >= 0
              ? "Enter an email address"
              : 'Press "Add Signature" to sign'
          }
          assistiveText={`${
            item.allowedTypes.indexOf(SignatureType.TYPED_EMAIL) >= 0
              ? "Enter email address or s"
              : "S"
          }elect “Add Signature” to digitally sign in the field`}
          label="[First Name] [“Nick”] [Last Name]"
          onChange={() => null}
          assistiveLink={{
            label: "Add Signature",
            onClick: () => null,
          }}
        />
      );
    case "PHOTO_GALLERY":
      return (
        <PhotoGalleryWidget
          qa={`${qaBase}-${WidgetType.PhotoGallery}-${item.itemIndex}`}
        />
      );
    case "ESRI_CONTENT":
      return (
        <EsriContentWidget
          qa={`${qaBase}-${WidgetType.EsriContent}-${item.itemIndex}`}
          item={item}
        />
      );
    default:
      return null;
  }
};
