import React, { FC } from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import styles from "./Table.module.scss";

interface TableProps {
  children?: React.ReactNode;
  className?: string;
  fixedLayout?: boolean;
}

export const Table: FC<TableProps> = ({ children, className, fixedLayout }) => (
  <table
    className={joinClassNames(
      styles.table,
      fixedLayout && styles.fixed,
      className
    )}
  >
    {children}
  </table>
);
