export const formatToHtmlTags = (input: string): string => {
  // convert numbered items to hyphens except each parents. It'll have space before it.
  let formatted = input.replace(/(?!^)(?<!\n)\d+\.\s/g, "- ");

  // In rare cases, there is a chance that bullet items are not indented properly, was aligned
  // with the parent list item. So, lets space it out to make it formatted correctly.
  formatted = formatted.replace(/(\n-\s)(.*?)(?=\n|$)/g, "\n - $2");

  // sub points
  formatted = formatted
    .replace(/(\n\s{5,}-\s)(.*?)(?=\n|$)/g, "<ul><li>$2</li></ul>")
    .replace(/<\/ul><ul>/g, "");

  // main points
  formatted = formatted
    .replace(/(\n\s{2,4}-\s)(.*?)(?=\n|$)/g, "<ul><li>$2</li></ul>")
    .replace(/<\/ul><ul>/g, "");

  // capture the remaining stragglers. Some responses would format with no spaces before -
  formatted = formatted
    .replace(/(\n\s-\s)(.*?)(?=\n|$)/g, "<ul><li>$2</li></ul>")
    .replace(/<\/ul>\n\s{0,}<ul>/g, "");

  // parent list and clean out the remaining \n
  formatted = formatted
    .replace(/(\d+\.\s)(.*?)(?=\d+\.\s|$)/gs, "<ul><li>$2</li></ul>")
    .replace(/<\/ul>(\n){1,}<\/li>/g, "</ul></li>")
    .replace(/\n/g, "<br />")
    .replace(/<\/ul><ul>/g, "");

  // bold
  formatted = formatted.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

  return formatted;
};

export const updateImageUrl = (url: string) => {
  const newUrl = new URL(url);
  // This logic is to replace url to use the aws s3 for chatGPT to read the image out of vpn.
  if (
    window.location.hostname.indexOf("dev") >= 0 ||
    window.location.hostname.indexOf("localhost") >= 0
  ) {
    newUrl.hostname = "ff-dev-rts-document-attachments.s3.amazonaws.com";
  } else if (window.location.hostname.indexOf("qa") >= 0) {
    newUrl.hostname = "ff-qa-rts-document-attachments.s3.amazonaws.com";
  } else if (window.location.hostname.indexOf("demo") >= 0) {
    newUrl.hostname = "ff-demo-rts-document-attachments.s3.amazonaws.com";
  }

  return newUrl;
};
