import React, { forwardRef, useMemo, useState } from "react";
import { LinkButton } from "../../../Button/Button";

import { DocumentQuestionResponseVm } from "@rtslabs/field1st-fe-common";
import { debounce } from "lodash";
import { ElementType, QAProps } from "../../../../qa-slugs";
import { AUTOSAVE_DEBOUNCE_DELAY } from "../../../../util/debounceDelays";
import ErrorText from "../../../ErrorText/ErrorText";
import { TextInput, TextInputRef } from "../../../TextInput/TextInput";
import styles from "./CommentField.module.scss";

export interface CommentFieldProps {
  className?: string;
  commentValue?: string;
  error?: string;
  disabled?: boolean;
  handleClearComment: (response?: DocumentQuestionResponseVm) => void;
  handleUpdateComment: (
    value: string,
    response?: DocumentQuestionResponseVm
  ) => void;
  name: string;
  required?: boolean;
  rows?: number;
  response?: DocumentQuestionResponseVm;
  useDynamicTextArea?: boolean;
}

const CommentField = forwardRef<TextInputRef, CommentFieldProps & QAProps>(
  (
    {
      className,
      commentValue,
      error,
      disabled,
      handleClearComment,
      handleUpdateComment,
      name,
      required,
      rows = 1,
      qa,
      response,
      useDynamicTextArea,
    },
    ref
  ) => {
    const [newComments, setNewComments] = useState<string>(commentValue ?? "");

    const debouncedChangeHandler = useMemo(
      () =>
        debounce(handleUpdateComment, AUTOSAVE_DEBOUNCE_DELAY, {
          trailing: true,
        }),
      [response]
    );

    if (disabled) {
      return null;
    }

    return (
      <div className={styles.commentContainer}>
        <div className={className}>
          <TextInput
            endAdornment={{
              visible: !!required,
              label: "* required",
              className: styles.required,
            }}
            ref={ref}
            error={!!error}
            required={required}
            name={name}
            value={newComments}
            placeholder="Add comment"
            onChange={({ target: { value } }) => {
              setNewComments(value);
              debouncedChangeHandler.cancel();
              debouncedChangeHandler(value, response ?? undefined);
            }}
            multiline={useDynamicTextArea || rows > 1}
            rows={rows}
            rounded={true}
            qa={qa}
            wrapperClassName={styles.inputWrapper}
            useDynamicTextArea={useDynamicTextArea}
          />
          {error && <ErrorText>{error}</ErrorText>}
        </div>
        {commentValue && (
          <LinkButton
            onClick={() => {
              setNewComments("");
              handleClearComment(response ?? undefined);
            }}
            className={styles.removeCommentLink}
            qa={qa ? `${qa}-${ElementType.Button}-removeComment` : undefined}
          >
            Remove Comment
          </LinkButton>
        )}
      </div>
    );
  }
);

export default CommentField;
