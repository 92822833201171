import React from "react";
import { DangerOption, Option, PopperChevron, PopperRow } from "./styles";

type Props = {
  onClick: () => unknown;
  label: string;
  danger?: boolean;
  qa?: string;
};

export const ActionMenuItem = ({ onClick, label, danger, qa }: Props) => (
  <PopperRow data-testid={qa} onClick={onClick}>
    {danger ? <DangerOption>{label}</DangerOption> : <Option>{label}</Option>}
    <PopperChevron />
  </PopperRow>
);
