import React, { FC } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps } from "../../qa-slugs";
import styles from "./ListItem.module.scss";

interface Props extends QAProps {
  children?: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export const ListItem: FC<Props> = ({
  qa,
  active,
  onClick,
  disabled,
  children,
  className,
}) => {
  return (
    <li
      className={joinClassNames(
        styles.listItem,
        active && styles.active,
        disabled && styles.disabled,
        className
      )}
      onClick={onClick}
      data-testid={qa}
    >
      {children}
    </li>
  );
};
