import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  API,
  CoreRedux,
  FormTypeDTO,
  ReportingFormTypeDocumentCountVm,
  SortDirection,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import Loader from "shared/src/components/Loader/Loader";
import { GroupsListDrawer } from "../groups/GroupsListDrawer";
import {
  generateGroupOptions,
  getStatsForFormType,
} from "shared/src/components/clientAdmin/helpers";
import { H1 } from "shared/src/components/clientAdmin/styles";
import { ReportsFilters } from "./ReportsFilters";
import { ReportsTable } from "./ReportsTable";
import FormTypeSummary from "./statistics/FormTypeSummary";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import s from "./styles.module.scss";
import {
  DateRangeValue,
  getMinMaxSubmissionDatesFromTimeFilter,
  TimeFilter,
} from "shared/src/data/timeFilters";
import { getPastDays } from "shared/src/helpers/filters";
import { Icon } from "shared/src/components/Icon/Icon";
import AiChatButton from "../../Document/AiChatDrawer/AiChatButton";
import AiChatDrawer from "../../Document/AiChatDrawer/AiChatDrawer";
import Text from "shared/src/components/common/Text";

const ChatDrawerTitle = (
  <span className={s.drawerTitle}>
    <Icon type="star" className={s.titleIcon} />
    <Text className={s.drawerTitleText}>Reporting Insights</Text>
  </span>
);
const Reports: React.FC = () => {
  const ownClientGroups = useSelector(CoreRedux.getUserGroups);
  const [queryParams] = useSearchParams();
  const groupParam = queryParams.get("group");
  const defaultGroupId = groupParam ? Number(groupParam) : undefined;
  const groupFilterOptions = generateGroupOptions(ownClientGroups);

  const [documentType, setDocumentType] = useState<number>();
  const [clientGroupId, setClientGroupId] = useState<number | undefined>(
    defaultGroupId
  );
  const [dateRange, setDateRange] = useState<DateRangeValue>();
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(
    TimeFilter.THIRTY_DAYS
  );
  const [query, setQuery] = useState<string>("");
  const [newPageNum, setNewPageNum] = useState<number>(0);
  const [newPageSize, setNewPageSize] = useState<number>(10);
  const [sortingType, setSortingType] = useState<string>("name");
  const [sortingDirection, setSortingDirection] =
    useState<SortDirection>("asc");
  const [showGroupsDrawer, setShowGroupsDrawer] = useState<boolean>(false);
  const [selectedFormType, setSelectedFormType] = useState<FormTypeDTO | null>(
    null
  );
  const [queryString, setQueryString] = useState<string>("");
  const [formTypeData, setFormTypeData] = useState<
    ReportingFormTypeDocumentCountVm[] | null
  >(null);
  const [selectedFormTypeData, setSelectedFormTypeData] =
    useState<ReportingFormTypeDocumentCountVm | null>(null);

  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState<boolean>(false);

  const { minSubmissionDate, maxSubmissionDate } =
    getMinMaxSubmissionDatesFromTimeFilter(timeFilter, dateRange);

  // get form type data
  const allFormTypeStatsCall = useAPI(API.getFormTypesReport, {
    minSubmissionDate,
    maxSubmissionDate,
    clientGroupId: !showGroupsDrawer ? clientGroupId : undefined,
    onlySubordinates: false,
  });

  // get form list
  const allFormStatsCall = useAPI(API.getFormsReport, {
    clientGroupId: !showGroupsDrawer ? clientGroupId : undefined,
    minSubmissionDate,
    maxSubmissionDate,
    formTypeId: documentType,
    query,
    page: newPageNum,
    size: newPageSize,
    sort: [sortingType, sortingDirection],
  });

  useEffect(() => {
    // build and store a query string for persisting filters to FormReport
    if (timeFilter === "CUSTOM_RANGE" && !dateRange) {
      setQueryString("");
    } else {
      const pastDays = getPastDays(timeFilter);
      setQueryString(
        "?" +
          `${pastDays ? `pastDays=${pastDays}&` : ""}` +
          `${clientGroupId ? `clientGroupId=${clientGroupId}` : ""}`
      );
    }
  }, [dateRange, timeFilter, clientGroupId]);

  useEffect(() => {
    if (allFormTypeStatsCall.data) {
      if (showGroupsDrawer && selectedFormType?.id) {
        const data = allFormTypeStatsCall.data.find(
          (d) => d.formType.id === selectedFormType.id
        );
        if (data) {
          setSelectedFormTypeData(data);
          return;
        }
      } else {
        setFormTypeData(allFormTypeStatsCall.data);
      }
    }
    setSelectedFormTypeData(null);
  }, [allFormTypeStatsCall.data, selectedFormType]);

  /**
   * Set current form type in state and open the groups drawer
   * @param formType  - formType selected from summary
   */
  const openGroupsDrawer = (formType: FormTypeDTO): void => {
    setSelectedFormType(formType);
    setNewPageNum(0);
    setDocumentType(formType.id);
    setShowGroupsDrawer(true);
  };

  const closeGroupsDrawer = (): void => {
    setSelectedFormType(null);
    setShowGroupsDrawer(false);
    setDocumentType(undefined);
  };

  // @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
  // @TODO: UPDATE with a better solution - Trevor Kirpaul
  return (
    <ContentWrapper id="mainContent">
      {selectedFormType && selectedFormTypeData && (
        <GroupsListDrawer
          qa={`${Components.Reports}-${
            ElementType.Drawer
          }-viewAllGroups-${selectedFormType.name.replaceAll(/\s/g, "")}`}
          closeDrawer={closeGroupsDrawer}
          formStats={getStatsForFormType(selectedFormTypeData)}
          formType={selectedFormType}
          onGroupClick={(groupId: number): void => {
            setNewPageNum(0);
            setClientGroupId(groupId);
            setDocumentType(selectedFormType.id);
            setShowGroupsDrawer(false);
          }}
          show={showGroupsDrawer}
          isLoading={allFormTypeStatsCall.isLoading}
        />
      )}
      {/* <AiChatDrawer
        open={isChatDrawerOpen}
        onClose={() => setIsChatDrawerOpen(false)}
        title={ChatDrawerTitle}
      /> */}
      <Breadcrumbs paths={[{ pathName: "Reports" }]} />
      <H1 data-testid={`${Page.Desktop}-${Components.Reports}`}>Reports</H1>
      <Loader loading={!selectedFormType && allFormTypeStatsCall.isLoading}>
        <div className={s.formTypesContainer}>
          {formTypeData
            ?.sort((a, b) => b.totalCount - a.totalCount)
            .map((d) => (
              <FormTypeSummary
                key={d.formType.id}
                formType={d.formType}
                formStats={getStatsForFormType(d)}
                loadingStats={false}
                onGroupClick={(id: number) => {
                  setDocumentType(d.formType.id);
                  setClientGroupId(id);
                }}
                onAllGroupsClick={() => openGroupsDrawer(d.formType)}
                viewingAllGroups={false}
              />
            )) || <p>something went wrong</p>}
        </div>
      </Loader>
      <div>
        <ReportsFilters
          idPrefix="reportsFilters"
          setNewPageNum={setNewPageNum}
          clientGroupId={clientGroupId}
          setClientGroupId={setClientGroupId}
          groupFilterOptions={groupFilterOptions}
          documentType={documentType}
          setDocumentType={setDocumentType}
          formTypes={formTypeData?.map((d) => d.formType) || []}
          handleChangeDateRange={setDateRange}
          setQuery={setQuery}
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
        />
        <ReportsTable
          sortingType={sortingType}
          setSortingType={setSortingType}
          sortingDirection={sortingDirection}
          setSortingDirection={setSortingDirection}
          loadingStats={allFormStatsCall.isLoading}
          formStats={allFormStatsCall.data}
          setNewPageNum={setNewPageNum}
          setNewPageSize={setNewPageSize}
          queryString={queryString}
        />
      </div>
      {/* <AiChatButton handleClick={() => setIsChatDrawerOpen(true)} /> */}
    </ContentWrapper>
  );
};

export default Reports;
