import {
  API,
  CoreRedux,
  DeviceEnum,
  DocumentPhotoSaveVm,
  DocumentPhotoVm,
  DocumentStatus,
  DocumentSubmissionType,
  DocumentUpdateVm,
  DocumentVm,
  ParticipantUserVm,
  StoredObjectDTO,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ImageUploader } from "../../../helpers/attachment.helpers";
import {
  getFormItemByRootId,
  sortPhotos,
  sortPhotosByTimeAdded,
} from "../../../helpers/document.helpers";
import { selectIsPreview } from "../../../redux/document/documentSelectors";
import { useAppSelector } from "../../../redux/reduxHooks";

export interface UploadingPhoto {
  key: number;
  src: string;
  abort: () => void;
  documentId: number;
  loaded: number;
  total: number;
  file: File;
  error?: string;
}

export interface PhotosHandling {
  galleryPhotos: DocumentPhotoVm[];
  photos: DocumentPhotoVm[];
  photosUploading: UploadingPhoto[];
  photoQuestionTitle: string;
  handleUploadPhoto: (newFiles: File[]) => Promise<void>[];
  handleDeletePhoto: (photoId: number) => Promise<void>;
  handleRetryPhoto: (file: File) => void;
  handleUpdatePhoto: (newPhoto: DocumentPhotoVm) => void;
}

const getPreviewPhoto = (
  photo: UploadingPhoto,
  participantUser: ParticipantUserVm,
  questionRootId?: number,
  associatedId?: number | null
) => {
  const previewPhoto: DocumentPhotoVm = {
    associatedId,
    description: "",
    id: photo.key,
    imageUrl: URL.createObjectURL(photo.file),
    participant: {
      email: participantUser.email,
      firstName: participantUser.firstName,
      fullName: participantUser.fullName,
      lastName: participantUser.lastName,
      source: "USER_GENERATED",
      workLocation: {
        id: -1,
        locationId: "",
        name: "",
        softDeleted: false,
        source: "USER_GENERATED",
      },
    },
    questionRootId: questionRootId,
    timeAdded: new Date().toISOString(),
  };
  return previewPhoto;
};

export function usePhotos(
  questionRootId?: number,
  associatedId?: number | null,
  appPath?: string
): PhotosHandling {
  const { values, setFieldValue, setValues } = useFormikContext<DocumentVm>();
  const [photosUploading, setPhotosUploading] = useState<UploadingPhoto[]>([]);
  const isPreview = useAppSelector(selectIsPreview);
  const participantUser = useSelector(CoreRedux.selectUser)!;
  const item = questionRootId
    ? getFormItemByRootId(values, questionRootId)
    : null;
  const photoQuestionTitle =
    item?.type === "QUESTION" ? item.title : "Photo Gallery";

  function updatePhotoUploading(upload: UploadingPhoto) {
    setPhotosUploading((prev) =>
      prev.map((p) => (p.key === upload.key ? upload : p))
    );
  }

  const galleryPhotos = useMemo(
    () => sortPhotos(values.photos, values.form.sections),
    [values.photos]
  );

  const photos = useMemo(() => {
    if (!!associatedId)
      return values.photos
        .filter((p) => p.associatedId === associatedId)
        .sort(sortPhotosByTimeAdded);

    if (!!questionRootId) {
      return values.photos
        .filter((p) => p.questionRootId === questionRootId)
        .sort(sortPhotosByTimeAdded);
    }
    return galleryPhotos;
  }, [values.photos, galleryPhotos]);

  function handleUploadPhoto(newFiles: File[]) {
    let key = photosUploading.values.length;
    const uploads: UploadingPhoto[] = newFiles.map((file) => ({
      key: key++,
      file: file,
      src: URL.createObjectURL(file),
      abort: () => {},
      loaded: 0,
      total: file.size,
      documentId: values.id,
    }));

    setPhotosUploading((prev) => prev.concat(uploads));

    return uploads.map((upload) => processIndividualUpload(upload));
  }

  async function processIndividualUpload(upload: UploadingPhoto) {
    try {
      await addPhoto(upload);
    } catch (error) {
      throw new Error(`${upload.file.name} Photo Upload Failed`);
    }
  }

  async function addPhoto(photo: UploadingPhoto) {
    if (isPreview) {
      setPhotosUploading((prev) => prev.filter((p) => p.key !== photo.key));
      setValues(
        (values) => ({
          ...values,
          photos: [
            ...values.photos,
            getPreviewPhoto(
              photo,
              participantUser,
              questionRootId,
              associatedId
            ),
          ],
        }),
        false
      );
    } else {
      const imageUrl = await uploadPhotoToS3(photo, appPath);
      await addPhotoToApi(imageUrl);
    }
  }

  function uploadPhotoToS3(
    upload: UploadingPhoto,
    appPath?: string
  ): Promise<StoredObjectDTO> {
    return new Promise(async (resolve, reject) => {
      try {
        const uploader = new ImageUploader(
          upload.file,
          upload.documentId,
          (ev: ProgressEvent<EventTarget>) => {
            updatePhotoUploading({ ...upload, loaded: ev.loaded });
          },
          appPath
        );
        upload.abort = uploader.abort;
        updatePhotoUploading(upload);
        const value = await uploader.startUpload();
        setPhotosUploading((prev) => prev.filter((p) => p.key !== upload.key));
        resolve(value);
      } catch (reason) {
        if (typeof reason === "string") {
          updatePhotoUploading({ ...upload, error: reason });
        }
        reject(reason);
      }
    });
  }

  const updateDocumentStatus = async () => {
    // !!appPath logic is a quick fix for storm/EFAT that does not need submitted check
    // until then, refactor for any future clients - AE
    if (isPreview || !!appPath) {
      return values.photos;
    }

    // To update photos the document status must be in-progress.
    if (values.status === DocumentStatus.SUBMITTED) {
      const documentUpdateVm: DocumentUpdateVm = {
        documentDevice: DeviceEnum.WEB,
        formActionRequests:
          values.form.actions?.map((fa) => ({
            actionId: fa.id,
            requestBody: fa.actionType,
          })) || [],
        id: values.id,
        operationalExperiences: values.operationalExperiences,
        participants: values.participants,
        photos: values.photos,
        responses: values.responses,
        sectionComments: values.sectionComments,
        submissionDate: new Date().toISOString(),
        submissionType: DocumentSubmissionType.SAVE_DRAFT,
      };
      const updatedDocument = await API.updateDocument({
        document: documentUpdateVm,
      });
      setValues(updatedDocument, false);
      return updatedDocument.photos;
    }

    return values.photos;
  };

  async function addPhotoToApi(URLs: StoredObjectDTO) {
    await updateDocumentStatus();

    const photo: DocumentPhotoSaveVm = {
      imageUrl: URLs.readableUrl!,
      description: "",
      timeAdded: new Date().toISOString(),
      questionRootId: questionRootId,
      associatedId,
    };
    const newPhoto = await API.createOrUpdateDocumentPhoto({
      documentId: values.id,
      photo,
      appPath,
    });

    setValues(
      (values) => ({
        ...values,
        photos: [...values.photos, newPhoto],
      }),
      false
    );
  }

  const handleDeletePhoto = async (photoId: number) => {
    const updatedPhotos = await updateDocumentStatus();
    if (!isPreview) {
      await API.deleteDocumentPhotoById({
        documentId: values.id,
        photoId,
        appPath,
      });
    }
    setFieldValue(
      "photos",
      updatedPhotos.filter((p) => p.id !== photoId),
      false
    );
  };

  async function handleRetryPhoto(file: File) {
    const photo = photosUploading.find((p) => p.file === file);
    if (!photo) return handleUploadPhoto([file]);
    photo.error = undefined;
    updatePhotoUploading(photo);
    await addPhoto(photo);
  }

  async function handleUpdatePhoto(photo: DocumentPhotoVm) {
    const updatedPhotos = await updateDocumentStatus();
    const updatedPhoto = updatedPhotos.find(
      (p) => p.id === photo.id || p.timeAdded === photo.timeAdded
    );
    if (updatedPhoto) {
      photo.id = updatedPhoto.id;
    }
    if (!isPreview) {
      await API.createOrUpdateDocumentPhoto({
        documentId: values.id,
        photo,
        appPath,
      });
    }
    setFieldValue(
      "photos",
      updatedPhotos.filter((p) => p.id !== photo.id).concat(photo),
      false
    );
  }

  return {
    galleryPhotos,
    photos,
    photoQuestionTitle,
    photosUploading,
    handleUploadPhoto,
    handleDeletePhoto,
    handleRetryPhoto,
    handleUpdatePhoto,
  };
}
