import React from "react";
import { useField, useFormikContext } from "formik";

import { Select, SelectOption } from "../../../../../../Select/Select";
import { FBForm, FBItem, FBSection } from "../../../../../types";

import ps from "../../styles.module.scss";
import bs from "../../../../styles.module.scss";
import { joinClassNames } from "../../../../../../../helpers/theme.helpers";

interface ShowHideConditionProps {
  conditionIndex: number;
  responseOptions: SelectOption[];
  targetOptions: SelectOption<number>[];
  targetItems: (FBItem | FBSection)[];
  itemSubType: string;
}

const ShowHideCondition = ({
  conditionIndex,
  responseOptions,
  targetOptions,
  targetItems,
  itemSubType,
}: ShowHideConditionProps) => {
  const { setFieldValue } = useFormikContext<FBForm>();

  // formik values field names
  const conditionSource =
    itemSubType === "YES_OR_NO" ? "booleanCondition" : "sourceConditionRootId";
  const conditionSourceFieldName = `displayConditions[${conditionIndex}].${conditionSource}`;
  const actionFieldName = `displayConditions[${conditionIndex}].action`;
  const targetFieldName = `displayConditions[${conditionIndex}].targetRootId`;
  const targetTypeFieldName = `displayConditions[${conditionIndex}].targetType`;

  // formik field objects
  const [conditionSourceField] = useField(conditionSourceFieldName);
  const [actionField] = useField(actionFieldName);
  const [targetField] = useField(targetFieldName);

  return (
    <div className={ps.conditionRules}>
      <div className={joinClassNames(bs.row, ps.dcRow)}>
        <span className={ps.text}>If response is</span>
        <Select
          {...conditionSourceField}
          wrapperClassName={joinClassNames(ps.dcSelect, ps.showHideSelect)}
          options={responseOptions}
          placeholder="Please add options"
          onChange={(option: SelectOption | null) =>
            setFieldValue(conditionSourceFieldName, option?.value)
          }
        />
      </div>
      <div className={joinClassNames(bs.row, ps.dcRow)}>
        <span className={joinClassNames(ps.text, ps.dcRow)}>then</span>
        <Select
          {...actionField}
          wrapperClassName={joinClassNames(ps.dcSelect, ps.actionSelect)}
          options={[
            { label: "show", value: "SHOW" },
            { label: "hide", value: "HIDE" },
          ]}
          onChange={(option: SelectOption | null) =>
            setFieldValue(actionFieldName, option?.value)
          }
        />
        <Select
          {...targetField}
          wrapperClassName={joinClassNames(ps.dcSelect, ps.showHideSelect)}
          options={targetOptions}
          onChange={(option: SelectOption | null) => {
            const targetItem = targetItems.find(
              (tItem) => tItem.id === option?.value
            );
            setFieldValue(targetFieldName, targetItem?.rootId);
            setFieldValue(targetTypeFieldName, targetItem?.type);
          }}
        />
      </div>
    </div>
  );
};

export default ShowHideCondition;
