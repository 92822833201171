import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { LoginFailureResponse } from "@rtslabs/field1st-fe-common";
import { Components, ElementType } from "../../qa-slugs";
import styles from "./auth.module.scss";

const AuthErrorMessage: FC<LoginFailureResponse> = (props) => {
  const navigate = useNavigate();
  if (props.accountLocked) {
    return (
      <span className={styles.error}>
        Your account has been locked.&nbsp;
        <span
          className={styles.helpLink}
          onClick={() => navigate("/help")}
          data-testid={`${Components.Login}-${ElementType.Link}-accountLockedHelpLink`}
        >
          Reset your password.
        </span>
      </span>
    );
  }
  return (
    <span className={styles.error}>
      Wrong username or password.&nbsp;
      <span
        className={styles.helpLink}
        onClick={() => navigate("/help")}
        data-testid={`${Components.Login}-${ElementType.Link}-badPwHelpLink`}
      >
        Need help logging in?
      </span>
    </span>
  );
};

export default AuthErrorMessage;
