import {
  API,
  ApplicationConfigKey,
  ClientConfigProperties,
  CoreRedux,
} from "@rtslabs/field1st-fe-common";
import { FormikHelpers } from "formik";
import React, { FC, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { ErrorToast } from "../../../../../shared/src/components/Toast/ErrorToast";
import { SuccessToast } from "../../../../../shared/src/components/Toast/SuccessToast";
import { updateCompany } from "../../../api/admin/updateCompany";
import { settingsWriteRoles } from "../../../routes/constants/permissionSets";
import {
  Components,
  ElementType,
  Page,
} from "./../../../../../shared/src/qa-slugs";
import { CompanyForm, uploadImages } from "./companyInfoHelpers";
import { FormikCompanyInfo } from "./FormikCompanyInfo";
import s from "./styles.module.scss";

export const CompanyInfo: FC = () => {
  const primaryLogo = useSelector(CoreRedux.selectPrimaryLogoImageProps);
  const condensedLogo = useSelector(CoreRedux.selectCondensedLogoImageProps);
  const company = useSelector(CoreRedux.selectClientConfigProps);
  const isFormReadOnly = !API.Environment.hasRoleAccess(settingsWriteRoles);

  const initialValues = {
    primaryLogo,
    condensedLogo,
    name: company?.clientName || "",
    email: company?.email || "",
    address1: company?.address1 || "",
    address2: company?.address2 || "",
    city: company?.city || "",
    state: company?.state || null,
    zip: company?.zip || null,
  };

  const [primaryImageFile, setPrimaryImageFile] = useState<File | undefined>();
  const [condensedImageFile, setCondensedImageFile] = useState<
    File | undefined
  >();

  // form submission
  const [submitStatus, setSubmitStatus] = useState<"SUCCESS" | "FAIL" | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const topOfForm = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const handleUploadLogos = async () => {
    if (primaryImageFile || condensedImageFile) {
      const newLogoConfigs = await uploadImages({
        newFiles: {
          primary: primaryImageFile,
          condensed: condensedImageFile,
        },
        existingLogos: {
          primary: primaryLogo,
          condensed: condensedLogo,
        },
      });

      dispatch<CoreRedux.GetAppConfigAction>({
        response: newLogoConfigs,
        keyName: ApplicationConfigKey.logoConfigs,
        type: CoreRedux.GET_APPLICATION_CONFIGS.SUCCESS,
      });
    }
  };

  const handleSaveCompany = async (values: CompanyForm) => {
    const updatedCompanyProps: ClientConfigProperties = {
      adfsEnabled: false,
      clientName: values.name,
      email: values.email,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      zip: values.zip,
    };
    const newCompanyConfig = await updateCompany(updatedCompanyProps);

    dispatch<CoreRedux.GetAppConfigAction>({
      response: newCompanyConfig,
      keyName: ApplicationConfigKey.clientConfig,
      type: CoreRedux.GET_APPLICATION_CONFIGS.SUCCESS,
    });
  };

  const handleSubmitForm = async (
    values: CompanyForm,
    formikHelpers: FormikHelpers<CompanyForm>
  ) => {
    try {
      setSubmitStatus(null);
      setError(null);
      await handleUploadLogos();
      await handleSaveCompany(values);
      setSubmitStatus("SUCCESS");
    } catch (err) {
      setSubmitStatus("FAIL");
      setError(API.getErrorMessage(err));
    } finally {
      topOfForm.current?.scrollIntoView({ behavior: "smooth" });
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <>
      {/* Toasts */}
      <SuccessToast
        qa={`${Components.CompanyInfo}-${ElementType.Toast}-success`}
        visible={submitStatus === "SUCCESS"}
        onDelay={() => setSubmitStatus(null)}
        component={() => <>Saved Successfully!</>}
      />
      <ErrorToast
        qa={`${Components.CompanyInfo}-${ElementType.Toast}-failure`}
        visible={submitStatus === "FAIL"}
        onDelay={() => setSubmitStatus(null)}
        component={() => <>Error - {error || "Failed to Submit"}</>}
      />

      {/* Breadcrumb Nav */}
      <div id="mainContent" ref={topOfForm}>
        <Breadcrumbs
          paths={[
            {
              pathName: "Settings",
            },
            {
              pathName: "Company Info",
              href: "/settings/company-info",
            },
          ]}
        />
      </div>

      {/* Title */}
      <div className={s.titleWrapper}>
        <h1
          className={s.h1}
          data-testid={`${Page.Desktop}-${Components.CompanyInfo}`}
        >
          Company Info
        </h1>
      </div>

      {/* Form */}
      {company && (
        <FormikCompanyInfo
          handleSubmitForm={handleSubmitForm}
          companyForm={initialValues}
          isReadOnly={isFormReadOnly}
          setPrimaryImageFile={setPrimaryImageFile}
          setCondensedImageFile={setCondensedImageFile}
        />
      )}
    </>
  );
};
