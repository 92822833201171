import React from "react";
import withTheme from "@mui/styles/withTheme";

import * as S from "./styles";
import { Components, ElementType } from "shared/src/qa-slugs";
import Loader from "shared/src/components/Loader/Loader";

interface Props {
  handleFile: (file: File) => void;
  parentLoading?: boolean;
  successState: boolean;
}

/**
 * Drop zone for file uploads with optional traditional file input
 * @param handleFile    - callback for handling the dropped file
 * @param parentLoading - a Promise initiated by the parent has not resolved (such as a file upload)
 * @param successState  - render a success state if true
 */
function DragToUpload({ handleFile, parentLoading, successState }: Props) {
  let dropBoxRef: HTMLElement | null = null;
  const [dragging, setDragging] = React.useState<boolean>(false);

  function overrideDefaults(event: Event | React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
  }

  /** Prevent defaults and set dragging to true when cursor drags into element */
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    overrideDefaults(event);
    if (event.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  /** Prevent defaults and set dragging to false when cursor drags out of element */
  const handleDragLeave = (event) => {
    overrideDefaults(event);
    setDragging(false);
  };

  /** Handle file drop onto element */
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    overrideDefaults(event);
    setDragging(false);
    if (event.dataTransfer.files.length > 0) {
      handleFile(event.dataTransfer.files[0]);
    }
  };

  /** Update file in state when input file is changed */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      handleFile(event.target.files[0]);
    }
  };

  /** Open file selection window */
  const handleBrowse = () => dropBoxRef && dropBoxRef.click();

  /** Add listeners for drag events on mount and remove them on unmount */
  React.useEffect(() => {
    window.addEventListener("dragover", overrideDefaults);
    window.addEventListener("drop", overrideDefaults);
    return function removeListeners() {
      window.removeEventListener("dragover", overrideDefaults);
      window.removeEventListener("drop", overrideDefaults);
    };
  }, []);

  return (
    <S.DragToDiv
      dragging={dragging}
      success={successState}
      onDrag={overrideDefaults}
      onDragStart={overrideDefaults}
      onDragEnd={overrideDefaults}
      onDragOver={overrideDefaults}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Loader loading={parentLoading}>
        <S.DragToDivContent>
          <S.SuccessIcon successState={successState} />
          <S.InstructionalText>
            {successState ? "Upload Successful!" : "Drag & drop CSV here"}
          </S.InstructionalText>
          {!successState && (
            <S.LinkText
              data-testid={`${Components.DragToUpload}-${ElementType.Link}-clickToBrowseFiles`}
              onClick={handleBrowse}
            >
              or click to browse files
            </S.LinkText>
          )}
        </S.DragToDivContent>
        <S.HiddenInput
          ref={(el) => (dropBoxRef = el)}
          type="file"
          onChange={handleFileChange}
        />
      </Loader>
    </S.DragToDiv>
  );
}

export default withTheme(DragToUpload);
