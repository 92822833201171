import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { Text, TextProps } from "shared/src/components/clientAdmin/styles";

// Wraps column headers
export const TermColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

// Wraps each column header
export const TermColumnHeader = styled.div<{
  width?: string;
}>`
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  width: ${({ width }) => width};
`;

export const TermColumnHeaderContent: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.75rem"
      fontWeight={600}
      letterSpacing="1.88px"
      textTransform="uppercase"
      {...props}
    />
  );
};

export const Label: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.colors.black}
      fontSize="1rem"
      fontWeight={500}
      letterSpacing="0.15px"
      {...props}
    />
  );
};

// Wraps each term label
export const LabelWrapper = styled.div<{
  width?: string;
}>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 25px;
  width: ${({ width }) => width};
`;

// Wraps each term input field
export const FieldWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
  max-width: 398px;
  padding-left: 0;
  width: 100%;
`;

export const TabContent = styled.div`
  width: 100%;
`;
