import React from "react";
import { orderBy, camelCase } from "lodash";

import { StatsCard } from "../../../common/StatsCard";
import Loader from "shared/src/components/Loader/Loader";

import PercentLineGraph from "./PercentLineGraph";
import { FormTypeStats } from "shared/src/components/clientAdmin/helpers";

import { Dot, GroupWrapper, Statistic, LinkButton } from "./styles";
import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import { Wrapper as StatsCardWrapper } from "../../../common/StatsCard/styles";
import { Components, ElementType } from "shared/src/qa-slugs";

type Props = {
  formStats?: FormTypeStats;
  formType: FormTypeDTO;
  loadingStats?: boolean;
  onGroupClick: (g: number) => void;
  onAllGroupsClick: () => void;
  viewingAllGroups: boolean;
};

function FormTypeSummary({
  formStats,
  formType,
  loadingStats,
  onGroupClick,
  onAllGroupsClick,
  viewingAllGroups,
}: Props) {
  // TODO refactor - could be more sensibly placed (such as within StatsCard) -JA
  if (loadingStats || !formStats) {
    return (
      <StatsCardWrapper>
        <Loader loading />
      </StatsCardWrapper>
    );
  }
  if (formStats) {
    const groupsSorted = orderBy(
      formStats.groupCounts,
      ["percent"],
      ["desc"]
    ).filter((g) => g.total !== 0);

    return (
      <StatsCard
        header={formType.name}
        value={formStats.totalCount}
        iconName={formType.iconName}
        iconColor={formType.iconColor}
      >
        <>
          <PercentLineGraph
            data={formStats.groupCounts}
            total={formStats.totalCount}
          />

          {groupsSorted.slice(0, 4).map(
            (
              group // only display top four groups on an individual card
            ) => (
              <GroupWrapper
                key={group.name}
                className="d-flex w-100 justify-content-between my-2"
              >
                <div className="d-flex align-items-center">
                  <Dot color={group.color} className="mr-2" />
                  <LinkButton
                    truncate
                    onClick={() => onGroupClick(group.id)}
                    data-testid={`${Components.Reports}-${
                      ElementType.Button
                    }-group-${camelCase(formType.name)}`}
                  >
                    {group.name}
                  </LinkButton>
                </div>
                <div className="d-flex align-items-center">
                  <Statistic className="mr-3">{group.total}</Statistic>
                  <Statistic>{group.percent}%</Statistic>
                </div>
              </GroupWrapper>
            )
          )}

          {!viewingAllGroups && (
            <LinkButton
              bold
              onClick={onAllGroupsClick}
              data-testid={`${Components.Reports}-${
                ElementType.Button
              }-allGroups-${camelCase(formType.name)}`}
            >
              View All Groups
            </LinkButton>
          )}
        </>
      </StatsCard>
    );
  }
  // formStats is falsy
  return null;
}

export default FormTypeSummary;
