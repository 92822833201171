import React, { useEffect, useMemo, useState } from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext } from "formik";
import { CONTENT_COPY } from "shared/src/components/clientAdmin/formBuilder/language";
import { FBForm, FBOEWidget } from "shared/src/components/clientAdmin/types";
import { getQuestionsWithOeTags } from "shared/src/components/clientAdmin/formBuilder/create/properties/customFields/Tags/helpers";
import OECard from "../../../../../../Document/OperationalExperiences/components/OECard";
import usePrevious from "shared/src/util/hooks/usePrevious";

import styles from "./styles";
import { API } from "@rtslabs/field1st-fe-common";
import { serializeError } from "serialize-error";
import { QAProps } from "shared/src/qa-slugs";

interface Props extends QAProps {
  item: FBOEWidget;
  selected?: boolean;
}

const OEWidget = ({ qa, selected }: Props) => {
  const [included, setIncluded] = useState<boolean>(false);
  const [possibleOes, setPossibleOes] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const { values } = useFormikContext<FBForm>();
  const s = styles(selected);

  // track the question selections with tags added so we can update the possible OEs count
  const questionsWithTags = useMemo(
    () => getQuestionsWithOeTags(values),
    [values]
  );
  const tagsCount = useMemo(
    () =>
      questionsWithTags
        .flatMap((question) => question.selections)
        .reduce((count, selection) => {
          return count + (selection?.tags?.length || 0);
        }, 0),
    [questionsWithTags]
  );
  const previousTagsCount = usePrevious(tagsCount);

  useEffect(() => {
    // if any selections with tags have been added/removed, refetch the possible OEs count
    if (previousTagsCount !== tagsCount) {
      (async () => {
        try {
          const res = await API.getPossibleFormOesCount({ formId: values.id });
          setPossibleOes(res);
        } catch (e) {
          const errorString = JSON.stringify(serializeError(e));
          setError(errorString);
        }
      })();
    }
  }, [tagsCount]);

  function toggleIncluded() {
    setIncluded((prevIncluded) => !prevIncluded);
  }

  return (
    <div data-testid={qa}>
      <div className={css(s.possibleOesLabel)}>
        {!error && (
          <>
            <span className={css(s.possibleOesNumber)}>{possibleOes}</span>
            <span> possible operational experiences</span>
          </>
        )}
      </div>
      <OECard
        className={css(s.oeCard)}
        handleIncludeExcludeOE={toggleIncluded}
        included={included}
        loading={false}
        oe={{
          id: 10000,
          incident: "Operational Experience",
          description: CONTENT_COPY.oeWidgetDescription,
          favoriteCount: 0,
          importance: "",
          incidentDate: null,
          includedCount: 0,
          score: 0,
        }}
      />
    </div>
  );
};

export default OEWidget;
