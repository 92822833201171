import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TabContent,
  TabGroup,
} from "./../../../../../shared/src/components/Tabs/TabGroup/TabGroup";
import { CompanyInfo } from "./CompanyInfo";
import { Permissions } from "./Permissions";

const paths = {
  companyInfo: "/settings/company-info",
  permissions: "/settings/permissions",
};

const settingsTabs = [
  {
    label: "Company Info",
    tabId: "companyInfo",
    tabPanelId: "companyInfo",
    tabPanelContent: <CompanyInfo />,
  },
  {
    label: "Permissions",
    tabId: "permissions",
    tabPanelId: "permissions",
    tabPanelContent: <Permissions />,
  },
];

const SettingsTabs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(-1);

  useEffect(() => {
    if (pathname === paths.companyInfo) {
      setSelectedTabIndex(0);
    } else if (pathname === paths.permissions) {
      setSelectedTabIndex(1);
    } else {
      setSelectedTabIndex(-1);
    }
  }, [pathname]);

  /**
   * ensures that side and top tab navigation match
   */
  const tabsMatch =
    (selectedTabIndex === 0 && pathname === paths.companyInfo) ||
    (selectedTabIndex === 1 && pathname === paths.permissions);

  const onTabChange = (e: TabContent) => {
    switch (e.tabId) {
      case "permissions":
        navigate(paths.permissions);
        break;
      case "companyInfo":
      default:
        navigate(paths.companyInfo);
        break;
    }
  };

  return (
    <>
      {tabsMatch && (
        <TabGroup
          variant="white"
          initiallySelectedTabIndex={selectedTabIndex}
          onTabChange={(e) => onTabChange(e)}
          tabs={settingsTabs}
        />
      )}
    </>
  );
};

export default SettingsTabs;
