import React, { FC } from "react";

interface ClassName {
  className?: string;
}
export const BackArrow: FC<ClassName> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
    >
      <path
        d="M6.89789 11.1962L6.30331 11.8063C6.05156 12.0646 5.64446 12.0646 5.39538 11.8063L0.188818 6.46714C-0.0629399 6.20884 -0.0629399 5.79116 0.188818 5.53561L5.39538 0.193725C5.64714 -0.0645755 6.05423 -0.0645755 6.30331 0.193725L6.89789 0.803755C7.15233 1.0648 7.14697 1.49073 6.88718 1.74628L3.65986 4.90085L11.3572 4.90085C11.7134 4.90085 12 5.19487 12 5.56034L12 6.43966C12 6.80513 11.7134 7.09915 11.3572 7.09915L3.65986 7.09915L6.88718 10.2537C7.14965 10.5093 7.155 10.9352 6.89789 11.1962Z"
        fill="white"
      />
    </svg>
  );
};
