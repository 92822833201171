import {
  API,
  PageableRequest,
  Pagination as PaginationType,
  PaginationParams,
  Person,
  ResourceDTO,
  ResourceStatus,
} from "@rtslabs/field1st-fe-common";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { Button } from "../../Button/Button";
import { ContentWrapper } from "../../Wrappers/Wrappers";
import { Components, ElementType, Page } from "../../../qa-slugs";
import usePrevious from "../../../util/hooks/usePrevious";
import Loader from "../../Loader/Loader";
import { getMinMaxSubmissionDatesFromTimeFilter } from "../../../data/timeFilters";
import { resourceWriteRoles } from "../../routes/constants/permissionSets";
import WriteContent from "../../common/permissions/WriteContent";
import { PageHeader, PageTitle } from "../styles";
import ResourcesFilters, { ResourceFilters } from "./ResourcesFilters";
import ResourcesTable from "./ResourcesTable";
import ResourceTablePagination from "./ResourceTablePagination";

const initialPaginationParams = {
  page: 0,
  size: 10,
  sort: "lastModifiedDate,desc",
};

const MaintainResources: React.FC = () => {
  const [authors, setAuthors] = useState<Person[]>([]);
  const [filters, setFilters] = useState<API.GetResourcesArgs>({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationValues, setPaginationValues] = useState<
    Omit<PaginationType, "content"> | undefined
  >(undefined);
  const [params, setParams] = useState<PageableRequest>(
    initialPaginationParams
  );
  const [resources, setResources] = useState<ResourceDTO[]>([]);

  const fetchResources = async () => {
    setIsLoading(true);
    const res = await API.getResources({ ...params, ...filters });
    setResources(res.content);
    setPaginationValues(omit(res, ["content"]));
    setIsLoading(false);
  };

  const fetchAuthors = async (query: string) => {
    const authorsRes = await API.getResourceAuthors({ query });
    setAuthors(authorsRes.content);
  };

  const handleSelectAuthor = () => {
    setAuthors([]);
  };

  const onToggleResourceStatus = (
    resourceId: number,
    status: ResourceStatus
  ) => {
    const updatedResources = resources.map((resource) => {
      if (resource.id === resourceId) {
        return { ...resource, status };
      }
      return resource;
    });
    setResources(updatedResources);
  };

  const handleUpdateFilters = (filters: ResourceFilters) => {
    // renamed filters will be removed from the filters spread
    const renamedFilters = ["timeFilter", "dateRange"];
    const passableFilters = omit(filters, renamedFilters);

    const { minSubmissionDate, maxSubmissionDate } =
      getMinMaxSubmissionDatesFromTimeFilter(
        filters.timeFilter,
        filters.dateRange
      );

    const mappedFilters = {
      ...passableFilters,
      maxLastUpdatedDate: maxSubmissionDate,
      minLastUpdatedDate: minSubmissionDate,
    };
    setFilters(mappedFilters);
    // return to page 1 when we change the filters
    setParams({ ...params, page: 0 });
  };

  const handleSort = (sort: string) => setParams({ ...params, sort });

  const handleUpdatePaginationParams = (param: PaginationParams) =>
    setParams({ ...params, ...param });

  const handleAddResource = () => navigate("/content/resources/new");

  const prevParamsSize = usePrevious(params.size);

  // get the resources on mount and when search/pagination params change
  useEffect(() => {
    // When we change the page size, reset page to 0
    if (prevParamsSize !== params.size) {
      setParams({ ...params, page: 0 });
    } else {
      fetchResources();
    }
  }, [params, filters]);

  // @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
  // @TODO: Update with a better solution - Trevor
  return (
    <ContentWrapper id="mainContent">
      <Breadcrumbs
        paths={[
          {
            pathName: "Content",
          },
          {
            pathName: "Resources",
            href: "/content/resources",
          },
        ]}
      />
      <PageHeader>
        <PageTitle
          data-testid={`${Page.Desktop}-${Components.MaintainResources}`}
        >
          Resources
        </PageTitle>
        <WriteContent roles={resourceWriteRoles}>
          <Button
            onClick={handleAddResource}
            qa={`${Components.MaintainResources}-${ElementType.Button}-addResource`}
          >
            Add Resource
          </Button>
        </WriteContent>
      </PageHeader>
      <ResourcesFilters
        authors={authors}
        onSearchAuthors={fetchAuthors}
        onSelectAuthor={handleSelectAuthor}
        onUpdateFilters={handleUpdateFilters}
      />
      <Loader loading={isLoading}>
        <ResourcesTable
          onSort={handleSort}
          params={params}
          totalElements={paginationValues?.totalElements || 0}
          resources={resources}
          onToggleResourceStatus={onToggleResourceStatus}
        />
      </Loader>
      <ResourceTablePagination
        params={params}
        paginationValues={paginationValues}
        onUpdateParams={handleUpdatePaginationParams}
      />
    </ContentWrapper>
  );
};

export default MaintainResources;
