import { API, FormTypeDTO, useAPI } from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "shared/src/components/Button/Button";
import { Icon } from "shared/src/components/Icon/Icon";
import Loader from "shared/src/components/Loader/Loader";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { Table } from "shared/src/components/TableUI/Table/Table";
import { TableHead } from "shared/src/components/TableUI/TableHead/TableHead";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { SuccessToast } from "shared/src/components/Toast/SuccessToast";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Components, ElementType } from "shared/src/qa-slugs";
import { formTypeWriteRoles } from "../../../routes/constants/permissionSets";
import { DeleteFormTypeModal } from "./DeleteFormTypeModal";
import { FormTypeRow } from "./FormTypeRow";
import styles from "./FormTypes.module.scss";
import { AddFormType } from "./modals/AddFormType";

const FormTypes: FC = () => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [addFormTypeIsVisible, setAddFormTypeIsVisible] = useState(false);
  const [selectedFormType, setSelectedFormType] = useState<
    FormTypeDTO | undefined
  >();
  const [deleteFormTypeIsVisible, setDeleteFormTypeIsVisible] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const hasFormsRole = API.Environment.hasRoleAccess(formTypeWriteRoles);
  const success = searchParams.get("success");
  const verb = success === "edit" ? "Updated" : "Processed";

  const formTypesCall = useAPI(API.getFormTypes, {
    page,
    size: pageSize,
    sort: ["name", "asc"],
  });

  const handleClickAdd = () => {
    setAddFormTypeIsVisible(true);
  };

  const handleDelete = (formType: FormTypeDTO) => {
    setSelectedFormType(formType);
    setDeleteFormTypeIsVisible(true);
  };

  const navigate = useNavigate();

  const handleEdit = (formType: FormTypeDTO) => {
    navigate(`/forms/form-types/${formType.id}/edit`);
  };

  const handleView = (formType: FormTypeDTO) => {
    navigate(`/forms/form-types/${formType.id}/view`);
  };

  function closeSuccessToast() {
    setSearchParams({});
  }

  return (
    <>
      <SuccessToast
        visible={!!success}
        onDelay={closeSuccessToast}
        onDelayTime={5000}
        onClick={closeSuccessToast}
      >
        Form Type {verb} Successfully.
      </SuccessToast>
      <AddFormType
        visible={addFormTypeIsVisible}
        onClose={() => {
          setAddFormTypeIsVisible(false);
          formTypesCall.refresh();
        }}
      />
      {selectedFormType && (
        <DeleteFormTypeModal
          formType={selectedFormType}
          deleteFormTypeIsVisible={deleteFormTypeIsVisible}
          setDeleteFormTypeIsVisible={setDeleteFormTypeIsVisible}
          refreshFormTypes={formTypesCall.refresh}
        />
      )}

      <div className={joinClassNames(styles.centerRow, styles.header)}>
        <h1 className={styles.pageTitle}>Form Types</h1>
        {hasFormsRole && (
          <Button
            className={styles.addButton}
            onClick={handleClickAdd}
            qa={`${Components.FormTypes}-${ElementType.Button}-create`}
            startContent={
              <Icon
                className={styles.addButtonIcon}
                size={20}
                type="add_file"
              />
            }
          >
            Create Form Type
          </Button>
        )}
      </div>
      <Loader loading={formTypesCall.isLoading}>
        <TableSummary
          currentPage={page}
          ofWhat="form types"
          pageSize={pageSize}
          totalElements={formTypesCall.data?.totalElements}
        />
        <Table className={styles.table}>
          <TableHead
            className="sr-only"
            columns={[
              { id: "name", label: "Name" },
              { id: "actions", label: "Actions" },
            ]}
          />
          <tbody>
            {formTypesCall.data?.content.map((formType) => (
              <FormTypeRow
                formType={formType}
                key={formType.id}
                onDelete={handleDelete}
                onEdit={handleEdit}
                onView={handleView}
                hasFormsRole={hasFormsRole}
              />
            ))}
          </tbody>
        </Table>
        {formTypesCall.data && (
          <PaginationWrapper>
            <RowsPerPage
              pageSize={pageSize}
              onClick={(size) => {
                setPageSize(size);
              }}
            />
            <Pagination
              currentPage={page}
              onClick={(page) => setPage(page)}
              totalPages={formTypesCall.data.totalPages}
            />
            <BackToTop />
          </PaginationWrapper>
        )}
      </Loader>
    </>
  );
};

export default FormTypes;
