import React from "react";

import s from "./styles.module.scss";
import { joinClassNames } from "../../../../../helpers/theme.helpers";

interface Props {
  active?: boolean;
  direction: "left" | "right";
  children?: React.ReactNode;
  label?: string;
  detail?: string;
}

const SidebarIntro = ({ active, direction, label, detail }: Props) => {
  return (
    <div
      className={joinClassNames(
        s.sidebarIntro,
        active && s.introActive,
        direction === "left" ? s.introLeft : s.introRight
      )}
    >
      <h5 className={s.sidebarIntroLabel}>{label}</h5>
      <span>{detail}</span>
    </div>
  );
};

export default SidebarIntro;
