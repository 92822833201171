import React, { FC } from "react";
import styles from "./ListItemContent.module.scss";

export interface ListItemContentProps {
  aside?: string;
  subtitle?: string;
  title: string;
}

export const ListItemContent: FC<ListItemContentProps> = ({
  title,
  subtitle,
  aside,
}) => {
  return (
    <>
      <div className={styles.details}>
        <div className={styles.title}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
      <div className={styles.aside}>{aside}</div>
    </>
  );
};
