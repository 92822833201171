import React, { useState } from "react";

import {
  DataSource,
  QuestionAnswerSourceDTO,
} from "@rtslabs/field1st-fe-common";
import Button from "../../../../../common/Button";
import DrawerHeader from "../../../../../common/Drawer/components/Header";
import Label from "../../../../../Label/Label";
import { Link } from "../../../../../Link/Link";

import ps from "../styles.module.scss";
import DataSetFields from "../customFields/DataSetFields";
import Drawer from "./Drawer";
import { FBItem, Property } from "../../../../types";
import { ItemParams } from "../../Create";

interface Props {
  initialValues?: QuestionAnswerSourceDTO | null;
  onAddDataSet: (
    dataSet: DataSource,
    sourceColumn: string,
    sortBy: string
  ) => void;
  closeDrawer: () => void;
  show: boolean;
  detailedSearchProps?: Property[];
  itemPath?: string;
  item: FBItem;
  appWidgetsList: ItemParams[];
}

const DataSetDrawer = ({
  initialValues,
  onAddDataSet,
  closeDrawer,
  show,
  detailedSearchProps,
  itemPath,
  item,
  appWidgetsList,
}: Props) => {
  const [dataSet, setDataSet] = useState<DataSource | null>(null);
  const [sourceColumn, setSourceColumn] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<string | null>(null);

  const handleAddDataSet = async () => {
    if (dataSet && sourceColumn && sortBy) {
      onAddDataSet(dataSet, sourceColumn, sortBy);
      closeDrawer();
    }
  };

  const setDataSetValues = (
    dataSet?: DataSource,
    sourceColumn?: string,
    sortBy?: string
  ) => {
    if (dataSet) setDataSet(dataSet);
    if (sourceColumn) setSourceColumn(sourceColumn);
    if (sortBy) setSortBy(sortBy);
  };

  return (
    <Drawer
      anchor="right"
      content={
        <Drawer.Content>
          <DrawerHeader closeDrawer={closeDrawer} text="Add Data Sets" />
          <Drawer.Section>
            <Label className={ps.label} htmlFor="">
              Add a Data Set as an Answer Source
            </Label>
            <Drawer.Description>
              Custom dropdown questions can utilize pre-built data sets for
              their answer options. Each record in the selected data set will
              display as a selectable answer option in a dropdown menu. In order
              to include a specific data set,{" "}
              <Link to="/forms/data-sets">build it here</Link>.
            </Drawer.Description>
            <DataSetFields
              initialValues={initialValues}
              onUpdate={setDataSetValues}
              detailedSearchProps={detailedSearchProps}
              itemPath={itemPath}
              item={item}
              appWidgetsList={appWidgetsList}
            />
          </Drawer.Section>
          <Drawer.ButtonContainer>
            <Button
              type="submit"
              onClick={handleAddDataSet}
              disabled={!dataSet || !sourceColumn || !sortBy}
            >
              {initialValues ? "Edit" : "Add"} Data Set
            </Button>
          </Drawer.ButtonContainer>
        </Drawer.Content>
      }
      id="dataSetDrawer"
      onClose={closeDrawer}
      onOpen={() => undefined}
      open={show}
      showCloseIcon={false}
      variant="persistent"
    />
  );
};

export default DataSetDrawer;
