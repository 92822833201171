import {
  API,
  DocumentQuestionResponseVm,
  DocumentSubmissionType,
  DocumentVM,
  PageOfDataSourceValueDTO,
  QuestionDTO,
  ResponseContent,
  SubmissionType,
} from "@rtslabs/field1st-fe-common";
import { Form, FormikProps } from "formik";
import React, {
  FC,
  JSXElementConstructor,
  ReactNode,
  RefObject,
  useMemo,
} from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { Components, ElementType } from "../../../qa-slugs";
import { Button, SecondaryButton } from "../../Button/Button";
import styles from "./documentForm.module.scss";
import Section from "./Section";
import { FormWidgetProps, QuestionsToDisableProps } from "./types";
import useErrorScroll from "./useErrorScroll";

interface DocumentFormProps {
  documentTerm: string;
  flattenedQuestions: QuestionDTO[];
  formikProps: FormikProps<DocumentVM>;
  formWidget: JSXElementConstructor<FormWidgetProps>;
  getDataSourceValues: <T>(
    params: API.GetDataSourceValuesArgs
  ) => Promise<PageOfDataSourceValueDTO<T>>;
  itemRefs: Map<number, RefObject<HTMLDivElement>>;
  setDocumentResponses: (
    question: QuestionDTO,
    questionResponses: DocumentQuestionResponseVm[],
    content?: ResponseContent | null
  ) => Promise<void>;
  customFooter?: ReactNode;
  appPath?: string;
  questionsToDisable?: QuestionsToDisableProps[];
  disableSubmit?: boolean;
  disableSave?: boolean;
}

export const DocumentForm: FC<DocumentFormProps> = ({
  documentTerm,
  flattenedQuestions,
  formikProps,
  formWidget,
  getDataSourceValues,
  itemRefs,
  setDocumentResponses,
  customFooter,
  appPath,
  questionsToDisable,
  disableSubmit,
  disableSave,
}) => {
  const { errors, handleSubmit, isSubmitting, setFieldValue, values } =
    formikProps;
  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);
  useErrorScroll({ itemRefs });

  function onSubmit(submissionType: SubmissionType): void {
    setFieldValue("submissionType", submissionType);
    setTimeout(handleSubmit, 0);
  }

  // ensures that Photo Gallery Widget displays at end of document
  const photoGallerySection = values.form.sections.find((s) =>
    s.items.find((i) => i.subType === "PHOTO_GALLERY")
  );
  const sortedSections = photoGallerySection
    ? [
        ...values.form.sections.filter((s) => s.id !== photoGallerySection?.id),
        photoGallerySection,
      ]
    : values.form.sections;

  return (
    <Form>
      {/* Sections and child items */}
      {sortedSections?.map((section) => (
        <Section
          key={section.id}
          formikProps={formikProps}
          responses={values.responses || []}
          flattenedQuestions={flattenedQuestions}
          formWidget={formWidget}
          getDataSourceValues={getDataSourceValues}
          itemRefs={itemRefs}
          section={section}
          setDocumentResponses={setDocumentResponses}
          defenses={values.form.defenses || []}
          displayConditions={values.form.displayConditions}
          errors={errors}
          appPath={appPath}
          questionsToDisable={questionsToDisable}
        />
      ))}

      {/* Submit and save buttons */}
      {!customFooter && (
        <div
          className={joinClassNames(
            styles.formActions,
            hasErrors && styles.error
          )}
        >
          <Button
            loading={isSubmitting}
            onClick={() => onSubmit(DocumentSubmissionType.SUBMIT)}
            qa={`${Components.Document}-${ElementType.Button}-submit`}
            disabled={isSubmitting || disableSubmit}
          >
            Submit {documentTerm}
          </Button>
          <SecondaryButton
            loading={isSubmitting}
            onClick={() => onSubmit(DocumentSubmissionType.SAVE_DRAFT)}
            qa={`${Components.Document}-${ElementType.Button}-save`}
            disabled={isSubmitting || disableSave}
          >
            Save {documentTerm}
          </SecondaryButton>
        </div>
      )}

      {!!customFooter && customFooter}
    </Form>
  );
};
