import { FormSectionVm, SectionItem } from "@rtslabs/field1st-fe-common";

export enum DocSecActionTypes {
  SET_CURRENT_SECTION = "SET_CURRENT_SECTION",
  SET_DOCUMENT_TITLE = "SET_DOCUMENT_TITLE",
  //   SET_IS_SECTION_VALIDATED = "SET_IS_SECTION_VALIDATED",
  SET_VISIBLE_SECTIONS = "SET_VISIBLE_SECTIONS",
}

export interface DocSecCurrentSectionAction {
  value: FormSectionVm<SectionItem>;
  type: typeof DocSecActionTypes.SET_CURRENT_SECTION;
}

export interface DocSecDocumentTitleAction {
  value: string;
  type: typeof DocSecActionTypes.SET_DOCUMENT_TITLE;
}

// export interface DocSecIsSectionValidatedAction {
//   value: boolean;
//   type: typeof DocSecActionTypes.SET_IS_SECTION_VALIDATED;
// }

export interface DocSecVisibleSectionsAction {
  value: FormSectionVm<SectionItem>[];
  type: typeof DocSecActionTypes.SET_VISIBLE_SECTIONS;
}

export type DocSecActions =
  | DocSecCurrentSectionAction
  | DocSecDocumentTitleAction
  //   | DocSecIsSectionValidatedAction
  | DocSecVisibleSectionsAction;
