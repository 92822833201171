import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { hotjar } from "react-hotjar";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";

import * as FullStory from "@fullstory/browser";
import { API } from "@rtslabs/field1st-fe-common";
import { getApiHost } from "shared/src/api/network";
import Loader from "shared/src/components/Loader/Loader";
import { getBuildEnvironment } from "shared/src/helpers/version";
import GlobalStyle from "shared/src/themes/GlobalStyle";
import ThemeContext, {
  ThemeContextProvider,
} from "shared/src/themes/ThemeContext";
import App from "./App";
import "./assets/icomoon/style.css";
import ErrorBoundary from "./components/common/ErrorBoundary";
import "./sass/main.scss";
import { persistor, store } from "./store";

import "shared/src/i18n";
import { geocode, reverseGeocode } from "shared/src/api/esri/geocode";
import { setGeocoder, setReverseGeocoder } from "shared/src/api/geolocationAPI";

const host = getApiHost();
API.Environment.apiRoot = host + "/api";

setGeocoder(geocode);
setReverseGeocoder(reverseGeocode);

// Analytics
const fsOrgId = "V7CZE";
FullStory.init({ orgId: fsOrgId });
if (process.env.NODE_ENV !== "development") {
  let sentryUrl: string | undefined;
  let hjid: number | undefined;
  const fsOrgId = "V7CZE";

  switch (getBuildEnvironment()) {
    case "DEV":
      console.log("Not tracking this ENV");
      break;
    case "QA":
      hjid = 1494582;
      sentryUrl = "https://93a2b5d9b38948819f7aaf8fa8f9f0a9@sentry.io/1762785";
      // fsOrgId = "V7CZE";
      break;
    case "UAT":
      hjid = 1494582;
      sentryUrl = "https://d475831ff9424490bf0c31815c60c0d7@sentry.io/1758454";
      // fsOrgId = "V7CZE";
      break;
    case "MASTER":
      hotjar.initialize(1494582, 6);
      Sentry.init({
        dsn: "https://28e0f6473e4b4239a2f0d499334c2ff5@o304379.ingest.sentry.io/5303874",
      });
      break;
    default:
      console.log("Not tracking this ENV");
  }

  if (hjid) hotjar.initialize(1494582, 6);
  if (sentryUrl) Sentry.init({ dsn: sentryUrl });
  // if (fsOrgId) FullStory.init({ orgId: fsOrgId });
}

const MUItheme = createTheme();

const Root = () => {
  const { darkMode } = React.useContext(ThemeContext);

  // New app version available
  const [needUpdate, setNeedUpdate] = React.useState<boolean | undefined>();

  // Check for new version
  // Check if app needs an update
  // const checkAsset = React.useCallback(() => {
  //   if (process.env.NODE_ENV === "production") {
  //     const currentBuildHash = localStorage.getItem("buildHash");
  //     fetch("/asset-manifest.json")
  //       .then((response) => response.json())
  //       .then((json) => {
  //         if (needUpdate === undefined && json["main.js"] !== currentBuildHash) {
  //           setNeedUpdate(true);
  //         }
  //       });
  //   }
  // }, []);

  // On mount
  // React.useEffect(() => {
  //   // Fetch hash for JS asset and set timer to check for changes
  //   if (process.env.NODE_ENV === "production") {
  //     fetch("/asset-manifest.json")
  //       .then((response) => response.json())
  //       .then((json) => {
  //         const assets: {
  //           "main.js": string;
  //         } = json;
  //         // If current build hash isn't stored, set it
  //         if (!localStorage?.buildHash) {
  //           localStorage.setItem("buildHash", assets["main.js"]);
  //         }
  //         // If current build is stored, but is different than server's
  //         else if (localStorage.buildHash !== assets["main.js"]) {
  //           setNeedUpdate(true);
  //         }
  //       });
  //     setInterval(() => checkAsset(), 180000);
  //   }
  // }, []);
  return (
    <MuiThemeProvider theme={MUItheme}>
      <Provider store={store}>
        <ErrorBoundary>
          <PersistGate
            loading={<Loader loading={true} />}
            persistor={persistor}
          >
            <React.Suspense fallback={<Loader loading={true} />}>
              {/* <ApplicationUpdateModal isOpen={needUpdate} onClose={() => setNeedUpdate(false)}/> */}
              <GlobalStyle />
              <App />
            </React.Suspense>
          </PersistGate>
        </ErrorBoundary>
      </Provider>
    </MuiThemeProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ThemeContextProvider>
    <Root />
  </ThemeContextProvider>
);
