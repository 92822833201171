import React, { FC, useId } from "react";
import { Button } from "shared/src/components/Button/Button";
import { Icon } from "shared/src/components/Icon/Icon";
import styles from "./NewDocumentCard.module.scss";
import { Components } from "shared/src/qa-slugs";

interface Props {
  handleClick: () => void;
}

export const NewDocumentCard: FC<Props> = ({ handleClick }) => {
  const labelId = useId();

  return (
    <div className={styles.container}>
      <div className={styles.createText} id={labelId}>
        New document
      </div>
      <Button
        ariaLabelledBy={labelId}
        className={styles.startButton}
        onClick={handleClick}
        qa={`${Components.Home}-${Components.Button}-new-document`}
      >
        <Icon type="add_file" size={20} />
      </Button>
    </div>
  );
};
