import React, { FC } from "react";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import Toast, { ToastProps } from "./Toast";

type SuccessToastProps = Omit<ToastProps, "backgroundColor" | "color"> & {
  children?: React.ReactNode;
};

/**
 * @deprecated Use react-toastify and Toastify.tsx instead.
 * @see https://fkhadra.github.io/react-toastify/introduction
 */
export const SuccessToast: FC<SuccessToastProps> = (toastProps) => {
  return (
    <Toast
      backgroundColor={scssVariables.successFill}
      color={scssVariables.success}
      {...toastProps}
    />
  );
};
