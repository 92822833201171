import {
  API,
  APIState,
  QuestionDTO,
  QuestionType,
  ReportingFormStatsVm,
} from "@rtslabs/field1st-fe-common";
import moment from "moment";
import * as React from "react";
import BarChart from "shared/src/components/Charts/BarChart/BarChart";
import PercentChart from "shared/src/components/Charts/PercentChart/PercentChart";
import { SummaryPieChart } from "shared/src/components/Charts/PieChart/PieChart";
import Loader from "shared/src/components/Loader/Loader";
import { DownloadResultsButton } from "shared/src/components/TableUI/TableSummary/DownloadResultsButton";
import { pluralize } from "shared/src/helpers/utils";
import { createGlobalStyle } from "styled-components";
import { ElementType } from "shared/src/qa-slugs";
import {
  H3,
  ListItem,
  SmallText,
} from "shared/src/components/clientAdmin/styles";

/**
 * When printing, just show the Summary tab contents and header.
 */
const GlobalPrintStyle = createGlobalStyle`
  @media print {
    .sidebar-navigation,
    #caFormTableSummary,
    #caFormFiltersAndStats,
    .caExportAllResponsesLink { 
      display: none !important; 
    }

    #mainContent { 
      display: block; 
      overflow: visible !important;
    }
  }
`;

interface ResponseSummariesProps {
  formId: number;
  formStats: APIState<ReportingFormStatsVm>;
  minMaxDate?: {
    minSubmissionDate: string;
    maxSubmissionDate: string;
  };
  switchToHistory: () => void;
  visible: boolean;
}

const ResponseSummaries = ({
  formId,
  switchToHistory,
  formStats,
  minMaxDate,
  visible,
}: ResponseSummariesProps) => {
  if (formStats.isLoading) {
    return <Loader loading />;
  }

  if (formStats.error) {
    return (
      <>
        An error occurred when loading the form summary. Refresh the page and
        try again.
      </>
    );
  }

  return formStats.data ? (
    <>
      {visible && <GlobalPrintStyle />}
      {formStats.data.questions.map((response) => {
        const hasChangedForRange = moment(
          response.question.lastModifiedDate
        ).isBetween(
          minMaxDate?.minSubmissionDate,
          minMaxDate?.maxSubmissionDate
        );
        return (
          <ResponseSummary
            key={response.question.id}
            formId={formId}
            hasChangedForRange={hasChangedForRange}
            switchToHistory={switchToHistory}
            {...response}
          />
        );
      })}
    </>
  ) : null;
};

type Data = {
  id: string;
  value: number;
  label: string;
};

interface ResponseSummaryType {
  formId: number;
  hasChangedForRange?: boolean;
  question: QuestionDTO;
  topResponses: {
    response: string;
    responseCount: number;
  }[];
  totalResponses?: number;
  switchToHistory: () => void;
}

/**
 * Helper function to get how to display a response for the given question type
 * @param subType
 */
const getResponseDisplayTransform = (
  subType: QuestionType
): ((string) => string) => {
  switch (subType) {
    case "TIME":
      return (s) => moment(s).format("hh:mm A");
    case "DATE":
      return (s) => moment(s).format("MM/DD/YY");
    case "DATE_TIME":
      return (s) => moment(s).format("MM/DD/YY hh:mm A");
    case "YES_OR_NO":
      return (s) =>
        s.toLowerCase() === "true" || s.toLowerCase() === "yes" ? "Yes" : "No";
    default:
      return (s) => s;
  }
};

const ResponseSummary = ({
  formId,
  question,
  totalResponses,
  topResponses,
}: ResponseSummaryType) => {
  const responseTransform = getResponseDisplayTransform(question.subType);
  topResponses = topResponses.map((r) => ({
    ...r,
    response: responseTransform(r.response),
  }));

  let responseDetails, detailsTitle;
  const dataForCharts: Array<Data> = topResponses.map((r) => ({
    id: r.response,
    value: r.responseCount,
    label: r.response,
  }));

  switch (question.subType) {
    case "TEXT_LINE":
    case "PARTICIPANT":
    case "TEXT_AREA":
    case "TIME":
    case "DATE":
    case "DATE_TIME":
      responseDetails = <MostPopularResponseDetails responses={topResponses} />;
      detailsTitle = "Most Popular Responses";
      break;

    case "RADIO_BUTTONS":
    case "CHECKBOX":
    case "YES_OR_NO":
      detailsTitle = "Results Chart";
      responseDetails = (
        <PieChartResponseDetails
          data={dataForCharts}
          totalResponses={totalResponses}
        />
      );
      break;

    // case "DROP_DOWN":
    //   detailsTitle = "Results Chart";
    //   responseDetails = <BarChartResponseDetails data={dataForCharts} />;
    //   break;

    case "DROP_DOWN":
    case "MULTI_SELECT":
      detailsTitle = "Results Chart";
      responseDetails = (
        <PercentSelectResponseDetails
          data={dataForCharts}
          totalResponses={totalResponses}
        />
      );
      break;
    default:
      responseDetails = null;
      break;
  }

  if (responseDetails) {
    return (
      <>
        <H3 className="mt-4 mb-3">{question.title}</H3>
        <div className="d-flex mb-4">
          <div className="col-4x w-25 mr-3">
            <SmallText className="d-block mb-3">
              {pluralize("response", totalResponses || 0, true)}
            </SmallText>

            {!!totalResponses && (
              <SmallText className="caExportAllResponsesLink">
                <DownloadResultsButton
                  onExportPress={API.downloadQuestionResponses}
                  exportParams={{
                    formId,
                    questionId: question.id,
                  }}
                  mediaType="text/csv"
                  iconType="export_csv"
                  isTextVariant={true}
                  label="Export All Responses"
                  qa={`${ElementType.Button}-export-all-responses`}
                />
              </SmallText>
            )}
          </div>

          <div className="w-75">
            {/* {hasChangedForRange && <QuestionHasChanged onClick={switchToHistory}/>} */}
            <SmallText className="font-weight-bold d-block mb-3">
              {detailsTitle}
            </SmallText>
            {topResponses && topResponses.length > 0 ? (
              responseDetails
            ) : (
              <>No responses</>
            )}
          </div>
        </div>
        <hr />
      </>
    );
  }
  return null;
};

const MostPopularResponseDetails = ({ responses }) => (
  <div className="row">
    <div className="col">
      {responses.slice(0, 3).map((popular) => (
        <ListItem key={popular.response}>
          {popular.response} ({popular.responseCount})
        </ListItem>
      ))}
    </div>
    <div className="col">
      {responses.slice(3, 6).map((popular) => (
        <ListItem key={popular.response}>
          {popular.response} ({popular.responseCount})
        </ListItem>
      ))}
    </div>
  </div>
);

const PieChartResponseDetails = ({ data, totalResponses }) => {
  // TODO should I take top 3, top N? All gets super messy.
  const formattedData: Array<Data> = data.slice(0, 3).map((d) => ({
    id: `${d.id} (${d.value})`,
    label: `${d.label} (${d.value})`,
    value: d.value,
  }));
  return (
    <div style={{ height: "300px", width: "500px" }}>
      <SummaryPieChart
        data={formattedData}
        labels={true}
        valueFormat={(value: number) =>
          `${Math.round((value / totalResponses) * 100)}%`
        }
      />
    </div>
  );
};

const BarChartResponseDetails = ({ data }) => {
  const height = data.length * 50;
  return (
    <div style={{ height: height + 100 }}>
      <BarChart data={data} />
    </div>
  );
};

const PercentSelectResponseDetails = ({ data, totalResponses }) => {
  return <PercentChart data={data} total={totalResponses} />;
};

export default ResponseSummaries;
