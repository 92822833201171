import { CoreRole, roles } from "@rtslabs/field1st-fe-common";

// All of those roles here were moved from core. ST-717
export const formTemplateWriteRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const resourceWriteRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CONTENT_EDITOR,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];
