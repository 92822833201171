import { LogoFileType, LogoImgProps } from "@rtslabs/field1st-fe-common";
import React, { Dispatch, SetStateAction } from "react";
import { AssistiveText } from "../../../../../shared/src/components/AssistiveText/AssistiveText";
import { DEFAULT_SVG_WIDTH } from "../../../../../shared/src/components/Logo";
import { DEFAULT_HEIGHT } from "../../../../../shared/src/components/LogoCondensed";
import { Components, ElementType } from "../../../../../shared/src/qa-slugs";
import { LogoType } from "./companyInfoHelpers";
import s from "./styles.module.scss";

interface CompanyLogoView {
  setFieldValue: (property: string, value?: LogoImgProps) => void;
  setPrimaryImageFile: Dispatch<SetStateAction<File | undefined>>;
  setCondensedImageFile: Dispatch<SetStateAction<File | undefined>>;
  primaryLogo?: LogoImgProps;
  condensedLogo?: LogoImgProps;
  companyName: string;
  isReadOnly?: boolean;
}

const CompanyLogoView = ({
  setFieldValue,
  setPrimaryImageFile,
  setCondensedImageFile,
  primaryLogo,
  condensedLogo,
  companyName,
  isReadOnly,
}: CompanyLogoView) => {
  const handlePreviewLogo = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: LogoType
  ) => {
    const imageFile = e.target.files?.[0];
    if (imageFile) {
      if (imageFile.size > 5000000) {
        window.alert("File size is too large. Select an image less than 5MB");
        return;
      }
      const imageUrl = URL.createObjectURL(imageFile);
      if (type === LogoType.Primary) {
        setPrimaryImageFile(imageFile);
        setFieldValue("primaryLogo", {
          src: imageUrl,
          alt: `${companyName} Primary Logo`,
          fileType: imageFile.type as LogoFileType,
        });
      } else if (type === LogoType.Condensed) {
        setCondensedImageFile(imageFile);
        setFieldValue("condensedLogo", {
          src: imageUrl,
          alt: `${companyName} Condensed Logo`,
          fileType: imageFile.type as LogoFileType,
        });
      }
    }
  };

  return (
    <>
      {/* Primary Company Logo */}
      <div className={s.logo}>
        <div className={s.primaryLogoWrapper} id="primaryImg">
          <img
            src={primaryLogo?.src}
            width={DEFAULT_SVG_WIDTH}
            alt={"Primary Logo Preview"}
          />
        </div>
        {!isReadOnly && (
          <div>
            <span>Select an image file from your computer.</span>
            <AssistiveText>
              Primary Logo: displays on account activation, user login, and
              password help/reset
            </AssistiveText>
            <AssistiveText>Max file size: 5MB</AssistiveText>
            <AssistiveText>File types accepted: png, svg</AssistiveText>
            <AssistiveText>Image looks best as a square</AssistiveText>
            <input
              id="primaryLogo"
              type="file"
              name="primaryLogo"
              accept="image/png, image/svg+xml"
              className={s.inputFile}
              onChange={(e) => handlePreviewLogo(e, LogoType.Primary)}
            />
            <label
              data-testid={`${Components.CompanyInfo}-${ElementType.Button}-primary`}
              className={s.fileButton}
              htmlFor="primaryLogo"
            >
              CHOOSE IMAGE
            </label>
          </div>
        )}
      </div>

      {/* Condensed Company Logo */}
      <div className={s.logo}>
        <div className={s.condensedLogoWrapper}>
          <img
            src={condensedLogo?.src}
            height={DEFAULT_HEIGHT}
            alt={"Condensed Logo Preview"}
          />
        </div>
        {!isReadOnly && (
          <div>
            <span>Select an image file from your computer.</span>
            <AssistiveText>
              Condensed Logo: displays in the top-left header
            </AssistiveText>
            <AssistiveText>Max file size: 5MB</AssistiveText>
            <AssistiveText>File types accepted: png, svg</AssistiveText>
            <AssistiveText>Image looks best as a rectangle</AssistiveText>
            <input
              id="condensedLogo"
              type="file"
              name="condensedLogo"
              accept="image/png, image/svg"
              className={s.inputFile}
              onChange={(e) => handlePreviewLogo(e, LogoType.Condensed)}
            />
            <label
              data-testid={`${Components.CompanyInfo}-${ElementType.Button}-condensed`}
              className={s.fileButton}
              htmlFor="condensedLogo"
            >
              CHOOSE IMAGE
            </label>
          </div>
        )}
      </div>
    </>
  );
};

export default CompanyLogoView;
