import {
  API,
  ApplicationConfig,
  ApplicationConfigKey,
  LogoConfigProperties,
  LogoFileType,
  LogoImgProps,
} from "@rtslabs/field1st-fe-common";
import { uploadS3Image } from "./uploadS3Image";

export type UploadLogosResponse = Promise<
  ApplicationConfig<ApplicationConfigKey.logoConfigs, LogoConfigProperties>
>;

/**
 * updates company logos
 * PUT /api/application-configs : Updates an existing applicationConfig
 */
export function uploadLogos(
  properties: LogoConfigProperties
): UploadLogosResponse {
  return API.updateApplicationConfig({
    config: {
      active: true,
      keyName: "logoConfigs",
      properties,
      secured: false,
    },
  }) as UploadLogosResponse;
}

export async function uploadImageFile(newFile: File): Promise<LogoImgProps> {
  // Acquire file type (image/png | image/svg) for PUT request
  const fileType = newFile.type as LogoFileType;
  if (![LogoFileType.png, LogoFileType.svg].includes(fileType)) {
    throw new Error(`Image file of type ${fileType} is not supported.`);
  }

  // PUT image blob to URL with appropriate Content-Type
  const writableUrl = await uploadS3Image(fileType, newFile);

  return {
    src: writableUrl,
    fileType,
  };
}
