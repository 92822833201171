import React from "react";

import ts from "./styles.module.scss";
import bs from "../../../../styles.module.scss";
import { joinClassNames } from "../../../../../../../helpers/theme.helpers";

interface Props {
  count: number;
}

const TagsCount = ({ count }: Props) => {
  return (
    <div className={joinClassNames(bs.row, ts.tagsCount)}>
      <i className="icon icon-icons8-tags" />
      <span className={ts.tagsCountText}>{count} tags</span>
    </div>
  );
};

export default TagsCount;
