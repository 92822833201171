import {
  API,
  CoreRedux,
  DocumentStatus,
  DocumentVm,
  PaginatedResponse,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import React from "react";
import { useSelector } from "react-redux";
import { TabGroup } from "../../../../../shared/src/components/Tabs/TabGroup/TabGroup";
import { VisuallyHidden } from "../../common/VisuallyHidden/VisuallyHidden";
import { RecentsCard } from "./RecentsCard";
import styles from "./RecentsContainer.module.scss";

export const RecentsContainer = () => {
  const userId = useSelector(CoreRedux.selectUser)!.participantId;

  const recentDraftsCall = useAPI<
    PaginatedResponse<DocumentVm>,
    API.GetDocumentsArgs
  >(API.getDocuments, {
    sort: ["submissionDate", "desc"],
    page: 0,
    ownerId: userId,
    size: 5,
    statuses: [DocumentStatus.NEW, DocumentStatus.IN_PROGRESS],
    summary: false,
  });
  const recentSubmissionsCall = useAPI<
    PaginatedResponse<DocumentVm>,
    API.GetDocumentsArgs
  >(API.getDocuments, {
    sort: ["submissionDate", "desc"],
    page: 0,
    ownerId: userId,
    size: 5,
    status: DocumentStatus.SUBMITTED,
    summary: false,
  });

  return (
    <section>
      <VisuallyHidden>
        <h2>Recent Documents and Submissions</h2>
      </VisuallyHidden>
      <TabGroup
        classes={{
          tab: styles.tab,
          tabList: styles.tabList,
          tabsContainer: styles.tabContainer,
          tabActive: styles.tabActive,
          tabLabel: styles.tabLabel,
          tabLabelActive: styles.tabLabelActive,
        }}
        variant="white"
        tabs={[
          {
            label: "Recent Drafts",
            tabId: "recentDrafts",
            tabPanelId: "recentDrafts",
            tabPanelContent: (
              <RecentsCard
                documents={recentDraftsCall?.data?.content ?? []}
                loading={recentDraftsCall?.isLoading ?? true}
              />
            ),
          },
          {
            label: "Recent Submissions",
            tabId: "recentSubmissions",
            tabPanelId: "recentSubmissions",
            tabPanelContent: (
              <RecentsCard
                documents={recentSubmissionsCall?.data?.content ?? []}
                loading={recentSubmissionsCall?.isLoading ?? true}
              />
            ),
          },
        ]}
      />
    </section>
  );
};
