import { applyMiddleware, combineReducers, createStore, Reducer } from "redux";
import thunkMiddleware, { ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  appConfigsReducer,
  authTokenReducer,
  clientGroupConfigsReducer,
  LOGOUT,
  participantConfigsReducer,
  REHYDRATE_COMPLETE,
  rehydratedReducer,
  userReducer,
} from "@rtslabs/field1st-fe-common/dist/main/redux";
import { API } from "@rtslabs/field1st-fe-common";

import { documentReducer } from "shared/src/redux/document/documentReducers";
import { arcGisReducer } from "./arcGis/arcGisReducers";

export const appReducer = combineReducers({
  // inherited from common
  appConfigs: appConfigsReducer,
  arcGis: arcGisReducer,
  clientGroupConfigs: clientGroupConfigsReducer,
  document: documentReducer,
  participantConfigs: participantConfigsReducer,
  user: userReducer,
  auth: authTokenReducer,
  rehydrated: rehydratedReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === LOGOUT.ACTION) {
    localStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};

const whitelist = ["auth", "user"];

const persistConfig = {
  key: "root",
  storage,
  ...(process.env.NODE_ENV === "production" && { whitelist }),
  blacklist: ["rehydrated"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

type RootState = ReturnType<typeof rootReducer>;
type ActionsOfReducer<G> = G extends Reducer<any, infer S> ? S : never;
type RootActions = ActionsOfReducer<typeof rootReducer>;

export default function configureStore() {
  const middleWareEnhancer = applyMiddleware<
    ThunkDispatch<RootState, any, RootActions>,
    any
  >(thunkMiddleware);

  const store = createStore(
    persistedReducer,
    composeWithDevTools(middleWareEnhancer)
  );
  const persistor = persistStore(store, undefined, () => {
    // on rehydrate
    const { token } = store.getState().auth;
    if (token) {
      API.Environment.authToken = token;
    }
    store.dispatch({ type: REHYDRATE_COMPLETE.ACTION });
  });

  return { store, persistor };
}
