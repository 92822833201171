import React, { FC, MouseEvent } from "react";
import { ActionButton } from "../../ActionButton/ActionButton";
import { Icon } from "../../Icon/Icon";
import { ElementType } from "../../../../src/qa-slugs";
import Popper from "../Popper";
import { PopperWrapper } from "./styles";

type Props = {
  buttonLabel: string;
  closeOnClick?: boolean;
  qaBase?: string;
  children?: React.ReactNode;
};

/** Popup display with action items or informative content */
export const ActionMenu: FC<Props> = ({
  buttonLabel,
  children,
  closeOnClick,
  qaBase,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>(); // added typescript, was null

  const handleClose = () => open && setOpen(false);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setTimeout(() => setOpen(!open));
  };

  // Hide context menu if no links -- GK
  if (!children) return null;

  return (
    <>
      <ActionButton
        qa={`${qaBase}-${ElementType.Menu}-${ElementType.Button}`}
        label={buttonLabel}
        onClick={handleOpen}
        IconComponent={
          <Icon
            type="more_vert"
            data-testid={`${qaBase}-${ElementType.Menu}`}
          />
        }
      />
      <Popper
        qa={`${qaBase}-${ElementType.MenuModal}`}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        placement="left"
      >
        <PopperWrapper onClick={closeOnClick ? handleClose : undefined}>
          {children}
        </PopperWrapper>
      </Popper>
    </>
  );
};
