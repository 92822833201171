import React, { FC } from "react";

import styles from "./ReadOnlySignatures.module.scss";

interface Props {
  signatureTextValue?: string | null;
}

export const RenderTypedSignature: FC<Props> = ({ signatureTextValue }) => {
  if (!signatureTextValue) return null;

  return (
    <div className={styles.signatureWrapper}>
      <span>{signatureTextValue}</span>
    </div>
  );
};
