import React, { useContext, useMemo, useState } from "react";
import { orderBy } from "lodash";

import { pluralize } from "shared/src/helpers/utils";

import * as S from "./styles";
import { DownloadResultsButton } from "shared/src/components/TableUI/TableSummary/DownloadResultsButton";
import SafetyResponseCommentsDrawer from "./drawer/SafetyResponseCommentsDrawer";
import { H3, SmallText } from "shared/src/components/clientAdmin/styles";
import { FormReportSafetyResponses, useFetchComments } from "../helpers";
import { ThemeContext } from "styled-components";
import { API } from "@rtslabs/field1st-fe-common";

interface SafetyReportProps {
  formId: number;
  totalResponses: number;
  ratingsFilter: string[];
  mappedRiskResponses: FormReportSafetyResponses;
}

const SafetySection = ({
  formId,
  color,
  riskLevelCounts,
  riskLevel,
  questions,
  openDrawer,
  totalResponses,
}) => {
  const percentage = !totalResponses
    ? 0
    : `${((riskLevelCounts / totalResponses) * 100).toFixed(1)}%`;
  const questionsSorted = orderBy(questions, ["responseCount"], ["desc"]);
  const selectionIds =
    (questions && questions.map((question) => question.selectionId)) || [];
  const showAllCommentsLink = !(!selectionIds || selectionIds.length === 0);
  const showExportAllResponsesLink = !!riskLevelCounts;

  return (
    <>
      <div className="d-flex mt-4 mb-3">
        <S.Dot color={color} />
        <H3 style={{ margin: 0 }}>{riskLevel}</H3>
      </div>
      <div className="d-flex mb-4">
        <div className="col-4x w-25 mr-3">
          <SmallText className="d-block">
            {pluralize("Response", riskLevelCounts, true)}
          </SmallText>
          <S.PercentageText className="d-block mb-3">{`${percentage} Total Responses`}</S.PercentageText>

          {showAllCommentsLink && (
            <SmallText className="d-block mb-1 caExportAllResponsesLink">
              <S.StyledDownloadButton
                onClick={() => openDrawer(selectionIds, riskLevel)}
                bold
              >
                View All Comments
              </S.StyledDownloadButton>
            </SmallText>
          )}
          {showExportAllResponsesLink && (
            <SmallText className="d-block caExportAllResponsesLink">
              <DownloadResultsButton
                onExportPress={API.downloadQuestionSelectionComments}
                exportParams={{
                  formId,
                  questionSelectionIds: selectionIds,
                }}
                iconType="export_csv"
                isTextVariant={true}
                mediaType="text/csv"
                label="Export All Comments"
              />
            </SmallText>
          )}
        </div>

        <div className="w-75">
          <SmallText className="font-weight-bold d-block mb-3">
            {`Most Frequently Reported with “${riskLevel}” Rating`}
          </SmallText>
          {questionsSorted && questionsSorted.length > 0 ? (
            questionsSorted.map((question) => (
              <SafetySectionDetails
                key={question.selectionId}
                openDrawer={() => openDrawer([question.selectionId], riskLevel)}
                question={question}
              />
            ))
          ) : (
            <>No responses</>
          )}
        </div>
      </div>
      <hr />
    </>
  );
};

const SafetySectionDetails = ({ question, openDrawer }) => (
  <div className="mb-3">
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <S.QuestionText className="font-weight-bold mr-4">
        {question.responseCount}
      </S.QuestionText>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <S.QuestionText>{question.question.title}</S.QuestionText>
        <S.AreaText>{`Area: ${question.area}`}</S.AreaText>
      </div>
      <SmallText className="d-flex ml-auto caExportAllResponsesLink">
        <S.StyledDownloadButton onClick={openDrawer}>
          View Comments
        </S.StyledDownloadButton>
        <S.SendCommentIcon />
      </SmallText>
    </div>
  </div>
);

export const SafetyReport = ({
  formId,
  totalResponses,
  ratingsFilter,
  mappedRiskResponses,
}: SafetyReportProps) => {
  const theme = useContext(ThemeContext);
  const colors = [
    theme.colors.error,
    theme.masterColors.warning,
    theme.colors.primary,
    theme.colors.success,
  ];
  const keyIndexs = Object.keys(mappedRiskResponses);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerRiskLevel, setDrawerRiskLevel] = useState<
    "Risk" | "Coaching Moment" | "Satisfactory" | "Best Practice"
  >("Risk");
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<number[]>([]);
  const responseComments = useFetchComments(
    formId,
    mappedRiskResponses[drawerRiskLevel]
  );
  const openDrawer = (selectionIds: number[], riskLevel) => {
    setSelectedQuestionIds(selectionIds);
    setDrawerRiskLevel(riskLevel);
    setDrawerOpen(true);
  };
  const headerText = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <S.Dot color={colors[keyIndexs.indexOf(drawerRiskLevel)]} />
        <span className="d-block">
          {drawerRiskLevel} Comments ({responseComments.length})
        </span>
      </div>
    ),
    [drawerRiskLevel, responseComments]
  );
  const responsesLoaded =
    !!responseComments.length &&
    responseComments.length === mappedRiskResponses[drawerRiskLevel].length;
  const handleExpansionChange = (id: number) => () => {
    setSelectedQuestionIds((prevState) => {
      if (prevState.includes(id)) return prevState.filter((s) => s !== id);
      return [...prevState, id];
    });
  };

  return (
    <>
      <SafetyResponseCommentsDrawer
        closeDrawer={() => {
          setDrawerOpen(false);
          setSelectedQuestionIds([]);
        }}
        handleExpansionChange={handleExpansionChange}
        headerText={headerText}
        responseComments={responseComments}
        responsesLoaded={responsesLoaded}
        selectedQuestionIds={selectedQuestionIds}
        show={drawerOpen}
        formId={formId}
      />
      {Object.keys(mappedRiskResponses).map((riskLevel, i) => {
        const riskLevelCountsMap = mappedRiskResponses[riskLevel].map(
          (x) => x.responseCount
        );
        if (ratingsFilter.includes(riskLevel) || !ratingsFilter.length) {
          return (
            <SafetySection
              formId={formId}
              riskLevel={riskLevel}
              key={riskLevel}
              color={colors[i]}
              questions={mappedRiskResponses[riskLevel]}
              riskLevelCounts={
                riskLevelCountsMap.length
                  ? riskLevelCountsMap.reduce((acc, cur) => acc + cur)
                  : 0
              }
              openDrawer={openDrawer}
              totalResponses={totalResponses}
            />
          );
        }
      })}
    </>
  );
};
