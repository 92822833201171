import React, { FC } from "react";
import { PhotoArea } from "../../Photos/PhotoArea";
import { useQuestion } from "../DocumentForm/useQuestion";
import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import { Components } from "../../../qa-slugs";
import { usePhotos } from "../DocumentForm/usePhotos";

interface ReadOnlyPhotosProps {
  question: QuestionDTO;
  appPath?: string;
}

export const ReadOnlyPhotos: FC<ReadOnlyPhotosProps> = ({
  question,
  appPath,
}) => {
  const { allowPhotos, qa, response } = useQuestion(question);

  // this handles the saving or rendering photos if a question has photos for each options rather than main question
  const photoAssociatedId = ["MULTI_SELECT"].includes(question.subType)
    ? response?.associatedId
    : undefined;

  const photoHandling = usePhotos(question.id, photoAssociatedId, appPath);
  const { photos } = photoHandling;

  return (
    <>
      {allowPhotos && !!photos.length && (
        <PhotoArea
          {...photoHandling}
          photosExpanded={true}
          qa={qa ? `${qa}-${Components.PhotoArea}-question` : undefined}
          type="IMAGES_BELOW"
          readOnly
        />
      )}
    </>
  );
};
