import React from "react";
import { Field, useFormikContext, useField } from "formik";

import {
  FormRelationshipTypeDTO,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";

import Label from "../../../Label/Label";
import Checkbox from "../../../Checkbox/Checkbox";
import YesOrNo from "../../../YesOrNo/YesOrNo";

import { FBForm } from "../../types";
import s from "./styles.module.scss";
import bs from "../styles.module.scss";
import { joinClassNames } from "../../../../helpers/theme.helpers";

interface AdditionalActionsFormProps {
  formTypes: FormTypeDTO[];
}

const AdditionalActionsForm = ({ formTypes }: AdditionalActionsFormProps) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();

  async function handleChangeRelationshipTypes(
    checked: boolean,
    formType: FormTypeDTO,
    relationshipType?: FormRelationshipTypeDTO
  ) {
    let relTypes = values.relationshipTypes || [];
    // checking the box (adding/updating the relationship)
    if (checked) {
      relTypes = [
        ...relTypes,
        {
          id: relationshipType?.id || null,
          displayName: formType.name,
          formId: values.id,
          formTypeId: formType.id,
          softDeleted: false,
        },
      ];
    }
    // unchecking the box (removing the relationship type)
    else if (relationshipType) {
      relTypes = relTypes.filter(
        (relType) => relType.formTypeId !== relationshipType.formTypeId
      );
    }
    setFieldValue("relationshipTypes", relTypes);
  }

  const [isRehuddleEligible] = useField({
    name: "isRehuddleEligible",
    value: "true",
  });
  const [sharingEnabled] = useField({
    name: "sharingEnabled",
    value: "true",
  });
  const [startFromWorkOrderEnabled] = useField({
    name: "startFromWorkOrderEnabled",
    value: "true",
  });

  return (
    <div className={joinClassNames(s.settingsForm, bs.contentContainer)}>
      <YesOrNo
        {...isRehuddleEligible}
        label="Enable Rehuddle?"
        onChange={(value: string) => {
          setFieldValue("isRehuddleEligible", value);
        }}
      />
      <Label htmlFor="formRelationshipTypes">
        Allow users to start additional related documents:
      </Label>
      {formTypes.map((ft: FormTypeDTO) => {
        const relationshipType = values.relationshipTypes?.find(
          (rt) => ft.id === rt.formTypeId
        );

        return (
          <div key={ft.id}>
            <Field
              name={`relationshipTypes_${ft.id}`}
              as={Checkbox}
              label={ft.name}
              checked={!!relationshipType}
              onChange={(isChecked: boolean) =>
                handleChangeRelationshipTypes(isChecked, ft, relationshipType)
              }
            />
          </div>
        );
      })}
      <YesOrNo
        {...sharingEnabled}
        label="Enable sharing?"
        onChange={(value: string) => {
          setFieldValue("sharingEnabled", value);
        }}
      />
      <YesOrNo
        {...startFromWorkOrderEnabled}
        label="Allow users to start form from work order?"
        onChange={(value: string) => {
          setFieldValue("startFromWorkOrderEnabled", value);
        }}
      />
    </div>
  );
};

export default AdditionalActionsForm;
