import { loadModules } from "esri-loader";

export interface FeatureLayer {
  fieldInfos: __esri.FieldInfo[];
  fields: __esri.Field[];
  id: string;
  label: string;
  layerId: number;
  loadFailed: boolean;
  portalItemId: string;
  url: string;
}

/**
 * @param portalItemId The WebMap `portalItemId`.
 * Note: this is different from a FeatureLayer `portalItemId`
 */
export const getLayers = async (
  portalItemId: string
): Promise<FeatureLayer[]> => {
  const [WebMap] = await loadModules<[__esri.WebMapConstructor]>([
    "esri/WebMap",
  ]);

  const webMap = new WebMap({
    portalItem: {
      id: portalItemId,
    },
  });

  await webMap.load();

  const featureLayers = await Promise.all(
    webMap.allLayers
      .toArray()
      .filter((layer): layer is __esri.FeatureLayer => layer.type === "feature")
      .map(getLayer)
  );

  return featureLayers.filter((layer): layer is FeatureLayer => !!layer);
};

interface FindLayerByIdArgs {
  featureLayerPortalItemId: string;
  layerId: number;
}
export const findLayerById = async ({
  featureLayerPortalItemId,
}: FindLayerByIdArgs): Promise<__esri.FeatureLayer | undefined> => {
  const [WebMap] = await loadModules<[__esri.WebMapConstructor]>([
    "esri/WebMap",
  ]);

  const webMap = new WebMap({
    portalItem: {
      id: "03b5af27b3734442b3823f7a0b4228cb",
    },
  });

  await webMap.load();

  // The feature layer needs to be fetched from the webmap so that it includes
  // changes to the layer made in the Map Viewer. For example, if field display
  // names are edited in the map instead of the layer itself.
  //
  // Loading the feature layer directly gets data from the source layer and
  // won't include these changes.
  const featureLayer = webMap.allLayers.find((layer) => {
    if (layer.type === "feature") {
      const featureLayer = layer as __esri.FeatureLayer;
      return featureLayer.portalItem.id === featureLayerPortalItemId;
    }
    return false;
  }) as __esri.FeatureLayer;

  await featureLayer.load();

  return featureLayer;
};

const getLayer = async (
  layer: __esri.FeatureLayer
): Promise<FeatureLayer | undefined> => {
  try {
    const loadedLayer = await layer.load();
    /**
     * Note: the FeatureLayer portalItem is different from the WebMap portalItem,
     * therefore the portalItemId is different too
     */
    const loadedPortalItem = await loadedLayer.portalItem.load();
    return {
      fieldInfos: loadedLayer.popupTemplate.fieldInfos,
      fields: loadedLayer.fields,
      id: loadedLayer.id,
      layerId: loadedLayer.layerId,
      label: loadedLayer.title,
      loadFailed: false,
      portalItemId: loadedPortalItem.id,
      url: loadedLayer.url,
    };
  } catch (error) {
    console.error("Failed to load a layer.", error);
  }
};
