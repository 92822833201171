import { chain } from "lodash";

/**
 * Portion out percentages so that they add to 100.
 *
 * @see https://en.wikipedia.org/wiki/Largest_remainder_method
 */
export const roundPercentages = (values: number[]) => {
  const off = 100 - values.reduce((acc, x) => acc + Math.round(x), 0);

  // The starting position of each value is saved so that the data can be put
  // back in the original order at the end.
  return chain(values.map((value, index) => ({ position: index, value })))
    .sortBy(({ value }) => Math.round(value) - value)
    .map(({ value, position }, i) => ({
      value:
        Math.round(value) +
        (off > i ? 1 : 0) -
        (i >= values.length + off ? 1 : 0),
      position,
    }))
    .sortBy(({ position }) => position)
    .map(({ value }) => value)
    .value();
};
