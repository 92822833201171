import React, { FC } from "react";
import { BarItemProps } from "@nivo/bar";
interface CustomBarDatum {
  id: string;
  value?: any;
  label: string;
  totalResponseCount: number;
}
const CustomBarElement: FC<BarItemProps<any>> = ({
  bar: { x, y, color, width, height },
  label,
}) => (
  <g transform={`translate(${x}, ${y})`}>
    <rect
      rx={4}
      height={height}
      width={width}
      fill={`url(#gradientA.0.${color}.1.${color})`}
    />
    ;
    <text
      x={width / 2}
      y={height / 2}
      textAnchor="middle"
      dominantBaseline="central"
      style={{ fontFamily: "sans-serif", fontSize: "11px" }}
    >
      <tspan y="-10">{label}</tspan>
    </text>
  </g>
);

export default CustomBarElement;
