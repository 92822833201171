import {
  DocumentOEVm,
  DocumentVm,
  QuestionDTO,
} from "@rtslabs/field1st-fe-common";

export const OEs: DocumentOEVm[] = [
  {
    description:
      "A colleague was bitten by a tick while working in a swampy area.",
    favoriteCount: 0,
    id: 513861,
    importance: "MEDIUM",
    incident: "Injury: (2014/05/13) Tick Bite ",
    incidentDate: null,
    includedCount: 0,
    score: 3,
  },
  {
    description:
      "A colleague was bitten on the leg while working a turn off order.",
    favoriteCount: 0,
    id: 513890,
    importance: "MEDIUM",
    incident: "Injury: (2015/09/11) Dog Bite",
    incidentDate: null,
    includedCount: 1,
    score: 3,
  },
];

export const getPreviewOEs = (document: DocumentVm) => {
  const tags = document.responses.flatMap((response) => {
    const question = document.form.sections
      .flatMap((section) => section.items)
      .find(
        (item): item is QuestionDTO =>
          item.type === "QUESTION" && item.id === response.questionId
      );
    const questionTags = question?.tags || [];
    const selectionTags =
      question?.selections && response.associatedId
        ? question.selections
            .filter((selection) => selection.id === response.associatedId)
            .flatMap((selection) => selection.tags || [])
        : [];
    return questionTags.concat(selectionTags);
  });

  const oes = tags.length > 0 ? OEs : [];

  return oes;
};
