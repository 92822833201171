export interface ConfigsContextProps {
  enableChoiceIcons?: boolean;
  enableCloneDocument?: boolean;
  enableDataSets?: boolean;
  enableDefense?: boolean;
  enableOE?: boolean;
  enableHistory?: boolean;
  enableSettings?: boolean;
  enableVoiceToText?: boolean;
  enableWO?: boolean;
}

export const defaultFBConfigs: ConfigsContextProps = {
  enableChoiceIcons: true,
  enableCloneDocument: true,
  enableDataSets: true,
  enableDefense: true,
  enableHistory: true,
  enableOE: true,
  enableSettings: true,
  enableVoiceToText: true,
  enableWO: true,
};
