import React, { FC } from "react";
import { Components } from "../../qa-slugs";
import { ListItem } from "../List/ListItem";

import styles from "./ListSimple.module.scss";

export type ListSimpleItem = {
  primary: JSX.Element;
  rightSideItem?: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  showLink: boolean;
} | null;

interface Props {
  bottomDivider?: boolean;
  listItems: ListSimpleItem[];
  topDivider?: boolean;
  qa?: string;
}

const ListSimple: FC<Props> = ({
  bottomDivider,
  listItems,
  topDivider,
  qa,
}) => {
  const showListItems = listItems.filter((item) => item?.showLink);

  return (
    <nav>
      {topDivider && <hr className={styles.divider} />}
      <div className={styles.list}>
        {showListItems &&
          showListItems.map((listItem: ListSimpleItem, i: number) => {
            return (
              listItem && (
                <div key={i}>
                  <ListItem
                    onClick={listItem?.onClick}
                    disabled={listItem?.disabled}
                    className={
                      !listItem?.disabled ? styles.listButton : styles.disabled
                    }
                    qa={`${qa}-${Components.DashboardDocumentDrawer}-list-item-${i}`}
                  >
                    {listItem?.primary}
                    {listItem?.rightSideItem}
                  </ListItem>
                </div>
              )
            );
          })}
        {bottomDivider && <hr className={styles.divider} />}
      </div>
    </nav>
  );
};

export default ListSimple;
