import React, { FC } from "react";
import moment from "moment";
import styles from "./HistoryDateCell.module.scss";

interface Props {
  historyDate?: string | null;
}

const HistoryDateCell: FC<Props> = ({ historyDate }) => {
  if (historyDate) {
    return (
      <div className={styles.historyDateCell}>
        <span>{moment(historyDate).format("M/DD/YY")}</span>
        <span className={styles.time}>
          {" "}
          {moment(historyDate).format("h:mm A")}
        </span>
      </div>
    );
  }

  return <span>-</span>;
};

export default HistoryDateCell;
