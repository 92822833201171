import React, { FC } from "react";

interface ClassName {
  className?: string;
}
export const NextArrow: FC<ClassName> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
    >
      <path
        d="M5.10211 0.803755L5.69668 0.193725C5.94844 -0.0645755 6.35554 -0.0645755 6.60462 0.193725L11.8112 5.53286C12.0629 5.79116 12.0629 6.20884 11.8112 6.46439L6.60462 11.8063C6.35286 12.0646 5.94576 12.0646 5.69669 11.8063L5.10211 11.1962C4.84767 10.9352 4.85303 10.5093 5.11282 10.2537L8.34014 7.09915L0.642785 7.09915C0.286575 7.09915 -2.27075e-07 6.80513 -2.4305e-07 6.43966L-2.81487e-07 5.56034C-2.97462e-07 5.19487 0.286575 4.90085 0.642785 4.90085L8.34014 4.90085L5.11282 1.74628C4.85035 1.49073 4.84499 1.0648 5.10211 0.803755Z"
        fill="white"
      />
    </svg>
  );
};
