import styled from "styled-components";

import { remCalc } from "shared/src/themes/helpers";
import mediaQueries from "shared/src/themes/mediaQueries";

// @TODO: Add comments to separate styles based on the components using them

// TOP LEVEL STRUCTURAL COMPONENTS WITHIN ITEM SELECTOR
export const ItemSelector = styled.div<{
  responsiveWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (${mediaQueries.maxPhone}) {
    height: 75vh;
  }

  // Used for when we want to have the drawer width match side drawer
  ${({ responsiveWidth }) =>
    responsiveWidth &&
    `
  @media (${mediaQueries.minTablet}) {
    width: 75vw;
  }

  @media (${mediaQueries.minDesktop}) {
    width: 30vw;
  }

  `};
`;
export const TitleRow = styled.div``;
export const ItemRow = styled.div`
  overflow-y: scroll;
  overflow-x: none;
`;

export const Title = styled.h2`
  font-family: "Roboto", sans-serif;
  font-size: ${remCalc(20)};
  margin-bottom: ${remCalc(4)};
  padding-bottom: 0;
`;
export const SubTitle = styled.h3`
  font-family: "Roboto", sans-serif;
  font-size: ${remCalc(14)};
  margin-top: 0;
  padding-top: 0;
`;

// used with Row to handle styles
type VariantType =
  | "base"
  | "title"
  | "none"
  | "button-bar"
  | "header"
  | "relative";

const justifyByVariant = {
  case: "space-between",
  "button-bar": "flex-end",
  title: "space-between",
  header: "space-between",
};

export const FormSectionTitle = styled.h4`
  font-family: "Roboto", sans-serif;
  font-size: ${remCalc(12)};
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  color: ${({ theme }) => theme.masterColors.darkGrey};
  letter-spacing: ${remCalc(1.88)};
`;

// eslint-disable-next-line  no-unexpected-multiline
export const Row = styled.div<{
  fullWidth?: boolean;
  noPadding?: boolean;
  padding?: string; // Pass padding value
  variant?: VariantType;
  withBottomBorder?: boolean;
}>`
  display: flex;
  justify-content: ${({ variant }) =>
    variant ? justifyByVariant[variant] : "space-between"};
  padding: ${({ padding }) => padding || "1rem"};

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `};

  ${({ padding, variant }) => {
    switch (variant) {
      case "header":
        return `
          align-items: center;
          padding: 0;
          padding-top: ${remCalc(35)};
        `;

      case "title":
        return `
          padding: ${padding || "0 1rem 1rem 1rem"};
          min-height: ${remCalc(
            12
          )}; // Needs to be at least same height as text inside it
          `;

      case "button-bar":
        return `
          padding: ${remCalc(16)};
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 100000;
          `;

      case "base":
        return `
          padding: ${remCalc(16)};
        `;

      default:
        return null;
    }
  }}

  ${({ withBottomBorder, theme }) =>
    withBottomBorder &&
    `
    border-bottom: ${remCalc(1)} solid ${theme.masterColors.mediumGrey};
  `}
`;

export const Col = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ fullWidth }) => fullWidth && "width: 100%"};
`;

export const CloseButton = styled.button`
  /* margin-top: ${remCalc(18.5)}; */
  width: ${remCalc(28)};
  height: ${remCalc(28)};
  background-color: ${({ theme }) => theme.masterColors.mediumGrey};
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.i`
  padding-top: 1px;
  color: ${({ theme }) => theme.colors.white};
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
  margin-bottom: 50px;
`;

// --------------------------------------------------------------------------
// ? ./components/RenderOptions.tsx
// styles for RenderOptions component
// --------------------------------------------------------------------------
