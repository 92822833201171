import React, { FC } from "react";
import styles from "./DocumentSidebarDrawerHeader.module.scss";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
import { Icon } from "shared/src/components/Icon/Icon";
import { Components, ElementType } from "shared/src/qa-slugs";
import { prettifyDocumentStatus } from "../../../helpers";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { DocumentStatus } from "@rtslabs/field1st-fe-common";
// This is all worded like it's generic but it's not, it's a document. We should
// get rid of `subtitle` and name them for what they are -- GK

interface Props {
  isRehuddle?: boolean | null;
  parentId?: number | null;
  status: string;
  subtitle1?: string;
  subtitle2?: string;
  subtitle3?: string;
  title: string;
  ownerName?: string;
  formType?: string;
  terms: {
    rehuddle: string;
  };
  onClose: () => void;
}

export const DocumentHeader: FC<Props> = (props) => {
  const {
    formType,
    isRehuddle,
    ownerName,
    parentId,
    status,
    subtitle1,
    subtitle2,
    subtitle3,
    title,
    terms,
    onClose,
  } = props;
  const rehuddleTerm = conjugateTerm({
    term: terms.rehuddle,
    role: "noun",
    modifier: undefined,
    fallback: "Rehuddle",
  });
  const rehuddleTermUpper = rehuddleTerm.toUpperCase();
  return (
    <div
      data-testid={`${Components.DashboardDocumentDrawer}-header`}
      className={styles.documentHeader}
    >
      <div className={styles.headerContent}>
        <div className={styles.titleRow}>
          <h1 className={styles.title}>{title}</h1>
          <span
            className={joinClassNames(
              styles.status,
              status === DocumentStatus.NEW && styles.new,
              status === DocumentStatus.IN_PROGRESS && styles.inProgress,
              status === DocumentStatus.SUBMITTED && styles.submitted
            )}
          >
            {prettifyDocumentStatus(status)}
          </span>
        </div>
        <p className={styles.subTitle1}>{`${
          parentId && isRehuddle ? `[${rehuddleTermUpper}]` : ""
        } ${subtitle1}`}</p>
        {formType && <p>{formType}</p>}
        <p className={styles.subTitle2}>{subtitle2}</p>
        <p className={styles.subTitle2}>{subtitle3}</p>
        {ownerName && <p className={styles.subTitle2}>{ownerName}</p>}
      </div>
      <div
        data-testid={`${Components.DashboardDocumentDrawer}-close-${ElementType.Button}`}
        className={styles.iconContainer}
        onClick={onClose}
      >
        <Icon type="delete_sign" className={styles.icon} />
      </div>
    </div>
  );
};
