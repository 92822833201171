import { createContext, useContext, useRef, useState } from "react";
import {
  ConfigsContextProps,
  defaultFBConfigs,
} from "../../components/clientAdmin/defaultFBConfigs";
import { NavigateFunction } from "react-router-dom";

const FBConfigsContext = createContext<ConfigsContextProps>(defaultFBConfigs);

export const FBConfigsProvider = FBConfigsContext.Provider;

export const useFBConfigs = () => {
  const appFBConfigs = useContext(FBConfigsContext);
  const configsRef = useRef<ConfigsContextProps>({
    ...defaultFBConfigs,
    ...appFBConfigs,
  });
  const [formBuilderConfigs, setFormBuilderConfigs] =
    useState<ConfigsContextProps>(configsRef.current);

  // ** This is for QA Testing Code **/
  const setConfigById = (id: string, value: boolean) => {
    configsRef.current = {
      ...formBuilderConfigs,
      [id]: value,
    };
    setFormBuilderConfigs(configsRef.current);
  };

  const reload = (id: number, navigate: NavigateFunction) => {
    navigate(
      `/forms/form/debug/${id}/${btoa(JSON.stringify(configsRef.current))}`
    );
  };

  const reset = (id: number, navigate: NavigateFunction) => {
    navigate(`/forms/form/${id}`);
  };
  // ** *** *** *** //

  return { formBuilderConfigs, setConfigById, reload, reset };
};

// This is a working code below without the QA testing feature. If we want to scrap that
// import { createContext, useContext } from "react";
// import {
//   ConfigsContextProps,
//   defaultFBConfigs,
// } from "../../components/clientAdmin/defaultFBConfigs";

// const FBConfigsContext = createContext<ConfigsContextProps>(defaultFBConfigs);

// export const FBConfigsProvider = FBConfigsContext.Provider;

// export const useFBConfigs = () => {
//   const appFBConfigs = useContext(FBConfigsContext);
//   const formBuilderConfigs = { ...defaultFBConfigs, ...appFBConfigs };

//   return { formBuilderConfigs };
// };
