import { API, DefenseDTO, DefenseSaveVm } from "@rtslabs/field1st-fe-common";
import { omit, uniq } from "lodash";

/**
 * Add defense to a question selection by adding the question selection id to the defense questionSelectionIds
 * @param defense Defense to add the question selection to
 * @param questionSelectionId ID of question selection
 */
export async function addDefenseToQuestionSelection(
  defense: DefenseDTO,
  questionSelectionId: number
): Promise<DefenseDTO> {
  const existingSelections =
    defense.questionSelections?.map((qs) => qs.id) || [];
  const questionSelectionIds = uniq([
    ...existingSelections,
    questionSelectionId,
  ]);

  const body: DefenseSaveVm = {
    ...omit(defense, "questionSelections"),
    questionSelectionIds,
  };

  return API.saveDefense({ defense: body });
}

/**
 * Remove a defense from a question selection by remove the question selection from the defense questionSelections
 * @param defense Defense to add the question selection to
 * @param questionSelectionId ID of question selection
 */
export async function removeDefenseFromQuestionSelection(
  defense: DefenseDTO,
  questionSelectionId: number
): Promise<DefenseDTO> {
  const questionSelectionIds = defense.questionSelections
    .filter((selection) => selection.id !== questionSelectionId)
    .map((s) => s.id);

  const body: DefenseSaveVm = {
    ...omit(defense, "questionSelections"),
    questionSelectionIds,
  };

  return API.saveDefense({ defense: body });
}
