export const availableIcons = [
  "droplet",
  "icons8-snow",
  "fallen-tree",
  "icons8-wind",
  "icons8-hurricane-winds",
  "lab",
  "fire",
  "magnet",
  "bug",
  "switch",
  "wrench",
  "icons8-settings",
  "airplane",
  "icons8-a_home",
  "office",
  "truck",
  "binoculars",
  "user",
  "users",
  "icons8-business_conference_female_speaker",
  "blocked",
  "lifebuoy",
  "aid-kit",
  "icons8-under_construction",
  "flag",
  "bullhorn",
  "bell",
  "address-book",
  "book",
  "icons8-add_file",
  "folder",
  "calendar",
  "icons8-autograph",
  "icons8-marker",
  "map",
  "compass2",
  "substation",
  "connection",
  "stopwatch",
  "icons8-high_priority",
  "icons8-error-filled",
  "icons8-iphone",
  "icons8-laptop",
  "icons8-lock1",
  "icons8-true_false",
];
