import React, { memo, useState } from "react";

import { ClientGroupDTO, FormTypeDTO } from "@rtslabs/field1st-fe-common";

import AdditionalActionsForm from "./AdditionalActionsForm";
import { SettingsForm } from "./SettingsForm";
import s from "../styles.module.scss";
import styles from "./FormBuilderSettings.module.scss";
import ss from "../baseUi/sidebar/styles.module.scss";
import { VerticalTabGroup } from "../../../Tabs/TabGroup/VerticalTabGroup";
import { joinClassNames } from "../../../../helpers/theme.helpers";

interface Props {
  clientGroups: ClientGroupDTO[];
  formTypes: FormTypeDTO[];
}

const FormBuilderSettings: React.FC<Props> = ({ clientGroups, formTypes }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  return (
    <div className={s.contentWrapper}>
      <VerticalTabGroup
        variant="white"
        setSelectedTab={setSelectedTab}
        classes={{
          tabList: joinClassNames(styles.tabsContainer),
          tab: styles.settingsTab,
          tabActive: styles.tabActive,
          content: s.content,
          tabSubLabel: styles.tabSubLabel,
          tabLabel: styles.tabLabel,
        }}
        tabs={[
          {
            label: "Form Settings",
            tabId: "form-settings",
            subLabel: "Form properties and group access",
            tabPanelId: "form-settings",
            tabPanelContent: (
              <SettingsForm clientGroups={clientGroups} formTypes={formTypes} />
            ),
          },
          {
            label: "Additional Actions",
            tabId: "additional-actions",
            subLabel: "Customize form actions within the app",
            tabPanelId: "additional-actions",
            tabPanelContent: <AdditionalActionsForm formTypes={formTypes} />,
          },
        ]}
      />
      {/* placeholder so width matches Create tab */}
      <div className={ss.sidebarContainer} />
    </div>
  );
};

export default memo(FormBuilderSettings);
