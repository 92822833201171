import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  CoreRedux,
  DocumentQuestionResponseVm,
  DocumentVm,
} from "@rtslabs/field1st-fe-common";

import { createMarkup } from "../../../util/createMarkup";
import { getResponsesAssociatedWithDefenses } from "../DocumentForm/formEntity.helpers";
import Label from "../../Label/Label";
import { ReadOnlyResponse } from "./ReadOnlyResponse";
import styles from "./ReadOnly.module.scss";
import useGroupTerm from "../../../util/hooks/useGroupTerm";

interface Props {
  document?: DocumentVm | null;
  documentId?: number;
  responseIds: Array<number | null | undefined>;
}

export const ReadOnlyDefenses = ({ responseIds, document }: Props) => {
  // Group config terms
  const defensesGroupTerm = useSelector((state: CoreRedux.Store.RootState) =>
    CoreRedux.selectTermByVisibleId(state, "defenses")
  );
  const defensesTerm = useGroupTerm("defenses", "noun", "plural", "Defenses");

  const documentDefenses =
    useMemo(
      () =>
        document?.form.defenses?.filter(
          (def) =>
            def.questionSelections[0] &&
            responseIds.includes(def.questionSelections[0].id)
        ),
      [document]
    ) || [];

  /**
   * Object with key being associatedID and value being response.
   * Only contains values which are associated with a Defense
   * attached / answered within this DocumentVM
   */
  const responsesAssociatedWithDefenses = useMemo(() => {
    if (documentDefenses.length > 0) {
      return getResponsesAssociatedWithDefenses({
        defenses: documentDefenses,
        responses: document?.responses || [],
      });
    }
    return {};
  }, [documentDefenses]);

  if (documentDefenses.length === 0) {
    return null;
  }

  return (
    <div>
      <Label className={styles.label}>{defensesTerm}</Label>

      {documentDefenses.map((defense) => (
        <div className={styles.section} key={defense.id}>
          <ReadOnlyResponse
            label="Defense"
            value={
              <div className={styles.value}>
                {defense.title && <p>{defense.title}</p>}
                <div
                  dangerouslySetInnerHTML={createMarkup(defense.description!)}
                />
              </div>
            }
          />

          {defense.questionSelections[0] && (
            <ReadOnlyResponse
              label="Comment"
              value={getComment({
                responses: responsesAssociatedWithDefenses,
                id: defense.questionSelections[0].id,
              })}
            />
          )}
        </div>
      ))}
    </div>
  );
};

interface GetComment {
  responses: {
    [key: number]: DocumentQuestionResponseVm;
  };
  id: number;
}

/**
 * Gets the comment string value for the given id
 * from the given responses. The id should be the
 * Defense questionSelectionIds[0].
 */
const getComment = ({ responses, id }: GetComment): string => {
  const response = responses[id];
  if (response && typeof response.comments === "string") {
    return response.comments;
  }

  return "--";
};
