import * as React from "react";
import styled from "styled-components";

import SafetyScale from "./SafetyObservationReport/SafetyScale";
import { H1, H5 } from "shared/src/components/clientAdmin/styles";
import { FormReportSafetyResponses } from "./helpers";
import ExpandableItemList from "../../../common/ExpandableItemList/ExpandableItemList";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import { ReportingFormStatsVm } from "@rtslabs/field1st-fe-common";

interface FormReportHeaderProps {
  datePeriod: string;
  formStats: ReportingFormStatsVm;
  ratingsFilter: string[];
  safetyFlag?: boolean;
  safetyResponses: FormReportSafetyResponses;
  safetyResponsesLoading?: boolean;
  totalResponseCount: number;
}

const GroupItemWrapper = styled.div`
  font-size: 1rem;
`;

const FormReportHeader = ({
  datePeriod = "",
  formStats,
  ratingsFilter,
  safetyFlag,
  safetyResponses,
  safetyResponsesLoading,
  totalResponseCount,
}: FormReportHeaderProps) => {
  // const dayFilterValue = (daysFilterOptions.find((o) => o.id === dateFilter) || { value: "All Time" }).value;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <H5>Form Title</H5>
        <H1 data-testid={`${Page.Desktop}-${Components.FormReport}`}>
          {formStats.form.name}
        </H1>

        <div className="d-flex mt-3 mb-3">
          <div
            data-testid={`${Components.ReportHeader}-${ElementType.Label}-formType`}
            className="mr-5"
          >
            <H5>Form Type</H5>
            {formStats.form.type.name}
          </div>

          <div>
            <H5>Groups</H5>
            <ExpandableItemList
              qa={`${Components.ReportHeader}-${ElementType.Text}-group`}
              items={formStats.clientGroups.map((group) => group.name) || []}
              Component={GroupItemWrapper}
            />
          </div>
        </div>
      </div>
      {safetyFlag && (
        <div>
          <SafetyScale
            dayFilterValue={datePeriod}
            loading={safetyResponsesLoading || false} // So it's not undefined
            ratingsFilter={ratingsFilter}
            responses={safetyResponses}
            totalResponseCount={totalResponseCount}
          />
        </div>
      )}
    </div>
  );
};

export default FormReportHeader;
