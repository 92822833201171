import {
  FormSubmissionConstraintDTO,
  FormSubmissionConstraintType,
} from "@rtslabs/field1st-fe-common";
import * as Yup from "yup";

type ItemSchema = {
  title?: Yup.StringSchema<string | undefined>;
  participantRole?: Yup.StringSchema<string | null | undefined>;
  selections?: Yup.ArraySchema<any>; // @todo types
  answerSource?: Yup.StringSchema<string | undefined | null>;
  questions?: Yup.ArraySchema<any>; // @todo types
  conditionFields?: Yup.ArraySchema<Yup.StringSchema<string | undefined>>;
  layerId?: Yup.NumberSchema<any>; // @todo types
};

type DisplayConditionSchema = {
  prefillAnswerField?: Yup.StringSchema<string>;
  action?: Yup.StringSchema<string>;
  sourceQuestionRootId?: Yup.NumberSchema<number>;
  targetRootId?: Yup.NumberSchema<number>;
  targetType?: Yup.StringSchema<string>;
};

const SELECTIONS_REQUIRED_FOR = ["DROP_DOWN", "MULTI_SELECT", "RADIO_BUTTONS"];

const requiredSchema = (fieldName: string) =>
  Yup.string().required(`${fieldName} is required`);

// yup typing for lazy schemas is not super workable at the moment - MW 6/10/21
const itemSchema: any = Yup.lazy((item: any) => {
  const itemSchema: ItemSchema = {};

  if (item.conditionFields) {
    itemSchema.conditionFields = Yup.array().of(
      Yup.string().matches(/.*[^\.]$/, "Please select field")
    );
  }

  if (!item.parentWidgetRootId) {
    // don't validate widget children
    if (item.type === "QUESTION" || item.type === "SECTION") {
      itemSchema.title = requiredSchema("Title");
    }
    if ("selections" in item && !item.answerSource?.dataSourceKey) {
      if (SELECTIONS_REQUIRED_FOR.includes(item.subType)) {
        itemSchema.selections = Yup.array().min(
          1,
          "At least one selection is required"
        );
      }
    }
    if (item.subType === "PARTICIPANT") {
      itemSchema.participantRole =
        requiredSchema("Participant Role").nullable();
      delete itemSchema.selections;
      if (
        !item.answerSource?.dataSourceKey &&
        item.answerSource?.type !== "CURRENT_PARTICIPANT"
      ) {
        itemSchema.answerSource = requiredSchema(
          "Participant data source"
        ).nullable();
      }
    }
    if (["DOCUMENT_CREATOR", "SUPERVISOR"].includes(item.subType)) {
      delete itemSchema.selections;
      itemSchema.title = requiredSchema("Title");
    }

    if (item.subType === "SAFETY_RATING") {
      itemSchema.questions = Yup.array().min(
        1,
        "At least one area to review is required"
      );
    }

    // TODO: CORE-1573 Figure out where to exclude this from the schema if esri is not active (follow up after CORE-1572)
    // if (item.subType === "ESRI_CONTENT") {
    //   // 0 is a common layer id
    //   itemSchema.layerId = Yup.number().moreThan(-1, "A layer is required");
    // }
  }
  // @todo - fix type
  return Yup.object().shape(itemSchema as any);
});

const FormValidationSchema = Yup.object().shape({
  clientGroups: Yup.array()
    .min(1, "At least one client group is needed")
    .required("Group Access is required"),
  name: Yup.string().required("Form title is required"),
  sections: Yup.array().of(
    Yup.object().shape({
      // title: requiredSchema("Title"),
      items: Yup.array().of(itemSchema),
    })
  ),
  formSubmissionConstraint: Yup.object()
    .nullable()
    .shape({
      hourLimit: Yup.number()
        .required("Time limit is required")
        .min(1, "Time limit must be greater than 0"),
    }),
});

export function isFormSubmissionConstraintValid(
  constraint: FormSubmissionConstraintDTO
): boolean {
  switch (constraint.type) {
    case FormSubmissionConstraintType.HOUR:
      return !!constraint.hourLimit;
    case FormSubmissionConstraintType.TIME:
      return !!constraint.timeLimit;
    default:
      // submissions can always be edited
      return true;
  }
}

export default FormValidationSchema;
