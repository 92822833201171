import React, { FC } from "react";
import Modal from "../common/Modal";
import { HazardContent } from "./HazardContent";

interface Props {
  openHazardAnalysis: boolean;
  onCloseHazardAnalysis: () => void;
  onUploadAnotherPhoto?: () => void;
  onSaveHazardToDocument?: () => void;
  onSaveHazardInfoToDoc?: (hazardInfo: string) => void;
  hideUpload?: boolean;
}

export const HazardAnalysis: FC<Props> = ({
  openHazardAnalysis,
  onCloseHazardAnalysis,
  onUploadAnotherPhoto,
  onSaveHazardInfoToDoc,
  hideUpload,
}) => {
  return (
    <Modal
      height="auto"
      width="auto"
      padding="20px"
      open={openHazardAnalysis}
      handleClose={onCloseHazardAnalysis}
      content={
        <HazardContent
          onUploadAnotherPhoto={onUploadAnotherPhoto}
          onSaveHazardInfoToDoc={onSaveHazardInfoToDoc}
          hideUpload={hideUpload}
        />
      }
    />
  );
};
