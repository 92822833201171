import React from "react";

interface ClassName {
  className?: string;
}
export const Sections = ({ className }: ClassName) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79236 0.180098C4.5713 -0.0600326 4.20135 -0.0600328 3.9803 0.180098L2.00082 2.33041L1.46707 1.75059C1.24601 1.51046 0.876067 1.51046 0.655014 1.75059C0.448329 1.97512 0.448329 2.32822 0.655013 2.55274L2.00082 4.01469L4.79236 0.982241C4.99904 0.757719 4.99904 0.40462 4.79236 0.180098ZM7.34207 1.3684C6.96419 1.3684 6.65786 1.67473 6.65786 2.05261C6.65786 2.43049 6.96419 2.73682 7.34207 2.73682H12.8158C13.1936 2.73682 13.5 2.43049 13.5 2.05261C13.5 1.67473 13.1936 1.3684 12.8158 1.3684H7.34207ZM6.65786 12.3158C6.65786 11.9379 6.96419 11.6316 7.34207 11.6316H12.8158C13.1936 11.6316 13.5 11.9379 13.5 12.3158C13.5 12.6937 13.1936 13 12.8158 13H7.34207C6.96419 13 6.65786 12.6937 6.65786 12.3158ZM10.079 6.15791C9.70109 6.15791 9.39476 6.46425 9.39476 6.84212C9.39476 7.22 9.70109 7.52634 10.079 7.52634L12.8158 7.52634C13.1937 7.52634 13.5 7.22 13.5 6.84212C13.5 6.46425 13.1937 6.15791 12.8158 6.15791L10.079 6.15791ZM5.46058 8.21052C6.12187 8.21052 6.65795 7.67444 6.65795 7.01315C6.65795 6.35186 6.12187 5.81578 5.46058 5.81578C4.79929 5.81578 4.26321 6.35186 4.26321 7.01315C4.26321 7.67444 4.79929 8.21052 5.46058 8.21052ZM5.46058 9.23683C6.68869 9.23683 7.68427 8.24126 7.68427 7.01315C7.68427 5.78504 6.68869 4.78947 5.46058 4.78947C4.23248 4.78947 3.2369 5.78504 3.2369 7.01315C3.2369 8.24126 4.23248 9.23683 5.46058 9.23683ZM3.92105 12.4868C3.92105 13.1481 3.38497 13.6842 2.72368 13.6842C2.0624 13.6842 1.52632 13.1481 1.52632 12.4868C1.52632 11.8256 2.0624 11.2895 2.72368 11.2895C3.38497 11.2895 3.92105 11.8256 3.92105 12.4868ZM4.94737 12.4868C4.94737 13.7149 3.95179 14.7105 2.72368 14.7105C1.49558 14.7105 0.5 13.7149 0.5 12.4868C0.5 11.2587 1.49558 10.2632 2.72368 10.2632C3.95179 10.2632 4.94737 11.2587 4.94737 12.4868Z"
      />
    </svg>
  );
};
