import React, { FC } from "react";
import { QAProps } from "../../../qa-slugs";
import styles from "./DocumentSection.module.scss";
import { useFormikContext } from "formik";
import { DocumentVm } from "@rtslabs/field1st-fe-common";
import moment from "moment";
import { useDocumentSection } from "./hooks/useDocumentSection";
import { isEmpty } from "lodash";

interface DocumentSectionStatusProps extends QAProps {
  submitting: boolean;
}

export const DocumentSectionStatus: FC<DocumentSectionStatusProps> = ({
  submitting,
  qa = "DocSec-status",
}) => {
  const { values, errors } = useFormikContext<DocumentVm>();
  const { getErrorCount } = useDocumentSection();

  const errorCount = getErrorCount();

  return (
    <div className={styles.documentStatus}>
      <div className={styles.lastSavedTimestamp}>
        <span className={styles.lastSaved}>Last Saved:</span>
        <span
          className={styles.timestamp}
          data-testid={`${qa}-lastSavedTimestamp`}
        >
          {!submitting && values.lastModifiedDate && (
            <>
              {moment(values.lastModifiedDate).format("MM/DD/yyyy")} &bull;{" "}
              {moment(values.lastModifiedDate).format("h:mma")}
            </>
          )}
          {submitting && <>Autosaving ...</>}
        </span>
      </div>
      {!isEmpty(errors) && !!errorCount && (
        <div className={styles.errors} data-testid={`${qa}-errorsOnPage`}>
          {errorCount} error{errorCount !== 1 && "s"} on this page
        </div>
      )}
    </div>
  );
};
