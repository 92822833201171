import React from "react";

import s from "../styles.module.scss";

const DeactivatedBanner: React.FC<{ visible: boolean }> = function ({
  visible,
}) {
  if (!visible) return null;

  return (
    <div className={s.deactivatedBanner}>
      This form is deactivated. To make changes, enable the form in the form
      list before attempting to edit.
    </div>
  );
};

DeactivatedBanner.displayName = "DeactivatedBanner";

export default DeactivatedBanner;
