import React from "react";

/**
 * experimental hook used to merge objects instead of overwriting them
 *
 * example:
 *   const [params, mergeParams] = useMergeState<API.GetDocumentsArgs>({ size: 10 });
 *   ...
 *   <Component
 *      updatePage={(page) => mergeParams({ page })}
 *   />
 */
export function useMergeState<S extends object>(
  initialState: S | (() => S)
): [S, (s: Partial<S>) => void] {
  const [state, setState] = React.useState(initialState);

  function mergeState(update: Partial<S>) {
    setState((s) => ({ ...s, ...update }));
  }

  return [state, mergeState];
}
