import {
  LogoConfigProperties,
  LogoImgProps,
  State,
} from "@rtslabs/field1st-fe-common";
import * as yup from "yup";
import {
  uploadImageFile,
  uploadLogos,
  UploadLogosResponse,
} from "../../../api/admin/uploadLogos";

export interface CompanyForm {
  primaryLogo?: LogoImgProps;
  condensedLogo?: LogoImgProps;
  name: string;
  email: string;
  address1: string;
  address2?: string;
  city: string;
  state: State | null;
  zip: number | null;
}

export enum LogoType {
  Primary = "PRIMARY",
  Condensed = "CONDENSED",
}

export const companySchema = yup.object().shape({
  primaryLogo: yup
    .object()
    .shape({
      src: yup.string().required(),
      alt: yup.string().notRequired(),
      fileType: yup.string().required(),
    })
    .default(undefined),
  condensedLogo: yup
    .object()
    .shape({
      src: yup.string().required(),
      alt: yup.string().notRequired(),
      fileType: yup.string().required(),
    })
    .default(undefined),
  name: yup.string().trim().required("Company Name is required"),
  email: yup
    .string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  address1: yup.string().required("Company Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required").nullable(),
  zip: yup.number().required("Zip code is required").nullable(),
});

interface UploadImagesArgs {
  newFiles: {
    primary?: File;
    condensed?: File;
  };
  existingLogos: LogoConfigProperties;
}
export async function uploadImages({
  newFiles,
  existingLogos,
}: UploadImagesArgs): UploadLogosResponse {
  let newPrimary: LogoImgProps | undefined;
  if (newFiles.primary || existingLogos.primary) {
    newPrimary = newFiles.primary
      ? await uploadImageFile(newFiles.primary)
      : existingLogos.primary;
  }
  let newCondensed: LogoImgProps | undefined;
  if (newFiles.condensed || existingLogos.condensed) {
    newCondensed = newFiles.condensed
      ? await uploadImageFile(newFiles.condensed)
      : existingLogos.condensed;
  }
  return uploadLogos({
    primary: newPrimary,
    condensed: newCondensed,
  });
}
