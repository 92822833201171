import React from "react";
import styles from "./PhotoSlide.module.scss";

interface PhotoSlideProps {
  photoParticipantName?: string | null;
  photoQuestionTitle: string;
  original: string;
  originalHeight: number;
  originalWidth: number;
  description?: string;
  originalAlt?: string;
  originalTitle?: string;
  sizes?: string;
  srcSet?: string;
  loading?: "eager" | "lazy";
}

const PhotoSlide = React.memo(
  ({
    photoParticipantName,
    photoQuestionTitle,
    original,
    originalHeight,
    originalWidth,
    description,
    originalAlt,
    originalTitle,
    sizes,
    srcSet,
    loading = "eager",
  }: PhotoSlideProps) => {
    return (
      <React.Fragment>
        <h1 className={styles.title}>
          {photoParticipantName
            ? `${photoParticipantName} on ${photoQuestionTitle}`
            : photoQuestionTitle}
        </h1>
        <img
          className="image-gallery-image"
          src={original}
          alt={originalAlt}
          srcSet={srcSet}
          height={originalHeight}
          width={originalWidth}
          sizes={sizes}
          title={originalTitle}
          loading={loading}
        />
        <div className={styles.center}>
          <p className={styles.description} title={description}>
            {description}
          </p>
        </div>
      </React.Fragment>
    );
  }
);

export default PhotoSlide;
