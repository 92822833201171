import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  EditProfileForm,
  OfficeLocation,
} from "shared/src/components/Profile/EditProfile/EditProfileForm";
import { TitleBar } from "shared/src/components/TitleBar/TitleBar";
import scssVariables from "shared/src/sass/jsExports.module.scss";

import * as S from "./styles";
import { API, UpdateUserConfig, useAPI } from "@rtslabs/field1st-fe-common";
import { useAuth } from "../../../store/hooks/useAuth";
import {
  getUser,
  putUser,
  selectUser as userParticipantSelector,
} from "@rtslabs/field1st-fe-common/dist/main/redux";
import { useAppDispatch } from "../../../store/hooks";

const toUpdateMeVm = (config: UpdateUserConfig) => {
  return {
    ...config,
    workLocationId:
      config.workLocationId !== -1 ? config.workLocationId : undefined,
  };
};

const EditProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isTablet = useMediaQuery({
    maxWidth: scssVariables.maxTablet,
  });

  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  const userProfile = useSelector(userParticipantSelector);

  // get office location options
  const workLocationCall = useAPI(API.getWorkLocations);
  const officeLocations: OfficeLocation[] = React.useMemo(() => {
    if (!workLocationCall.data) {
      return [];
    }
    return workLocationCall.data.content.map((location) => ({
      label: location.name,
      value: location.id,
      id: location.id,
    }));
  }, [workLocationCall.data]);

  /**
   * If user widens viewport to desktop view
   * we'll replace the route with the desktop
   * profile route. '/profile' is used for
   * Edit Profile and View Profile on Desktop
   */
  useEffect(() => {
    if (!isTablet) {
      navigate("/profile", { replace: true });
    }
  }, [isTablet]);

  // fetch new User data
  // when we leave the Edit Profile
  // component. This will enure that updates
  // made will be in redux
  useEffect(() => {
    return () => {
      dispatch(getUser());
    };
  }, [dispatch]);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = async (
    values: UpdateUserConfig,
    { setSubmitting }: { setSubmitting: (boolean) => void }
  ) => {
    try {
      const updateMeVm = toUpdateMeVm(values);
      await dispatch(putUser(updateMeVm));
      /**
       * We'll only need to fetch when viewing on desktop.
       * On Desktop there are elements which reflect the cached profile data.
       * On Mobile we don't worry any user data except for the form
       * which will always be up to date. (or ahead)
       */
      if (isDesktop) {
        dispatch(getUser());
      }
      navigate(-1);
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  const { logout } = useAuth();
  return (
    <S.EditProfile>
      <TitleBar title="Edit Profile" />
      <EditProfileForm
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        userProfile={userProfile!}
        officeLocations={officeLocations}
        logout={logout}
      />
    </S.EditProfile>
  );
};

export default EditProfile;
