import React, { useState } from "react";
import { DocumentOEVm } from "@rtslabs/field1st-fe-common";

import Loader from "shared/src/components/Loader/Loader";
import OECard from "./OECard";
import styles from "../OperationalExperiences.module.scss";

interface Props {
  displayedOEs: DocumentOEVm[];
  toggleInclude: (oe: DocumentOEVm, included: boolean) => Promise<void>;
  loading: boolean;
  includedOes: DocumentOEVm[];
}

function RenderOEs({
  displayedOEs,
  toggleInclude,
  loading,
  includedOes,
}: Props) {
  const [loadingInclude, setLoadingInclude] = useState<number>();

  async function handleIncludeExcludeOe(oe: DocumentOEVm, included: boolean) {
    setLoadingInclude(oe.id);
    try {
      await toggleInclude(oe, included);
    } finally {
      setLoadingInclude(undefined);
    }
  }
  return (
    <Loader loading={loading}>
      <div className={styles.operationalExperiences}>
        {displayedOEs.map((oe) => {
          const included = !!includedOes.find((iOe) => iOe.id === oe.id);
          return (
            <OECard
              key={oe.id}
              oe={oe}
              handleIncludeExcludeOE={handleIncludeExcludeOe}
              included={included}
              loading={loadingInclude === oe.id}
            />
          );
        })}
      </div>
    </Loader>
  );
}

export default RenderOEs;
