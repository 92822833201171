import React from "react";

interface ClassName {
  className?: string;
  viewBox?: string;
}

export const SortDown = ({ className, viewBox }: ClassName) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox={"0 0 24 24" || viewBox}
      width="24px"
      className={className}
    >
      <path d="M 12.029297,15.618035 A 1.2200669,1.2200669 0 0 1 11.136719,15.260613 L 4.6308594,8.7547542 A 1.2200669,1.2200669 0 0 1 5.4941406,6.6727229 H 18.505859 a 1.2200669,1.2200669 0 0 1 0.863282,2.0820313 l -6.50586,6.5058588 a 1.2200669,1.2200669 0 0 1 -0.833984,0.357422 z" />
    </svg>
  );
};
