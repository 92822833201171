import { LogoFileType, LogoImgProps } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { ElementType } from "../qa-slugs";
import { Link } from "./Link/Link";

export const BACKUP_IMAGE = "/assets/logo/Field1stLogo.svg";
export const BACKUP_ALT_TEXT = "Field1st Logo";
export const DEFAULT_SVG_WIDTH = "250";

interface LogoProps {
  logo?: LogoImgProps;
  className?: string;
}

const Logo: FC<LogoProps> = ({ logo, className }) => {
  const logoSrc = logo?.src ? logo.src : BACKUP_IMAGE;
  let width: string | undefined;
  // the "width" attribute of the "img" tag is required for SVGs, but not PNGs
  if (logo?.fileType === LogoFileType.svg || logoSrc === BACKUP_IMAGE) {
    width = DEFAULT_SVG_WIDTH;
  }

  return (
    <Link to="/" qa={`${ElementType.Logo}-${ElementType.Link}-toHome`}>
      <img
        src={logoSrc}
        width={width}
        alt={logo?.alt ? logo.alt : BACKUP_ALT_TEXT}
        className={className}
      />
    </Link>
  );
};
export default Logo;
