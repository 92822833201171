import { get } from "lodash";

import { SelectOption } from "../../../../../../Select/Select";

import { workOrderDto } from "./workOrderDto";
import { DisplayConditionDTO } from "@rtslabs/field1st-fe-common";
import { FBDisplayCondition, FBItem } from "../../../../../types";

/** Check if a value is an object with keys */
export function getHasChildKeys(value: unknown) {
  return typeof value === "object" && !Array.isArray(value);
}

/**
 * Get the parent path of a field on the work order DTO
 * @param fieldPath chained string path of the field
 */
export function getWorkOrderPath(fieldPath: string) {
  const keys = fieldPath.split(".");
  keys.pop();
  return keys.length === 1 ? keys[0] : keys.join(".");
}

/**
 * Build options for a select field
 * @param conditionField name of the field to build options for
 * @param woFieldValue value of the field on the work order dto
 * @param path path of the field to build options for
 */
export function buildOptions(
  conditionField: string,
  woFieldValue: unknown,
  path: string
): SelectOption[] {
  const options = [] as SelectOption<string>[];
  if (woFieldValue) {
    if (typeof woFieldValue === "string" || Array.isArray(woFieldValue)) {
      options.push({
        label: woFieldValue.toString(),
        value: conditionField,
      });
    } else if (typeof woFieldValue === "object") {
      options.push(
        ...Object.keys(woFieldValue as {}).map((childField) => ({
          value: `${path ? `${path}.` : ""}${childField}`,
          label: childField,
        }))
      );
    }
  }
  return options;
}

export function buildWODisplayConditions(
  item: FBItem,
  value: string | undefined,
  currentConditions: FBDisplayCondition[] = []
) {
  const conditions = [...(currentConditions || [])];
  const dtoValue = value && get(workOrderDto, value);

  // if the value is a nested field, get the id/geolocation fields from the parent
  // i.e. workLocation.physicalAddress.address string would find id from workLocation
  const valuePathArray = value?.split(".");
  let idField = dtoValue?.id ? `${value}.id` : undefined;
  let locationField = dtoValue?.geolocation
    ? `${value}.geolocation`
    : undefined;

  if (valuePathArray && valuePathArray.length > 1) {
    idField = `${valuePathArray[0]}.id`;
    locationField = `${valuePathArray[0]}.geolocation`;
  }

  const condition: DisplayConditionDTO = {
    action: "WORK_ORDER_PREFILL",
    prefillAnswerField: `${value}${getHasChildKeys(dtoValue) ? "." : ""}`,
    prefillAssociatedIdField: idField,
    prefillAssociatedLocation: locationField,
    targetRootId: item.rootId!,
    targetType: "QUESTION",
  };
  const conditionIndex = conditions.findIndex(
    (cond) =>
      cond.targetRootId === condition.targetRootId &&
      cond.action === "WORK_ORDER_PREFILL"
  );
  if (conditionIndex > -1) {
    conditions[conditionIndex] = condition;
  } else {
    conditions.push(condition);
  }
  return conditions;
}
