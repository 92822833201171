import React, { FC, ReactNode } from "react";

import styles from "./DocumentHeader.module.scss";
import { Button, IconButton } from "../../Button/Button";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { Components, ElementType, Page } from "../../../qa-slugs";

interface Props {
  className?: string;
  handleClickOnBackArrow?: () => void;
  handleOnClickMenu?: () => void;
  hideBackArrow?: boolean;
  title: string;
  formProgress?: number;
  additionalActions?: ReactNode;
}

export const DocumentHeader: FC<Props> = ({
  className,
  hideBackArrow,
  title,
  handleOnClickMenu,
  handleClickOnBackArrow,
  formProgress,
  additionalActions,
}) => {
  const [menuOpen, toggleMenuOpen] = React.useState(false);

  const handleOnClickMenuInternal = () => {
    if (handleOnClickMenu) {
      return handleOnClickMenu();
    }
    toggleMenuOpen(!menuOpen);
  };

  return (
    <div className={className}>
      <div className={styles.appBar}>
        <div className={styles.content}>
          {!hideBackArrow && (
            <IconButton
              icon={{
                type: "chevron-left",
                className: styles.icon,
              }}
              onClick={handleClickOnBackArrow}
              data-testid={`${Page.Desktop}-${Components.Document}-${ElementType.Button}-back`}
            />
          )}
          <h1
            className={styles.title}
            data-testid={`${Page.Desktop}-${Components.Document}`}
          >
            {title}
          </h1>
          <div className={styles.actionsContainer}>
            {additionalActions && (
              <span className={styles.additionalActionsContainer}>
                {additionalActions}
              </span>
            )}
            <IconButton
              icon={{
                type: "more_vert",
                className: styles.icon,
              }}
              onClick={handleOnClickMenuInternal}
              data-testid={`${Page.Desktop}-${Components.Document}-${ElementType.Button}-menu`}
            />
          </div>
        </div>
        <ProgressBar value={formProgress} />
      </div>
      <div style={{ height: "80px" }} />
    </div>
  );
};
