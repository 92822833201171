export enum HazardActionTypes {
  SET_PHOTOS_UPLOAD_IN_PROGRESS = "SET_PHOTOS_UPLOAD_IN_PROGRESS",
  SET_HAZARD_IMAGE_URL = "SET_HAZARD_IMAGE_URL",
}

export interface HazardImageUrlAction {
  value: URL | null;
  type: typeof HazardActionTypes.SET_HAZARD_IMAGE_URL;
}

export interface HazardPhotoUploadInProgressAction {
  value: boolean;
  type: typeof HazardActionTypes.SET_PHOTOS_UPLOAD_IN_PROGRESS;
}

export type HazardActions =
  | HazardImageUrlAction
  | HazardPhotoUploadInProgressAction;
