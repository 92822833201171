import { WorkflowType } from "@rtslabs/field1st-fe-common";
import React from "react";
import { Modal } from "../../Modal/Modal";
import useGroupTerm from "../../../util/hooks/useGroupTerm";

interface Props {
  closeModal: () => void;
  desiredWorkflowType: WorkflowType;
  setWorkflowType: (workflowType: WorkflowType) => void;
  loading: boolean;
  modalOpen: boolean;
  qaBase?: string;
}

interface Verbiage {
  actionLabel: string;
  title: string;
  message: string;
}

function getVerbiage(
  documentsTerm: string,
  desiredWorkflowType: WorkflowType
): Verbiage {
  documentsTerm = documentsTerm.toLowerCase();

  switch (desiredWorkflowType) {
    case "DEACTIVATED_DRAFT":
    case "DEACTIVATED_FINAL":
      return {
        actionLabel: "Disable form",
        title: "Are you sure you want to deactivate this form?",
        // eslint-disable-next-line max-len
        message: `Deactivating a form will disable it from being used to create new ${documentsTerm}. This change can be undone.`,
      };
    case "DRAFT": // reactivating
    case "FINAL":
      return {
        actionLabel: "Enable form",
        title: "Are you sure you want to enable this form?",
        // eslint-disable-next-line max-len
        message: `Enabling a form will allow the last published version of it to be used to create new ${documentsTerm}. This change can be undone.`,
      };
    case "DELETED":
      return {
        actionLabel: "Delete form",
        title: "Are you sure you want to delete this form?",
        // eslint-disable-next-line max-len
        message: `Deleting a form will remove it from this list and prevent it from being used to create new ${documentsTerm}. This change cannot be undone.`,
      };
    case "DISCARDED_DRAFT":
      return {
        actionLabel: "Discard draft changes",
        title: "Are you sure you want to discard any changes on this form?",
        // eslint-disable-next-line max-len
        message:
          "Discarding changes restores the form to the previously published version (if any). This change cannot be undone.",
      };
    default:
      // shouldn't ever happen
      return {
        actionLabel: "",
        title: "",
        message: "",
      };
  }
}

export function ConfirmationModal({
  closeModal,
  setWorkflowType,
  loading,
  modalOpen,
  desiredWorkflowType,
  qaBase,
}: Props) {
  const documentsTerm =
    useGroupTerm("document", "noun", "plural") || "Documents";
  const verbiage = getVerbiage(documentsTerm, desiredWorkflowType);
  return (
    <Modal
      qaBase={qaBase}
      action={{
        text: verbiage.actionLabel,
        callback: () => setWorkflowType(desiredWorkflowType),
        loading,
      }}
      alert={{
        variant: "warning",
        title: verbiage.title,
        message: verbiage.message,
        isVisible: true,
      }}
      handleClose={closeModal}
      open={modalOpen}
      title="Are you sure?"
    />
  );
}
