import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

// Global
import {
  API,
  ClientGroupDTO,
  JWTAuthorityDTO,
} from "@rtslabs/field1st-fe-common";
import VisibilitySensor from "react-visibility-sensor";
import { Button } from "shared/src/components/Button/Button";
import Loader from "shared/src/components/Loader/Loader";
import SearchBar from "shared/src/components/SearchBar/SearchBar";
import { useInfiniteScrollAPI } from "shared/src/util/hooks/useInfiniteScrollAPI";
import {
  Components,
  ElementType,
} from "../../../../../../../../shared/src/qa-slugs";
import Drawer from "../../../../../common/Drawer";
import { ParticipantListItem as ParticipantListItemBase } from "../../../../../common/ListItem";
import { Role } from "../../../constants";
import * as S from "./styles";

const ParticipantListItem = styled(ParticipantListItemBase)`
  margin-bottom: 6px;
  width: 100%;
`;

const addLocalGroup = (groupId: number, localGroups: number[]) => {
  return [...localGroups, groupId];
};

const removeLocalGroup = (groupId: number, localGroups: number[]) => {
  return localGroups.filter((group) => group !== groupId);
};

const SelectOrDeselectAll = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 1rem;
  text-decoration: underline;
`;

const ActionRow = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 1rem;
  width: 100%;
`;

const Circle = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 6px;
  padding-right: 6px;
`;

interface Props {
  groupsToExclude: number[];
  handleClose: (authority?: JWTAuthorityDTO) => void;
  role: Role;
}

export const GroupPicker = ({ groupsToExclude, handleClose, role }: Props) => {
  const groupsCall = useInfiniteScrollAPI(
    API.getClientGroups,
    { sort: "name,asc" },
    () => console.error("Problem getting groups")
  );

  const [groupOptions, setGroupOptions] = useState<ClientGroupDTO[]>([]);
  useEffect(() => {
    setGroupOptions(
      groupsCall.data.filter((g) => !(groupsToExclude.indexOf(g.id) >= 0))
    );
  }, [groupsCall.data]);

  // Added groups (local, not saved, by ID)
  const [localAddedGroups, setLocalAddedGroups] = useState<number[]>([]);

  // Random UI variables
  const numberOfGroupsToAdd = useMemo(
    () => localAddedGroups.length,
    [localAddedGroups.length]
  );
  const pluralOrSingularButtonLabel = useMemo(() => {
    if (numberOfGroupsToAdd === 1) return "GROUP";
    return "GROUPS";
  }, [numberOfGroupsToAdd]);
  const selectOrDeselectCopy = useMemo(() => {
    if (numberOfGroupsToAdd === groupOptions.length) {
      return "Deselect All";
    }
    return "Select All";
  }, [groupOptions, numberOfGroupsToAdd]);

  // Add or Remove all groups
  const addOrRemoveAllLocalGroups = () => {
    const action: "remove" | "add" =
      groupOptions.length === localAddedGroups.length ? "remove" : "add";
    if (action === "remove") {
      setLocalAddedGroups([]);
    } else {
      const _flattenedGroups = groupOptions.map((group) => group.id || 0) || [];
      setLocalAddedGroups(_flattenedGroups);
    }
  };

  return (
    <Drawer
      qa={`${Components.GroupPicker}-${ElementType.Drawer}-addGroups`}
      content={
        <>
          <S.ItemSelector responsiveWidth={true}>
            {/* HEADER AREA */}
            <S.TitleRow>
              <S.Row variant="header" withBottomBorder>
                <S.Row fullWidth>
                  <S.Col>
                    <S.Title>{`Add group to ${role.value} role`}</S.Title>
                    <S.SubTitle>
                      Which groups would you like to add to this set of
                      permissions?
                    </S.SubTitle>
                  </S.Col>
                </S.Row>
              </S.Row>

              <S.Row>
                <S.Col fullWidth>
                  <SearchBar
                    onSearch={groupsCall.setQuery}
                    name="ItemSelectorDrawerSearch-GroupPicker`"
                    placeholder={"Search by name"}
                    qa={`${Components.GroupPicker}-${ElementType.TextInput}-search`}
                  />
                </S.Col>
              </S.Row>
            </S.TitleRow>
            <S.Row padding="0 16px 0 28px" variant="title">
              <S.FormSectionTitle>
                GROUPS IN ALPHABETICAL ORDER
              </S.FormSectionTitle>
            </S.Row>
            <S.ItemRow>
              <S.Row padding="8px 16px 16px 16px">
                <S.List>
                  {groupOptions.map((group: ClientGroupDTO) => {
                    return (
                      <ParticipantListItem
                        qa={`${Components.ListItem}-${ElementType.Link}-${group.id}`}
                        key={group.id}
                        renderLabelsAsRow
                        active={localAddedGroups.includes(group.id)}
                        label={group.name}
                        onClick={() => {
                          localAddedGroups.includes(group.id)
                            ? setLocalAddedGroups(
                                removeLocalGroup(group.id, localAddedGroups)
                              )
                            : setLocalAddedGroups(
                                addLocalGroup(group.id, localAddedGroups)
                              );
                        }}
                        variant="ItemSelectorDrawer"
                      />
                    );
                  })}
                  {!groupsCall.isLastPage && (
                    <VisibilitySensor onChange={groupsCall.loadMore}>
                      <Loader loading={true} />
                    </VisibilitySensor>
                  )}
                </S.List>
              </S.Row>
            </S.ItemRow>

            <ActionRow>
              <SelectOrDeselectAll
                data-testid={`${Components.GroupPicker}-${ElementType.Link}-selectOrDeselectAll`}
                onClick={addOrRemoveAllLocalGroups}
              >
                {selectOrDeselectCopy}
              </SelectOrDeselectAll>
              <Button
                qa={`${Components.GroupPicker}-${ElementType.Button}-addGroups`}
                onClick={() => {
                  handleClose({
                    authority: role.id,
                    isGlobal: false,
                    groups: groupsToExclude.concat(localAddedGroups),
                  });
                  setLocalAddedGroups([]);
                }}
                disabled={numberOfGroupsToAdd === 0}
              >
                {"ADD"} <Circle>{numberOfGroupsToAdd}</Circle>
                {pluralOrSingularButtonLabel}
              </Button>
            </ActionRow>
          </S.ItemSelector>
        </>
      }
      anchor="right"
      id="GroupPickerDrawer"
      onClose={() => {
        setLocalAddedGroups([]);
        handleClose();
      }}
      open={true}
      paperProps={{ elevation: 0 }}
      overrideClasses={{
        content: "myDocumentsDrawerDesktop",
      }}
    />
  );
};
