import { DocumentParticipantVm } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { RenderDrawnSignature } from "./RenderDrawnSignature";
import { RenderTypedSignature } from "./RenderTypedSignature";

type Props = Pick<
  DocumentParticipantVm,
  "signatureType" | "signatureTextValue" | "email" | "signatureUrl"
>;

export const ReadOnlySignature: FC<Props> = ({
  signatureType,
  signatureUrl,
  signatureTextValue,
  email,
}) => {
  if (signatureType === "DRAWN") {
    return <RenderDrawnSignature signatureUrl={signatureUrl!} />;
  } else if (signatureType) {
    return (
      <RenderTypedSignature signatureTextValue={signatureTextValue || email!} />
    );
  }

  return null;
};
