import React, { FC } from "react";

interface ClassName {
  className?: string;
}
export const Save: FC<ClassName> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
    >
      <path
        d="M11.6234 2.62342L9.37658 0.37658C9.13546 0.135462 8.80844 1.78306e-06 8.46745 0H1.28571C0.575625 0 0 0.575625 0 1.28571V10.7143C0 11.4244 0.575625 12 1.28571 12H10.7143C11.4244 12 12 11.4244 12 10.7143V3.53255C12 3.19156 11.8645 2.86454 11.6234 2.62342ZM6 10.2857C5.05323 10.2857 4.28571 9.5182 4.28571 8.57143C4.28571 7.62466 5.05323 6.85714 6 6.85714C6.94677 6.85714 7.71429 7.62466 7.71429 8.57143C7.71429 9.5182 6.94677 10.2857 6 10.2857ZM8.57143 2.12893V4.82143C8.57143 4.99894 8.42751 5.14286 8.25 5.14286H2.03571C1.85821 5.14286 1.71429 4.99894 1.71429 4.82143V2.03571C1.71429 1.85821 1.85821 1.71429 2.03571 1.71429H8.15679C8.24204 1.71429 8.3238 1.74814 8.38406 1.80844L8.47728 1.90165C8.50713 1.93149 8.53081 1.96693 8.54696 2.00592C8.56312 2.04492 8.57143 2.08672 8.57143 2.12893Z"
        fill="white"
      />
    </svg>
  );
};
