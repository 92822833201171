const minWidth = (value: string) => `min-width: ${value}`;
const maxWidth = (value: string) => `max-width: ${value}`;

const minDeviceWidth = (value: number) => `(min-device-width: ${value}px)`;
const createMinWidthMQForHook = (value: number) => `(min-width: ${value}px)`;
const createMaxWidthMQForHook = (value: number) => `(max-width: ${value}px)`;

// ? EXAMPLE USE CASE

/**
 * ? Within a styled-component declaration:
 *
 * import mediaQueries from 'themes/mediaQueries'
 *
 * @media(${mediaQueries.iPad}) {
 *  ..styles go here..
 * }
 *
 * ? Within a component, using 'react-responsive' to
 * ? conditionally render elements based on viewport
 *
 * import MediaQuery from "react-responsive";
 * import { devices } from 'themes/mediaQueries'
 *
 * <MediaQuery maxWith={devices.iPad}>
 *  ..component which only renders if this viewport matches..
 * </MediaQuery>
 *
 */

// generics
const maxPhone = 767;
const maxTablet = 834;
const minTablet = 768;
const minDesktop = 835;
const desktop = 835;

// ? computed media queries
const mediaQueries = {
  // generics
  maxPhone: maxWidth(`${maxPhone}px`),
  maxTablet: maxWidth(`${maxTablet}px`),
  minTablet: minWidth(`${minTablet}px`),

  // DESKTOP
  desktop: maxWidth(`${desktop}px`),
  minDesktop: minWidth(`${minDesktop}px`),
};

const devices = {
  desktop,
  maxPhone,
  maxTablet,
  minDesktop,
  minTablet,
};

export { devices, mediaQueries as default };
