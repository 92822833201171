import React from "react";
import { css } from "aphrodite/no-important";
import { useFormikContext } from "formik";

import styles from "./styles";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { Icon } from "shared/src/components/Icon/Icon";
import { FBForm, FBItem } from "shared/src/components/clientAdmin/types";
import { DefensesWidgetSaveVm } from "@rtslabs/field1st-fe-common";
import { QAProps } from "shared/src/qa-slugs";

interface Props extends QAProps {
  item: FBItem<DefensesWidgetSaveVm>;
}

export function DefensesWidget({ qa, item }: Props) {
  const { values } = useFormikContext<FBForm>();
  const s = styles();
  return (
    <div data-testid={qa} className={css(s.container)}>
      <div className={css(s.possibleDefenses)}>
        <span className={css(s.numDefenses)}>{values.defenses?.length}</span>
        <span className={css(s.possibleDefenses)}>POSSIBLE DEFENSES</span>
      </div>
      <div className={css(s.detailsContainer)}>
        <div className={css(s.selectedField)}>
          <Icon type="icon-icons8-checkmark" />
          <span>Selected Field</span>
        </div>
        <span className={css(s.detail)}>
          The text that displays in this section is the related defense attached
          to its selected field above.&nbsp; These defenses display in the order
          of their selection on the form and are grouped together here to&nbsp;
          help facilitate the discussion between team members.
        </span>
        <>
          <div className={css(s.triangle)} />
          <div className={css(s.relatedCommentContainer)}>
            <TextInput
              assistiveLink={{
                label: "Voice to Text",
                onClick: () => undefined,
              }}
              className={css(s.textField)}
              label="Related Comment"
              labelClass={css(s.relatedCommentLabel)}
              placeholder="Add Comment"
              rounded
            />
          </div>
        </>
      </div>
    </div>
  );
}
