import * as React from "react";

import { joinClassNames } from "../../../helpers/theme.helpers";
import { pluralize } from "../../../helpers/utils";

import styles from "./PercentChart.module.scss";

export type ChartData = {
  id: string;
  value: number;
  label: string;
};

interface PercentChartProps {
  data: ChartData[];
  total: number;
  className?: string;
}

const PercentChart = ({ data, total, className }: PercentChartProps) => {
  const percentOfTotal = (num: number) => Math.round((num / total) * 100);

  return (
    <div className={joinClassNames(className, styles.percentChartWrapper)}>
      {data.map((r) => (
        <div className={styles.percentChartRow} key={r.label}>
          <div className={styles.percentChartRowValue}>
            {percentOfTotal(r.value)}%
          </div>

          <div className={styles.percentChartBar}>
            <div
              className={joinClassNames(
                styles.percentChartBarFill,
                percentOfTotal(r.value) === 100 &&
                  styles.percentChartBarFillFull
              )}
              style={{ width: `${percentOfTotal(r.value)}%` }}
            />

            <div className={styles.percentChartBarLabel}>{r.label}</div>

            <div className={styles.percentChartBarValue}>
              {pluralize("Response", r.value, true)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PercentChart;
