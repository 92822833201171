import React from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { ElementType, QAProps } from "../../../qa-slugs";
import { Button } from "../../Button/Button";
import styles from "./ButtonRow.module.scss";

interface Props extends QAProps {
  loading?: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
}

export const ButtonRow = ({ onSubmit, loading, onCancel, qa }: Props) => {
  return (
    <div className={styles.buttonRow}>
      <Button
        qa={qa ? `${qa}-${ElementType.Button}-save` : undefined}
        onClick={onSubmit}
        loading={loading}
        disabled={loading}
        className={styles.saveButton}
      >
        <span className={styles.label}>SAVE CHANGES</span>
      </Button>
      <button
        data-testid={qa ? `${qa}-cancel-${ElementType.Button}` : undefined}
        onClick={onCancel}
        className={styles.cancelButton}
        type="button"
      >
        <span className={joinClassNames(styles.label, styles.label_cancel)}>
          CANCEL
        </span>
      </button>
    </div>
  );
};
