import React, { useState } from "react";
import styles from "./CircularMenu.module.scss";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { useTheme } from "styled-components";
import { HazardIcon } from "../../../../assets/svg/HazardIcon";
import { Add } from "../../../../assets/svg/Add";
import { Icon } from "../../../Icon/Icon";
import { HazardComponent } from "../../../HazardAnalysis/HazardComponent";

interface CircularMenuProps {
  disabled?: boolean;
  onNewDoc: () => void;
  onChat: () => void;
}

export const CircularMenu = React.forwardRef<
  HTMLButtonElement,
  CircularMenuProps
>(({ disabled, onNewDoc, onChat }, ref) => {
  const [isActive, setIsActive] = useState(false);
  const theme = useTheme();

  const BottomNavComponent = () => (
    <div
      className={joinClassNames(styles.pie, styles.hazard)}
      onClick={() => {
        setIsActive(false);
      }}
    >
      <div className={styles.iconText}>
        <HazardIcon className={styles.hazardIcon} /> <span>Hazard</span>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={joinClassNames(
          styles.circularMenuContainer,
          isActive && styles.active
        )}
      >
        <HazardComponent bottomNavComponent={<BottomNavComponent />} />

        <div
          className={joinClassNames(styles.pie, styles.newDoc)}
          onClick={() => {
            setIsActive(false);
            onNewDoc();
          }}
        >
          <div className={styles.iconText}>
            <Add className={styles.newDocIcon} />
            <span>New Doc</span>
          </div>
        </div>
        <div
          className={joinClassNames(styles.pie, styles.chat)}
          onClick={() => {
            setIsActive(false);
            onChat();
          }}
        >
          <div className={styles.iconText}>
            <Icon type="ai-bot" size={24} />
            <span>Chat</span>
          </div>
        </div>
      </div>
      <div className={styles.outerContainer}>
        <button
          className={joinClassNames(
            styles.circularMenuBtn,
            isActive && styles.active
          )}
          ref={ref}
          onClick={() => setIsActive(!isActive)}
          disabled={disabled}
        >
          <span className={styles.plus}>+</span>
        </button>
      </div>
    </>
  );
});
