import React, { ReactNode } from "react";
import styles from "./DrawerContentWrapper.module.scss";
interface Props {
  children: ReactNode;
}

const DrawerContentWrapper = ({ children }: Props) => {
  return <div className={styles.wrapper}>{children}</div>;
};

export default DrawerContentWrapper;
