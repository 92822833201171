import React, { FC, useEffect, useRef, useState } from "react";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import { joinClassNames } from "../../../../../shared/src/helpers/theme.helpers";
import { QAProps } from "../../../../../shared/src/qa-slugs";
import { LocationMarker } from "shared/src/api/geolocationAPI";
import { selectIsLoadingConfig } from "../../../store/arcGis/arcGisSelectors";
import { useAppSelector } from "../../../store/hooks";
import styles from "./ArcGisMap.module.scss";
import {
  ArcGisMapContext,
  EsriViewport,
  loadMap,
  Marker,
} from "./esriMapUtilities";

interface Props extends QAProps {
  className?: string;
  disableNavigation?: boolean;
  userPositionViewport: EsriViewport;
  markers?: LocationMarker[];
  nearestFeature?: __esri.Graphic;
  onMapViewReady?: (mapView: __esri.MapView) => void;
}

const mapMarkers = (markers: LocationMarker[] | undefined) =>
  markers
    ? markers.filter((marker): marker is Marker => !!marker.geolocation)
    : [];

// @TODO: CORE-1573
// This could be on another account that is closed. We should implement it in client config
const testPortalId = "03b5af27b3734442b3823f7a0b4228cb";

export const ArcGisMap: FC<Props> = ({
  className,
  disableNavigation,
  userPositionViewport,
  markers: markersProp,
  nearestFeature,
  onMapViewReady,
  qa,
}) => {
  const apiKey = useAppSelector(
    (state) => state.appConfigs.arcGisConfig?.properties.apiKey
  );
  const [hasError, setHasError] = useState(false);
  const isLoadingConfig = useAppSelector(selectIsLoadingConfig);
  const mapDiv = useRef<HTMLDivElement>(null);
  const mapContext = useRef<ArcGisMapContext | null>(null);
  const [markers, setMarkers] = useState<Marker[]>(mapMarkers(markersProp));

  useEffect(() => {
    if (isLoadingConfig) {
      return;
    }
    if (!apiKey) {
      setHasError(true);
      return;
    }

    const load = async () => {
      try {
        const result = await loadMap({
          disableNavigation,
          mapElement: mapDiv.current ?? undefined,
          markers,
          portalId: testPortalId,
          viewport: userPositionViewport,
          nearestFeature,
        });
        mapContext.current = result.context;
        onMapViewReady?.(result.mapView);
      } catch (error) {
        console.error("Failed to load the map.", error);
        setHasError(false);
      }
    };

    if (userPositionViewport) load();
  }, [isLoadingConfig, userPositionViewport]);

  useEffect(() => {
    const newMarkers = mapMarkers(markersProp);
    setMarkers(newMarkers);
    mapContext.current?.updateMarkers(newMarkers);
  }, [markersProp]);

  return (
    <div
      data-testid={qa}
      className={joinClassNames(styles.map, className)}
      ref={mapDiv}
    >
      {hasError && (
        <ErrorText>Something went wrong - Map failed to load.</ErrorText>
      )}
    </div>
  );
};
