import React from "react";
import { Field, useFormikContext } from "formik";
import { get } from "lodash";

import Checkbox from "../../../../../Checkbox/Checkbox";

import ps from "../styles.module.scss";
import { FBForm, Property } from "../../../../../clientAdmin/types";
import {
  RatingSelection,
  ratingSelections,
} from "../../content/widgets/RatingsWidget/RatingsWidget";
import { joinClassNames } from "../../../../../../helpers/theme.helpers";

interface Props {
  property: Property;
  itemPath: string;
}

export const CommentsRequiredFor = ({ property, itemPath }: Props) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const requiredFor = get(
    values,
    `${itemPath}.${property.name}`,
    [] as string[]
  );

  function onChange(checked: boolean, sel: RatingSelection) {
    const val = checked
      ? [...requiredFor, sel.title]
      : requiredFor.filter((reqF: string) => reqF !== sel.title);
    setFieldValue(`${itemPath}.${property.name}`, val);
  }

  return (
    <div className={ps.requiredForContainer}>
      <p className={ps.requiredForLabel}>{property.label}</p>
      {ratingSelections.map((sel) => (
        <div key={sel.title}>
          <Field
            as={Checkbox}
            className={joinClassNames(ps.checkbox, ps.noVertPadding)}
            name={`${itemPath}.${property.name}.${sel.title}`}
            label={sel.title}
            checked={requiredFor.includes(sel.title)}
            onChange={(e: any) => onChange(e, sel)}
          />
        </div>
      ))}
    </div>
  );
};
