import React, { ButtonHTMLAttributes, HTMLAttributes } from "react";
import styled from "styled-components";
import s from "./styles.module.scss";

import BaseLoader, {
  LoaderProps as BaseLoaderProps,
} from "../../Loader/Loader";
import { joinClassNames } from "../../../helpers/theme.helpers";

type ButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  loading?: boolean;
  ["data-testid"]?: string;
};

export const Button: React.FC<ButtonType> = ({
  disabled,
  loading,
  className,
  ...otherProps
}) => {
  const classes = [s.button];
  if (disabled) {
    classes.push(s.buttonDisabled);
  }
  if (loading) {
    classes.push(s.buttonLoading);
  }
  return (
    <button className={joinClassNames(...classes, className)} {...otherProps} />
  );
};

export const ButtonText: React.FC<{ children?: React.ReactNode }> = ({
  className,
  children,
  ...otherProps
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={joinClassNames(className, s.buttonText)} {...otherProps}>
    {children}
  </div>
);

type TextButtonType = ButtonType & {
  variant?: "cancel" | "link";
  disabled?: boolean;
};

export const TextButton: React.FC<TextButtonType> = ({
  className,
  variant,
  disabled,
  ...otherProps
}: TextButtonType) => {
  const classes = [s.textButton];
  if (disabled) {
    classes.push(s.textButtonDisabled);
  } else if (variant === "cancel") {
    classes.push(s.textButtonCancel);
  }
  if (variant === "link") {
    classes.push(s.textButtonLink);
  }
  return (
    <Button
      className={joinClassNames(...classes, className)}
      disabled={disabled}
      {...otherProps}
    />
  );
};

export const Loader = styled<React.FC<BaseLoaderProps>>(({ ...otherProps }) => (
  <BaseLoader spinnerProps={{ size: 14 }} {...otherProps} />
))`
  margin-right: 0.6rem;
  padding: 0;
`;
