import React, { FC, useEffect, useState } from "react";
import styles from "shared/src/components/Documents/Documents.module.scss";
import { DocumentsBreadcrumb } from "shared/src/components/Documents/DocumentsBreadcrumb";
import { Components } from "shared/src/qa-slugs";
import { Button } from "shared/src/components/Button/Button";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { Icon } from "shared/src/components/Icon/Icon";
import NewDocumentDrawer from "./Documents/components/NewDocumentDrawer/NewDocumentDrawer";
import {
  API,
  CoreRedux,
  DocumentRequestParameters,
  DocumentSummaryVm,
  PageOfWorkOrderDTO,
  useAPI,
} from "@rtslabs/field1st-fe-common";
import DocumentSidebarDrawer from "./Document/DocumentSidebarDrawer";
import { createNewDocument } from "shared/src/components/Document/DocumentForm/prefills.helpers";
import { useSelector } from "react-redux";
import { DocumentRouteState } from "./Document/Document";
import { useNavigate, useParams } from "react-router-dom";
import { Documents } from "./Documents/Documents";
import { useMergeState } from "shared/src/util/hooks/useMergeState";
import useDebounce from "shared/src/util/hooks/useDebounce";
import { uniqBy } from "lodash";
import { DocumentsFiltersDrawer } from "./Documents/components/DocumentsFiltersDrawer";
import { AppliedFiltersProps } from "./Documents/components/types";

interface DocumentsScreen {}

export const DocumentsScreen: FC = () => {
  const { formType } = useParams<{ formType: string }>();
  const [showNewDocumentDrawer, setShowNewDocumentDrawer] =
    useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentSummaryVm>();
  const [showDocumentDrawer, setShowDocumentDrawer] = useState<boolean>(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFiltersProps>({});

  const initialFilters = {
    formTypeId: Number(formType) || undefined,
    sort: ["submissionDate", "desc"],
    page: 0,
    ownerId: undefined, // this prevents reloading on first owners query
  };

  // current full documents params
  const [filters, mergeFilters] =
    useMergeState<API.GetDocumentsArgs>(initialFilters);

  // set a param and reset the page
  function handleFilterChange(params: DocumentRequestParameters) {
    mergeFilters({ ...params, page: 0 });
  }

  // reset filters
  function handleResetFilter() {
    setAppliedFilters({});
    mergeFilters(initialFilters);
  }

  // debounce query
  const setQuery = useDebounce({
    method: (query?: string) => handleFilterChange({ query }),
  });

  const documentsCall = useAPI(API.getDocuments, filters);

  // infinite scrolling
  const [documents, setDocuments] = useState<DocumentSummaryVm[]>([]);
  useEffect(() => {
    if (documentsCall.data) {
      if (documentsCall.data.first) {
        setDocuments(documentsCall.data.content);
      } else {
        setDocuments((docs) =>
          uniqBy([...docs, ...documentsCall.data!.content], "id")
        );
      }
    }
  }, [documentsCall.data]);

  function handleLoadMore() {
    if (documentsCall.data && !documentsCall.data.last) {
      mergeFilters({ page: (filters.page || 0) + 1 });
    }
  }

  const documentsTerm = useGroupTerm(
    "documents",
    "noun",
    "plural",
    "Documents"
  );
  const documentTerm = useGroupTerm("document", "noun", "singular", "Document");
  const rehuddleTerm = useGroupTerm("refocus", "noun", "singular", "Rehuddle");
  const workOrderTerm = useGroupTerm(
    "workOrder",
    "noun",
    "singular",
    "Work Order"
  );

  const terms = {
    document: {
      plural: documentsTerm,
      singular: documentTerm,
    },
    rehuddle: {
      singular: rehuddleTerm,
    },
    workOrder: {
      singular: workOrderTerm,
    },
  };

  const formTypesCall = useAPI(API.getAllFormTypes);
  const formsCall = useAPI(API.getAllForms, {
    format: API.FormVmFormat.SUMMARY,
  });

  const user = useSelector(CoreRedux.selectUser);
  const prefillAlternates = useSelector(CoreRedux.selectPrefillAlternates);

  const navigate = useNavigate();

  function openDocumentDrawer(selectedDocument: DocumentSummaryVm) {
    if (document) {
      setSelectedDocument(selectedDocument);
      setShowDocumentDrawer(true);
    }
  }

  function openFilterDrawer() {
    setShowFilterDrawer(true);
  }

  async function onCreateDocument(formId: number, workOrderId?: number) {
    const document = await createNewDocument({
      formId,
      owner: user!,
      workOrderId,
      prefillAlternates,
    });
    const state: DocumentRouteState = { document };
    navigate(`/document/${document.id}`, { state });
  }

  async function onSearchWorkOrders(
    workOrderId: string
  ): Promise<PageOfWorkOrderDTO> {
    return await API.getWorkOrders({ partialWorkOrderId: workOrderId });
  }

  const qaBase = Components.MyDocuments;
  const formTypes = formTypesCall.data || [];

  return (
    <>
      {/* START NEW FORM */}
      <NewDocumentDrawer
        isVisible={showNewDocumentDrawer}
        setIsVisible={setShowNewDocumentDrawer}
        formTypes={formTypes}
        onCreateDocument={onCreateDocument}
        onSearchWorkOrders={onSearchWorkOrders}
        formTypesLoading={formTypesCall.isLoading}
        forms={formsCall.data || []}
        formsLoading={formsCall.isLoading}
        terms={terms}
      />

      <div className={styles.mainContainer}>
        <DocumentsBreadcrumb qa={`${qaBase}-${Components.Breadcrumbs}`} />

        <h2 className={styles.documentsTitle}>
          <span
            data-testid={`${qaBase}-DocumentsTitle`}
            className={styles.titleText}
          >
            {documentsTerm}
          </span>
          <span className={styles.newDocumentButtonWrapper}>
            <Button onClick={() => setShowNewDocumentDrawer(true)}>
              <Icon type="add_file" size={12} />
              <span className={styles.newDocumentButton}>
                New {terms.document.singular}
              </span>
            </Button>
          </span>
        </h2>
      </div>

      <Documents
        terms={terms}
        documents={documents}
        onLoadMoreDocuments={handleLoadMore}
        formTypes={formTypes}
        query={filters.query}
        isLoadingSearchInfinite={documentsCall.isLoading}
        isSearchLoading={documentsCall.isLoading && filters.page == 0}
        lastPageSearch={!!documentsCall.data?.last}
        searchDocumentsError={!!documentsCall.error}
        onOpenDocumentDrawer={openDocumentDrawer}
        onOpenNewDocumentDrawer={() => setShowNewDocumentDrawer(true)}
        filters={filters}
        onFilterChange={handleFilterChange}
        onSearch={setQuery}
        onOpenFilterDrawer={openFilterDrawer}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
      />

      <DocumentSidebarDrawer
        selectedDocument={selectedDocument}
        open={showDocumentDrawer}
        onClose={() => setShowDocumentDrawer(false)}
      />

      <DocumentsFiltersDrawer
        open={showFilterDrawer}
        onClose={() => setShowFilterDrawer(false)}
        filters={filters}
        formTypes={formTypes}
        onFilterChange={handleFilterChange}
        onFilterReset={handleResetFilter}
        resultsCount={documentsCall.data?.totalElements ?? 0}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        isLoading={documentsCall.isLoading}
      />
    </>
  );
};
