import { CoreRedux } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import MediaQuery from "react-responsive";
import LogoCondensed from "shared/src/components/LogoCondensed";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { useAppSelector } from "../../../store/hooks";
import { UserMenu } from "../UserMenu/UserMenu";
import styles from "./Header.module.scss";
import { HazardComponent } from "shared/src/components/HazardAnalysis/HazardComponent";

interface HeaderProps {
  breakpointClassName?: string;
}

export const Header: FC<HeaderProps> = ({ breakpointClassName }) => {
  const chatConfig = useAppSelector(CoreRedux.selectChatConfigProps);
  const logo = useAppSelector(CoreRedux.selectCondensedLogoImageProps);

  const hasOpenAi = chatConfig?.provider === "openai" && chatConfig?.token;

  return (
    <>
      <MediaQuery
        minWidth={
          breakpointClassName
            ? breakpointClassName
            : scssVariables.breakpointExtraLarge
        }
      >
        <header className={styles.header}>
          <div>
            <LogoCondensed logo={logo} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            {hasOpenAi && (
              <span style={{ paddingTop: 12 }}>
                <HazardComponent />
              </span>
            )}
            <UserMenu />
          </div>
        </header>
      </MediaQuery>
    </>
  );
};
