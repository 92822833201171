import React, { FC } from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { FormSectionTitle } from "./FormSectionTitle";
import styles from "./documentForm.module.scss";

interface Props {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  hasError?: boolean;
  id?: string;
}

export const FormSection: FC<Props> = ({
  title,
  hasError,
  className,
  children,
  id,
}) => (
  <div
    id={id}
    className={joinClassNames(
      styles.formSection,
      hasError && styles.formSectionError,
      className
    )}
  >
    {title && <FormSectionTitle title={title} />}
    {children}
  </div>
);
