import { useDebouncedValue } from "@rtslabs/field1st-fe-common";
import React, { useEffect, useState } from "react";
import * as QA from "../../../qa-slugs";
import { PILLBOX_SEARCH_DELAY } from "../../../util/debounceDelays";
import Modal from "../../common/Modal/Modal";
import { MoreResultsConfirmation } from "./MoreResultsConfirmation";
import { PillBoxWithSuggestions } from "./PillBoxWithSuggestions";

export interface PillBoxProps<T> extends QA.QAProps {
  label: string;
  labelClass?: string;
  labelField: keyof T;
  placeholder?: string;
  onChange: (newValues: Array<T>) => void;
  onSearch: (search: string) => void;
  required?: boolean;
  suggestions: Array<T>;
  suggestionsLabel: string;
  values: Array<T>;
  error?: string;
  disabled?: boolean;
}

export function PillBox<T>({
  label,
  labelClass,
  labelField,
  placeholder,
  onChange,
  onSearch,
  required,
  suggestions,
  suggestionsLabel,
  values,
  error,
  qa = QA.ElementType.PillBox,
  disabled,
}: PillBoxProps<T>): React.ReactElement {
  const [search, setSearch] = useState<string>("");
  const [viewMoreModalIsVisible, setViewMoreModalIsVisible] =
    useState<boolean>(false);
  const [originalValues, setOriginalValues] = useState<T[]>(values);
  const debouncedSearch = useDebouncedValue<string>(
    search,
    PILLBOX_SEARCH_DELAY
  );

  useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch]);

  function addValue(val: T) {
    onChange([...values, val]);
  }

  function removeValue(val: T) {
    onChange(values.filter((v) => v !== val));
  }

  // Filter out options already in the text area
  const availableSuggestions: T[] = suggestions.filter(
    (s) => !values.find((v) => v[labelField] === s[labelField])
  );

  return (
    <>
      {/* Displays first 5 suggestions */}
      <PillBoxWithSuggestions<T>
        onChange={(e) => setSearch(e.target.value)}
        onAdd={addValue}
        onRemove={removeValue}
        label={label}
        labelClass={labelClass}
        labelField={labelField}
        placeholder={placeholder}
        required={required}
        suggestions={availableSuggestions.slice(0, 5)}
        totalSuggestions={availableSuggestions.length}
        suggestionsLabel={suggestionsLabel}
        values={values}
        error={error}
        search={search}
        onViewMore={() => {
          setViewMoreModalIsVisible(true);
          setOriginalValues(values);
        }}
        viewMoreModalIsVisible={viewMoreModalIsVisible}
        qa={qa}
        disabled={disabled}
      />

      {/* View More Results Modal */}
      <Modal
        qa={`${qa}-${QA.ElementType.Modal}-viewMore`}
        border="none"
        open={viewMoreModalIsVisible}
        handleClose={() => {
          onChange(originalValues);
          setViewMoreModalIsVisible(false);
        }}
        height="auto"
        width="652px"
        maxHeight="800px"
        padding="24px 23px"
        content={
          <MoreResultsConfirmation
            qaBase={qa}
            component={
              <PillBoxWithSuggestions<T>
                onChange={(e) => setSearch(e.target.value)}
                onAdd={addValue}
                onRemove={removeValue}
                label={label}
                labelField={labelField}
                placeholder={placeholder}
                suggestions={availableSuggestions}
                totalSuggestions={availableSuggestions.length}
                suggestionsLabel={suggestionsLabel}
                values={values}
                error={error}
                search={search}
                viewMoreModalIsVisible={viewMoreModalIsVisible}
                qa={`${qa}-${QA.ElementType.Modal}-viewMore`}
              />
            }
            search={search}
            handleClose={() => {
              onChange(originalValues);
              setViewMoreModalIsVisible(false);
            }}
            handleSubmit={() => {
              onChange(values);
              setViewMoreModalIsVisible(false);
            }}
          />
        }
      />
    </>
  );
}
