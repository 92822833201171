import { FilterParams, useAPI } from "@rtslabs/field1st-fe-common";
import { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { PaginatedResponse } from "@rtslabs/field1st-fe-common/src/types/api/paginatedResponse";
import useDebounce from "./useDebounce";
import { useMergeState } from "./useMergeState";

export interface InfiniteScrollState<T> {
  data: T[];
  isFirstPage: boolean;
  isLastPage?: boolean;
  isLoading: boolean;
  totalElements?: number;
  setQuery: (query?: string) => void;
  refresh: () => void;
  loadMore: () => void;
}

/**
 * Experimental hook to handle small, common API calls with infinite scrolling by adding loading and error state.
 *
 * @param apiCall async api call
 * @param args arguments for API call - changes here trigger more API calls
 * @param onError
 */
export function useInfiniteScrollAPI<T, A extends FilterParams>(
  apiCall: (apiArgs: A) => Promise<PaginatedResponse<T>>,
  args: A,
  onError?: (error?: unknown) => void
): InfiniteScrollState<T> {
  const [localArgs, mergeLocalArgs] = useMergeState<FilterParams>({
    page: 0,
  });

  const setDebouncedQuery = useDebounce({
    method: (s) => {
      mergeLocalArgs({
        page: 0,
        query: s,
      });
    },
    delayAmount: 250,
  });

  const dataCall = useAPI(apiCall, {
    ...args,
    ...localArgs,
  });

  const [data, setData] = useState<T[]>([]);
  useEffect(() => {
    if (dataCall.data) {
      if (dataCall.data.first) {
        setData(dataCall.data.content);
      } else {
        setData((d) => uniqBy([...d, ...dataCall.data!.content], "id"));
      }
    }
  }, [dataCall.data]);

  useEffect(() => {
    if (onError && dataCall.error) {
      onError(dataCall.error);
    }
  }, [dataCall.error]);

  return {
    data,
    isFirstPage: localArgs.page === 0,
    isLastPage: dataCall.data?.last,
    isLoading: dataCall.isLoading,
    totalElements: dataCall.data?.totalElements,
    setQuery: setDebouncedQuery,
    refresh: () => {
      if (localArgs.page === 0) {
        dataCall.refresh();
      } else {
        mergeLocalArgs({ page: 0 });
      }
    },
    loadMore: () => {
      if (dataCall.data && !dataCall.isLoading && !dataCall.data.last) {
        mergeLocalArgs({ page: localArgs.page! + 1 });
      }
    },
  };
}
