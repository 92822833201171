import React, { FC } from "react";
import { DocumentOEVm } from "@rtslabs/field1st-fe-common";

import { createMarkup } from "../../../util/createMarkup";
import Label from "../../Label/Label";
import { ReadOnlyResponse } from "./ReadOnlyResponse";
import styles from "./ReadOnly.module.scss";
import useGroupTerm from "../../../util/hooks/useGroupTerm";

interface Props {
  oes: DocumentOEVm[];
}

export const ReadOnlyOEs: FC<Props> = ({ oes }) => {
  const oesWithDesc = oes.filter((oe) => !!oe.description);

  // Group config terms
  const operationExperiencesTerm = useGroupTerm(
    "operationalExperiences",
    "noun",
    "plural",
    "Operational Experiences"
  );

  if (oesWithDesc.length > 0) {
    return (
      <div>
        <Label className={styles.label}>{operationExperiencesTerm}</Label>

        {oesWithDesc.map((oe) => (
          <div className={styles.section} key={oe.id}>
            <ReadOnlyResponse
              label="Included"
              value={
                <div
                  className={styles.value}
                  dangerouslySetInnerHTML={createMarkup(oe.description)}
                />
              }
            />
          </div>
        ))}
      </div>
    );
  }
  return null;
};
