import React, { FC, useEffect, useState } from "react";
import { DrawSignatureField } from "./DrawSignatureField";
import { DrawnSignature } from "./DrawnSignature";
import { TypeSignatureField } from "./TypeSignatureField";

type Props = {
  allowTypedEmails: boolean;
  drawnSignatureUrl?: string;
  error: boolean;
  label: string;
  participantId: string;
  onAddDrawnSignature: (signatureDataUrl: string) => Promise<void>;
  onClearSignature: () => void;
  onUpdateTypedSignature: (value: string) => void;
  signatureTextValue: string;
};

const Signature: FC<Props> = ({
  allowTypedEmails,
  drawnSignatureUrl,
  error,
  label,
  participantId,
  onAddDrawnSignature,
  onClearSignature,
  onUpdateTypedSignature,
  signatureTextValue,
}: Props) => {
  const [mode, setMode] = useState<"drawn" | "text">("drawn");

  // if there's a typed signature, switch to type mode
  useEffect(() => {
    if (mode !== "text" && !drawnSignatureUrl && signatureTextValue) {
      setMode("text");
    }
  }, [drawnSignatureUrl, signatureTextValue]);

  function switchMode(): void {
    setMode(mode === "text" ? "drawn" : "text");
    onClearSignature();
  }

  /* submitted drawn signature */
  if (drawnSignatureUrl) {
    return (
      <DrawnSignature
        label={label}
        onClearSignature={onClearSignature}
        signatureUrl={drawnSignatureUrl}
      />
    );
  }

  /* text input mode */
  if (mode === "text" && allowTypedEmails) {
    return (
      <TypeSignatureField
        initialValue={signatureTextValue}
        label={label}
        onUpdateSignature={onUpdateTypedSignature}
        participantId={participantId}
        error={error && !signatureTextValue}
        onStartDrawMode={switchMode}
      />
    );
  }

  /* draw mode */
  return (
    <DrawSignatureField
      participantId={participantId}
      handleClearSignature={onClearSignature}
      assistiveLink={
        allowTypedEmails
          ? {
              label: "Type Signature",
              onClick: switchMode,
            }
          : undefined
      }
      label={label}
      onAddSignature={onAddDrawnSignature}
    />
  );
};

export default Signature;
