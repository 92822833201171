import {
  ClientGroupDTO,
  ReportingFormTypeDocumentCountVm,
} from "@rtslabs/field1st-fe-common";
import { SelectOption } from "../Select/Select";

/**
 * Create an array of options for a groups select dropdown
 */
export function generateGroupOptions(
  groups: ClientGroupDTO[] = []
): SelectOption<number>[] {
  return groups.map((group) => ({
    value: group.id,
    label: group.name,
  }));
}

export type GroupStats = {
  [key: string]: {
    name: string;
    id: number;
    total: number;
    color: string;
    percent: number;
  };
};

export type FormTypeStats = {
  totalCount: number;
  groupCounts: GroupStats;
};

/**
 * Aggregate data for a form type
 * @param apiFormTypeStats
 */
export const getStatsForFormType = (
  apiFormTypeStats: ReportingFormTypeDocumentCountVm
): FormTypeStats => {
  // TODO colors from mocks - should be moved to global - JA
  const colors = [
    "#F91919",
    "#00853C",
    "#1165A8",
    "#5E35B1",
    "#EF6C00",
    "#00838F",
    "#AD1457",
    "#795548",
  ];

  const { totalCount } = apiFormTypeStats;

  const groupCounts: any = {};
  for (let i = 0; i < apiFormTypeStats.groupCounts.length; i++) {
    const { group, count } = apiFormTypeStats.groupCounts[i];
    groupCounts[group.name] = {
      name: group.name,
      id: group.id,
      total: count,
      color: colors[i % 8],
      percent: Math.round((count / totalCount) * 100),
    };
  }

  return {
    totalCount,
    groupCounts,
  };
};
