import React, { ReactNode } from "react";
import Tooltip from "../Tooltip/Tooltip";
import { Icon } from "../Icon/Icon";

import styles from "./ActionButton.module.scss";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps } from "../../qa-slugs";

interface ActionButtonProps extends QAProps {
  className?: string;
  id?: string;
  IconComponent?: ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string;
  shouldShowTooltip?: boolean;
  ariaControls?: string;
  ariaExpanded?: boolean;
}

export const ActionButton = ({
  className,
  id,
  IconComponent,
  onClick,
  label,
  shouldShowTooltip = true,
  ariaControls,
  ariaExpanded,
  qa,
}: ActionButtonProps) => {
  return (
    <Tooltip
      className={className}
      describeChild
      title={shouldShowTooltip ? label : ""}
      placement="top"
      arrow={true}
      classes={{
        popper: className,
        tooltip: styles.toolTip,
        arrow: styles.arrow,
      }}
    >
      <div
        className={joinClassNames(
          styles.actionButtonWrapper,
          !shouldShowTooltip && className
        )}
      >
        <button
          aria-controls={ariaControls}
          aria-expanded={ariaExpanded}
          aria-label={label}
          className={styles.actionButton}
          data-testid={qa}
          id={id}
          onClick={onClick}
          type="button"
        >
          {IconComponent || (
            <Icon type="more_vert" className={styles.moreVertIcon} />
          )}
        </button>
      </div>
    </Tooltip>
  );
};
