import React from "react";
import { ViewState } from "react-map-gl";
import styles from "./GlMap.module.scss";
import satelliteSrc from "./satellitemap.png";
import { Viewport } from "./types";

interface Props {
  viewport: Viewport;
  handleSetViewport: (viewport: ViewState) => void;
  toggleTileStyle: () => void;
}

/** Custom ZoomControl component - replacement for react-map-gl NavigationControl */
export default function ZoomControl({
  viewport,
  handleSetViewport,
  toggleTileStyle,
}: Props) {
  const onZoomIn = () => {
    if (viewport) {
      handleSetViewport({
        latitude: viewport.center.latitude,
        longitude: viewport.center.longitude,
        zoom: viewport.zoom + 1,
      });
    }
  };

  const onZoomOut = () => {
    if (viewport) {
      handleSetViewport({
        latitude: viewport.center.latitude,
        longitude: viewport.center.longitude,
        zoom: viewport.zoom - 1,
      });
    }
  };

  return (
    <div className={styles.zoomContainer}>
      <button
        className={styles.controlButton}
        type="button"
        onClick={(event) => {
          event.preventDefault();
          toggleTileStyle();
        }}
      >
        {/* @TODO integrate an actual satellite map tile here */}
        <img
          className={styles.satelliteImg}
          src={satelliteSrc}
          alt="satellite"
        />
      </button>
      <button
        className={styles.controlButton}
        type="button"
        onClick={(event) => {
          event.preventDefault();
          onZoomOut();
        }}
      >
        <i className="icon-icons8-minus2" />
      </button>
      <button
        className={styles.controlButton}
        type="button"
        onClick={(event) => {
          event.preventDefault();
          onZoomIn();
        }}
      >
        <i className="icon-icons8-plus_math" />
      </button>
    </div>
  );
}
