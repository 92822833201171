import { useEffect, useMemo, useCallback } from "react";

import { debounce, DebounceSettings } from "lodash";
import { DEFAULT_USE_DEBOUNCE_DELAY } from "../debounceDelays";

interface Props<T extends (...args: any[]) => any> {
  method: T;
  delayAmount?: number;
  options?: DebounceSettings;
}

/**
 * Hook which helps to debounce and cancel the given method
 */
function useDebounce<T extends (...args: any[]) => any>({
  method,
  delayAmount = DEFAULT_USE_DEBOUNCE_DELAY,
  options,
}: Props<T>) {
  const methodToDebounce = useMemo(
    () => debounce(method, delayAmount, options),
    []
  );

  useEffect(() => {
    return () => {
      methodToDebounce.cancel();
    };
  }, []);

  return useCallback(
    (...args: Parameters<T>) => {
      methodToDebounce.cancel();

      methodToDebounce(...args);
    },
    [methodToDebounce]
  );
}

export default useDebounce;
