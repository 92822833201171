import { useFormikContext } from "formik";
import React, { useState } from "react";

import {
  API,
  DefenseDTO,
  QuestionSelectionsDTO,
  QuestionSelectionsSaveVm,
} from "@rtslabs/field1st-fe-common";
import { TextInputProps } from "../../../../../../TextInput/types";
import { TextButton } from "../../../../../../common/Button";
import { FBForm } from "../../../../../types";
import CustomAnswerOptionsDrawer from "../../drawers/CustomAnswerOptionsDrawer/CustomAnswerOptionsDrawer";
import { DefenseType } from "../../drawers/CustomAnswerOptionsDrawer/customAnswerOptionsFormUtils";
import ps from "../../styles.module.scss";
import { CustomerAnswerOptionsList } from "./CustomAnswerOptionsList";
import {
  addDefenseToQuestionSelection,
  removeDefenseFromQuestionSelection,
} from "./helpers";

/**
 * Add or remove a defense to/from a question selection value
 * @param options current option values
 * @param optionId option to update
 * @param defenseId defense to add/remove from the option
 * @param remove optional - should remove or not
 */
function updateOptionDefenses(
  options: QuestionSelectionsSaveVm[],
  optionId: number,
  defenseId: number,
  remove?: boolean
): QuestionSelectionsSaveVm[] {
  return options.map((option) => {
    if (option.id === optionId) {
      // we only allow 1 defense per option right now, so just replace it
      const defenseIds = remove ? [] : [defenseId];
      return { ...option, defenseIds };
    }
    return option;
  });
}

interface Props extends TextInputProps {
  itemSubType: string;
  name: string;
  onUpdate: (
    values: QuestionSelectionsSaveVm[]
  ) => Promise<QuestionSelectionsDTO[]>;
  selectedOptions: QuestionSelectionsDTO[];
}

const emptySelection: QuestionSelectionsDTO = {
  id: 0,
  rootId: 0,
  defenseIds: [],
  title: "",
  tags: [],
};

const CustomAnswerOptions: React.FC<Props> = ({
  itemSubType,
  selectedOptions,
  name,
  onUpdate,
}: Props) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [activeItem, setActiveItem] =
    useState<QuestionSelectionsDTO>(emptySelection);

  const { setFieldValue } = useFormikContext<FBForm>();

  /**
   * Add a defense to a selection by adding the selection ID to the defense's questionSelectionIds
   * @param optionId option to add to the defense
   * @param defense defense to update
   * @param options current option values
   */
  async function onAddDefense(
    optionId: number,
    defense: DefenseDTO,
    options: QuestionSelectionsSaveVm[]
  ): Promise<void> {
    // add the question selection to the defense
    await addDefenseToQuestionSelection(defense, optionId);
    // update the question selection defenseIds in the form values
    const finalOptions = updateOptionDefenses(options, optionId, defense.id);
    setFieldValue(name, finalOptions);
    onUpdate(finalOptions);
  }

  /**
   * Remove a defense from a selection by removing the selection from the defense's questionSelections
   * @param optionId option from which to remove the defense
   * @param defense defense to update
   * @param options current option values
   */
  async function removeDefense(
    optionId: number,
    defense: DefenseDTO,
    options: QuestionSelectionsSaveVm[]
  ): Promise<void> {
    // remove the question selection from the defense
    await removeDefenseFromQuestionSelection(defense, optionId);
    // update the question selection defenseIds in the form values
    const finalOptions = updateOptionDefenses(
      options,
      optionId,
      defense.id,
      true
    );
    setFieldValue(name, finalOptions);
    onUpdate(finalOptions);
  }

  async function updateDefenses(
    option: QuestionSelectionsSaveVm,
    defense: DefenseDTO,
    updatedOptions: QuestionSelectionsDTO[]
  ): Promise<void> {
    const addedDefense = option.defenseIds!.includes(defense.id)
      ? null
      : defense;
    const defenseOption = option.id
      ? (option as QuestionSelectionsDTO)
      : updatedOptions[updatedOptions.length - 1];
    if (addedDefense && defenseOption) {
      // remove any existing defenses before adding the new one
      // we're only allowed to have 1 defense per selection, but
      // some old forms and Form Builder bugs have more than 1 and
      // defenseIds is an array, so cycling through just in case
      if (option.defenseIds!.length > 0) {
        option.defenseIds!.forEach(async (dId) => {
          const res = await API.getDefense({ id: dId });
          removeDefenseFromQuestionSelection(res, defenseOption.id);
        });
      }
      await onAddDefense(defenseOption.id, defense, updatedOptions);
    }
  }

  async function onSaveOption(
    option: QuestionSelectionsSaveVm,
    selectedDefenseType: DefenseType,
    previousDefense?: DefenseDTO,
    newDefense?: DefenseDTO
  ): Promise<void> {
    let options: QuestionSelectionsSaveVm[];
    // if the option already exists, replace it
    if (option.id) {
      options = selectedOptions.map((sOption) => {
        if (sOption.id === option.id) return option;
        return sOption;
      });
    } else {
      // if the option is new, add it to the array
      options = [...selectedOptions, option];
    }
    if (
      option.id &&
      previousDefense &&
      selectedDefenseType === DefenseType.None
    ) {
      await removeDefense(option.id, previousDefense, options);
    }
    // PUT the options to the form
    const updatedOptions = await onUpdate(options);

    // Once the option has been added to the form, add/update the defense
    // await so that onSave doesn't complete until the defenses have been updated
    if (newDefense) {
      await updateDefenses(option, newDefense, updatedOptions);
    }
  }

  return (
    <>
      <CustomerAnswerOptionsList
        items={selectedOptions}
        onRemoveItem={(id) => {
          const finalOptions = selectedOptions.filter((i) => i.id !== id);
          setFieldValue(name, finalOptions);
        }}
        onClickItem={(id) => {
          const item = selectedOptions.find((i) => i.id === id);
          if (item) {
            setActiveItem(item);
            setShowDrawer(true);
          }
        }}
        onMoveItem={(id, updatedIndex) => {
          const finalOptions = [...selectedOptions];
          const currentIndex = finalOptions.findIndex((i) => i.id === id);
          if (currentIndex >= 0) {
            const option = finalOptions.splice(currentIndex, 1)[0];
            finalOptions.splice(updatedIndex, 0, option);
            setFieldValue(name, finalOptions);
          }
        }}
      />
      <CustomAnswerOptionsDrawer
        itemSubType={itemSubType}
        initialValues={activeItem}
        onSaveOption={onSaveOption}
        show={showDrawer}
        closeDrawer={() => {
          setShowDrawer(false);
          setActiveItem(emptySelection);
        }}
      />
      <TextButton className={ps.textButton} onClick={() => setShowDrawer(true)}>
        + Add Option
      </TextButton>
    </>
  );
};

export default CustomAnswerOptions;
