import { FormWorkflowType } from "@rtslabs/field1st-fe-common";
import { SelectOption } from "../../Select/Select";

const deactivatedStatuses: FormWorkflowType[] = [
  "DEPRECATED",
  "DEACTIVATED",
  "DEACTIVATED_DRAFT",
  "DEACTIVATED_FINAL",
  "DELETED",
  "DISCARDED_DRAFT",
];

interface statusSelection extends SelectOption<number> {
  workflowTypes: FormWorkflowType[];
}

export const statusOptions: statusSelection[] = [
  { value: 0, label: "All Active Statuses", workflowTypes: ["FINAL", "DRAFT"] },
  { value: 1, label: "FINAL", workflowTypes: ["FINAL"] },
  { value: 2, label: "DRAFT", workflowTypes: ["DRAFT"] },
  { value: 3, label: "DEACTIVATED", workflowTypes: deactivatedStatuses },
];

export const getWorkflowTypes = (statusIndex: number): FormWorkflowType[] => {
  return statusOptions.find((ss) => ss.value === statusIndex)?.workflowTypes!;
};
