import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withTheme from "@mui/styles/withTheme";

import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import Loader from "shared/src/components/Loader/Loader";
import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import { downloadFile } from "../../../util";
import { Button } from "shared/src/components/Button/Button";
import { TextButton } from "shared/src/components/common/Button";
import s from "./styles.module.scss";
import { RouteParams } from "./types";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import { API, DataSource } from "@rtslabs/field1st-fe-common";
import { dataSetWriteRoles } from "../../../routes/constants/permissionSets";

const nonEmptyRow = (row: string[]) => !(row.length === 1 && row[0] === "");

/** Table showing first ten records of an uploaded data source */
const SummaryTable = ({ csvData }: { csvData: string[][] }) =>
  csvData.length > 0 ? (
    <div className={s.tableWrapper}>
      <div>
        {csvData.map(
          (row, rowIdx) =>
            nonEmptyRow(row) &&
            (rowIdx === 0 ? (
              <div className={s.rowDiv} key={`summary_row_${rowIdx}`}>
                {row.map((data) => (
                  <div className={s.th} key={`row${rowIdx}data${data}`}>
                    <span className={s.smallTitle}>{data.toUpperCase()}</span>
                  </div>
                ))}
              </div>
            ) : (
              <div className={s.dataRow} key={`summary_row_${rowIdx}`}>
                {csvData[0].map((d, i) => (
                  <>
                    <div className={s.td} key={`row${rowIdx}data${i}`}>
                      {row[i] || ""}
                    </div>
                  </>
                ))}
              </div>
            ))
        )}
      </div>
    </div>
  ) : null;

/** View an Uploaded Data Set */
function ViewUploadedDS() {
  const params = useParams<RouteParams>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DataSource | null>(null);
  const [fileSize, setFileSize] = useState<number>(0);
  const [dataSourceValues, setDataSourceValues] = useState<string[][]>([]);
  const [numRecords, setNumRecords] = useState<number>(0);
  const readOnly = !API.Environment.hasRoleAccess(dataSetWriteRoles);

  const downloadCSV = async (id: number, title: string): Promise<void> => {
    try {
      const csv = await API.downloadDataSourceValues({
        dataSourceId: id,
        mediaType: "text/csv",
      });
      downloadFile(csv.blob, csv.fileName);
    } catch (err) {
      // TODO error handling -JA
      console.error(err);
    }
  };

  /** Get data source and CSV if an ID was supplied in params */
  useEffect(() => {
    (async () => {
      const id = Number(params.id);
      if (!isNaN(id)) {
        try {
          setLoading(true);
          const ds = await API.getDataSourceById({ id });
          setDataSource(ds);
          if (ds.id) {
            const dsv = await API.downloadDataSourceValues({
              dataSourceId: ds.id,
              mediaType: "text/csv",
            });
            setFileSize(dsv.blob.size);
            // @ts-ignore TypeScript doesn't see text() property on Blob
            const dsvtxt = await dsv.blob.text();
            const dsvrows = dsvtxt.split("\n");
            setNumRecords(dsvrows.length - 1);
            setDataSourceValues(
              dsvrows.splice(0, 11).map((row) => row.split(","))
            );
          }
        } catch (err) {
          console.error(err);
          // TODO error handling -JA
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  return (
    <ContentWrapper>
      <Breadcrumbs
        paths={[
          { pathName: "Forms" },
          { pathName: "Data Sets", href: "/forms/data-sets" },
          {
            pathName: `Uploaded Data Set${
              dataSource ? `: ${dataSource.title}` : ""
            }`,
          },
        ]}
      />
      <Loader loading={loading}>
        {dataSource && (
          <>
            <div className={s.metric}>
              <span className={s.smallTitle}>DATA SET TITLE</span>
              <span
                className={s.title}
                data-testid={`${Page.Desktop}-${Components.ViewUploadedDS}`}
              >
                {dataSource.title}
              </span>
            </div>
            <div className={s.rowDiv}>
              <div className={s.metricColumn}>
                <div className={s.metric}>
                  <span className={s.smallTitle}>ID</span>
                  <span className={s.dataItem}>{dataSource.id}</span>
                </div>
                <div className={s.metric}>
                  <span className={s.smallTitle}>SOURCE KEY</span>
                  <span className={s.dataItem}>{dataSource.dataSourceKey}</span>
                </div>
              </div>
              <div className={s.metricColumn}>
                <div className={s.metric}>
                  <span className={s.smallTitle}>COLUMN HEADERS</span>
                  {(dataSource.csvMappings || []).map((m) => (
                    <span className={s.dataItem} key={`column_${m.columnName}`}>
                      {m.columnName}
                    </span>
                  ))}
                </div>
              </div>
              <div className={s.metricColumn}>
                <div className={s.metric}>
                  <span className={s.smallTitle}>GROUPS</span>
                  {/* TODO where do groups come from? -JA */}
                </div>
              </div>
              <div className={s.metricColumn}>
                <div className={s.metric}>
                  <span className={s.smallTitle}># OF RECORDS</span>
                  <span className={s.dataItem}>{numRecords}</span>
                </div>
                <div className={s.metric}>
                  <span className={s.smallTitle}>FILE SIZE</span>
                  <span className={s.dataItem}>{fileSize}B</span>
                </div>
              </div>
            </div>
            {/* TODO tabs */}
            <span className={s.previewHeader}>
              Data set preview: {dataSourceValues.length - 1} of {numRecords}{" "}
              records
            </span>
            <span className={s.subtitle}>
              The table below is a preview of the first 10 records in your data
              set. To view the entire spreadsheet, download the current data
              set. You can make changes to the table by selecting to import a
              new data set.
            </span>
            <div className={s.tableButtons}>
              <Button
                className={s.downloadButton}
                qa={`${Components.ViewUploadedDS}-${ElementType.Button}-downloadDataSetTop`}
                loading={false}
                onClick={() =>
                  dataSource?.id &&
                  downloadCSV(dataSource.id, dataSource.title || "")
                }
              >
                DOWNLOAD CURRENT DATA SET
              </Button>
              <TextButton
                className={s.importButton}
                qa={`${Components.ViewUploadedDS}-${ElementType.Button}-importDataSetTop`}
                onClick={() =>
                  dataSource?.id &&
                  navigate(`/forms/data-sets/uploaded/${dataSource.id}`)
                }
                disabled={readOnly}
              >
                IMPORT NEW DATA SET
              </TextButton>
            </div>
            <SummaryTable csvData={dataSourceValues} />
            <div className={s.tableButtons}>
              <Button
                className={s.downloadButton}
                qa={`${Components.ViewUploadedDS}-${ElementType.Button}-downloadDataSetBottom`}
                loading={false}
                onClick={() =>
                  dataSource?.id &&
                  downloadCSV(dataSource.id, dataSource.title || "")
                }
              >
                DOWNLOAD CURRENT DATA SET
              </Button>
              <TextButton
                className={s.importButton}
                qa={`${Components.ViewUploadedDS}-${ElementType.Button}-importDataSetBottom`}
                onClick={() =>
                  dataSource?.id &&
                  navigate(`/forms/data-sets/uploaded/${dataSource.id}`)
                }
                disabled={readOnly}
              >
                IMPORT NEW DATA SET
              </TextButton>
            </div>
          </>
        )}
      </Loader>
    </ContentWrapper>
  );
}

export default withTheme(ViewUploadedDS);
