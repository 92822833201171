import React, { useMemo } from "react";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import documentsStyles from "shared/src/components/Documents/Documents.module.scss";
import Loader from "shared/src/components/Loader/Loader";
import { Carousel } from "shared/src/components/Carousel/Carousel";
import Tooltip, {
  lightTooltipClasses,
} from "shared/src/components/Tooltip/Tooltip";
import { Components, ElementType } from "shared/src/qa-slugs";
import { displayWhen, prettifyDocumentStatus } from "../../helpers";
import MapContent from "./MapContent";
import styles from "./RecentDocuments.module.scss";
import { Icon } from "shared/src/components/Icon/Icon";
import { DocumentScreenTerms } from "../../types";
import { DocumentStatus, DocumentSummaryVm } from "@rtslabs/field1st-fe-common";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { FormTypeIcon } from "shared/src/components/Documents/FormTypeIcon";

interface Props {
  displayDocumentsFromSearch?: boolean;
  isSearchLoading?: boolean;
  openContextMenu: (d: DocumentSummaryVm) => void;
  query?: string | null;
  searchDocumentsAPIError?: boolean;
  documents: DocumentSummaryVm[];
  terms: DocumentScreenTerms;
}

const RecentDocuments = ({
  searchDocumentsAPIError,
  isSearchLoading,
  openContextMenu,
  documents,
  terms: {
    document: { plural: documentsTerm },
  },
}: Props) => {
  const navigate = useNavigate();
  const recentDocuments = useMemo(
    () => documents.filter((x) => moment().diff(x.submissionDate, "h") <= 24),
    [documents]
  );

  if (searchDocumentsAPIError || recentDocuments?.length === 0) {
    return null;
  }

  return (
    <Loader loading={isSearchLoading}>
      <div className={styles.recentDocuments}>
        <div className={documentsStyles.documentsListHeader}>
          Recent {documentsTerm} (24 hours)
        </div>
        <Carousel>
          {recentDocuments.map((document) => {
            const location = get(document, "summary.location", null);
            const workOrderId = get(document, "workOrder.workOrderId", "-");
            const subHeader = `ID: ${workOrderId}`;
            const useTitle =
              (document.isRehuddle ? "[REHUDDLE] " : "") + document.title;

            return (
              <Tooltip
                classes={lightTooltipClasses}
                key={document.id}
                title={useTitle}
                placement="top"
              >
                <div>
                  <div
                    className={joinClassNames(
                      styles.card,
                      document.status === DocumentStatus.NEW && styles.new,
                      document.status === DocumentStatus.IN_PROGRESS &&
                        styles.inProgress,
                      document.status === DocumentStatus.SUBMITTED &&
                        styles.submitted
                    )}
                    data-tag-qa={`${Components.RecentDocuments}-${ElementType.Card}-documentCard-${document.id}`}
                    onClick={() => navigate(`/document/${document.id}`)}
                  >
                    <div className={styles.cardHeader}>
                      <FormTypeIcon formType={document.formSummary.type} />
                      <span className={styles.statusPill}>
                        {prettifyDocumentStatus(document.status)}
                      </span>
                      <button
                        className={styles.actionMenu}
                        onClick={(e) => {
                          e.stopPropagation();
                          openContextMenu(document);
                        }}
                      >
                        <Icon type="more_vert" />
                      </button>
                    </div>
                    <div className={styles.subtitle}>
                      <span className={styles.id}>{subHeader}</span>
                      <span className={styles.date}>
                        {displayWhen(document.submissionDate)}
                      </span>
                    </div>
                    <div className={styles.title}>{useTitle}</div>
                    <MapContent location={location} />
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </Carousel>
      </div>
    </Loader>
  );
};

export default RecentDocuments;
