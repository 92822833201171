import { LatLng } from "@rtslabs/field1st-fe-common";

const rootURL = "https://nominatim.openstreetmap.org";

/**
 * Get geo coordinates for a given address string. Returns false on error
 * @param address
 */
export async function geocodeWithOpenStreetMap(
  address: string
): Promise<{ geolocation: LatLng; address: string } | false> {
  if (isCoords(address)) {
    const coords = parseGeolocation(address);
    return {
      geolocation: coords,
      address: address,
    };
  }

  const url = `${rootURL}/search.php?q=${address}&format=json`;

  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (json.length) {
        return {
          geolocation: {
            latitude: parseFloat(json[0].lat),
            longitude: parseFloat(json[0].lon),
          },
          address: json[0].display_name,
        };
      }
      return false;
    });
}

/** Convert lat/lng coords into street address */
export async function reverseGeocodeWithOpenStreetMap(
  geolocation: LatLng
): Promise<string> {
  const url = `${rootURL}/reverse?lat=${geolocation.latitude}&lon=${geolocation.longitude}&format=json`;
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((json) => json.display_name);
}

/** Test if string is formatted coordinates **/
function isCoords(string: string) {
  const regMatch = /\s*\(?-?\d+(.\d+)?,\s*-?\d+(.\d+)\)?\s*/;
  return regMatch.test(string);
}

/** Parse the string of format "(<lat>,<lng>)" into numbers **/
function parseGeolocation(str: string): LatLng {
  if (!isCoords(str)) {
    return { latitude: 0, longitude: 0 };
  }
  str = str.replace(/[()\s]/g, "");
  const coords = str.split(",");
  return {
    latitude: parseFloat(coords[0]),
    longitude: parseFloat(coords[1]),
  };
}
