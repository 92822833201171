import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import React, { FC, RefObject } from "react";
import { DateField } from "./DateField";
import styles from "./documentForm.module.scss";
import { DropdownField } from "./DropdownField/DropdownField";
import { LocationField } from "./LocationField/LocationField";
import { MultiSelectField } from "./MultiSelectField";
import { ParticipantField } from "./ParticipantField";
import { QuestionPhotoWrapper } from "./QuestionPhotoWrapper";
import { RadioField } from "./RadioField/RadioField";
import { RatingField } from "./RatingField/RatingField";
import { TextField } from "./TextField/TextField";
import { YesNoField } from "./YesNoField/YesNoField";

interface Props {
  questionRef?: RefObject<HTMLDivElement>;
  question: QuestionDTO;
  appPath?: string;
  disabled?: boolean;
}

export const Question: FC<Props> = ({
  question,
  questionRef,
  appPath,
  disabled,
}) => {
  // Question fields

  /** Render the input based on the question's subType */
  function renderInput() {
    switch (question.subType) {
      case "DATE":
        return <DateField type="date" question={question} />;
      case "DATE_TIME":
        return <DateField type="datetime-local" question={question} />;
      case "TIME":
        return <DateField type="time" question={question} />;

      case "DROP_DOWN":
        return (
          <QuestionPhotoWrapper question={question} appPath={appPath}>
            <DropdownField question={question} appPath={appPath} />
          </QuestionPhotoWrapper>
        );

      case "LOCATION":
        // this only renders location fields that are not attached to a
        // map widget. For location fields within a map widget, see MapWidget.tsx
        return <LocationField question={question} />;

      case "CHECKBOX":
        return (
          <QuestionPhotoWrapper question={question} appPath={appPath}>
            <MultiSelectField question={question} appPath={appPath} />
          </QuestionPhotoWrapper>
        );

      case "MULTI_SELECT":
        return <MultiSelectField question={question} appPath={appPath} />;

      case "PARTICIPANT":
        return <ParticipantField question={question} disabled={disabled} />;

      case "RADIO_BUTTONS":
        return (
          <QuestionPhotoWrapper question={question} appPath={appPath}>
            <RadioField question={question} />
          </QuestionPhotoWrapper>
        );

      case "RATING":
        return <RatingField question={question} appPath={appPath} />;

      case "TEXT_LINE":
      case "TEXT_AREA":
        return (
          <QuestionPhotoWrapper question={question} appPath={appPath}>
            <TextField question={question} disabled={disabled} />
          </QuestionPhotoWrapper>
        );

      case "YES_OR_NO":
        return (
          <QuestionPhotoWrapper question={question} appPath={appPath}>
            <YesNoField question={question} />
          </QuestionPhotoWrapper>
        );

      // currently unhandled and default cases
      case "HYPERLINK":
      case "BARCODE":
      case "DOCUMENT":
      case "PHOTO":
      case "DRAWING":
      case "MAP_DRAW":
      default:
        return null;
    }
  }

  return (
    <div className={styles.formQuestion} ref={questionRef}>
      {renderInput()}
    </div>
  );
};
