import React, { useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";

import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import { Components, ElementType } from "shared/src/qa-slugs";

const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  width: "398px";
`;

const initialFilters = {
  query: "",
};

type Props = {
  onUpdateFilters: (filters: typeof initialFilters) => void;
};

const TagFilters = ({ onUpdateFilters }: Props) => {
  const [filters, setFilters] = useState<typeof initialFilters>(initialFilters);

  const updateFilters = (filter: string, value: string) => {
    const newFilters = {
      ...filters,
      [filter]: value,
    };
    setFilters(newFilters);
    onUpdateFilters(newFilters);
  };

  const handleSearch = debounce(
    (query: string) => updateFilters("query", query),
    500
  );

  return (
    <FiltersContainer>
      <SearchBar
        qa={`${Components.TagFilters}-${ElementType.TextInput}-searchBar`}
        onSearch={handleSearch}
      />
    </FiltersContainer>
  );
};

export default TagFilters;
