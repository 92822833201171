import React from "react";
import styled from "styled-components";

import { toRgba } from "shared/src/themes/helpers";

// Action buttons

type ButtonType = {
  disabled?: boolean;
};

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 2rem 0;
`;

const AddUserButton = styled.button<ButtonType>`
  align-items: center;
  background-color: ${({ disabled, theme }) =>
    disabled ? toRgba(theme.colors.black, 0.12) : theme.colors.primary};
  border-radius: 4px;
  border: none;
  box-shadow: ${({ disabled }) => disabled && "none"};
  color: ${({ disabled, theme }) =>
    disabled ? toRgba(theme.colors.black, 0.26) : theme.colors.white};
  display: flex;
  font-size: 0.875rem !important;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin-right: 1.5rem;
  padding: 8px 35px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;

const CancelButton = styled.div<ButtonType>`
  background-color: none;
  border: none;
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
  display: ${({ disabled }) => disabled && "none"}
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 1.25px;
  padding: 8px 16px;
  text-align: center;
  text-transform: uppercase;
`;

// Group and Role
type GroupAndRoleHeaderItemType = {
  align?: "flex-start" | "flex-end" | "center";
  margin?: string;
  width?: string;
};
type GroupAndRoleCellType = {
  align?: "flex-start" | "flex-end" | "center";
  border?: boolean;
  margin?: string;
  width?: string;
};
type GroupAndRoleCellWrapperType = {
  border?: boolean;
};

type GroupAndRoleWrapperType = {
  hideBorder?: boolean;
  noPaddingTop?: boolean;
};

const GroupAndRoleWrapper = styled.div<GroupAndRoleWrapperType>`
  border-top: ${({ hideBorder, theme }) =>
    (!hideBorder && `1px solid ${theme.colors.lightGrey}`) || ""};
  margin-top: 23px;
  padding-left: 0;
  padding-right: 0;
  padding-top: ${({ noPaddingTop }) => (!noPaddingTop ? "23px" : "")};
`;
const GroupAndRoleHeader = styled.div`
  display: flex;
`;
const GroupAndRoleHeaderItem = styled.div<GroupAndRoleHeaderItemType>`
  align-items: center;
  color: ${({ theme }) => theme.masterColors.darkGrey};
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  justify-content: ${({ align }) => align || "center"};
  letter-spacing: 1.88px;
  margin: ${({ margin }) => margin || ""};
  position: relative;
  text-transform: uppercase;
  width: ${(props) => `${props.width}px`};
`;

const GroupAndRoleCellWrapper = styled.div.attrs({
  className: "col-12 pl-0 pr-0",
})<GroupAndRoleCellWrapperType>`
  align-items: center;
  border-bottom: ${({ border, theme }) =>
    border && `2px solid ${theme.colors.lightGrey}`};
  display: flex;
  padding: 12px 0;
`;
const GroupAndRoleCell = styled.div.attrs({
  className: "ellipsis",
})<GroupAndRoleCellType>`
  color: ${({ theme }) => theme.masterColors.darkGrey};
  display: flex;
  justify-content: ${({ align }) => align || "center"};
  margin: ${({ margin }) => margin || ""};
  width: ${(props) => `${props.width}px`};
`;

const LoaderWrapper = styled.div`
  position: absolute;
  left: 3px;
  top: 11px;
`;

export {
  AddUserButton,
  ButtonsWrapper,
  CancelButton,
  GroupAndRoleCell,
  GroupAndRoleCellWrapper,
  GroupAndRoleHeader,
  GroupAndRoleHeaderItem,
  GroupAndRoleWrapper,
  LoaderWrapper,
};
