import React, { FC } from "react";
import { ClassicLocationMarker } from "../DocumentForm/LocationField/geo";
import { GlMap } from "../../GlMap/GlMap";
import { Viewport } from "../../GlMap/types";
import styles from "./ReadOnly.module.scss";

interface StaticMap {
  viewport: Viewport;
  mapMarkers: ClassicLocationMarker[];
}

export const ReadOnlyMap: FC<StaticMap> = ({ viewport, mapMarkers }) => {
  return (
    <div className={styles.map}>
      <GlMap initialViewport={viewport} markers={mapMarkers} disableControls />
    </div>
  );
};
