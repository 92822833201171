import React from "react";
import { css } from "aphrodite/no-important";

import { TextButton } from "../../../../../../common/Button";
import { Icon } from "../../../../../../Icon/Icon";
import styles from "./styles";

interface ReorderButtonProps {
  onReorder: () => void;
  icon: string;
}

const ReorderButton = ({ onReorder, icon }: ReorderButtonProps) => (
  <TextButton
    className={css(styles().reorderButton)}
    onClick={(e) => {
      e.stopPropagation();
      onReorder();
    }}
  >
    <Icon type={icon} size="12" />
  </TextButton>
);

interface ReorderButtonsProps {
  hide?: {
    top: boolean;
    bottom: boolean;
  };
  onReorder: (diff: number) => void;
}

const ReorderButtons = ({ hide, onReorder }: ReorderButtonsProps) => {
  return (
    <div className={css(styles().buttonsContainer)}>
      {!hide?.top && (
        <ReorderButton onReorder={() => onReorder(-1)} icon="sort_up" />
      )}
      {!hide?.bottom && (
        <ReorderButton onReorder={() => onReorder(1)} icon="sort_down" />
      )}
    </div>
  );
};

export default ReorderButtons;
