import React, { FC } from "react";
import { Props as ReactSelectProps } from "react-select";
import { Select, SelectOption } from "../../Select/Select";
import styles from "./RowsPerPage.module.scss";

interface RowsPerPage {
  pageSize?: number;
  onClick: (size: number) => void;
  qaBase?: string;
  menuPlacement?: ReactSelectProps["menuPlacement"];
}

const initialOptions: SelectOption<number>[] = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
];

export const RowsPerPage: FC<RowsPerPage> = ({
  onClick,
  pageSize,
  qaBase,
  menuPlacement,
}) => {
  let options = [...initialOptions];

  // This is an override view that can go over 100 at client's request. Give them the flexibity
  // but they should be aware that it can cause performance issue if the list goes too long
  const overrideLabel = "---";
  const hasOverrideValue = options.find((o) => o.label === overrideLabel);

  if (pageSize && pageSize > 100 && !hasOverrideValue) {
    options.push({ label: overrideLabel, value: pageSize });
  } else if (!!hasOverrideValue) {
    // reset
    options = initialOptions;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>View: </div>
      <Select
        qa={qaBase && `${qaBase}-dropdown`}
        onChange={(option) => option && onClick(option.value)}
        value={pageSize || 10}
        className={styles.select}
        options={options}
        menuPosition="fixed"
        menuPlacement={menuPlacement || "bottom"}
      />
    </div>
  );
};
