import React, { FC } from "react";
import Loader from "shared/src/components/Loader/Loader";
import { Button, TertiaryButton } from "shared/src/components/Button/Button";
import { LoaderWrapper } from "../../addUser/styles";
import {
  ModalPromptWrapper,
  ModalPromptTitle,
  ModalPromptCloseIcon,
  DisableUserButtonsWrapper,
  DeleteUserWarningLabel,
} from "../styles";

interface DeleteUserConfirmationProps {
  displayName: string;
  loading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const DeleteUserConfirmation: FC<DeleteUserConfirmationProps> = ({
  displayName,
  loading,
  onCancel,
  onSubmit,
}) => (
  <ModalPromptWrapper>
    <ModalPromptTitle>Delete User</ModalPromptTitle>
    <ModalPromptCloseIcon onClick={onCancel} />
    <DeleteUserWarningLabel margin="32.5px 0 0 0">
      <b>Do you wish to permanently remove {displayName}?</b>
      <br />
      This change cannot be undone.
    </DeleteUserWarningLabel>
    <DisableUserButtonsWrapper margin="24px 0 0 0">
      <TertiaryButton onClick={onCancel}>cancel</TertiaryButton>
      <Button onClick={onSubmit}>
        {" "}
        <LoaderWrapper>
          <Loader
            loading={loading}
            className="p-0 mr-3"
            spinnerProps={{ size: 14 }}
          />
        </LoaderWrapper>
        remove user
      </Button>
    </DisableUserButtonsWrapper>
  </ModalPromptWrapper>
);
