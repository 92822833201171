import React, { FC, useEffect, useState } from "react";
import { Button, ButtonProps } from "../Button/Button";
import { AuthRequestStatus } from "./types";

interface Props extends ButtonProps {
  submitStatus?: AuthRequestStatus;
  onComplete: () => void;
}

const AuthAnimatedButton: FC<Props> = ({
  loading,
  submitStatus,
  onComplete,
  ...rest
}) => {
  const [complete, setComplete] = useState<boolean>(false);
  let successTimeout: NodeJS.Timeout;
  let completionTimeout: NodeJS.Timeout;

  useEffect(() => {
    if (submitStatus === "success") {
      successTimeout = setTimeout(() => setComplete(true), 600);
    }
    () => {
      clearTimeout(successTimeout);
    };
  }, [submitStatus]);

  useEffect(() => {
    if (complete) {
      completionTimeout = setTimeout(onComplete, 600);
    }
    () => {
      clearTimeout(completionTimeout);
    };
  }, [complete]);

  return (
    <Button
      type="submit"
      loadingState={
        loading
          ? "Loading"
          : complete
          ? "Completed"
          : submitStatus === "success"
          ? "Checked"
          : "None"
      }
      {...rest}
    />
  );
};

export default AuthAnimatedButton;
