import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import TextFieldBase from "@mui/material/TextField";

import styles from "./TextField.module.scss";
import { useMouseFocusAndBlur } from "../../util/hooks/useMouseFocus";
import { joinClassNames } from "../../helpers/theme.helpers";

interface TextFieldProps extends AutocompleteRenderInputParams {
  hasError?: boolean;
  isExpanded?: boolean;
  placeholder?: string;
  qa?: string;
}

export interface TextFieldRef {
  focus: () => void;
}

export const TextField = forwardRef<TextFieldRef, TextFieldProps>(
  (
    {
      disabled,
      hasError,
      InputProps,
      isExpanded,
      placeholder,
      qa,
      ...otherProps
    },
    ref
  ) => {
    const { eventHandlers, isFocused, isMouseFocused, wasMouseBlurred } =
      useMouseFocusAndBlur();
    const input = useRef<any>();

    useImperativeHandle(ref, () => ({
      focus: () => {
        input.current?.focus();
      },
    }));

    return (
      <TextFieldBase
        data-testid={qa}
        InputProps={{
          ...InputProps,
          classes: {
            input: joinClassNames(
              styles.input,
              isExpanded ? styles.inputExpanded : styles.inputNotExpanded
            ),
            notchedOutline: joinClassNames(
              styles.notchedOutline,
              disabled && styles.notchedOutlineDisabled,
              hasError && styles.notchedOutlineError,
              isFocused &&
                !isMouseFocused &&
                styles.notchedOutlineKeyboardFocused,
              isFocused && isMouseFocused && styles.notchedOutlineMouseFocused,
              wasMouseBlurred && styles.notchedOutlineMouseBlurred
            ),
            root: joinClassNames(
              styles.root,
              isExpanded ? styles.rootExpanded : styles.rootNotExpanded,
              disabled && styles.rootDisabled,
              hasError && styles.rootError
            ),
          },
        }}
        inputRef={input}
        placeholder={placeholder}
        {...eventHandlers}
        {...otherProps}
        variant="outlined"
      />
    );
  }
);
