import { ResponseContent } from "@rtslabs/field1st-fe-common";
import React from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { ElementType, QAProps } from "../../qa-slugs";
import { CheckboxLabel } from "../Document/DocumentForm/CheckboxField/CheckboxLabel";
import Label from "../Label/Label";
import { RadioButton, RadioButtonProps } from "./RadioButton";
import styles from "./RadioButtonsGroup.module.scss";

export interface RadioButtonOption<
  T extends number | boolean | string | undefined | null
> {
  disabled?: boolean;
  label: {
    icon?: {
      name: string;
      color: string;
    };
    text: string;
  };
  hideLabel?: boolean;
  /**
   * `data` Could be the:
   *   - id of a QuestionSelectionsDTO,
   *   - id of a DataSourceValueDTO,
   *   - true/false for yes/no questions
   *   - undefined/null only for new questions in FormBuilder
   *   - string for SettingsForm, CustomAnswerOptionsDrawer
   */
  data: T;
  content?: ResponseContent | null;
  rootId?: number | null;
}

export type RadioButtonsGroupProps<
  T extends number | boolean | string | undefined | null
> = {
  RadioButtonComponent?: React.ComponentType<RadioButtonProps<T>>;
  className?: string;
  name: string;
  onChange: (data: T) => void;
  options: RadioButtonOption<T>[];
  required?: boolean;
  row?: boolean;
  value: string;
  label?: string;
  error?: string;
} & QAProps;

export function RadioButtonsGroup<
  T extends number | boolean | string | undefined | null
>({
  className,
  label,
  name,
  onChange,
  options,
  RadioButtonComponent = RadioButton,
  row,
  value,
  required,
  qa,
  error,
}: RadioButtonsGroupProps<T>) {
  return (
    <div className={className}>
      {label && (
        <Label htmlFor={name} required={required} hasError={!!error}>
          {label}
        </Label>
      )}
      <div className={styles.container}>
        <div
          aria-labelledby={name}
          className={joinClassNames(
            styles.radioGroup,
            row ? styles.row : styles.column
          )}
          id={name}
          role="radiogroup"
          data-testid={qa ? `${qa}-${ElementType.RadioGroup}` : undefined}
        >
          {options?.map((option) => (
            <RadioButtonComponent
              checked={
                value?.toLowerCase() ===
                  option.rootId?.toString().toLowerCase() ||
                value?.toLowerCase() === option.data?.toString().toLowerCase()
              }
              className={styles.item}
              disabled={option.disabled}
              onChange={onChange}
              key={
                option.data
                  ? option.data.toString()
                  : option.label.icon?.name ??
                    "icon" + option.label.text ??
                    "text"
              }
              label={
                <CheckboxLabel
                  icon={option.label.icon}
                  text={(!option.hideLabel && option.label.text) || ""}
                  qa={qa}
                />
              }
              name={`${name}_${option.data}`}
              data={option.data}
              qa={qa}
            />
          ))}
        </div>
      </div>
      {/* {error && <span className={styles.errorText}>{error}</span>} */}
    </div>
  );
}
