import React from "react";
import { get } from "lodash";
import WorkOrderListItem from "./WorkOrderListItem";
import { WorkOrderDTO } from "@rtslabs/field1st-fe-common";
import { Components, ElementType } from "shared/src/qa-slugs";

export interface WorkOrderProps extends WorkOrderDTO {
  iconUrl?: string;
  iconColor?: string;
  isActive?: boolean;
  handleSelectWorkOrder: (value: number) => void;
}

export interface RenderWorkOrderProps {
  workOrders?: Array<WorkOrderDTO>;
  mockWorkOrders?: Array<WorkOrderDTO>;
  handleSelectWorkOrderId: (workOrderId?: number) => void;
  selectedWorkOrderId?: number;
}

interface RenderNoResultsMessageProps {
  workOrders?: Array<WorkOrderDTO>;
}
const RenderNoResultsMessage = ({
  workOrders,
}: RenderNoResultsMessageProps) => {
  if (workOrders?.length === 0) {
    return <WorkOrderListItem description="No work orders found" />;
  }
  return null;
};

/**  Used to render the "Work Orders" returned from the API */
const RenderWorkOrders = ({
  workOrders,
  selectedWorkOrderId,
  handleSelectWorkOrderId,
}: RenderWorkOrderProps) => {
  return (
    <>
      <RenderNoResultsMessage workOrders={workOrders} />
      {workOrders?.map((workOrder) => (
        <WorkOrderListItem
          qa={`${Components.WorkOrderListItem}-${ElementType.Link}`}
          key={workOrder.id}
          {...workOrder}
          workLocation={get(workOrder, "workLocation.name")}
          isActive={selectedWorkOrderId === workOrder.id}
          handleSelectWorkOrder={handleSelectWorkOrderId}
        />
      ))}
    </>
  );
};

RenderWorkOrders.defaultProps = {};

export default RenderWorkOrders;
