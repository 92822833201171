import React, { FC } from "react";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { useUserInfo } from "../useUserInfo";
import styles from "./UserProfileInfo.module.scss";

interface UserProfileInfoProps {
  className?: string;
}

export const UserProfileInfo: FC<UserProfileInfoProps> = ({ className }) => {
  const userInfo = useUserInfo();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div className={joinClassNames(styles.header, className)}>
        <div className={styles.initials}>{userInfo.initials}</div>
        <div className={styles.headerContent}>
          <div className={styles.fullName}>
            {`${userInfo.firstName} ${userInfo.lastName}`}
          </div>
          <a className={styles.viewProfileLink} href="/profile">
            View profile
          </a>
        </div>
      </div>
    </div>
  );
};
