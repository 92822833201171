import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ListSimple, {
  ListSimpleItem,
} from "shared/src/components/ListSimple/ListSimple";
import Loader from "shared/src/components/Loader/Loader";
import { StyledLinkCaretRight } from "shared/src/components/StyledLinkCaretRight/StyledLinkCaretRight";

import styles from "./DocumentSideBarMenu.module.scss";
import {
  RelatedDocumentVm,
  DocumentSummaryVm,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";
import { conjugateTerm } from "shared/src/util/conjugateTerm";

export type RelatedDocuments = {
  data: RelatedDocumentVm[];
  isLoading: boolean;
  hasError: boolean;
};

interface Props {
  document: DocumentSummaryVm;
  formTypes: FormTypeDTO[];
  onSelectFormType: (type: FormTypeDTO) => void;
  relatedDocuments: RelatedDocuments;
  toggleDocumentHistory: () => void;
  toggleFormTypesVisibility: () => void;
  toggleRehuddleSidebarVisibility: () => void;
  toggleShareDocumentVisibility: () => void;
  toggleRelatedDocumentsVisibility: () => void;
  terms: {
    document: {
      singular: string;
      plural: string;
    };
    rehuddle: string;
  };
}

const DocumentSidebarMenu = ({
  document,
  formTypes,
  onSelectFormType,
  relatedDocuments,
  toggleDocumentHistory,
  toggleFormTypesVisibility,
  toggleRehuddleSidebarVisibility,
  toggleRelatedDocumentsVisibility,
  toggleShareDocumentVisibility,
  terms,
}: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Group config terms
  const documentTerm = conjugateTerm({
    term: terms.document.singular,
    role: "noun",
    modifier: undefined,
    fallback: "Document",
  });

  const documentsTerm = conjugateTerm({
    term: terms.document.plural,
    role: "noun",
    modifier: "plural",
    fallback: "Documents",
  });

  const rehuddleTerm = conjugateTerm({
    term: terms.rehuddle,
    role: "noun",
    modifier: undefined,
    fallback: "Rehuddle",
  });

  const lowerDocumentTerm = documentTerm.toLowerCase();

  const handleNavigateToDocument = useCallback(
    () => navigate(`/${lowerDocumentTerm}/${document.id}`),
    [navigate, document.id]
  );

  /* Nav items */
  const viewDocument: ListSimpleItem = {
    primary: <span className={styles.navLabel}>View {documentTerm}</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: handleNavigateToDocument,
    showLink: !pathname.includes("/document/"),
  };

  const documentHistory: ListSimpleItem = {
    primary: <span className={styles.navLabel}>{documentTerm} History</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: toggleDocumentHistory,
    showLink: true,
  };

  const shareDocument: ListSimpleItem = {
    primary: <span className={styles.navLabel}>Share</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => toggleShareDocumentVisibility(),
    showLink: !!document.formSummary.sharingEnabled,
  };

  const rehuddle: ListSimpleItem = {
    primary: <span className={styles.navLabel}>{rehuddleTerm}</span>,
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => toggleRehuddleSidebarVisibility(),
    showLink: true,
  };
  const startRelatedShortList: ListSimpleItem[] = formTypes
    .slice(0, 1)
    .map((type: FormTypeDTO) => ({
      primary: (
        <span className={styles.navLabel}>Start related {type.name}</span>
      ),
      rightSideItem: <StyledLinkCaretRight />,
      onClick: () => onSelectFormType(type),
      showLink: true,
    }));

  const viewMoreRelated: ListSimpleItem = {
    primary: (
      <span className={styles.navLabel}>
        Start more Related {documentsTerm} ({formTypes.length - 2})
      </span>
    ),
    rightSideItem: <StyledLinkCaretRight />,
    onClick: () => toggleFormTypesVisibility(),
    showLink: true,
  };

  const startRelated: ListSimpleItem[] = [...startRelatedShortList];
  if (formTypes.length > 2) {
    startRelated.push(viewMoreRelated);
  }

  const isViewRelatedDisabled: boolean =
    relatedDocuments.isLoading || !relatedDocuments?.data?.length;

  const viewRelated: ListSimpleItem = {
    primary: (
      <span className={styles.navLabel}>
        <div className={styles.viewRelated}>
          <span>View Related {documentsTerm}</span>
          <Loader
            loading={relatedDocuments.isLoading}
            className={styles.loader}
            spinnerProps={{ size: 20 }}
          >
            ({relatedDocuments.data.length})
          </Loader>
        </div>
      </span>
    ),
    rightSideItem: <StyledLinkCaretRight />,
    onClick: !isViewRelatedDisabled
      ? () => toggleRelatedDocumentsVisibility()
      : undefined,
    disabled: isViewRelatedDisabled,
    showLink: true,
  };

  /* Group nav items together */
  // top nav group
  const docDetailsGroup: ListSimpleItem[] = [
    viewDocument,
    documentHistory,
    shareDocument,
  ];

  // bottom ("related") nav group
  const relatedDocsGroup: ListSimpleItem[] = [viewRelated];
  if (startRelated.length > 0) {
    relatedDocsGroup.unshift(...startRelated);
  }

  return (
    <div>
      {/* FIXME remove external padding, remove negative margins, add consistent padding -- GK [POW-2362] */}
      <div style={{ marginLeft: "-16px", marginRight: "-16px", marginTop: 0 }}>
        <hr className={styles.divider} />
        <ListSimple qa="detail" listItems={docDetailsGroup} />
        <hr className={styles.divider} />
        {document.formSummary.isRehuddleEligible && (
          <>
            <ListSimple qa="rehuddle" listItems={[rehuddle]} />
            <hr className={styles.divider} />
          </>
        )}
        <ListSimple qa="related" listItems={relatedDocsGroup} />
      </div>
    </div>
  );
};

export default DocumentSidebarMenu;
