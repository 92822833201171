import React from "react";
import { Print, Share } from "@mui/icons-material";

import * as S from "./styles";
import Icon from "shared/src/components/common/Icon";
import Loader from "shared/src/components/Loader/Loader";

interface ActionBarProps {
  handlePrint: () => void;
  handleExport: () => void;
  handleShare: () => void;
  loading: {
    export: boolean;
  };
}

const ActionBar = ({
  handlePrint,
  handleExport,
  handleShare,
  loading,
}: ActionBarProps) => {
  return (
    <S.ActionBarContainer>
      <S.LinkContainer disabled onClick={handleShare}>
        <S.IconContainer>
          <Share />
        </S.IconContainer>
        Share
      </S.LinkContainer>
      <S.LinkContainer onClick={handleExport}>
        <Icon
          className="d-block"
          type="export_csv"
          color="white"
          framed={false}
        />
        <Loader
          loading={loading.export}
          className="p-0"
          spinnerProps={{ size: 12, style: { color: "white", lineHeight: 0 } }}
        >
          Export
        </Loader>
      </S.LinkContainer>
      <S.LinkContainer onClick={handlePrint}>
        <S.IconContainer>
          <Print />
        </S.IconContainer>
        Print
      </S.LinkContainer>
    </S.ActionBarContainer>
  );
};

export default ActionBar;
