import LinearProgress from "@mui/material/LinearProgress";
import React, { FC } from "react";

import styles from "./PasswordRequirements.module.scss";
import { joinClassNames } from "../../helpers/theme.helpers";

interface PasswordRequirementProps {
  fulfilled: boolean;
  label: string;
}

const PasswordRequirement: FC<PasswordRequirementProps> = ({
  label,
  fulfilled,
}) => (
  <span
    className={joinClassNames(
      styles.passwordRequirement,
      fulfilled && styles.fulfilled
    )}
  >
    <i
      className={`${
        fulfilled ? "icon-icons8-checkmark" : "icon-icons8-delete_sign"
      } mr-1`}
    />
    {label}
  </span>
);

interface PasswordRequirementsValidations {
  atLeastEight: boolean;
  oneDigit: boolean;
  oneSymbol: boolean;
  oneUpperOneLower: boolean;
}

type PasswordRequirementsProps = {
  newPassword: string;
  passwordRegex: {
    DIGIT: RegExp;
    FULL: RegExp;
    LOWERCASE: RegExp;
    SPECIAL: RegExp;
    UPPERCASE: RegExp;
  };
};

const PasswordRequirements = ({
  newPassword,
  passwordRegex,
}: PasswordRequirementsProps) => {
  const validations = getValidations(newPassword, passwordRegex); // Validations object
  const progressValue = getProgressValue(validations); // Progress bar value

  return (
    <div className={styles.passwordRequirements}>
      <span className={styles.title}>Password Strength</span>
      <LinearProgress
        classes={{
          root: styles.progressRoot,
          barColorPrimary: styles.progressBarColorPrimary,
        }}
        variant="determinate"
        value={progressValue}
        color="primary"
      />
      <PasswordRequirement
        label="Use 8 or more characters"
        fulfilled={validations.atLeastEight}
      />
      <PasswordRequirement
        label="Use upper and lowercase letters (e.g. Aa)"
        fulfilled={validations.oneUpperOneLower}
      />
      <PasswordRequirement
        label="Use a number (e.g. 1234)"
        fulfilled={validations.oneDigit}
      />
      <PasswordRequirement
        label="Use a symbol (e.g. !@#$)"
        fulfilled={validations.oneSymbol}
      />
    </div>
  );
};

export default PasswordRequirements;

// Validations are moved outside so checks are performed once only
const getValidations = (
  newPassword: string,
  passwordRegex: PasswordRequirementsProps["passwordRegex"]
): PasswordRequirementsValidations => {
  // This is what will be used to pick icons (checked/unchecked), styles, etc
  let result = {
    atLeastEight: false,
    oneDigit: false,
    oneSymbol: false,
    oneUpperOneLower: false,
  };

  // If params are not falsy
  if (newPassword && passwordRegex) {
    // Check for at least one upper and one lower case
    if (
      newPassword.search(passwordRegex.UPPERCASE) > -1 &&
      newPassword.search(passwordRegex.LOWERCASE) > -1
    ) {
      result = {
        ...result,
        oneUpperOneLower: true,
      };
    }
    // Check for at least 7 characters
    if (newPassword.length > 7) {
      result = {
        ...result,
        atLeastEight: true,
      };
    }

    // Check for at least 1 digit
    if (newPassword.search(passwordRegex.DIGIT) > -1) {
      result = {
        ...result,
        oneDigit: true,
      };
    }

    // Check for at least 1 special char
    if (newPassword.search(passwordRegex.SPECIAL) > -1) {
      result = {
        ...result,
        oneSymbol: true,
      };
    }
  }
  return result;
};

// This sets the progress bar value, increments are based on number of requirements and 100 is max
const getProgressValue = (
  validations: PasswordRequirementsValidations
): number => {
  let result = 0;
  let numberOfCheckedValidations = 0;

  for (const [k, v] of Object.entries(validations)) {
    if (v) {
      numberOfCheckedValidations += 1;
      result += 100 / Object.keys(validations).length;
    }
  }
  // This is to round up weird numbers (3, 7, 11, ..., number of requirements)
  if (
    result !== 100 &&
    numberOfCheckedValidations === Object.keys(validations).length
  ) {
    result = 100;
  }
  return result;
};
