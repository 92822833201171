import {
  DocumentQuestionResponseVm,
  QuestionDTO,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { ReadOnlyResponse } from "./ReadOnlyResponse";
import { ReadOnlyPhotos } from "./ReadOnlyPhotos";
import styles from "./ReadOnly.module.scss";

interface DocumentQuestionProps {
  responses: DocumentQuestionResponseVm[];
  question: QuestionDTO;
  appPath?: string;
}

interface ReadOnlyDocumentItemProps {
  items: SectionItem[];
  responses: DocumentQuestionResponseVm[];
  appPath?: string;
}

export const ReadOnlyDocumentItem: FC<ReadOnlyDocumentItemProps> = ({
  items,
  responses,
  appPath,
}) => {
  /**
   * Get the responses for a specific question
   * @param itemId itemId to get responses for
   */
  const getResponses = (itemId: number) =>
    (responses &&
      responses.filter((response) => response.questionId === itemId)) ||
    [];

  const DocumentResponses: FC<DocumentQuestionProps> = ({
    responses,
    question,
    appPath,
  }) => {
    if (responses.length > 0) {
      return (
        <>
          <div className={styles.section} key={question.id}>
            <h2 className={styles.label}>{question.title}</h2>
            {responses?.length ? (
              responses.map((response) => {
                return (
                  <div key={response.id}>
                    <ReadOnlyResponse
                      label="Response"
                      value={response?.answer || "N/A"}
                    />

                    {response?.comments && (
                      <ReadOnlyResponse
                        label="Comment"
                        value={response.comments}
                      />
                    )}

                    <ReadOnlyPhotos question={question} appPath={appPath} />
                  </div>
                );
              })
            ) : (
              // if no response is found
              <ReadOnlyResponse label="Response" value="N/A" />
            )}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {items.map((item) => {
        const questionResponses = getResponses(item.id);

        return (
          <div>
            <DocumentResponses
              responses={questionResponses}
              question={item as QuestionDTO}
              appPath={appPath}
            />
          </div>
        );
      })}
    </>
  );
};
