import { TagDTO } from "@rtslabs/field1st-fe-common";
import moment from "moment";
import React from "react";
import Loader from "shared/src/components/Loader/Loader";
import { Components, ElementType } from "shared/src/qa-slugs";
import { LoaderWrapper } from "../../../clientAdmin/addUser/styles";
import {
  CancelButton,
  SubmitButton,
} from "shared/src/components/clientAdmin/styles";
import {
  DataItemLabel,
  DataItemText,
  DisableUserButtonsWrapper,
  DisableUserDataItem,
  ModalPromptCloseIcon,
  ModalPromptSubtitle,
  ModalPromptTitle,
  ModalPromptWrapper,
} from "../../../clientAdmin/users/styles";
import { actionPerformed } from "../helpers";
import { TagStatusOutcomeModal } from "./TagStatusOutcomeModal";

export interface TagStatusModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  status: "request" | "failure" | "success" | null;
  tag: TagDTO | null;
}

const qaBase = `${Components.TagLibraryTable}-${ElementType.Button}`;

export const TagStatusModal = ({
  onCancel,
  onSubmit,
  status,
  tag,
}: TagStatusModalProps) => {
  const successOrFailure = status === "success" || status === "failure";

  if (successOrFailure) {
    return (
      <TagStatusOutcomeModal
        archived={tag?.archived}
        onClose={onCancel}
        status={status}
      />
    );
  }

  return (
    <>
      <ModalPromptWrapper>
        <ModalPromptTitle>Are you sure?</ModalPromptTitle>
        <ModalPromptSubtitle>
          You are about to {actionPerformed(tag?.archived, "present")} this tag:
        </ModalPromptSubtitle>
        <div className="d-flex flex-row">
          <DisableUserDataItem>
            <DataItemLabel>Name</DataItemLabel>
            <DataItemText>{tag?.name || "-"}</DataItemText>
          </DisableUserDataItem>

          <DisableUserDataItem>
            <DataItemLabel>Uses</DataItemLabel>
            <DataItemText>{tag?.useCount || "-"}</DataItemText>
          </DisableUserDataItem>

          <DisableUserDataItem>
            <DataItemLabel>Date Added</DataItemLabel>
            <DataItemText>
              {tag ? moment(tag.createdDate).format("MM/DD/YYYY") : "-"}
            </DataItemText>
          </DisableUserDataItem>
        </div>

        <DisableUserButtonsWrapper margin="24px 0 0 0">
          <CancelButton
            data-testid={`${qaBase}-cancel`}
            disabled={false}
            onClick={onCancel}
          >
            cancel
          </CancelButton>
          <SubmitButton
            data-testid={`${qaBase}-submit`}
            disabled={false}
            onClick={onSubmit}
          >
            <LoaderWrapper>
              <Loader
                loading={status === "request"}
                className="p-0 mr-3"
                spinnerProps={{ size: 14 }}
              />
            </LoaderWrapper>
            <span>yes</span>
          </SubmitButton>
        </DisableUserButtonsWrapper>

        <ModalPromptCloseIcon
          data-testid={`${qaBase}-close`}
          onClick={onCancel}
        />
      </ModalPromptWrapper>
    </>
  );
};
