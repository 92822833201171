import React from "react";

import { Icon } from "../../../../../../Icon/Icon";
import { TextButton } from "../../../../../../common/Button/styles";

import ps from "../../styles.module.scss";

interface AddRuleButtonProps {
  onAdd: () => void;
}

const AddRuleButton = ({ onAdd }: AddRuleButtonProps) => {
  return (
    <TextButton type="button" className={ps.newRuleButton} onClick={onAdd}>
      <div className={ps.newRuleText}>
        <div>Add another display rule</div>
        <div>
          <Icon className={ps.addIcon} type="add" />
        </div>
      </div>
    </TextButton>
  );
};

export default AddRuleButton;
