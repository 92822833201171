import withTheme from "@mui/styles/withTheme";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  API,
  DataSource,
  DataSourceStatus,
  FilterParams,
  SortParams,
} from "@rtslabs/field1st-fe-common";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { Button } from "shared/src/components/Button/Button";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import useDebounce from "shared/src/util/hooks/useDebounce";
import { dataSetWriteRoles } from "../../../routes/constants/permissionSets";
import { AppState } from "../../../store";
import { downloadFile } from "../../../util";

import { Icon } from "shared/src/components/Icon/Icon";
import { ErrorToast } from "shared/src/components/Toast/ErrorToast";
import { SuccessToast } from "shared/src/components/Toast/SuccessToast";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import Loader from "shared/src/components/Loader/Loader";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import { ConfirmModal, CreateModal } from "./DSModals";
import { DSTable } from "./DSTable";
import s from "./styles.module.scss";

/** Data sets main view. Note that "data set" (business) is synonymous with "data source" (API) */
function DataSets() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSources, setDataSources] = useState<Array<DataSource>>([]);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [query, setQuery] = useState<string>("");
  const [sort, setSort] = useState<SortParams | undefined>(["title", "asc"]);
  const [totals, setTotals] = useState<{ elements: number; pages: number }>({
    elements: 0,
    pages: 0,
  });
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [typeSelected, setTypeSelected] = useState<
    "uploaded" | "api" | "basic" | null
  >(null);
  const [sourceSelected, setSourceSelected] = useState<DataSource | null>(null);
  const canEdit = API.Environment.hasRoleAccess(dataSetWriteRoles);
  const [statusUpdated, setStatusUpdated] = useState<DataSourceStatus>();
  const [loadingUpdateStatus, setLoadingUpdateStatus] =
    useState<boolean>(false);
  const [error, setError] = useState<string>();

  const authToken = useSelector(({ auth }: AppState) => auth.token);

  const getAndSetDataSources = async (params: FilterParams) => {
    if (authToken) {
      try {
        setLoading(true);
        const ds = await API.getDataSources(params);
        setTotals({
          pages: ds.totalPages,
          elements: ds.totalElements,
        });
        setDataSources(ds.content);
      } catch (err) {
        // TODO error handling -JA
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  /** get all data sources on mount */
  React.useEffect(() => {
    (async () => {
      await getAndSetDataSources({
        page,
        size,
        sort,
        query,
      });
    })();
  }, [sort, page, size]);

  /** delayed search for user input */
  const debouncedGet = useDebounce({
    method: async (q) => {
      setQuery(q);
      await getAndSetDataSources({
        page,
        size,
        sort,
        query: q,
      });
    },
    delayAmount: 1000,
  });

  const closeCreateModal = () => {
    setCreateModalOpen(false);
    setTypeSelected(null);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const downloadCSV = async (id: number, title?: string | null) => {
    try {
      const csv = await API.downloadDataSourceValues({
        dataSourceId: id,
        mediaType: "text/csv",
      });
      downloadFile(csv.blob, csv.fileName);
    } catch (err) {
      // TODO error handling -JA
      console.error(err);
    }
  };

  const updateSource = async (ds: DataSource, status?: DataSourceStatus) => {
    if (authToken && status && ds.id) {
      await API.updateDataSourceStatus({ id: ds.id, status });
      closeConfirmModal();
      await getAndSetDataSources({
        page,
        size,
        sort,
        query,
      });
    }
  };

  const handleUpdateConfirmed = async (
    ds: DataSource,
    status?: DataSourceStatus
  ) => {
    setLoadingUpdateStatus(true);
    try {
      await updateSource(ds, status);
      setStatusUpdated(status);
    } catch (error) {
      const verb =
        status === DataSourceStatus.UNPUBLISHED ? "unpublish" : "publish";
      setError(`Failed to ${verb} “${ds.title}”`);
      setConfirmModalOpen(false);
    } finally {
      setLoadingUpdateStatus(false);
    }
  };

  return (
    <>
      <SuccessToast
        component={() => <>Data Set “{sourceSelected?.title}” Published</>}
        icon="checkmark"
        onClick={() => {
          setStatusUpdated(undefined);
          sourceSelected &&
            updateSource(sourceSelected, DataSourceStatus.UNPUBLISHED);
        }}
        onDelay={() => setStatusUpdated(undefined)}
        qa={`${Components.DataSets}-${ElementType.Toast}-published`}
        visible={statusUpdated === DataSourceStatus.PUBLISHED}
      />
      <SuccessToast
        component={() => <>Data Set “{sourceSelected?.title}” Unpublished</>}
        icon="checkmark"
        onClick={() => {
          setStatusUpdated(undefined);
          sourceSelected &&
            updateSource(sourceSelected, DataSourceStatus.PUBLISHED);
        }}
        onDelay={() => setStatusUpdated(undefined)}
        qa={`${Components.DataSets}-${ElementType.Toast}-unpublished`}
        visible={statusUpdated === DataSourceStatus.UNPUBLISHED}
      />
      <ErrorToast
        component={() => <>{error}</>}
        icon="high_priority"
        onDelay={() => setError(undefined)}
        qa={`${Components.DataSets}-${ElementType.Toast}-error`}
        visible={!!error && !statusUpdated}
      />
      <CreateModal
        open={createModalOpen}
        closeModal={closeCreateModal}
        typeSelected={typeSelected}
        setTypeSelected={setTypeSelected}
      />
      <ConfirmModal
        open={confirmModalOpen}
        closeModal={closeConfirmModal}
        loading={loadingUpdateStatus}
        updateSourceStatus={handleUpdateConfirmed}
        sourceSelected={sourceSelected}
      />
      <Breadcrumbs
        paths={[
          { href: "/forms", pathName: "Forms" },
          { pathName: "Data Sets" },
        ]}
      />
      <div className={s.dataSetsTitleWrapper}>
        <h1
          className={s.h1}
          data-testid={`${Page.Desktop}-${Components.DataSets}`}
        >
          Data Sets
        </h1>
        <WriteContent roles={dataSetWriteRoles}>
          <Button
            className={s.addButton}
            onClick={() => setCreateModalOpen(true)}
            qa={`${Components.FormTypes}-${ElementType.Button}-create`}
            startContent={
              <Icon className={s.addButtonIcon} size={20} type="add_file" />
            }
          >
            Add data set
          </Button>
        </WriteContent>
      </div>
      <div className={s.searchBarContainer}>
        <SearchBar
          qa={`${Components.DataSets}-${ElementType.TextInput}-searchBar`}
          onSearch={(q) => debouncedGet(q)}
        />
      </div>
      <Loader loading={loading}>
        <TableSummary
          pageSize={size}
          currentPage={page}
          totalElements={totals.elements}
          ofWhat="data sets"
          exports={["print", "xls", "csv"]}
          onExport={API.downloadDataSources}
          exportParams={{ query }}
        />
        <DSTable
          canEdit={canEdit}
          dataSources={dataSources}
          sort={sort}
          setSort={setSort}
          page={page}
          setPage={setPage}
          totalPages={totals.pages}
          size={size}
          setSize={setSize}
          downloadCSV={downloadCSV}
          setCreateModalOpen={setCreateModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          setSourceSelected={setSourceSelected}
        />
      </Loader>
    </>
  );
}

export default withTheme(DataSets);
