import React, { useEffect } from "react";

import Loader from "shared/src/components/Loader/Loader";
import styles from "./NewForm.module.scss";
import RenderWorkOrders from "./RenderWorkOrders";
import { Components, ElementType } from "shared/src/qa-slugs";
import { WorkOrderDTO } from "@rtslabs/field1st-fe-common";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";

export interface WorkOrderProps {
  clearWorkOrders: () => void;
  handleSelectWorkOrderId: (workOrderId?: number) => void;
  loading: boolean;
  selectedWorkOrderId?: number;
  onSearchForWorkOrders: (workOrderId: string) => void;
  workOrders?: WorkOrderDTO[];
}

/**
 * Handles the part of the Add New Document feature which involves entering
 * a Work Order ID, hitting the API for matching Work Orders, allowing the
 * user to select a Work Order, ect
 */
const WorkOrder = ({
  handleSelectWorkOrderId,
  selectedWorkOrderId,
  onSearchForWorkOrders,
  workOrders,
  loading,
  clearWorkOrders,
}: WorkOrderProps) => {
  // clear work orders on unmount
  useEffect(() => {
    return clearWorkOrders();
  }, []);

  return (
    <div className={styles.setFormValue}>
      <label htmlFor="workOrder">Start with work order</label>
      <SearchBar
        qa={`${Components.SearchInput}-${ElementType.TextInput}-work-order-search`}
        name="workOrder"
        placeholder="Enter Work Order ID#"
        onSearch={onSearchForWorkOrders}
        className={styles.workOrderSearch}
      />
      <Loader loading={loading}>
        <div className={styles.workOrderWrapper}>
          <RenderWorkOrders
            workOrders={workOrders}
            handleSelectWorkOrderId={handleSelectWorkOrderId}
            selectedWorkOrderId={selectedWorkOrderId}
          />
        </div>
      </Loader>
    </div>
  );
};

export default WorkOrder;
