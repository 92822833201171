import { UserStatus } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { QAProps } from "shared/src/qa-slugs";
import { getActivationStatusForTable } from "./helpers";
import styles from "./ReadOnlyUserStatus.module.scss";

interface Props extends QAProps {
  currentStatus: UserStatus;
}

const getClassNameForStatus = (status: UserStatus) => {
  switch (status) {
    case UserStatus.ACTIVE:
      return styles.active;
    case UserStatus.DISABLED:
      return styles.deactivated;
    default:
      return styles.nonActivated;
  }
};

export const ReadOnlyUserStatus: FC<Props> = ({ currentStatus, qa }) => {
  return (
    <span className={getClassNameForStatus(currentStatus)} data-testid={qa}>
      {getActivationStatusForTable(currentStatus)}
    </span>
  );
};
