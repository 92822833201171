import React, { FC } from "react";
import { Link } from "../Link/Link";
import { ContentWrapper } from "../Wrappers/Wrappers";
import styles from "./routes.module.scss";
import { Components, Page } from "../../qa-slugs";

interface NoPermissionsProps {
  noReturn?: boolean;
}

export const NoPermissions: FC<NoPermissionsProps> = ({ noReturn }) => {
  return (
    <ContentWrapper>
      <div
        className={styles.warningWrapper}
        data-testid={`${Page.Desktop}-${Components.NoPermissions}`}
      >
        You do not have required permissions to view this page.{" "}
        {!noReturn && (
          <>
            Click <Link to="/">here</Link> to return to Dashboard.
          </>
        )}
      </div>
    </ContentWrapper>
  );
};
