import moment, { Moment } from "moment";

export enum TimeFilter {
  ALL_TIME = "ALL_TIME",
  CUSTOM_RANGE = "CUSTOM_RANGE",
  FIFTEEN_DAYS = "FIFTEEN_DAYS",
  SEVEN_DAYS = "SEVEN_DAYS",
  THIRTY_DAYS = "THIRTY_DAYS",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
}

export interface DateRangeValue {
  endDate: Moment;
  startDate: Moment;
}

export const getMinMaxSubmissionDatesFromTimeFilter = (
  timeFilter?: TimeFilter,
  dateRange?: DateRangeValue
): { minSubmissionDate: string; maxSubmissionDate: string } => {
  let maxSubmissionDate = "";
  let minSubmissionDate = "";

  switch (timeFilter) {
    case TimeFilter.CUSTOM_RANGE:
      if (!dateRange) {
        break;
      }
      minSubmissionDate = dateRange.startDate.toISOString();
      if (dateRange.endDate < moment()) {
        maxSubmissionDate = dateRange.endDate.toISOString();
      }
      break;

    case TimeFilter.FIFTEEN_DAYS:
      minSubmissionDate = moment()
        .subtract(15, "d")
        .startOf("day")
        .toISOString();
      break;

    case TimeFilter.SEVEN_DAYS:
      minSubmissionDate = moment()
        .subtract(7, "d")
        .startOf("day")
        .toISOString();
      break;

    case TimeFilter.THIRTY_DAYS:
      minSubmissionDate = moment()
        .subtract(30, "d")
        .startOf("day")
        .toISOString();
      break;

    case TimeFilter.TODAY:
      minSubmissionDate = moment().startOf("day").toISOString();
      break;

    case TimeFilter.YESTERDAY:
      maxSubmissionDate = moment().subtract(1, "d").endOf("day").toISOString();
      minSubmissionDate = moment()
        .subtract(1, "d")
        .startOf("day")
        .toISOString();
      break;
  }

  return { minSubmissionDate, maxSubmissionDate };
};

export const timeFilterOptions: { value: TimeFilter; label: string }[] = [
  {
    value: TimeFilter.ALL_TIME,
    label: "All Time",
  },
  {
    value: TimeFilter.TODAY,
    label: "Today",
  },
  {
    value: TimeFilter.YESTERDAY,
    label: "Yesterday",
  },
  {
    value: TimeFilter.SEVEN_DAYS,
    label: "7 Days",
  },
  {
    value: TimeFilter.FIFTEEN_DAYS,
    label: "15 Days",
  },
  {
    value: TimeFilter.THIRTY_DAYS,
    label: "30 Days",
  },
  {
    value: TimeFilter.CUSTOM_RANGE,
    label: "Custom Range",
  },
];
