import React, { PropsWithChildren } from "react";

interface TabPanelProps {
  className?: string;
  id: string;
  isHidden?: boolean;
  tabId: string;
}

export const TabPanel = ({
  children,
  className,
  id,
  isHidden,
  tabId,
}: PropsWithChildren<TabPanelProps>) => {
  return (
    <div
      aria-labelledby={tabId}
      className={className}
      hidden={isHidden ? true : undefined}
      id={id}
      role="tabpanel"
    >
      {children}
    </div>
  );
};
