import React from "react";

import { DocumentParticipantVm } from "@rtslabs/field1st-fe-common";
import Label from "../../Label/Label";
import { ReadOnlyResponse } from "./ReadOnlyResponse";
import { ReadOnlySignature } from "./signatures/ReadOnlySignature";
import styles from "./ReadOnly.module.scss";

interface Props {
  participants: Array<DocumentParticipantVm>;
}

export const ReadOnlySignatures = ({ participants }: Props) => {
  const signedParticipants = participants.filter((x) => x.signatureType);

  if (signedParticipants.length === 0) return null;

  return (
    <div>
      <Label className={styles.label}>Signatures</Label>

      {signedParticipants.map((participant) => (
        <div className={styles.section} key={participant.participantId}>
          <ReadOnlyResponse
            label={participant.fullName}
            value={<ReadOnlySignature {...participant} />}
          />
        </div>
      ))}
    </div>
  );
};
