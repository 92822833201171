import React from "react";
import styled from "styled-components";

import ListItemIconBase from "@mui/material/ListItemIcon";

const ListItemIcon = styled(({ ...otherProps }) => (
  <ListItemIconBase {...otherProps} />
))``;

const IconElement = styled.span<{
  color: string;
  framed: boolean;
  size?: string;
}>`
  color: ${({ color }) => color};

  font-size: ${({ size }) => (size ? size : "1.5rem")};

  background: ${(props) =>
    props.framed ? props.theme.colors.lightGrey : "transparent"};
  padding: 4px 3px;
  border-radius: 4px;
`;

export { IconElement, ListItemIcon };
