import React, { FC, useContext } from "react";
import { PieChart } from "../../Charts/PieChart/PieChart";
import styles from "./PieSectionProgress.module.scss";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { useDocumentSection } from "./hooks/useDocumentSection";
import { QAProps } from "../../../qa-slugs";
import { ThemeContext } from "styled-components";

interface PieSectionProgressProps extends QAProps {}

export const PieSectionProgress: FC<PieSectionProgressProps> = ({
  qa = "DocSec-pieProgress",
}) => {
  const theme = useContext(ThemeContext);
  const { getCurrentSectionIndex, getVisibleSectionsTotal } =
    useDocumentSection();

  const curSecIndex = getCurrentSectionIndex();
  const currentSectionNumber = curSecIndex + 1;
  const visibleSectionsTotal = getVisibleSectionsTotal();
  const sliceColors = [
    { name: "blue", value: theme.colors.primary },
    { name: "grey", value: theme.colors.lightGrey },
  ];

  const data = [
    {
      color: sliceColors[0].value,
      id: "Sections Completed",
      qa: sliceColors[0].name,
      value: currentSectionNumber,
    },
    {
      color: sliceColors[1].value,
      id: "Sections Remaining",
      qa: sliceColors[1].name,
      value: visibleSectionsTotal - currentSectionNumber,
    },
  ];

  return (
    <div className={styles.pieChartWrapper}>
      <div className={styles.pieChart} data-testid={qa}>
        <PieChart
          ariaLabel="Pie chart showing the proportion of reports for each form type."
          data={data}
          labels={false}
          innerRadius={0.7}
          startAngle={-180}
          endAngle={180}
          borderWidth={0.01}
          fill={{ type: "Solid" }}
          sliceColors={{ datum: "data.color" }}
          centeredContent={
            <div
              className={joinClassNames(
                styles.pieChartCenterText,
                styles.overlay
              )}
            >
              <div className={styles.centerTextWrapper}>
                <div className={styles.countText}>
                  {currentSectionNumber} / {visibleSectionsTotal}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
