import React, { FC } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import Label from "../Label/Label";
import { RadioButtonOption } from "../RadioButtons/RadioButtonsGroup";
import { RatingButton } from "./RatingButton";
import styles from "./Ratings.module.scss";

interface Props {
  className?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: RadioButtonOption<string>[];
  required?: boolean;
  value: string;
  label?: string;
  error?: boolean;
}

export const Ratings: FC<Props> = ({
  className,
  label,
  name,
  onChange,
  options,
  value,
  required,
  error,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
  };

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={name} required={required} hasError={!!error}>
          {label}
        </Label>
      )}
      <div
        aria-labelledby={name}
        className={joinClassNames(styles.radioGroup)}
        id={name}
        role="radiogroup"
      >
        {options?.map((option) => {
          return (
            <RatingButton
              error={error}
              checked={value === option.data}
              className={styles.item}
              disabled={option.disabled}
              onChange={handleChange}
              key={option.label.text}
              label={option.label.text}
              name={`${name}_${option.label}`}
              value={option.data}
            />
          );
        })}
      </div>
    </div>
  );
};
