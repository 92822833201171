import React, { FC } from "react";
import ReactDOM from "react-dom";

import styles from "./Portal.module.scss";

interface PortalProps {
  children?: React.ReactNode;
  onClick?: (e?: any) => void;
  portalId?: string;
  variant: "default" | "backdrop";
}

const Portal: FC<PortalProps> = ({ children, onClick, portalId, variant }) => {
  const el = document.getElementById(portalId || "backdrop-root");

  if (!el) {
    return null;
  }

  if (variant === "backdrop") {
    return ReactDOM.createPortal(
      <div className={styles.portal} onClick={onClick ? onClick : () => {}} />,
      el
    );
  }

  return ReactDOM.createPortal(React.cloneElement(<>{children}</>), el);
};

export default Portal;
