import React, { FC } from "react";
import TooltipBase, { TooltipProps } from "@mui/material/Tooltip";
import styles from "./Tooltip.module.scss";

const Tooltip: FC<TooltipProps> = ({ children, ...rest }) => (
  <TooltipBase
    classes={{
      arrow: styles.arrow,
      tooltip: styles.tooltip,
    }}
    {...rest}
  >
    {children}
  </TooltipBase>
);

export const lightTooltipClasses = {
  tooltip: styles.lightTooltip,
};

export default Tooltip;
