import React, { FC } from "react";
import styles from "./SectionNav.module.scss";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { Components, ElementType, QAProps } from "../../../../qa-slugs";
import { useDocumentSection } from "../hooks/useDocumentSection";
import { useSectionalContext } from "../hooks/useSectionalContext";
import { useFormikContext } from "formik";
import { DocumentVm, FormSectionVm } from "@rtslabs/field1st-fe-common";
import { getErrorsInSection } from "../../document.helpers";

interface SectionItemProps extends QAProps {
  index: number;
  section: FormSectionVm;
}

export const SectionItem: FC<SectionItemProps> = ({
  index,
  section,
  qa = `${Components.SidebarNavigation}-${ElementType.MenuItem}`,
}) => {
  const { values, errors } = useFormikContext<DocumentVm>();
  const { getCurrentSectionIndex, handleSelectSection } = useDocumentSection();
  const { currentSection } = useSectionalContext();
  const curSecIndex = getCurrentSectionIndex();

  const wasDocSubmitted = !!values.resubmissions.find(
    (r) => r.submissionType === "SUBMIT"
  );

  const progressIndex = index;
  const disableSection = !wasDocSubmitted && curSecIndex < progressIndex;
  const disableLine = curSecIndex - 2 < progressIndex;
  const selected = curSecIndex === progressIndex;

  const getSection = (id: number) => {
    !disableSection &&
      currentSection.id !== section.id &&
      handleSelectSection(id);
  };

  const errorsInSection = getErrorsInSection(section, errors);

  return (
    <div
      className={joinClassNames(
        styles.navItem,
        disableSection && styles.disabled,
        selected && styles.selected,
        !!errorsInSection.length && !disableSection && styles.error
      )}
      onClick={() => getSection(section.id)}
      data-testid={`${qa}-section${progressIndex + 1}`}
    >
      {!disableLine && <span className={styles.verticalProgressBar}></span>}
      <span className={styles.progressDot}>{progressIndex + 1}</span>
      <span className={styles.text}>
        {!!errorsInSection.length &&
          !disableSection &&
          `(${errorsInSection.length}) `}
        {section.title || "Untitled Section"}
      </span>
    </div>
  );
};
