import { QAProps } from "../../qa-slugs";
import React, { PropsWithChildren, ReactNode, useState } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { Progress } from "./Progress";
import {
  ButtonStyle,
  CircleCheckmarkStyle,
  LabelStyle,
  ProgressStyle,
  ButtonLoadingState,
} from "./types";

import styles from "./Button.module.scss";
import primary from "./Primary.module.scss";
import secondary from "./Secondary.module.scss";
import tertiary from "./Tertiary.module.scss";
import { Icon, IconProps } from "../Icon/Icon";

const primaryButtonStyles: ButtonStyle = {
  button: primary.button,
  buttonChecked: primary.buttonChecked,
  buttonCompleted: primary.buttonCompleted,
  buttonDisabled: primary.buttonDisabled,
  buttonEnabled: primary.buttonEnabled,
  buttonLoading: primary.buttonLoading,
  buttonWithoutMouseFocus: primary.buttonWithoutMouseFocus,
  retainer: primary.retainer,
};

const primaryCircleCheckmarkStyles: CircleCheckmarkStyle = {
  circledIcon: primary.circledIcon,
  iconCircle: primary.iconCircle,
  iconCircleBeforeLabel: primary.iconCircleBeforeLabel,
};

const genericLabelStyles: LabelStyle = {
  labelChecked: primary.labelChecked,
  labelLoading: primary.labelLoading,
};

const primaryProgressStyles: ProgressStyle = {
  progress: primary.progress,
};

export interface ButtonProps extends QAProps {
  ariaLabelledBy?: string;
  buttonStyle?: ButtonStyle;
  children: React.ReactNode;
  className?: string;
  completedIconStyle?: CircleCheckmarkStyle;
  disabled?: boolean;
  labelStyle?: LabelStyle;
  loading?: boolean;
  loadingState?: ButtonLoadingState;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  progressStyle?: ProgressStyle;
  buttonRef?: React.Ref<HTMLButtonElement>;
  startContent?: ReactNode;
  type?: "submit" | "reset";
}

const getStateButtonStyles = (
  styles: ButtonStyle,
  disabled?: boolean,
  loadingState?: ButtonLoadingState
) => {
  if (disabled) {
    return styles.buttonDisabled;
  } else if (loadingState) {
    switch (loadingState) {
      case "Checked":
        return styles.buttonChecked;
      case "Completed":
        return styles.buttonCompleted;
      case "Loading":
        return styles.buttonLoading;
      case "None":
      default:
        return styles.buttonEnabled;
    }
  } else {
    return styles.buttonEnabled;
  }
};

const getStateLabelStyles = (
  styles: LabelStyle,
  disabled?: boolean,
  loadingState?: ButtonLoadingState
) => {
  if (disabled) {
    return styles.labelDisabled;
  } else if (loadingState) {
    switch (loadingState) {
      case "Checked":
        return styles.labelChecked;
      case "Completed":
        return styles.labelCompleted;
      case "Loading":
        return styles.labelLoading;
      case "None":
      default:
        return styles.labelEnabled;
    }
  } else {
    return styles.labelEnabled;
  }
};

/**
 * Common "Filled" Button component
 */
export const Button = ({
  ariaLabelledBy,
  children,
  className,
  buttonStyle = primaryButtonStyles,
  completedIconStyle = primaryCircleCheckmarkStyles,
  labelStyle = genericLabelStyles,
  progressStyle = primaryProgressStyles,
  onClick,
  disabled,
  loading,
  loadingState,
  buttonRef,
  startContent,
  type,
  qa,
}: ButtonProps) => {
  const [isMouseFocused, setIsMouseFocused] = useState(false);
  const styles = buttonStyle;

  const handleBlur = () => {
    setIsMouseFocused(false);
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  if (loading) {
    loadingState = "Loading";
  }

  const styleClassName = joinClassNames(
    styles.button,
    getStateButtonStyles(styles, disabled, loadingState),
    !isMouseFocused && styles.buttonWithoutMouseFocus
  );

  return (
    <button
      aria-labelledby={ariaLabelledBy}
      className={joinClassNames(styleClassName, className)}
      disabled={(!!loadingState && loadingState !== "None") || disabled}
      onBlur={handleBlur}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      ref={buttonRef}
      type={type || "button"}
      data-testid={qa}
    >
      <div className={styles.retainer}>
        <StartContent
          createCompletedIconStyle={completedIconStyle}
          createProgressStyle={progressStyle}
          component={startContent}
          disabled={disabled}
          loadingState={loadingState}
        />
        <Label
          createLabelStyle={labelStyle}
          disabled={disabled}
          loadingState={loadingState}
        >
          {children}
        </Label>
      </div>
    </button>
  );
};

interface LabelProps {
  createLabelStyle?: LabelStyle;
  disabled?: boolean;
  loadingState?: ButtonLoadingState;
}

const Label = ({
  children,
  createLabelStyle = genericLabelStyles,
  disabled,
  loadingState,
}: PropsWithChildren<LabelProps>) => {
  const styles = createLabelStyle;
  return (
    <span
      className={joinClassNames(
        styles.label,
        getStateLabelStyles(styles, disabled, loadingState)
      )}
    >
      {children}
    </span>
  );
};

interface StartContentProps {
  createCompletedIconStyle: CircleCheckmarkStyle;
  createProgressStyle: ProgressStyle;
  component?: ReactNode;
  disabled?: boolean;
  loadingState?: ButtonLoadingState;
}

const StartContent = ({
  createCompletedIconStyle,
  createProgressStyle,
  component = null,
  disabled,
  loadingState,
}: StartContentProps) => {
  if (disabled) {
    return null;
  }
  switch (loadingState) {
    case "Checked":
      return (
        <CircleCheckmark
          createCompletedIconStyle={createCompletedIconStyle}
          isBeforeLabel={false}
        />
      );
    case "Completed":
      return (
        <CircleCheckmark
          createCompletedIconStyle={createCompletedIconStyle}
          isBeforeLabel={true}
        />
      );
    case "Loading":
      return <Progress createStyles={createProgressStyle} />;
    case "None":
    default:
      return <>{component}</>;
  }
};

interface CircleCheckmarkProps {
  createCompletedIconStyle?: CircleCheckmarkStyle;
  isBeforeLabel: boolean;
}

export const CircleCheckmark = ({
  createCompletedIconStyle = primaryCircleCheckmarkStyles,
  isBeforeLabel = false,
}: CircleCheckmarkProps) => {
  const styles = createCompletedIconStyle;
  return (
    <div
      className={joinClassNames(
        styles.iconCircle,
        isBeforeLabel && styles.iconCircleBeforeLabel
      )}
    >
      <span
        className={`icon icon-icons8-checkmark ${styles.circledIcon}`}
      ></span>
    </div>
  );
};

const tertiaryButtonStyles: ButtonStyle = {
  button: tertiary.button,
  buttonChecked: tertiary.buttonChecked,
  buttonCompleted: tertiary.buttonCompleted,
  buttonDisabled: tertiary.buttonDisabled,
  buttonEnabled: tertiary.buttonEnabled,
  buttonLoading: tertiary.buttonLoading,
  buttonWithoutMouseFocus: tertiary.buttonWithoutMouseFocus,
  retainer: tertiary.retainer,
};

const tertiaryCircleCheckmarkStyles: CircleCheckmarkStyle = {
  circledIcon: tertiary.circledIcon,
  iconCircle: tertiary.iconCircle,
  iconCircleBeforeLabel: tertiary.iconCircleBeforeLabel,
};

const tertiaryProgressStyles: ProgressStyle = {
  progress: tertiary.progress,
};

export const TertiaryButton: React.FC<ButtonProps> = (props) => (
  <Button
    buttonStyle={tertiaryButtonStyles}
    completedIconStyle={tertiaryCircleCheckmarkStyles}
    progressStyle={tertiaryProgressStyles}
    {...props}
  />
);

const secondaryButtonStyles: ButtonStyle = {
  button: secondary.button,
  buttonChecked: secondary.buttonChecked,
  buttonCompleted: secondary.buttonCompleted,
  buttonDisabled: secondary.buttonDisabled,
  buttonEnabled: secondary.buttonEnabled,
  buttonLoading: secondary.buttonLoading,
  buttonWithoutMouseFocus: secondary.buttonWithoutMouseFocus,
  retainer: secondary.retainer,
};

const secondaryCircleCheckmarkStyles: CircleCheckmarkStyle = {
  circledIcon: secondary.circledIcon,
  iconCircle: secondary.iconCircle,
  iconCircleBeforeLabel: secondary.iconCircleBeforeLabel,
};

const secondaryProgressStyles: ProgressStyle = {
  progress: secondary.progress,
};

export const SecondaryButton: React.FC<ButtonProps> = (props) => (
  <Button
    buttonStyle={secondaryButtonStyles}
    completedIconStyle={secondaryCircleCheckmarkStyles}
    progressStyle={secondaryProgressStyles}
    {...props}
  />
);

export const LinkButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & QAProps
> = ({ className, ...rest }) => (
  <button
    className={joinClassNames(styles.linkBtn, className)}
    type="button"
    {...rest}
  />
);

export const IconButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & { icon: IconProps }
> = ({ icon, className, ...buttonProps }) => (
  <button
    className={joinClassNames(styles.iconBtn, className)}
    type="button"
    {...buttonProps}
  >
    <Icon {...icon} />
  </button>
);
