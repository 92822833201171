import React from "react";
import { FastField, Field, useFormikContext } from "formik";
import { get, orderBy } from "lodash";

import Checkbox from "../../../../../Checkbox/Checkbox";
import ColorPicker from "../../../../../common/form/ColorPicker";
import useDebounce from "../../../../../../util/hooks/useDebounce";

import { FBForm, FBItem, FBQuestion, Property } from "../../../../types";
import {
  getFormItemByRootId,
  getPathIndices,
  unmarshallForm,
} from "../../../helpers";
import { getItemPath } from "../getItemPath";
import AutoSaveItem from "../../../autoSave/AutoSaveItem";
import s from "../styles.module.scss";
import PrefillFromWorkOrder from "./PrefillFromWorkOrder/PrefillFromWorkOrder";
import { commonProps } from "../fieldProperties";
import {
  API,
  ApplicationConfigKey,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { useFBConfigs } from "../../../../../../util/hooks/useFBConfigs";

/**
 * Check that a question corresponding to a map widget question exists in the form
 * @param questionRootId  - root id of question to search for
 * @param values          - current values of the form
 * @param sectionIndex    - section within the form to search
 */
function isIncluded(
  questionRootId: number,
  values: FBForm,
  sectionIndex?: number
): boolean {
  if (typeof sectionIndex === "undefined") {
    return false;
  }
  return (
    values.sections[sectionIndex]?.items.findIndex(
      (i) => i.rootId === questionRootId
    ) > -1
  );
}

/**
 * Sort global questions by their answer source type (descending), placing "DATA_SOURCE" before "CURRENT_GPS"
 * @param questions
 */
function sortQuestions(questions: Array<FBQuestion>): Array<FBQuestion> {
  return orderBy(questions, (question) => question.answerSource?.type, [
    "desc",
  ]);
}

interface Props {
  itemPath: string;
  property: Property;
}

export default function LocationProperties({ itemPath, property }: Props) {
  const { formBuilderConfigs } = useFBConfigs();
  const { values, setValues, setFieldValue } = useFormikContext<FBForm>();
  const [globalMapQuestions, setGlobalMapQuestions] = React.useState<
    Array<FBQuestion>
  >([]);
  const { enableCloneDocument, enableWO } = formBuilderConfigs;

  const [sectionIndex, wqIndex] = React.useMemo(
    () => getPathIndices(itemPath),
    [itemPath]
  );

  const propertyPath = `${itemPath}.${property.name}`;

  /* fetch global map questions on mount */
  useAsyncEffect(async () => {
    const res = await API.getApplicationConfigByName({
      keyName: ApplicationConfigKey.mapWidgetQuestions,
    });
    setGlobalMapQuestions(sortQuestions(Object.values(res.properties)));
  }, [setGlobalMapQuestions]);

  const debouncedSetColor = useDebounce({
    method(index: number, color: string) {
      if (color.match(/^#[A-Fa-f0-9]{6}$/)) {
        setFieldValue(`${propertyPath}[${index}].iconColor`, color);
      }
    },
  });

  /**
   * Add a map question to the form and map widget
   * @param question - a global question being added
   */
  async function addMapQuestion(question: FBQuestion): Promise<void> {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    if (typeof sectionIndex !== "undefined" && typeof wqIndex !== "undefined") {
      const widget = values.sections[sectionIndex].items[wqIndex];
      if (widget?.subType === "MAP" && question.answerSource) {
        // this is some weird and possibly unnecessary logic to add the map widget questions in order
        // (work location first, 911 second)
        const newItemIndex =
          question.answerSource.type === "CURRENT_GPS" &&
          widget.questions?.length
            ? 2
            : 1;

        // 1. add the new question under the widget
        // including the parentWidgetId automatically updates the map widget questions
        const newQuestion = {
          ...question,
          parentWidgetId: widget.id,
          parentWidgetRootId: widget.rootId,
        };

        const qRes = await API.addOrUpdateFormItem({
          formId: values.id,
          sectionId: widget.parentSectionId,
          item: { ...newQuestion, workflowType: "DRAFT" },
          index: wqIndex + newItemIndex,
        });

        // 2. update the form values
        const updatedForm = unmarshallForm(qRes);
        setValues(updatedForm);
      }
    }
  }

  /**
   * Remove a map question from the form and map widget (if existing)
   * @param question - the form question to be removed
   */
  async function deleteMapQuestion(question: FBItem) {
    const res = await API.deleteFormItem({
      formId: values.id,
      sectionId: question.parentSectionId,
      index: question.itemIndex,
      removeWidgetChildren: true,
    });
    setValues(unmarshallForm(res));
  }

  const widget = get(values, itemPath);

  return (
    <>
      {globalMapQuestions.map((question) => {
        const checked = isIncluded(question.rootId!, values, sectionIndex);
        const formQuestion = getFormItemByRootId(values, question.rootId!);
        const wqIndex = widget.questions?.findIndex(
          (wq: any) => wq.questionRootId === question.rootId
        );
        const questionPath =
          formQuestion && getItemPath(values.sections, formQuestion);

        return (
          <>
            <Field
              key={`mapQuestion[${wqIndex}]`}
              as={Checkbox}
              className={s.checkbox}
              name={`mapQuestion[${wqIndex}]`}
              label={`Include ${question.title} field`}
              checked={checked}
              onChange={(checked: boolean) => {
                if (checked) {
                  addMapQuestion(question);
                } else if (formQuestion) {
                  deleteMapQuestion(formQuestion);
                }
              }}
            />
            {checked && (
              <div style={{ marginLeft: 28 }}>
                {questionPath && (
                  <AutoSaveItem
                    values={get(values, questionPath)}
                    itemPath={questionPath}
                  />
                )}
                <FastField
                  as={Checkbox}
                  containerClassName={s.checkboxContainer}
                  className={s.checkbox}
                  name={`${questionPath}.formProperties.isRequired`}
                  label="Required"
                  checked={get(
                    values,
                    `${questionPath}.formProperties.isRequired`
                  )}
                  onChange={(checked: boolean) =>
                    setFieldValue(
                      `${questionPath}.formProperties.isRequired`,
                      checked
                    )
                  }
                />
                {enableCloneDocument && (
                  <FastField
                    as={Checkbox}
                    containerClassName={s.checkboxContainer}
                    className={s.checkbox}
                    name={`${questionPath}.formProperties.isCloneable`}
                    label="Clone answer to new Related Documents"
                    checked={get(
                      values,
                      `${questionPath}.formProperties.isCloneable`
                    )}
                    onChange={(checked: boolean) =>
                      setFieldValue(
                        `${questionPath}.formProperties.isCloneable`,
                        checked
                      )
                    }
                  />
                )}
                <FastField
                  as={ColorPicker}
                  id={question.id?.toString() || `pin_color_${wqIndex}`}
                  withText
                  label="Icon Color"
                  onColorChange={(value: string) =>
                    debouncedSetColor(wqIndex, value)
                  }
                  colorValue={get(
                    values,
                    `${propertyPath}[${wqIndex}].iconColor`,
                    ""
                  )}
                />
                {question.answerSource?.type === "DATA_SOURCE" ? (
                  <span className={s.workLocationNotice}>
                    {question.answerSource.dataSourceKey
                      ? `Work location responses are configured in the 
                      ${
                        question.answerSource.dataSourceKey || "work location"
                      } data set`
                      : "The work location data set for this field has not been set. Please contact the system administrator."}
                  </span>
                ) : (
                  <FastField
                    as={Checkbox}
                    name={`${questionPath}.answerSource.properties.detailedSearch.enabled`}
                    checked={get(
                      values,
                      `${questionPath}.answerSource.properties.detailedSearch.enabled`,
                      false
                    )}
                    label="Enable Geo Location finder"
                    onChange={(checked: boolean) =>
                      setFieldValue(
                        `${questionPath}.answerSource.properties.detailedSearch.enabled`,
                        checked
                      )
                    }
                  />
                )}
                {enableWO && (
                  <FastField
                    as={PrefillFromWorkOrder}
                    item={question}
                    property={commonProps.prefillFromWorkOrder}
                    itemPath={questionPath}
                  />
                )}
              </div>
            )}
          </>
        );
      })}
    </>
  );
}
