import React, { FC } from "react";

import styles from "./Pagination.module.scss";
import { CurrentPage, TotalElements } from "./types";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { Icon } from "../../Icon/Icon";
import { ElementType } from "../../../qa-slugs";

export const PaginationWrapper: FC<{ children?: React.ReactNode }> = ({
  children,
}) => <div className={styles.paginationWrapper}>{children}</div>;

interface Pagination {
  currentPage?: CurrentPage;
  totalPages?: TotalElements;
  onClick: (page: number) => void;
  qaBase?: string;
}

const getPrecedingNumbers = (startAt: number, howMany: number) => {
  for (var i = 1, res: number[] = []; i < 3 && startAt - i > 0; i++) {
    res.unshift(startAt - i);
  }
  return res;
};

const getFollowingNumbers = (
  startAt: number,
  max: number,
  howMany: number
): number[] => {
  for (var i = 1, res: number[] = []; i < howMany && max >= startAt + i; i++) {
    res.push(startAt + i);
  }
  return res;
};

const getRemainingNumbersAfter = (
  startAt: number,
  max: number,
  onClick: (num: number) => void,
  qaBase?: string
): JSX.Element | undefined => {
  if (startAt < max) {
    return (
      <div
        className={joinClassNames(styles.pageNumber, styles.clickable)}
        data-testid={qaBase && `${qaBase}-goToEnd`}
        onClick={() => onClick(max - 1)}
      >
        {max}
      </div>
    );
  }
};

export const Pagination = ({
  currentPage = 0,
  totalPages = 0,
  onClick,
  qaBase = "pagination",
}: Pagination) => {
  const previous = getPrecedingNumbers(currentPage + 1, 2);
  const next = getFollowingNumbers(
    currentPage + 1,
    totalPages,
    5 - previous.length
  );
  const moreThanTwoPagesRemaining = next[next.length - 1] < totalPages; // Show dots and last page if more than 2 remaining
  const nextLinkIsActive = totalPages && currentPage !== totalPages - 1;
  const previousLinkIsActive = currentPage !== 0;
  const moreThanTwoPagesToStart = currentPage > 2;
  return (
    <div className={styles.wrapper}>
      {/* Chevron Prev */}
      {(previousLinkIsActive && (
        <div
          className={joinClassNames(styles.pageNumber, styles.clickable)}
          onClick={() => onClick(currentPage - 1)}
          data-testid={`${qaBase}-previous`}
        >
          <Icon
            type="chevron-left"
            className={joinClassNames(styles.chevron, styles.left)}
            data-testid={`${qaBase}-${ElementType.Button}-left`}
          />
          PREV
        </div>
      )) || (
        <div className={styles.pageNumber} data-testid={`${qaBase}-previous`}>
          <Icon
            type="chevron-left"
            className={joinClassNames(styles.chevron, styles.left)}
            data-testid={`${qaBase}-${ElementType.Button}-left`}
          />
          PREV
        </div>
      )}
      {/* Dots */}
      {moreThanTwoPagesToStart && (
        <>
          <div
            className={joinClassNames(styles.pageNumber, styles.clickable)}
            data-testid={`${qaBase}-goToStart`}
            onClick={() => onClick(0)}
          >
            1
          </div>
          {previous && previous[0] !== 2 && (
            <div className={styles.pageNumber}>...</div>
          )}
        </>
      )}
      {/* // Prev Pages */}
      {previous.map((e) => (
        <div
          className={joinClassNames(styles.pageNumber, styles.clickable)}
          data-testid={`${qaBase}-page${e}`}
          key={e}
          onClick={() => onClick(e - 1)}
        >
          {e}
        </div>
      ))}
      {/* Current page */}
      <div
        data-testid={`${qaBase}-${currentPage + 1}`}
        className={styles.currentPage}
      >
        {currentPage + 1}
      </div>
      {/* Next pages */}
      {next.map((e) => (
        <div
          className={joinClassNames(styles.pageNumber, styles.clickable)}
          data-testid={`${qaBase}-page${e}`}
          key={e}
          onClick={() => onClick(e - 1)}
        >
          {e}
        </div>
      ))}
      {/* Dots */}
      {moreThanTwoPagesRemaining && (
        <>
          {next && next[next.length - 1] !== totalPages - 1 && (
            <div className={styles.pageNumber}>...</div>
          )}
          {getRemainingNumbersAfter(
            currentPage + 3,
            totalPages,
            onClick,
            qaBase
          )}
        </>
      )}
      {/* Chevron Next */}
      {(nextLinkIsActive && (
        <div
          className={joinClassNames(styles.pageNumber, styles.clickable)}
          data-testid={qaBase && `${qaBase}-next`}
          onClick={() => onClick(currentPage + 1)}
        >
          NEXT
          <Icon
            type="chevron-right"
            className={joinClassNames(styles.chevron, styles.right)}
            data-testid={qaBase && `${qaBase}-${ElementType.Button}-right`}
          />
        </div>
      )) || (
        <div
          className={styles.pageNumber}
          data-testid={qaBase && `${qaBase}-next`}
        >
          NEXT
          <Icon
            type="chevron-right"
            className={joinClassNames(styles.chevron, styles.right)}
            data-testid={qaBase && `${qaBase}-${ElementType.Button}-right`}
          />
        </div>
      )}
    </div>
  );
};
