import {
  API,
  ClientGroupDTO,
  FormTypeDTO,
  FormVersionDTO,
  FormVm,
} from "@rtslabs/field1st-fe-common";
import { Form, Formik, FormikHelpers } from "formik";
import { omit } from "lodash";
import React, { FC, JSXElementConstructor, useState } from "react";
import { TabContent, TabGroup } from "../../Tabs/TabGroup/TabGroup";
import { ErrorToast } from "../../Toast/ErrorToast";
import { joinClassNames } from "../../../helpers/theme.helpers";
import AutoSaveForm from "./autoSave/AutoSaveForm";
import FormBuilderHeader from "./baseUi/header/FormBuilderHeader";
import Create from "./create";
import FormBuilderHistory, {
  FormBuilderHistoryProps,
} from "./history/FormBuilderHistory";
import { HistorySortDropdownProps } from "./history/sortDropdown/HistorySortDropdown";
import FormBuilderSettings from "./settings/FormBuilderSettings";
import styles from "./styles.module.scss";
import { AppWidgetProps, FBForm, FBItem, FBSection } from "../types";
import formValidationSchema from "./validationSchema";
import { useFBConfigs } from "../../../util/hooks/useFBConfigs";
import { ItemParams } from "./create/Create";
import AdminSettingsPanel from "./settings/AdminSettingsPanel";
import { FormWidgetProps } from "../../Document/DocumentForm/types";

interface FormikFormBuilderProps {
  form: FBForm;
  formTypes: FormTypeDTO[];
  clientGroups: ClientGroupDTO[];
  formVersionRecords: FormVersionDTO[];
  historySortSelectOption: HistorySortDropdownProps["sortOption"];
  historySortDirection: HistorySortDropdownProps["sortDirection"];
  isLoadingFormVersions: boolean;
  isLoadingMoreFormVersions: boolean;
  handleHistorySortChange: HistorySortDropdownProps["onSortChange"];
  handleHistorySortDirectionChange: HistorySortDropdownProps["onSortDirectionChange"];
  handleVersionUpdate: FormBuilderHistoryProps["onVersionUpdate"];
  loadMoreFormVersions(): void;
  publishForm: (
    values: FBForm,
    { setValues }: FormikHelpers<FBForm>
  ) => Promise<FBForm | undefined>;
  saveForm: (values: FBForm) => Promise<FormVm | undefined>;
  error: string;
  setError: (error: string) => void;
  appWidgets: JSXElementConstructor<AppWidgetProps>;
  appWidgetsList: ItemParams[];
  widget: JSXElementConstructor<FormWidgetProps>;
  documentTerm: string;
}

const FormikFormBuilder: FC<FormikFormBuilderProps> = ({
  form,
  formTypes,
  clientGroups,
  formVersionRecords,
  historySortSelectOption,
  historySortDirection,
  isLoadingFormVersions,
  isLoadingMoreFormVersions,
  handleHistorySortChange,
  handleHistorySortDirectionChange,
  handleVersionUpdate,
  loadMoreFormVersions,
  publishForm,
  saveForm,
  error,
  setError,
  appWidgets,
  appWidgetsList,
  widget,
  documentTerm,
}) => {
  const { formBuilderConfigs } = useFBConfigs();
  const { enableHistory, enableSettings } = formBuilderConfigs;
  const userRoles = API.Environment.getRoles();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [currentItem, setCurrentItem] = useState<FBSection | FBItem | null>(
    null
  );
  const portalId = "tab-portal";
  const publishedFormVersion = formVersionRecords[0]
    ? formVersionRecords[0].version || formVersionRecords[0].formId
    : undefined;
  const isSuperAdmin = userRoles.includes("ROLE_SUPER_ADMIN");

  const tabs: TabContent[] = [
    {
      label: "Create",
      tabId: "create",
      tabPanelId: "create",
      tabPanelContent: (
        <Create
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          onSaveError={setError}
          appWidgets={appWidgets}
          appWidgetsList={appWidgetsList}
        />
      ),
    },
  ];

  if (enableSettings) {
    tabs.push({
      label: "Settings",
      tabId: "settings",
      tabPanelId: "settings",
      tabPanelContent: (
        <FormBuilderSettings
          formTypes={formTypes}
          clientGroups={clientGroups}
        />
      ),
    });
  }

  // Hide the history tab if there are no records
  if (enableHistory && formVersionRecords.length) {
    tabs.push({
      label: "History",
      showPanelInPortal: true,
      tabId: "history",
      tabPanelId: "history",
      tabPanelContent: (
        <FormBuilderHistory
          currentFormId={form.id}
          formVersionRecords={formVersionRecords}
          formWorkflowType={form.workflowType}
          isLoadingFormVersions={isLoadingFormVersions}
          isLoadingMoreFormVersions={isLoadingMoreFormVersions}
          sortOption={historySortSelectOption}
          sortDirection={historySortDirection}
          loadMoreFormVersions={loadMoreFormVersions}
          onSortChange={handleHistorySortChange}
          onSortDirectionChange={handleHistorySortDirectionChange}
          navigateToCreateTab={() => setSelectedTab(0)}
          onVersionUpdate={handleVersionUpdate}
          setError={setError}
          widget={widget}
          documentTerm={documentTerm}
        />
      ),
    });
  }

  if (isSuperAdmin) {
    tabs.push({
      label: "Admin Settings",
      showPanelInPortal: true,
      tabId: "adminSettings",
      tabPanelId: "adminSettings",
      tabPanelContent: <AdminSettingsPanel id={form.id} />,
    });
  }

  return (
    <>
      <Formik
        initialValues={form}
        onSubmit={publishForm}
        validationSchema={formValidationSchema}
        validateOnMount
      >
        {({ values }) => (
          <Form
            translate="yes"
            className={joinClassNames(
              styles.form,
              tabs[selectedTab].showPanelInPortal && styles.PortalTabOpen
            )}
          >
            {/* AutoSave all value changes except for sections, items, id, and workflowType */}
            <AutoSaveForm
              onSave={saveForm}
              listeners={omit(values, [
                "sections",
                "id",
                "workflowType",
                "lastModifiedDate",
              ])}
            />
            <div className={styles.formBuilder}>
              {/* Error toast */}
              <ErrorToast
                visible={!!error}
                onClick={() => setError("")}
                onDelay={() => setError("")}
                onDelayTime={6000}
              >
                {error}
              </ErrorToast>
              {/* Settings form, which wraps header so it can get the title from the current form state */}
              <FormBuilderHeader
                handleNavigate={(tabIndex) => setSelectedTab(tabIndex)}
                onSave={saveForm}
                selectedTab={selectedTab}
                formVersion={publishedFormVersion}
                setError={setError}
              />
              <TabGroup
                variant="primary"
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                classes={{
                  tabsContainer: styles.tabsContainer,
                  tabPanel: styles.tabPanel,
                  tabList: styles.tabList,
                  content: styles.tabsContent,
                }}
                portalId={portalId}
                tabs={tabs}
              />
            </div>
          </Form>
        )}
      </Formik>
      <div className={styles.tabPortal} id={portalId}></div>
    </>
  );
};

export default FormikFormBuilder;
