import React, { FC } from "react";
import * as yup from "yup";
import { Link } from "../Link/Link";
import { Form, Formik } from "formik";
import { TextInput } from "../TextInput/TextInput";
import { Components, ElementType } from "../../qa-slugs";
import styles from "./auth.module.scss";
import AuthAnimatedButton from "./AuthAnimatedButton";
import { AuthRequestStatus } from "./types";
import Logo from "../Logo";
import { API, LogoImgProps } from "@rtslabs/field1st-fe-common";

const helpSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
});

const buttonText = (status?: AuthRequestStatus): string => {
  switch (status) {
    case "success":
      return "Recovery Email Sent";
    case "failure":
      return "Unable to Send Email";
    default:
      return "Send Recovery Email";
  }
};

interface PasswordHelpProps {
  status?: AuthRequestStatus;
  onRequestReset: (args: API.RequestPasswordResetArgs) => Promise<void>;
  onReturnToLogin: () => void;
  logo?: LogoImgProps;
}

const PasswordHelp: FC<PasswordHelpProps> = ({
  status,
  onRequestReset,
  onReturnToLogin,
  logo,
}) => {
  return (
    <div className={styles.passwordHelp}>
      <Logo logo={logo} />
      <span className={styles.title}>Need Help Logging In?</span>
      <span className={styles.message}>
        We&apos;re here for you. Enter your email and we&apos;ll send
        instructions on how to reset your password. If you don&apos;t know your
        account email or don&apos;t have access, contact your administrator.
      </span>
      <Formik
        initialValues={{
          email: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={helpSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await onRequestReset(values);
          setSubmitting(false);
        }}
      >
        {({ errors, handleChange, isSubmitting }) => (
          <Form>
            <TextInput
              name="email"
              label="Email Address"
              placeholder="Email Address"
              error={errors["email"]}
              onChange={handleChange}
              assistiveText="Your account email is the address you used to sign up initially"
              qa={`${Components.PasswordHelp}-${ElementType.TextInput}-email`}
            />
            <AuthAnimatedButton
              qa={`${Components.PasswordHelp}-${ElementType.Button}-submit`}
              className={`${styles.submitButton} ${status}`}
              submitStatus={status}
              onComplete={onReturnToLogin}
            >
              {buttonText(status)}
            </AuthAnimatedButton>
          </Form>
        )}
      </Formik>
      <Link
        className={styles.returnLink}
        to="/login"
        qa={`${Components.PasswordHelp}-${ElementType.Link}-returnToLogin`}
      >
        Return to login
      </Link>
    </div>
  );
};

PasswordHelp.displayName = "PasswordHelp";
export default PasswordHelp;
