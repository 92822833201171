/**
 * Gets the current API hostname from window location
 *
 * PROD URLs
 * - web: https://<client>.powerfields.io
 * - api: https://<client>.api.powerfields.io
 *
 * NON-PROD URLs
 * - web: https://<client>.<env>.powerfields-dev.io
 * - api: https://<client>.api.<evn>.powerfields-dev.io
 */
export const getApiHost = (): string => {
  // default to FF dev if working locally
  if (window.location.hostname.indexOf("localhost") >= 0) {
    return "https://rts.api.dev.field1st-test.com";
    // return "http://localhost:8080";
  }

  const hostArr = window.location.hostname.split(".");
  const client = hostArr[0];
  let env;
  let pfenv;
  // production
  if (hostArr.length === 3) {
    pfenv = `${hostArr[1]}.${hostArr[2]}`;
    // lower environments
  } else if (hostArr.length === 4) {
    env = hostArr[1];
    pfenv = `${hostArr[2]}.${hostArr[3]}`;
  }
  // generate string
  return `https://${client}.api.${(env && env + ".") || ""}${pfenv}`;
};
