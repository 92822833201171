import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { Icon } from "shared/src/components/Icon/Icon";
import { DocumentDrawerSection } from "../DocumentDrawer";
import { ThemeTypes } from "shared/src/themes/colors";
import { remCalc } from "shared/src/themes/helpers";
import { Components, ElementType } from "shared/src/qa-slugs";

const ActionBarContainer = styled.div.attrs({ className: "p-2 d-flex" })`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-size: ${remCalc(12)};

  > a {
    border-right: solid 1px ${({ theme }) => theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    cursor: pointer;
    &:last-child {
      border-right: none;
    }
  }
`;

interface WhiteIconProps {
  theme: ThemeTypes;
  type: string;
}

const WhiteIcon = styled<React.FC<WhiteIconProps>>(({ type }) => {
  const theme = useContext(ThemeContext);
  return <Icon className="d-block" type={type} color={theme.colors.white} />;
})``;

interface ActionBarProps {
  setShowing: (bool) => void;
}

const ActionBar = ({ setShowing }: ActionBarProps) => {
  return (
    <ActionBarContainer style={{ marginLeft: "-10px", marginRight: "-10px" }}>
      <a
        data-testid={`${Components.DashboardDocumentDrawer}-${ElementType.Button}-viewDocument`}
        className="flex-grow-1"
        onClick={() => setShowing(DocumentDrawerSection.Document)}
      >
        <WhiteIcon type="document" />
        View Document
      </a>
      <a
        data-testid={`${Components.DashboardDocumentDrawer}-${ElementType.Button}-documentHistory`}
        className="flex-grow-1"
        onClick={() => setShowing(DocumentDrawerSection.History)}
      >
        <WhiteIcon type="documents" />
        Document History
      </a>
      <a
        data-testid={`${Components.DashboardDocumentDrawer}-${ElementType.Button}-shareDocument`}
        className="flex-grow-1"
        onClick={() => setShowing(DocumentDrawerSection.Share)}
      >
        <WhiteIcon type="icon-share" />
        Share
      </a>
    </ActionBarContainer>
  );
};

export default ActionBar;
