import React, { FC } from "react";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import Toast, { ToastProps } from "./Toast";

type WarningToastProps = Omit<ToastProps, "backgroundColor" | "color">;

/**
 * @deprecated Use react-toastify and Toastify.tsx instead.
 * @see https://fkhadra.github.io/react-toastify/introduction
 */
export const WarningToast: FC<WarningToastProps> = (toastProps) => {
  return (
    <Toast
      backgroundColor={scssVariables.warningFill}
      color={scssVariables.warning}
      {...toastProps}
    />
  );
};
