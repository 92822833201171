import React from "react";
import { useNavigate } from "react-router-dom";
import { API, ResourceDTO, ResourceStatus } from "@rtslabs/field1st-fe-common";

import * as S from "./styles";
import { Components, ElementType } from "../../../qa-slugs";
import { ActionMenu } from "../../common/ActionMenu";
import { useTranslation } from "react-i18next";

interface ResourceActionMenuProps {
  resource: ResourceDTO;
  onToggleResourceStatus: (resourceId: number, status: ResourceStatus) => void;
}

const ResourceActionMenu = ({
  resource,
  onToggleResourceStatus,
}: ResourceActionMenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleUpdateResourceStatus = async () => {
    // "DRAFT" is another possible status but we're not handling it yet (and there's no way for a user to create a draft at this time)
    const status = resource.status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED";
    try {
      await API.updateResourceStatus({ id: resource.id, status });
      onToggleResourceStatus(resource.id, status);
    } catch (e) {
      console.error(e); // todo handle
    }
  };

  const handleDuplicateResource = async () => {
    try {
      const res = await API.duplicateResource({ id: resource.id });
      navigate(`/content/resources/${res.id}`);
    } catch (e) {
      console.error(e); // todo handle
    }
  };

  return (
    <ActionMenu
      buttonLabel={t("common:table.rowMenuButtonLabel")}
      qaBase={`${Components.ResourceActionMenu}-${resource.id}`}
      closeOnClick={true}
    >
      <S.PopperRow
        data-testid={`${Components.ResourceActionMenu}-${ElementType.Link}-${resource.id}_edit`}
        onClick={() => navigate(`/content/resources/${resource.id}`)}
      >
        <span>Edit Resource</span>
        <S.PopperChevron />
      </S.PopperRow>
      <S.PopperRow
        data-testid={`${Components.ResourceActionMenu}-${ElementType.Link}-${resource.id}_duplicate`}
        onClick={handleDuplicateResource}
      >
        <span>Duplicate</span>
        <S.PopperChevron />
      </S.PopperRow>
      <S.PopperRow
        data-testid={`${Components.ResourceActionMenu}-${ElementType.Link}-${
          resource.id
        }_${resource.status === "ARCHIVED" ? "activate" : "archive"}`}
        error={true}
        onClick={handleUpdateResourceStatus}
      >
        <span>
          {resource.status === "ARCHIVED" ? "Activate" : "Archive"} Resource
        </span>
        <S.PopperChevron />
      </S.PopperRow>
    </ActionMenu>
  );
};

export default ResourceActionMenu;
