import React, { FC } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { useKeyboardFocus } from "../../util/hooks/useKeyboardFocus";
import styles from "./Ratings.module.scss";

interface Props {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name?: string;
  value?: string | number;
}

export const RatingButton: FC<Props> = ({
  checked,
  className,
  disabled,
  error,
  onChange: handleChange,
  label,
  name,
  value,
}) => {
  const { isKeyboardFocused, ...eventHandlers } = useKeyboardFocus();

  return (
    <div className={joinClassNames(styles.rating, className)}>
      <label
        className={joinClassNames(styles.label, checked && styles.checked)}
      >
        <input
          checked={checked}
          className={joinClassNames(styles.input, !!error && styles.error)}
          disabled={disabled}
          name={name}
          onChange={handleChange}
          type="radio"
          value={value}
          {...eventHandlers}
        />
        <span
          className={joinClassNames(
            styles.text,
            !!error && styles.error,
            disabled && styles.disabled
          )}
        >
          {label}
        </span>
      </label>
    </div>
  );
};
