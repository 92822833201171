import React, { FC } from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import styles from "./documentForm.module.scss";

interface Props {
  title: string;
  className?: string;
}

export const FormSectionTitle: FC<Props> = ({ title, className }) => (
  <h3 className={joinClassNames(styles.title, className)}>{title}</h3>
);
