import React, { FC } from "react";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Icon } from "shared/src/components/Icon/Icon";
import s from "./styles.module.scss";
import { QAProps } from "shared/src/qa-slugs";

type DSType = "uploaded" | "api" | "basic";

interface Props extends QAProps {
  icon: string;
  label: string;
  onSelect: (type: DSType) => void;
  selectedType: DSType | null;
  type: DSType;
}

export const DSTypeOption: FC<Props> = ({
  icon,
  label,
  onSelect,
  qa,
  selectedType,
  type,
}) => {
  const handleClick = () => onSelect(type);

  return (
    <button
      aria-pressed={type === selectedType}
      className={joinClassNames(
        s.toggleButton,
        type === selectedType && s.toggleButtonSelected
      )}
      data-testid={qa}
      onClick={handleClick}
      type="button"
    >
      <Icon
        className={joinClassNames(
          s.toggleButtonIcon,
          type === selectedType && s.toggleButtonIconSelected
        )}
        size={80}
        type={icon}
      />
      <span>{label}</span>
    </button>
  );
};
