import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../../../store";
import { Action } from "redux";
import { CoreRedux } from "@rtslabs/field1st-fe-common";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const LogoutLink = styled.span`
  color: steelblue; // Don't theme this yet -- GK
  cursor: pointer;
`;

interface StateType {
  error: string | null;
  errorInfo: string | null;
}

interface Props {
  logout: () => void;
  children: React.ReactElement;
}

// TODO refactor to functional component to use logout hooks once componentDidCatch equiv becomes available -JA
class ErrorBoundary extends React.Component<Props, StateType> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Wrapper>
          <h2>
            Something went wrong. Click&nbsp;
            <LogoutLink
              onClick={() => {
                this.props.logout();
                window.location.href = "/";
              }}
            >
              here&nbsp;
            </LogoutLink>
            to logout and refresh.
          </h2>
        </Wrapper>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, Action>
) => ({
  logout: () => dispatch(CoreRedux.logout()),
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
