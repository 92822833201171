import Popover from "@mui/material/Popover";
import { isAfterDate, isBeforeDate } from "./dateRangeFunctions";
import moment, { Moment } from "moment";
import React, { FC, useRef, useState } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { DatePicker } from "../DatePicker/DatePicker";
import { DateButton } from "./DateButton";
import styles from "./DateRange.module.scss";
import { QAProps } from "../../qa-slugs";
import Label from "../Label/Label";
import { DateRangeValue } from "../../data/timeFilters";

export interface DateRangeValueAllowBlankEndDate {
  endDate: Moment | null;
  startDate: Moment;
}

type LabelType = {
  text: string;
  className?: string;
};

interface HandleChangeAllowBlankEndDate extends DateRangePropsBasic {
  handleChange: (range: DateRangeValueAllowBlankEndDate) => void;
  allowBlankEndDate: true;
}

interface HandleChangePropsStandard extends DateRangePropsBasic {
  handleChange: (range: DateRangeValue) => void;
  allowBlankEndDate?: false;
}
export interface DateRangePropsBasic {
  className?: string;
  labelId: string;
  name: string;
  // Labels that appear on top of each date field
  labels?: {
    labelFrom: LabelType;
    labelTo: LabelType;
  };
  // Initial values
  initialValues?: {
    from?: Moment;
    to?: Moment;
  };
  onClear?: () => void;
  qa?: QAProps["qa"];
  variant?: string;
  maxEndDate?: Moment;
  disabled?: boolean;
}

export type DateRangeProps =
  | HandleChangePropsStandard
  | HandleChangeAllowBlankEndDate;

export const DateRange: FC<DateRangeProps> = ({
  className,
  handleChange,
  initialValues,
  labelId,
  labels,
  name,
  variant,
  onClear,
  qa,
  maxEndDate,
  disabled,
  allowBlankEndDate,
}) => {
  // Initial values
  const initialFrom =
    (initialValues?.from && moment(initialValues.from)) || null;
  const initialTo = (initialValues?.to && moment(initialValues.to)) || null;

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [isChoiceQueued, setIsChoiceQueued] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState<Moment | null>(
    initialTo
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Moment | null>(
    initialFrom
  );
  const [shouldHideAutoFocus, setShouldHideAutoFocus] = useState(false);
  const endButton = useRef<HTMLButtonElement | null>(null);
  const startButton = useRef<HTMLButtonElement | null>(null);

  const closePopover = () => {
    setAnchor(null);
    setIsPopoverOpen(false);
  };

  const selectSide = (shouldSelectEndDate: boolean) => {
    setIsSelectingEndDate(shouldSelectEndDate);
    setAnchor(shouldSelectEndDate ? endButton.current : startButton.current);
  };

  const openPopover = (newAnchor: HTMLButtonElement | null) => {
    setAnchor(newAnchor);
    setIsPopoverOpen((priorIsPopoverOpen) => !priorIsPopoverOpen);
    setIsChoiceQueued(true);
  };

  const handleClickStartDate = (
    anchor: HTMLButtonElement | null,
    isKeyboardFocused: boolean
  ) => {
    openPopover(anchor);
    setIsSelectingEndDate(false);
    setShouldHideAutoFocus(!isKeyboardFocused);
  };

  const handleClickEndDate = (
    anchor: HTMLButtonElement | null,
    isKeyboardFocused: boolean
  ) => {
    openPopover(anchor);
    setIsSelectingEndDate(true);
    setShouldHideAutoFocus(!isKeyboardFocused);
  };

  const handleSelectionChange = (date: Moment) => {
    let shouldPreventClose = false;
    let startDate = selectedStartDate || moment();
    let endDate = selectedEndDate || moment();
    let endDateSelected = !!selectedEndDate;

    if (isSelectingEndDate) {
      if (isBeforeDate(date, selectedStartDate)) {
        startDate = date;
        endDate = date;
        selectSide(true);
        shouldPreventClose = !isChoiceQueued;
      } else {
        endDate = date;
        selectSide(false);
      }
      endDateSelected = true;
    } else {
      if (isAfterDate(date, selectedEndDate)) {
        startDate = date;
        endDate = date;
        selectSide(true);
        shouldPreventClose = !isChoiceQueued;
      } else {
        startDate = date;
        selectSide(true);
      }
    }

    endDate = endDate.endOf("day");
    const endDateValue = !endDateSelected && allowBlankEndDate ? null : endDate;
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDateValue);
    allowBlankEndDate && handleChange({ startDate, endDate: endDateValue });
    !allowBlankEndDate && handleChange({ startDate, endDate });

    if (isChoiceQueued || shouldPreventClose) {
      setIsChoiceQueued(false);
    } else {
      closePopover();
    }
  };

  const showLabels = !!labels; // If labels prop found

  return (
    <>
      <div
        className={joinClassNames(styles.container, className)}
        data-testid={qa} // Tagging parent, you can use selectors to get children
      >
        <div
          className={joinClassNames(
            styles.startDate,
            variant === "column" && styles.column
          )}
        >
          {showLabels && (
            <Label htmlFor={labelId}>{labels?.labelFrom.text}</Label>
          )}
          <DateButton
            handleClick={handleClickStartDate}
            isActive={!isSelectingEndDate && isPopoverOpen}
            label="Start date"
            labelId={labelId}
            name={`${name}-startDate`}
            ref={startButton}
            selectedDate={selectedStartDate}
            disabled={disabled}
          />
        </div>
        {!showLabels && <span className={styles.rangeSeparator}>to</span>}
        <div className={variant === "column" ? styles.column : ""}>
          {showLabels && (
            <Label htmlFor={labelId}>{labels?.labelTo.text}</Label>
          )}
          <DateButton
            handleClick={handleClickEndDate}
            isActive={isSelectingEndDate && isPopoverOpen}
            label="End date"
            labelId={labelId}
            name={`${name}-endDate`}
            ref={endButton}
            selectedDate={selectedEndDate}
            disabled={disabled}
          />
        </div>
        {onClear && (
          <div
            className={styles.clearButton}
            onClick={() => {
              allowBlankEndDate && setSelectedEndDate(null);
              onClear();
            }}
          >
            Clear
          </div>
        )}
      </div>
      <Popover
        anchorEl={anchor}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={closePopover}
        open={isPopoverOpen}
        PaperProps={{ variant: "outlined" }}
      >
        <DatePicker
          handleSelectionChange={handleSelectionChange}
          isAutoFocusDisabled={!isPopoverOpen}
          isSelectingEndDate={isSelectingEndDate}
          maxDate={(isSelectingEndDate && maxEndDate) || undefined}
          name={name}
          selectedEndDate={selectedEndDate}
          selectedStartDate={selectedStartDate}
          shouldHideAutoFocus={shouldHideAutoFocus}
        />
      </Popover>
    </>
  );
};
