import { useEffect, useState } from "react";
import {
  API,
  PaginatedResponse,
  DataSourceValueDTO,
} from "@rtslabs/field1st-fe-common";
import useDebounce from "./useDebounce";

interface Return<T> {
  dataSourceValues: DataSourceValueDTO<T>[];
  setQuery: (q: string) => void;
  loading: boolean;
  loadingMore: boolean;
  error: string | undefined;
  lastPage: boolean;
  onLoadMore: () => void;
}

export function useDataSourceValues<T = unknown>(
  dataSourceKey: string,
  sortBy: string
): Return<T> {
  const [dsv, setDsv] = useState<DataSourceValueDTO<T>[]>([]);
  const [page, setPage] = useState<number>(0);
  const [query, setQuery] = useState<string>("");
  const [last, setLast] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [searched, setSearched] = useState<boolean>(false);

  const size = 100;

  function updateState(
    apiRes: PaginatedResponse<DataSourceValueDTO<T>>,
    append = false
  ) {
    if (apiRes.content) {
      setLast(apiRes.last);
      setDsv((currDsv) => {
        const prevItems = append ? currDsv : [];
        return [...prevItems, ...apiRes.content];
      });
    } else {
      setError("Error fetching data sources");
    }
  }

  async function getDsv(q: string, append = false) {
    if (dataSourceKey) {
      const newPage = append ? page + 1 : 0;
      const currQuery = q && q.length > 2 ? q : "";
      setPage(newPage);
      if (dsv.length === 0 || currQuery.length > 2 || searched) {
        const params: API.GetDataSourceValuesArgs = {
          dataSourceKey,
          sort: sortBy,
          page: newPage,
          query: currQuery,
          size,
        };
        append ? setLoadingMore(true) : setLoading(true);
        const dsv = await API.getDataSourceValues<T>(params);
        currQuery.length > 2 ? setSearched(true) : setSearched(false);
        append ? setLoadingMore(false) : setLoading(false);
        updateState(dsv, append);
      }
    }
  }

  const debouncedGet = useDebounce({
    method: (q: string) => getDsv(q),
  });

  useEffect(() => {
    debouncedGet(query);
  }, [query]);

  return {
    dataSourceValues: dsv,
    setQuery,
    loading,
    loadingMore,
    error,
    lastPage: last,
    onLoadMore: () => getDsv(query, true),
  };
}
