import { API, FormTypeDTO } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { serializeError } from "serialize-error";
import Loader from "shared/src/components/Loader/Loader";
import { ErrorToast } from "shared/src/components/Toast/ErrorToast";
import Breadcrumbs from "../../../../../shared/src/components/Breadcrumbs/Breadcrumbs";
import styles from "./FormTypes.module.scss";
import { loadFormType } from "./helpers";
import { FormTypeForm } from "./modals/FormTypeForm";

interface Params extends Record<string, string> {
  id: string;
}

interface Props {
  readOnly?: boolean;
}

const ViewEditFormType: FC<Props> = ({ readOnly }) => {
  const params = useParams<Params>();
  const formTypeId = parseInt(params.id!);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFormType, setLoadingFormType] = useState(false);
  const [formType, setFormType] = useState<FormTypeDTO | undefined>();
  const [fetchError, setFetchError] = useState<"save" | "load" | "">("");

  const navigate = useNavigate();

  async function handleSubmit(values: FormTypeDTO) {
    try {
      setLoading(true);
      await API.updateFormType({ formType: values });
      setLoading(false);
      navigate(`/forms/form-types/?success=edit`);
    } catch (err) {
      const errorString = JSON.stringify(serializeError(err));
      console.error(errorString);
      setFetchError("save");
      setLoading(false);
    }
  }

  useEffect(() => {
    if (
      (!formType || formTypeId !== formType.id) &&
      !loadingFormType &&
      !fetchError
    ) {
      setLoadingFormType(true);
      loadFormType(formTypeId, setLoadingFormType, setFormType, setFetchError);
    }
  }, [formTypeId, loadingFormType, formType]);

  function dismissErrorToast() {
    setFetchError("");
  }

  return (
    <>
      <ErrorToast
        visible={!!fetchError}
        onDelay={dismissErrorToast}
        onDelayTime={5000}
        onClick={dismissErrorToast}
      >
        The system encountered an error while trying to {fetchError} the form
        type. Please try again.
      </ErrorToast>
      <Loader loading={loadingFormType}>
        {formType && (
          <>
            <Breadcrumbs
              paths={[
                {
                  pathName: "Forms",
                },
                {
                  pathName: "Form Types",
                  href: "/forms/form-types",
                },
                {
                  pathName: `Form Type: ${formType.name || ""}`,
                  href: `/forms/form-types/${formType.id}/${
                    readOnly ? "view" : "edit"
                  }`,
                },
              ]}
            />
            <h1 className={styles.pageTitle}>
              {readOnly ? "View" : "Edit"} Form Type
            </h1>
            <FormTypeForm
              readOnly={!!readOnly}
              fullPage
              loading={loading}
              initialValues={formType}
              onSubmit={handleSubmit}
              onClose={() => {
                navigate("/forms/form-types");
              }}
              saveButtonLabel="update"
            />
          </>
        )}
      </Loader>
    </>
  );
};
export default ViewEditFormType;
