import styled from "styled-components";

const StyledCloseIcon = styled.i.attrs({
  className: "icon-icons8-delete_sign",
})`
  border-radius: 50%;
  color: ${(props) => props.theme.masterColors.mediumGrey};
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  padding: 5px;
`;

export { StyledCloseIcon };
