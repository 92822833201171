import React, { PropsWithChildren } from "react";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { ElementType } from "../../../qa-slugs";
import styles from "./TableCell.module.scss";

interface TableCellProps {
  className?: string;
  emptyPlaceholder?: string;
  colSpan?: number;
  qa?: string | null;
}

export const TableCell = ({
  children,
  className,
  emptyPlaceholder = "--",
  colSpan,
  qa,
}: PropsWithChildren<TableCellProps>) => {
  return (
    <td
      data-testid={qa ? `${ElementType.TableCell}-${qa}` : undefined}
      colSpan={colSpan}
      className={joinClassNames(styles.td, className)}
    >
      {children || emptyPlaceholder}
    </td>
  );
};
