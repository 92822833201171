import React from "react";
import moment from "moment";

import * as S from "../styles";
import Loader from "shared/src/components/Loader/Loader";
import {
  CancelButton,
  SubmitButton,
} from "shared/src/components/clientAdmin/styles";
import { LoaderWrapper } from "../../addUser/styles";

interface ResendVerificationEmailConfirmation {
  // user data
  email?: string | null;
  firstName?: string | null;
  groupNames?: string[] | null;
  lastLogin?: string | null;
  lastName?: string | null;
  // status?: "ACTIVE" | "DISABLED" | "NOT_ACTIVATED";

  loading?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

// Content only
export const ResendVerificationEmailConfirmation = ({
  // User data
  email = "",
  firstName = "",
  groupNames = [],
  // status,
  lastLogin = "",
  lastName = "",

  loading,
  onCancel,
  onSubmit,
}: ResendVerificationEmailConfirmation) => {
  return (
    <S.ModalPromptWrapper>
      <S.ModalPromptTitle>
        Would you like to send an Activation email?
      </S.ModalPromptTitle>
      <S.ModalPromptSubtitle>
        You are about to send an Activation email to this user:
      </S.ModalPromptSubtitle>
      <div className="d-flex flex-row">
        <S.DisableUserDataItem>
          <S.DataItemLabel>USER NAME</S.DataItemLabel>
          <S.DataItemText>
            {firstName} {lastName}
          </S.DataItemText>
          <S.DataItemEmail>{email}</S.DataItemEmail>
        </S.DisableUserDataItem>
        <S.DisableUserDataItem>
          <S.DataItemLabel>GROUP</S.DataItemLabel>
          {groupNames?.map((e) => (
            <S.DataItemText key={e}>{e}</S.DataItemText>
          ))}
        </S.DisableUserDataItem>
        <S.DisableUserDataItem>
          <S.DataItemLabel>LAST LOGIN</S.DataItemLabel>
          <S.DataItemText>
            {lastLogin && moment(lastLogin).format("MM/DD/YY")}
          </S.DataItemText>
        </S.DisableUserDataItem>
      </div>
      <S.DisableUserWarningLabel margin="32.5px 0 0 0">
        Continuing with this action will trigger an email to be sent to the
        selected user. This email explains the authentication process and will
        help validate the user. The user should then follow the instructions
        provided to establish credentials to be used to access the system.
      </S.DisableUserWarningLabel>
      <S.DisableUserButtonsWrapper margin="24px 0 0 0">
        <CancelButton disabled={false} onClick={onCancel}>
          cancel
        </CancelButton>
        <SubmitButton disabled={false} onClick={onSubmit}>
          <LoaderWrapper>
            <Loader
              loading={loading}
              className="p-0 mr-3"
              spinnerProps={{ size: 14 }}
            />
          </LoaderWrapper>
          <span>send email </span>
        </SubmitButton>
      </S.DisableUserButtonsWrapper>
      <S.ModalPromptCloseIcon onClick={onCancel} />
    </S.ModalPromptWrapper>
  );
};
