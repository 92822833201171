import { API, useAPI } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useState } from "react";
import SearchBar from "shared/src/components/SearchBar/SearchBar";
import { FilterSelect } from "shared/src/components/Select/FilterSelect";
import { DateRangeValue, TimeFilter } from "shared/src/data/timeFilters";
import { Components, ElementType } from "shared/src/qa-slugs";
import { timeFilterOptions } from "shared/src/data/timeFilters";
import { generateGroupOptions } from "shared/src/components/clientAdmin/helpers";
import * as S from "./styles";
import { mockRoleOptions, statusOptions } from "./usersConstants";

export interface FilterChangeProps {
  dateRange: DateRangeValue | undefined;
  group: number | undefined;
  role: number | undefined;
  status: number | undefined;
  searchQuery: string;
  timeFilter?: TimeFilter;
}
interface UsersFiltersProps {
  groupParams: number;
  onFilterChange: (props: FilterChangeProps) => void;
}

export const UsersFilters: FC<UsersFiltersProps> = ({
  groupParams,
  onFilterChange,
}) => {
  // Filters
  const [dateRange, setDateRange] = useState<DateRangeValue>();
  const [group, setGroup] = useState<number | undefined>(
    groupParams || undefined
  );
  const [role, setRole] = useState<number>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [status, setStatus] = useState<number>();
  const [timeFilter, setTimeFilter] = useState<TimeFilter>();

  const allGroupCall = useAPI(API.getClientGroups, {
    size: 200,
    sort: "name,asc",
  });

  useEffect(() => {
    onFilterChange({
      dateRange,
      group,
      role,
      searchQuery,
      status,
      timeFilter,
    });
  }, [dateRange, group, role, status, searchQuery, timeFilter]);

  return (
    <>
      <div className="col-12 d-flex flex-row justify-content-start align-items-center mt-2">
        <div className="mr-2">
          <FilterSelect
            qa={`${Components.Users}-${ElementType.SelectInput}-groupsFilter`}
            name="groups"
            value={group}
            label=""
            placeholder="All Groups"
            options={[
              { value: 0, label: "All Groups" },
              ...generateGroupOptions(allGroupCall.data?.content),
            ]}
            onChange={(option) => setGroup(option?.value)}
          />
        </div>
        <div className="ml-2 mr-2">
          <FilterSelect
            qa={`${Components.Users}-${ElementType.SelectInput}-rolesFilter`}
            name="roles"
            value={role}
            label=""
            placeholder="All Roles"
            options={mockRoleOptions}
            onChange={(option) => setRole(option?.value)}
          />
        </div>
        <div className="ml-2 mr-2">
          <FilterSelect
            qa={`${Components.Users}-${ElementType.SelectInput}-statusesFilter`}
            name="statuses"
            value={status}
            label=""
            placeholder="All Statuses"
            options={statusOptions}
            onChange={(option) => setStatus(option?.value)}
          />
        </div>
        <div className="ml-2 mr-2">
          <FilterSelect
            qa={`${Components.Users}-${ElementType.SelectInput}-timeFilter`}
            name="timeFilter"
            value={timeFilter}
            label=""
            placeholder="All Time"
            options={timeFilterOptions}
            onChange={(option) =>
              setTimeFilter(option?.value || TimeFilter.ALL_TIME)
            }
          />
        </div>

        <div style={{ width: "398px" }}>
          <SearchBar
            qa={`${Components.Users}-${ElementType.TextInput}-searchBar`}
            onSearch={setSearchQuery}
          />
        </div>
      </div>
      {timeFilter === "CUSTOM_RANGE" && (
        <S.DateRangeRow>
          <span className="visually-hidden" id="custom-range-label">
            Custom Range
          </span>
          <S.DateRange
            handleChange={setDateRange}
            labelId="custom-range-label"
            name="customRange"
            onClear={() => {
              setDateRange(undefined);
              setTimeFilter(TimeFilter.ALL_TIME);
            }}
          />
        </S.DateRangeRow>
      )}
    </>
  );
};
