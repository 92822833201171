import React, { useCallback, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import * as S from "./styles";
import ActionBar from "./ActionBar";
import Drawer from "../../../../../common/Drawer";
import Header from "shared/src/components/common/Drawer/components/Header";
import Loader from "shared/src/components/Loader/Loader";
import { API, roles } from "@rtslabs/field1st-fe-common";
import downloadFileFromBlob from "downloadjs";

const QuestionExpansionPanel = ({
  comments,
  expanded,
  handleExpansionChange,
  id,
  title,
  onUserNameClick,
}) => (
  <S.Accordion
    disabled={!comments.length}
    expanded={expanded}
    elevation={0}
    onChange={handleExpansionChange}
  >
    <S.AccordionSummary
      expandIcon={<S.SortDown />}
      aria-controls={`panel-${id}-content`}
      id={`panel-${id}-header`}
    >
      <S.TitleText>{title}</S.TitleText>
      <S.DetailsText>
        <S.Message /> {comments.length} Comments
      </S.DetailsText>
    </S.AccordionSummary>
    <S.AccordionDetails>
      {comments.map(
        ({
          comments,
          firstName,
          lastName,
          lastModifiedDate,
          participantId,
        }) => (
          <QuestionExpansionPanelDetails
            comment={comments}
            date={lastModifiedDate}
            key={lastModifiedDate}
            name={`${firstName} ${lastName}`}
            onUserNameClick={() => onUserNameClick(participantId)}
          />
        )
      )}
    </S.AccordionDetails>
  </S.Accordion>
);

const QuestionExpansionPanelDetails = ({
  name,
  date,
  comment,
  onUserNameClick,
}) => (
  <S.PanelDetailsContainer>
    <S.LinkText onClick={() => onUserNameClick()}>{name}</S.LinkText>
    <S.DateText>{moment(date).format("MM/DD/YYYY")}</S.DateText>
    <S.CommentText>{comment}</S.CommentText>
  </S.PanelDetailsContainer>
);

type DrawerProps = {
  closeDrawer: () => void;
  handleExpansionChange: (id: number) => (event: React.ChangeEvent<{}>) => void;
  headerText: JSX.Element;
  responsesLoaded: boolean;
  responseComments: {
    comments: any;
    id: number;
    title: string;
  }[];
  selectedQuestionIds: number[];
  show: boolean;
  formId: number;
};

const SafetyResponseCommentsDrawer = ({
  closeDrawer,
  handleExpansionChange,
  headerText,
  responseComments,
  responsesLoaded,
  selectedQuestionIds,
  show,
  formId,
}: DrawerProps) => {
  const navigate = useNavigate();

  // is admin?
  const userIsAdmin = API.Environment.hasRoleAccess([
    roles.ROLE_CLIENT_ADMIN,
    roles.ROLE_CLIENT_REVIEWER,
  ]);

  // On user name click
  const onUserNameClick = useCallback((id) => {
    if (id && userIsAdmin) {
      navigate(`/people/users/view/${id}`);
    }
  }, []);

  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const handleExport = () => {
    setExportLoading(true);
    const mediaType = "text/csv";
    API.downloadQuestionSelectionComments({
      formId,
      questionSelectionIds: selectedQuestionIds,
      mediaType,
    })
      .then((res) => downloadFileFromBlob(res.blob, res.fileName, mediaType))
      .finally(() => setExportLoading(false));
  };
  return (
    <Drawer
      showCloseIcon={false}
      content={
        <div style={{ width: "30vw" }}>
          <Loader loading={!responsesLoaded} />
          <Header
            className="d-flex justify-content-between px-4 pt-3 pb-3"
            closeDrawer={closeDrawer}
            text={headerText}
          />
          <ActionBar
            handleExport={handleExport}
            handlePrint={() => window.print()}
            handleShare={() => undefined}
            loading={{ export: exportLoading }}
          />
          {responseComments.map((r) => (
            <QuestionExpansionPanel
              comments={r.comments}
              expanded={selectedQuestionIds.includes(r.id)}
              handleExpansionChange={handleExpansionChange(r.id)}
              id={r.id}
              key={r.id}
              onUserNameClick={userIsAdmin ? onUserNameClick : undefined}
              title={r.title}
            />
          ))}
        </div>
      }
      anchor="right"
      id="documentDrawer"
      onClose={closeDrawer}
      onOpen={() => undefined}
      open={show}
      overrideClasses={{ content: "commentDrawerStyles" }}
    />
  );
};

export default SafetyResponseCommentsDrawer;
