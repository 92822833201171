import React, { JSXElementConstructor } from "react";
import { useFormikContext } from "formik";

import { AppWidgetProps, FBForm, FBItem, FBSection } from "../../types";
import { ItemParams, ItemsMutator, SectionsMutator } from "./Create";
import { Placeholder } from "./content/Placeholder";
import { scrollToElement } from "../helpers";
import bs from "../styles.module.scss";
import PublishedBanner from "./PublishedBanner";
import Section from "./content/Section";
import ValidationBanner from "./validation/ValidationBanner";
import DeactivatedBanner from "./DeactivatedBanner";

interface Props {
  currentItem: FBItem | FBSection | null;
  removeItem: ItemsMutator;
  removeSection: SectionsMutator;
  setCurrentItem: (item: FBItem | FBSection) => void;
  onUpdateItem: (
    item: FBItem | FBSection,
    index?: number
  ) => Promise<FBForm | undefined>;
  appWidgets: JSXElementConstructor<AppWidgetProps>;
  appWidgetsList: ItemParams[];
}

function Content({
  currentItem,
  removeItem,
  removeSection,
  setCurrentItem,
  onUpdateItem,
  appWidgets,
  appWidgetsList,
}: Props) {
  const { values } = useFormikContext<FBForm>();

  /**
   * Swap the position of two items in an array
   * @param item  - item to shift
   * @param pos   - current position of item
   * @param shift - shift direction (e.g. 1 or -1)
   */
  function shiftItems(item: FBItem | FBSection, pos: number, shift: number) {
    onUpdateItem(item, pos + shift);
  }

  /**
   * Increments the index at which an item is positioned in an array
   * @param item    - item to shift
   * @param pos     - item's current index
   * @param arrLen  - length of item's containing array
   */
  function incrementItemIndex(
    item: FBItem | FBSection,
    pos: number,
    arrLen: number
  ): void {
    if (pos >= arrLen - 1) {
      return;
    }
    shiftItems(item, pos, 1);
  }

  /**
   * Decrement the index at which an item is positioned in an array
   * @param item  - item to shift
   * @param pos   - item's current index
   */
  function decrementItemIndex(item: FBItem | FBSection, pos: number): void {
    if (pos <= 0) {
      return;
    }
    shiftItems(item, pos, -1);
  }

  const visibleSections = values.sections.filter(
    (section) => !section.parentWidgetRootId
  );

  return (
    <div className={bs.content}>
      <div className={bs.contentContainer}>
        <PublishedBanner visible={values.workflowType === "FINAL"} />
        <DeactivatedBanner visible={values.workflowType === "DEACTIVATED"} />
        <ValidationBanner
          errorType="create"
          onJumpToError={(errorKey, errorItem) => {
            if (errorItem) {
              setCurrentItem(errorItem);
              scrollToElement(errorItem.id!.toString());
            }
          }}
        />
        {visibleSections.length > 0 ? (
          visibleSections.map((s, i) => (
            <Section
              key={`section_${i + 1}_${s.title}`}
              currentItem={currentItem}
              incrementItemIndex={incrementItemIndex}
              decrementItemIndex={decrementItemIndex}
              position={i}
              removeItem={removeItem}
              removeSection={removeSection}
              section={s}
              setCurrentItem={setCurrentItem}
              siblings={visibleSections}
              appWidgets={appWidgets}
              appWidgetsList={appWidgetsList}
            />
          ))
        ) : (
          <Placeholder />
        )}
      </div>
    </div>
  );
}

export default Content;
