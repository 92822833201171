import React, { Dispatch, FC, SetStateAction } from "react";
import MediaQuery from "react-responsive";
import styles from "shared/src/components/Documents/Documents.module.scss";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import {
  API,
  DocumentRequestParameters,
  DocumentSummaryVm,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";

import MyDocuments from "./components/MyDocuments";
import RecentDocuments from "./components/RecentDocuments/RecentDocuments";
import { DocumentScreenTerms } from "./types";
import { Components, Page } from "shared/src/qa-slugs";
import { AppliedFiltersProps } from "./components/types";

interface DocumentsProps {
  documents: DocumentSummaryVm[];
  formTypes: FormTypeDTO[];
  //   mobileFilterStatsLength?: number;
  onFilterChange: (params: Partial<DocumentRequestParameters>) => void;
  onSearch: (query?: string) => void;
  query?: string;
  filters: API.GetDocumentsArgs;
  onLoadMoreDocuments: () => void;
  isLoadingSearchInfinite: boolean;
  isSearchLoading: boolean;
  lastPageSearch: boolean;
  searchDocumentsError: boolean;
  onOpenDocumentDrawer: (selectedDocument: DocumentSummaryVm) => void;
  onOpenNewDocumentDrawer: () => void;
  // White label
  terms: DocumentScreenTerms;
  onOpenFilterDrawer: () => void;
  appliedFilters: AppliedFiltersProps;
  setAppliedFilters: Dispatch<SetStateAction<AppliedFiltersProps>>;
}

export const Documents: FC<DocumentsProps> = ({
  onFilterChange,
  formTypes,
  //   mobileFilterStatsLength,
  terms,
  onSearch,
  query,
  filters,
  documents,
  onLoadMoreDocuments,
  isLoadingSearchInfinite,
  isSearchLoading,
  lastPageSearch,
  searchDocumentsError,
  onOpenDocumentDrawer,
  onOpenNewDocumentDrawer,
  onOpenFilterDrawer,
  appliedFilters,
  setAppliedFilters,
}) => {
  //   const [filtersDrawerVisible, setFiltersDrawerVisible] =
  //     useState<boolean>(false);
  //   const [showNewDocumentDrawer, setShowNewDocumentDrawer] =
  //     useState<boolean>(false);
  //   const [searchParams] = useSearchParams();

  // White label
  const documentsTerm = terms.document.plural;
  const documentsTermLower = documentsTerm.toLowerCase();

  //   useEffect(() => {
  //     const initialOpenDrawer = searchParams.get("openDrawer") === "true";
  //     if (initialOpenDrawer) {
  //       setShowNewDocumentDrawer(true);
  //     }
  //   }, []);

  return (
    <>
      {/* Desktop */}
      <MediaQuery minWidth={scssVariables.minTablet}>
        <div
          className={styles.title}
          data-testid={`${Page.Desktop}-${Components.MyDocuments}`}
        ></div>
        <RecentDocuments
          openContextMenu={onOpenDocumentDrawer}
          query={query}
          terms={terms}
          documents={documents}
        />
        <MyDocuments
          onOpenNewDocumentDrawer={onOpenNewDocumentDrawer}
          openContextMenu={onOpenDocumentDrawer}
          documents={documents}
          onLoadMore={onLoadMoreDocuments}
          isLoadingSearchInfinite={isLoadingSearchInfinite}
          isSearchLoading={isSearchLoading}
          lastPageSearch={lastPageSearch}
          searchDocumentsError={searchDocumentsError}
          terms={terms}
          filters={filters}
          formTypes={formTypes}
          onFilterChange={onFilterChange}
          onSearch={onSearch}
          query={query}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
      </MediaQuery>

      {/* Mobile + Tablet */}
      <MediaQuery maxWidth={scssVariables.maxPhone}>
        <div
          className={styles.documents}
          data-testid={`${Page.Mobile}-${Components.MyDocuments}`}
        >
          <div>
            <RecentDocuments
              openContextMenu={onOpenDocumentDrawer}
              query={query}
              terms={terms}
              documents={documents}
            />
            <MyDocuments
              onOpenNewDocumentDrawer={onOpenNewDocumentDrawer}
              openContextMenu={onOpenDocumentDrawer}
              documents={documents}
              onLoadMore={onLoadMoreDocuments}
              isLoadingSearchInfinite={isLoadingSearchInfinite}
              isSearchLoading={isSearchLoading}
              lastPageSearch={lastPageSearch}
              searchDocumentsError={searchDocumentsError}
              terms={terms}
              filters={filters}
              formTypes={formTypes}
              onFilterChange={onFilterChange}
              onSearch={onSearch}
              query={query}
              onOpenFilterDrawer={onOpenFilterDrawer}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
            />
          </div>
        </div>
      </MediaQuery>
    </>
  );
};
