import React, { FC } from "react";
import FormBuilder from "shared/src/components/clientAdmin/formBuilder/FormBuilder";
import { FBConfigsProvider } from "shared/src/util/hooks/useFBConfigs";
import { ConfigsContextProps } from "shared/src/components/clientAdmin/defaultFBConfigs";
import {
  AppWidgets,
  appWidgetsList,
} from "./clientAdmin/formBuilder/create/content/AppWidgets";
import Widget from "./Document/Widget";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";

export const FormBuilderScreen: FC = () => {
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const configs: ConfigsContextProps = {};

  return (
    <FBConfigsProvider value={configs}>
      <FormBuilder
        appWidgets={AppWidgets}
        appWidgetsList={appWidgetsList}
        widget={Widget}
        documentTerm={documentTerm}
      />
    </FBConfigsProvider>
  );
};
