import {
  DocumentStatus,
  DocumentVm,
  QuestionDTO,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import { Formik, FormikProps } from "formik";
import React, { createRef, Dispatch, FC, SetStateAction, useMemo } from "react";
import { DocumentFormWrapper } from "shared/src/components/Document/DocumentForm/DocumentFormWrapper";
import { getFlattenedItems } from "shared/src/components/Document/DocumentForm/formEntity.helpers";
import { validate } from "shared/src/components/Document/DocumentForm/validation";
import {
  DocumentToasts,
  toastScrollBannerStyle,
} from "shared/src/components/Document/DocumentToasts";
import { RehuddleInfo } from "shared/src/components/Document/RehuddleInfo";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import Widget from "./Widget";

interface FormikDocumentProps {
  document: DocumentVm;
  error: string;
  submissionSuccessful: boolean;
  handleAutoSync: (values: DocumentVm) => Promise<void>;
  handleSubmit: (values: DocumentVm) => Promise<void>;
  returnToDocs(): void;
  setError: Dispatch<SetStateAction<string>>;
  setFormProgress: Dispatch<SetStateAction<number>>;
}

export const FormikDocument: FC<FormikDocumentProps> = ({
  document,
  error,
  submissionSuccessful,
  handleAutoSync,
  handleSubmit,
  returnToDocs,
  setError,
  setFormProgress,
}) => {
  const documentTerm: string = useGroupTerm(
    "document",
    "noun",
    "singular",
    "Document"
  );
  const documentsTerm: string = useGroupTerm(
    "document",
    "noun",
    "plural",
    "Documents"
  );

  const flattenedItems: SectionItem[] = useMemo(
    () => getFlattenedItems(document?.form.sections || []),
    [document]
  );

  const flattenedQuestions: QuestionDTO[] = useMemo(
    () =>
      flattenedItems.filter(
        (item) => item.type === "QUESTION"
      ) as QuestionDTO[],
    [flattenedItems]
  );

  const itemRefs: Map<number, React.RefObject<HTMLDivElement>> = useMemo(
    () =>
      flattenedItems.reduce((result, fi) => {
        result.set(fi.id, createRef<HTMLDivElement>());
        return result;
      }, new Map<number, React.RefObject<HTMLDivElement>>()),
    [flattenedItems]
  );

  /**
   * to update responses with the DocumentResponse endpoints, the document status must be in-progress
   * if document status is SUBMITTED, auto-sync is triggered to convert the document to IN_PROGRESS
   * if responsesEqual, auto-sync to update document OEs or Signatures
   */
  const autoSyncDocument = async (
    values: DocumentVm,
    responsesEqual?: boolean
  ) => {
    if (values.status === DocumentStatus.SUBMITTED || responsesEqual)
      await handleAutoSync(values);
  };

  return (
    <Formik
      initialValues={document}
      validate={(values) => validate(values)}
      enableReinitialize
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<DocumentVm>) => {
        const { values, errors } = formikProps;
        const hasErrors = useMemo(
          () => Object.keys(errors).length > 0 || !!error,
          [errors, error]
        );

        return (
          <>
            <DocumentToasts
              documentTerm={documentTerm}
              documentsTerm={documentsTerm}
              hasErrors={hasErrors}
              error={error}
              submissionSuccessful={submissionSuccessful}
              initialFormValues={document}
              formValues={values}
              returnToDocs={returnToDocs}
              setError={setError}
            />

            {values.isRehuddle && (
              <RehuddleInfo
                id={values.id}
                parentId={values.parentId}
                dateCreated={values.dateCreated}
              />
            )}

            <div
              style={toastScrollBannerStyle(document.submissionType, hasErrors)}
            >
              <DocumentFormWrapper
                formikProps={formikProps}
                formWidget={Widget}
                handleAutoSync={autoSyncDocument}
                setFormProgress={setFormProgress}
                documentTerm={documentTerm}
                flattenedQuestions={flattenedQuestions}
                itemRefs={itemRefs}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};
