import {
  ApiDataSourceConfigDTO,
  DataSourceAuthType,
  DataSourceDTO,
  DataSourceManagementType,
  DataSourceScheduleType,
  DataSourceStatus,
  DataSourceType,
} from "@rtslabs/field1st-fe-common";

interface ApiDataSourceConfigUpdate {
  url: string;
}

export interface DataSourceUpdate {
  datasetKey: string;
  description: string;
  name: string;
  uniqueIdFieldName: string;
  url: string;
}

const getUpdatedStatus = (update: DataSourceUpdate): DataSourceStatus => {
  return !update.datasetKey ||
    !update.name ||
    !update.uniqueIdFieldName ||
    !update.url
    ? DataSourceStatus.UNPUBLISHED
    : DataSourceStatus.PUBLISHED;
};

const getUpdatedApiDataSourceConfigDTO = (
  update: ApiDataSourceConfigUpdate,
  existing: ApiDataSourceConfigDTO | null | undefined
): ApiDataSourceConfigDTO => {
  return {
    ...existing,
    authType: existing?.authType || DataSourceAuthType.NONE,
    paginated: existing?.paginated ?? false,
    scheduleType: existing?.scheduleType || DataSourceScheduleType.NIGHTLY,
    url: update.url,
  };
};

export const getUpdatedDataSourceDTO = (
  update: DataSourceUpdate,
  existing: DataSourceDTO | undefined
): DataSourceDTO => {
  return {
    ...existing,
    apiConfig: getUpdatedApiDataSourceConfigDTO(update, existing?.apiConfig),
    dataSourceKey: update.datasetKey,
    description: update.description,
    managed: DataSourceManagementType.API,
    status: getUpdatedStatus(update),
    title: update.name,
    type: DataSourceType.ADVANCED,
    uniqueIdField: update.uniqueIdFieldName,
  };
};
