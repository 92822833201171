export enum ArcGisActionType {
  SetIsLoadingConfig = "SET_IS_LOADING_CONFIG",
}

export interface ArcGisSetIsLoadingConfigAction {
  isLoadingConfig: boolean;
  type: typeof ArcGisActionType.SetIsLoadingConfig;
}

export type ArcGisAction = ArcGisSetIsLoadingConfigAction;
