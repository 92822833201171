import {
  API,
  EsriContentWidgetSaveVm,
  FormVm,
  QuestionSaveVm,
} from "@rtslabs/field1st-fe-common";
import { FBForm, FBItem } from "../../components/clientAdmin/types";
import { FeatureLayerField } from "../esri/types";

export async function updateEsriContentWidget(
  fbForm: FBForm,
  widget: EsriContentWidgetSaveVm,
  newFields: FeatureLayerField[]
): Promise<FormVm | undefined> {
  const section = fbForm.sections.filter((s) =>
    s.items.find((i) => i.id === widget.id)
  )[0];

  const itemRootIdsOfWidgetQuestions =
    widget.questions?.map((q) => q.questionRootId) || [];

  const existingItems = section.items.filter(
    (i) => i.rootId && itemRootIdsOfWidgetQuestions.indexOf(i.rootId) !== -1
  ) as FBItem<QuestionSaveVm>[];

  // remove all items if layer changes
  if (!newFields.length) {
    const updatedSection = {
      ...section,
      items: [{ ...widget, questions: [] }],
    };
    return await API.addOrUpdateFormSection({
      formId: fbForm.id,
      section: updatedSection,
      index: section.sectionIndex,
    });
  }

  if (newFields.length == existingItems.length) {
    /**
     * The PillBox component fires when the modal closes and this component
     * has already processed all necessary additions/deletions so there's
     * nothing left to do here.
     */
    return;
  }

  if (newFields.length > existingItems.length) {
    // add a new item
    const valuesToBeAdded = newFields.filter(
      (v) =>
        !section.items.find((i) => i.type === "QUESTION" && i.title === v.key)
    );
    /**
     * If valuesToBeAdded.length is greater than 1, then this function has already executed
     * and made an API request to add a form item for that value. So don't make a duplicate
     * request and just make a request for the last item
     */
    const lastItem = valuesToBeAdded.pop();
    return await API.addOrUpdateFormItem({
      formId: fbForm.id,
      sectionId: section.id!,
      item: {
        title: lastItem!.key,
        workflowType: "DRAFT",
        subType: "TEXT_LINE",
        type: "QUESTION",
        parentWidgetRootId: widget.rootId,
        formProperties: {
          isRequired: widget.esriFieldResponsesAreRequired,
        },
      },
    });
  } else {
    // remove an item
    const newValueStrings = newFields.map((v) => v.key);
    const itemsToBeRemoved = existingItems.filter(
      (i) => newValueStrings.indexOf(i.title) === -1
    );
    const lastItem = itemsToBeRemoved.pop();
    return await API.deleteFormItem({
      formId: fbForm.id,
      sectionId: section.id!,
      index: lastItem?.itemIndex!,
      removeWidgetChildren: false,
    });
  }
}
