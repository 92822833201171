import { QAProps } from "../../qa-slugs";
import React from "react";
import { Link as BaseLink } from "react-router-dom";

import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./Link.module.scss";

export interface LinkProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  external?: boolean;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void; // must be used in combination with "to"
}

export const Link: React.FC<LinkProps & QAProps> = ({
  children,
  className,
  disabled,
  external,
  qa,
  to,
  onClick,
}) => {
  return disabled ? (
    <span
      data-testid={qa}
      className={joinClassNames(
        styles["link"],
        styles["linkDisabled"],
        className
      )}
    >
      {children}
    </span>
  ) : external ? (
    <a
      className={joinClassNames(styles["link"], className)}
      data-testid={qa}
      href={to}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <BaseLink
      data-testid={qa}
      className={joinClassNames(styles["link"], className)}
      to={to}
      onClick={onClick}
    >
      {children}
    </BaseLink>
  );
};
