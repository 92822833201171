import { ClickAwayListener } from "@mui/material";
import React, {
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  useId,
  useState,
} from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { useAuth } from "../../../store/hooks/useAuth";
import Popper from "shared/src/components/common/Popper";
import styles from "./UserMenu.module.scss";
import FocusTrap from "@mui/base/FocusTrap";
import { ElementType } from "shared/src/qa-slugs";
import { UserProfileInfo } from "../UserProfileInfo/UserProfileInfo";
import { useUserInfo } from "../useUserInfo";

export const UserMenu: FC = () => {
  const userInfo = useUserInfo();
  const { logout } = useAuth();
  const popperId = useId();
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClickMenuButton: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setMenuAnchor(event.currentTarget);
    setIsMenuOpen((priorIsOpen) => !priorIsOpen);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleKeyDownMenu: KeyboardEventHandler<HTMLDivElement> = (event) => {
    switch (event.key) {
      case "Escape":
        handleClose();
        event.preventDefault();
        break;
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <button
          aria-controls={isMenuOpen ? popperId : undefined}
          aria-expanded={isMenuOpen}
          aria-haspopup="true"
          aria-label="User account"
          className={styles.menuButton}
          data-testid={`user-initials-profile-${ElementType.Button}`}
          onClick={handleClickMenuButton}
          type="button"
        >
          <div className={styles.initials}>{userInfo.initials}</div>
          <Icon color={scssVariables.darkGrey} size={24} type="expand_arrow" />
        </button>
        <Popper
          arrow
          anchorEl={menuAnchor}
          className={styles.popper}
          open={isMenuOpen}
          placement="bottom-end"
          placementOffset={-30}
          qa="user-menu-modal"
        >
          <FocusTrap open>
            <div className={styles.popperContent} onKeyDown={handleKeyDownMenu}>
              <UserProfileInfo />
              <button
                className={styles.logOutButton}
                data-testid={`sign-out-${ElementType.Button}`}
                onClick={logout}
                type="button"
              >
                Sign out{" "}
                <Icon
                  color={scssVariables.red700}
                  size={24}
                  type="chevron-right"
                />
              </button>
            </div>
          </FocusTrap>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
