import React, {
  Dispatch,
  FC,
  JSXElementConstructor,
  ReactNode,
  SetStateAction,
  useReducer,
} from "react";
import { DocumentVm } from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import { Components, QAProps } from "../../../qa-slugs";
import { DocumentLayout } from "./navigation/DocumentLayout";
import styles from "./DocumentSection.module.scss";
import { DocumentSectionContent } from "./DocumentSectionContent";
import { SectionalContextProps, SectionalState } from "./types";
import {
  DocSecActionTypes,
  DocSecActions,
  DocSecCurrentSectionAction,
  DocSecDocumentTitleAction,
  DocSecVisibleSectionsAction,
} from "./hooks/actions";
import reducer from "./hooks/reducer";
import { SectionalProvider } from "./SecionalContext";
import { FormWidgetProps } from "../DocumentForm/types";
import { getVisibleSections } from "../document.helpers";
import { DashboardBottomNavProps } from "../../navigation/DashboardBottomNavigation/types";

interface DocumentSectionProviderProps extends QAProps {
  documentTerm: string;
  onPutDocument: (
    values: DocumentVm,
    responsesEqual?: boolean
  ) => Promise<void>;
  appHeader: JSXElementConstructor<{}>;
  appNav: JSXElementConstructor<{}>;
  appNavBottom: JSXElementConstructor<DashboardBottomNavProps>;
  formWidget: JSXElementConstructor<FormWidgetProps>;
  setShowSidebarDrawer: Dispatch<SetStateAction<boolean>>;
  qaBase?: string;
}

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const { values } = useFormikContext<DocumentVm>();

  const visibleSections = getVisibleSections(values);

  const initialDocSecState: SectionalState = {
    currentSection: visibleSections[0],
    documentTitle: values.title,
    visibleSections,
  };

  const [state, dispatch] = useReducer<
    (state: any, action: DocSecActions) => SectionalState
  >(reducer, initialDocSecState);

  const value: SectionalContextProps = {
    ...state,
    setCurrentSection: (currentSection) => {
      dispatch({
        type: DocSecActionTypes.SET_CURRENT_SECTION,
        value: currentSection,
      } as DocSecCurrentSectionAction);
    },
    setDocumentTitle: (documentTitle) => {
      dispatch({
        type: DocSecActionTypes.SET_DOCUMENT_TITLE,
        value: documentTitle,
      } as DocSecDocumentTitleAction);
    },

    setVisibleSections: (visibleSections) => {
      dispatch({
        type: DocSecActionTypes.SET_VISIBLE_SECTIONS,
        value: visibleSections,
      } as DocSecVisibleSectionsAction);
    },
  };

  return <SectionalProvider value={value}>{children}</SectionalProvider>;
};

export const DocumentSectionProvider: FC<DocumentSectionProviderProps> = ({
  documentTerm,
  onPutDocument,
  appHeader,
  appNav,
  appNavBottom,
  formWidget,
  setShowSidebarDrawer,
  qaBase,
}) => {
  return (
    <Provider>
      <DocumentLayout
        appHeader={appHeader}
        appNav={appNav}
        appNavBottom={appNavBottom}
        qa={`${qaBase}-${Components.DocumentLayout}`}
      >
        <div className={styles.documentQuestionContainer}>
          <DocumentSectionContent
            documentTerm={documentTerm}
            formWidget={formWidget}
            onPutDocument={onPutDocument}
            setShowSidebarDrawer={setShowSidebarDrawer}
            qa={`${qaBase}-${Components.DocumentSectionContent}`}
          />
        </div>
      </DocumentLayout>
    </Provider>
  );
};
