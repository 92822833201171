import { API, PasswordResetVm, CoreRedux } from "@rtslabs/field1st-fe-common";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Activation } from "../../../shared/src/components/auth/Activation";
import { AuthRequestStatus } from "../../../shared/src/components/auth/types";

export const ActivationScreen = () => {
  const [keyValidationStatus, setKeyValidationStatus] =
    useState<AuthRequestStatus>();
  const [activationStatus, setActivationStatus] = useState<AuthRequestStatus>();
  const [loadingActivation, setLoadingActivation] = useState<boolean>(false);
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [username, setUsername] = useState<string | null>(null);

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const activationKey = params.get("key");
  const logo = useSelector(CoreRedux.selectPrimaryLogoImageProps);

  useEffect(() => {
    if (activationKey) {
      onValidateKey(activationKey);
    } else {
      setKeyValidationStatus("failure");
    }
  }, [activationKey]);

  const onActivate = async (
    newPassword: PasswordResetVm["newPassword"]
  ): Promise<void> => {
    if (activationKey !== null) {
      setLoadingActivation(true);
      try {
        await API.activateUser({
          credentials: {
            key: activationKey,
            newPassword,
          },
        });
        setActivationStatus("success");
      } catch {
        setActivationStatus("failure");
      } finally {
        setLoadingActivation(false);
      }
    }
  };

  const onValidateKey = async (key: string): Promise<void> => {
    setLoadingValidation(true);
    try {
      const user = await API.validateUserKey({ activationKey: key });
      setKeyValidationStatus("success");
      setUsername(user.email || null);
    } catch {
      setKeyValidationStatus("failure");
    } finally {
      setLoadingValidation(false);
    }
  };

  return (
    <Activation
      activationStatus={activationStatus}
      keyValidationStatus={keyValidationStatus}
      loadingActivation={loadingActivation}
      onReturnToLogin={() => navigate("/login")}
      loadingValidation={loadingValidation}
      username={username}
      logo={logo}
      onActivate={onActivate}
    />
  );
};
