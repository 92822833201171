import { API, FormSaveVm } from "@rtslabs/field1st-fe-common";
import { FormikHelpers } from "formik";
import { unmarshallForm } from "../helpers";
import { FBForm } from "../../types";

export async function loadPreviousFormAsDraft(
  previousForm: FormSaveVm,
  currentFormId: number,
  setValues: FormikHelpers<FBForm>["setValues"]
) {
  const response = await API.revertForm({
    form: { ...previousForm, id: currentFormId, workflowType: "DRAFT" },
  });
  const fbForm = unmarshallForm(response);
  setValues(fbForm);
}
