import React, { useState } from "react";

import { SuggestionType } from "../../../../../../MultiInput/MultiInput";
import CollapsibleDiv, {
  CollapseCaret,
} from "../../../../../../common/CollapsibleDiv";
import Label from "../../../../../../Label/Label";
import MultiInput from "../../../../../../MultiInput/MultiInput";

import ps from "../../styles.module.scss";
import ts from "./styles.module.scss";
import bs from "../../../../styles.module.scss";
import TagsCount from "./TagsCount";
import { Tag } from "./types";
import { joinClassNames } from "../../../../../../../helpers/theme.helpers";

interface Props {
  title: string;
  id: number;
  tags: Tag[];
  toggleTag: (tag: SuggestionType) => void;
}

const TagsSection = ({ title, id, tags, toggleTag }: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const toggleCollapse = () => setCollapsed((currState) => !currState);

  return (
    <div className={ts.tagsSection}>
      <div className={joinClassNames(bs.row, bs.spaceBetween)}>
        <Label className={ps.label} htmlFor={String(id)}>
          {title}
        </Label>
        <CollapseCaret collapsed={collapsed} toggleCollapse={toggleCollapse} />
      </div>
      <TagsCount count={tags.length} />
      <CollapsibleDiv collapsed={collapsed}>
        <MultiInput
          className={joinClassNames(ps.multiInput, ts.multiInput)}
          labelField="title"
          canUseCustomValues={true}
          idField="id"
          name={String(id)}
          itemClassName={ts.multiInputItem}
          onAddItem={() => null}
          onRemoveItem={toggleTag}
          selectedValues={tags}
        />
      </CollapsibleDiv>
    </div>
  );
};

export default TagsSection;
