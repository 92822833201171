import React from "react";
import Loader from "shared/src/components/Loader/Loader";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Icon } from "shared/src/components/Icon/Icon";
import styles from "../OperationalExperiences.module.scss";

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  included: boolean;
  loading: boolean;
}

const IncludeButton = ({ onClick, children, included, loading }: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={loading}
      className={joinClassNames(styles.includeBtn, included && styles.included)}
    >
      <Loader
        loading={loading}
        className={styles.loader}
        spinnerProps={{ size: 14, color: included ? "secondary" : "primary" }}
      >
        {included && <Icon type="checkmark" className={styles.includedIcon} />}
      </Loader>
      {children}
    </button>
  );
};

export default IncludeButton;
