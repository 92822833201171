import {
  getMapModules,
  Marker,
} from "../../components/common/ArcGisMap/esriMapUtilities";

export const addMarkersToLayer = (
  graphicsLayer: __esri.GraphicsLayer,
  markers: Marker[] | undefined
) => {
  if (!markers || markers.length === 0) {
    return;
  }

  const { Graphic, Point, SimpleMarkerSymbol } = getMapModules();
  const pointGraphics = markers.map(
    (marker) =>
      new Graphic({
        geometry: new Point({
          latitude: marker.geolocation.latitude,
          longitude: marker.geolocation.longitude,
        }),
        symbol: new SimpleMarkerSymbol({
          color: marker.color,
          outline: {
            width: 0,
          },
          path: "M16 0c-6.187 0-11.2 5.015-11.2 11.2 0 8.001 11.2 20.799 11.2 20.799s11.2-12.8 11.2-20.799c0-6.187-5.015-11.2-11.2-11.2zM16 15.202c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4c0 2.209-1.791 4-4 4z",
          size: 24,
          yoffset: 12,
        }),
      })
  );

  graphicsLayer.addMany(pointGraphics);
};
