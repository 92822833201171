import React, { FC } from "react";
import YesOrNo from "../../../YesOrNo/YesOrNo";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import bs from "../styles.module.scss";
import s from "./styles.module.scss";
import { useFBConfigs } from "../../../../util/hooks/useFBConfigs";
import { Button } from "../../../Button/Button";
import { Params, useNavigate, useParams } from "react-router-dom";

interface SettingsFormProps {
  id: number;
}

export const AdminSettings: FC<SettingsFormProps> = ({ id }) => {
  const { formBuilderConfigs, setConfigById, reload, reset } = useFBConfigs();
  const {
    enableChoiceIcons,
    enableCloneDocument,
    enableDataSets,
    enableDefense,
    enableHistory,
    enableOE,
    enableSettings,
    enableVoiceToText,
    enableWO,
  } = formBuilderConfigs;

  const navigate = useNavigate();
  const hasConfig = !!useParams<Params>().config;

  return (
    <div className={joinClassNames(s.settingsForm, bs.contentContainer)}>
      <YesOrNo
        name="enableChoiceIcons"
        label="Enable Choice Icons?"
        value={enableChoiceIcons}
        onChange={(value: string) => {
          setConfigById("enableChoiceIcons", value === "true");
        }}
        disabled={hasConfig}
      />

      <YesOrNo
        name="enableCloneDocument"
        label="Enable Clone Document?"
        value={enableCloneDocument}
        onChange={(value: string) => {
          setConfigById("enableCloneDocument", value === "true");
        }}
        disabled={hasConfig}
      />

      <YesOrNo
        name="enableDataSets"
        label="Enable DataSets?"
        value={enableDataSets}
        onChange={(value: string) => {
          setConfigById("enableDataSets", value === "true");
        }}
        disabled={hasConfig}
      />

      <YesOrNo
        name="enableDefense"
        label="Enable Defense?"
        value={enableDefense}
        onChange={(value: string) => {
          setConfigById("enableDefense", value === "true");
        }}
        disabled={hasConfig}
      />

      <YesOrNo
        name="enableHistory"
        label="Enable History?"
        value={enableHistory}
        onChange={(value: string) => {
          setConfigById("enableHistory", value === "true");
        }}
        disabled={hasConfig}
      />

      <YesOrNo
        name="enableOE"
        label="Enable Operational Experiences?"
        value={enableOE}
        onChange={(value: string) => {
          setConfigById("enableOE", value === "true");
        }}
        disabled={hasConfig}
      />

      <YesOrNo
        name="enableSettings"
        label="Enable Settings?"
        value={enableSettings}
        onChange={(value: string) => {
          setConfigById("enableSettings", value === "true");
        }}
        disabled={hasConfig}
      />

      <YesOrNo
        name="enableVoiceToText"
        label="Enable Voice To Text?"
        value={enableVoiceToText}
        onChange={(value: string) => {
          setConfigById("enableVoiceToText", value === "true");
        }}
        disabled={hasConfig}
      />

      <YesOrNo
        name="enableWO"
        label="Enable Work Order?"
        value={enableWO}
        onChange={(value: string) => {
          setConfigById("enableWO", value === "true");
        }}
        disabled={hasConfig}
      />
      {!hasConfig && (
        <Button onClick={() => reload(id, navigate)}>
          Reload Form with this Config
        </Button>
      )}
      {hasConfig && (
        <Button onClick={() => reset(id, navigate)}>
          Reset Form to Default Config
        </Button>
      )}
    </div>
  );
};
