import React from "react";
import moment from "moment";

import * as S from "../styles";
import Loader from "shared/src/components/Loader/Loader";
import {
  CancelButton,
  SubmitButton,
} from "shared/src/components/clientAdmin/styles";
import { LoaderWrapper } from "../../addUser/styles";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { ElementType, QAProps } from "shared/src/qa-slugs";

interface Props extends QAProps {
  // document data
  created?: string;
  group?: string | null;
  title?: string;

  loading?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

// Content only
export const DeleteDocumentConfirmation = ({
  // Document data
  created,
  group,
  title,

  loading,
  onCancel,
  onSubmit,
  qa,
}: Props) => {
  // Group config terms
  const documentTerm = useGroupTerm("document", "noun", undefined, "Document");
  const documentTermLower = documentTerm.toLowerCase();
  const documentTermUpper = documentTerm.toUpperCase();
  return (
    <S.ModalPromptWrapper>
      <S.ModalPromptTitle>
        Would you like to delete this {documentTermLower}?
      </S.ModalPromptTitle>
      <S.ModalPromptSubtitle>
        You are about to delete this {documentTermLower}:
      </S.ModalPromptSubtitle>
      <div className="d-flex flex-row">
        <S.DisableUserDataItem>
          <S.DataItemLabel>{documentTermUpper}</S.DataItemLabel>
          <S.DataItemText>{title || "-"}</S.DataItemText>
        </S.DisableUserDataItem>
        <S.DisableUserDataItem>
          <S.DataItemLabel>GROUP</S.DataItemLabel>
          <S.DataItemText>{group || "-"}</S.DataItemText>
        </S.DisableUserDataItem>
        <S.DisableUserDataItem>
          <S.DataItemLabel>CREATED</S.DataItemLabel>
          <S.DataItemText>
            {(created && moment(created).format("MM/DD/YY - hh:mm A")) || "-"}
          </S.DataItemText>
        </S.DisableUserDataItem>
      </div>
      <S.DisableUserWarningLabel margin="32.5px 0 0 0">
        Deleting a {documentTermLower} is a permanent action and cannot be
        undone. The deleted {documentTermLower} will be removed from the search
        database and will no longer impact relevant reporting.
      </S.DisableUserWarningLabel>
      <S.DisableUserButtonsWrapper margin="24px 0 0 0">
        <CancelButton
          data-testid={qa ? `${qa}-${ElementType.Button}-cancel` : undefined}
          disabled={false}
          onClick={onCancel}
        >
          cancel
        </CancelButton>
        <SubmitButton disabled={false} onClick={onSubmit}>
          <LoaderWrapper>
            <Loader
              loading={loading}
              className="p-0 mr-3"
              spinnerProps={{ size: 14 }}
            />
          </LoaderWrapper>
          <span
            data-testid={qa ? `${qa}-${ElementType.Button}-delete` : undefined}
          >
            delete {documentTermLower}{" "}
          </span>
        </SubmitButton>
      </S.DisableUserButtonsWrapper>
      <S.ModalPromptCloseIcon
        data-testid={qa ? `${qa}-${ElementType.Button}-close` : undefined}
        onClick={onCancel}
      />
    </S.ModalPromptWrapper>
  );
};
