import { API, FormTypeDTO } from "@rtslabs/field1st-fe-common";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Alert } from "../../../../../shared/src/components/Alert/Alert";
import {
  Button,
  TertiaryButton,
} from "../../../../../shared/src/components/Button/Button";
import { ModalPromptCloseButton } from "../../../../../shared/src/components/Modal/ModalPromptCloseButton";
import { Components, ElementType } from "../../../../../shared/src/qa-slugs";
import s from "./DeleteFormType.module.scss";

interface DeleteFormTypeConfirmationProps {
  refreshFormTypes: () => void;
  onCancel: () => void;
  formType: FormTypeDTO;
  setDeleteFormTypeIsVisible: Dispatch<SetStateAction<boolean>>;
  setDeleteStatus: Dispatch<SetStateAction<"CONFIRM" | "SUCCESS" | "FAIL">>;
}

export const DeleteFormTypeConfirmation: FC<
  DeleteFormTypeConfirmationProps
> = ({
  refreshFormTypes,
  onCancel,
  formType,
  setDeleteFormTypeIsVisible,
  setDeleteStatus,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    API.deleteFormType({ id: formType.id })
      .then(() => {
        setDeleteStatus("SUCCESS");
        refreshFormTypes();
      })
      .catch(() => setDeleteStatus("FAIL"))
      .finally(() => {
        setLoading(false);
        setDeleteFormTypeIsVisible(false);
      });
  };

  return (
    <>
      <h1 className={s.modalPromptTitle}>Delete Form Type</h1>
      <ModalPromptCloseButton
        qa={`${Components.FormTypes}-${ElementType.Button}-close`}
        onClick={onCancel}
      />
      <Alert
        variant={"error"}
        message={
          "Deleting a form type will also result in deleting all of the forms and documents of that type. This change cannot be undone."
        }
        title={`Do you wish to permanently remove "${formType.name}" form type?`}
      />
      <div className={s.deleteFormTypeButtonsWrapper}>
        <TertiaryButton
          qa={`${Components.FormTypes}-${ElementType.Button}-cancel`}
          className={s.buttonMargin}
          onClick={onCancel}
        >
          cancel
        </TertiaryButton>
        <Button
          qa={`${Components.FormTypes}-${ElementType.Button}-delete`}
          onClick={handleSubmit}
          loading={loading}
        >
          remove form type
        </Button>
      </div>
    </>
  );
};
