import React from "react";

import { MenuDivider as MenuDividerType } from "./types";

import styles from "./Menu.module.scss";
interface Props {
  menuDivider: MenuDividerType;
}

const MenuDivider = ({ menuDivider: { title } }: Props) => {
  return (
    <div className={styles.menuDivider}>
      <p className={styles.dividerTitle}>{title}</p>
    </div>
  );
};

export default MenuDivider;
