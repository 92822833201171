import React, { useMemo, useEffect } from "react";
import { range } from "lodash";

import useWindowResize from "shared/src/util/hooks/useWindowResize";
import { createMarkup } from "shared/src/util/createMarkup";
import { DocumentOEVm } from "@rtslabs/field1st-fe-common";
import { joinClassNames } from "shared/src/helpers/theme.helpers";

import IncludeButton from "./IncludeButton";
import ShowMoreButton from "./ShowMoreButton";
import styles from "../OperationalExperiences.module.scss";

interface Props {
  oe: DocumentOEVm;
  handleIncludeExcludeOE: (
    oe: DocumentOEVm,
    included: boolean
  ) => void | Promise<void>;
  included: boolean;
  loading: boolean;
  className?: string;
}

function OECard({
  oe,
  handleIncludeExcludeOE,
  included,
  loading,
  className,
}: Props) {
  const { width = 0 } = useWindowResize();
  const [collapsed, setCollapse] = React.useState(true);
  const isDesktopWidth = useMemo(() => width >= 1024, [width]);

  // On desktop, we will not collapse cards
  // and we'll hide the show more/less buttons
  useEffect(() => {
    if (isDesktopWidth && collapsed) {
      setCollapse(false);
    }
  }, [width, collapsed, isDesktopWidth]);

  const descriptionCharCount = useMemo(
    () => (oe.description ? oe.description.length : 0),
    [oe.description]
  );

  const showCardActionRow = useMemo(() => {
    if (isDesktopWidth) {
      return false;
    }

    if (range(width, 768, 835) && descriptionCharCount < 370) {
      return false;
    }

    if (width < 768 && descriptionCharCount < 150) {
      return false;
    }

    return true;
  }, [descriptionCharCount, isDesktopWidth, width]);

  return (
    <div
      className={joinClassNames(
        styles.oeCard,
        included && styles.included,
        className
      )}
    >
      <div className={styles.actionRow}>
        <IncludeButton
          onClick={() => handleIncludeExcludeOE(oe, !included)}
          included={included}
          loading={loading}
        >
          {included ? "Included" : "Include"}
        </IncludeButton>
      </div>
      <div>
        <p className={styles.title}>{oe.incident}</p>
        <p className={styles.label}>#{oe.id}</p>
      </div>
      <div
        className={joinClassNames(
          styles.description,
          collapsed && styles.collapsed
        )}
      >
        <div
          className={styles.html}
          dangerouslySetInnerHTML={createMarkup(oe.description)}
        />
      </div>
      {showCardActionRow && (
        <div
          className={joinClassNames(
            styles.actionRow,
            styles.showMore,
            collapsed && styles.collapsed
          )}
        >
          <ShowMoreButton
            collapsed={collapsed}
            onClick={() => setCollapse(!collapsed)}
          />
        </div>
      )}
    </div>
  );
}

export default OECard;
