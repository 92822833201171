import React from "react";
import { FormSectionTitle } from "shared/src/components/Document/DocumentForm/FormSectionTitle";
import styles from "../OperationalExperiences.module.scss";

interface Props {
  availableCount: number;
  requiredCount: number;
  oeTerm: string;
}

function OEHeader({ availableCount, requiredCount, oeTerm }: Props) {
  return (
    <div className={styles.oeHeader}>
      <FormSectionTitle title={oeTerm} />

      {availableCount > 0 ? (
        <>
          <span>
            Select {requiredCount} real scenarios below to discuss with your
            team. After each discussion, select{" "}
            <em>
              <b>Include</b>
            </em>{" "}
            to include it in this form. If the displayed cards aren’t relevant,
            you can&nbsp;
            <em>
              <b>Reshuffle</b>
            </em>{" "}
            to view more scenarios.
          </span>
        </>
      ) : (
        <span>
          Please make your task selections first and then we’ll provide&nbsp;
          {oeTerm} to talk through with your team
        </span>
      )}
    </div>
  );
}

export default OEHeader;
