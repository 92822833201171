import React from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps, ElementType } from "../../qa-slugs";
import { useKeyboardFocus } from "../../util/hooks/useKeyboardFocus";
import styles from "./RadioButton.module.scss";

export interface RadioButtonProps<
  T extends number | boolean | string | undefined | null
> extends QAProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  error?: string;
  onChange: (data: T) => void;
  label: string | React.ReactElement;
  name?: string | undefined;
  data?: T;
}

export function RadioButton<
  T extends number | boolean | string | undefined | null
>({
  checked,
  className,
  disabled,
  error,
  onChange: handleChange,
  label,
  name,
  data,
  qa,
}: RadioButtonProps<T>) {
  const { isKeyboardFocused, ...eventHandlers } = useKeyboardFocus();

  const inputValue: string | number | undefined | null =
    typeof data === "boolean" ? data.toString() : data;

  return (
    <div className={className}>
      <label
        className={joinClassNames(styles.label, checked && styles.checked)}
      >
        <input
          checked={checked}
          className={joinClassNames(styles.input, !!error && styles.error)}
          disabled={disabled}
          name={name}
          onChange={() => handleChange(inputValue as T)}
          type="radio"
          value={inputValue ? inputValue : undefined}
          {...eventHandlers}
          data-testid={
            qa
              ? `${qa}-${ElementType.RadioButton}-${
                  inputValue ?? name ?? label
                }`
              : undefined
          }
        />
        <span
          className={joinClassNames(
            styles.text,
            !!error && styles.error,
            disabled && styles.disabled
          )}
        >
          {label}
        </span>
      </label>
    </div>
  );
}

export const PillRadioButton = <T extends number | string>({
  className,
  ...rest
}: RadioButtonProps<T>) => {
  return (
    <RadioButton className={joinClassNames(styles.pill, className)} {...rest} />
  );
};
