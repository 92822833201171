import React, { FC, ReactElement, useEffect, useState } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps } from "../../qa-slugs";
import { Icon } from "../Icon/Icon";
import Portal from "../Portal/Portal";
import styles from "./Toast.module.scss";

export interface ToastProps extends QAProps {
  children?: React.ReactNode;
  backgroundColor: string;
  className?: string;
  color: string;
  component?: (secondsRemaining: number) => ReactElement;
  icon?: string;
  onClick?: (e?: any) => void;
  onDelay?: (e?: any) => void;
  onDelayTime?: number;
  shouldScroll?: boolean;
  visible: boolean;
}

/**
 * @deprecated Use react-toastify and Toastify.tsx instead.
 * @see https://fkhadra.github.io/react-toastify/introduction
 */
const Toast: FC<ToastProps> = ({
  children,
  component,
  visible,
  onClick,
  onDelay,
  onDelayTime = 4000,
  className,
  shouldScroll,
  color,
  backgroundColor,
  icon,
  qa,
}) => {
  if (!visible) {
    return null;
  }

  // state used to store the timeout ID so we can clear if the user clicks
  // rather than waits for the timeout to execute
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);

  const [timeRemaining, setTimeRemaining] = useState<number>(onDelayTime);

  const decrementSecondsRemaining = () => {
    if (timeRemaining > 1000) {
      setTimeRemaining(timeRemaining - 1000);
      setTimeoutId(window.setTimeout(decrementSecondsRemaining, 1000));
    } else if (onDelay) {
      return onDelay();
    }
  };

  // effect to clear timeoutId on unmount
  useEffect(() => {
    // set timeout
    if (onDelay && !timeoutId) {
      setTimeoutId(window.setTimeout(decrementSecondsRemaining, 1000));
    }
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);

        return setTimeoutId(undefined);
      }
    };
  }, [timeoutId]);

  // local onClick handler to clear timeout
  const localOnClick = () => {
    window.clearTimeout(timeoutId);

    if (onClick) {
      onClick();
    }
  };

  return (
    <Portal variant="default">
      <div
        className={joinClassNames(styles.toast, className)}
        style={{ position: shouldScroll ? "absolute" : "fixed" }}
      >
        <button
          data-testid={qa}
          onClick={localOnClick}
          className={styles.actionButton}
          style={{
            color,
            backgroundColor,
            borderColor: color,
          }}
        >
          {icon && <Icon type={icon} className={styles.icon} />}
          {component?.(timeRemaining / 1000) ?? children}
        </button>
      </div>
    </Portal>
  );
};

export default Toast;
