import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";

import { FBForm, FBOEWidget, FBQuestion } from "../../../../../types";
import { TextInputProps } from "../../../../../../TextInput/types";
import { SuggestionType } from "../../../../../../MultiInput/MultiInput";
import Label from "../../../../../../Label/Label";

import TagsSection from "./TagsSection";
import ps from "../../styles.module.scss";
import ts from "./styles.module.scss";
import { Tag } from "./types";
import {
  getQuestionsWithOeTags,
  renderQuestionTags,
  suppressOrIncludeOeTag,
} from "./helpers";
import { OperationalExperiencesWidgetQuestionSaveVm } from "@rtslabs/field1st-fe-common";

interface Props extends TextInputProps {
  name: string;
  item: FBOEWidget;
  onUpdate: (
    widgetQuestions: OperationalExperiencesWidgetQuestionSaveVm[]
  ) => void;
}

const Tags = ({
  name,
  label,
  item: widget,
  assistiveText,
  onUpdate,
}: Props) => {
  const { values } = useFormikContext<FBForm>();
  const [questions, setQuestions] = useState<FBQuestion[]>([]);

  // get questions with OE tags
  useEffect(() => {
    const questionsWithTags = getQuestionsWithOeTags(values);
    setQuestions(questionsWithTags);
  }, [values.sections]);

  /**
   * Include or exclude (suppress) an OE tag for a form question
   * @param tag
   * @param question
   */
  function includeOrExcludeTag(tag: Tag, question: FBQuestion) {
    const updatedQuestions = suppressOrIncludeOeTag(
      widget.questions || [],
      question,
      tag
    );
    onUpdate(updatedQuestions);
  }

  return (
    <>
      <Label className={ps.label} htmlFor={name}>
        {label}
      </Label>
      <p className={ps.text}>{assistiveText}</p>
      {questions.map((question) => {
        const questionTags: Tag[] = renderQuestionTags(
          widget,
          question,
          ts.excludedItem
        );
        return (
          <TagsSection
            key={question.id}
            title={question.title || "Untitled Question"}
            id={question.id!}
            tags={questionTags}
            toggleTag={(tag: SuggestionType) =>
              includeOrExcludeTag(tag as unknown as Tag, question)
            }
          />
        );
      })}
    </>
  );
};

export default Tags;
