import styled from "styled-components";
import { remCalc } from "shared/src/themes/helpers";

export const ResponseList = styled.div`
  /* max-width: 415px; */
  display: flex;
  flex-direction: column;
`;

export const OEDescription = styled.div`
  word-break: break-word;
  hyphens: auto;
  color: ${({ theme }) => theme.masterColors.darkGrey};
`;

export const SidebarDrawerHeader = styled.h6`
  color: ${({ theme }) => theme.masterColors.black};
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.26px;
`;

export const SidebarDrawerBody = styled.p`
  color: ${({ theme }) => theme.masterColors.black};
  font-size: ${remCalc(14)};
  letter-spacing: 0.24px;
`;
