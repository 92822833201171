import { Icon } from "../Icon/Icon";
import React, { FC } from "react";
import styles from "./StyledLinkCaretRight.module.scss";
import { joinClassNames } from "../../helpers/theme.helpers";

type Props = {
  style?: {
    color: string;
  };
  className?: string;
};

const StyledLinkCaretRight: FC<Props> = ({ style, className }) => {
  return (
    <Icon
      type="expand_arrow"
      color={style?.color}
      className={joinClassNames(styles.linkCaret, className)}
    />
  );
};

export { StyledLinkCaretRight };
