export enum MenuItemIcon {
  PENCIL = "PENCIL",
  PHONE_SETTINGS = "PHONE_SETTINGS",
}

export interface MenuItem {
  icon?: MenuItemIcon;
  label: string;
  onClick: () => void;
}

export interface MenuDivider {
  title: string;
  onClick?: () => void;
}

export function isMenuItem(item: MenuItem | MenuDivider): item is MenuItem {
  return (item as MenuItem).label !== undefined;
}
