import moment, { Moment } from "moment";

export const clampDate = (date: Moment, minDate?: Moment, maxDate?: Moment) => {
  const clampedLow = maxDate ? moment.min([date, maxDate]) : date;
  return minDate ? moment.max([clampedLow, minDate]) : clampedLow;
};

export const getMonthYear = (date: Moment) => {
  return date.format("MMMM YYYY");
};

export const getStartOfToday = () => moment().startOf("day");
