import React, { FC, JSXElementConstructor, ReactNode } from "react";
import MediaQuery from "react-responsive";
import { Components, QAProps } from "../../../../qa-slugs";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import styles from "./DocumentLayout.module.scss";
import { SectionNav } from "../navigation/SectionNav";
import { useAppSelector } from "../../../../redux/reduxHooks";
import { CoreRedux } from "@rtslabs/field1st-fe-common";
import { DashboardBottomNavProps } from "../../../navigation/DashboardBottomNavigation/types";

interface DocumentLayoutProps extends QAProps {
  appHeader: JSXElementConstructor<{}>;
  appNav: JSXElementConstructor<{}>;
  appNavBottom: JSXElementConstructor<DashboardBottomNavProps>;
  children: ReactNode;
}

export const DocumentLayout: FC<DocumentLayoutProps> = ({
  appHeader,
  appNav,
  appNavBottom,
  children,
  qa = Components.DocumentLayout,
}) => {
  const mainNavigationConfig = useAppSelector(
    CoreRedux.getMainNavigationConfigProperties
  );

  const AppHeader = appHeader;
  const AppNav = appNav;
  const AppNavBottom = appNavBottom;

  return (
    <>
      <AppHeader />
      <div
        className={styles.navbarWrapper}
        data-testid={`${qa}-${Components.SidebarNavigation}-Container`}
      >
        {/* Document Section Navigation */}
        <SectionNav qa={`${qa}-${Components.SidebarNavigation}`} />

        {/* Page content, used for both mobile-responsive and web */}
        <div
          className={styles.mainContent}
          id="contentWrapper"
          data-testid={`${qa}-Content`}
        >
          {children}
        </div>
        <MediaQuery maxWidth={scssVariables.breakpointLarge}>
          {/* Mobile navigation */}
          <AppNavBottom
            drawerContent={<AppNav />}
            mainNavigationConfig={mainNavigationConfig}
          />
        </MediaQuery>
      </div>
    </>
  );
};
