import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";

import COLORS from "./colors";
import { toRgba } from "./helpers";

export const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      width: 200,
      paddingLeft: 0,
    },

    roundedTextField: {
      width: 200,
      padding: 0,
      paddingLeft: 0,

      ["& fieldset"]: { borderRadius: 50 },
    },

    roundedTextFieldDark: {
      width: 200,
      padding: 0,
      paddingLeft: 0,

      ["& fieldset"]: {
        borderRadius: 50,
      },
    },

    inputLabel: {
      marginBottom: 0,
    },
    formControl: {
      minWidth: 120,
    },
    formControlDate: {
      marginLeft: 0,
      minWidth: 120,
    },
    formControlCheckbox: {},
    selectEmpty: {},
    // Cards
    actions: {},
    avatar: {},
    card: {},
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    selectedIconStyle: {
      position: "absolute",
      right: 20,
      top: 5,
    },
    disabledCardStyle: {
      opacity: 0.5,
    },
    // Search input
    searchInput: {
      paddingLeft: 0,
      color: COLORS.masterColors.darkGrey,
    },
    // Recent document card
    recentDocumentCard: {
      // height: '13.25rem',
      // width: '14rem',
      height: "159px",
      marginBottom: "0.5rem",
      marginLeft: "0.1rem",
      marginRight: "0.5rem",
      marginTop: "0.5rem",
      width: "168px",
    },
    recentDocumentCardStatus: {
      background: COLORS.lightTheme.activeFill,
      border: `1px solid ${COLORS.masterColors.primary}`,
      borderRadius: "21px",
      color: COLORS.masterColors.primary,
      fontSize: "12px",
      padding: "1px 8px",
      textAlign: "center",
      width: "85px",
    },
    recentDocumentCardTitle: {
      fontSize: "16px",
    },
    recentDocumentCardSubtitle: {
      fontSize: "0.875rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    // Drawer
    drawerContainerStyles: {
      backgroundColor: toRgba(COLORS.masterColors.primary, 0.25),
    },
    drawerContainerNoBackdrop: {
      backgroundColor: "transparent",
    },
    drawerStyles: {
      width: "100%",
      backgroundColor: COLORS.masterColors.white,
      textAlign: "center",
      padding: 10,
    },
    drawerStylesDark: {
      width: "100%",
      backgroundColor: COLORS.darkTheme.background,
      textAlign: "center",
      padding: 10,
    },

    commentDrawerStyles: {
      width: "100%",
      backgroundColor: COLORS.masterColors.white,
      textAlign: "center",
      padding: 0,
    },

    myDocumentsDrawer: {
      width: "75vw",
      backgroundColor: COLORS.masterColors.white,
      textAlign: "center",
      padding: 18,
    },
    myDocumentsDrawerDesktop: {
      width: "30vw",
      backgroundColor: COLORS.masterColors.white,
      // textAlign: "center",
      // padding: 18,
    },
    myDocumentsDrawerMobile: {
      width: "100vw",
      backgroundColor: COLORS.masterColors.white,
      // textAlign: "center",
      // padding: 18,
    },
    // Dashboard Bottom Navigation
    dashboardBottomNavigationStyles: {
      // `bottom: 3px` was here originally but I'm assuming we
      // don't want to use it? - Trevor
      bottom: "0px",
      position: "fixed",
      width: "100%",
      boxShadow: `0px 1px 3px ${toRgba(COLORS.masterColors.black, 0.2)}`,
      left: "0",
    },
    // Square Avatar
    squareAvatar: {
      backgroundColor: COLORS.masterColors.lightGrey,
      borderRadius: "4px",
      fontSize: "1.75rem",
    },
    // Submit Buttons
    submitButton: {
      backgroundColor: COLORS.masterColors.primary,
    },
  });
};

export type MUIProps = WithStyles<typeof styles>;
