import React, { FC } from "react";
import styles from "./DocumentSection.module.scss";
import { useDocumentSection } from "./hooks/useDocumentSection";
import { useSectionalContext } from "./hooks/useSectionalContext";
import { QAProps } from "../../../qa-slugs";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { getIsSectionValidated } from "../document.helpers";
import { useFormikContext } from "formik";
import { DocumentVm } from "@rtslabs/field1st-fe-common";
import { DocumentSectionStatus } from "./DocumentSectionStatus";

interface SectionDesciptionProps extends QAProps {
  isSubmitting: boolean;
}

export const SectionDesciption: FC<SectionDesciptionProps> = ({
  isSubmitting,
  qa = "DocSec-description",
}) => {
  const { currentSection } = useSectionalContext();
  const { values } = useFormikContext<DocumentVm>();
  const { getNextSection, handleNextSection } = useDocumentSection();
  const isSectionValidated = getIsSectionValidated(currentSection, values);

  const untitledSection = "Untitled Section";
  const nextSection = getNextSection();

  return (
    <div className={styles.sectionDesciptionContainer}>
      <div className={styles.sectionTitle} data-testid={`${qa}-title`}>
        {currentSection.title || untitledSection}
        {!!nextSection && (
          <div
            className={styles.upNextSectionTitle}
            data-testid={`${qa}-upNext`}
          >
            <span>Up Next:</span>{" "}
            <span
              className={joinClassNames(
                isSectionValidated && styles.upNextLink
              )}
              onClick={isSectionValidated ? handleNextSection : undefined}
            >
              {nextSection.title || untitledSection}
            </span>
          </div>
        )}
      </div>
      {/* <div> */}
      <DocumentSectionStatus
        qa={`${qa}-DocSec-status`}
        submitting={isSubmitting}
      />
      {/* </div> */}
    </div>
  );
};
