import { FBItem, FBSection } from "../../../types";

export const getItemPath = (
  sections: FBSection[],
  item: FBItem | FBSection
): string => {
  if (item.type === "SECTION") {
    const sectionIndex = sections.findIndex(
      (section) => section.rootId === item.rootId
    );
    return `sections[${sectionIndex}]`;
  } else {
    const sectionIndex = sections.findIndex(
      (section) => section.rootId === item.parentSectionRootId
    );
    if (sectionIndex > -1) {
      // I can't seem to get item position to update when new items are added BEFORE
      // existing items. Reverting to findIndex for now, sorry :( -- ED
      const itemIndex = sections[sectionIndex].items?.findIndex(
        (sItem) => sItem.rootId === item.rootId
      );
      return `sections[${sectionIndex}].items[${itemIndex}]`;
    }
    return "";
  }
};
