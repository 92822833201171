import React, { useEffect } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { get } from "lodash";

import { Select, SelectOption } from "../../../../../../Select/Select";
import { toTitleCase } from "../../../../../../../util/toTitleCase";
import Checkbox from "../../../../../../Checkbox/Checkbox";

import {
  buildOptions,
  buildWODisplayConditions,
  getWorkOrderPath,
} from "./helpers";
import { workOrderDto } from "./workOrderDto";
import s from "../../styles.module.scss";
import { FBForm, FBItem, Property } from "../../../../../types";

interface PrefillFromWorkOrderProps {
  property: Property;
  item: FBItem; // @todo we should only allow FBQuestion here
  itemPath: string;
}

export function buildConditionFields(answerField?: string | null) {
  const fieldArray: string[] | undefined = answerField?.split(".");

  if (fieldArray) {
    const fields = fieldArray.reduce(
      (arr: string[], field: string, index: number) => {
        let newValue: string;
        if (field === "undefined") {
          newValue = ".";
        } else {
          newValue = index > 0 ? `${arr[index - 1]}.${field}` : field;
        }
        return [...arr, newValue];
      },
      []
    );

    return fields;
  }
}

const PrefillFromWorkOrder = ({
  property,
  item,
  itemPath,
}: PrefillFromWorkOrderProps) => {
  const { values, errors, setFieldValue } = useFormikContext<FBForm>();

  useEffect(() => {
    if (values.displayConditions) {
      const displayCondition = values.displayConditions.find(
        (dc) =>
          dc.action === "WORK_ORDER_PREFILL" && dc.targetRootId === item.rootId
      );
      const cf =
        buildConditionFields(displayCondition?.prefillAnswerField) || [];
      setFieldValue(`${itemPath}.conditionFields`, cf);
    }
  }, [values.displayConditions]);

  function handleCheckboxChange(checked: boolean) {
    if (checked) {
      const displayConditions = buildWODisplayConditions(
        item,
        "",
        values.displayConditions
      );
      setFieldValue("displayConditions", displayConditions);
    } else {
      const filteredConditions = values.displayConditions?.filter(
        (dc) =>
          !(
            dc.action === "WORK_ORDER_PREFILL" &&
            dc.targetRootId === item.rootId
          )
      );
      setFieldValue("displayConditions", filteredConditions || []);
    }
  }

  function handlePrefillFieldChange(option?: SelectOption<string>) {
    // save the display condition to the form values
    const conditions = buildWODisplayConditions(
      item,
      option?.value,
      values.displayConditions || []
    );
    setFieldValue("displayConditions", conditions);
  }

  const conditionFields = get(
    values,
    `${itemPath}.conditionFields`
  ) as FBItem["conditionFields"];

  return (
    <>
      <Field
        as={Checkbox}
        name={property.name}
        label={property.label}
        containerClassName={s.checkboxContainer}
        className={s.checkbox}
        checked={conditionFields?.length}
        onChange={handleCheckboxChange}
      />
      {!!conditionFields && conditionFields.length > 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <FieldArray
            name={`${itemPath}.conditionFields`}
            render={() =>
              // map through the existing conditionField values and build select fields for valid ones
              conditionFields.map((conField, index) => {
                // get the parent path so we can build sibling options
                const path = getWorkOrderPath(conField);
                // get the field value from the work order dto
                const woFieldValue = path
                  ? get(workOrderDto, path)
                  : workOrderDto;
                // if the work order dto doesn't contain this field, don't build a field for it
                if (!woFieldValue) return;
                // build the select options
                const options: SelectOption[] = buildOptions(
                  conField,
                  woFieldValue,
                  path
                );

                return (
                  <Field
                    key={index}
                    name={`${itemPath}.conditionFields[${index}]`}
                    as={Select}
                    label={
                      path ? `${toTitleCase(path)} subfield` : "Prefill field"
                    }
                    value={
                      !conditionFields[index] ||
                      conditionFields[index].endsWith(".")
                        ? "undefined"
                        : conditionFields[index]
                    }
                    options={options}
                    placeholder={
                      path ? `Select ${path} subfield` : "Select prefill field"
                    }
                    onChange={handlePrefillFieldChange}
                    error={get(errors, `${itemPath}.conditionFields[${index}]`)}
                  />
                );
              })
            }
          />
        </div>
      )}
    </>
  );
};

export default PrefillFromWorkOrder;
