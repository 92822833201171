import React, { useState } from "react";
import styles from "./RehuddleSidebar.module.scss";
import {
  DocumentSummaryVm,
  DocumentVm,
  CoreRedux,
  ParticipantUser,
} from "@rtslabs/field1st-fe-common";
import useHandleRehuddle from "./useHandleRehuddle";
import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";

import { getSubmissionTimeError } from "../../helpers";
import { Button, TertiaryButton } from "shared/src/components/Button/Button";
import { conjugateTerm } from "shared/src/util/conjugateTerm";
import { useSelector } from "react-redux";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import { ElementType } from "shared/src/qa-slugs";

interface Props {
  onSuccess: () => void;
  toggleRehuddleSidebarVisibility: () => void;
  selectedDocument: DocumentSummaryVm | DocumentVm | null;
  terms: {
    document: {
      singular: string;
    };
    rehuddle: string;
  };
}

const RehuddleSidebarContent = ({
  onSuccess,
  toggleRehuddleSidebarVisibility,
  selectedDocument,
  terms,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentUser: ParticipantUser | null = useSelector(
    ({ user }: CoreRedux.Store.RootState) => user.data
  );
  const { handleRehuddle: handleRehuddleFromHook } =
    useHandleRehuddle(currentUser);
  // Group config terms
  const rehuddleTerm = conjugateTerm({
    term: terms.rehuddle,
    role: "noun",
    modifier: undefined,
    fallback: "Rehuddle",
  });

  const documentTerm = conjugateTerm({
    term: terms.document.singular,
    role: "noun",
    modifier: undefined,
    fallback: "Document",
  });

  const documentTermLower = documentTerm.toLowerCase();
  const rehuddleTermLower = rehuddleTerm.toLowerCase();
  const rehuddleTermUpper = rehuddleTerm.toUpperCase();

  const handleRehuddle = () => {
    if (selectedDocument) {
      try {
        setIsLoading(true);
        handleRehuddleFromHook({
          selectedDocument,
          onSuccess,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  // is the current time past the form submission time constraint
  const submissionTimeError = getSubmissionTimeError(
    selectedDocument?.formSummary.formSubmissionConstraint
  );
  return (
    <>
      {/* Header */}
      <DrawerHeader
        title={rehuddleTerm}
        onClose={toggleRehuddleSidebarVisibility}
      />
      {/* Content */}
      <DrawerContentWrapper>
        <h6 className={styles.sidebarDrawerHeader}>{rehuddleTerm}</h6>
        <p className={styles.sidebarDrawerBody}>
          Kicking off a {rehuddleTermLower} will create a new{" "}
          {documentTermLower} that carries over only some key information from
          the original while providing cleared fields for the rest of the form.
        </p>
        {submissionTimeError && <ErrorText>{submissionTimeError}</ErrorText>}
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.submitButton}
            onClick={handleRehuddle}
            disabled={isLoading || !!submissionTimeError}
            loading={isLoading}
            qa={`rehuddle-${ElementType.Button}-start`}
          >
            START {rehuddleTermUpper}
          </Button>
          <TertiaryButton
            className={styles.cancelButton}
            onClick={toggleRehuddleSidebarVisibility}
            disabled={isLoading}
            qa={`rehuddle-${ElementType.Button}-cancel`}
          >
            Cancel
          </TertiaryButton>
        </div>
      </DrawerContentWrapper>
    </>
  );
};

export default RehuddleSidebarContent;
