import { RefObject, useEffect } from "react";
import { useFormikContext } from "formik";
import { DocumentVm } from "@rtslabs/field1st-fe-common";
import { toast } from "react-toastify";
import { errorToastOptions, updateToast } from "../../Toast/Toastify";

interface UseErrorScrollArgs {
  itemRefs: Map<number, RefObject<HTMLDivElement>>;
  toastContainerId?: string;
}

const errorToastId = "error-scroll";

/** Scrolls to first error in document and displays an error toast */
function useErrorScroll({
  itemRefs,
  toastContainerId,
}: UseErrorScrollArgs): void {
  const {
    errors,
    submitCount,
    values: { submissionType },
  } = useFormikContext<DocumentVm>();

  function scrollToFirstErrorIfExists(): void {
    const firstError = getFirstErrorIfExists();
    if (!firstError) return;
    scrollToError(firstError);
  }
  function scrollToError(errorRef: RefObject<HTMLDivElement>): void {
    const targetScrollLocation = getScrollLocationIfValid(errorRef);
    window.scrollTo({
      top: targetScrollLocation,
      behavior: "smooth",
    });
  }

  function getFirstErrorIfExists(): RefObject<HTMLDivElement> | undefined {
    const firstErrorKey = [...itemRefs.keys()].find((key) =>
      errors.hasOwnProperty(key.toString())
    );
    if (!firstErrorKey) return undefined;
    return itemRefs.get(firstErrorKey);
  }

  function getScrollLocationIfValid(
    itemRef: RefObject<HTMLDivElement>
  ): number | undefined {
    const topOfBoundingRect = itemRef?.current?.getBoundingClientRect().top;
    if (!topOfBoundingRect) return undefined;

    return topOfBoundingRect + window.pageYOffset - 200;
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      updateToast(
        "Form is incomplete. See highlighted details below.",
        errorToastId,
        {
          ...errorToastOptions,
          containerId: toastContainerId,
          onClose: scrollToFirstErrorIfExists,
        }
      );
    } else {
      toast.dismiss(errorToastId);
    }
  }, [errors, submitCount, submissionType]);

  useEffect(() => {
    if (submissionType === "SUBMIT" && Object.keys(errors).length > 0) {
      scrollToFirstErrorIfExists();
    }
  }, [errors, submitCount, submissionType]);
}

export default useErrorScroll;
