import {
  DocumentStatus,
  FormSubmissionConstraintDTO,
} from "@rtslabs/field1st-fe-common";
import moment, { Moment } from "moment";

export const prettifyWorkorderID = (id: string | number) => {
  const workOrderId = id || "-";
  const workOrderIdLabel =
    typeof workOrderId === "string" ? workOrderId : `#${workOrderId}`;
  return workOrderIdLabel;
};

export const dateToString = (submissionDate: string): string | undefined => {
  if (submissionDate) {
    const now = moment();
    return moment(submissionDate).calendar(now, {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: () => {
        const diff = now.diff(submissionDate, "days");
        if (diff === 1) {
          return "[2 days ago]";
        }
        return `${diff} [days ago]`;
      },
      sameElse: () => {
        const diff = now.diff(submissionDate, "weeks");
        if (diff < 4) {
          return `${diff === 0 ? 1 : diff} [${
            diff === 1 || diff === 0 ? "week" : "weeks"
          } ago]`;
        } else {
          return "[28+ days ago]";
        }
      },
    });
  }
};

/**
 * Return the form's time of day submission constraint if it has one
 * @param formSubmissionConstraint form.formSubmissionConstraint object
 */
function getFormTimeLimit(
  formSubmissionConstraint?: FormSubmissionConstraintDTO | null
): string | undefined {
  if (
    formSubmissionConstraint &&
    formSubmissionConstraint.type === "TIME" &&
    formSubmissionConstraint.timeLimit &&
    formSubmissionConstraint.noSubmissionsUntilNextDay
  ) {
    const time = moment()
      .startOf("day")
      .seconds(formSubmissionConstraint.timeLimit);
    return moment(time).toLocaleString();
  }
}

/**
 * Return an error string if the current time is past the form submission
 * time constraint
 *
 * @param formSubmissionConstraint
 */
export function getSubmissionTimeError(
  formSubmissionConstraint?: FormSubmissionConstraintDTO | null
): string | undefined {
  const formTimeLimit = getFormTimeLimit(formSubmissionConstraint);
  const isPastSubmissionTime = moment().isAfter(moment(formTimeLimit));

  if (isPastSubmissionTime) {
    return `Selected form type cannot be started 
              ${
                formTimeLimit
                  ? `after ${moment(formTimeLimit).format("h:mm a")}`
                  : "until tomorrow"
              }`;
  }
}

export const prettifyDocumentStatus = (status: string) => {
  // Source is `document.statuses`
  switch (status) {
    case DocumentStatus.DELETED:
      return "Deleted";
    case DocumentStatus.SUBMITTED:
      return "Submitted";
    case DocumentStatus.IN_PROGRESS:
      return "In Progress";
    case DocumentStatus.NEW:
      return "New";
    default:
      return "N/A"; // BE's default case is `NA` -- GK
  }
};

export const displayWhen = (submissionDate?: string): string => {
  if (!submissionDate) {
    return "";
  }

  if (isToday(submissionDate)) {
    return moment(submissionDate).format("h A");
  }

  return renderHoursPassedSince(submissionDate);
};

const isToday = (date: string): boolean => {
  const TODAY = moment().clone().startOf("day");
  return moment(date).isSame(TODAY, "d");
};

export const renderHoursPassedSince = (givenDate: string | Moment): string => {
  if (
    (typeof givenDate === "string" && isNaN(Date.parse(givenDate))) ||
    !moment(givenDate).isValid()
  ) {
    return "";
  }

  return moment(givenDate).fromNow();
};
