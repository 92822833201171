import React from "react";

const DevAppFlag = ({ appName }: { appName: string }) => {
  if (window.location.hostname.indexOf("localhost") >= 0) {
    return (
      <div
        style={{
          position: "absolute",
          background: "rgba(252, 186, 3, .5)",
          padding: "2px 4px",
        }}
      >
        {appName}
      </div>
    );
  }

  return null;
};

export default DevAppFlag;
