import { useSelector } from "react-redux";
import { conjugateTerm } from "../conjugateTerm";
import { CoreRedux } from "@rtslabs/field1st-fe-common";

// Order is intentionally not alphabetical
interface Props {
  visibleId: string;
  role?: "verb" | "noun";
  modifier?: "past" | "future" | "plural" | "singular";
  fallback?: string;
}

/**
 * Hook for client group config terms
 * @visibleId - string identifier for term
 * @role  - function of speech
 * @modifier  - tense, plural/singular, etc
 */
const useGroupTerm = (
  visibleId: Props["visibleId"],
  role?: Props["role"],
  modifier?: Props["modifier"],
  fallback?: Props["fallback"]
) => {
  // Find term
  const term = useSelector((state: CoreRedux.Store.RootState) =>
    CoreRedux.selectTermByVisibleId(state, visibleId)
  );

  return conjugateTerm({
    term: term,
    role,
    modifier,
    fallback,
  });
};

export default useGroupTerm;
