import React, { FC, KeyboardEventHandler, MouseEventHandler } from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import styles from "./MenuItem.module.scss";
import { MenuOption } from "./TableRowMenu";

interface Props {
  isSelected: boolean;
  onSelect: (option: MenuOption, wasClicked?: boolean) => void;
  option: MenuOption;
}

export const MenuItem: FC<Props> = ({ isSelected, onSelect, option }) => {
  const { id, label } = option;

  const handleClick: MouseEventHandler<HTMLLIElement> = () => {
    onSelect(option, true);
  };

  return (
    <li
      aria-selected={isSelected ? true : undefined}
      className={joinClassNames(
        styles.option,
        isSelected && styles.optionSelected,
        option.variant === "error" && styles.optionError,
        option.variant === "success" && styles.optionSuccess
      )}
      data-testid={id}
      id={id}
      onClick={handleClick}
      role="menuitem"
    >
      {option.iconType && (
        <Icon
          className={joinClassNames(
            styles.icon,
            option.variant === "error" && styles.iconError,
            option.variant === "success" && styles.iconSuccess
          )}
          size={16}
          type={option.iconType}
        />
      )}
      <span className={styles.label}>{label}</span>
      <Icon className={styles.icon} size={24} type="chevron-right" />
    </li>
  );
};
