import React, { useState } from "react";
import moment from "moment";
import {
  API,
  CoreRedux,
  DocumentVm,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { useSelector } from "react-redux";

import Loader from "../Loader/Loader";

interface Props {
  id: DocumentVm["id"];
  className?: string;
  parentId: DocumentVm["parentId"];
  dateCreated: DocumentVm["dateCreated"];
}

export const RehuddleInfo = ({
  dateCreated,
  id,
  parentId,
  className,
}: Props) => {
  const [parentLoading, setParentLoading] = useState<boolean>(false);
  const [parentData, setParentData] = useState({
    formTypeName: "",
    documentTitle: "",
    documentOwner: "",
  });

  // White label
  const rehuddleGroupTerm = useSelector((state: CoreRedux.Store.RootState) =>
    CoreRedux.selectTermByVisibleId(state, "rehuddle")
  );
  const rehuddleTerm = rehuddleGroupTerm || "rehuddle";

  // Fetch parent details
  useAsyncEffect(async () => {
    if (parentId) {
      setParentLoading(true);
      try {
        const res = await API.getDocumentById({ id: parentId });
        setParentData({
          formTypeName: res.form.type.name,
          documentTitle: res.title,
          documentOwner: res.owner.name || "",
        });
      } finally {
        setParentLoading(false);
      }
    }
  }, [id, parentId]);

  return (
    <Loader
      loading={parentLoading}
      spinnerProps={{ size: 16, style: { lineHeight: 0 } }}
    >
      <p className={className}>
        This document is a {rehuddleTerm.toLowerCase()} from "
        {parentData.formTypeName}: {parentData.documentTitle}" by{" "}
        {parentData.documentOwner} on {moment(dateCreated).format("M/DD/YYYY")}{" "}
        at {moment(dateCreated).format("h:mma")}.
      </p>
    </Loader>
  );
};
