import React from "react";
import * as QA from "../../../../qa-slugs";
import { QAProps } from "../../../../qa-slugs";
import Label from "../../../Label/Label";
import { PhotoArea } from "../../../Photos/PhotoArea";
import { usePhotos } from "../usePhotos";
import styles from "./PhotoGallery.module.scss";

interface PhotoGalleryProps extends QAProps {
  name: string;
  appPath?: string;
}

export const PhotoGallery = ({ qa, name, appPath }: PhotoGalleryProps) => {
  const photoHandling = usePhotos(undefined, undefined, appPath);
  return (
    <div data-testid={qa}>
      <Label htmlFor={name} className={styles.title}>
        Photo Gallery
      </Label>
      <PhotoArea
        {...photoHandling}
        type="IMAGES_ABOVE"
        qa={
          qa
            ? `${qa}-${QA.Components.PhotoArea}-${QA.WidgetType.PhotoGallery}`
            : undefined
        }
      />
    </div>
  );
};
