import { GeoPoint, LatLng, WorkLocationDTO } from "@rtslabs/field1st-fe-common";
import {
  geocodeWithOpenStreetMap,
  reverseGeocodeWithOpenStreetMap,
} from "../util/openStreetMap";
import { getCurrentPosition } from "../util/geolocation";

export interface LocationMarker {
  geolocation?: GeoPoint;
  locationName?: string | null;
  onUpdate?: () => void;
  color?: string;
  borderColor?: string;
}

let geocoder: (
  address: string
) => Promise<{ geolocation: LatLng; address: string } | false> =
  geocodeWithOpenStreetMap;
let reverseGeocoder: (geolocation: LatLng) => Promise<string> =
  reverseGeocodeWithOpenStreetMap;

export const geocode = (
  address: string
): Promise<{ geolocation: LatLng; address: string } | false> => {
  return geocoder(address);
};

export const reverseGeocode = async (geolocation: LatLng): Promise<string> => {
  return reverseGeocoder(geolocation);
};

export const setGeocoder = (newGeocoder: typeof geocoder) => {
  geocoder = newGeocoder;
};

/** Set a custom reverse geocoder to use instead of OpenStreetMap. */
export const setReverseGeocoder = (
  newReverseGeocoder: typeof reverseGeocoder
) => {
  reverseGeocoder = newReverseGeocoder;
};

export const getLocation = async (
  workLocation: WorkLocationDTO | null | undefined
) => {
  try {
    const position = await getCurrentPosition();
    const location: LatLng = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    return location;
  } catch (error) {
    const geolocation = workLocation?.geolocation;
    const latitude = geolocation?.latitude;
    const longitude = geolocation?.longitude;

    if (!latitude || !longitude) {
      return null;
    }

    const location: LatLng = { latitude, longitude };
    return location;
  }
};
