import { useState, useCallback } from "react";

enum Key {
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  ArrowUp = "ArrowUp",
  Tab = "Tab",
}

function isFocusKeyboardEvent<ElementType = HTMLInputElement>(
  event: React.KeyboardEvent<ElementType>
): boolean {
  switch (event.key) {
    case Key.ArrowDown:
    case Key.ArrowLeft:
    case Key.ArrowRight:
    case Key.ArrowUp:
      return true;
    case Key.Tab:
      return !event.ctrlKey && !event.altKey;
    default:
      return false;
  }
}

interface UseKeyboardFocusResult<ElementType> {
  onBlur: React.FocusEventHandler<ElementType>;
  onFocus: React.FocusEventHandler<ElementType>;
  onKeyUp: React.KeyboardEventHandler<ElementType>;
  isKeyboardFocused: boolean;
}

/**
 * This provides a heuristic method of detecting whether an element is keyboard
 * focused. The heuristic should only fail if the blur event occurs before
 * the key release event is fired. If it fails, it will prefer to return false
 * for 'isKeyboardFocused'.
 *
 * One drawback is that this does not detect auto focus or other explicit calls
 * to 'Element.focus()'.
 *
 * All event handlers are expected to be attached to the same element for the
 * hook to function correctly.
 */
export function useKeyboardFocus<
  ElementType = HTMLInputElement
>(): UseKeyboardFocusResult<ElementType> {
  const [hasFocusOccurred, setHasFocusOccurred] = useState(false);
  const [isKeyboardFocused, setIsKeyboardFocused] = useState(false);

  const handleBlur: React.FocusEventHandler<ElementType> = useCallback(() => {
    setHasFocusOccurred(false);
    setIsKeyboardFocused(false);
  }, []);

  const handleFocus: React.FocusEventHandler<ElementType> = useCallback(() => {
    setHasFocusOccurred(true);
  }, []);

  const handleKeyUp: React.KeyboardEventHandler<ElementType> = useCallback(
    (event) => {
      if (hasFocusOccurred && isFocusKeyboardEvent(event)) {
        setIsKeyboardFocused(true);
      }
    },
    [hasFocusOccurred]
  );

  return {
    onBlur: handleBlur,
    onFocus: handleFocus,
    onKeyUp: handleKeyUp,
    isKeyboardFocused,
  };
}
