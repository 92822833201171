import fuzzysort from "fuzzysort";
import React, { FC, useEffect, useState } from "react";
import { Icon } from "../../Icon/Icon";
import { TextInput } from "../../TextInput/TextInput";
import { joinClassNames } from "../../../helpers/theme.helpers";
import * as QA from "../../../qa-slugs";
import { Button } from "../../Button/Button";
import Label from "../../Label/Label";
import ColorPicker from "../../common/form/ColorPicker";
import { availableIcons } from "./availableIcons";
import defaultStyles from "./IconSelector.module.scss";

export interface IconValue {
  icon: string;
  color: string;
}

interface IconSelectorProps {
  disabled?: boolean;
  error?: { icon?: string; color?: string };
  label?: string;
  /**
   * Interactions with the color picker trigger lots of state updates.
   * onChange handlers should either be simple or throttled
   */
  onChange: (value: IconValue) => void;
  onAddClick?: () => void;
  onCancelClick?: () => void;
  onClearClick?: () => void;
  qaBase?: string;
  required?: boolean;
  value?: IconValue;
  propStyles?: {
    searchInputWrapper?: string;
    selectionArea?: string;
  };
}
export const IconSelector: FC<IconSelectorProps> = ({
  disabled,
  error,
  label,
  onChange,
  qaBase = QA.Components.IconSelector,
  required,
  value = { icon: "", color: "#000000" },
  propStyles,
  onAddClick,
  onCancelClick,
  onClearClick,
}) => {
  const [search, setSearch] = useState("");
  const [filteredList, setFilteredList] = useState(availableIcons);
  const { icon, color } = value;

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--icon-color", color);
  }, [color]);

  useEffect(() => {
    if (!search) setFilteredList(availableIcons);
    else
      setFilteredList(
        fuzzysort.go(search, availableIcons).map((l) => l.target)
      );
  }, [search]);

  function getErrorText() {
    if (!error) return;
    if (!error.color) return error.icon;
    if (!error.icon) return error.color;
    return (
      <span>
        {error.color}
        <br />
        {error.icon}
      </span>
    );
  }

  return (
    <div
      className={joinClassNames(
        (error?.color || error?.icon) && defaultStyles.error
      )}
    >
      <TextInput
        label={label}
        placeholder="Type to Filter"
        value={disabled ? value.icon : search}
        onChange={(e) => setSearch(e.target.value)}
        wrapperClassName={joinClassNames(
          defaultStyles.filterInput,
          propStyles?.searchInputWrapper
        )}
        labelClass={defaultStyles.label}
        qa={`${qaBase}-${QA.Components.IconFilter}`}
        required={required}
        disabled={disabled}
      />
      {!disabled && (
        <div
          className={joinClassNames(
            defaultStyles.selectionArea,
            propStyles?.selectionArea
          )}
        >
          {filteredList.map((i, idx) => (
            <button
              onClick={() => onChange({ color, icon: i })}
              className={joinClassNames(
                defaultStyles.icon,
                i === value.icon && defaultStyles.selectedIcon
              )}
              data-testid={`${qaBase}-${QA.Components.IconSelectionItem}-${i}`}
              key={idx}
              type="button"
            >
              <Icon type={i} color={color} size={22} />
            </button>
          ))}
        </div>
      )}
      <div className={defaultStyles.outputRow}>
        <div className={defaultStyles.colorContainer}>
          <Label
            required={required}
            htmlFor="colorPicker"
            className={defaultStyles.colorPickerLabel}
          >
            Icon Color
          </Label>
          <ColorPicker
            onColorChange={(val: string) => onChange({ color: val, icon })}
            colorValue={color}
            name="colorPicker"
            withText
            data-testid={`${qaBase}-${QA.Components.IconColorPicker}`}
            required={required}
            disabled={disabled}
            propStyles={{
              colorInput: defaultStyles.colorPicker,
              textInput: defaultStyles.colorPickerHexInput,
            }}
          />
        </div>
        <div className={defaultStyles.previewContainer}>
          <Label htmlFor="preview" className={defaultStyles.colorPickerLabel}>
            Icon Preview
          </Label>
          <div className={defaultStyles.previewRow}>
            <output
              id="preview"
              className={defaultStyles.preview}
              data-testid={`${qaBase}-iconPreview`}
            >
              <Icon
                type={icon}
                size={22}
                data-testid={`${qaBase}-${QA.Components.IconSelectionPreview}`}
              />
            </output>
            {onClearClick && value?.icon && (
              <span
                onClick={onClearClick}
                className={defaultStyles.clearButton}
                data-testid={`${qaBase}-clearButton`}
              >
                Clear
              </span>
            )}
          </div>
        </div>
        <div className={defaultStyles.buttonRow}>
          {onCancelClick && (
            <span
              className={defaultStyles.cancelButton}
              onClick={onCancelClick}
              data-testid={`${qaBase}-cancelButton`}
            >
              Cancel
            </span>
          )}
          {onAddClick && (
            <Button
              className={defaultStyles.addButton}
              onClick={onAddClick}
              qa={`${qaBase}-addButton`}
            >
              Add
            </Button>
          )}
        </div>
        {error && (
          <span
            className={defaultStyles.errorText}
            data-testid={`${qaBase}-${QA.Components.IconErrorText}`}
          >
            {getErrorText()}
          </span>
        )}
      </div>
    </div>
  );
};
