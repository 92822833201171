import React, { FC } from "react";

interface ClassName {
  className?: string;
}
export const Add: FC<ClassName> = ({ className }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Icon / Actions / Add">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6332 21.2624C13.622 22.0582 12.9572 22.7007 12.1339 22.7116C11.278 22.723 10.5811 22.0494 10.5929 21.2222L10.706 13.2409L2.44866 13.3503C1.59277 13.3616 0.895926 12.6881 0.907651 11.8608C0.918931 11.065 1.58365 10.4225 2.40701 10.4116L10.9214 10.2989L11.0381 2.06918C11.0494 1.27336 11.7141 0.630869 12.5374 0.619965C13.3933 0.608629 14.0902 1.28217 14.0785 2.10944L13.9653 10.0907L22.2227 9.98135C23.0786 9.97002 23.7754 10.6436 23.7637 11.4708C23.7524 12.2666 23.0877 12.9091 22.2643 12.92L13.7499 13.0328L13.6332 21.2624Z"
        />
      </g>
    </svg>
  );
};
