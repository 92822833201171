import React, { FC, MouseEventHandler, useMemo } from "react";
import {
  BarDatum,
  BarItemProps,
  ComputedDatum,
  ResponsiveBar,
} from "@nivo/bar";
import styles from "./PercentLineGraph.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { QAProps } from "shared/src/qa-slugs";

interface PercentLineGraphDatum<T = any> {
  color: string;
  id: string;
  label?: string;
  qa?: string;
  source?: T;
  value: number;
}

interface PercentLineGraphBarDatum extends BarDatum {
  qa: string;
}

interface PercentLineGraphProps<T = any> extends QAProps {
  ariaLabel: string;
  className?: string;
  data: PercentLineGraphDatum<T>[];
  onClick?: (datum: PercentLineGraphDatum<T>) => void;
}

export const PercentLineGraph: FC<PercentLineGraphProps> = ({
  ariaLabel,
  className,
  data,
  onClick,
  qa,
}) => {
  // This component uses a single stacked bar as the entire graph. Since the bar
  // chart expects the data to be in a fairly different format, we need to
  // transform it.
  const barData = useMemo(() => {
    const valueProps = Object.fromEntries(
      data.map((entry) => [entry.id, entry.value])
    );
    const colorProps = Object.fromEntries(
      data.map((entry) => [`${entry.id}Color`, entry.color])
    );
    const qaProps = Object.fromEntries(
      data.map((entry) => [`${entry.id}QA`, entry.qa])
    );
    // Make the id always the same so that there's only one bar in the bar chart.
    const result: PercentLineGraphBarDatum[] = [
      {
        ...valueProps,
        ...colorProps,
        ...qaProps,
        id: "data",
        qa: qa ?? "",
      },
    ];
    return result;
  }, [data]);

  const keys = useMemo(() => {
    return data.map((item) => item.id);
  }, [data]);

  const handleClick = (
    datum: ComputedDatum<BarDatum> & {
      color: string;
    }
  ) => {
    const foundDatum = data.find((d) => d.id === datum.id);
    if (onClick && foundDatum) {
      onClick(foundDatum);
    }
  };

  return (
    <div className={joinClassNames(styles.graphWrapper, className)}>
      <ResponsiveBar
        animate={false}
        ariaLabel={ariaLabel}
        axisBottom={undefined}
        axisLeft={undefined}
        barComponent={Bar}
        colors={({ id, data }) => String(data[`${id}Color`])}
        data={barData}
        enableGridY={false}
        enableLabel={false}
        indexBy="id"
        keys={keys}
        layout="horizontal"
        onClick={handleClick}
        padding={0}
        // Disable the tooltip by passing an empty component. Nivo doesn't have
        // a way to disable it other than passing false to the isInteractive
        // prop. But we need that to be true so we can click the bars.
        tooltip={() => <></>}
      />
    </div>
  );
};

const Bar = ({
  bar: { color, data, height, width, x, y },
  onClick,
  onMouseEnter,
  onMouseLeave,
}: BarItemProps<PercentLineGraphBarDatum>) => {
  const handleClick: MouseEventHandler<SVGRectElement> | undefined = onClick
    ? (event) => onClick({ ...data, color }, event)
    : undefined;

  const handleMouseEnter: MouseEventHandler<SVGRectElement> | undefined =
    onMouseEnter ? (event) => onMouseEnter(data, event) : undefined;

  const handleMouseLeave: MouseEventHandler<SVGRectElement> | undefined =
    onMouseLeave ? (event) => onMouseLeave(data, event) : undefined;

  const qaPrefix = data.data.qa;
  const qaSuffix = data.data[`${data.id}QA`];
  const qa = qaPrefix ? `${qaPrefix}-${qaSuffix}` : undefined;

  return (
    <rect
      data-testid={qa}
      focusable={false}
      fill={color}
      height={height}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      width={width}
      x={x}
      y={y}
    />
  );
};
