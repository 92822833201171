import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Select, SelectOption } from "../../../../Select/Select";
import { TextInput } from "../../../../TextInput/TextInput";
import { Components, ElementType } from "../../../../../qa-slugs";
import { FBForm } from "../../../types";
import s from "../styles.module.scss";

const options: SelectOption<"hours" | "days">[] = [
  { label: "hours", value: "hours" },
  { label: "days", value: "days" },
];

interface TimeLimitOptionProps {
  error: any;
  formSubmissionType: any;
  setFormSubmissionType: any;
}

const TimeLimitOption = ({
  error,
  formSubmissionType,
  setFormSubmissionType,
}: TimeLimitOptionProps) => {
  const { values, setFieldValue, errors } = useFormikContext<FBForm>();

  const [hourLimitField] = useField("formSubmissionConstraint.hourLimit");

  const [hourTypeField] = useField("formSubmissionConstraint.hourType");

  const { hourLimit, hourType } = values.formSubmissionConstraint || {};
  const [time, setTime] = useState<number | string>(hourLimit || 24);

  function handleChange(option: SelectOption<string> | null) {
    setFormSubmissionType("HOUR");
    setFieldValue(
      "formSubmissionConstraint.hourType",
      option?.value || "hours"
    );
    setFieldValue("formSubmissionConstraint.hourLimit", time);
  }

  // if the parent radio button is selected/unselected we want to handle the change
  useEffect(() => {
    if (!hourLimit) {
      setFieldValue("formSubmissionConstraint.hourLimit", time);
    }
    if (values.formSubmissionConstraint?.type === "HOUR") {
      handleChange(options.find((o) => o.value === hourType) || null);
    }
  }, [values.formSubmissionConstraint?.type, time]);

  return (
    <>
      <div className={s.editTimeframeOption}>
        <label htmlFor="hourLimit">Time limit of</label>
        <div className={s.timeLimitInput}>
          <TextInput
            {...hourLimitField}
            qa={`${Components.FormSettings}-${ElementType.TextInput}-hourLimit`}
            wrapperClassName={s.timeLimitInput}
            id={"hourLimit"}
            className={s.timeLimitInput}
            type="number"
            value={time}
            onChange={(e) => {
              setFormSubmissionType("HOUR");
              const value = parseInt(e.target.value);
              value >= 0 ? setTime(value) : setTime("");
            }}
            onBlur={() => (!time ? setTime(24) : undefined)}
            onKeyDown={(event) => {
              if (/[.-]/g.test(event.key)) event.preventDefault();
            }}
            error={
              !!(
                (errors.formSubmissionConstraint as any)?.hourLimit &&
                formSubmissionType === "HOUR"
              )
            }
          />
        </div>
        <Select
          {...hourTypeField}
          qa={`${Components.FormSettings}-${ElementType.SelectInput}-timeLimitUnit`}
          className={s.timeLimitUnit}
          wrapperClassName={s.timeLimitUnit}
          value={hourType || "hours"}
          options={options}
          onChange={handleChange}
        />
      </div>
      {error && values.formSubmissionConstraint?.type === "HOUR" && (
        <span className={s.errorText}>{error}</span>
      )}
    </>
  );
};

export default TimeLimitOption;
