import React, { useState } from "react";
import { Formik } from "formik";

import * as US from "../addUser/styles";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import Loader from "shared/src/components/Loader/Loader";
import {
  API,
  ClientGroupSaveVm,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { PageContent } from "shared/src/components/clientAdmin/styles";
import { ErrorToast } from "shared/src/components/Toast/ErrorToast";
import { Components, Page } from "shared/src/qa-slugs";
import { useNavigate, useParams } from "react-router-dom";

// Constants

type ErrorType = {
  externalGroupId?: string;
  name?: string;
};
const validate = (values) => {
  // Validates Formik form
  const errors: ErrorType = {};
  if (values) {
    if (!values.name) {
      errors.name = "Group Name is required";
    }
    if (!values.externalGroupId) {
      errors.externalGroupId = "Group ID is required";
    }
  }
  return errors;
};

interface Params extends Record<string, string> {
  id: string;
}

const AddGroup: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  // Get group id from url
  const groupId = params.id ? Number(params.id) : undefined;

  // Current group
  const [currentGroup, setCurrentGroup] = useState<ClientGroupSaveVm>();
  const [currentGroupLoading, setCurrentGroupLoading] = useState(false);

  // Error toast visible
  const [errorToastVisible, setErrorToastVisible] = useState<boolean>(false);

  // Async form submit loading (API)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  // On mount
  useAsyncEffect(async () => {
    if (groupId) {
      setCurrentGroupLoading(true);
      try {
        const res = await API.getClientGroupById({ id: groupId });
        setCurrentGroup(res);
      } catch (error) {
        console.error(error); // todo
      } finally {
        setCurrentGroupLoading(false);
      }
    }
  }, [groupId]);

  // Submits the form
  const _handleSubmit = async (values) => {
    const { externalGroupId, name } = values;
    let group: ClientGroupSaveVm = {
      id: groupId,
      externalGroupId,
      name,
    };
    setSubmitLoading(true);
    try {
      await API.saveClientGroup({ group });
      setSubmitLoading(false);
      navigate(-1);
    } catch (error) {
      setErrorToastVisible(true);
      setSubmitLoading(false);
    }
  };

  const addEditGroupPathName = groupId
    ? `Edit ${currentGroup?.name} Group`
    : "Add New Group";

  const addEditGroupHref = groupId
    ? `/people/groups/view/${groupId}`
    : "/people/groups/add-group";

  return (
    <PageContent>
      <div className="col-12">
        <Breadcrumbs
          paths={[
            {
              pathName: "People",
            },
            {
              pathName: "Groups",
              href: "/people/groups",
            },
            {
              pathName: addEditGroupPathName,
              href: addEditGroupHref,
            },
          ]}
        />
      </div>
      <Loader loading={currentGroupLoading}>
        <div
          className="col-12 mt-3 mb-3"
          data-testid={`${Page.Desktop}-${Components.EditGroup}`}
        >
          <Formik
            initialValues={
              currentGroup || {
                name: "",
                externalGroupId: "",
              }
            }
            onSubmit={(values, { setFieldTouched }) => {
              // Set all fields to touched
              setFieldTouched("name", true, true);
              _handleSubmit(values);
            }}
            enableReinitialize
            validate={validate}
            validateOnChange={false}
            // validateOnBlur={false}
          >
            {(props) => {
              const { errors, handleSubmit, setValues, touched, values } =
                props;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="pl-0 pr-0">
                    <div style={{ width: "398px" }}>
                      <TextInput
                        error={touched["name"] && errors["name"]}
                        label="Group Name"
                        name="name"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            // externalGroupId: getExternalGroupId(e.target.value),
                            name: e.target.value,
                          });
                        }}
                        placeholder="Group Name"
                        value={values["name"]}
                      />
                    </div>
                    <div style={{ width: "398px" }}>
                      <TextInput
                        error={
                          touched["externalGroupId"] &&
                          errors["externalGroupId"]
                        }
                        label="Group ID"
                        name="externalGroupId"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            externalGroupId: e.target.value,
                          });
                        }}
                        placeholder="Group ID"
                        value={values["externalGroupId"]}
                      />
                    </div>
                  </div>

                  <ErrorToast
                    onClick={() => setErrorToastVisible(false)}
                    visible={errorToastVisible}
                  >
                    Failed to {groupId ? "Edit" : "Add"} Group
                  </ErrorToast>
                  <br />
                  <US.ButtonsWrapper>
                    <US.AddUserButton disabled={submitLoading}>
                      <US.LoaderWrapper>
                        <Loader
                          loading={submitLoading}
                          className="p-0 mr-3"
                          spinnerProps={{ size: 14 }}
                        />
                      </US.LoaderWrapper>
                      <span>{`${groupId ? "edit" : "add"}`} group </span>
                    </US.AddUserButton>
                    <US.CancelButton
                      disabled={submitLoading}
                      onClick={() => navigate(-1)}
                    >
                      cancel
                    </US.CancelButton>
                  </US.ButtonsWrapper>
                </form>
              );
            }}
          </Formik>
        </div>
      </Loader>
    </PageContent>
  );
};

export default AddGroup;
