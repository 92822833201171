import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ContentWrapper } from "shared/src/components/Wrappers/Wrappers";
import Loader from "shared/src/components/Loader/Loader";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";

import {
  API,
  BasicDataSourceValue,
  DataSource,
} from "@rtslabs/field1st-fe-common";
import s from "./styles.module.scss";
import { DSForm } from "./DSForm";
import { RouteParams } from "./types";
import withTheme from "@mui/styles/withTheme";
import { AppState } from "../../../store";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import { sortBy } from "lodash";

/* Component behavior is dictated by the existence of an "id" param in the URL:
 * 1. "id" exists: user is editing an existing source. fetch source and pre-fill form.
 * 2. "id" undefined: user is creating a new source. provide empty form. */

/** Parent view for adding, editing and viewing a basic Data Set */
function BasicDS() {
  const navigate = useNavigate();
  const params = useParams<RouteParams>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<DataSource | null>(null);
  // TODO handle data source values for delimited data list -JA
  const [dataSourceValues, setDataSourceValues] = React.useState<string[]>([]);
  const authToken = useSelector(({ auth }: AppState) => auth.token);

  React.useEffect(() => {
    (async () => {
      const dataSourceId = Number(params.id);
      if (!isNaN(dataSourceId) && authToken) {
        try {
          setLoading(true);
          const ds = await API.getDataSourceById({ id: dataSourceId });
          const dsv =
            await API.getAllDataSourceValuesByDataSourceId<BasicDataSourceValue>(
              { dataSourceId }
            );
          setDataSource(ds);
          const sorted = sortBy(dsv, ["content.displayOrder", "content.value"]);
          setDataSourceValues(sorted.map((value) => value.content.value));
        } catch (err) {
          // TODO error handling -JA
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  return (
    <ContentWrapper>
      <Loader loading={loading}>
        <Breadcrumbs
          paths={[
            { pathName: "Forms" },
            { pathName: "Data Sets", href: "/forms/data-sets" },
            {
              pathName: `Data Set${
                dataSource?.title ? `: ${dataSource.title}` : ""
              }`,
            },
          ]}
        />
        <h1
          className={s.title}
          data-testid={`${Page.Desktop}-${Components.BasicDS}`}
        >
          {dataSource?.title ? dataSource.title : "Create a Basic Data Set"}
        </h1>
        {!dataSource && (
          <span className={s.subtitle}>
            Creating a basic data set is a quick way to include single item
            lists as answer options on forms. To create a basic data set, assign
            a title and description, then copy/paste or write out a list with
            each item on a new line. If you would like to create a data set with
            multiple properties per item or import by CSV,&nbsp;
            <span
              className={s.link}
              data-testid={`${Components.BasicDS}-${ElementType.Link}-createAdvancedDataSet`}
              onClick={() => navigate("/forms/data-sets/uploaded")}
            >
              upload a data set instead.
            </span>
          </span>
        )}
        <DSForm
          dataSource={dataSource}
          dataSourceValues={dataSourceValues}
          setDataSource={setDataSource}
        />
      </Loader>
    </ContentWrapper>
  );
}

export default withTheme(BasicDS);
