import { Moment } from "moment";
import React, { forwardRef, MouseEventHandler } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./DateButton.module.scss";
import { Calendar } from "../../assets/svg/calendar";
import { useKeyboardFocus } from "../../util/hooks/useKeyboardFocus";

interface DateButtonProps {
  handleClick: (
    anchor: HTMLButtonElement | null,
    isKeyboardFocused: boolean
  ) => void;
  isActive: boolean;
  label: string;
  labelId: string;
  name: string;
  selectedDate: Moment | null;
  disabled?: boolean;
}

export const DateButton = forwardRef<HTMLButtonElement, DateButtonProps>(
  (
    { handleClick, isActive, label, labelId, name, selectedDate, disabled },
    ref
  ) => {
    const { isKeyboardFocused, ...eventHandlers } =
      useKeyboardFocus<HTMLButtonElement>();
    const buttonLabelId = `${name}-label`;
    const valueId = `${name}-value`;

    const localHandleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      handleClick(event.currentTarget, isKeyboardFocused);
    };

    return (
      <>
        <label className="sr-only" id={buttonLabelId}>
          {label}
        </label>
        <button
          aria-labelledby={[valueId, buttonLabelId, labelId].join(" ")}
          className={joinClassNames(
            styles.dateButton,
            isActive && styles.dateButtonActive,
            disabled && styles.disabled,
            isKeyboardFocused
              ? styles.dateButtonKeyboardFocused
              : styles.dateButtonMouseFocused
          )}
          onClick={localHandleClick}
          ref={ref}
          type="button"
          {...eventHandlers}
          disabled={disabled}
        >
          <Calendar
            className={joinClassNames(
              styles.calendarIcon,
              isActive && styles.calendarIconActive
            )}
          />
          <span id={valueId}>
            {selectedDate ? selectedDate.format("l") : "mm/dd/yyyy"}
          </span>
        </button>
      </>
    );
  }
);
