import React, { FC, RefObject } from "react";
import { Question } from "./Question";
import { QuestionDTO } from "@rtslabs/field1st-fe-common";
import { QAProps } from "../../../qa-slugs";
import styles from "../DocumentForm/documentForm.module.scss";

interface QuestionWrapperProps extends QAProps {
  itemRefs: Map<number, RefObject<HTMLDivElement>>;
  item: QuestionDTO;
  appPath?: string;
}

export const QuestionWrapper: FC<QuestionWrapperProps> = ({
  itemRefs,
  item,
  qa,
  appPath,
}) => {
  //   const questionResponses =
  //     responses.filter(
  //       (response) => response.questionRootId === item.rootId
  //     ) || [];
  return (
    <div
      data-testid={`${qa}-question-${item.id}`}
      //   className={
      //     isDecisionBlock ? styles.decisionBlock : styles.mainBlock
      //   }
      className={styles.mainBlock}
    >
      <Question
        question={item}
        questionRef={itemRefs.get(item.id)}
        appPath={appPath}
        // disabled={isQuestionDisabled}
      />
      {/* Show the safety rating scale */}
      {/* {!!questionResponses.length &&
                shouldShowSafetyScale(item, safetyRatingWidgets) && (
                  <RatingScale />
                )} */}
    </div>
  );
};
