import React, { FC } from "react";
import { LinkButton } from "../../../Button/Button";

interface Props {
  onClick: () => void;
}

export const ChangeTypeButton: FC<Props> = ({ onClick }) => {
  return (
    <LinkButton
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      Change
    </LinkButton>
  );
};
