import { useEffect, useMemo, useState } from "react";
import {
  API,
  QuestionDTO,
  QuestionSelectionsDTO,
  ReportingFormStatsVm,
} from "@rtslabs/field1st-fe-common";
import isEqual from "lodash/isEqual";

import { SafetyResponse } from "./FormReport";

export const useFetchComments = (
  formId: number,
  responses: SafetyResponse[]
) => {
  const [selectionCommentsMap, setSelectionCommentsMap] = useState<null | {
    [key: number]: any;
  }>(null);
  const [priorSelectionIds, setPriorSelectionIds] = useState<number[]>([]);
  useEffect(() => {
    const selectionIds = responses.map(({ selectionId }) => selectionId);
    if (isEqual(selectionIds.sort(), priorSelectionIds.sort())) {
      return;
    }
    setPriorSelectionIds(selectionIds);
    const promises = selectionIds.map((questionSelectionId) =>
      API.getQuestionSelectionComments({ formId, questionSelectionId })
    );
    Promise.all(promises)
      .then((value) => {
        const response = {};
        selectionIds.forEach((id, i) => (response[id] = value[i]));
        setSelectionCommentsMap(response);
      })
      .catch((e) => console.log(e));
  }, [responses]);
  return useMemo(
    () =>
      selectionCommentsMap
        ? responses.map((response) => {
            return {
              comments: selectionCommentsMap[response.selectionId].content,
              id: response.selectionId,
              title: response.question.title,
            };
          })
        : [],
    [selectionCommentsMap]
  );
};

type Response = {
  area: string;
  question: QuestionDTO;
  topResponses: Array<{ response: string; responseCount: number }>;
  totalResponses: number;
};

/**
 * Compile a list of question responses that are of type "RATING"
 * @param formStatsData - data from stats endpoint
 */
const getRatingQuestions = (formStatsData): Array<Response> =>
  // for each question in form stat
  formStatsData.questions.reduce((result, statQuestion) => {
    if (statQuestion.question.subType === "RATING") {
      result.push({
        ...statQuestion,
        area: statQuestion.sectionTitle, // Maps to `area` key to communicate what it's used for
      });
    }
    return result;
  }, []);

export type FormReportSafetyResponses = {
  Risk: Array<SafetyResponse>;
  "Coaching Moment": Array<SafetyResponse>;
  Satisfactory: Array<SafetyResponse>;
  "Best Practice": Array<SafetyResponse>;
};

function mapRiskResponses(
  ratingQuestions: Array<Response>,
  responses: FormReportSafetyResponses
): FormReportSafetyResponses {
  let selection: QuestionSelectionsDTO | null = null;
  // for each response in each rating question
  return ratingQuestions.reduce((result, question) => {
    question.topResponses.forEach((response) => {
      // if the risk type is a key in responses (declared above)
      if (response.response in responses) {
        if (question && question.question && question.question.selections) {
          // find that selection
          selection =
            question.question.selections.find(
              (sel) => sel.title === response.response
            ) || null;
          // if found, add risk response to mapped responses
          if (selection?.id) {
            responses[response.response].push({
              question: question.question,
              responseCount: response.responseCount,
              area: question.area,
              selectionId: selection.id,
            });
          }
        }
      }
    });
    return result;
  }, responses);
}

/**
 * Sum response counts for all risk question responses
 * @param responses
 */
const responseCount = (responses: FormReportSafetyResponses): number =>
  Object.keys(responses).reduce((count, responseKey) => {
    responses[responseKey].forEach(
      (response) => (count += response.responseCount)
    );
    return count;
  }, 0);

export interface RatingQuestionResponses {
  mappedResponses: FormReportSafetyResponses;
  totalResponses: number;
  SO_FLAG: boolean;
}

/**
 * Generate statistics and options for rating filter based on form questions and stats
 * @param formStats - form statistics
 */
export const useRatingQuestions = (
  formStats: ReportingFormStatsVm | undefined | null
): RatingQuestionResponses => {
  const baseResponses = {
    Risk: [],
    "Coaching Moment": [],
    Satisfactory: [],
    "Best Practice": [],
  };
  let ratingQuestions: Array<Response> = [];
  let mappedResponses: FormReportSafetyResponses = baseResponses;
  let totalResponses = 0;

  // When useRatingQuestions() runs a second time, these 3 values above are reset and the block below
  // doesn't run anymore, until you pull data or change the arrays. useMemo() could probably be outside
  // in parent, but then all the hooks inside have to be moved outside.
  // useMemo(() => {
  //   if (formStats.data && form.data) {
  //     ratingQuestions = getRatingQuestions(formStats.data, form.data);
  //     mappedResponses = mapRiskResponses(ratingQuestions, baseResponses);
  //     totalResponses = responseCount(mappedResponses);
  //   }
  // }, [formStats.data, form.data]);

  if (formStats) {
    ratingQuestions = getRatingQuestions(formStats);
    mappedResponses = mapRiskResponses(ratingQuestions, baseResponses);
    totalResponses = responseCount(mappedResponses);
  }
  return {
    mappedResponses,
    totalResponses,
    SO_FLAG: ratingQuestions.length > 0,
  };
};
