import {
  API,
  DataSourceDTO,
  isNetworkError,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { ApiDataSourceConfigVm } from "@rtslabs/field1st-fe-common/src";
import { FormikHelpers } from "formik";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../../../shared/src/components/Breadcrumbs/Breadcrumbs";
import Loader from "../../../../../shared/src/components/Loader/Loader";
import { ErrorToast } from "../../../../../shared/src/components/Toast/ErrorToast";
import { Components, Page } from "../../../../../shared/src/qa-slugs";
import { getUpdatedDataSourceDTO } from "../../../data/updateDataSource";
import { APIForm } from "./apiDSHelpers";
import { APIFormikForm } from "./APIFormikForm";
import s from "./styles.module.scss";
import * as Sentry from "@sentry/browser";

interface Params extends Record<string, string> {
  id: string;
}

const APIDS = () => {
  const [existingDataSource, setExistingDataSource] = useState<DataSourceDTO>();
  const [isLoadingDataSource, setIsLoadingDataSource] =
    useState<boolean>(false);
  const [isLoadingTest, setIsLoadingTest] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string>();
  const [isTestSuccessful, setIsTestSuccessful] = useState<boolean>(false);
  const [testResponse, setTestResponse] = useState<string>("");
  const navigate = useNavigate();
  const params = useParams<Params>();
  const id = useMemo(
    () => (params.id ? parseInt(params.id) : undefined),
    [params.id]
  );

  const initialValues: APIForm = {
    name: existingDataSource?.title ?? "",
    description: existingDataSource?.description ?? "",
    uniqueIdFieldName: existingDataSource?.uniqueIdField ?? "",
    datasetKey: existingDataSource?.dataSourceKey ?? "",
    url: existingDataSource?.apiConfig?.url ?? "",
    paginated: existingDataSource?.apiConfig?.paginated ?? false,
    data: "",
  };

  const closeErrorToast = () => {
    setSaveError(undefined);
  };

  const handleCancelForm = () => {
    navigate("/forms/data-sets");
  };

  const handleSubmitForm = async (
    values: APIForm,
    formikHelpers: FormikHelpers<APIForm>
  ) => {
    try {
      const dataSource = getUpdatedDataSourceDTO(values, existingDataSource);
      const updatedDataSource = existingDataSource
        ? await API.updateDataSource({ dataSource })
        : await API.createDataSource({ dataSource });
      setExistingDataSource(updatedDataSource);
      navigate("/forms/data-sets");
    } catch (error) {
      setSaveError("Failed to save the data set.");
    }
  };

  const handleTest = async (values: APIForm) => {
    setIsLoadingTest(true);
    try {
      const config: ApiDataSourceConfigVm = {
        paginated: values.paginated,
        url: values.url,
      };
      const testResponse = await API.testApiDataSource(config);
      setTestResponse(JSON.stringify(testResponse, null, 2));
      setIsTestSuccessful(true);
    } catch (error) {
      if (error instanceof Error) {
        setTestResponse(error.message);
      } else {
        setTestResponse("Failed to connect to the API.");
      }
      setIsTestSuccessful(false);
    } finally {
      setIsLoadingTest(false);
    }
  };

  useAsyncEffect(async () => {
    if (id) {
      setIsLoadingDataSource(true);
      try {
        const dataSource = await API.getDataSourceById({ id });
        setExistingDataSource(dataSource);
      } catch (error) {
        if (
          isNetworkError(error) &&
          error.status >= 400 &&
          error.status < 500 &&
          error.status !== 422
        ) {
          setSaveError(error.message);
        } else {
          Sentry.captureException(error);
          setSaveError("Failed to load the Data Source.");
        }
      } finally {
        setIsLoadingDataSource(false);
      }
    }
  }, [id]);

  return (
    <div id="mainContent">
      <ErrorToast
        onClick={closeErrorToast}
        onDelay={closeErrorToast}
        onDelayTime={5000}
        visible={!!saveError}
      >
        {`Failed to save. ${saveError}`}
      </ErrorToast>
      <Breadcrumbs
        paths={[
          { pathName: "Forms" },
          { pathName: "Data Sets", href: "/forms/data-sets" },
          {
            pathName: "Utilize API",
          },
        ]}
      />
      <h1
        className={s.title}
        data-testid={`${Page.Desktop}-${Components.APIDS}`}
      >
        Utilize API
      </h1>
      <span className={s.subtitle}>
        API data sets allow for access to 3rd party information. The data sets
        gathered with an API can then be used when building a form in Form
        Builder.
      </span>

      <Loader loading={isLoadingDataSource}>
        <APIFormikForm
          initialValues={initialValues}
          isLoadingTest={isLoadingTest}
          isTestSuccessful={isTestSuccessful}
          onCancel={handleCancelForm}
          onSubmit={handleSubmitForm}
          onTest={handleTest}
          testResponse={testResponse}
        />
      </Loader>
    </div>
  );
};

export default APIDS;
