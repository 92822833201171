import { zipObject } from "lodash";
import {
  DefenseDTO,
  DocumentQuestionResponseVm,
  EsriContentWidgetDTO,
  FormItemType,
  FormSectionVm,
  FormVm,
  Functions,
  QuestionDTO,
  SafetyRatingWidgetDTO,
  SectionItem,
} from "@rtslabs/field1st-fe-common";

/**
 * Extract all items of a form from each of its sections
 * @param sections
 * @param types
 */
export function getFlattenedQuestions(
  sections: FormSectionVm[],
  types?: Array<string>
): Array<QuestionDTO> {
  return sections.reduce((result: Array<QuestionDTO>, section) => {
    section.items?.forEach((item) => {
      if (item.type === "QUESTION") {
        if (!types || types.length === 0) {
          result.push(item as QuestionDTO);
        } else if (types && types.includes(item.subType)) {
          result.push(item as QuestionDTO);
        }
      }
    });
    return result;
  }, []);
}

/**
 * Extract all items of a form from each of its sections
 * @param sections
 * @param itemType - optional: only return items of a specific type
 * @param subTypes - optional: only return items matching specific subtypes
 */
export function getFlattenedItems(
  sections: FormSectionVm[],
  itemType?: FormItemType,
  types?: string[]
): SectionItem[] {
  return sections.reduce((result: SectionItem[], section) => {
    section.items?.forEach((item) => {
      if (!itemType) {
        // if no item type is specified, return all items
        result.push(item);
      } else if (item.type === itemType) {
        if (!types || types.length === 0) {
          result.push(item);
        } else if (types && types.includes(item.subType)) {
          result.push(item);
        }
      }
    });
    return result;
  }, []);
}

/**
 * **getRequiredQuestions** returns as array of questions ids
 * which represents questions that are required. Required
 * questions will contains `true` for their `isRequired`
 * property, under `formProperties`
 */
export const getRequiredQuestions = (
  questions: Array<QuestionDTO>
): Array<number> => {
  return questions.reduce((result: Array<number>, question) => {
    if (question.formProperties?.isRequired) {
      result.push(question.id);
    }
    return result;
  }, []);
};

/**
 * returns as array of question selection ids which require
 * comments.
 */
export const getRequiredSelectionComments = (
  questions: Array<QuestionDTO>
): Array<{
  associatedId?: number | null;
  questionId: number;
}> => {
  return questions.reduce(
    (
      result: Array<{
        associatedId?: number | null;
        questionId: number;
      }>,
      question
    ) => {
      if (question.selections) {
        question.selections.forEach((selection) => {
          if (selection.properties?.commentRequired) {
            result.push({
              associatedId: selection.id,
              questionId: question.id,
            });
          }
        });
      }
      return result;
    },
    []
  );
};

interface GetCommentForDefense {
  defenses: Array<DefenseDTO>;
  responses: Array<DocumentQuestionResponseVm>;
}

/**
 * Returns an object with the key
 * being the associatedId and the value
 * being the response. Only returns values
 * which have associated Defenses
 */
export const getResponsesAssociatedWithDefenses = ({
  defenses,
  responses,
}: GetCommentForDefense) => {
  const responseIDsFromDefenses = defenses.flatMap((x) =>
    x.questionSelections?.map((qs) => qs.id)
  );
  const filteredResponses = responses.filter(
    (r) => r.associatedId && responseIDsFromDefenses.includes(r.associatedId)
  );

  return zipObject(
    filteredResponses.map((x) => x.associatedId || x.questionId),
    filteredResponses
  );
};

/**
 * Find an item within a form's sections with a rootId that matches argument rootId
 * @param rootId
 * @param sections
 */
export function getItemByRootId(
  rootId: number,
  sections: Array<FormSectionVm>
): SectionItem | undefined {
  for (const section of sections) {
    if (!section || !section.items) {
      continue;
    }
    for (const item of section.items) {
      if (item.rootId === rootId) {
        return item;
      }
    }
  }
}

/**
 * Determine whether an item is part of a widget and (optionally) of a specific subType
 */
export function isWidgetQuestion(
  item: QuestionDTO,
  specificTypes?: string[]
): boolean {
  return (
    !!item.parentWidgetRootId &&
    (specificTypes ? specificTypes.includes(item.subType) : true)
  );
}

/**
 * Determine if a question is the parent question for a Safety Rating widget
 */
function isRatingsParentQuestion(
  item: QuestionDTO,
  ratingsWidgets: SafetyRatingWidgetDTO[]
): boolean {
  return (
    ratingsWidgets.length > 0 &&
    !!ratingsWidgets.find((widg) => item.rootId === widg.parentQuestionRootId)
  );
}

/**
 * Determine if the safety rating parent question should show the Rating Scale
 */
export function shouldShowSafetyScale(
  item: QuestionDTO,
  ratingsWidgets: SafetyRatingWidgetDTO[]
): boolean {
  if (isRatingsParentQuestion(item, ratingsWidgets)) {
    return (
      ratingsWidgets.find((widg) => item.rootId === widg.parentQuestionRootId)
        ?.displayDescription || false
    );
  }
  return false;
}

/**
 * Get the set of visible response ids based on visible question ids and current responses
 */
export function getVisibleResponseIds(
  visibleQuestionIds: Set<number>,
  responses: DocumentQuestionResponseVm[]
) {
  return responses.reduce((result: Set<number>, response) => {
    if (visibleQuestionIds.has(response.questionId)) {
      result.add(response.questionId);
    }
    return result;
  }, new Set<number>());
}

/** Get form progress percentage based on visible questions and current responses */
export function getFormProgress(
  form: FormVm,
  responses: DocumentQuestionResponseVm[]
): number {
  const visibleQuestionIds = Functions.getVisibleQuestionIds(
    form.sections,
    responses,
    form.displayConditions
  );
  const visibleResponseIds = getVisibleResponseIds(
    visibleQuestionIds,
    responses || []
  );

  return (100 * visibleResponseIds.size) / visibleQuestionIds.size;
}

export function getEsriQuestionRootIds(sections: FormSectionVm[]): number[] {
  return sections
    .flatMap((section: FormSectionVm) => section.items)
    .filter(
      (item): item is EsriContentWidgetDTO =>
        item.type === "WIDGET" && item.subType === "ESRI_CONTENT"
    )
    .flatMap((widget) => widget.questions.map((q) => q.questionRootId));
}
