import React, { Dispatch, FC, SetStateAction } from "react";

import { Drawer } from "../../../../Drawer/Drawer";
import ShareDocumentSidebarContent from "./ShareDocumentSidebarContent";
import { useMediaQuery } from "react-responsive";
import scssVariables from "shared/src/sass/jsExports.module.scss";

interface ShareDocumentSidebarProps {
  isOpen: boolean;
  onShareDocument: (participantIds: number[]) => Promise<void>;
  onClose: () => void;
}

const ShareDocumentSidebar: FC<ShareDocumentSidebarProps> = ({
  onShareDocument,
  isOpen,
  onClose,
}) => {
  const isDesktop = useMediaQuery({
    minWidth: scssVariables.minDesktop,
  });

  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      id="ShareDocumentSidebar"
      onClose={onClose}
      disableBackdrop
      isOpen={isOpen}
    >
      <ShareDocumentSidebarContent
        onShareDocument={onShareDocument}
        toggleShareDocumentVisibility={onClose}
      />
    </Drawer>
  );
};

export default ShareDocumentSidebar;
