import React, { FC } from "react";
import { DocumentStatus, DocumentSummaryVm } from "@rtslabs/field1st-fe-common";
import { useTranslation } from "react-i18next";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import { Components, ElementType } from "shared/src/qa-slugs";
import { FormTypeIcon } from "shared/src/components/Documents/FormTypeIcon";
import { Table } from "shared/src/components/TableUI/Table/Table";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import NoResults from "shared/src/components/common/TableUI/NoResults";
import { prettifyDocumentStatus, prettifyWorkorderID } from "../helpers";
import { DocumentScreenTerms } from "../types";
import styles from "./DocumentsList.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import moment from "moment";

interface DocumentsListProps {
  documents: DocumentSummaryVm[];
  handleOnClick: (documentId: number) => void;
  openContextMenu: (document: any) => void;
  onOpenNewDocumentDrawer: () => void;
  terms: DocumentScreenTerms;
}

const DocumentsList: FC<DocumentsListProps> = ({
  handleOnClick,
  openContextMenu,
  documents,
  onOpenNewDocumentDrawer,
  terms,
}) => {
  const { t } = useTranslation("common");
  return (
    <Table className={styles.documentList}>
      <tbody>
        <TableRow
          className={joinClassNames(
            styles.documentListItem,
            styles.tableHeader
          )}
        >
          <td className={styles.left}>Type</td>
          <td className={styles.middle}>Name</td>
          <td style={{ paddingRight: 20 }}>Modified</td>
        </TableRow>
      </tbody>
      {documents && documents.length > 0 ? (
        documents.map((document) => (
          <tbody key={document.id}>
            <TableRow
              qa={`${Components.MyDocuments}-${ElementType.TableRow}-document-${document.id}`}
              className={joinClassNames(
                styles.documentListItem,
                document.status === DocumentStatus.NEW && styles.new,
                document.status === DocumentStatus.IN_PROGRESS &&
                  styles.inProgress,
                document.status === DocumentStatus.SUBMITTED && styles.submitted
              )}
              key={document.id}
              onClick={() => handleOnClick(document.id)}
            >
              <td className={styles.left}>
                <FormTypeIcon
                  qa={`${Components.MyDocuments}-${ElementType.TableCell}-type-${document.formSummary.type.iconName}-${document.formSummary.type.iconColor}-${document.id}`}
                  formType={document.formSummary.type}
                />
              </td>
              <td className={styles.middle}>
                <div className={styles.documentInfo}>
                  <div className={styles.titleAndSubtitle}>
                    <span
                      data-testid={`${Components.MyDocuments}-${ElementType.Text}-title-${document.id}`}
                      className={styles.title}
                    >
                      {document.parentId && document.isRehuddle
                        ? `[${terms.rehuddle.singular.toUpperCase()}]`
                        : ""}{" "}
                      {document.title}
                    </span>
                    <span className={styles.subtitle}>
                      <span
                        data-testid={`${Components.MyDocuments}-${ElementType.Text}-owner-${document.id}`}
                        className={styles.ownerInitials}
                      >
                        {document.owner.firstName
                          ? document.owner.firstName[0]
                          : ""}
                        . {document.owner.lastName}
                      </span>
                    </span>
                    {!!document.workOrder?.workOrderId && (
                      <span
                        className={styles.workOrderId}
                        data-testid={`${Components.MyDocuments}-${ElementType.Text}-workId-${document.id}`}
                      >
                        ID:{" "}
                        {prettifyWorkorderID(
                          document.workOrder?.workOrderId || "-"
                        )}
                      </span>
                    )}
                  </div>
                  <div className={styles.statusDate}>
                    <span className={styles.status}>
                      {prettifyDocumentStatus(document.status)}
                    </span>
                    {document.submissionDate && (
                      <span
                        className={styles.date}
                        data-testid={`${Components.MyDocuments}-${ElementType.Text}-submissionDate-${document.id}`}
                      >
                        {moment(document.submissionDate).format("MM/DD/YY")}
                      </span>
                    )}
                  </div>
                </div>
              </td>
              <td
                data-testid={`${Components.MyDocuments}-${ElementType.Button}-documentContextMenu-${document.id}`}
              >
                <ActionButton
                  label={t("common:table.rowMenuButtonLabel")}
                  onClick={(e) => {
                    e.stopPropagation();
                    openContextMenu(document);
                  }}
                  qa={`${Components.MyDocuments}-${ElementType.Button}-${document.id}`}
                />
              </td>
            </TableRow>
          </tbody>
        ))
      ) : (
        <tbody className={styles.noResults}>
          <NoResults
            icon="document-2"
            header="No documents were found."
            body="Please update your filters, or create a new document."
            button={{
              children: "Create a document",
              onClick: onOpenNewDocumentDrawer,
            }}
            qa={`${Components.Document}-${Components.NotFound}`}
          />
        </tbody>
      )}
    </Table>
  );
};

export default DocumentsList;
