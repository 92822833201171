import React from "react";

import {
  MenuItem as MenuItemType,
  MenuItemIcon,
  MenuDivider as MenuDividerType,
  isMenuItem,
} from "./types";
import styles from "./Menu.module.scss";
import MenuItem from "./MenuItem";
import MenuDivider from "./MenuDivider";
import { joinClassNames } from "../../helpers/theme.helpers";

export type MenuItems = Array<MenuItemType | MenuDividerType>;

interface Props {
  className?: string;
  menuItems: MenuItems;
}

/**
 * Renders a Menu with Button list items.
 * @props.menuItems takes a specific config and
 * uses an enum for the Icon, be sure to check the type.
 */
const Menu = ({ menuItems, className }: Props) => {
  return (
    <div className={joinClassNames(styles.menu, className)}>
      {menuItems.map((item) => {
        if (isMenuItem(item)) {
          return <MenuItem key={item.label} menuItem={item} />;
        }
        return <MenuDivider key={item.title} menuDivider={item} />;
      })}
    </div>
  );
};

export { Menu as default, MenuItemIcon };
