import React from "react";

import DrawerContentWrapper from "shared/src/components/Drawer/DrawerContentWrapper/DrawerContentWrapper";
import ErrorText from "shared/src/components/ErrorText/ErrorText";
import styles from "./InterstitialDrawerContent.module.scss";
import {
  TertiaryButton,
  SecondaryButton,
} from "shared/src/components/Button/Button";
import DrawerHeader from "shared/src/components/Drawer/DrawerHeader";
import { Components, ElementType } from "shared/src/qa-slugs";

interface Props {
  formType?: string;
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
  error?: string;
  submissionTimeError?: string | null;
  isLoading: boolean;
}

const InterstitialDrawerContent: React.FC<Props> = ({
  error,
  submissionTimeError,
  formType = "Form",
  onClose,
  onSubmit,
  title = "",
  isLoading,
}) => {
  return (
    <>
      <DrawerHeader title={title} onClose={onClose} />
      {/* Content */}
      <DrawerContentWrapper>
        <div className={styles.body}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.description}>
            This will create a new {formType} that carries over only some key
            information from the original while providing cleared fields for the
            rest of the form.
          </p>
          {submissionTimeError && <ErrorText>{submissionTimeError}</ErrorText>}
          <div className={styles.buttonWrapper}>
            <SecondaryButton
              onClick={onSubmit}
              disabled={isLoading || !!submissionTimeError}
              loading={isLoading}
              qa={`interstitialDrawer-${ElementType.Button}-start`}
            >
              START DOCUMENT
            </SecondaryButton>
            <TertiaryButton
              qa={`${ElementType.Button}-cancel`}
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </TertiaryButton>
          </div>
          {error && <ErrorText>{error}</ErrorText>}
        </div>
      </DrawerContentWrapper>
    </>
  );
};

export default InterstitialDrawerContent;
