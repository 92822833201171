import React from "react";
import styles from "./DocumentDetails.module.scss";

interface DocumentDetailsProps {
  className?: string;
  formType?: string;
  ownerName?: string;
  row1: string;
  row2?: string;
  status?: string;
  subtitle: string;
  title: string;
  titleId?: string;
}

export const DocumentDetails = ({
  className,
  formType,
  ownerName,
  row1,
  row2,
  status,
  subtitle,
  title,
  titleId,
}: DocumentDetailsProps) => {
  return (
    <div className={className}>
      <div className={styles.titleRow}>
        <span className={styles.title} id={titleId}>
          {title}
        </span>
        {!!status && <span className={styles.status}>{status}</span>}
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.row}>{row1}</div>
      {formType && <div className={styles.row}>{formType}</div>}
      {!!row2 && <div className={styles.row}>{row2}</div>}
      {ownerName && <div className={styles.row}>{ownerName}</div>}
    </div>
  );
};
