import CircularProgress from "@mui/material/CircularProgress";
import { GeoPoint } from "@rtslabs/field1st-fe-common";
import React, { FC, ReactNode, useEffect, useState } from "react";
import ReactMapGL, { Marker, ViewState } from "react-map-gl";
import { CallbackEvent } from "react-map-gl/src/components/draggable-control";
import {
  ClassicLocationMarker,
  classicReverseGeolocation,
} from "../Document/DocumentForm/LocationField/geo";
import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./GlMap.module.scss";
import MarkerControl from "./MarkerControl";
import { Pin } from "./Pin";
import { Viewport } from "./types";
import ZoomControl from "./ZoomControl";
import { QAProps } from "../../qa-slugs";

interface GlMapProps extends QAProps {
  initialViewport: {
    width: number | string;
    height: number | string;
    zoom: number;
    center: GeoPoint;
  };
  markers: ClassicLocationMarker[];
  disableControls?: boolean;
  staticOnly?: boolean;
  bottomLeft?: ReactNode;
  bottomRight?: ReactNode;
  customContent?: ReactNode;
  loading?: boolean;
  onClickPin?: (locationName: string) => void;
  hideControls?: boolean;
}

/**
 * Extension of ClassicLocationMarker that stores local state
 */
interface MapMarker extends ClassicLocationMarker {
  dragging: boolean;
  visible: () => boolean;
}

/**
 * Map from LocationMarkers to MapMarkers
 * @param markers Markers from props
 */
function mapMarkers(markers: ClassicLocationMarker[]): MapMarker[] {
  return (markers || []).map((m) => ({
    ...m,
    dragging: false,
    visible() {
      return Boolean(m.geolocation) || this.dragging;
    },
  }));
}

/** Map component */
export const GlMap: FC<GlMapProps> = (props) => {
  const [tileStyle, setTileStyle] = useState("streets");

  const [viewport, setViewport] = useState<Viewport>({
    width: props.initialViewport.width,
    height: props.initialViewport.height,
    zoom: props.initialViewport.zoom,
    center: props.initialViewport.center,
  });

  // local versions of props stored in state, only modified when props are updated or marker dragged locally
  const [markers, setMarkers] = useState<Array<MapMarker>>([]);
  const setMarker = (marker: MapMarker, idx: number): void => {
    const updatedMarkers = [...markers];
    updatedMarkers[idx] = marker;
    setMarkers(updatedMarkers);
  };

  // reset the markers when props change
  useEffect(() => {
    setMarkers(mapMarkers(props.markers));
  }, [props.markers]);

  // store these to calculate offset to hide pin behind control
  const [mapDimensions, setMapDimensions] = useState({
    width: 100,
    height: 100,
  });

  /**
   * Set Marker attributes - lat/lng and whether it has been placed or is being dragged
   * @param event Drag event
   * @param idx Index of marker
   */
  const updateMarker = async (
    event: CallbackEvent,
    idx: number
  ): Promise<void> => {
    if (!markers[idx]) {
      return;
    }

    const marker = {
      ...markers[idx],
      geolocation: {
        latitude: event.lngLat[1],
        longitude: event.lngLat[0],
      },
    };
    setMarker(marker, idx);

    const reverseGeo = await classicReverseGeolocation(marker.geolocation);

    if (reverseGeo?.display_name) {
      marker.locationName = reverseGeo.display_name;
    } else {
      delete marker.locationName;
    }
  };

  const clearMarker = (idx: number): void => {
    if (!markers[idx]) {
      return;
    }
    const marker = {
      ...markers[idx],
      geolocation: undefined,
    };
    setMarker(marker, idx);
  };

  /**
   * Set of map when dragged or zoomed
   * @param newViewport
   */
  const handleSetViewport = (newViewport: ViewState): void => {
    !props.disableControls &&
      setViewport({
        ...viewport,
        zoom: newViewport.zoom,
        center: {
          latitude: newViewport.latitude,
          longitude: newViewport.longitude,
        },
      });
  };

  /** Toggle the style of map shown */
  const toggleTileStyle = () => {
    if (!props.disableControls) {
      if (tileStyle === "streets") {
        setTileStyle("hybrid"); // satellite
      } else {
        setTileStyle("streets"); // street
      }
    }
  };

  const onViewportChange = props.disableControls
    ? () => undefined
    : handleSetViewport;

  return (
    <span data-testid={props.qa}>
      <ReactMapGL
        {...viewport}
        latitude={viewport.center.latitude}
        longitude={viewport.center.longitude}
        mapStyle={`https://api.maptiler.com/maps/${tileStyle}/style.json?key=sfrAG6pKd1hsQOsD6mqH`}
        onViewportChange={onViewportChange}
        scrollZoom={!props.disableControls}
        onResize={setMapDimensions}
        attributionControl={false}
      >
        {!props.hideControls && (
          <>
            <div className={styles.bottomRightContainer}>
              {props.bottomRight}
              <ZoomControl
                viewport={viewport}
                handleSetViewport={handleSetViewport}
                toggleTileStyle={toggleTileStyle}
              />
            </div>
            <div className={styles.bottomLeftContainer}>
              {props.bottomLeft}
              <div className={styles.markerControlContainer}>
                {!props.staticOnly &&
                  markers.map((marker, idx) => (
                    <MarkerControl
                      key={idx}
                      disabled={marker.visible()}
                      color={marker.color}
                      onMouseUp={() => clearMarker(idx)}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
        {markers.map((marker, idx) => (
          <div
            key={idx}
            className={joinClassNames(
              styles.markerContainer,
              !marker.visible() && styles.hidden,
              marker.dragging && styles.dragging
            )}
            style={
              !marker.visible()
                ? {
                    left: `calc(${-mapDimensions.width / 2}px + ${
                      3 + 5 * idx
                    }em)`,
                    bottom: `calc(${mapDimensions.height / 2}px + 2em)`,
                  }
                : {}
            }
          >
            <Marker
              latitude={
                (marker.geolocation && marker.geolocation.latitude) ||
                viewport.center.latitude
              }
              longitude={
                (marker.geolocation && marker.geolocation.longitude) ||
                viewport.center.longitude
              }
              offsetLeft={-15}
              offsetTop={-30}
              draggable={!props.disableControls && !props.staticOnly}
              onDragStart={() => {
                if (props.disableControls) return null;
                setMarker({ ...marker, dragging: true }, idx);
              }}
              onDragEnd={(event) => {
                if (props.disableControls) return null;
                updateMarker(event, idx);
              }}
            >
              <Pin
                color={marker.color}
                borderColor={marker.borderColor}
                visible={marker.visible()}
                onClick={() =>
                  props.onClickPin &&
                  props.onClickPin(marker.locationName || "")
                }
              />
            </Marker>
          </div>
        ))}
        <div>
          {props.customContent}
          {props.loading && (
            <div className={styles.loading}>
              <CircularProgress />
            </div>
          )}
        </div>
      </ReactMapGL>
    </span>
  );
};
