import React, { useState } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import Label from "../Label/Label";
import TextInputWithSuggestions from "../TextInput/TextInputWithSuggestions";
import { TextInputProps } from "../TextInput/types";
import styles from "./MultiInput.module.scss";
import MultiInputItemOption from "./MultiInputItemOption";
import { FieldMessagingWrapper } from "../Document/DocumentForm/FieldMessagingWrapper";

/**
 * value that populates the **ItemSelectorDrawer** and
 * is the value for the **MultiInput** field
 */
export type SuggestionType = {
  id: number;
  label?: string;
  subLabel?: string;
  className?: string;
  icon?: string;
};

interface MultiInputProps<T = SuggestionType> extends TextInputProps {
  onAddItem: (value: T) => void;
  canUseCustomValues: boolean;
  autoCompleteSuggestions?: SuggestionType[];
  idField: string;
  inlineLabel?: boolean;
  labelField: string;
  onChangeInput?: (value: string) => void;
  onRemoveItem: (value: T) => void;
  selectedValues?: T[];
  itemClassName?: string;
  onClickItem?: (value: T, index: number) => void;
}

const MultiInput = <T extends SuggestionType | string>({
  assistiveLink,
  autoCompleteSuggestions,
  canUseCustomValues,
  className,
  error,
  assistiveText,
  idField,
  inlineLabel,
  itemClassName,
  label,
  labelField,
  name,
  onBlur,
  onChangeInput,
  onClickItem,
  onAddItem,
  onRemoveItem,
  placeholder,
  required,
  selectedValues = [],
  autoComplete = "off",
  qa,
  disabled,
}: MultiInputProps<T>) => {
  const [localError, setLocalError] = useState<string>("");
  const [textFieldValue, setTextFieldValue] = useState("");

  /**
   * Method used when user types into the participant input field.
   * Also handles opening the "auto-complete" menu
   */
  const handleOnChange = (value: string): void => {
    // if the field is cleared, close the autocomplete, else show it
    if (value === "") {
      setLocalError("");
    }
    setTextFieldValue(value);
    onChangeInput?.(value);
  };

  const handleAddValue = (value: SuggestionType | string): void => {
    // clear the text input field & any errors
    setTextFieldValue("");
    setLocalError("");
    // add the values
    onAddItem(value as T);
  };

  const handleRemoveValue = (value: T): void => {
    onRemoveItem(value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    // if the user can add custom (just text) values, and they press "return", add the value
    if (canUseCustomValues && e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleAddValue(textFieldValue);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    // display an error if the text field has not cleared (value hasn't been added) and the field has been blurred
    if (!canUseCustomValues && textFieldValue !== "") {
      setLocalError("Please select a valid item");
    }
    // use the text field value on blur, if custom values can be used
    if (canUseCustomValues && textFieldValue !== "") {
      handleAddValue(textFieldValue);
    }

    onBlur?.(e);
  };

  const hasError = localError || error;

  return (
    <FieldMessagingWrapper assistiveText={assistiveText} error={error}>
      <div id={name} className={`${className} multiInput`}>
        {label && !inlineLabel && (
          <Label
            htmlFor={name || ""}
            assistiveLink={assistiveLink}
            qa={qa}
            required={required}
            hasError={!!error}
          >
            {label}
          </Label>
        )}
        <div
          className={joinClassNames(
            styles.multiInputContent,
            !!hasError && styles.error,
            disabled && styles.disabled
          )}
        >
          <ul
            className={styles.multiInputList}
            data-testid={`${name}_multiInput`}
          >
            {selectedValues.map((value, index) => (
              <MultiInputItemOption
                icon={typeof value === "string" ? undefined : value.icon}
                onClick={() => onClickItem && onClickItem(value, index)}
                className={
                  typeof value === "string"
                    ? itemClassName ?? ""
                    : `${itemClassName ?? ""}} ${value.className}`
                }
                key={index}
                subLabel={
                  typeof value === "string" ? undefined : value.subLabel
                }
                label={typeof value === "string" ? value : value.label}
                onRemove={() => handleRemoveValue(value)}
              />
            ))}
            <TextInputWithSuggestions
              name={name}
              disabled={disabled}
              wrapperClassName={joinClassNames(
                styles.inputWrapper,
                disabled && styles.disabled
              )}
              className={styles.input}
              autoComplete={autoComplete}
              suggestions={autoCompleteSuggestions || []}
              showSuggestions={true}
              onSelectSuggestion={handleAddValue}
              error={!!hasError}
              placeholder={placeholder}
              value={textFieldValue}
              onInputChange={handleOnChange}
              onKeyDown={handleKeyPress}
              onBlur={handleBlur}
              qa={qa}
              idField={idField}
              labelField={labelField}
            />
          </ul>
        </div>
      </div>
    </FieldMessagingWrapper>
  );
};

export default MultiInput;
