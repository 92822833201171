import React, { useState } from "react";
import Loader from "../../../../Loader/Loader";
import { Icon } from "../../../../Icon/Icon";
import { joinClassNames } from "../../../../../helpers/theme.helpers";
import { ElementType } from "../../../../../qa-slugs";
import s from "./FormItem.module.scss";

type FormItemProps = {
  disabled?: boolean;
  icon: string;
  name: string;
  onClick: () => Promise<void>;
};

const FormItem = ({ disabled = false, icon, name, onClick }: FormItemProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const addableItem = hover && !disabled;

  async function handleClick() {
    setLoading(true);
    await onClick();
    setLoading(false);
  }

  return (
    <button
      className={joinClassNames(s.formItem, disabled ? s.disabled : s.enabled)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
      disabled={disabled}
      type="button"
    >
      <div className={s.formItemIcon}>
        <Icon qa={`${ElementType.Icon}-${icon}`} size="1.25rem" type={icon} />
      </div>
      <span>{name}</span>
      {loading ? (
        <Loader
          className={s.formItemLoader}
          loading
          spinnerProps={{ size: 16 }}
        />
      ) : (
        addableItem && (
          <Icon
            className={s.addIcon}
            size="1.5rem"
            type="icon-icons8-plus_math"
          />
        )
      )}
    </button>
  );
};

export default FormItem;
