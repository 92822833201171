import { MouseEventHandler, Pie, ResponsivePie } from "@nivo/pie";
import { animated } from "@react-spring/web";
import * as React from "react";
import { QAProps } from "../../../qa-slugs";
import { useCommonChartProps } from "../common";
import styles from "./PieChart.module.scss";
import colors from "./PieChartColors.module.scss";
import { PieChartDatum } from "./PieChartTypes";
import { PieChartTooltip } from "./PieChartTooltip";

interface BasePieChartProps<T = any> extends QAProps {
  data: PieChartDatum<T>[];
  labels?: boolean;
  valueFormat?: (n: number) => string;
  innerRadius?: number;
  borderWidth?: number;
  sliceColors?: any;
  startAngle?: number;
  endAngle?: number;
  fill?: any;
  centeredContent?: any;
  onClick?: MouseEventHandler<
    { id: string; value: number; label?: string; source?: T },
    SVGPathElement
  >;
}

interface PieChartProps<T = any> extends BasePieChartProps<T>, QAProps {
  ariaLabel: string;
}

export const SummaryPieChart: React.FC<BasePieChartProps> = ({
  data,
  labels,
  valueFormat,
  innerRadius,
  ...chartProps
}) => {
  const commonChartProps = useCommonChartProps();

  return (
    <Pie
      height={300}
      width={500}
      data={data}
      valueFormat={valueFormat}
      innerRadius={innerRadius || 0.5}
      margin={{ bottom: 0, right: 200, left: 0 }}
      borderWidth={1}
      enableArcLabels={labels}
      enableArcLinkLabels={false}
      legends={[
        {
          anchor: "right",
          direction: "column",
          translateX: 75,
          translateY: 0,
          itemWidth: 50,
          itemHeight: 22,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: colors.black,
              },
            },
          ],
        },
      ]}
      arcLabelsTextColor={colors.white}
      {...commonChartProps}
      {...chartProps}
    />
  );
};

export const PieChart: React.FC<PieChartProps> = ({
  ariaLabel,
  data,
  labels,
  innerRadius,
  borderWidth,
  sliceColors,
  centeredContent,
  qa,
  ...chartProps
}) => {
  const commonChartProps = useCommonChartProps();
  const chartRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const svgElement = chartRef.current?.querySelector("svg");
    if (svgElement) {
      svgElement.ariaLabel = ariaLabel;
    }
  }, [ariaLabel, chartRef.current]);

  return (
    <div className={styles.pieChart} ref={chartRef}>
      <ResponsivePie
        {...commonChartProps}
        {...chartProps}
        data={data}
        innerRadius={innerRadius || 0.5}
        margin={{ bottom: 0, right: 0, left: 0 }}
        borderWidth={borderWidth || 1}
        // In order to allow QA to hover and click pie slices, we use labels
        // for automation to position the cursor. So if the labels prop is false
        // we still may have invisible labels that exist purely for automation.
        enableArcLabels={labels || !!qa}
        enableArcLinkLabels={false}
        arcLabelsTextColor={colors.white}
        colors={sliceColors || commonChartProps.colors}
        arcLabelsComponent={
          qa
            ? ({ datum, label, style }) => (
                <animated.g
                  data-testid={`${qa}-Slice-${datum.data.qa}`}
                  style={{ pointerEvents: "none" }}
                  transform={style.transform as any}
                >
                  {/* Use invisible text when we need a label for automation but
                    not for the user. */}
                  {labels && (
                    <text
                      dominantBaseline="central"
                      fill={style.textColor}
                      style={{
                        fontSize: 11,
                        fontWeight: 600,
                      }}
                      textAnchor="middle"
                    >
                      {label}
                    </text>
                  )}
                </animated.g>
              )
            : undefined
        }
        tooltip={
          qa
            ? (props) => <PieChartTooltip {...props} qa={`${qa}-Tooltip`} />
            : undefined
        }
      />
      <div style={{ position: "relative" }}>{centeredContent}</div>
    </div>
  );
};
