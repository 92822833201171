import React from "react";
import { StyleSheet } from "aphrodite";
import { ThemeContext } from "styled-components";
import { remCalc } from "../../../../themes/helpers";

export default function (isMouseFocused: boolean): {
  [key: string]: React.CSSProperties;
} {
  const theme = React.useContext(ThemeContext);
  return StyleSheet.create({
    errorText: {
      display: "block",
      color: theme.colors.error,
      fontSize: remCalc(12),
      margin: `${remCalc(3)} ${remCalc(5)} 0`,
    },

    input: {
      backgroundColor: theme.colors.fieldInputBackground,
      border: `1px solid ${theme.masterColors.darkGrey}`,
      borderRadius: remCalc(4),
      margin: "2px", // prevents "shifting" on focus
      ":focus": {
        border: `${isMouseFocused ? "1px" : "3px"}
          solid ${
            isMouseFocused
              ? theme.colors.primary
              : theme.colors.focusIndicatorOutline
          }`,
        outline: 0,
        margin: isMouseFocused ? "2px" : "0px",
      },
      padding: remCalc(16),
    },

    wrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  });
}
