import React, { Dispatch, SetStateAction } from "react";

import { Drawer } from "shared/src/components/Drawer/Drawer";
import FormTypesDrawerContent from "./components/FormTypesDrawerContent";
import {
  FormRelationshipTypeDTO,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";

interface Props {
  formTypes: FormTypeDTO[];
  isDesktop?: boolean;
  isOpen: boolean;
  onSelectFormType: (type: FormTypeDTO) => void;
  relationshipTypes: FormRelationshipTypeDTO[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const FormTypesDrawer = ({
  formTypes,
  isOpen,
  setIsOpen,
  isDesktop,
  onSelectFormType,
}: Props) => {
  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      id="FormTypesDrawer"
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <FormTypesDrawerContent
        formTypes={formTypes}
        onClose={() => setIsOpen(false)}
        onSelectFormType={onSelectFormType}
      />
    </Drawer>
  );
};

export default FormTypesDrawer;
