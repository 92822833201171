import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ClassNameMap, WithStyles } from "@mui/styles";
import { css } from "aphrodite";
import * as React from "react";
import styled from "styled-components";

import { PaperProps } from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import { styles } from "../../../../themes/materialUI";
import ThemeContext from "../../../../themes/ThemeContext";

import { useThemedStyleSheet } from "../../../../util/hooks/useThemedStyleSheet";
import { StyledCloseIcon } from "../../styled/StyledCloseIcon";
import { drawerStyles } from "../DrawerStyles";

type DrawerAnchorType = "bottom" | "left" | "right" | "top";

interface DrawerCustomProps extends WithStyles<typeof styles> {
  internalVariant?: string;
  anchor?: DrawerAnchorType;
  disableBackdrop?: boolean;
  overrideClasses?: {
    container?: string;
    content?: string;
  };
  content: any;
  id: string;
  onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onOpen?: () => void;
  open: boolean;
  paperProps?: PaperProps;
  showCloseIcon?: boolean;
  qa?: string;
}

const getContainerClasses = (
  overrideClasses: { container?: string; content?: string } = {},
  allClasses: ClassNameMap
) => {
  if (overrideClasses.container) {
    return allClasses[overrideClasses.container];
  }
  return allClasses.drawerContainerStyles;
};

const getContentClasses = (
  overrideClasses: { container?: string; content?: string } = {},
  allClasses: ClassNameMap,
  darkMode: boolean
) => {
  if (overrideClasses.content) {
    return allClasses[overrideClasses.content];
  }

  if (darkMode) {
    return allClasses.drawerStylesDark;
  }
  return allClasses.drawerStyles;
};

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 17px;
  right: 15px;
`;

const DivWithNoFocusOutline = styled.div`
  :focus {
    outline: 0;
  }
`;

/**
 * Drawer component which relies on MUI. This component is used with
 * the majority of Drawer features throughout the app.
 */
const DrawerCustom = ({
  anchor,
  classes,
  content,
  disableBackdrop,
  onClose,
  onOpen = () => undefined,
  open,
  overrideClasses,
  paperProps = {},
  showCloseIcon = true,
  qa,
}: DrawerCustomProps) => {
  const { darkMode } = React.useContext(ThemeContext);
  const styles = useThemedStyleSheet(drawerStyles);

  /**
   * handles closing the drawer using `onClose` from the MUI component
   * also clears **NewForm** Context using `resetForm`
   */
  const handleCloseDrawerAndResetNewFormContext = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  ) => {
    return onClose(e);
  };

  return (
    <div className="col d-flex align-items-center">
      <SwipeableDrawer
        data-testid={qa}
        anchor={anchor || "bottom"}
        BackdropProps={{ invisible: disableBackdrop }}
        className={getContainerClasses(overrideClasses, classes)}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        PaperProps={paperProps}
        SwipeAreaProps={{ className: css(styles.disableSwipe) }}
      >
        <DivWithNoFocusOutline
          tabIndex={0}
          role="button"
          className={getContentClasses(overrideClasses, classes, darkMode)}
        >
          {content}
          {showCloseIcon && (
            <CloseIconWrapper
              data-testid={`${qa}-close`}
              onClick={(e) => {
                handleCloseDrawerAndResetNewFormContext(e, onClose);
              }}
            >
              <StyledCloseIcon />
            </CloseIconWrapper>
          )}
        </DivWithNoFocusOutline>
      </SwipeableDrawer>
    </div>
  );
};

const Drawer = withStyles(styles)(DrawerCustom);

export { Drawer };
