import React from "react";
import { Field, useFormikContext } from "formik";

import { DataSource } from "@rtslabs/field1st-fe-common";
import { Select, SelectOption } from "../../../../../../Select/Select";
import { FBForm, FBItem, FBSection } from "../../../../../types";
import Banner from "../../../../../../common/Banner/Banner";

import ps from "../../styles.module.scss";
import bs from "../../../../styles.module.scss";
import { joinClassNames } from "../../../../../../../helpers/theme.helpers";

interface PrefillConditionProps {
  conditionIndex: number;
  dataSet?: DataSource;
  targetOptions: SelectOption[];
  targetItems: (FBItem | FBSection)[];
  sourceColumns: SelectOption<string>[];
}

// @TODO what question types can be prefilled?
const PrefillCondition = ({
  conditionIndex,
  dataSet,
  targetOptions,
  targetItems,
  sourceColumns,
}: PrefillConditionProps) => {
  const { setFieldValue } = useFormikContext<FBForm>();

  if (!dataSet) {
    return (
      <Banner type="warning">
        You must add a data set to set a Prefill rule
      </Banner>
    );
  }

  return (
    <div className={ps.conditionRules}>
      <div className={joinClassNames(bs.row, ps.dcRow)}>
        <span className={ps.text}>Use</span>
        {/* @TODO what if we need a prefillAssociatedIdField? */}
        <Field
          as={Select}
          wrapperClassName={joinClassNames(ps.dcSelect, ps.prefillSelect)}
          name={`displayConditions[${conditionIndex}].prefillAnswerField`}
          options={sourceColumns}
          onChange={(option?: SelectOption<string>) =>
            setFieldValue(
              `displayConditions[${conditionIndex}].prefillAnswerField`,
              option?.value
            )
          }
        />
      </div>
      <span className={joinClassNames(ps.text, ps.dcRow)}>
        from this question&apos;s data set
      </span>
      <div className={joinClassNames(bs.row, ps.dcRow)}>
        <span className={ps.text}>to prefill</span>
        <Field
          as={Select}
          wrapperClassName={joinClassNames(ps.dcSelect, ps.prefillSelect)}
          name={`displayConditions[${conditionIndex}].targetRootId`}
          options={targetOptions}
          onChange={(option?: SelectOption<number>) => {
            const targetItem = targetItems.find(
              (tItem) => tItem.id === option?.value
            );
            setFieldValue(
              `displayConditions[${conditionIndex}].targetRootId`,
              option?.value
            );
            setFieldValue(
              `displayConditions[${conditionIndex}].targetType`,
              targetItem?.type
            );
            setFieldValue(
              `displayConditions[${conditionIndex}].action`,
              "PREFILL"
            );
          }}
        />
      </div>
    </div>
  );
};

export default PrefillCondition;
