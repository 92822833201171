import * as yup from "yup";

export interface APIForm {
  name: string;
  description: string;
  uniqueIdFieldName: string;
  datasetKey: string;
  url: string;
  paginated: boolean;
  data: string;
}

function noWhitespace(string: string | undefined) {
  return string ? /^\S*$/.test(string) : true;
}

/**
 * Find the first value with a given property name.
 */
const findValue = (obj: any, keyToFind: string) => {
  if (obj[keyToFind]) {
    return obj[keyToFind];
  }
  return Object.values(obj)
    .filter((value) => typeof value === "object")
    .find((value) => findValue(value, keyToFind));
};

export const isUniqueIdFieldInData = (data: string, fieldName: string) => {
  try {
    const items = JSON.parse(data);
    if (!Array.isArray(items) || items.length === 0) {
      return false;
    }
    return findValue(items[0], fieldName) !== undefined;
  } catch (error) {
    return false;
  }
};

export const apiFormSchema = yup.object().shape({
  name: yup.string().trim().required("API Name is required"),
  description: yup.string().trim().required("Description is required"),
  uniqueIdFieldName: yup
    .string()
    .trim()
    .test({
      test: (value) => noWhitespace(value),
      message: "Unique ID cannot have spaces",
    })
    .test({
      test: (value, context) => {
        const data = context.parent.data;
        return !data || !value ? true : isUniqueIdFieldInData(data, value);
      },
      message: "Field name was not found in the test data.",
    })
    .required("Unique ID is required"),
  datasetKey: yup
    .string()
    .trim()
    .test({
      test: (value) => noWhitespace(value),
      message: "Data Set Key cannot have spaces",
    })
    .required("Data Set Key is required"),
  url: yup
    .string()
    .trim()
    .url('Please enter a "https://" or "http://" URL')
    .required("URL is required"),
  data: yup.string(),
});
