import { LatLng } from "@rtslabs/field1st-fe-common";
import { loadModules } from "esri-loader";

export async function findNearestFeature(
  coordinates: LatLng,
  features: __esri.Graphic[]
): Promise<__esri.Graphic | undefined> {
  const [Point, Polyline, geodesicUtils, geometryEngine] = await loadModules<
    [
      __esri.PointConstructor,
      __esri.PolylineConstructor,
      __esri.geodesicUtils,
      __esri.geometryEngine
    ]
  >([
    "esri/geometry/Point",
    "esri/geometry/Polyline",
    "esri/geometry/support/geodesicUtils",
    "esri/geometry/geometryEngine",
  ]);

  const viewportPoint = new Point({
    x: coordinates.longitude,
    y: coordinates.latitude,
  });

  let nearestFeature: {
    feature?: __esri.Graphic;
    distance?: number;
  } = { feature: undefined, distance: undefined };

  let distanceFromLocation: number | undefined;
  for (const feature of features) {
    switch (feature.geometry.type) {
      case "point":
        distanceFromLocation = geodesicUtils.geodesicDistance(
          feature.geometry as __esri.Point,
          viewportPoint
        ).distance;
        break;
      case "polygon":
      case "polyline":
        // calculates planar distance
        distanceFromLocation = geometryEngine.distance(
          viewportPoint,
          feature.geometry
        );
        break;
      default:
        throw "Unsupported geometry type";
    }

    if (
      !nearestFeature.feature ||
      (distanceFromLocation &&
        nearestFeature.distance &&
        distanceFromLocation < nearestFeature.distance)
    ) {
      nearestFeature = { feature, distance: distanceFromLocation };
    }
  }

  return nearestFeature.feature;
}
