import { DocumentPhotoVm } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { QAProps } from "../../qa-slugs";
import {
  PhotosHandling,
  UploadingPhoto,
} from "../Document/DocumentForm/usePhotos";
import { Photo } from "./Photo";
import { PhotoInProgress } from "./PhotoInProgress";

interface Props extends QAProps {
  handleClick: (idx: number) => void;
  onRetryPhoto: (file: File) => void;
  onDeletePhoto: (photoId: number) => Promise<void>;
  onUpdatePhoto: PhotosHandling["handleUpdatePhoto"];
  photos: DocumentPhotoVm[];
  photosUploading: UploadingPhoto[];
  type: "IMAGES_ABOVE" | "IMAGES_BELOW";
  questionTitle: string;
  readOnly?: boolean;
}

export const PhotoGroup: FC<Props> = ({
  handleClick,
  onDeletePhoto,
  onRetryPhoto,
  onUpdatePhoto,
  photos,
  photosUploading,
  qa,
  type,
  questionTitle,
  readOnly,
}) => (
  <>
    {photosUploading.map((p, idx) => (
      <PhotoInProgress
        key={idx}
        photo={p}
        onRetryPhoto={onRetryPhoto}
        qa={qa ? `${qa}-${p.key}` : undefined}
      />
    ))}
    {photos.map((p) => (
      <Photo
        key={p.id}
        onClick={() => handleClick(p.id)}
        photo={p}
        onDeletePhoto={onDeletePhoto}
        onUpdatePhoto={onUpdatePhoto}
        type={type}
        questionTitle={questionTitle}
        qa={qa ? `${qa}-${p.id}` : undefined}
        readOnly={readOnly}
      />
    ))}
  </>
);
