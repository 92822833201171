import React, { PropsWithChildren, useState } from "react";
import styles from "./SortableTableHeader.module.scss";
import { joinClassNames } from "../../../helpers/theme.helpers";
import { SortUp } from "../../../assets/svg/sortUp";
import { SortDown } from "../../../assets/svg/sortDown";
import { QAProps } from "../../../qa-slugs";

export type SortOrder = "ascending" | "descending" | "none";

interface SortableTableHeaderProps extends QAProps {
  handleSortOrderChange: (sortOrder: SortOrder, id: string) => void;
  isDisabled?: boolean;
  id: string;
  scope?: "col" | "row";
  sortOrder: SortOrder;
  className?: string;
}

const getNextSortOrder = (sortOrder: SortOrder) => {
  switch (sortOrder) {
    case "ascending":
      return "descending";
    case "descending":
      return "none";
    case "none":
      return "ascending";
  }
};

export const SortableTableHeader = ({
  children,
  handleSortOrderChange,
  id,
  isDisabled = false,
  qa,
  scope = "col",
  sortOrder = "none",
  className,
}: PropsWithChildren<SortableTableHeaderProps>) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMouseFocused, setIsMouseFocused] = useState(false);
  const isActive = sortOrder !== "none";
  const SortIcon = sortOrder === "ascending" ? SortUp : SortDown;

  const handleBlur = () => {
    setIsFocused(false);
    setIsMouseFocused(false);
  };

  const handleClick = () => {
    handleSortOrderChange(getNextSortOrder(sortOrder), id);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleMouseDown = () => {
    setIsMouseFocused(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const getSortIconFill = () => {
    if (isDisabled) {
      return styles.sortOrderFillDisabled;
    }
    if (isHovered) {
      return styles.sortOrderFillHovered;
    }
    if (!isMouseFocused && isFocused) {
      return styles.sortOrderFillKeyboardFocused;
    }
    if (isActive) {
      return styles.sortOrderFillActive;
    }
    return styles.sortOrderFillEnabled;
  };

  return (
    <th
      aria-sort={sortOrder}
      className={joinClassNames(styles.tableHeader, className)}
      scope={scope}
    >
      <button
        className={joinClassNames(
          styles.button,
          isDisabled && styles.buttonDisabled,
          !isDisabled && isHovered && styles.buttonHovered,
          !isMouseFocused && styles.buttonKeyboardFocused,
          isActive && styles.buttonActive
        )}
        data-testid={qa}
        disabled={isDisabled}
        onBlur={handleBlur}
        onClick={handleClick}
        onFocus={handleFocus}
        onMouseDown={handleMouseDown}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        type="button"
      >
        {children}
        {sortOrder !== "none" && (
          <SortIcon
            className={joinClassNames(styles.sortOrderIcon, getSortIconFill())}
            viewBox="-4 -4 32 32"
          />
        )}
      </button>
    </th>
  );
};
