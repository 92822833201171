import React from "react";

import SettingsMenu from "../../Settings/Menu";
import { Logout } from "shared/src/components/Logout/Logout";
import { TitleBar } from "shared/src/components/TitleBar/TitleBar";
import { useAuth } from "../../../store/hooks/useAuth";

import styles from "./SettingsScreen.module.scss";

const Settings = () => {
  const { logout } = useAuth();
  return (
    <>
      <TitleBar title="Settings" />
      <SettingsMenu />
      <Logout className={styles.logout_tablet} onLogOut={logout} />
    </>
  );
};

export default Settings;
