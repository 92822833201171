import React, { useCallback } from "react";

import { API, JWTAuthorityDTO, useAPI } from "@rtslabs/field1st-fe-common";
import { Icon } from "shared/src/components/Icon/Icon";
import Label from "shared/src/components/Label/Label";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Components, ElementType } from "shared/src/qa-slugs";
import { Loader } from "shared/src/components/common/Button/styles";
import ListItem from "../../../../common/ListItem";
import { Role } from "../../constants";
import s from "./styles.module.scss";

interface Props {
  openGroupSelector: () => void;
  groups: number[];
  role: Role;
  isGlobal: boolean;
  updateAuthority: (
    authority: JWTAuthorityDTO,
    method?: "replace" | "add"
  ) => void;
}

interface GroupCardProps {
  groupId: number;
  onRemove: () => void;
}

const GroupCard: React.FC<GroupCardProps> = ({ groupId, onRemove }) => {
  const groupCall = useAPI(API.getClientGroupById, { id: groupId });
  return (
    <Loader loading={groupCall.isLoading}>
      <ListItem
        key={groupId}
        className={s.ListItemOption}
        variant="participant-selected"
        renderLabelsAsRow
        label={groupCall.data?.name}
        endAdornment={{
          hideTextDecoration: true,
          visible: true,
          type: "button",
          onClick: onRemove,
          label: (
            <Icon type={"delete_sign"} className={s.IconForEndAdornment} />
          ),
        }}
        qa={`${Components.ListItem}-${ElementType.Link}-${groupId}`}
      />
    </Loader>
  );
};

export const GroupsContainerPerRole = ({
  openGroupSelector,
  groups = [],
  role,
  isGlobal,
  updateAuthority,
}: Props) => {
  const removeGroup = useCallback(
    (groupId, role) => {
      const _groups = groups.filter((group) => group !== groupId);
      updateAuthority(
        { authority: role.id, isGlobal: false, groups: _groups },
        "replace"
      );
    },
    [groups, role]
  );

  const makeGlobal = useCallback(() => {
    updateAuthority(
      { authority: role.id, isGlobal: true, groups: [] },
      "replace"
    );
  }, [updateAuthority]);

  return (
    <>
      <div className={s.LabelWrapper}>
        <Label containerClassName={s.Label}>{role.value}</Label>
        {
          // if isGlobal not predefined for the role, give it as option
          role.isGlobal !== undefined ? null : (
            <div
              data-testid={`${Components.GroupsContainerPerRole}-${ElementType.Link}-${role.id}-allGroups`}
              className={s.ActionLink}
              onClick={makeGlobal}
            >
              All Groups
            </div>
          )
        }
        <div
          data-testid={`${Components.GroupsContainerPerRole}-${ElementType.Link}-${role.id}-addGroups`}
          className={s.ActionLink}
          onClick={openGroupSelector}
        >
          Add Groups
        </div>
      </div>

      <div className={s.Wrapper}>
        {isGlobal ? (
          <ListItem
            key={"all-groups-selected"}
            className={s.ListItemOption}
            variant="participant-selected"
            renderLabelsAsRow
            label={"All groups"}
            endAdornment={{
              hideTextDecoration: true,
              visible: true,
              type: "button",
              onClick: () => {
                updateAuthority(
                  { authority: role.id, isGlobal: false, groups: [] },
                  "replace"
                );
              },
              label: (
                <i
                  className={joinClassNames(
                    "icon icon-icons8-delete_sign",
                    s.IconForEndAdornment
                  )}
                />
              ),
            }}
            qa={`${Components.ListItem}-${ElementType.Link}-${role.id}`}
          />
        ) : (
          groups.map((group) => (
            <GroupCard
              key={group}
              groupId={group}
              onRemove={() => removeGroup(group, role)}
            />
          ))
        )}
        <div
          data-testid={`${Components.GroupsContainerPerRole}-${ElementType.TextInput}-${role.id}`}
          className={s.AssistiveText}
        >
          Select groups for which this user is a {role.value}
        </div>
      </div>
    </>
  );
};
