import {
  API,
  DocumentRequestParameters,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";
import React, { Dispatch, FC, SetStateAction } from "react";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import { Components, ElementType } from "shared/src/qa-slugs";
import styles from "./DesktopSearchFilters.module.scss";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { Icon } from "shared/src/components/Icon/Icon";
import { Button } from "shared/src/components/Button/Button";
import { SearchFilters } from "./SearchFilters";
import { AppliedFiltersProps } from "./types";

interface DesktopSearchFiltersProps {
  filters: API.GetDocumentsArgs;
  formTypes: Array<FormTypeDTO>;
  onFilterChange: (params: Partial<DocumentRequestParameters>) => void;
  onSearch: (value: string) => void;
  query?: string;
  onOpenFilterDrawer?: () => void;
  appliedFilters: AppliedFiltersProps;
  setAppliedFilters: Dispatch<SetStateAction<AppliedFiltersProps>>;
}

const DesktopSearchFilters: FC<DesktopSearchFiltersProps> = ({
  filters,
  formTypes,
  onFilterChange,
  onSearch,
  query,
  onOpenFilterDrawer,
  appliedFilters,
  setAppliedFilters,
}) => {
  return (
    <>
      <div className={styles.filtersContainer}>
        <div className={joinClassNames(styles.searchWrapper)}>
          <SearchBar
            initialQuery={query}
            onSearch={onSearch}
            qa={`${Components.MyDocuments}-${ElementType.TextInput}-searchFilter`}
          />
          <Button className={styles.filterButton} onClick={onOpenFilterDrawer}>
            <Icon type="mail_filter" size={16} />
          </Button>
        </div>
        <div className={styles.filterOptions}>
          <SearchFilters
            filters={filters}
            formTypes={formTypes}
            onFilterChange={onFilterChange}
            className={styles.tabletDesktop}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        </div>
      </div>
    </>
  );
};

export default DesktopSearchFilters;
