import { CoreRedux } from "@rtslabs/field1st-fe-common";
import React, { FC, useMemo } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import LogoCondensed from "shared/src/components/LogoCondensed";
import { Components, ElementType } from "shared/src/qa-slugs";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import {
  dataSetReadRoles,
  formTemplateReadRoles,
  formTypeReadRoles,
  groupReadRoles,
  resourceReadRoles,
  settingsReadRoles,
  tagReadRoles,
  userReadRoles,
} from "../../../routes/constants/permissionSets";
import scssVariables from "shared/src/sass/jsExports.module.scss";
import { AppState } from "../../../store";
import { useAppSelector } from "../../../store/hooks";
import NavigationList, {
  NavigationMenuLink,
} from "../NavigationList/NavigationList";
import { UserProfileInfo } from "../UserProfileInfo/UserProfileInfo";
import styles from "./Nav.module.scss";

const peopleMenu: NavigationMenuLink[] = [
  {
    id: "101",
    icon: "business_conference_female_speaker",
    link: "People",
    expansion: [
      {
        id: "101-1",
        icon: "",
        link: "Groups",
        linkTo: "/people/groups",
        roles: groupReadRoles,
      },
      {
        id: "101-2",
        icon: "",
        link: "Users",
        linkTo: "/people/users",
        roles: userReadRoles,
      },
    ],
  },
];

const formsMenu: NavigationMenuLink[] = [
  {
    id: "003",
    icon: "multi_edit",
    link: "Forms",
    expansion: [
      {
        id: "003-1",
        icon: "",
        link: "Form Templates",
        linkTo: "/forms",
        roles: formTemplateReadRoles,
      },
      {
        id: "003-2",
        icon: "",
        link: "Data Sets",
        linkTo: "/forms/data-sets",
        roles: dataSetReadRoles,
      },
      {
        id: "003-3",
        icon: "",
        link: "Form Types",
        linkTo: "/forms/form-types",
        roles: formTypeReadRoles,
      },
    ],
  },
];

const contentMenu: NavigationMenuLink[] = [
  {
    id: "004",
    icon: "overview_pages_4",
    link: "Content",
    expansion: [
      {
        id: "004-1",
        icon: "",
        link: "Resources",
        linkTo: "/content/resources",
        roles: resourceReadRoles,
      },
      {
        id: "004-2",
        icon: "",
        link: "Tag Library",
        linkTo: "/content/tag-library",
        roles: tagReadRoles,
      },
    ],
  },
];

const settingsMenu: NavigationMenuLink[] = [
  {
    id: "005",
    icon: "settings",
    link: "Settings",
    roles: settingsReadRoles,
    expansion: [
      {
        id: "005-1",
        icon: "",
        link: "Company Info",
        linkTo: "/settings/company-info",
      },
      {
        id: "005-2",
        icon: "",
        link: "Permissions",
        linkTo: "/settings/permissions",
      },
    ],
  },
];

const buildUserMenu = (documentsTerm: string): NavigationMenuLink[] => [
  {
    id: "001",
    icon: "a_home",
    link: "Dashboard",
    linkTo: "/",
  },
  {
    id: "002",
    icon: "document-2",
    link: documentsTerm,
    linkTo: "/documents",
  },
  {
    id: "003",
    icon: "combo_chart",
    link: "Reports",
    linkTo: "/reports",
    subRoutes: ["/reports/:id"],
  },
  {
    id: "004",
    icon: "help",
    link: "Help Desk",
    linkTo: "/help-desk",
    subRoutes: ["/feedback"],
  },
];

const canAccessLink = (link: NavigationMenuLink, roles: string[]): boolean => {
  return link.roles
    ? roles.some((role) => link.roles!.includes(role))
    : link.expansion
    ? link.expansion.reduce(
        (canAccess, expansionLink) =>
          canAccess ||
          roles.some((role) =>
            expansionLink.roles ? expansionLink.roles.includes(role) : true
          ),
        false
      )
    : true;
};

const filterLinksByAccess = (
  links: NavigationMenuLink[],
  userRoles: string[]
): NavigationMenuLink[] => {
  return links
    .filter((link) => canAccessLink(link, userRoles))
    .map((link) =>
      link.expansion
        ? { ...link, expansion: filterLinksByAccess(link.expansion, userRoles) }
        : link
    );
};

export const Nav: FC = () => {
  const logo = useAppSelector(CoreRedux.selectCondensedLogoImageProps);

  // Group config terms
  const documentsTerm = useGroupTerm(
    "documents",
    "noun",
    "plural",
    "Documents"
  );

  // Get user roles
  const userRoles = useAppSelector(
    (state: AppState) =>
      state.user?.data?.authorities?.map((e) => e.authority) || []
  );

  // Home, Documents, Reports menu
  const userMenu = useMemo(
    () => filterLinksByAccess(buildUserMenu(documentsTerm), userRoles),
    [documentsTerm, userRoles]
  );

  // Array of options for admin menu sections
  const adminMenuData = useMemo(
    () =>
      filterLinksByAccess(
        peopleMenu.concat(formsMenu, contentMenu, settingsMenu),
        userRoles
      ),
    [userRoles]
  );

  const showAdminMenuHeader = adminMenuData.reduce(
    (canAccess, link) => canAccess || canAccessLink(link, userRoles),
    false
  );

  return (
    <nav className={styles.nav}>
      <div className={styles.navContent}>
        <MediaQuery maxWidth={scssVariables.breakpointExtraLarge}>
          <div className={styles.logo}>
            <LogoCondensed logo={logo} />
          </div>
          <UserProfileInfo className={styles.userProfileInfo} />
        </MediaQuery>
        <NavigationList menuData={userMenu} />
        {showAdminMenuHeader && (
          <h3 className={styles.navHeader}>System Admin</h3>
        )}
        <NavigationList menuData={adminMenuData} />
      </div>
      <div className={styles.privacyTermsWrapper}>
        <Link
          data-testid={`${Components.SidebarNavigation}-${ElementType.Link}-privacy`}
          className={styles.link}
          to="/privacy"
        >
          Privacy
        </Link>
      </div>
    </nav>
  );
};
