import { Property } from "../../../types";
import { fieldProperties } from "./fieldProperties";

const itemPropertiesMap: any = {
  BANNER_MESSAGE: fieldProperties.bannerMessage,
  BLOCK: fieldProperties.block,
  DATE: fieldProperties.date,
  DATE_TIME: fieldProperties.date,
  DEFENSES: fieldProperties.defenses,
  DOCUMENT_CREATOR: fieldProperties.documentCreator,
  DROP_DOWN: fieldProperties.dropdown,
  ESRI_CONTENT: fieldProperties.esriContent,
  LINE_BREAK: fieldProperties.lineBreak,
  MAP: fieldProperties.map,
  MULTI_SELECT: fieldProperties.multiSelect,
  OPERATIONAL_EXPERIENCES: fieldProperties.operationalExperiences,
  PARTICIPANT: fieldProperties.participant,
  PHOTO_GALLERY: fieldProperties.photoGallery,
  RADIO_BUTTONS: fieldProperties.radioButtons,
  SAFETY_RATING: fieldProperties.safetyRating,
  SECTION: fieldProperties.section,
  SIGNATURE: fieldProperties.signature,
  SUPERVISOR: fieldProperties.supervisor,
  TEXT_AREA: fieldProperties.textArea,
  TEXT_LINE: fieldProperties.textLine,
  TIME: fieldProperties.time,
  YES_OR_NO: fieldProperties.yesOrNo,
};

export const getItemProperties = (itemType: string): Property[] => {
  return itemPropertiesMap[itemType] || [];
};
