import React, { FC } from "react";

import ps from "../../styles.module.scss";
import { Select, SelectInputProps } from "../../../../../../Select/Select";
import { joinClassNames } from "../../../../../../../helpers/theme.helpers";
import { useFBConfigs } from "../../../../../../../util/hooks/useFBConfigs";

interface ShowHideFieldProps {
  value: SelectInputProps["value"];
  onChange: SelectInputProps["onChange"];
}

const ShowHideField: FC<ShowHideFieldProps> = ({ value, onChange }) => {
  const { formBuilderConfigs } = useFBConfigs();
  const { enableDataSets } = formBuilderConfigs;

  const options = [{ label: "Show/Hide", value: "showhide" }];

  if (enableDataSets) {
    options.push({ label: "Prefill", value: "prefill" });
  }

  return (
    <Select
      wrapperClassName={joinClassNames(ps.dcSelect, ps.ruleTypeSelect)}
      name="showhide"
      label=""
      value={value}
      placeholder="Select rule type"
      options={options}
      onChange={onChange}
    />
  );
};

export default ShowHideField;
