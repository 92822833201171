import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

import { Text, TextProps } from "shared/src/components/clientAdmin/styles";

export const DeleteDocumentButton: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return <Text color={theme.colors.error} fontSize="0.9rem" {...props} />;
};

export const IdLabel: React.FC<TextProps> = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <Text
      color={theme.masterColors.darkGrey}
      fontSize="0.875rem"
      fontWeight={400}
      {...props}
    />
  );
};
