import { State } from "@rtslabs/field1st-fe-common";

export interface StateEntry {
  label: string;
  value: State;
}

export const stateList: StateEntry[] = [
  {
    label: "AL",
    value: State.Alabama,
  },
  {
    label: "AK",
    value: State.Alaska,
  },
  {
    label: "AZ",
    value: State.Arizona,
  },
  {
    label: "AR",
    value: State.Arkansas,
  },
  {
    label: "CA",
    value: State.California,
  },
  {
    label: "CO",
    value: State.Colorado,
  },
  {
    label: "CT",
    value: State.Connecticut,
  },
  {
    label: "DE",
    value: State.Delaware,
  },
  {
    label: "FL",
    value: State.Florida,
  },
  {
    label: "GA",
    value: State.Georgia,
  },
  {
    label: "HI",
    value: State.Hawaii,
  },
  {
    label: "ID",
    value: State.Idaho,
  },
  {
    label: "IL",
    value: State.Illinois,
  },
  {
    label: "IN",
    value: State.Indiana,
  },
  {
    label: "IA",
    value: State.Iowa,
  },
  {
    label: "KS",
    value: State.Kansas,
  },
  {
    label: "KY",
    value: State.Kentucky,
  },
  {
    label: "LA",
    value: State.Louisiana,
  },
  {
    label: "ME",
    value: State.Maine,
  },
  {
    label: "MD",
    value: State.Maryland,
  },
  {
    label: "MA",
    value: State.Massachusetts,
  },
  {
    label: "MI",
    value: State.Michigan,
  },
  {
    label: "MN",
    value: State.Minnesota,
  },
  {
    label: "MS",
    value: State.Mississippi,
  },
  {
    label: "MO",
    value: State.Missouri,
  },
  {
    label: "MT",
    value: State.Montana,
  },
  {
    label: "NE",
    value: State.Nebraska,
  },
  {
    label: "NV",
    value: State.Nevada,
  },
  {
    label: "NH",
    value: State.NewHampshire,
  },
  {
    label: "NJ",
    value: State.NewJersey,
  },
  {
    label: "NM",
    value: State.NewMexico,
  },
  {
    label: "NY",
    value: State.NewYork,
  },
  {
    label: "NC",
    value: State.NorthCarolina,
  },
  {
    label: "ND",
    value: State.NorthDakota,
  },
  {
    label: "OH",
    value: State.Ohio,
  },
  {
    label: "OK",
    value: State.Oklahoma,
  },
  {
    label: "OR",
    value: State.Oregon,
  },
  {
    label: "PA",
    value: State.Pennsylvania,
  },
  {
    label: "RI",
    value: State.RhodeIsland,
  },
  {
    label: "SC",
    value: State.SouthCarolina,
  },
  {
    label: "SD",
    value: State.SouthDakota,
  },
  {
    label: "TN",
    value: State.Tennessee,
  },
  {
    label: "TX",
    value: State.Texas,
  },
  {
    label: "UT",
    value: State.Utah,
  },
  {
    label: "VT",
    value: State.Vermont,
  },
  {
    label: "VA",
    value: State.Virginia,
  },
  {
    label: "WA",
    value: State.Washington,
  },
  {
    label: "WV",
    value: State.WestVirginia,
  },
  {
    label: "WI",
    value: State.Wisconsin,
  },
  {
    label: "WY",
    value: State.Wyoming,
  },
];
