import {
  API,
  AuthProviderType,
  JWTAuthorityDTO,
  ParticipantType,
  ParticipantUserVm,
} from "@rtslabs/field1st-fe-common";
import { Formik } from "formik";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import Label from "shared/src/components/Label/Label";
import Loader from "shared/src/components/Loader/Loader";
import { Modal } from "shared/src/components/Modal/Modal";
import { RadioButton } from "shared/src/components/RadioButtons/RadioButton";
import {
  RadioButtonOption,
  RadioButtonsGroup,
} from "shared/src/components/RadioButtons/RadioButtonsGroup";
import {
  OptionContent,
  SearchSelect,
} from "shared/src/components/SearchableSelect/SearchSelect";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import { ErrorToast } from "shared/src/components/Toast/ErrorToast";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { useInfiniteScrollAPI } from "shared/src/util/hooks/useInfiniteScrollAPI";
import { AppState } from "../../../store";
import { ErrorText } from "shared/src/components/clientAdmin/resources/styles";
import { PageContent } from "shared/src/components/clientAdmin/styles";
import { RolesAndGroups } from "./components/RolesAndGroups";
import {
  atLeastOneRoleHasGroup,
  buildBreadcrumbs,
  EditUserFormValues,
  getInitialValues,
  mapClientGroupsOptions,
  mapSupervisorOptions,
  mapWorkLocations,
  updateAuthorities,
  validateAddUserForm,
} from "./helpers";
import * as S from "./styles";

interface Params extends Record<string, string> {
  id: string;
}

// Status options
const statusOptions: RadioButtonOption<string>[] = [
  { data: "ACTIVE", label: { text: "Active" } },
  { data: "DISABLED", label: { text: "Inactive" } },
];

const AddUser: FC = () => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const dispatch = useDispatch<ThunkDispatch<AppState, void, Action>>();
  // Group config terms
  const supervisorTerm = useGroupTerm(
    "supervisor",
    "noun",
    undefined,
    "Supervisor"
  );
  // Get user id from url
  const participantId = params.id ? Number(params.id) : undefined;

  // current user
  const [currentUser, setCurrentUser] = useState<Partial<ParticipantUserVm>>(
    {}
  );
  const [currentUserIsLoading, setCurrentUserIsLoading] = useState<boolean>(
    !!participantId
  );
  const [currentUserError, setCurrentUserError] = useState<boolean>(false);
  // authorities
  const [authorities, setAuthorities] = useState<JWTAuthorityDTO[]>([]);

  // form submission
  const [errorToast, setErrorToast] = useState({ error: "", showing: false });
  const [submitStatus, setSubmitStatus] = useState<string | null>(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const topOfForm = useRef<HTMLDivElement>(null);

  // form cancellation
  const [isCancelled, setIsCancelled] = useState<boolean>(false);

  function showError(message: string) {
    setErrorToast({ error: message, showing: true });
  }

  const workLocationsCall = useInfiniteScrollAPI(
    API.getWorkLocations,
    { sort: "name,asc" },
    () => showError("Problem getting work locations")
  );

  const supervisorsCall = useInfiniteScrollAPI(
    API.getParticipants,
    { sort: "name,asc" },
    () => showError("Problem getting supervisors")
  );

  const primaryGroupsCall = useInfiniteScrollAPI(
    API.getClientGroups,
    { sort: "name,asc" },
    () => showError("Problem getting primary groups")
  );

  /** get user data */
  const getUserData = useCallback(() => {
    if (participantId) {
      API.getUserByParticipantId({ participantId })
        .then((e) => {
          setCurrentUser(e);
          setAuthorities(e.authorities || []);
        })
        .catch(() => setCurrentUserError(true))
        .finally(() => setCurrentUserIsLoading(false));
    }
  }, [dispatch, participantId]);

  // Fetch current user data to prefill form
  useEffect(() => getUserData(), [getUserData, participantId]);

  // Initial values for formik
  const initialValues = useMemo(
    () => getInitialValues(currentUser),
    [currentUser.email, participantId]
  );

  /***
   * Form Submission
   ***/

  /**
   * Submit formik form
   * @param values Form values
   */
  const _handleSubmit = (values: EditUserFormValues) => {
    if (values?.createUser && !atLeastOneRoleHasGroup(authorities)) {
      setErrorToast({
        error: "At least one Group / Role set must be present",
        showing: true,
      });
      return;
    } else {
      setErrorToast({
        error: "",
        showing: false,
      });
    }

    if (values) {
      // API call to submit
      setSubmitLoading(true);
      setSubmitStatus(null);

      let callPromise: Promise<ParticipantUserVm>;
      if (!participantId) {
        callPromise = API.createUser({
          user: {
            // editable
            authorities,
            createUser: values.createUser,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            nickname: values.nickname,
            primaryGroupId: values.primaryGroupId,
            supervisorId: values.supervisorId,
            workLocationId: values.workLocationId!,

            // standard
            authProviderType: AuthProviderType.LOCAL,
            participantType: "EMPLOYEE" as ParticipantType,
          },
        });
      } else {
        callPromise = API.updateParticipant({
          user: {
            // editable
            authorities,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            nickname: values.nickname,
            primaryGroupId: values.primaryGroupId!,
            supervisorId: values.supervisorId,
            workLocationId: values.workLocationId!,

            // unchanged
            userId: currentUser.userId!,
            accountLocked: currentUser.accountLocked!,
            authProviderType: currentUser.authProviderType!,
            authProviderId: currentUser.authProviderId!,
            imageUrl: currentUser.imageUrl,
            langKey: currentUser.langKey,
            participantType: currentUser.participantType,
          },
        });
      }
      callPromise
        .then(() => setSubmitStatus("success"))
        .catch(() => setSubmitStatus("failure"))
        .finally(() => setSubmitLoading(false));
    }
  };

  // On successful form submit, go to `people/users`
  useEffect(() => {
    if (submitStatus === "success") {
      navigate("/people/users", { replace: true });
    } else if (submitStatus === "failure") {
      setErrorToast({
        error: "Failed to submit",
        showing: true,
      });
    }
  }, [history, submitStatus]);

  /***
   * Build field options
   ***/

  // Supervisor options
  const supervisorOptions = useMemo(() => {
    const result = mapSupervisorOptions(supervisorsCall.data, currentUser);
    // Add default empty option
    result.unshift({
      id: null,
      label: "No supervisor",
    });
    return result;
  }, [supervisorsCall.data]);

  // Work location options
  const workLocationOptions = useMemo(() => {
    const result = mapWorkLocations(workLocationsCall.data);
    // Add default empty option
    result.unshift({
      id: null,
      label: "No work location",
    });
    return result;
  }, [workLocationsCall.data]);

  // Client group options
  const clientGroupOptions = useMemo(() => {
    return mapClientGroupsOptions(primaryGroupsCall.data);
  }, [primaryGroupsCall.data]);

  // Breadcrumb paths
  const breadcrumbPaths = useMemo(
    () => buildBreadcrumbs(participantId, currentUser),
    [currentUser.firstName, participantId]
  );

  return (
    <PageContent>
      <Modal
        qaBase={Components.EditUser}
        action={{
          text: "Yes",
          callback: () => navigate("/people/users"),
          loading: false,
        }}
        secondaryText="No"
        alert={{
          variant: "error",
          title: "Are you sure you want to cancel?",
          message: "Changes you made will not be saved.",
          isVisible: true,
        }}
        handleClose={() => setIsCancelled(!isCancelled)}
        open={isCancelled}
        title="Are you sure?"
      />
      <div className="col-12" ref={topOfForm}>
        <Breadcrumbs paths={breadcrumbPaths} />
      </div>
      <div
        className="col-12 mt-1 mb-3"
        data-testid={`${Page.Desktop}-${Components.EditUser}`}
      >
        <Loader loading={currentUserIsLoading} spinnerProps={{ size: 20 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={_handleSubmit}
            enableReinitialize
            validate={validateAddUserForm}
            validateOnChange={false}
          >
            {(props) => {
              const {
                errors,
                handleSubmit,
                isValid,
                setFieldValue,
                touched,
                values,
                getFieldProps,
                submitCount,
              } = props;

              // Lift this state up so we can use it for form level validation
              useEffect(() => {
                if (submitCount > 0 && !isValid) {
                  showError(
                    `Oops, there was an issue submitting the form. 
                    Check the fields below for more helpful error messages.`
                  );
                  topOfForm.current?.scrollIntoView({ behavior: "smooth" });
                }
              }, [submitCount, isValid]);

              return (
                <form onSubmit={handleSubmit}>
                  <div className="pl-0 pr-0" style={{ width: "398px" }}>
                    {/* First Name */}
                    <TextInput
                      {...getFieldProps("firstName")}
                      label="First Name"
                      placeholder="First Name"
                      error={
                        (touched.firstName || submitCount > 0) &&
                        errors.firstName
                      }
                      qa={`${Components.EditUser}-${ElementType.TextInput}-firstName`}
                    />
                    {/* Last Name */}
                    <TextInput
                      {...getFieldProps("lastName")}
                      label="Last Name"
                      placeholder="Last Name"
                      error={
                        (touched.lastName || submitCount > 0) && errors.lastName
                      }
                      qa={`${Components.EditUser}-${ElementType.TextInput}-lastName`}
                    />
                    {/* Nickname */}
                    <TextInput
                      {...getFieldProps("nickname")}
                      label="Nickname"
                      placeholder="Nickname"
                      qa={`${Components.EditUser}-${ElementType.TextInput}-nickName`}
                    />
                    {/* Emil Address */}
                    <TextInput
                      {...getFieldProps("email")}
                      label="Email Address"
                      placeholder="Email Address"
                      error={(touched.email || submitCount > 0) && errors.email}
                      qa={`${Components.EditUser}-${ElementType.TextInput}-email`}
                    />
                    {/* Work Location */}
                    <SearchSelect
                      {...getFieldProps("workLocationId")}
                      label="Work Location"
                      handleChange={(value) => {
                        setFieldValue(
                          "workLocationId",
                          Array.isArray(value) ? undefined : value?.id,
                          true
                        );
                      }}
                      handleUpdateSearch={(s) =>
                        workLocationsCall.setQuery(s ?? "")
                      }
                      isApiSearch
                      onLoadMore={workLocationsCall.loadMore}
                      isFinalPage={workLocationsCall.isLastPage}
                      placeholder={"Work Location"}
                      options={workLocationOptions}
                      value={
                        workLocationOptions.find(
                          (e) => e.id === values["workLocationId"]
                        ) || null
                      }
                      error={
                        (touched.workLocationId || submitCount > 0) &&
                        errors.workLocationId
                      }
                      qa={`${Components.EditUser}-${ElementType.TextInput}-workLocation`}
                    />
                    {/* Supervisor */}
                    <SearchSelect
                      {...getFieldProps("supervisorId")}
                      label={supervisorTerm}
                      handleChange={(value) => {
                        setFieldValue(
                          "supervisorId",
                          Array.isArray(value) ? undefined : value?.id
                        );
                      }}
                      handleUpdateSearch={(s) =>
                        supervisorsCall.setQuery(s ?? "")
                      }
                      isApiSearch
                      onLoadMore={supervisorsCall.loadMore}
                      placeholder={supervisorTerm}
                      options={supervisorOptions}
                      value={
                        supervisorOptions.find(
                          (e) => e.id === values["supervisorId"]
                        ) || null
                      }
                      qa={`${Components.EditUser}-${ElementType.TextInput}-supervisor`}
                    />
                    {/* Primary Group */}
                    <SearchSelect
                      {...getFieldProps("primaryGroupId")}
                      label="Primary Group"
                      handleChange={(
                        value: OptionContent | OptionContent[] | null
                      ) => {
                        setFieldValue(
                          "primaryGroupId",
                          Array.isArray(value) ? undefined : value?.id,
                          true
                        );
                      }}
                      handleUpdateSearch={(s) =>
                        primaryGroupsCall.setQuery(s ?? "")
                      }
                      isApiSearch
                      onLoadMore={primaryGroupsCall.loadMore}
                      placeholder={"Select group"}
                      options={clientGroupOptions}
                      touched={touched["primaryGroupId"]}
                      value={
                        clientGroupOptions.find(
                          (e) => e.id === values["primaryGroupId"]
                        ) || null
                      }
                      error={
                        (touched.primaryGroupId || submitCount > 0) &&
                        errors.primaryGroupId
                      }
                      qa={`${Components.EditUser}-${ElementType.TextInput}-primaryGroup`}
                    />
                    {/* Status */}
                    <RadioButtonsGroup<string | undefined>
                      {...getFieldProps("status")}
                      label="Status"
                      name="status"
                      onChange={(e) =>
                        e === "ACTIVE"
                          ? setFieldValue("status", e)
                          : setFieldValue("status", "DISABLED")
                      }
                      options={statusOptions}
                      value={values["status"]}
                      qa={`${Components.EditUser}-${ElementType.RadioGroup}-status`}
                    />
                    {/* User Type */}
                    {!participantId && (
                      <>
                        <Label>User Type</Label>
                        <RadioButton
                          {...getFieldProps("createUser")}
                          checked={values.createUser}
                          label="User (with system login)"
                          onChange={() => {
                            setFieldValue("createUser", true);
                          }}
                          qa={`${Components.EditUser}-userType_user`}
                        />
                        <RadioButton
                          {...getFieldProps("createUser")}
                          checked={!values.createUser}
                          label="Crew Member Participant (no system login)"
                          onChange={() => {
                            setFieldValue("createUser", false);
                          }}
                          qa={`${Components.EditUser}-userType_crewMember`}
                        />
                      </>
                    )}
                  </div>

                  {/* This shows if you're creating a user only, doesn't show for participants -- GK */}
                  {/* Groups & Roles */}
                  {values.createUser && (
                    <RolesAndGroups
                      authorities={authorities}
                      updateAuthority={(
                        authority: JWTAuthorityDTO,
                        method?: "replace"
                      ) => {
                        setAuthorities(
                          updateAuthorities(authorities, authority, method)
                        );
                      }}
                      removeAuthority={(authority: string) => {
                        setAuthorities(
                          authorities.filter((a) => a.authority !== authority)
                        );
                      }}
                    />
                  )}

                  <ErrorToast
                    onClick={() =>
                      setErrorToast({
                        error: "",
                        showing: false,
                      })
                    }
                    visible={errorToast.showing}
                  >
                    {errorToast.error}
                  </ErrorToast>
                  <br />
                  <S.ButtonsWrapper>
                    <S.AddUserButton
                      data-testid={`${Components.EditUser}-${ElementType.Button}-addOrEdit`}
                      disabled={submitLoading}
                      type="submit"
                    >
                      <S.LoaderWrapper>
                        <Loader
                          loading={submitLoading}
                          className="p-0 mr-3"
                          spinnerProps={{ size: 14 }}
                        />
                      </S.LoaderWrapper>
                      <span>{`${participantId ? "edit" : "add"}`} user </span>
                    </S.AddUserButton>
                    <S.CancelButton
                      data-testid={`${Components.EditUser}-${ElementType.Button}-cancel`}
                      disabled={submitLoading}
                      onClick={() => setIsCancelled(true)}
                    >
                      cancel
                    </S.CancelButton>
                  </S.ButtonsWrapper>
                </form>
              );
            }}
          </Formik>
          {currentUserError && <ErrorText>{currentUserError}</ErrorText>}
        </Loader>
      </div>
    </PageContent>
  );
};

export default AddUser;
