import React from "react";
import { Components, ElementType } from "../../qa-slugs";
import { Modal } from "../Modal/Modal";

interface DeletePhotoModalProps {
  visible: boolean;
  loading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DeletePhotoConfirmationModal = ({
  visible,
  loading,
  onConfirm,
  onCancel,
}: DeletePhotoModalProps) => {
  return (
    <Modal
      qaBase={`${Components.PhotoArea}-${ElementType.Modal}-delete-modal`}
      open={visible}
      handleClose={onCancel}
      alert={{
        variant: "error",
        message: "This change cannot be undone.",
        title: `Are you sure you want to delete this photo from your assessment?`,
        isVisible: true,
      }}
      title="Delete Photo"
      cancellable={true}
      action={{
        text: "delete photo",
        loading: loading,
        callback: onConfirm,
      }}
    />
  );
};
