import { FormTypeDTO } from "@rtslabs/field1st-fe-common";
import React, { FC } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";
import { QAProps } from "../../qa-slugs";
import { Icon } from "../Icon/Icon";
import styles from "./Documents.module.scss";

interface Props extends QAProps {
  formType: FormTypeDTO;
  className?: string;
}

export const FormTypeIcon: FC<Props> = ({ formType, className, qa }) => {
  if (!formType.iconName) return null;

  return (
    <Icon
      qa={qa}
      type={formType.iconName?.replace(/icon-icons8-/, "")}
      color={formType.iconColor || ""}
      className={joinClassNames(styles.formTypeIcon, className)}
    />
  );
};
