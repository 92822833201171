import { DocumentVm } from "@rtslabs/field1st-fe-common";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../shared/src/components/Button/Button";
import { Icon } from "../../../../../shared/src/components/Icon/Icon";
import Loader from "../../../../../shared/src/components/Loader/Loader";
import { Table } from "../../../../../shared/src/components/TableUI/Table/Table";
import { Components, ElementType } from "../../../../../shared/src/qa-slugs";
import { ReactComponent as Powerlines } from "../../../assets/svg/powerlines.svg";
import styles from "./RecentsCard.module.scss";
import { RecentsListItem } from "./RecentsListItem";

interface RecentsCardProps {
  documents: DocumentVm[];
  loading: boolean;
}

export const RecentsCard = ({ documents, loading }: RecentsCardProps) => {
  const navigate = useNavigate();
  const [selectedDocumentId, setSelectedDocumentId] = React.useState<number>();

  const handleClickDocument = (id: number) => {
    selectedDocumentId === id
      ? setSelectedDocumentId(undefined)
      : setSelectedDocumentId(id);
  };

  return (
    <Loader loading={loading} className={styles.loader}>
      {documents.length > 0 ? (
        <Table>
          <tbody className={styles.recentsListContainer}>
            {documents.map((document) => (
              <RecentsListItem
                document={document}
                key={document.id}
                selectedDocumentId={selectedDocumentId}
                onClickDocument={(id: number) => handleClickDocument(id)}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className={styles.noRecentsDataArea}>
          <p
            className={styles.noDocumentsText}
            data-testid={`${Components.RecentDocuments}-${ElementType.Text}-noDocumentsMessage`}
          >
            No Documents have been created
          </p>
          <Button
            className={styles.addButton}
            onClick={() => navigate("/documents?openDrawer=true")}
            qa={`${Components.RecentDocuments}-${ElementType.Button}-createDocument`}
            startContent={
              <Icon
                className={styles.addButtonIcon}
                size={20}
                type="add_file"
              />
            }
          >
            Start a New Document
          </Button>
          <Powerlines className={styles.powerlines} />
        </div>
      )}
    </Loader>
  );
};
