import React, { FC } from "react";
import { Select, SelectInputProps } from "./Select";

export interface LazyLoadSelectProps extends SelectInputProps {
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  isFinalPage?: boolean;
}

const LazyLoadSelect: FC<LazyLoadSelectProps> = ({
  onLoadMore,
  isLoadingMore,
  options,
  isFinalPage,
  ...selectProps
}) => {
  const handleScrollToBottom = () => {
    if (!isFinalPage && !isLoadingMore && onLoadMore) onLoadMore();
  };
  return (
    <Select
      {...selectProps}
      options={options}
      onMenuScrollToBottom={handleScrollToBottom}
      isLoading={isLoadingMore}
    />
  );
};

export { LazyLoadSelect };
