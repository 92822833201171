import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import { useMediaQuery } from "react-responsive";

import { devices } from "shared/src/themes/mediaQueries";
import { API, CoreRedux, useAPI } from "@rtslabs/field1st-fe-common";
import { Logout } from "shared/src/components/Logout/Logout";
import ProfileCard, {
  UserForm,
} from "shared/src/components/Profile/ProfileCard/ProfileCard";
import ProfileMenu from "shared/src/components/Profile/ProfileMenu/ProfileMenu";
import EditProfile from "../EditProfile";
import topography from "../../../assets/svg/topography.svg";
import useGroupTerm from "shared/src/util/hooks/useGroupTerm";
import { useAuth } from "../../../store/hooks/useAuth";

import styles from "./ProfileScreen.module.scss";

const Profile = () => {
  const isTablet = useMediaQuery({
    maxWidth: devices.maxTablet,
  });

  const userProfile = useSelector(CoreRedux.getUserProfile);
  const participantId = API.Environment.getParticipantId()!; // we know we're authenticated
  const userFormStatsCall = useAPI(API.getParticipantFormSubmissionStats, {
    participantId,
  });

  const userForms: UserForm[] = useMemo(() => {
    if (!userFormStatsCall.data) {
      return [];
    }
    return sortBy(
      userFormStatsCall.data.map((ufs) => ({
        id: ufs.formTypeId,
        count: ufs.totalDocuments,
        icon: ufs.formTypeIconName?.replace(/icon-icons8-/, ""),
        iconColor: ufs.formTypeIconColor,
        name: ufs.formTypeName,
      })),
      "name"
    );
  }, [userFormStatsCall.data]);

  const groupLabel = useMemo(() => {
    const { primaryGroupId, groups } = userProfile;

    const primaryGroup = groups.find((x) => x.id === primaryGroupId);

    if (primaryGroup && primaryGroup.name) {
      const count = groups.length - 1;
      const other = count === 1 ? "other" : "others";
      const countLabel = count > 0 ? ` + ${count} ${other}` : "";
      const otherGroups = groups
        .filter((x) => x.id !== primaryGroupId)
        .map((y) => y.name);
      const otherGroupsLabel =
        otherGroups.length > 0 ? `, ${otherGroups.join(", ")}` : undefined;

      return {
        mobile: `${primaryGroup.name}` + countLabel,
        desktop: otherGroupsLabel
          ? `${primaryGroup.name}` + otherGroupsLabel
          : `${primaryGroup.name}`,
      };
    }

    return {
      mobile: "",
      desktop: "",
    };
  }, [userProfile.groups, userProfile.primaryGroupId]);

  // Group config terms
  const supervisorTerm = useGroupTerm(
    "supervisor",
    "noun",
    undefined,
    "Supervisor"
  );
  const { logout } = useAuth();
  return (
    <>
      <ProfileCard
        name={userProfile.name}
        email={userProfile.email || ""}
        groupLabel={groupLabel}
        userForms={userForms}
        headerBackground={topography}
        supervisor={userProfile.supervisor || ""}
        adfsEnabled={userProfile.adfsEnabled}
        supervisorTerm={supervisorTerm}
      />
      {isTablet && <ProfileMenu />}
      {!isTablet && <EditProfile />}
      {isTablet && (
        <Logout className={styles.logout_spacing} onLogOut={logout} />
      )}
    </>
  );
};

export default Profile;
