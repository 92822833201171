import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { remCalc } from "shared/src/themes/helpers";

import { Icon } from "shared/src/components/Icon/Icon";

// although "dragging" is not currently used, it may be helpful if styles change in the future -JA
export const DragToDiv = styled.div<{ dragging: boolean; success: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${remCalc(279)};
  width: ${remCalc(398)};
  background-color: ${({ theme, success }) =>
    success ? theme.masterColors.successFill : theme.colors.activeFill};
  border-radius: ${remCalc(4)};
  border: 2px dashed
    ${({ theme, success }) =>
      success ? theme.colors.success : theme.colors.primary};
`;

export const DragToDivContent = styled.div`
  width: 100%;
  text-align: center;
`;

export const InstructionalText = styled.span`
  display: block;
  font-size: ${remCalc(20)};
  font-weight: 500;
`;

export const LinkText = styled.span<{ ["data-testid"]?: string }>`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
`;

export const HiddenInput = styled.input`
  display: none;
`;

interface SuccessIconProps {
  successState: boolean;
}

export const SuccessIcon = styled<React.FC<SuccessIconProps>>(
  ({ successState }) => {
    const theme = useContext(ThemeContext);
    return (
      <Icon
        color={successState ? theme.colors.success : theme.colors.black}
        type={successState ? "checked" : "add_file"}
        size="50px"
      />
    );
  }
)``;
