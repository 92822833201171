import React, { FC, useState } from "react";

import FormActions from "./FormActions";
import SelectForm from "./SelectForm";
import SelectFormType from "shared/src/components/Documents/components/NewForm/SelectFormType";
import WorkOrder from "./WorkOrder";

import styles from "./NewForm.module.scss";
import {
  FormSummaryVm,
  FormTypeDTO,
  PaginatedResponse,
  WorkOrderDTO,
} from "@rtslabs/field1st-fe-common";
import { Icon } from "shared/src/components/Icon/Icon";
import { DocumentScreenTerms } from "../../types";

interface NewFormProps {
  formTypes: FormTypeDTO[];
  handleClose: () => void;
  initialFormType?: FormTypeDTO;
  onCreateDocument: (formId: number, workOrderId?: number) => Promise<void>;
  onSearchWorkOrders: (
    workOrderId: string
  ) => Promise<PaginatedResponse<WorkOrderDTO>>;
  title: string;
  formTypesLoading: boolean;
  forms: FormSummaryVm[];
  formsLoading: boolean;
  terms: DocumentScreenTerms;
}

const NewForm: FC<NewFormProps> = ({
  formTypes,
  handleClose,
  initialFormType,
  onCreateDocument,
  onSearchWorkOrders,
  title = "New Document",
  formTypesLoading,
  forms,
  formsLoading,
  terms,
}) => {
  const [formType, setFormType] = useState<FormTypeDTO["id"] | undefined>(
    initialFormType?.id
  );
  const [createDocumentLoading, setCreateDocumentLoading] =
    useState<boolean>(false);
  const [formTemplate, setFormTemplate] = useState<FormSummaryVm>();
  const [workOrderId, setWorkOrderId] = useState<number>();
  const [workOrders, setWorkOrders] = useState<WorkOrderDTO[] | undefined>();
  const [workOrdersLoading, setWorkOrdersLoading] = useState<boolean>(false);

  async function handleSearchWorkOrders(workOrderId: string): Promise<void> {
    if (workOrderId) {
      setWorkOrdersLoading(true);
      try {
        const res = await onSearchWorkOrders(workOrderId);
        setWorkOrders(res.content);
      } finally {
        setWorkOrdersLoading(false);
      }
    } else {
      setWorkOrders(undefined);
    }
  }

  const handleStartDocument = () => {
    if (!formTemplate) return;
    setCreateDocumentLoading(true);
    try {
      onCreateDocument(formTemplate.id, workOrderId);
    } finally {
      setCreateDocumentLoading(false);
    }
  };

  const displayWorkOrderSelector =
    !!formType && !!formTemplate && formTemplate?.startFromWorkOrderEnabled;

  return (
    <div>
      <div className={styles.header}>
        <h1>{title}</h1>
        <button className={styles.closeBtn} onClick={handleClose}>
          <Icon type="delete_sign" />
        </button>
      </div>
      <SelectFormType
        handleSelectFormType={setFormType}
        formTypes={formTypes}
        loading={formTypesLoading}
        selectedFormTypeId={formType}
        selectedFormTemplateId={formTemplate?.id}
        handleSelectFormTemplate={setFormTemplate}
      />
      <SelectForm
        loading={formsLoading}
        formTemplates={forms}
        handleSelectFormTemplate={setFormTemplate}
        selectedFormTypeId={formType}
        selectedFormTemplateId={formTemplate?.id}
      />
      {displayWorkOrderSelector && (
        <WorkOrder
          handleSelectWorkOrderId={setWorkOrderId}
          selectedWorkOrderId={workOrderId}
          workOrders={workOrders}
          loading={workOrdersLoading}
          onSearchForWorkOrders={handleSearchWorkOrders}
          clearWorkOrders={() => {
            setWorkOrders(undefined);
          }}
        />
      )}
      <FormActions
        selectedFormTypeId={formType}
        selectedFormTemplateId={formTemplate?.id}
        selectedWorkOrderId={workOrderId}
        startNewDocument={handleStartDocument}
        createDocumentLoading={createDocumentLoading}
        terms={terms}
      />
    </div>
  );
};

export default NewForm;
