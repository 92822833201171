import React from "react";

import styles from "./ReadOnlySignatures.module.scss";
import { joinClassNames } from "../../../../helpers/theme.helpers";

interface Props {
  signatureUrl?: string | null;
}

export const RenderDrawnSignature = ({ signatureUrl }: Props) => {
  if (!signatureUrl) return null;

  return (
    <div className={joinClassNames(styles.signatureWrapper, styles.drawn)}>
      <img className={styles.signatureImg} src={signatureUrl} />
    </div>
  );
};
