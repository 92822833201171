import React from "react";
import styles from "../Checkbox.module.scss";

interface Error {
  error?: string;
}

export const CheckBoxTwoTone = ({ error }: Error) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      className={styles.checkmark}
    >
      <path
        className={error ? styles.errorBackground : styles.background}
        d="M5 19h14V5H5v14zm2.41-7.4l2.58 2.58 6.59-6.59L17.99 9l-8 8L6 13.01l1.41-1.41z"
      />
      <path
        className={error ? styles.errorFill : styles.fill}
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"
      />
    </svg>
  );
};
