import { useRef, useEffect } from "react";

function usePrevious<T>(value: T): T | null {
  const ref = useRef<T | null>(null);

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value
  return ref.current;
}

export default usePrevious;
