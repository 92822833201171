import React from "react";
import styles from "./NewForm.module.scss";
import { Components, ElementType } from "shared/src/qa-slugs";
import { Button, SecondaryButton } from "shared/src/components/Button/Button";
import Loader from "shared/src/components/Loader/Loader";
import { DocumentScreenTerms } from "../../types";
import { FormTypeDTO } from "@rtslabs/field1st-fe-common";

export interface FormActionsProps {
  selectedFormTypeId?: FormTypeDTO["id"];
  selectedFormTemplateId?: number;
  selectedWorkOrderId?: number;
  startNewDocument: (prefill?: boolean) => void;
  createDocumentLoading: boolean;
  terms: DocumentScreenTerms;
}

/** FormActions handles rendering the buttons used to create a NewDocument */
const FormActions = ({
  selectedFormTemplateId,
  selectedFormTypeId,
  selectedWorkOrderId,
  startNewDocument,
  createDocumentLoading,
  terms,
}: FormActionsProps) => {
  return (
    <div className={styles.formActions}>
      {selectedFormTemplateId && selectedFormTypeId && (
        <SecondaryButton
          qa={`${Components.GhostButton}-${ElementType.Button}-start-blank-doc`}
          onClick={() => startNewDocument(false)}
          disabled={createDocumentLoading}
          className={styles.secondaryBtn}
        >
          START BLANK {terms.document.singular.toUpperCase()}
          <Loader loading={createDocumentLoading} />
        </SecondaryButton>
      )}

      {selectedWorkOrderId && (
        <Button
          qa={`${Components.Button}-${ElementType.Button}-prefill-with-workorder`}
          disabled={createDocumentLoading}
          onClick={() => startNewDocument(true)}
        >
          PRE-FILL WITH {terms.workOrder.singular} INFORMATION
          <Loader loading={createDocumentLoading} />
        </Button>
      )}
    </div>
  );
};

export default FormActions;
