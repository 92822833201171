import React from "react";
import { useNavigate } from "react-router-dom";
import { IconBox } from "shared/src/components/Icon/IconBox";
import { QAProps } from "../../../../../shared/src/qa-slugs";
import * as S from "./styles";

type RenderValueParams = {
  notAvailable?: boolean;
  type?: string;
  value?: Value;
  valueName?: string;
  clickable?: boolean;
};

/**
 * Generate a value or N/A element depending on whether data is available to show
 * @param notAvailable  - is the value available to show?
 * @param type          - type of value (time vs other)
 * @param value
 * @param valueName
 * @param clickable     - is the value clickable?
 */
function RenderValue({
  notAvailable,
  type,
  value,
  valueName,
  clickable,
}: RenderValueParams) {
  const displayNA =
    notAvailable ||
    value === undefined ||
    value === null ||
    (typeof value !== "number" && type === "time");
  if (displayNA) {
    // display N/A
    return (
      <S.ValueWrapper>
        <S.Value>N/A</S.Value>
      </S.ValueWrapper>
    );
  } else if (type === "time") {
    // format and render time display
    const valueInMinutes = Number(value) / 60;
    const minutes = (Number(valueInMinutes) % 60).toFixed(0); // Get minutes
    const hours = Math.floor(Number(valueInMinutes) / 60); // Get hours
    const minutesLabel = "mins"; // Get label for minutes
    const hoursLabel = [1].includes(hours) ? "hr" : "hrs"; // Get label for hours
    return (
      <span className="d-flex flex-row">
        {(hours && (
          <S.ValueWrapper>
            <S.Value>{hours}</S.Value>
            <S.ValueName>{hoursLabel}</S.ValueName>
          </S.ValueWrapper>
        )) ||
          ""}
        <S.ValueWrapper>
          <S.Value>{minutes}</S.Value>
          <S.ValueName>{minutesLabel}</S.ValueName>
        </S.ValueWrapper>
      </span>
    );
  }
  // value is available and not a time measurement - render raw
  return (
    <S.ValueWrapper>
      <S.Value clickable={clickable}>{value}</S.Value>
      <S.ValueName>{valueName}</S.ValueName>
    </S.ValueWrapper>
  );
}

type Value = string | number | JSX.Element;

type Props = {
  children?: JSX.Element;
  clickable?: boolean;
  header?: string;
  height?: string;
  iconColor?: string | null;
  iconName?: string | null;
  linkTo?: string;
  notAvailable?: boolean;
  stretch?: boolean;
  type?: string;
  value?: Value;
  valueColor?: string;
  valueName?: string;
} & QAProps;

// TODO use Card as wrapper here?
const StatsCard = ({
  children,
  header,
  height,
  iconColor,
  iconName,
  linkTo,
  notAvailable,
  qa,
  stretch,
  type,
  value,
  valueName,
}: Props) => {
  const navigate = useNavigate();
  const clickable = !!linkTo;

  return (
    <S.Wrapper
      data-testid={qa}
      clickable={clickable}
      stretch={stretch}
      onClick={() => linkTo && navigate(linkTo)}
      height={height}
    >
      <S.ContentWrapper>
        <div>
          <S.Header>{header}</S.Header>
          <RenderValue
            notAvailable={notAvailable}
            clickable={clickable}
            type={type}
            value={value}
            valueName={valueName}
          />
        </div>
        {iconName && <IconBox color={iconColor ?? undefined} type={iconName} />}
      </S.ContentWrapper>
      {children}
    </S.Wrapper>
  );
};

export default StatsCard;
