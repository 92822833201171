import React, { FC } from "react";

interface ClassName {
  className?: string;
}
export const Submit: FC<ClassName> = ({ className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      x="0px"
      y="0px"
      viewBox="0 0 12 12"
      className={className}
      fill="none"
    >
      <path
        d="M11.7,0.1C11.9,0.3,12,0.6,12,0.9l-1.5,9.7c0,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.1-0.7,0L6.7,10l-1.6,1.7
	c-0.2,0.2-0.5,0.3-0.8,0.2c-0.3-0.1-0.5-0.4-0.5-0.7v-2c0-0.1,0-0.2,0.1-0.3l3.9-4.3c0.1-0.1,0.1-0.4,0-0.5c-0.1-0.1-0.4-0.1-0.5,0
	L2.5,8.5l-2.1-1C0.2,7.3,0,7,0,6.8c0-0.3,0.1-0.5,0.4-0.7l10.5-6C11.1,0,11.4,0,11.7,0.1z"
        fill="white"
      />
    </svg>
  );
};
