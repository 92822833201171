import {
  API,
  DefensesWidgetDTO,
  SectionItemSaveVm,
} from "@rtslabs/field1st-fe-common";
import { useFormikContext } from "formik";
import { get } from "lodash";
import React, { useMemo } from "react";
import AutoSaveItem from "../../autoSave/AutoSaveItem";
import SidebarIntro from "../../baseUi/sidebar/SidebarIntro";
import SidebarLabel from "../../baseUi/sidebar/SidebarLabel";
import { getMissingWidgets, unmarshallForm } from "../../helpers";
import { PROPERTIES_HEADER_TEXT } from "../../language";
import { FBForm, FBItem, FBSection } from "../../../types";
import { GeneratorFn, ItemParams } from "../Create";
import { elements } from "../items/Elements";
import { fields } from "../items/Fields";
import { Selections } from "./customFields/Selections/Selections";
import { DefensesProperties } from "./DefensesProperties";
import DisplayConditionsBanner from "./DisplayConditionsBanner";
import { getItemInSection } from "./getItemInSection";
import { getItemPath } from "./getItemPath";
import { getItemProperties } from "./helpers";
import MissingWidgetBanner, { MissingWidgetType } from "./MissingWidgetBanner";
import Property from "./Property";
import s from "./styles.module.scss";
import { useFBConfigs } from "../../../../../util/hooks/useFBConfigs";

interface Props {
  currentItem: FBItem | FBSection;
  setCurrentItem: (item: FBItem | FBSection) => void;
  onAddItem: GeneratorFn;
  onUpdateItem: (item: FBItem | FBSection) => Promise<FBForm | undefined>;
  onSaveError?: (error: string) => void;
  appWidgetsList: ItemParams[];
}

const FormItemProperties: React.FC<Props> = ({
  currentItem,
  setCurrentItem,
  onAddItem,
  onUpdateItem,
  onSaveError,
  appWidgetsList,
}) => {
  const { formBuilderConfigs } = useFBConfigs();
  const { values, setValues } = useFormikContext<FBForm>();
  const { enableCloneDocument, enableVoiceToText, enableWO } =
    formBuilderConfigs;
  const itemNames = [...appWidgetsList, ...elements, ...fields];
  const itemPath = useMemo(
    () => getItemPath(values.sections, currentItem),
    [currentItem, values]
  );
  let itemType: string = currentItem.subType;
  if (
    currentItem.type === "QUESTION" &&
    currentItem.properties?.fakeWidgetType
  ) {
    itemType = currentItem.properties.fakeWidgetType;
  }

  /**
   * Build the item's name based on its subtype
   * @param subType - subType of the item
   */
  function getHeaderLabel(subType: string): string {
    const item = itemNames.find((i) => i.subType === subType);
    return item?.name || "";
  }

  const headerLabel = useMemo(() => getHeaderLabel(itemType), [itemType]);

  const itemProps = useMemo(() => {
    if (itemType) {
      let itemProperties = getItemProperties(itemType);

      if (!enableCloneDocument) {
        itemProperties = itemProperties.filter(
          (ip) => ip.name !== "formProperties.isCloneable"
        );
      }

      if (!enableVoiceToText) {
        itemProperties = itemProperties.filter(
          (ip) => ip.name !== "properties.voiceToTextEnabled"
        );
      }

      if (!enableWO) {
        itemProperties = itemProperties.filter(
          (ip) => ip.name !== "prefillFromWorkOrder"
        );
      }

      return itemProperties;
    }
    return [];
  }, [currentItem]);

  const rules = useMemo(
    () => itemProps.filter((prop) => prop.propertyType === "RULE"),
    [itemProps]
  );

  const properties = useMemo(
    () => itemProps.filter((prop) => prop.propertyType === "PROPERTY"),
    [itemProps]
  );
  const selections = useMemo(
    () => itemProps.filter((prop) => prop.propertyType === "SELECTIONS"),
    [itemProps]
  );

  const sourceDisplayConditions =
    (currentItem.type === "QUESTION" &&
      values.displayConditions?.filter(
        (dc) =>
          dc.action !== "WORK_ORDER_PREFILL" &&
          dc.targetRootId === currentItem.rootId
      )) ||
    [];
  const missingWidgets =
    currentItem.type === "QUESTION"
      ? getMissingWidgets(values, currentItem)
      : [];

  const item = useMemo(
    () => get(values, itemPath, {} as FBItem),
    [itemPath, values]
  );

  // get parent if exists
  const parentRootId = useMemo(() => get(item, "parentQuestionRootId"), [item]);
  const parentItem = useMemo(
    () =>
      parentRootId &&
      getItemInSection(values, item.parentSectionRootId, parentRootId),
    [item, parentRootId, values]
  );
  const parentItemPath = useMemo(
    () => parentItem && getItemPath(values.sections, parentItem),
    [parentItem, values]
  );

  async function addMissingWidget(type: MissingWidgetType) {
    // @TODO this is a hack find a better way to prevent updates on Published form
    if (values.workflowType === "FINAL") return;
    // end hack

    const items: SectionItemSaveVm[] = [];
    if (type === "DEFENSES") {
      items.push({
        type: "WIDGET",
        subType: "DEFENSES",
        autoAppendComment: false,
      });
    } else if (type === "OPERATIONAL_EXPERIENCES") {
      items.push({
        type: "WIDGET",
        subType: "OPERATIONAL_EXPERIENCES",
        numberRequired: 0,
      });
    }

    const res = await API.addOrUpdateFormSection({
      formId: values.id,
      section: {
        items,
        title: "",
        workflowType: "DRAFT",
      },
    });
    setValues(unmarshallForm(res));
  }

  return (
    <>
      <AutoSaveItem
        values={get(values, itemPath)}
        itemPath={itemPath}
        onError={onSaveError}
      />
      {!parentItemPath ? null : (
        <AutoSaveItem
          values={get(values, parentItemPath)}
          itemPath={parentItemPath}
          onError={onSaveError}
        />
      )}
      <SidebarIntro
        active
        direction="right"
        label={`${headerLabel} Properties`}
        detail={PROPERTIES_HEADER_TEXT[itemType]}
      />
      <div className={s.properties}>
        {sourceDisplayConditions.length > 0 && (
          <>
            <DisplayConditionsBanner
              sourceDisplayConditions={sourceDisplayConditions}
              onSelectItem={setCurrentItem}
            />
            <hr className={s.hr} />
          </>
        )}
        {properties.length > 0 &&
          properties.map((prop) => (
            <Property
              itemPath={itemPath}
              key={`${itemPath}.${prop.name}`}
              property={prop}
              item={item}
              appWidgetsList={appWidgetsList}
            />
          ))}
        {selections.length > 0 && (
          <>
            <hr className={s.hr} />
            {selections.map((prop) => (
              <Selections
                itemPath={itemPath}
                itemType={itemType}
                key={`${itemPath}.${prop.name}`}
                onUpdateItem={onUpdateItem}
                property={prop}
                item={item}
                appWidgetsList={appWidgetsList}
              />
            ))}
          </>
        )}
        {rules.length > 0 && (
          <>
            <hr className={s.hr} />
            <SidebarLabel>Rules</SidebarLabel>
            {rules.map((prop) =>
              prop.forParent && parentItem && parentItemPath ? (
                <Property
                  onAddItem={onAddItem}
                  itemPath={parentItemPath}
                  key={`${parentItemPath}.${prop.name}`}
                  property={prop}
                  item={parentItem}
                  appWidgetsList={appWidgetsList}
                />
              ) : (
                <Property
                  onAddItem={onAddItem}
                  itemPath={itemPath}
                  key={`${itemPath}.${prop.name}`}
                  property={prop}
                  item={item}
                  appWidgetsList={appWidgetsList}
                />
              )
            )}
          </>
        )}
        {item.type === "WIDGET" && currentItem.subType === "DEFENSES" && (
          <>
            <hr className={s.hr} />
            <DefensesProperties
              item={item as DefensesWidgetDTO}
              itemPath={itemPath}
            />
          </>
        )}
        {missingWidgets.length > 0 &&
          missingWidgets.map((missingWidget) => (
            <MissingWidgetBanner
              key={missingWidget}
              type={missingWidget}
              onAddMissing={() => addMissingWidget(missingWidget)}
            />
          ))}
      </div>
    </>
  );
};

export default FormItemProperties;
