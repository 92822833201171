import { DocumentVm } from "@rtslabs/field1st-fe-common";
import React, { FC, useEffect, useId } from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import { Components, ElementType } from "shared/src/qa-slugs";
import { LocationMarker } from "shared/src/api/geolocationAPI";
import { ClassicLocationMarker } from "shared/src/components/Document/DocumentForm/LocationField/geo";
import { scrollToElement } from "shared/src/components/clientAdmin/formBuilder/helpers";
import { ArcGisMap } from "../../common/ArcGisMap/ArcGisMap";
import { EsriViewport } from "../../common/ArcGisMap/esriMapUtilities";
import styles from "./ExpandedSection.module.scss";
import { useAppSelector } from "../../../store/hooks";
import { GlMap } from "shared/src/components/GlMap/GlMap";

interface ExpandedSectionProps {
  displayMap: boolean;
  document: DocumentVm;
  error: string | undefined;
  location: LocationMarker | ClassicLocationMarker | undefined;
  viewport: EsriViewport | undefined;
}

export const ExpandedSection: FC<ExpandedSectionProps> = ({
  displayMap,
  document,
  error,
  location,
  viewport,
}) => {
  const isEsriActive = useAppSelector(
    (state) => state.appConfigs.arcGisConfig?.active
  );
  const sectionId = useId();

  // Scroll to this section so it's visible when it's first opened. This didn't
  // work when called in the click handler that expands the section, because the
  // element with the ID wasn't rendered yet. So, do it here when first
  // rendered.
  useEffect(() => {
    scrollToElement(sectionId, false);
  }, []);

  return (
    <div
      className={
        displayMap ? styles.listItemDetails : styles.listItemDetailsNoMap
      }
      id={sectionId}
    >
      <div className={styles.document}>
        <div className={styles.documentDetails}>
          <span
            data-testid={`${
              Components.RecentDocuments
            }-${`${ElementType.Text}`}-location-${document.id}`}
            className={styles.documentDetailsLocation}
          >
            {location?.locationName || error || "No map data to display"}
          </span>
          <span
            data-testid={`${
              Components.RecentDocuments
            }-${`${ElementType.Text}`}-name-${document.id}`}
          >
            Document Type: {document.formSummary.type.name}
          </span>
        </div>
        <a
          className={styles.viewDocumentButton}
          href={`/document/${document.id}`}
          data-testid={`${Components.RecentDocuments}-viewDocumentButton-${document.id}`}
        >
          VIEW DOCUMENT <Icon type="chevron-right" size="28px" />
        </a>
      </div>
      {displayMap && (
        <>
          {!!isEsriActive && (
            <ArcGisMap
              qa={`${Components.RecentDocuments}-map-${document.id}`}
              className={styles.map}
              disableNavigation={true}
              markers={[location as LocationMarker]}
              userPositionViewport={viewport!}
            />
          )}
          {!isEsriActive && (
            <GlMap
              qa={`${Components.RecentDocuments}-map-${document.id}`}
              disableControls
              hideControls
              markers={[location! as ClassicLocationMarker]}
              initialViewport={{
                width: "100%",
                height: "100%",
                zoom: 14,
                center: viewport!.center,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
