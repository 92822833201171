import { API } from "@rtslabs/field1st-fe-common";
import React, { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import { Button } from "shared/src/components/Button/Button";
import { getMinMaxSubmissionDatesFromTimeFilter } from "shared/src/data/timeFilters";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import { userWriteRoles } from "../../../routes/constants/permissionSets";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import * as CAStyles from "shared/src/components/clientAdmin/styles";
import { PageContent } from "shared/src/components/clientAdmin/styles";
import { mapStatus } from "./helpers";
import * as S from "./styles";
import { getParticipantType } from "./usersConstants";
import { FilterChangeProps, UsersFilters } from "./UsersFilters";
import { UsersStats } from "./UsersStats";
import { UsersTable } from "./UsersTable";

export const Users: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(0);

  // Get default filters from url
  const groupString = searchParams.get("group");
  const group = groupString ? Number(groupString) : undefined;

  // Filters
  const [participantQueryParams, setParticipantQueryParams] =
    useState<API.ParticipantQueryParams>({
      clientGroupId: group ? [group] : undefined,
    });
  const [status, setStatus] = useState<number>();

  const handleFilterChange = ({
    dateRange,
    group,
    role,
    status: filteredStatus,
    searchQuery,
    timeFilter,
  }: FilterChangeProps) => {
    const { maxSubmissionDate: maxLastLogin, minSubmissionDate: minLastLogin } =
      getMinMaxSubmissionDatesFromTimeFilter(timeFilter, dateRange);
    const mappedStatus = mapStatus(filteredStatus);
    const participantType = role ? [getParticipantType(role)] : undefined;

    setStatus(filteredStatus);
    setPage(0);
    setParticipantQueryParams({
      clientGroupId: group ? [group] : undefined,
      maxLastLogin,
      minLastLogin,
      participantType,
      searchText: searchQuery,
      status: mappedStatus ? [mappedStatus] : undefined,
    });
  };

  return (
    <PageContent>
      <div id="mainContent" className="col-12">
        <Breadcrumbs
          paths={[
            { pathName: "People" },
            { pathName: "Users", href: "/people/users" },
          ]}
        />
      </div>

      <CAStyles.TableWrapper>
        <S.PageHeader>
          <S.PageTitle data-testid={`${Page.Desktop}-${Components.Users}`}>
            Users
          </S.PageTitle>
          <WriteContent roles={userWriteRoles}>
            <Button
              data-testid={`${Components.Users}-${ElementType.Button}-add-user`}
              onClick={() => navigate("/people/users/add-user")}
            >
              Add user
            </Button>
          </WriteContent>
        </S.PageHeader>

        {/* Filters */}
        <UsersFilters
          groupParams={Number(group)}
          onFilterChange={handleFilterChange}
        />

        {/* Users Stats */}
        <UsersStats
          participantQueryParams={participantQueryParams}
          status={status}
        />

        {/* Users */}
        <UsersTable
          participantQueryParams={participantQueryParams}
          page={page}
          setPage={setPage}
        />
      </CAStyles.TableWrapper>
    </PageContent>
  );
};
