export enum HistorySortOption {
  PublishedDate = "createdDate",
  VersionName = "version",
}

export interface HistorySortSelectOption {
  label: string;
  value: HistorySortOption;
}

export function getHistorySortLabel(sortOption: HistorySortOption): string {
  switch (sortOption) {
    case HistorySortOption.PublishedDate:
      return "Published date";
    case HistorySortOption.VersionName:
      return "Version name";
  }
}
