import {
  DocumentVm,
  MapWidgetQuestionDTO,
  SectionItem,
} from "@rtslabs/field1st-fe-common";
import React from "react";
import { getFlattenedQuestions } from "../DocumentForm/formEntity.helpers";
import { Viewport } from "../../GlMap/types";
import { RehuddleInfo } from "../RehuddleInfo";
import { ReadOnlyMap } from "./ReadOnlyMap";
import { ReadOnlyOEs } from "./ReadOnlyOEs";
import { ReadOnlyDefenses } from "./ReadOnlyDefenses";
import { ReadOnlySignatures } from "./ReadOnlySignatures";
import styles from "./ReadOnly.module.scss";
import { SuccessToast } from "../../Toast/SuccessToast";
import {
  calculateZoom,
  getDistanceBetweenGeoLocations,
  getMidPoint,
} from "../../../util/geolocation";
import { ClassicLocationMarker } from "../DocumentForm/LocationField/geo";
import { ReadOnlySections } from "./ReadOnlySections";
import { ReadOnlyDocumentItem } from "./ReadOnlyDocumentItems";
import { Formik } from "formik";

interface Props {
  document: DocumentVm;
  appPath?: string;
  altToastTitle?: string;
}

export function ReadOnlyDoc({ document, appPath, altToastTitle }: Props) {
  const [viewport, setViewport] = React.useState<Viewport | undefined>();
  const { form, responses } = document;
  const sections = form.sections || [];

  const questions = getFlattenedQuestions(sections || []);
  const oes = document.operationalExperiences;

  /** Map of question ids to map widget questions associated with them */
  const mapWidgetQuestions =
    sections &&
    sections.reduce((result: Map<number, MapWidgetQuestionDTO>, s) => {
      for (const i of s.items!) {
        if (i.type === "WIDGET" && i.subType === "MAP" && i.questions?.length) {
          for (const q of i.questions) {
            result.set(q.questionRootId!, q as MapWidgetQuestionDTO);
          }
        }
      }
      return result;
    }, new Map());

  const responseIds = document.responses?.map((r) => r.associatedId) || [];

  /** Markers for location responses present in the document */
  const markers = questions.reduce((result: ClassicLocationMarker[], q) => {
    if (q.subType === "LOCATION" && document.responses) {
      const qResponse = document.responses.find((r) => r.questionId === q.id);
      if (qResponse?.associatedLocation) {
        result.push({
          color: mapWidgetQuestions?.get(q.id)?.iconColor || undefined,
          geolocation: {
            latitude: qResponse?.associatedLocation?.latitude,
            longitude: qResponse?.associatedLocation?.longitude,
          },
        });
      }
    }
    return result;
  }, []);

  /** Should the map be rendered */
  const renderMap = markers.length > 0;

  /* get map viewport if form has location responses */
  if (renderMap && !viewport) {
    const responseGeolocations = markers.map((r) => ({
      latitude: r.geolocation?.latitude || 0,
      longitude: r.geolocation?.longitude || 0,
    }));
    const midpoint = getMidPoint(responseGeolocations);
    const distance = getDistanceBetweenGeoLocations(responseGeolocations);
    const zoom = calculateZoom(distance);
    if (midpoint.latitude && midpoint.longitude && zoom) {
      setViewport(() => {
        return {
          width: "100%",
          height: "100%",
          center: {
            latitude: midpoint.latitude || 0, // TODO this is nonsensical but I had to do it to stop TS from complaining -JA
            longitude: midpoint.longitude || 0,
          },
          zoom,
        };
      });
    }
  }

  return (
    <Formik initialValues={document} onSubmit={() => {}}>
      {() => (
        <div
          className={styles.readOnly}
          data-testid={`readOnlyDoc-${document.id}`}
        >
          {document.isRehuddle && (
            <RehuddleInfo
              id={document.id}
              parentId={document.parentId}
              className={styles.rehuddleInfo}
              dateCreated={document.dateCreated}
            />
          )}

          <SuccessToast visible>
            {`${
              altToastTitle || `${document.title} by ${document.owner.name}`
            } - Read-Only`}
          </SuccessToast>

          {renderMap && viewport && (
            <ReadOnlyMap viewport={viewport} mapMarkers={markers} />
          )}

          <ReadOnlySections sections={sections}>
            {(items?: SectionItem[]) =>
              items && (
                <ReadOnlyDocumentItem
                  items={items}
                  responses={responses}
                  appPath={appPath}
                />
              )
            }
          </ReadOnlySections>

          {oes && <ReadOnlyOEs oes={oes} />}

          <ReadOnlyDefenses
            documentId={document.id}
            responseIds={responseIds}
            document={document}
          />

          <ReadOnlySignatures participants={document.participants || []} />
        </div>
      )}
    </Formik>
  );
}
