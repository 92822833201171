import { useEffect } from "react";
import { get } from "lodash";
import { useFormikContext } from "formik";

import { API, useDebouncedValue } from "@rtslabs/field1st-fe-common";
import usePrevious from "../../../../util/hooks/usePrevious";

import { FBForm, FBItem, FBSection } from "../../types";
import {
  getPathIndices,
  marshallItem,
  marshallSection,
  unmarshallForm,
  unmarshallSection,
} from "../helpers";
import { serializeError } from "serialize-error";
import { AUTOSAVE_DEBOUNCE_DELAY } from "../../../../util/debounceDelays";

interface Props {
  values: FBItem | FBSection;
  itemPath: string;
  onError?: (error: string) => void;
}

const AutoSaveItem = ({ onError, itemPath, values }: Props): null => {
  const {
    values: form,
    isSubmitting,
    setSubmitting,
    setFieldValue,
  } = useFormikContext<FBForm>();

  /**
   * PUT an section to the form section endpoint and handle ID changes
   * @param values Section values
   */
  async function saveSection(values: FBSection): Promise<string | undefined> {
    const section = marshallSection(values);

    try {
      const response = await API.addOrUpdateFormSection({
        formId: form.id,
        section: { ...section, workflowType: "DRAFT" },
      });

      const updatedItem = get(unmarshallForm(response), itemPath);
      const [sectionIndex] = getPathIndices(itemPath);

      if (sectionIndex !== undefined && updatedItem.id !== values.id) {
        const updatedSection = unmarshallSection(
          updatedItem,
          sectionIndex,
          response.displayConditions
        );
        setFieldValue(itemPath, updatedSection);
      }
    } catch (e) {
      return JSON.stringify(serializeError(e));
    }
  }

  /**
   * PUT an item to the form item endpoint and handle ID changes
   * @param values Item values
   */
  async function saveItem(values: FBItem): Promise<string | undefined> {
    const item = marshallItem(values);

    if ("workflowType" in item) {
      item.workflowType = "DRAFT";
    }

    try {
      const response = await API.addOrUpdateFormItem({
        formId: form.id,
        sectionId: values.parentSectionId,
        item,
      });

      const updatedItem: FBItem | undefined = get(
        unmarshallForm(response),
        itemPath
      );
      if (!updatedItem) {
        console.error("Unable to find item in response ", { values, response });
        return;
      }

      const [sectionIndex, itemIndex] = getPathIndices(itemPath);

      // if the item id has changed (reverted from FINAL), update the item and reset the current item
      if (updatedItem.id !== values.id) {
        setFieldValue(itemPath, updatedItem);
      }
      // item the item's parent section id has changed, update the section id
      if (
        sectionIndex !== undefined &&
        values.parentSectionId !== updatedItem.parentSectionId
      ) {
        setFieldValue(
          `sections[${sectionIndex}].id`,
          updatedItem.parentSectionId
        );
        // update the remaining section items with the new section id
        response.sections![sectionIndex].items!.forEach((item, index) => {
          if (index !== itemIndex) {
            setFieldValue(
              `sections[${sectionIndex}].items[${index}].parentSectionId`,
              updatedItem.parentSectionId
            );
            setFieldValue(
              `sections[${sectionIndex}].items[${index}].parentSectionRootId`,
              updatedItem.parentSectionRootId
            );
          }
        });
      }
    } catch (e) {
      console.error("Unexpected error while saving an item: ", e);
      return "An unexpected error occurred. Please refresh.";
    }
  }

  /**
   * AutoSave the item + handle errors
   * @param values Item or Section to be saved
   */
  async function autoSave(values: FBItem | FBSection) {
    setSubmitting(true);
    let error: string | undefined;

    switch (values.type) {
      case "SECTION":
        error = await saveSection(values);
        break;
      case "QUESTION":
      case "CONTENT":
      case "WIDGET":
        error = await saveItem(values);
        break;
      default:
        throw "item type is not handled in auto save";
    }

    if (error) {
      onError?.(error);
    }
    setSubmitting(false);
  }

  const debouncedValues = useDebouncedValue(values, AUTOSAVE_DEBOUNCE_DELAY);
  const previousValues = usePrevious(debouncedValues);

  useEffect(() => {
    // don't attempt submit if we're already submitting or on initial load
    // don't submit if the item id has changed // @TODO instead, can we check if the ONLY change is the item id
    if (
      debouncedValues &&
      form.workflowType !== "FINAL" &&
      form.workflowType !== "DEACTIVATED" &&
      !isSubmitting &&
      previousValues?.id === debouncedValues.id
    ) {
      autoSave(debouncedValues);
    }
  }, [debouncedValues]);

  return null;
};

export default AutoSaveItem;
