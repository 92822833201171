import React from "react";
import styles from "../Checkbox.module.scss";
interface Error {
  error?: string;
}

export const CheckBoxTwoToneBlank = ({ error }: Error) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      className={styles.checkmark}
    >
      <path
        className={error ? styles.errorBackground : styles.background}
        d="M 5,19 H 19 V 5 H 5 Z"
      />
      <path
        className={error ? styles.errorFill : styles.fill}
        d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
      />
    </svg>
  );
};
