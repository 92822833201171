import React, { FC } from "react";
import { Icon } from "shared/src/components/Icon/Icon";
import scssVariables from "shared/src/sass/jsExports.module.scss";

interface Props {
  checked?: boolean;
}

export const PermissionCell: FC<Props> = ({ checked }) => {
  const htmlTag = document.documentElement;
  const theme = htmlTag.getAttribute("data-theme");
  const isAdmin = theme === "admin";

  return (
    <td>
      {!!checked && (
        <>
          <Icon
            color={isAdmin ? scssVariables.primaryAdmin : scssVariables.primary}
            type="checkmark"
            size={24}
          />
          <span className="sr-only">Checked</span>
        </>
      )}
    </td>
  );
};
