import { LatLng, UnitOfLength } from "@rtslabs/field1st-fe-common";
import { loadModules } from "esri-loader";

interface QueryFeatureLayer {
  coordinates: LatLng;
  featureLayer: __esri.FeatureLayer;
  outFields: string[];
  distance: number;
  units: UnitOfLength;
}

export const queryFeatureLayer = async ({
  coordinates,
  featureLayer,
  outFields,
  distance,
  units,
}: QueryFeatureLayer): Promise<__esri.FeatureSet> => {
  const [Point] = await loadModules<[__esri.PointConstructor]>([
    "esri/geometry/Point",
  ]);

  let featureQuery = featureLayer.createQuery();
  featureQuery.geometry = new Point({
    latitude: coordinates.latitude,
    longitude: coordinates.longitude,
  });
  featureQuery.distance = distance;
  featureQuery.units = convertToEsriUnit(units);
  featureQuery.outFields = outFields;
  featureQuery.spatialRelationship = "intersects";
  featureQuery.maxRecordCountFactor = 5; // allows up to 10,000 features

  return await featureLayer.queryFeatures(featureQuery);
};

function convertToEsriUnit(unit: UnitOfLength): __esri.Query["units"] {
  switch (unit) {
    case UnitOfLength.FEET:
      return "feet";
    case UnitOfLength.MILES:
      return "miles";
    case UnitOfLength.METERS:
      return "meters";
    case UnitOfLength.KILOMETETRS:
      return "kilometers";
  }
}
