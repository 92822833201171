import React, { Dispatch, FC, SetStateAction } from "react";
import styles from "./DocumentSection.module.scss";
import { Icon } from "../../Icon/Icon";
import { useSectionalContext } from "./hooks/useSectionalContext";
import { QAProps } from "../../../qa-slugs";

interface DocumentTitleProps extends QAProps {
  setShowSidebarDrawer: Dispatch<SetStateAction<boolean>>;
}

export const DocumentTitle: FC<DocumentTitleProps> = ({
  setShowSidebarDrawer,
  qa = "DocSec-title",
}) => {
  const { documentTitle } = useSectionalContext();

  return (
    <h2 className={styles.documentTitle}>
      <span data-testid={qa}>{documentTitle}</span>
      <span
        className={styles.moreVert}
        onClick={() => setShowSidebarDrawer(true)}
      >
        <Icon type="more_vert" />
      </span>
    </h2>
  );
};
