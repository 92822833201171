import configureStore from "./configureStore";
import { BaseRootState } from "shared/src/redux/reduxTypes";
import { ArcGisState } from "./arcGis/arcGisReducers";

export const { persistor, store } = configureStore();

export interface AppState extends BaseRootState {
  arcGis: ArcGisState;
}
export type AppDispatch = typeof store.dispatch;
