import React, { useEffect, useState } from "react";
import { css } from "aphrodite/no-important";
import { Field, useFormikContext } from "formik";
import { get } from "lodash";

import { AreaToReview, FBForm, FBQuestion } from "../../../../types";
import Button, { TextButton } from "../../../../../common/Button";
import DrawerHeader from "../../../../../common/Drawer/components/Header";
import Label from "../../../../../Label/Label";
import { TextInput } from "../../../../../TextInput/TextInput";
import Text from "../../../../../common/Text";

import Drawer from "./Drawer";
import ps from "../styles.module.scss";
import drawerStyles from "./styles";
import MultiInput from "../../../../../MultiInput/MultiInput";

const DeleteAreaButton = ({ onDelete }: { onDelete: () => void }) => {
  const ds = drawerStyles();

  return (
    <div className={css(ds.deleteAreaButton)}>
      <TextButton variant="cancel" onClick={onDelete}>
        Delete Area
      </TextButton>
    </div>
  );
};

interface Props {
  closeDrawer: () => void;
  show: boolean;
  onAddArea: (area: AreaToReview) => void;
  onUpdateArea: (area: AreaToReview) => void;
  initialValues?: AreaToReview;
  onDeleteArea: (areaId: number) => void;
  areaName: string;
}

const AreasToReviewDrawer = ({
  onAddArea,
  closeDrawer,
  show,
  areaName,
  initialValues,
  onUpdateArea,
  onDeleteArea,
}: Props) => {
  const [addedSubcats, setAddedSubcats] = useState<FBQuestion[]>([]);
  const [areaLabel, setAreaLabel] = useState<string>("");
  const [submitErrors, setSubmitErrors] = useState<boolean>(false);

  const { touched, setFieldTouched } = useFormikContext<FBForm>();

  function clearValues() {
    setAddedSubcats([]);
    setAreaLabel("");
  }

  function handleAddSubcat(subcat: string) {
    // subcat is evaluated as a string in MultiInput
    const newSubcat = { title: subcat } as FBQuestion;
    // new addedSubcats are updated on onAddArea/onUpdateArea
    setAddedSubcats([...addedSubcats, newSubcat]);
  }

  function handleRemoveSubcat(subcat: FBQuestion) {
    const filteredSubcats = addedSubcats.filter(
      (sc) => sc.title !== subcat.title
    );
    setAddedSubcats(filteredSubcats);
  }

  useEffect(() => {
    if (areaLabel && addedSubcats.length) {
      setSubmitErrors(false);
    }
  }, [areaLabel, addedSubcats]);

  async function addArea() {
    // clear previous submit error
    setSubmitErrors(false);

    // trigger validation
    setFieldTouched(`areaToReview.label_${areaName}`);
    setFieldTouched(`areaToReview.subcategories_${areaName}`);

    if (!areaLabel || !addedSubcats.length) {
      // local validation to prevent drawer submission
      setSubmitErrors(true);
      return;
    }

    // clear temp ids from subcategory items
    const items = addedSubcats.map((cat) => {
      if (typeof cat.id === "string") {
        return { ...cat, id: null };
      }
      return cat;
    });

    if (initialValues?.id) {
      onUpdateArea({ ...initialValues, label: areaLabel, items });
    } else {
      onAddArea({ label: areaLabel, items, id: null });
    }
    clearValues();
    closeDrawer();

    // un-touch the fields to clear validation errors
    setFieldTouched(`areaToReview.label_${areaName}`, false);
    setFieldTouched(`areaToReview.subcategories_${areaName}`, false);
  }

  function deleteArea() {
    if (initialValues?.id) {
      onDeleteArea(initialValues.id);
      clearValues();
      closeDrawer();
    }
  }

  useEffect(() => {
    if (initialValues) {
      setAddedSubcats(initialValues.items || []);
      setAreaLabel(initialValues.label || "");
    } else {
      clearValues();
    }
  }, [initialValues]);

  return (
    <Drawer
      anchor="right"
      content={
        <Drawer.Content>
          <DrawerHeader
            closeDrawer={closeDrawer}
            text="Add Area &amp; Subcategories"
          />
          <Drawer.Section>
            <Drawer.SectionHeader>Add an Area to Review</Drawer.SectionHeader>
            <Drawer.Description>
              Areas to review are displayed as a list of multi-select checkboxes
              that will guide the user on what to review. When an area is
              selected, the related subcategories will be displayed.
            </Drawer.Description>

            <Field
              as={TextInput}
              className={ps.textInput}
              name={`areaToReview.label_${areaName}`}
              label="Area To Review Label"
              placeholder="Area To Review Label"
              onChange={(e: any) => setAreaLabel(e.target.value)}
              value={areaLabel}
              error={!areaLabel ? "Area label is required" : ""}
              touched={get(touched, `areaToReview.label_${areaName}`)}
            />

            <Label
              className={ps.label}
              htmlFor={`areaToReview.subcategories_${areaName}`}
            >
              Add Subcategories
            </Label>
            <Drawer.Description>
              Each area to review is required to have at least one subcategory.{" "}
              These subcategories are the specific topics related to the area
              that will be rated.
              <br />
              <i>
                EXAMPLE: A subcategory for Job Hazard Analysis is Hazard
                Mitigation
              </i>
            </Drawer.Description>
            <Field
              as={MultiInput}
              canUseCustomValues={true}
              className={ps.multiInput}
              name={`areaToReview.subcategories_${areaName}`}
              label=""
              assistiveText=""
              placeholder={`Add a${
                addedSubcats.length > 1 ? "nother" : ""
              } subcategory`}
              labelField="label"
              idField="id"
              selectedValues={addedSubcats.map((cat) => {
                return {
                  label: cat.title,
                  ...cat,
                };
              })}
              onAddItem={handleAddSubcat}
              onRemoveItem={handleRemoveSubcat}
              error={
                !addedSubcats.length
                  ? "At least one subcategory is required"
                  : ""
              }
              touched={get(touched, `areaToReview.subcategories_${areaName}`)}
            />
          </Drawer.Section>
          <Drawer.ButtonContainer>
            {initialValues?.id && <DeleteAreaButton onDelete={deleteArea} />}
            <Button onClick={addArea}>
              {initialValues ? "Update" : "Add"} Area
            </Button>
          </Drawer.ButtonContainer>
          {submitErrors && (
            <Text variant="error">Please fix errors before continuing</Text>
          )}
        </Drawer.Content>
      }
      id="areasToReviewDrawer"
      onClose={closeDrawer}
      onOpen={() => {}}
      open={show}
      showCloseIcon={false}
      variant="persistent"
    />
  );
};

export default AreasToReviewDrawer;
