import {
  EsriContentWidgetSaveVm,
  UnitOfLength,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { useField, useFormikContext } from "formik";
import { get } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { Select, SelectOption } from "../../../../../../Select/Select";
import { ElementType, WidgetType } from "../../../../../../../qa-slugs";
import { TextInput } from "../../../../../../TextInput/TextInput";
import {
  FeatureLayer,
  getLayers,
} from "../../../../../../../api/esri/getFeatureLayers";
import { FeatureLayerField } from "../../../../../../../api/esri/types";
import { updateEsriContentWidget } from "../../../../../../../api/formBuilder/updateEsriContentWidget";
import { TextButton } from "../../../../../../common/Button";
import { PillBox } from "../../../../../../common/Pill/PillBox";
import { unmarshallForm } from "../../../../helpers";
import { FBForm } from "../../../../../types";
import styles from "../../styles.module.scss";
import esriStyles from "./EsriContentProperties.module.scss";

interface EsriContentPropertiesProps {
  itemPath: string;
}

export const EsriContentProperties: FC<EsriContentPropertiesProps> = ({
  itemPath,
}) => {
  const { values, setFieldValue, setValues } = useFormikContext<FBForm>();
  const widget: EsriContentWidgetSaveVm = get(values, itemPath);
  const [layerOptions, setLayerOptions] = useState<SelectOption<string>[]>([]);
  const [fieldInfos, setFieldInfos] = useState<__esri.FieldInfo[]>([]);
  const [filteredEsriFieldInfos, setFilteredEsriFieldInfos] = useState<
    __esri.FieldInfo[]
  >([]);
  const [selectedEsriFields, setSelectedEsriFields] = useState<
    FeatureLayerField[]
  >([]);
  const [layers, setLayers] = useState<FeatureLayer[]>([]);
  const [selectedLayer, setSelectedLayer] = useState<string>("");
  const [isEsriLoadError, setIsEsriLoadError] = useState<boolean>(false);
  const [selectedUnits, setSelectedUnits] = useState<UnitOfLength>(
    widget.unitOfMeasure
  );
  const [selectedDistance, setSelectedDistance] = useState<number | string>(
    widget.maxDistance
  );

  const loadEsriData = async () => {
    try {
      /**
       * Need to figure out where to store/update this WebMap portalItemId.
       * Probably in appConfigs, and also need UI to set it.
       */
      setIsEsriLoadError(false);
      const loadedLayers = await getLayers("03b5af27b3734442b3823f7a0b4228cb");
      initializeState(loadedLayers);
    } catch (error) {
      console.error("Failed to get layers.", error);
      setIsEsriLoadError(true);
    }
  };

  useAsyncEffect(loadEsriData, []);

  const initializeState = (loadedLayers: FeatureLayer[]) => {
    setLayers(loadedLayers);
    setLayerOptions(
      loadedLayers.map((layer) => ({
        label: layer.label,
        value: layer.id,
      }))
    );
    if (widget.layerId > -1 && widget.portalItemId) {
      const initialLayer = loadedLayers.find(
        (l) =>
          l.portalItemId === widget.portalItemId && l.layerId === widget.layerId
      );
      if (!initialLayer) {
        throw "The loaded layers doesn't contain the previously saved layer";
      }
      setSelectedLayer(initialLayer!.id);
      const initialFieldNames = initialLayer.fields.map((f) => f.name);
      const initialFieldInfos = initialLayer.fieldInfos
        .filter(
          (fi) => fi.visible && initialFieldNames.indexOf(fi.fieldName) !== -1
        )
        .sort((a, b) => a.fieldName.localeCompare(b.fieldName));
      const initialEsriFields: FeatureLayerField[] | undefined =
        widget.questions?.map((q) => ({
          key: q.fieldName,
          label:
            initialFieldInfos.find((info) => info.fieldName === q.fieldName)
              ?.label || q.fieldName,
        }));
      setFieldInfos(initialFieldInfos);
      initialEsriFields && setSelectedEsriFields(initialEsriFields);
    }
  };

  useEffect(() => {
    setFilteredEsriFieldInfos(fieldInfos);
  }, [fieldInfos]);

  const [maxDistanceField] = useField(`${itemPath}.maxDistance`);
  const [layerField] = useField(`${itemPath}.layerId`);
  const [unitField] = useField(`${itemPath}.unitOfMeasure`);

  const handleLayerChange = async (event: SelectOption<string> | null) => {
    await handlePillBoxChange([]);
    setSelectedLayer(event?.value || "");
    const foundLayer = layers.find((layer) => layer.id === event?.value);
    if (foundLayer) {
      const fieldNames = foundLayer.fields.map((f) => f.name);
      setFieldInfos(foundLayer.fieldInfos);
      setFilteredEsriFieldInfos(
        foundLayer.fieldInfos
          .filter((fi) => fi.visible && fieldNames.indexOf(fi.fieldName) !== -1)
          .sort((a, b) => a.fieldName.localeCompare(b.fieldName))
      );
      setFieldValue(`${itemPath}.layerId`, foundLayer.layerId);
      setFieldValue(`${itemPath}.portalItemId`, foundLayer.portalItemId);
    }
  };

  const handlePillBoxSearch = (query: string) => {
    const lowercaseQuery = query.toLowerCase();
    setFilteredEsriFieldInfos(
      fieldInfos.filter((fi) =>
        fi.label
          ? fi.label?.toLowerCase().indexOf(lowercaseQuery) !== -1
          : fi.fieldName?.toLowerCase().indexOf(lowercaseQuery) !== -1
      )
    );
  };

  const handlePillBoxChange = async (newValues: FeatureLayerField[]) => {
    setSelectedEsriFields(newValues);
    const formVm = await updateEsriContentWidget(values, widget, newValues);
    formVm && setValues(unmarshallForm(formVm));
  };

  const handleUnitsChange = (newUnits: SelectOption<UnitOfLength> | null) => {
    setSelectedUnits(newUnits!.value);
    setFieldValue(`${itemPath}.unitOfMeasure`, newUnits!.value);
  };

  const handleDistanceChange = (newDistance: string) => {
    const value = Number(newDistance);
    setSelectedDistance(value > 0 ? value : "");
    setFieldValue(`${itemPath}.maxDistance`, value > 0 ? value : 0);
  };

  if (isEsriLoadError) {
    return (
      <>
        <p data-testid={`${WidgetType.EsriContent}-${ElementType.Text}`}>
          Error loading Esri data
        </p>
        <TextButton
          className={styles.textButton}
          onClick={loadEsriData}
          qa={`${WidgetType.EsriContent}-${ElementType.Button}`}
        >
          Try again
        </TextButton>
      </>
    );
  }

  const memoizedEsriFieldInfos = useMemo(
    () =>
      filteredEsriFieldInfos.map((field) => {
        const fieldData: FeatureLayerField = {
          key: field.fieldName,
          label: field.label || field.fieldName,
        };
        return fieldData;
      }),
    [fieldInfos, filteredEsriFieldInfos]
  );

  return (
    <>
      <TextInput
        {...maxDistanceField}
        label="Find Nearby"
        labelClass={esriStyles.label}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleDistanceChange(e.target.value)
        }
        placeholder="Max Distance"
        type="number"
        value={selectedDistance}
        qa={`${WidgetType.EsriContent}-${ElementType.TextInput}-maxDistance`}
      />
      <Select
        {...unitField}
        label="Units"
        labelClassName={esriStyles.label}
        onChange={handleUnitsChange}
        value={selectedUnits}
        options={unitOptions}
        qa={`${WidgetType.EsriContent}-${ElementType.SelectInput}-units`}
      />
      <Select
        {...layerField}
        label="Layer"
        labelClassName={esriStyles.label}
        onChange={handleLayerChange}
        value={selectedLayer}
        options={layerOptions}
        qa={`${WidgetType.EsriContent}-${ElementType.SelectInput}-layer`}
      />
      {selectedLayer && (
        <PillBox<FeatureLayerField>
          label="Field"
          labelClass={esriStyles.label}
          suggestions={memoizedEsriFieldInfos}
          values={selectedEsriFields}
          labelField="label"
          suggestionsLabel={"Field"}
          onChange={handlePillBoxChange}
          onSearch={handlePillBoxSearch}
          qa={`${WidgetType.EsriContent}-${ElementType.PillBox}`}
        />
      )}
    </>
  );
};

const unitOptions = [
  { label: "Feet", value: UnitOfLength.FEET },
  { label: "Miles", value: UnitOfLength.MILES },
  { label: "Kilometers", value: UnitOfLength.KILOMETETRS },
  { label: "Meters", value: UnitOfLength.METERS },
];
