import {
  API,
  CreateDocumentVM,
  DeviceEnum,
  DocumentQuestionResponseVm,
  DocumentSubmissionType,
  DocumentUpdateVm,
  DocumentVm,
  Functions,
  ParticipantUserVm,
  PrefillAlternate,
} from "@rtslabs/field1st-fe-common";
import isEmpty from "lodash/isEmpty";

export async function createNewDocument({
  formId,
  owner,
  workOrderId,
  prefillAlternates,
}: {
  formId: number;
  owner: ParticipantUserVm;
  workOrderId?: number;
  prefillAlternates?: PrefillAlternate[];
}): Promise<DocumentVm> {
  // create the initial document
  const requestBody: CreateDocumentVM = {
    formId,
    ownerId: owner.participantId,
    clientGroupId: owner.primaryGroupId,
    workOrderId,
    isRehuddle: false,
    submissionDate: new Date().toISOString(),
    createdOffline: false,
    documentDevice: DeviceEnum.WEB,
  };
  const document = await API.createDocument({
    document: requestBody,
  });

  const { displayConditions, sections } = document.form;

  let responses: DocumentQuestionResponseVm[] = [...document.responses];

  // handle questions with default responses
  responses = Functions.generateQuestionDefaultResponses({
    owner,
    sections,
    displayConditions: displayConditions!,
    existingResponses: responses,
    prefillAlternates,
  });

  // handle WORK ORDER PREFILL conditions
  // get work order associated to this document
  if (workOrderId && !isEmpty(displayConditions)) {
    try {
      const workOrder = await API.getWorkOrderById({ id: workOrderId });
      responses = Functions.prefillFromWorkOrder({
        workOrder,
        sections,
        displayConditions: displayConditions!,
        existingResponses: responses,
        prefillAlternates,
      });
    } catch (ex) {
      console.error(
        `Failed to fetch work order of id ${workOrderId}. Skipping prefill.`
      );
    }
  }

  // if nothing was generated, just return document instead of resubmitting
  if (isEmpty(responses)) {
    return document;
  }

  const participants = Functions.generateParticipants([], responses, sections);

  const documentUpdate: DocumentUpdateVm = {
    ...document,
    participants,
    responses,
    submissionDate: new Date().toISOString(),
    submissionType: DocumentSubmissionType.NEW,
  };

  return await API.updateDocument({ document: documentUpdate });
}
