import {
  API,
  ApplicationConfig,
  ApplicationConfigKey,
  ClientConfigProperties,
} from "@rtslabs/field1st-fe-common";

export type UpdateCompanyResponse = Promise<
  ApplicationConfig<ApplicationConfigKey.clientConfig, ClientConfigProperties>
>;

/**
 * updates client config
 * PUT /api/application-configs : Updates an existing applicationConfig
 */
export function updateCompany(
  properties: ClientConfigProperties
): UpdateCompanyResponse {
  return API.updateApplicationConfig({
    config: {
      active: true,
      keyName: "clientConfig",
      properties,
      secured: false,
    },
  }) as UpdateCompanyResponse;
}
