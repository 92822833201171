import React from "react";

import { RelatedDocumentVm } from "@rtslabs/field1st-fe-common";
import { Table } from "shared/src/components/TableUI/Table/Table";
import { TableCell } from "shared/src/components/TableUI/TableCell/TableCell";
import { TableHead } from "shared/src/components/TableUI/TableHead/TableHead";
import { TableRow } from "shared/src/components/TableUI/TableRow/TableRow";
import HistoryDateCell from "shared/src/components/Documents/components/DocumentSidebarDrawer/DocumentHistory/HistoryDateCell";
import Tooltip, {
  lightTooltipClasses,
} from "shared/src/components/Tooltip/Tooltip";
import styles from "./relatedDocuments.module.scss";
import { ElementType } from "shared/src/qa-slugs";

interface RelatedDocumentsTableProps {
  relatedDocuments: RelatedDocumentVm[];
}

const RelatedDocumentsTable = ({
  relatedDocuments,
}: RelatedDocumentsTableProps) => {
  if (relatedDocuments.length > 0) {
    return (
      <Table>
        <TableHead
          columns={[
            { id: "document", label: "Document", isUnsortable: true },
            { id: "createdBy", label: "Created By", isUnsortable: true },
            { id: "date", label: "Date", isUnsortable: true },
          ]}
        />
        <tbody>
          {relatedDocuments.map((d, index) => {
            const createdBy = d.createdBy || "-";

            return (
              <TableRow key={`row-${index}`}>
                <TableCell>
                  <a
                    href={`/document/${d.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid={`${ElementType.Link}-${d.id}`}
                  >
                    {`${d.isRehuddle ? "Rehuddle" : d.formName ?? "-"}`}
                  </a>
                </TableCell>
                <TableCell>
                  <Tooltip
                    classes={lightTooltipClasses}
                    title={createdBy}
                    placement="top"
                    style={{
                      top: 15,
                    }}
                  >
                    <div className={styles.overflow}>{createdBy}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <HistoryDateCell historyDate={d.createdDate} />
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return <span className={styles.noData}>No data available</span>;
};

export default RelatedDocumentsTable;
