/// <reference types="@types/arcgis-js-api" />

import { LatLng } from "@rtslabs/field1st-fe-common";
import { loadModules, setDefaultOptions } from "esri-loader";
import { addMarkersToLayer } from "../../../data/esri/addMarkersToLayer";
import { addSymbolToFeature } from "../../../data/esri/addSymbolToFeature";

export class ArcGisMapContext {
  markerLayer: __esri.GraphicsLayer;

  constructor(markerLayer: __esri.GraphicsLayer) {
    this.markerLayer = markerLayer;
  }

  updateMarkers(newMarkers: Marker[]) {
    this.markerLayer.removeAll();
    addMarkersToLayer(this.markerLayer, newMarkers);
  }
}

export interface Marker {
  color?: string;
  geolocation: LatLng;
  onUpdate: () => void;
}

interface LoadMapConfig {
  disableNavigation?: boolean;
  mapElement: HTMLDivElement | undefined;
  markers?: Marker[];
  portalId: string;
  viewport?: EsriViewport;
  nearestFeature?: __esri.Graphic;
}

interface LoadMapResult {
  context: ArcGisMapContext;
  mapView: __esri.MapView;
}

interface MapModules {
  BasemapToggle: __esri.BasemapToggleConstructor;
  Fullscreen: __esri.FullscreenConstructor;
  Graphic: __esri.GraphicConstructor;
  GraphicsLayer: __esri.GraphicsLayerConstructor;
  MapView: __esri.MapViewConstructor;
  Point: __esri.PointConstructor;
  Query: __esri.QueryConstructor;
  SimpleFillSymbol: __esri.SimpleFillSymbolConstructor;
  SimpleLineSymbol: __esri.SimpleLineSymbolConstructor;
  SimpleMarkerSymbol: __esri.SimpleMarkerSymbolConstructor;
  WebMap: __esri.WebMapConstructor;
}

export interface EsriViewport {
  center: LatLng;
}

setDefaultOptions({ css: true });

let mapModules: MapModules | undefined = undefined;

export const loadMap = async ({
  disableNavigation,
  mapElement,
  markers,
  portalId,
  viewport,
  nearestFeature,
}: LoadMapConfig): Promise<LoadMapResult> => {
  const { BasemapToggle, Fullscreen, GraphicsLayer, MapView, WebMap } =
    await loadMapModules();

  const webMap = new WebMap({
    portalItem: {
      id: portalId,
    },
  });

  const mapView = new MapView({
    center: viewport
      ? [viewport.center.longitude, viewport.center.latitude]
      : undefined,
    container: mapElement,
    map: webMap,
  });

  setMapViewEventHandlers({
    view: mapView,
    disableNavigation,
  });
  setUpMapViewControls(mapView, BasemapToggle, Fullscreen, disableNavigation);

  await webMap.when();

  const graphicsLayer = new GraphicsLayer();
  addMarkersToLayer(graphicsLayer, markers);

  //TODO: remove map view layers
  if (nearestFeature) {
    addSymbolToFeature(nearestFeature);
    mapView.graphics.add(nearestFeature);
  }
  webMap.add(graphicsLayer);

  const context = new ArcGisMapContext(graphicsLayer);

  return { context, mapView };
};

export const getMapModules = () => {
  if (!mapModules) {
    throw new Error("Failed to get map modules.");
  }
  return mapModules;
};

const loadMapModules = async () => {
  const [
    WebMap,
    MapView,
    Graphic,
    GraphicsLayer,
    SimpleFillSymbol,
    SimpleLineSymbol,
    SimpleMarkerSymbol,
    Point,
    BasemapToggle,
    Fullscreen,
    Query,
  ] = await loadModules<
    [
      __esri.WebMapConstructor,
      __esri.MapViewConstructor,
      __esri.GraphicConstructor,
      __esri.GraphicsLayerConstructor,
      __esri.SimpleFillSymbolConstructor,
      __esri.SimpleLineSymbolConstructor,
      __esri.SimpleMarkerSymbolConstructor,
      __esri.PointConstructor,
      __esri.BasemapToggleConstructor,
      __esri.FullscreenConstructor,
      __esri.QueryConstructor
    ]
  >([
    "esri/WebMap",
    "esri/views/MapView",
    "esri/Graphic",
    "esri/layers/GraphicsLayer",
    "esri/symbols/SimpleFillSymbol",
    "esri/symbols/SimpleLineSymbol",
    "esri/symbols/SimpleMarkerSymbol",
    "esri/geometry/Point",
    "esri/widgets/BasemapToggle",
    "esri/widgets/Fullscreen",
    "esri/rest/support/Query",
  ]);

  mapModules = {
    BasemapToggle,
    Fullscreen,
    Graphic,
    GraphicsLayer,
    MapView,
    Point,
    Query,
    SimpleFillSymbol,
    SimpleLineSymbol,
    SimpleMarkerSymbol,
    WebMap,
  };

  return mapModules;
};

const setUpMapViewControls = (
  view: __esri.MapView,
  BasemapToggle: __esri.BasemapToggleConstructor,
  Fullscreen: __esri.FullscreenConstructor,
  disableNavigation: boolean | undefined
) => {
  if (disableNavigation) {
    view.ui.components = ["attribution"];
  } else {
    const fullscreen = new Fullscreen({
      view: view,
    });

    view.ui.add(fullscreen, "top-right");

    let basemapToggle = new BasemapToggle({
      view: view,
    });

    view.ui.add(basemapToggle, {
      position: "bottom-right",
    });
  }
};

interface SetMapViewEventHandlers {
  view: __esri.MapView;
  disableNavigation?: boolean;
}

const setMapViewEventHandlers = ({
  view,
  disableNavigation,
}: SetMapViewEventHandlers) => {
  if (disableNavigation) {
    view.on("key-down", (event) => {
      const prohibitedKeys = ["+", "-", "Shift", "_", "="];
      const keyPressed = event.key;
      if (prohibitedKeys.indexOf(keyPressed) !== -1) {
        event.stopPropagation();
      }
    });

    view.on("mouse-wheel", (event) => {
      event.stopPropagation();
    });

    view.on("double-click", (event) => {
      event.stopPropagation();
    });

    view.on("double-click", ["Control"], (event) => {
      event.stopPropagation();
    });

    view.on("drag", (event) => {
      event.stopPropagation();
    });

    view.on("drag", ["Shift"], (event) => {
      event.stopPropagation();
    });

    view.on("drag", ["Shift", "Control"], (event) => {
      event.stopPropagation();
    });
  }
};
