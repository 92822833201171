import React from "react";
import { Modal } from "../../../../Modal/Modal";

interface RemoveMapModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  handleAction: () => void;
}

export const RemoveMapModal = ({
  isModalOpen,
  handleClose,
  handleAction,
}: RemoveMapModalProps) => {
  return (
    <Modal
      action={{
        text: `Include Map`,
        callback: handleAction,
        loading: false,
      }}
      alert={{
        variant: "error",
        title: "Only 1 widget can include a map view.",
        message: `Including a map on this widget will remove the map from all other widgets`,
        isVisible: true,
      }}
      handleClose={handleClose}
      open={isModalOpen}
      title="Are you sure you want to continue?"
    />
  );
};
