import {
  ClientGroupDTO,
  DocumentSummaryEntryDTO,
  FormTypeDTO,
} from "@rtslabs/field1st-fe-common";
import { useField, useFormikContext } from "formik";
import fuzzysort from "fuzzysort";
import React, { useEffect, useState } from "react";
import { RadioButton } from "../../../RadioButtons/RadioButton";
import { RadioButtonsGroup } from "../../../RadioButtons/RadioButtonsGroup";
import radioButtonStyles from "../../../RadioButtons/RadioButtonsGroup.module.scss";
import { Select, SelectOption } from "../../../Select/Select";
import { TextInput } from "../../../TextInput/TextInput";
import YesOrNo from "../../../YesOrNo/YesOrNo";
import { joinClassNames } from "../../../../helpers/theme.helpers";
import { Components, ElementType } from "../../../../qa-slugs";
import Label from "../../../Label/Label";
import { PillBox } from "../../../common/Pill/PillBox";
import ValidationBanner from "../create/validation/ValidationBanner";
import { getAllFormItems, isFBQuestion, scrollToElement } from "../helpers";
import bs from "../styles.module.scss";
import { FBForm } from "../../types";
import AlwaysOption from "./fields/AlwaysOption";
import TimeLimitOption from "./fields/TimeLimitOption";
import TimeToOption from "./fields/TimeToOption";
import s from "./styles.module.scss";

/** Search for an item in an array. If it exists, update it. If not, add it to the array
 * @param values - the value array to search
 * @param searchFor - key and value to search for
 * @param updatedValue - the new value
 */
function updateValueArray<T>(
  values: T[],
  searchFor: { key: string; value: any },
  updatedValue: T
): T[] {
  const updatedValues = values ? [...values] : [];
  const valueIndex = updatedValues.findIndex(
    (value: any) => value[searchFor.key] === searchFor.value
  );

  if (valueIndex === -1) {
    updatedValues.push(updatedValue);
  } else {
    updatedValues[valueIndex] = updatedValue;
  }

  return updatedValues;
}

/**
 * Build the title sources options from the current form values
 * @param form current form values
 */
function getQuestionOptions(form: FBForm): SelectOption<number>[] {
  const questionOptions = getAllFormItems(form)
    .filter(isFBQuestion)
    .filter((q) => q.title)
    .map((q) => ({ value: q.rootId!, label: q.title }));
  return [{ value: -1, label: "None" }, ...questionOptions];
}

interface SettingsFormProps {
  formTypes: FormTypeDTO[];
  clientGroups: ClientGroupDTO[];
}

export const SettingsForm = ({
  formTypes,
  clientGroups,
}: SettingsFormProps) => {
  const { values, errors, setFieldValue } = useFormikContext<FBForm>();
  const [formSubmissionType, setFormSubmissionType] = useState<string>(
    values.formSubmissionConstraint?.type || ""
  );
  const [groupSuggestions, setGroupSuggestions] =
    useState<ClientGroupDTO[]>(clientGroups);

  useEffect(() => {
    setFieldValue("formSubmissionConstraint.type", formSubmissionType);
  }, [formSubmissionType, values.formSubmissionConstraint?.hourLimit]);

  const mappedFormTypes = formTypes?.map((type) => ({
    value: type.id,
    label: type.name,
  }));

  const questionOptions = getQuestionOptions(values);

  const titleSourceEntryIndex = values.documentSummaryEntries?.findIndex(
    (entry) => entry.type === "TITLE"
  );
  const locationSourceEntryIndex = values.documentSummaryEntries?.findIndex(
    (entry) => entry.type === "LOCATION"
  );

  // create the formik field props
  const [nameField, nameFieldMeta] = useField({
    name: "name",
    validate: (name) =>
      name
        ? ""
        : "Form title is required. No changes will be saved until a form title has been defined.",
  });
  const [formTypeField] = useField("type");
  const [titleSourceField] = useField(
    `documentSummaryEntries[${titleSourceEntryIndex}]`
  );
  const [locationSourceField] = useField(
    `documentSummaryEntries[${locationSourceEntryIndex}]`
  );
  const [emailSupervisorField] = useField("emailSupervisor");
  const [formSubmissionConstraintTypeField] = useField<string>({
    name: "formSubmissionConstraint.type",
    value: "",
  });
  const [allowedDocumentEditorField] = useField("allowedDocumentEditor");
  const [clientGroupsField] = useField("clientGroups");

  const editPeriodOptions = [
    {
      label: (
        <TimeLimitOption
          error={(errors.formSubmissionConstraint as any)?.hourLimit}
          formSubmissionType={formSubmissionType}
          setFormSubmissionType={setFormSubmissionType}
        />
      ),
      value: "HOUR",
    },
    {
      label: <TimeToOption setFormSubmissionType={setFormSubmissionType} />,
      value: "TIME",
    },
    { label: <AlwaysOption />, value: "" },
  ];

  const handleFormSubmissionContraintTypeChange = (value: string) => {
    setFormSubmissionType(value);
    setFieldValue("formSubmissionConstraint.type", value);
    setFieldValue(
      "formSubmissionConstraint.noSubmissionsUntilNextDay",
      value === "TIME"
    );
  };

  return (
    <>
      {/* Validation Banner */}
      <ValidationBanner
        errorType="settings"
        onJumpToError={(errorKey: any) =>
          scrollToElement(errorKey || `formSubmissionConstraint.${errorKey}`)
        }
      />
      <div className={joinClassNames(s.settingsForm, bs.contentContainer)}>
        {/* Form Title */}
        <TextInput
          {...nameField}
          {...nameFieldMeta}
          label="Form Title"
          id={"name"}
          placeholder="Form title"
          error={errors.name}
        />

        {/* Form Type */}
        <Select
          {...formTypeField}
          label="Form Type"
          placeholder="Select form type"
          options={mappedFormTypes}
          value={values.type.id}
          onChange={(option: SelectOption<number> | null) => {
            const type = formTypes.find((type) => type.id === option?.value);
            setFieldValue("type", type);
          }}
        />

        {/* Submission Title Source */}
        <Select
          {...titleSourceField}
          label="Submission Title Source"
          options={questionOptions}
          placeholder="Choose field to define title"
          value={
            values.documentSummaryEntries?.find(
              (entry) => entry.type === "TITLE"
            )?.sourceQuestionRootId
          }
          onChange={(option: SelectOption<number> | null) => {
            const entries = values.documentSummaryEntries || [];
            let updatedEntries: DocumentSummaryEntryDTO[];

            if (!option?.value || option.value === -1) {
              updatedEntries =
                entries.filter((entry) => entry.type !== "TITLE") || [];
            } else {
              const updatedEntry: DocumentSummaryEntryDTO = {
                name: "title",
                sourceQuestionRootId: option.value,
                type: "TITLE",
              };

              updatedEntries = updateValueArray(
                entries,
                { key: "type", value: "TITLE" },
                updatedEntry
              );
            }

            setFieldValue("documentSummaryEntries", updatedEntries);
          }}
        />

        {/* Submission Location Source */}
        <Select
          {...locationSourceField}
          qa={`${Components.FormSettings}-${ElementType.SelectInput}-locationSource`}
          label="Submission Location Source"
          options={questionOptions}
          placeholder="Choose field to define document location"
          value={
            values.documentSummaryEntries?.find(
              (entry) => entry.type === "LOCATION"
            )?.sourceQuestionRootId
          }
          onChange={(option: SelectOption<number> | null) => {
            const entries = values.documentSummaryEntries || [];
            let updatedEntries: DocumentSummaryEntryDTO[];

            if (!option?.value || option.value === -1) {
              updatedEntries =
                entries.filter((entry) => entry.type !== "LOCATION") || [];
            } else {
              const updatedEntry: DocumentSummaryEntryDTO = {
                name: "location",
                sourceQuestionRootId: option.value,
                type: "LOCATION",
              };

              updatedEntries = updateValueArray(
                entries,
                { key: "type", value: "LOCATION" },
                updatedEntry
              );
            }

            setFieldValue("documentSummaryEntries", updatedEntries);
          }}
        />

        {/* Email supervisor */}
        <YesOrNo
          {...emailSupervisorField}
          label="Email document creator’s supervisor automatically on form submission?"
          value={
            !!values.actions &&
            values.actions.findIndex(
              (action) =>
                action.actionType === "EMAIL_SUPERVISOR" && action.doItByDefault
            ) > -1
          }
          onChange={(data: string) => {
            const doItByDefault = data === "true";
            const actions = values.actions ? [...values.actions] : [];
            const currentAction = actions.find(
              (action) => action.actionType === "EMAIL_SUPERVISOR"
            );

            const updatedAction = {
              actionType: "EMAIL_SUPERVISOR",
              doItByDefault,
              isRequired: currentAction?.isRequired || false,
            };

            const updatedActions = updateValueArray(
              actions,
              { key: "actionType", value: "EMAIL_SUPERVISOR" },
              updatedAction
            );

            setFieldValue("actions", updatedActions);
          }}
        />

        {/* Edit Period */}
        <Label htmlFor={formSubmissionConstraintTypeField.name}>
          Edit Period
        </Label>
        <div
          aria-labelledby={formSubmissionConstraintTypeField.name}
          className={joinClassNames(
            radioButtonStyles.radioGroup,
            radioButtonStyles.column
          )}
          id={formSubmissionConstraintTypeField.name}
          role="radiogroup"
        >
          {editPeriodOptions.map((option) => (
            <RadioButton
              qa={Components.FormSettings}
              checked={
                formSubmissionConstraintTypeField.value?.toLowerCase() ===
                option.value.toLowerCase()
              }
              className={radioButtonStyles.item}
              onChange={handleFormSubmissionContraintTypeChange}
              key={option.value}
              label={option.label}
              name={formSubmissionConstraintTypeField.name}
              data={option.value}
            />
          ))}
        </div>

        {/* Edit Permissions */}
        <RadioButtonsGroup
          {...allowedDocumentEditorField}
          label="Edit Permissions"
          value={values.allowedDocumentEditor ?? "DOCUMENT_CREATOR"}
          options={[
            { label: { text: "Creator Only" }, data: "DOCUMENT_CREATOR" },
            {
              label: { text: "Creator and Supervisors" },
              data: "DOCUMENT_CREATOR_AND_SUPERVISORS",
            },
            {
              label: { text: "All Participants" },
              data: "DOCUMENT_PARTICIPANTS",
            },
          ]}
          onChange={(allowedDocumentEditor: string) => {
            setFieldValue("allowedDocumentEditor", allowedDocumentEditor);
          }}
        />

        {/* Group Access */}
        <PillBox<ClientGroupDTO>
          {...clientGroupsField}
          label="Group Access"
          labelField="name"
          suggestions={groupSuggestions}
          suggestionsLabel="Recommended Groups"
          placeholder="Add group"
          onChange={(newValues) => setFieldValue("clientGroups", newValues)}
          onSearch={(search: string) => {
            const newSuggestions = fuzzysort.go(search, clientGroups, {
              keys: ["name"],
            });
            const filteredGroups = clientGroups.filter((cg) =>
              newSuggestions.find((ns) => ns.obj.name === cg.name)
            );
            setGroupSuggestions(filteredGroups);
          }}
          values={values.clientGroups || []}
          error={errors.clientGroups}
        />
      </div>
    </>
  );
};
