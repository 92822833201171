import {
  API,
  APIState,
  PageOfDocumentOwnerVms,
  useAPI,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import { useState } from "react";

export interface DocumentOwnersAPIArgs {
  queryText: string;
  selected: boolean;
}

/**
 * Hook used to handle a query string for document owners and be able to set it without reloading
 */
export function useDocumentOwnersAPI({
  queryText,
  selected,
}: DocumentOwnersAPIArgs): APIState<PageOfDocumentOwnerVms> {
  const [rawQuery, setRawQuery] = useState<string>(queryText);
  const query = useDebouncedValue(rawQuery, 100);
  const ownersCall = useAPI(API.getDocumentOwners, { query });

  if (!selected && queryText != rawQuery) {
    setRawQuery(queryText);
  }

  return ownersCall;
}
