import React, { useMemo } from "react";
import moment from "moment";
import styled from "styled-components";

import { prettifyDocumentStatus } from "../../../Documents/helpers";
import { WarningToast } from "shared/src/components/Toast/WarningToast";
import { SuccessToast } from "shared/src/components/Toast/SuccessToast";
import { ErrorToast } from "shared/src/components/Toast/ErrorToast";

const EditLink = styled.a`
  color: ${({ theme }) => theme.masterColors.primary};
  margin-left: 10px;
  text-decoration: underline !important;
  text-transform: uppercase;
`;

const SubmissionStatus = ({ document }) => {
  const submittedAt = moment(document.submissionDate).format("h:mma M/D/YYYY");

  const documentStatus = prettifyDocumentStatus(document.status);

  const Toast = useMemo(() => {
    switch (documentStatus) {
      case "In Progress":
      case "New":
        return WarningToast;
      case "Submitted":
        return SuccessToast;
      default:
        return ErrorToast;
    }
  }, [documentStatus]);

  return (
    <Toast visible={true} icon="icon-icons8-checkmark">
      {documentStatus === "N/A" ? documentStatus : "N/A status"}{" "}
      {document.formSummary.name} at {submittedAt}
      {!document.readOnly && (
        <EditLink
          onClick={() =>
            window.open(`/document/${document.id}`, "", "target=blank")
          }
        >
          edit
        </EditLink>
      )}
    </Toast>
  );
};

export default SubmissionStatus;
