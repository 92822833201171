import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  API,
  ClientGroupStatsVm,
  SortDirection,
} from "@rtslabs/field1st-fe-common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "shared/src/components/ActionButton/ActionButton";
import Breadcrumbs from "shared/src/components/Breadcrumbs/Breadcrumbs";
import Loader from "shared/src/components/Loader/Loader";
import { SearchBar } from "shared/src/components/SearchBar/SearchBar";
import BackToTop from "shared/src/components/TableUI/BackToTop/BackToTop";
import {
  Pagination,
  PaginationWrapper,
} from "shared/src/components/TableUI/Pagination/Pagination";
import { RowsPerPage } from "shared/src/components/TableUI/RowsPerPage/RowsPerPage";
import { TableSummary } from "shared/src/components/TableUI/TableSummary/TableSummary";
import { Components, ElementType, Page } from "shared/src/qa-slugs";
import { Button } from "shared/src/components/Button/Button";
import {
  formTemplateReadRoles,
  groupWriteRoles,
} from "../../../routes/constants/permissionSets";
import * as CAStyles from "shared/src/components/clientAdmin/styles";
import * as UsersStyles from "../../clientAdmin/users/styles";
import WriteContent from "shared/src/components/common/permissions/WriteContent";
import Popper from "shared/src/components/common/Popper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "shared/src/components/common/styled/Table";
import DefaultCell from "../../common/TableUI/DefaultCell";
import NoResults from "shared/src/components/common/TableUI/NoResults";
import { Text } from "shared/src/components/clientAdmin/styles";
import * as S from "./styles";

const Groups: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canViewFormTemplates = API.Environment.hasRoleAccess(
    formTemplateReadRoles
  );

  // Current page of group stats
  const [groupStats, setGroupStats] = useState<ClientGroupStatsVm[]>([]);

  // Pagination
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  // Group stats table is loading
  const [groupStatsLoading, setGroupStatsLoading] = useState(true);

  // Filter
  const [searchQuery, setSearchQuery] = useState("");

  // Sort
  const [primarySort, setPrimarySort] = useState({
    direction: "asc",
    type: "groupName",
  });
  const [sortingByGroupName, setSortingByGroupName] =
    useState<SortDirection>("asc");
  const [sortingByTeamMembers, setSortingByTeamMembers] =
    useState<SortDirection>("asc");
  const [sortingByFeedback, setSortingByFeedback] =
    useState<SortDirection>("asc");
  const [sortingBySubmissions, setSortingBySubmissions] =
    useState<SortDirection>("asc");
  const sortQuery = useMemo(
    () => `${primarySort.type},${primarySort.direction}`,
    [primarySort.type, primarySort.direction]
  );

  const sortBy = (type, direction) => {
    setPrimarySort({
      direction,
      type,
    });
    switch (type) {
      case "groupName":
        setSortingByGroupName(direction);
        break;
      case "feedback":
        setSortingByFeedback(direction);
        break;
      case "submissions":
        setSortingBySubmissions(direction);
        break;
      case "teamMembers":
        setSortingByTeamMembers(direction);
        break;
      default:
        return "";
    }
  };

  // Popper context menu
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorGroup, setAnchorGroup] = useState<ClientGroupStatsVm>();
  const [popperIsOpen, setPopperIsOpen] = useState(false);

  // Request groups
  const requestGroups = useCallback(
    async (page) => {
      setGroupStatsLoading(true);
      try {
        const response = await API.getClientGroupStats({
          page,
          query: searchQuery,
          size,
          sort: sortQuery,
        });
        const { content, number, totalElements, totalPages } = response;
        setGroupStats(content);
        setTotalPages(totalPages);
        setTotalElements(totalElements);
        setPage(number);
      } catch (error) {
        console.error(error);
        // todo handle properly
      }
      setGroupStatsLoading(false);
    },
    [page, searchQuery, sortQuery, size]
  );

  // On filter or size change
  useEffect(() => {
    requestGroups(0);
  }, [size, searchQuery, sortQuery]);

  return (
    <>
      <Popper
        qa={`${Components.Groups}-${ElementType.MenuModal}-${anchorGroup?.clientGroupId}`}
        anchorEl={anchorEl}
        onClose={() => {
          // setPopperIsOpen(false);
        }}
        open={popperIsOpen}
        placement="left"
      >
        <UsersStyles.PopperWrapper>
          <WriteContent roles={groupWriteRoles}>
            <UsersStyles.PopperRow
              data-testid={`${Components.Groups}-${ElementType.MenuItem}-viewEditProfile-${anchorGroup?.clientGroupId}`}
              onClick={() =>
                navigate(
                  `/people/groups/view/${
                    anchorGroup && anchorGroup.clientGroupId
                  }`
                )
              }
            >
              <span>View/edit group profile</span>
              <UsersStyles.PopperChevron />
            </UsersStyles.PopperRow>
          </WriteContent>
          <UsersStyles.PopperRow
            data-testid={`${Components.Groups}-${ElementType.MenuItem}-viewDetailedReport-${anchorGroup?.clientGroupId}`}
            onClick={() =>
              navigate(
                `/reports?group=${anchorGroup && anchorGroup.clientGroupId}`
              )
            }
          >
            <span>View detailed reports</span>
            <UsersStyles.PopperChevron />
          </UsersStyles.PopperRow>

          {canViewFormTemplates && (
            <UsersStyles.PopperRow
              data-testid={`${Components.Groups}-${ElementType.MenuItem}-viewFormsTemplates-${anchorGroup?.clientGroupId}`}
              onClick={() =>
                navigate(
                  `/forms?group=${anchorGroup && anchorGroup.clientGroupId}`
                )
              }
            >
              <span>View active forms & templates</span>
              <UsersStyles.PopperChevron />
            </UsersStyles.PopperRow>
          )}
        </UsersStyles.PopperWrapper>
      </Popper>

      <CAStyles.PageContent>
        <CAStyles.TableWrapper>
          {/* 
            @NOTE: ID IS USED FOR "SCROLL TO" / "BACK TO TOP" LOGIC
            @TODO: Update with a better solution - Trevor Kirpaul
          */}
          <div id="mainContent" className="col-12">
            <Breadcrumbs
              paths={[
                {
                  pathName: "People",
                },
                {
                  pathName: "Groups",
                  href: "/people/groups",
                },
              ]}
            />
          </div>
          <UsersStyles.PageHeader>
            <UsersStyles.PageTitle
              data-testid={`${Page.Desktop}-${Components.Groups}`}
            >
              Groups
            </UsersStyles.PageTitle>
            <WriteContent roles={groupWriteRoles}>
              <Button
                qa={`${Components.Groups}-${ElementType.Button}-add-group`}
                onClick={() => navigate("/people/groups/add-group")}
              >
                Add group
              </Button>
            </WriteContent>
          </UsersStyles.PageHeader>
          {/* Filters */}
          <div
            style={{
              width: "398px",
              marginTop: "10px",
              marginBottom: "15px",
              padding: "15px",
            }}
          >
            <SearchBar
              qa={`${Components.Groups}-${ElementType.TextInput}-searchBar`}
              onSearch={setSearchQuery}
            />
          </div>
          <div className="col-12">
            <Loader loading={groupStatsLoading}>
              <TableSummary
                pageSize={size}
                currentPage={page}
                totalElements={totalElements}
                ofWhat="groups"
                exports={["print", "xls", "csv"]}
                onExport={API.downloadClientGroupStats}
                exportParams={{ query: searchQuery }}
              />
              <Table>
                <TableHead>
                  <TableRow noBottomBorder>
                    <TableCell
                      qa={`${Components.Groups}-${ElementType.TableCell}-sort-groupName`}
                      active={primarySort.type === "groupName"}
                      align="left"
                      minWidth="200px"
                      onClick={(dir) => sortBy("groupName", dir)}
                      sortDirection={sortingByGroupName}
                    >
                      Group Name
                    </TableCell>
                    <TableCell
                      qa={`${Components.Groups}-${ElementType.TableCell}-sort-teamMembers`}
                      active={primarySort.type === "teamMembers"}
                      align="center"
                      onClick={(dir) => sortBy("teamMembers", dir)}
                      sortDirection={sortingByTeamMembers}
                      width="210px"
                    >
                      Team Members
                    </TableCell>
                    <TableCell
                      qa={`${Components.Groups}-${ElementType.TableCell}-sort-feedback`}
                      active={primarySort.type === "feedback"}
                      align="center"
                      onClick={(dir) => sortBy("feedback", dir)}
                      sortDirection={sortingByFeedback}
                      width="200px"
                    >
                      Feedback
                    </TableCell>
                    <TableCell
                      qa={`${Components.Groups}-${ElementType.TableCell}-sort-submissions`}
                      active={primarySort.type === "submissions"}
                      align="center"
                      onClick={(dir) => sortBy("submissions", dir)}
                      sortDirection={sortingBySubmissions}
                      width="200px"
                    >
                      Submissions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupStats && groupStats.length > 0 ? (
                    groupStats.map((group) => (
                      <TableRow key={group.clientGroupId}>
                        <TableCell>
                          <WriteContent
                            altComponent={
                              <Text
                                data-testid={`${Components.Groups}-${ElementType.Text}-groupName-${group.clientGroupId}`}
                                fontSize={"0.875rem"}
                                fontWeight={400}
                              >
                                {group.groupName}
                              </Text>
                            }
                            roles={groupWriteRoles}
                          >
                            <Text
                              data-testid={`${Components.Groups}-${ElementType.Link}-groupName-${group.clientGroupId}`}
                              fontSize={"0.875rem"}
                              fontWeight={400}
                              onClick={() =>
                                navigate(
                                  `/people/groups/view/${group.clientGroupId}`
                                )
                              }
                            >
                              {group.groupName}
                            </Text>
                          </WriteContent>
                          <S.GroupId
                            data-testid={`${Components.Groups}-${ElementType.Text}-externalGroupId-${group.clientGroupId}`}
                          >
                            {`ID: ${group.externalGroupId}` || "-"}
                          </S.GroupId>
                        </TableCell>
                        <TableCell align="center">
                          <DefaultCell
                            qa={`${Components.Groups}-${ElementType.Link}-teamMembers-${group.clientGroupId}`}
                            color="primary"
                            onClick={() =>
                              navigate(
                                `/people/users?group=${group.clientGroupId}`
                              )
                            }
                            value={group.teamMembers}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <DefaultCell
                              qa={`${Components.Groups}-${ElementType.Text}-feedback-${group.clientGroupId}`}
                              // Removed link per POW-2269 -- GK
                              // color="primary"
                              // onClick={() => history.push("/feedback")}
                              value={group.feedback}
                            />
                            <i
                              className="icon-icons8-flag"
                              style={{ marginLeft: "10px" }}
                            />
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <DefaultCell
                            qa={`${Components.Groups}-${ElementType.Link}-submissions-${group.clientGroupId}`}
                            color="primary"
                            onClick={() =>
                              navigate(`/reports?group=${group.clientGroupId}`)
                            }
                            value={group.submissions}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <ClickAwayListener
                            onClickAway={() => {
                              if (popperIsOpen) {
                                setPopperIsOpen(false);
                              }
                            }}
                          >
                            <div className="d-flex flex-row justify-content-end">
                              <ActionButton
                                qa={`${Components.Groups}-${ElementType.Menu}-${group.clientGroupId}`}
                                label={t("common:table.rowMenuButtonLabel")}
                                onClick={(e) => {
                                  setAnchorEl(e.currentTarget);
                                  setAnchorGroup(group);
                                  setTimeout(() => {
                                    setPopperIsOpen(true);
                                  }, 100);
                                }}
                              />
                            </div>
                          </ClickAwayListener>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResults
                      icon="users"
                      header="No groups were found."
                      body="Please update your filters, or create a new group."
                      button={{
                        children: "Create a group",
                        onClick: () => navigate("/people/groups/add-group"),
                      }}
                      qa={`${Components.Groups}-${Components.NotFound}`}
                    />
                  )}
                </TableBody>
              </Table>

              {/* // Pagination */}
              <PaginationWrapper>
                <RowsPerPage
                  qaBase={`${Components.Groups}-view`}
                  pageSize={size}
                  onClick={(size) => {
                    setSize(size);
                  }}
                />
                <Pagination
                  qaBase={`${Components.Groups}-pagination`}
                  currentPage={page}
                  totalPages={totalPages}
                  onClick={(page) => {
                    setPage(page);
                    requestGroups(page);
                  }}
                />
                <BackToTop
                  qa={`${Components.Groups}-${ElementType.Link}-backToTop`}
                />
              </PaginationWrapper>
            </Loader>
          </div>
        </CAStyles.TableWrapper>
      </CAStyles.PageContent>
    </>
  );
};

export default Groups;
