import React, { useEffect, useState } from "react";
import Login from "shared/src/components/auth/Login";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../store/hooks/useAuth";
import {
  API,
  CoreRedux,
  LoginFailureResponse,
  LoginVM,
} from "@rtslabs/field1st-fe-common";
import { useAppDispatch } from "../store/hooks";

interface LocationState {
  redirect: string;
}

const LoginScreen = () => {
  const [error, setError] = useState<LoginFailureResponse | undefined>();

  const { getUserConfigs } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const otherDispatch = useDispatch(); // I also dont know things - MW

  const { redirect } = (location.state as LocationState) || {};

  const logo = useSelector(CoreRedux.selectPrimaryLogoImageProps);

  const handleGetAdfsUserConfigs = async (
    adfsToken: string,
    redirectAfterLogin: string | null
  ) => {
    await otherDispatch(CoreRedux.setAuthToken(adfsToken));
    API.Environment.authToken = adfsToken;
    if (API.Environment.isTokenValid()) {
      try {
        await getUserConfigs();
        navigate(`../${redirectAfterLogin || redirect || "/"}`, {
          replace: true,
        });
      } catch (err: unknown) {
        console.error(err);
      }
    }
  };

  const handleLogin = async (values: LoginVM): Promise<void> => {
    setError(undefined);
    try {
      await dispatch(
        CoreRedux.getAuthToken({
          username: values.username?.replace(/\s/g, ""),
          password: values.password,
        })
      );
      if (API.Environment.isTokenValid()) {
        try {
          await getUserConfigs();
        } catch (err: unknown) {
          console.error(err);
        }
        navigate(redirect || "/", { replace: true });
      } else {
        throw new Error("Invalid token");
      }
    } catch (err: unknown) {
      console.error(err);
      if (API.isLoginFailure(err)) {
        setError(err);
      } else {
        setError({
          accountLocked: false,
          message: "Something went wrong. Please try again.",
          recaptchaNeeded: false,
          resetPassword: false,
        });
      }
    }
  };

  return (
    <Login
      onLogin={handleLogin}
      handleGetAdfsUserConfigs={handleGetAdfsUserConfigs}
      error={error}
      logo={logo}
      redirect={redirect}
    />
  );
};

export default LoginScreen;
