import React, { FC } from "react";
import { ElementType, QAProps } from "../../qa-slugs";
import styles from "./AssistiveText.module.scss";

interface Props extends QAProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
}

export const AssistiveText: FC<Props> = ({ children, qa, ...rest }) => {
  if (!children) return null;
  return (
    <span
      className={styles.assistiveText}
      data-testid={qa ? `qa-${ElementType.Text}` : undefined}
      {...rest}
    >
      {children}
    </span>
  );
};
