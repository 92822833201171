import moment from "moment";
import React from "react";

import {
  DefenseDocument,
  DefenseDTO,
  DocumentQuestionResponseVm,
  DocumentStatus,
  DocumentVm,
  QuestionDTO,
} from "@rtslabs/field1st-fe-common";
import styles from "./Defenses.module.scss";

import { useFormikContext } from "formik";
import { createOrUpdateResponse } from "shared/src/api/responseRequests";
import CommentField from "shared/src/components/Document/DocumentForm/CommentField/CommentField";
import { Icon } from "shared/src/components/Icon/Icon";
import { Link } from "shared/src/components/Link/Link";
import { joinClassNames } from "shared/src/helpers/theme.helpers";
import { selectIsPreview } from "shared/src/redux/document/documentSelectors";
import { useAppSelector } from "../../../store/hooks";

const createMarkup = (x: string) => ({ __html: x });

const Doc = ({
  description,
  title,
  url,
  fileSize,
  fileType,
  lastUploadedDate,
}: DefenseDocument) => (
  <div className={styles.docWrapper}>
    <span className={styles.fileType}>{fileType}</span>
    {url ? (
      <Link
        to={url}
        className={joinClassNames(styles.descriptionText, styles.titleLink)}
      >
        {title}
      </Link>
    ) : (
      <span
        className={joinClassNames(styles.descriptionText, styles.titleLink)}
      >
        {title}
      </span>
    )}
    <p className={styles.descriptionText}>{description}</p>
    <div className={styles.iconAndSpecs}>
      <Icon type="pdf" className={styles.styledIcon} />
      <div className={styles.specs}>
        <span className={styles.spec}>{fileSize}</span>
        <span className={styles.spec}>
          {moment(lastUploadedDate).format("MM/DD/YYYY")}
        </span>
      </div>
    </div>
  </div>
);

type NotesProps = {
  name: number | string;
  id: number;
  commentValue: string | null | undefined;
  handleClearComment: () => void;
  handleUpdateComment: (comments?: string) => void;
};

const NotesBlock = ({
  id,
  name,
  commentValue,
  handleClearComment,
  handleUpdateComment,
}: NotesProps) => {
  return (
    <>
      <div className={styles.caret} />
      <div className={styles.noteContainer}>
        <span className={styles.subtitle}>Related Comment</span>
        <CommentField
          commentValue={commentValue || ""}
          handleClearComment={handleClearComment}
          handleUpdateComment={handleUpdateComment}
          name={`${name}`}
          className={styles.comment}
        />
      </div>
    </>
  );
};

interface DetailsBlockProps {
  handleUpdateComment: (comments?: string) => void;
  handleClearComment: () => void;
  response: DocumentQuestionResponseVm;
  defense: DefenseDTO;
}

const DetailsBlock = ({
  response,
  defense,
  handleClearComment,
  handleUpdateComment,
}: DetailsBlockProps) => (
  <div className={styles.detailsBlockContainer}>
    <div className={styles.detailsTitleContainer}>
      <Icon type="checkmark" className={styles.detailsTitleIcon} />
      <span className={styles.detailsTitle}>{response.answer}</span>
    </div>
    <div className={styles.detailsWrapper}>
      <p dangerouslySetInnerHTML={createMarkup(defense.description ?? "")} />
      {defense?.defenseDocuments &&
        defense.defenseDocuments.length > 0 &&
        defense.defenseDocuments.map((d) => (
          <Doc
            key={`defense_doc_${d.id}`}
            contentLength={d.contentLength}
            contentType={d.contentType}
            description={d.description}
            fileSize={d.fileSize}
            fileType={d.fileType?.toUpperCase()}
            id={d.id}
            lastUploadedDate={d.lastUploadedDate}
            title={d.title}
            url={d.url}
          />
        ))}
    </div>
    <NotesBlock
      id={response.questionId}
      handleClearComment={handleClearComment}
      handleUpdateComment={(comments?: string) => handleUpdateComment(comments)}
      commentValue={response.comments}
      name={response.associatedId || response.questionId}
    />
  </div>
);

type Props = {
  defenses?: DefenseDTO[];
  responses?: DocumentQuestionResponseVm[];
  subTitle: string;
  questions: QuestionDTO[];
  setQuestionResponses: (
    question: QuestionDTO,
    questionResponses: DocumentQuestionResponseVm[]
  ) => void;
};

export const DefenseDetails = ({
  questions,
  defenses = [],
  responses = [],
  subTitle,
  setQuestionResponses,
}: Props) => {
  const { values } = useFormikContext<DocumentVm>();
  /**
   * CORE doucments should only update on response changes when in SUBMITTED status
   * documents IN_PROGRESS will update via the DocumentResponse endpoints
   */
  const isPreview = useAppSelector(selectIsPreview);
  const shouldUpdateForm =
    values.status === DocumentStatus.SUBMITTED || isPreview;

  function handleUpdateComment(
    response: DocumentQuestionResponseVm,
    comment?: string
  ) {
    const question = questions.find((ques) => ques.id === response.questionId);
    if (!question) return;

    const questionResponses = responses.filter(
      (res) => res.questionId === response.questionId
    );

    const updatedQuestionResponses = questionResponses.map((qRes) => {
      if (qRes.associatedId === response.associatedId) {
        const updatedResponse = {
          ...qRes,
          comments: comment || "",
          timeAnswered: new Date().toISOString(),
        };
        shouldUpdateForm
          ? updatedResponse
          : createOrUpdateResponse(values.id, updatedResponse);
        return updatedResponse;
      }
      return qRes;
    });
    setQuestionResponses(question, updatedQuestionResponses);
  }

  function getDefenseResponses(defense: DefenseDTO) {
    return responses.filter((res) =>
      defense.questionSelections?.find((sel) =>
        res.associatedId
          ? sel.id === res.associatedId
          : sel.id === res.questionId
      )
    );
  }

  return (
    <div>
      <span>{subTitle}</span>
      {defenses.map((defense) => (
        <React.Fragment key={defense.id}>
          {getDefenseResponses(defense).map((response) => (
            <DetailsBlock
              key={`${response.questionId}${
                response.associatedId ? `_${response.associatedId}` : ""
              }`}
              handleUpdateComment={(comment?: string) =>
                handleUpdateComment(response, comment)
              }
              handleClearComment={() => {
                handleUpdateComment(response, "");
              }}
              defense={defense}
              response={response}
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};
