import { CoreRole, roles } from "@rtslabs/field1st-fe-common";

export const ALL_ROLES: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_CONTENT_EDITOR,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_GROUP_MANAGER,
  roles.ROLE_SUPER_ADMIN,
  roles.ROLE_USER,
];

export const dataSetReadRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const dataSetWriteRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const formTemplateReadRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const formTypeReadRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const formTypeWriteRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const groupReadRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_GROUP_MANAGER,
  roles.ROLE_SUPER_ADMIN,
];

export const groupWriteRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_GROUP_MANAGER,
  roles.ROLE_SUPER_ADMIN,
];

export const resourceReadRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_CONTENT_EDITOR,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const settingsReadRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_SUPER_ADMIN,
];

export const settingsWriteRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_SUPER_ADMIN,
];

export const tagReadRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_CONTENT_EDITOR,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const tagWriteRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CONTENT_EDITOR,
  roles.ROLE_FORM_ARCHITECT,
  roles.ROLE_SUPER_ADMIN,
];

export const userReadRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_CLIENT_REVIEWER,
  roles.ROLE_GROUP_MANAGER,
  roles.ROLE_SUPER_ADMIN,
];

export const userWriteRoles: CoreRole[] = [
  roles.ROLE_CLIENT_ADMIN,
  roles.ROLE_GROUP_MANAGER,
  roles.ROLE_SUPER_ADMIN,
];
