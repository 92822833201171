import React from "react";
import styles from "./RatingScale.module.scss";

interface ScaleRowProps {
  label: string;
  message: string;
}

const ScaleRow = ({ label, message }: ScaleRowProps) => (
  <p className={styles.scaleRow}>
    <strong>{label}:</strong> {message}
  </p>
);

export const RatingScale = () => (
  <div className={styles.scaleContainer}>
    <div className={styles.headerContainer}>
      <h4 className={styles.header}>How to use the safety scale:</h4>
    </div>
    <div>
      <ScaleRow
        label="Risk"
        message="A situation involving exposure to danger."
      />
      <ScaleRow
        label="Coaching Moment"
        message="When additional effort is required to prevent a risk."
      />
      <ScaleRow
        label="Satisfactory"
        message="Meeting expectations is acceptable."
      />
      <ScaleRow
        label="Best Practice"
        message="Most effective or above expectations."
      />
    </div>
  </div>
);
