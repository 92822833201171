export enum DocumentActionType {
  SetPreview = "SET_PREVIEW",
}

export interface DocumentSetPreviewAction {
  isPreview: boolean;
  type: typeof DocumentActionType.SetPreview;
}

export type DocumentAction = DocumentSetPreviewAction;
