import {
  API,
  ClientGroupDTO,
  FormTypeSaveVm,
  FormTypeVm,
  useAPI,
  useDebouncedValue,
} from "@rtslabs/field1st-fe-common";
import { Form, Formik } from "formik";
import fuzzysort from "fuzzysort";
import React, { useMemo, useState } from "react";
import { Button, TertiaryButton } from "shared/src/components/Button/Button";
import { TextInput } from "shared/src/components/TextInput/TextInput";
import YesOrNo from "shared/src/components/YesOrNo/YesOrNo";
import * as QA from "shared/src/qa-slugs";
import {
  IconSelector,
  IconValue,
} from "shared/src/components/common/Icon/IconSelector";
import { PillBox } from "shared/src/components/common/Pill/PillBox";
import { DisableUserButtonsWrapper } from "../../users/styles";
import { validation } from "../helpers";
import styles from "./modals.module.scss";

interface FormTypeFormProps<T extends FormTypeSaveVm | FormTypeVm> {
  fullPage?: boolean;
  initialValues: T;
  loading: boolean;
  onSubmit: (values: T) => void;
  onClose: () => void;
  readOnly?: boolean;
  saveButtonLabel: string;
}

export function FormTypeForm<T extends FormTypeSaveVm | FormTypeVm>({
  fullPage,
  initialValues,
  loading,
  onSubmit,
  onClose,
  readOnly = false,
  saveButtonLabel,
}: FormTypeFormProps<T>) {
  const [searchText, setSearchText] = useState<string | undefined>();

  const suggestionsSearch = useDebouncedValue(searchText, 300);

  const suggestionsCall = useAPI(API.getClientGroups, { page: 0, size: 1000 });

  const suggestions: ClientGroupDTO[] = useMemo(() => {
    if (!suggestionsCall?.data?.content) return [];
    if (!suggestionsSearch) return suggestionsCall.data.content;
    return fuzzysort
      .go(suggestionsSearch, suggestionsCall.data.content, { key: "name" })
      .map((s) => s.obj);
  }, [suggestionsCall.data?.content, suggestionsSearch]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={!readOnly && validation}
      validateOnChange
      validateOnBlur
    >
      {({ values, errors, setFieldValue }) => (
        <Form>
          <div
            className={
              fullPage ? styles.formWrapperFullPage : styles.formWrapper
            }
          >
            <TextInput
              wrapperClassName={styles.field}
              name="name"
              label="Field Type Name"
              placeholder="Field Type Name"
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
              required={!readOnly}
              value={values.name}
              error={errors.name as string}
              qa={`${QA.Components.FormTypes}-${QA.Components.AddModal}-${QA.ElementType.TextInput}-name`}
              disabled={readOnly}
            />
            <IconSelector
              label="Form Type Icon"
              required={!readOnly}
              onChange={(value: IconValue) => {
                if (values.iconColor !== value.color) {
                  setFieldValue("iconColor", value.color);
                }
                if (values.iconName !== value.icon) {
                  setFieldValue("iconName", value.icon);
                }
              }}
              value={{
                color: values.iconColor ?? "#000000",
                icon: values.iconName ?? "",
              }}
              error={{
                icon: errors.iconName as string,
                color: errors.iconColor as string,
              }}
              qaBase={`${QA.Components.FormTypes}-${QA.Components.AddModal}-${QA.Components.IconSelector}`}
              disabled={readOnly}
              propStyles={{ selectionArea: styles.iconSelectionArea }}
            />
            {/* If it's global, it's not restricted to groups */}
            <YesOrNo
              label="Restrict to Groups?"
              required={!readOnly}
              name="isGlobal"
              onChange={(data: string) => {
                setFieldValue("isGlobal", data === "false");
              }}
              value={!values.isGlobal}
              qa={`${QA.Components.FormTypes}-${QA.Components.AddModal}-${QA.ElementType.Radio}-restrictToGroups`}
              error={errors.isGlobal as string}
              disabled={readOnly}
            />
            {!values.isGlobal && (
              <PillBox
                label="Visible To"
                required={!readOnly}
                labelField={"name"}
                placeholder="Add Group..."
                suggestions={suggestions}
                suggestionsLabel="Group Suggestions"
                values={values.clientGroups ?? []}
                error={errors.clientGroups as string}
                disabled={readOnly}
                onChange={(newValues: ClientGroupDTO[]) => {
                  if (
                    values.clientGroups &&
                    newValues.length < values.clientGroups?.length
                  ) {
                    setFieldValue(
                      "clientGroups",
                      values.clientGroups.filter((ov) =>
                        newValues.find((val) => val.id === ov.id)
                      )
                    );
                  } else {
                    const next: ClientGroupDTO[] = newValues.reduce(
                      (acc, cur) => {
                        const nv =
                          values.clientGroups?.find((cg) => cg.id === cur.id) ??
                          suggestions.find((s) => s.id === cur.id);
                        if (nv) return acc.concat(nv);
                        return acc;
                      },
                      [] as ClientGroupDTO[]
                    );
                    setFieldValue("clientGroups", next);
                  }
                }}
                onSearch={(search: string) => {
                  setSearchText(search);
                }}
                qa={`${QA.Components.FormTypes}-${QA.Components.AddModal}-${QA.ElementType.PillBox}`}
              />
            )}
            {!readOnly && (
              <DisableUserButtonsWrapper margin="24px 0 0 0">
                <TertiaryButton
                  disabled={false}
                  onClick={onClose}
                  qa={`${QA.Components.FormTypes}-${QA.Components.AddModal}-${QA.Components.Button}-cancel`}
                >
                  cancel
                </TertiaryButton>
                <Button
                  type="submit"
                  qa={`${QA.Components.FormTypes}-${QA.Components.AddModal}-${QA.Components.Button}-save`}
                  loading={loading}
                >
                  {saveButtonLabel}
                </Button>
              </DisableUserButtonsWrapper>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
