import React, { FC } from "react";
import { joinClassNames } from "../../helpers/theme.helpers";

import styles from "./Logout.module.scss";

interface Props {
  className?: string;
  onLogOut: () => void;
}

export const Logout: FC<Props> = ({ className, onLogOut }: Props) => {
  return (
    <div className={joinClassNames(styles.logout, className)}>
      <button onClick={onLogOut} className={styles.button}>
        <span className={styles.label}>LOG OUT</span>
      </button>
    </div>
  );
};

Logout.displayName = "Logout";
