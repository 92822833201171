import styled from "styled-components";
import { remCalc, toRgba } from "../../themes/helpers";

interface PageActionButtonProps {
  ["data-testid"]?: string;
}

export const H1 = styled.h1`
  font-weight: 400;
  font-size: ${remCalc(36)};
  letter-spacing: ${remCalc(0.63)};
`;

export const H2 = styled.h2`
  font-size: ${remCalc(20)};
`;
export const H3 = styled.h3`
  font-size: ${remCalc(16)};
`;

export const H5 = styled.h5`
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 500;
  color: ${(props) => props.theme.masterColors.darkGrey};
  letter-spacing: 1.88px;
`;

export const FadedText = styled.span`
  color: ${(props) => props.theme.colors.assistiveText};
`;

export const SmallText = styled.span`
  font-size: 0.875rem;
`;

export const ListItem = styled.li`
  color: ${(props) => props.theme.masterColors.darkGrey};
  margin-bottom: 0.3rem;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitle = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.25rem;
  letter-spacing: 0.63px;
`;

export const PageActionButton = styled.div<PageActionButtonProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  height: 36px;
  justif-content: center;
  letter-spacing: 1.25px
  padding: 0 16px;
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  gap: 10px;
`;

type TextType = {
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  letterSpacing?: string;
  lineClamp?: number;
  margin?: string;
  textAlign?: "center" | "left" | "right" | "justify";
  textDecoration?: string;
  textTransform?: string;
};

export type TextProps = TextType & React.HTMLAttributes<HTMLDivElement>;

export const Text = styled.div<TextType>`
  color: ${({ color, onClick, theme }) =>
    onClick && !color ? theme.colors.primary : color};
  cursor: ${({ onClick }) => onClick && "pointer"};
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  line-clamp: ${({ lineClamp }) => lineClamp};
  margin: ${({ margin }) => margin};
  text-align: ${({ textAlign }) => textAlign};
  text-decoration: ${({ textDecoration }) => textDecoration};
  text-transform: ${({ textTransform }) => textTransform};
`;

export const EditIcon = styled.i.attrs({
  className: "icon-icons8-pencil",
})`
  cursor: pointer;
  font-size: 0.875rem;
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

type ButtonType = {
  margin?: string;
  disabled?: boolean;
};

export const CancelButton = styled.div<ButtonType>`
  background-color: none;
  border: none;
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
  display: ${({ disabled }) => disabled && "none"};
  font-size: 0.875rem !important;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin: ${({ margin }) => margin};
  padding: 8px 16px;
  text-align: center;
  text-transform: uppercase;
`;

export const SubmitButton = styled.button<ButtonType>`
  align-items: center;
  background-color: ${({ disabled, theme }) =>
    disabled ? toRgba(theme.colors.black, 0.12) : theme.colors.primary};
  border-radius: 4px;
  border: none;
  box-shadow: ${({ disabled }) => disabled && "none"};
  color: ${({ disabled, theme }) =>
    disabled ? toRgba(theme.colors.black, 0.26) : theme.colors.white};
  display: flex;
  font-size: 0.875rem !important;
  font-weight: 500;
  letter-spacing: 1.25px;
  margin: ${({ margin }) => margin};
  padding: 8px 35px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
`;

//
// Global wrappers below
//

// Page content wrapper (excluding sidebar nav)
export const PageContent = styled.div<{
  className?: string;
}>`
  max-width: 990px; // 30px added to offset inside padding
  min-width: 785px; // 30px added to offset inside padding
`;

// Stats card wrappers
export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin-top: 26px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
`;
