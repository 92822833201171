import React from "react";
import { ContentDTO } from "@rtslabs/field1st-fe-common";
import styles from "./documentForm.module.scss";

interface Props {
  item: ContentDTO;
}

/**
 * Render a custom content item or line break
 * @param item
 * @constructor
 */
export default function Content({ item }: Props) {
  switch (item.subType) {
    case "LINE_BREAK":
      return <hr />;
    case "BANNER_MESSAGE":
      return (
        <div
          className={styles.contentBanner}
          style={{
            backgroundColor: item.backgroundColor,
            color: item.textColor,
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: item.content }}
            className="dangerousBanner"
          />
          <style
            dangerouslySetInnerHTML={{
              __html: ".dangerousBanner > p:last-of-type { margin-bottom: 0 }",
            }}
          />
        </div>
      );
    case "BLOCK":
      return (
        <div className={styles.contentBlock}>
          <div
            dangerouslySetInnerHTML={{ __html: item.content }}
            className="dangerousBlock"
          />
          <style
            dangerouslySetInnerHTML={{
              __html: ".dangerousBlock > p:last-of-type { margin-bottom: 0 }",
            }}
          />
        </div>
      );
    default:
      return null;
  }
}
