import React from "react";
import { Icon } from "../Icon/Icon";
import { joinClassNames } from "../../helpers/theme.helpers";
import styles from "./MultiInput.module.scss";

type MultiInputItemOptionProps = {
  className?: string;
  label?: string;
  subLabel?: string;
  onRemove: () => void;
  onClick?: () => void;
  icon?: string;
};

const MultiInputItemOption = ({
  icon = "delete_sign",
  className,
  subLabel,
  label,
  onRemove,
  onClick,
}: MultiInputItemOptionProps) => {
  function handleRemoveItem(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    // LastPass causes the form to reset when clicking this sometimes. These e methods block it from wacking out
    e.preventDefault();
    e.stopPropagation();
    onRemove();
  }

  return (
    <li
      className={joinClassNames(className, styles.multiInputListItem)}
      onClick={onClick}
    >
      <div>
        <span>{label}</span>
        {subLabel && <p>{subLabel}</p>}
      </div>
      <div className={styles.endAdornment} onClick={handleRemoveItem}>
        <Icon type={icon} />
      </div>
    </li>
  );
};

export default MultiInputItemOption;
