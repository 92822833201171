import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useField, useFormikContext } from "formik";

import {
  API,
  ConditionActionType,
  DataSourceDTO,
  DataSourceValueDTO,
  QuestionAnswerSourceDTO,
  useAsyncEffect,
} from "@rtslabs/field1st-fe-common";
import { SelectOption } from "../../../../../../Select/Select";
import {
  FBDisplayCondition,
  FBForm,
  FBQuestion,
  Property,
} from "../../../../../types";
import Checkbox from "../../../../../../Checkbox/Checkbox";

import { buildTargetOptions, getTargetItems } from "./helpers";
import { ItemSelection } from "../../../content/ItemField";
import AddRuleButton from "./AddRuleButton";
import Condition from "./Condition";
import ps from "../../styles.module.scss";
import { ItemParams } from "../../../Create";

function buildSelectionResponses(
  values: FBForm,
  itemPath: string,
  itemSubType: string
): SelectOption[] {
  const selections = get(
    values,
    `${itemPath}.selections`,
    [] as NonNullable<FBQuestion["selections"]>
  );

  if (itemSubType === "YES_OR_NO") {
    return [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ];
  }

  return selections.map((option: ItemSelection) => ({
    value: option.rootId!,
    label: option.title,
  }));
}

function buildAnswerSourceSelections(
  answerSource: QuestionAnswerSourceDTO,
  dataSourceValues: DataSourceValueDTO[]
): SelectOption<number>[] {
  if (answerSource.dataSourceKey) {
    const contentProp = answerSource?.properties?.answerField || 0;
    return dataSourceValues.map((dsv: DataSourceValueDTO) => ({
      value: dsv.id,
      label: (dsv.content as { [key: string]: string })[contentProp],
    }));
  }
  return [];
}

function buildNewCondition(itemId: number): FBDisplayCondition {
  return {
    sourceQuestionRootId: itemId,
    targetRootId: 0,
    action: undefined,
    sourceConditionRootId: undefined,
    targetType: undefined,
    prefillAnswerField: "",
    prefillAssociatedIdField: "",
  };
}

interface Props {
  property: Property;
  itemPath: string;
  item: FBQuestion;
  appWidgetsList: ItemParams[];
}

const DisplayConditions = ({
  property,
  itemPath,
  item,
  appWidgetsList,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<FBForm>();
  const [checked, setChecked] = useState<boolean>(false);

  // get data source if necessary
  const [dataSource, setDataSource] = useState<DataSourceDTO>();
  useAsyncEffect(async () => {
    const id = Number(item.answerSource?.properties?.dataSourceId);
    if (!isNaN(id)) {
      try {
        const res = await API.getDataSourceById({ id });
        setDataSource(res);
      } catch (e) {
        console.error(e);
      }
    }
  }, [item.answerSource?.properties?.dataSourceId, setDataSource]);

  // get data source values if necessary
  const [dataSourceValues, setDataSourceValues] = useState<
    DataSourceValueDTO[]
  >([]);
  useAsyncEffect(async () => {
    if (item.answerSource?.dataSourceKey) {
      try {
        const res = await API.getDataSourceValues({
          dataSourceKey: item.answerSource?.dataSourceKey,
        });
        setDataSourceValues(res.content);
      } catch (e) {
        console.error(e);
      }
    }
  }, [item.answerSource?.dataSourceKey, setDataSourceValues]);

  // form display conditions
  const formDisplayConditions = values.displayConditions;
  // item display conditions
  const itemDisplayConditions = formDisplayConditions.filter(
    (fdc) => fdc.sourceQuestionRootId === item.rootId
  );

  useEffect(() => {
    if (itemDisplayConditions.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [item]);

  // answer source options
  let sourceColumns: SelectOption<string>[];
  if (dataSource?.type === "BASIC") {
    sourceColumns = [{ value: "value", label: "Basic Value" }];
  } else {
    sourceColumns =
      dataSource?.csvMappings?.map((map) => ({
        label: map.columnName,
        value: map.columnName,
      })) || [];
  }

  // build response options from answerSource or item selections
  const responseOptions = item.answerSource
    ? buildAnswerSourceSelections(item.answerSource, dataSourceValues)
    : buildSelectionResponses(values, itemPath, item.subType);

  // build target options
  const targetItems = getTargetItems(values.sections);
  const targetOptions = buildTargetOptions(targetItems, item, appWidgetsList);

  function addCondition() {
    const newCondition = buildNewCondition(item.rootId!);
    setFieldValue("displayConditions", [
      ...formDisplayConditions,
      newCondition,
    ]);
  }

  function removeCondition(conditionIndex: number) {
    const updatedConditions = [...formDisplayConditions];
    updatedConditions.splice(conditionIndex, 1);
    setFieldValue("displayConditions", updatedConditions);
  }

  function updateCondition(
    conditionIndex: number,
    updates: FBDisplayCondition
  ) {
    const updatedConditions = formDisplayConditions.map((fdc, index) => {
      if (index === conditionIndex) {
        return { ...fdc, ...updates };
      }
      return fdc;
    });
    setFieldValue("displayConditions", updatedConditions);
  }

  function updateConditionType(
    conditionIndex: number,
    type?: ConditionActionType
  ) {
    updateCondition(conditionIndex, {
      action: type,
      targetRootId: undefined,
      targetType: undefined,
      sourceConditionRootId: undefined,
      booleanCondition: undefined,
    });
  }

  function handleCheck(e: boolean) {
    if (e) {
      addCondition();
      setChecked(true);
    } else {
      setChecked(false);
      // @TODO do we want to preserve the display conditions if they uncheck the rule?
      // if so we should keep a copy in a reducer/state
      setFieldValue(
        "displayConditions",
        formDisplayConditions.filter(
          (dc) => dc.sourceQuestionRootId !== item.rootId
        )
      );
    }
  }

  const [enabledField] = useField(`${itemPath}.${property.name}.enabled`);

  return (
    <div className="displayConditions">
      <Checkbox
        {...enabledField}
        className={ps.checkbox}
        containerClassName={ps.checkboxContainer}
        label={property.label}
        checked={checked}
        onChange={handleCheck}
      />
      {checked && (
        <div style={{ marginLeft: "1.8rem", marginTop: "1rem" }}>
          {itemDisplayConditions.map((condition, index) => {
            // display conditions may not have an ID :(
            const conditionIndex = formDisplayConditions.findIndex(
              (fdc) =>
                (fdc.id && fdc.id === condition.id) ||
                (!fdc.id &&
                  !condition.id &&
                  fdc.sourceQuestionRootId === condition.sourceQuestionRootId &&
                  fdc.targetRootId === condition.targetRootId &&
                  fdc.sourceConditionRootId ===
                    condition.sourceConditionRootId &&
                  fdc.booleanCondition === condition.booleanCondition)
            );
            return (
              <Condition
                key={index}
                ruleType={condition.action}
                conditionIndex={conditionIndex}
                itemIndex={index}
                itemSubType={item.subType}
                onRemove={removeCondition}
                onUpdateType={updateConditionType}
                responseOptions={responseOptions}
                targetOptions={targetOptions}
                targetItems={targetItems}
                dataSource={dataSource}
                sourceColumns={sourceColumns}
              />
            );
          })}
          <AddRuleButton onAdd={addCondition} />
        </div>
      )}
    </div>
  );
};

export default DisplayConditions;
